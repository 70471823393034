const TRANSLATION = {
  slogen: "L'assistant IA le plus simple",
  pageHeader: {
    help: "Centre d'aide",
    contact: 'Contactez-nous',
    pricing: 'Tarifs',
    signUp: 'Se connecter',
    settings: 'Paramètres',
    logout: 'Déconnexion',
    logoutSucc: 'Déconnexion réussie',
  },
  pageFooter: {
    product: {
      title: 'Produits',
      web: 'Traduction Web et résumé',
      pdf: 'Traduction PDF',
      video: 'Traduction et résumé vidéo',
      image: 'Traduction d’images',
    },
    resources: { title: 'Ressources' },
    follow: { title: 'Suivre', twitter: 'Gazouillement', facebook: 'Facebook' },
    legal: {
      title: 'Légal',
      policy: 'politique de confidentialité',
      terms: "Conditions d'utilisation",
    },
  },
  home: {
    getStart: 'Commencer',
    getStartedForFree: 'Commencez gratuitement',
    top: {
      desc: 'Noam est une extension de navigateur IA gratuite qui vous aide à traduire des pages Web résumées, des vidéos et du contenu PDF.\n      Rejoignez Noam et profitez de la magie !',
      title2: 'Une magie',
      title1: 'Un clic',
    },
    howItWork: {
      title: 'Alors, comment ça marche',
      desc: "Noam intègre des modèles d'IA pour offrir aux utilisateurs une expérience intelligente pour la traduction et le résumé de pages Web multilingues, la traduction de PDF et d'images, la traduction et le résumé de vidéos.",
    },
    PDFTranslate: {
      title: 'PDF Traduire',
      desc: 'Traduction Bilingue + Chat PDF',
      sub1: {
        title: 'Lecture bilingue',
        desc: "Lecture bilingue, afin que vous puissiez comparer le texte original et la traduction pendant la lecture pour améliorer l'efficacité de la lecture",
      },
      sub2: {
        title: 'Chat PDF',
        desc: "L'IA analyse et résume et peut répondre à vos questions en fonction du contenu du PDF",
      },
      sub3: {
        title: 'Précision et efficacité',
        desc: "PDF alimenté par l'IA pour des traductions plus précises et efficaces",
      },
    },
    aiAssistant: {
      title: 'Assistant IA',
      desc: 'Rendre la traduction Web plus intelligente et plus pratique',
      panel1: {
        title: 'Traduction bilingue',
        desc: 'Les traductions bilingues avec références croisées vous aident à comparer le texte original avec la traduction afin que vous puissiez mieux comprendre et améliorer votre efficacité de lecture.',
      },
      panel2: {
        left: {
          title: 'Traduction du survol',
          desc: 'Lorsque vous parcourez une page Web, passez votre souris directement sur le contenu à traduire pour obtenir le résultat de la traduction.',
        },
        right: {
          title: 'Traduction de sélection de texte',
          desc: 'Lorsque vous parcourez une page Web, sélectionnez directement le texte à souligner pour obtenir le résultat de la traduction.',
        },
      },
      panel3: {
        one: {
          title: 'Pointer et cliquer',
          desc: "Traduisez et résumez des pages Web et du contenu vidéo pour vous à tout moment, n'importe où et dans n'importe quel scénario ; vous apportant une expérience plus fluide!",
        },
        two: {
          title: 'Plus complet et pratique',
          desc: 'Couverture complète multilingue, lecture bilingue sans obstacle, traduction à une touche ; vous apporte une expérience inégalée!',
        },
        three: {
          title: "Piloté par l'IA",
          desc: "Grand modèle piloté par l'IA pour garantir l'exactitude et la cohérence de la traduction et améliorer l'expérience de lecture",
        },
      },
      panel4: {
        tab1: {
          key: 'Vidéo',
          title: 'Traduction et résumé vidéo',
          desc: 'Votre expert en efficacité, associé à chatgpt traduit et résume le contenu vidéo YouTube pour gagner du temps de lecture !',
        },
        tab2: {
          key: 'Image',
          title: 'Image Traduire',
          desc: 'Cliquez pour traduire des images lorsque vous naviguez sur le Web',
        },
        tab3: {
          key: 'Web',
          title: 'Traduction et résumé Web',
          desc: 'Extraire rapidement les informations clés des pages Web pour les résumer, améliorant ainsi votre efficacité de lecture',
        },
        tag1: 'Simple',
        tag2: 'Efficacité',
        tag3: 'Facilement compris',
      },
    },
    userReviews: {
      title: 'Avis des utilisateurs',
      desc: 'Avis cinq étoiles sur le chromewebstore',
    },
    lastBlock: { desc: 'Meilleure traduction IA et expérience de lecture plus fluide' },
  },
  login: {
    title: 'Bienvenue à Noam',
    btn: 'Connectez-vous avec Google',
    tips: "En m'inscrivant, j'accepte",
    terms: "Conditions d'utilisation",
    and: 'et',
    policy: 'politique de confidentialité',
    btn1: 'Connectez-vous maintenant',
    desc: "Connectez-vous pour débloquer plus d'expériences",
  },
  pricing: {
    pageTitle: 'Tarifs',
    desc: 'Comparez et choisissez le meilleur pour vous',
    yearly: 'Annuellement',
    monthly: 'Mensuel',
    discount: '20% de réduction',
    preMonth: 'Par mois',
    billedYearly: 'Facturé annuellement',
    billedMonthly: 'Facturé mensuellement',
    detailedCompare: 'Comparaison détaillée',
    FAQ: 'FAQ',
    day: 'jour',
    month: 'mois',
    around: 'Autour',
    free: {
      benefit1: 'Traduction instantanée 100 fois/jour',
      benefit2: 'Traduction et résumé Web',
      benefit3: "30 feuilles de traduction d'images",
      benefit4: 'Traduction vidéo et résumé',
      benefit5: '10 Traduction PDF',
      benefit6: '5 000 jetons',
      recommendText: 'Essai gratuit',
      btn: 'Amusez-vous',
    },
    pro: {
      benefit1: 'Service client Assistance rapide',
      benefit2: '1 000 000 de jetons/mois',
      benefit3: 'Traduction et résumé Web',
      benefit4: "200 feuilles de traduction d'images",
      benefit5: 'Traduction vidéo et résumé',
      benefit6: '200 Traduction PDF',
      btn: 'Commencer',
      recommendText: 'La plupart des gens choisissent',
    },
    proPlus: {
      benefit1: 'Service client Assistance rapide',
      benefit2: '3 000 000 de jetons/mois',
      benefit3: 'Traduction et résumé Web',
      benefit4: "500 feuilles de traduction d'images",
      benefit5: 'Traduction vidéo et résumé',
      benefit6: '500 Traductions PDF',
      recommendText: 'Le choix le plus adapté',
    },
    enterprise: {
      benefit1: 'Service client Assistance rapide',
      benefit2: 'Multi-utilisateur',
      benefit3: 'Solutions personnalisées',
      benefit5: 'Gestionnaire de compte exclusif',
      recommendText: 'Plans personnalisés（≥3人）',
      talkToUs: 'Parlez-nous',
      btn: 'Contact',
    },
    web: {
      contrastiveTranslation: 'Traduction bilingue',
      instantTranslation: 'Traduction instantanée',
      hoverTranslation: 'Traduction du survol',
      dictionaryTranslation: 'Traduction de sélection de texte',
      summary: 'Résumé de la page Web',
    },
    imageTranslation: "Traduction d'images",
    video: { translation: 'Traduction vidéo', summary: 'Résumé vidéo' },
    pdf: {
      translation: 'Traduction PDF',
      maxPage: 'Nombre maximum de pages en PDF',
      chat: 'Nombre de demandes PDF',
    },
    FQAObj: {
      q1: "Puis-je l'utiliser gratuitement ?",
      a1: 'Il existe une version gratuite de Noam qui peut être utilisée directement.',
      q2: "Existe-t-il différents plans d'adhésion et options de frais ?",
      a2: "En plus de la version gratuite, il existe trois plans d'adhésion, Pro, Pro+ et Enterprise ; vous pouvez choisir entre deux méthodes de paiement, mensuelle continue et annuelle continue, et le coût global de l'année continue est plus avantageux.",
      q3: 'Quelle est la différence entre un abonnement mensuel continu et un abonnement annuel continu ?',
      a3: "Il n'y a aucune différence de fonctionnalité. Le coût global d'un abonnement annuel continu est inférieur à celui d'un abonnement mensuel continu ; l'abonnement mensuel est facturé une fois par mois, tandis que l'abonnement annuel est facturé une fois par an.",
      q4: 'Comment puis-je annuler mon plan de renouvellement automatique ?',
      a4: 'Vous pouvez accéder à la page des paramètres du backend et choisir de vous abonner pour annuler le plan de renouvellement.',
    },
  },
  upgradeModal: {
    title: 'Rappels',
    limitAlertStr:
      "Votre quota de forfait gratuit a été épuisé, nous vous recommandons de mettre à niveau votre forfait pour continuer l'expérience ~ !",
    upgradeAlertStr:
      "Votre quota de forfait gratuit a été épuisé, nous vous recommandons de mettre à niveau votre forfait pour continuer l'expérience ~ !",
    btn: 'Mettre à niveau maintenant',
    more: 'Plus de forfaits',
    contactStr:
      'Veuillez attendre la prochaine réinitialisation ou contactez-nous à contact@noam.tools.',
    upload: {
      free: 'Vous avez atteint votre limite de traduction PDF',
      pro: 'Vous avez atteint votre limite de traduction PDF ce mois-ci',
    },
    chat: {
      free: 'Vous avez atteint votre limite de Chatpdf dépassée',
      pro: 'Vous avez atteint votre limite de Chatpdf ce mois-ci',
    },
    pageLimit: { free: 'Vous avez atteint votre limite de pages PDF dépassée' },
  },
  buyModal: {
    title: "Plan d'abonnement",
    freeTrial: 'Essai gratuit de 3 jours',
    selectLabel: 'Sélectionnez un forfait',
    btn: 'Essayez-le gratuitement',
    yearLabel: 'année',
    monthLabel: 'mois',
    afterFree: 'Après le procès',
  },
  privacy: {
    title: 'politique de confidentialité',
    updateTime: 'Mise à jour : 15 mars 2024',
    block1:
      "Nous, Noam (dénommé « Noam », « nous », « notre » ou « notre »), respectons votre vie privée et nous engageons à protéger toute information que nous obtenons de vous ou à votre sujet. Cette politique de confidentialité décrit nos pratiques concernant la collecte ou l'utilisation d'informations personnelles provenant de votre utilisation de notre site Web, de nos applications et de nos services (collectivement appelés les « Services »). Cette politique de confidentialité ne s'applique pas au contenu que nous traitons au nom de nos clients de produits professionnels, tels que notre API. L'utilisation de ces données est soumise à notre accord client qui couvre l'accès et l'utilisation de ces produits.",
    subTitle1: 'Informations personnelles que nous collectons',
    block2:
      'Nous collectons les informations personnelles suivantes (« Informations personnelles ») vous concernant :',
    block3:
      'Informations fournies par vous : Si vous créez un compte pour utiliser nos services ou communiquer avec nous, nous collecterons des informations personnelles comme suit :',
    subBlock1:
      "Informations sur le compte : lorsque vous créez un compte sur notre plateforme, nous collectons des informations relatives à votre compte, notamment votre nom, vos coordonnées, vos identifiants de compte, vos informations de carte de paiement et l'historique des transactions (appelées « Informations sur le compte »).",
    subBlock2:
      'Contenu utilisateur : lorsque vous utilisez nos services, nous collectons des informations personnelles à partir de vos entrées, téléchargements de fichiers ou commentaires qui nous sont fournis (appelés « Contenu »).',
    subBlock3:
      'Informations de communication : si vous nous envoyez des messages, nous collecterons votre nom, vos coordonnées et le contenu des messages que vous envoyez (appelés « Informations de communication »).',
    subBlock4:
      "Informations sur les réseaux sociaux : nous avons des pages sur des sites de réseaux sociaux tels qu'Instagram, Facebook, Medium, Twitter, YouTube et LinkedIn. Lorsque vous interagissez avec nos pages de réseaux sociaux, nous collectons les informations personnelles que vous choisissez de nous fournir, telles que vos coordonnées (appelées « Informations sociales »). De plus, les sociétés hébergeant nos pages de réseaux sociaux peuvent nous fournir des informations et des analyses globales sur nos activités sur les réseaux sociaux.",
    block4:
      'Informations personnelles reçues automatiquement lors de votre utilisation des services : lorsque vous accédez, utilisez ou interagissez avec les services, nous recevons les informations suivantes concernant votre accès, votre utilisation ou votre interaction ("Informations techniques") :',
    subBlock5:
      "Données de journal : informations automatiquement envoyées par votre navigateur lors de l'utilisation de nos services. Les données du journal incluent votre adresse IP (Internet Protocol), le type et les paramètres de votre navigateur, la date et l'heure de vos demandes et la manière dont vous interagissez avec notre site Web.",
    subBlock6:
      "Données d'utilisation : nous pouvons collecter automatiquement des informations sur votre utilisation du service, telles que les types de contenu que vous consultez ou avec lesquels vous interagissez, les fonctionnalités que vous utilisez et vos actions entreprises, ainsi que votre fuseau horaire, votre pays, la date et l'heure de votre utilisation. accès, agent utilisateur et version, type d'ordinateur ou d'appareil mobile et connexion à votre ordinateur.",
    subBlock7:
      "Informations sur l'appareil : inclut le nom de l'appareil, le système d'exploitation, l'identifiant de l'appareil et le navigateur que vous utilisez. Les informations collectées peuvent dépendre du type d'appareil que vous utilisez et de ses paramètres.",
    subBlock8:
      "Cookies : Nous utilisons des cookies pour exploiter et gérer nos services et améliorer votre expérience. Un « cookie » est une information envoyée par un site Internet que vous visitez à votre navigateur. Vous pouvez configurer votre navigateur pour qu'il accepte tous les cookies, rejette tous les cookies ou vous avertisse lorsqu'un cookie est fourni afin que vous puissiez décider de l'accepter ou non à chaque fois. Cependant, refuser les cookies peut vous empêcher d’utiliser ou affecter négativement l’affichage ou la fonctionnalité de certaines zones ou fonctionnalités du site Internet. Pour plus d'informations sur les cookies, veuillez visiter Tout sur les cookies.",
    subBlock9:
      "Analyse : nous pouvons utiliser divers produits d'analyse en ligne qui utilisent des cookies pour nous aider à analyser la façon dont les utilisateurs utilisent nos services et à améliorer votre expérience lors de l'utilisation des services.",
    subTitle2: 'Comment nous utilisons les informations personnelles',
    block5: 'Nous pouvons utiliser les informations personnelles aux fins suivantes :',
    subBlock10: 'Fournir, gérer, maintenir et/ou analyser des services ;',
    subBlock26: 'Améliorer nos services et mener des recherches ;',
    subBlock11: 'Communiquer avec vous ;',
    subBlock12: 'Développer de nouveaux projets et services ;',
    subBlock13:
      "Prévenir la fraude, les activités criminelles ou l'utilisation abusive de nos services et protéger la sécurité de nos systèmes informatiques, de notre architecture et de nos réseaux ;",
    subBlock14: "Réaliser des transmissions d'entreprises ;",
    subBlock15:
      "Se conformer aux obligations légales et aux procédures judiciaires, protéger nos droits, notre vie privée, notre sécurité ou nos biens, ainsi que ceux de nos sociétés affiliées, de vous ou d'autres tiers.",
    block6:
      "Informations résumées ou anonymisées : nous pouvons regrouper ou anonymiser des informations personnelles pour empêcher leur réutilisation à des fins d'identification et utiliser ces informations pour analyser l'efficacité de nos services, améliorer et ajouter des fonctionnalités à nos services, mener des recherches et à des fins similaires. . De plus, nous pouvons analyser le comportement général et les caractéristiques des utilisateurs de nos services et partager des informations agrégées avec des tiers, publier ces informations agrégées ou les rendre généralement accessibles. Nous pouvons collecter des informations agrégées via les services, via des cookies et par d'autres moyens décrits dans la présente politique de confidentialité. Nous conserverons et utiliserons les informations anonymisées sous une forme anonyme ou anonymisée, et nous ne tenterons pas de réidentifier ces informations, sauf si la loi l'exige.",
    subTitle3: 'Divulgation de renseignements personnels',
    block7:
      "Dans certains cas, nous pouvons fournir vos informations personnelles à des tiers sans préavis, sauf si la loi l'exige :",
    subBlock16:
      "Fournisseurs et prestataires de services : pour nous aider à répondre aux besoins opérationnels de l'entreprise et à exécuter certains services et fonctions, nous pouvons fournir des informations personnelles à des fournisseurs et prestataires de services, notamment des fournisseurs de services d'hébergement, des fournisseurs de services cloud et d'autres fournisseurs de services informatiques, des logiciels de communication par courrier électronique. , et les fournisseurs de services d'analyse Web, etc. Ces partenaires accéderont, traiteront ou stockeront les informations personnelles uniquement selon nos instructions dans l'exercice de leurs fonctions pour nous.",
    subBlock17:
      "Transferts d'entreprises : si nous nous engageons dans des transactions stratégiques, des réorganisations, des faillites, des rachats en cas de faillite ou une transition de services vers un autre fournisseur (collectivement appelés « Transactions »), vos informations personnelles et d'autres informations peuvent être partagées avec des contreparties à la transaction et d'autres parties aidant à les Transactions au cours de la vérification diligente et transférés dans le cadre des Transactions avec d'autres actifs à une société successeur ou affiliée.",
    subBlock18:
      "Exigences légales : nous pouvons partager vos informations personnelles avec des autorités gouvernementales, des pairs du secteur ou d'autres tiers, y compris vos informations d'interaction avec nos services, (1) si la loi l'exige ou si nous pensons qu'une telle action est nécessaire pour se conformer aux obligations légales ; (2) pour protéger et défendre nos droits ou nos biens ; (3) si nous déterminons unilatéralement qu'il y a une violation de nos conditions, politiques ou lois ; (4) pour détecter ou prévenir la fraude ou d'autres activités illégales ; (5) pour protéger nos produits, nos employés ou nos utilisateurs, ou la sécurité, la sécurité et l'intégrité du public ; ou (vi) pour vous défendre contre toute responsabilité légale.",
    subTitle4: 'Vos droits',
    block8:
      "En fonction de votre situation géographique, les personnes vivant dans l'Espace économique européen, au Royaume-Uni et dans le monde peuvent bénéficier de certains droits légaux concernant leurs informations personnelles. Par exemple, vous pourriez avoir le droit de :",
    subBlock19:
      'Accédez à vos informations personnelles et aux informations sur la manière dont elles sont traitées.',
    subBlock20: 'Supprimez vos informations personnelles de nos dossiers.',
    subBlock21: 'Corrigez ou mettez à jour vos informations personnelles.',
    subBlock22: 'Transférez vos informations personnelles à un tiers (portabilité des données).',
    subBlock23: 'Limitez la manière dont nous traitons vos informations personnelles.',
    subBlock24:
      'Retirez votre consentement : lorsque nous nous appuyons sur le consentement comme base juridique pour le traitement, vous pouvez retirer votre consentement à tout moment.',
    subBlock25: 'Vous opposer à la manière dont nous traitons vos informations personnelles.',
    subTitle5: "Liens vers d'autres sites Web",
    block9:
      "Le service peut contenir des liens vers d'autres sites Web non exploités ou contrôlés par Noam, y compris des services de médias sociaux (« Sites Web tiers »). Les informations que vous partagez avec des sites Web tiers seront régies par les politiques de confidentialité et les conditions de service spécifiques des sites Web tiers, et non par la présente politique de confidentialité. Nous fournissons ces liens pour plus de commodité et n'implique pas notre approbation ou notre examen de ces sites Web. Veuillez contacter directement les sites Web tiers pour obtenir des informations sur leurs politiques et conditions de confidentialité.",
    subTitle6: 'Sécurité et conservation des informations',
    block10:
      "Nous mettons en œuvre des mesures techniques, administratives et organisationnelles commercialement raisonnables pour protéger les informations personnelles en ligne et hors ligne contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation, l'altération ou les actes hostiles. Cependant, aucune transmission par Internet ou par courrier électronique ne peut garantir une sécurité ou une exactitude totale. En particulier, les e-mails qui nous sont envoyés ou que nous émanons peuvent ne pas être sécurisés. Vous devez donc être particulièrement prudent lorsque vous décidez quelles informations nous envoyer via le service ou par courrier électronique. De plus, nous ne sommes pas responsables de toute obstruction des paramètres de confidentialité ou des mesures de sécurité dans le service ou des paramètres de sécurité ou des mesures de sécurité des sites Web tiers. Nous conserverons vos informations personnelles aussi longtemps que nécessaire pour vous fournir des services ou à d'autres fins commerciales légitimes, telles que la résolution de litiges, des raisons de sécurité et de sûreté, ou le respect de nos obligations légales. La durée pendant laquelle nous conservons les informations personnelles dépend de nombreux facteurs, tels que la quantité, la nature, la sensibilité des informations, les risques potentiels d'utilisation ou de divulgation non autorisée, les finalités pour lesquelles nous traitons les informations et d'autres considérations juridiquement contraignantes.",
    subTitle7: 'Modification de la politique de confidentialité',
    block11:
      'Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Lorsque nous le ferons, nous publierons une version mise à jour sur cette page, sauf disposition contraire de la loi applicable.',
    subTitle8: 'Comment nous contacter',
    block12:
      'Si vous avez des questions ou des préoccupations sans réponse concernant cette politique de confidentialité, veuillez contacter notre service client. E-mail:',
  },
  terms: {
    title: "Conditions d'utilisation",
    updateTime: 'Mise à jour : 15 mars 2024',
    block1: "Merci d'utiliser Noam !",
    block2:
      "Ces conditions d'utilisation s'appliquent à votre utilisation des services de Noam, y compris nos interfaces de programmation d'applications, nos logiciels, nos outils, nos services de développement, nos données, notre documentation et notre site Web (collectivement appelés les « Services »). En utilisant nos Services, vous acceptez de respecter ces conditions. Notre politique de confidentialité explique comment nous collectons et utilisons les informations personnelles.",
    subTitle1: 'Inscription et accès',
    block3:
      "Si vous avez moins de 18 ans, vous devez obtenir l'autorisation de vos parents ou tuteurs légaux pour utiliser le Service. Si vous utilisez le Service au nom d'une autre personne ou entité, vous devez être autorisé à accepter ces conditions en son nom. Vous devez fournir des informations exactes et complètes pour créer un compte. Vous ne pouvez pas fournir vos identifiants d'accès ou votre compte à quiconque en dehors de votre organisation, et vous êtes responsable de toutes les activités menées à l'aide de vos identifiants.",
    subTitle2: "Exigences d'utilisation",
    block4:
      "(a) Utilisation du Service. Vous pouvez accéder au Service selon ces conditions et nous vous accordons un droit non exclusif d'utiliser le Service. Lorsque vous utilisez le Service, vous respecterez ces conditions et toutes les lois applicables. Nous et nos sociétés affiliées détenons tous les droits, titres et intérêts dans le Service.",
    block5:
      "(b) Commentaires. Nous apprécions les commentaires, les idées, les suggestions et les améliorations. Si vous fournissez un tel contenu, nous pouvons l'utiliser sans aucune restriction et sans compensation pour vous.",
    block6:
      "(c) Restrictions. Vous ne pouvez pas : (1) utiliser le Service d'une manière qui enfreint, détourne ou viole les droits d'autrui ; (2) faire de l'ingénierie inverse, décompiler, désassembler, traduire ou tenter de toute autre manière de découvrir le code source ou les composants sous-jacents des modèles, algorithmes et systèmes du Service (sauf si de telles restrictions sont interdites par la loi applicable) ; (3) utiliser les résultats du Service pour développer des modèles concurrents de Noam ; (4) extraire des données ou des résultats du Service à l'aide de méthodes automatisées ou de programmation, sauf autorisation via l'API, y compris le scraping, la récolte Web ou l'extraction de données Web ; (5) représenter le résultat du Service comme généré artificiellement alors qu'il ne l'est pas, ou violer de toute autre manière nos politiques d'utilisation ; (6) acheter, vendre ou transférer des clés API sans notre consentement préalable ; ou (7) nous fournir des informations personnelles sur des enfants de moins de 13 ans ou de l'âge applicable du consentement numérique. Vous respecterez toutes les limites de taux et autres exigences indiquées dans notre documentation.",
    block7:
      '(d) Services tiers. Tous les logiciels, services ou autres produits tiers associés au Service sont régis par leurs propres conditions et nous ne sommes pas responsables des produits tiers.',
    subTitle3: 'Contenu',
    block8:
      "(a) Votre contenu. Vous pouvez fournir une entrée (« Entrée ») au Service et recevoir une sortie (« Sortie ») générée et renvoyée en fonction de l'entrée (collectivement appelée « Contenu »). Entre les parties, et dans la mesure permise par la loi applicable, vous êtes propriétaire de toutes les Entrées. Noam peut utiliser le Contenu pour fournir et maintenir le Service, se conformer aux lois applicables et appliquer nos politiques. Vous êtes responsable du contenu, notamment en vous assurant qu'il ne viole aucune loi applicable ou les présentes conditions.",
    block9:
      "(b) Précision. L’intelligence artificielle et l’apprentissage automatique sont des domaines de recherche en évolution rapide. Nous nous efforçons continuellement d'améliorer notre service pour le rendre plus précis, fiable, sécurisé et avantageux. Compte tenu de la nature probabiliste de l'apprentissage automatique, l'utilisation de notre service peut entraîner des résultats incorrects dans certains cas, qui peuvent ne pas refléter avec précision des individus, des lieux ou des faits réels. Vous devez évaluer de manière appropriée l'exactitude de tout résultat en fonction de votre cas d'utilisation, notamment en examinant manuellement les résultats.",
    subTitle4: 'Frais et paiement',
    block10:
      "(a) Frais et facturation. Vous paierez tous les frais (« Frais ») conformément aux tarifs et aux conditions indiqués sur la page de tarification applicable ou comme convenu par écrit entre nous. Nous nous réservons le droit de corriger les erreurs ou erreurs de prix même si nous avons émis des factures ou reçu un paiement. Vous fournirez des informations de facturation complètes et précises, y compris un mode de paiement valide et autorisé. Nous facturerons votre mode de paiement sur une base périodique comme convenu, mais nous pouvons raisonnablement modifier la date de facturation. Vous autorisez Noam, ses sociétés affiliées et les processeurs de paiement tiers à facturer votre mode de paiement. Si votre paiement échoue, nous vous enverrons un avis écrit et pourrons suspendre l'accès au Service jusqu'à ce que le paiement soit reçu. Sauf indication contraire dans le présent accord, les paiements ne sont pas remboursables.",
    block11:
      "(b) Impôts. Sauf indication contraire, les frais n'incluent pas les taxes, droits et évaluations similaires fédéraux, étatiques, locaux et étrangers (« taxes »). Vous êtes responsable de toutes les taxes associées à vos achats, à l'exclusion des taxes basées sur notre revenu net que nous vous facturerons. Vous acceptez de payer rapidement ces taxes, de nous fournir des déclarations de confidentialité légalement valables et d'obtenir les consentements nécessaires pour traiter ces données, et vous nous garantissez que vous traitez ces données conformément à la loi applicable.",
    block12:
      "(c) Modifications de prix. Nous pouvons modifier nos prix en vous en informant via votre compte et/ou notre site Web. Les augmentations de prix entreront en vigueur immédiatement après leur publication. Toute modification de prix s'appliquera aux frais de votre compte après la date d'entrée en vigueur de la modification.",
    block13:
      '(d) Litiges et paiements en retard. Si vous souhaitez contester des frais ou taxes, veuillez contacter contact@noam.tools dans les trente (30) jours suivant la date de facture.',
    block14:
      "(e) Utilisation gratuite. Vous ne pouvez pas créer plusieurs comptes pour obtenir des crédits gratuits. Si nous déterminons que vous n'utilisez pas les crédits gratuits de bonne foi, nous pouvons vous facturer des frais standard ou cesser de fournir l'accès au Service.",
    subTitle5: 'Confidentialité, sécurité et protection des données',
    block15:
      "(a) Confidentialité. Vous pouvez avoir accès aux informations confidentielles de Noam. Vous ne pouvez utiliser les informations confidentielles que dans la mesure permise par les présentes conditions dans le but d'utiliser le Service. Vous ne pouvez divulguer d’informations confidentielles à aucun tiers et vous protégerez les informations confidentielles d’une manière non moins protectrice que vous protégez vos propres informations confidentielles similaires, au moins avec un soin raisonnable. Les informations confidentielles sont des informations non publiques désignées comme confidentielles par Noam ou qui devraient raisonnablement être traitées comme confidentielles dans les circonstances, y compris les logiciels, les spécifications et autres informations commerciales non publiques. Les informations confidentielles n'incluent pas les informations qui : (1) deviennent généralement accessibles au public sans faute de votre part ; (2) vous l'aviez en votre possession sans aucune obligation de confidentialité avant de le recevoir selon les présentes conditions ; (3) vous est légalement divulgué par un tiers sans aucune obligation de confidentialité ; ou (4) est développé indépendamment par vous sans utiliser les informations confidentielles. Vous pouvez divulguer des informations confidentielles si la loi, un tribunal ou toute autre ordonnance gouvernementale l'exige, mais vous devez fournir un préavis écrit à Noam de manière raisonnable et, dans la mesure du possible, faire des efforts raisonnables pour limiter la portée de la divulgation, y compris en nous aidant. en s'opposant aux demandes de divulgation.",
    block16:
      'b) Sécurité. Vous devez mettre en œuvre des mesures raisonnables et appropriées pour aider à protéger votre accès et votre utilisation du Service. Si vous découvrez des vulnérabilités ou des violations liées à votre utilisation du Service, vous devez rapidement contacter Noam et fournir des détails sur la vulnérabilité ou la violation.',
    block17:
      '((c) Traitement des données personnelles. Si vous utilisez le Service pour traiter des données personnelles, vous devez fournir des déclarations de confidentialité légalement suffisantes et obtenir les consentements nécessaires pour traiter ces données, et vous nous garantissez que vous traitez ces données conformément à la loi applicable. .',
    subTitle6: 'Durée et résiliation',
    block18:
      "(a) Résiliation ; Suspension. Ces conditions entreront en vigueur dès votre première utilisation du Service et resteront en vigueur jusqu'à leur résiliation. Vous pouvez résilier ces conditions à tout moment pour quelque raison que ce soit en cessant d'utiliser le Service et le Contenu. Nous pouvons résilier ces conditions avec préavis pour quelque raison que ce soit. Nous pouvons immédiatement résilier ces conditions en vous avertissant si vous enfreignez de manière substantielle la section 2 (Conditions d'utilisation), la section 5 (Confidentialité, sécurité et protection des données), la section 8 (Résolution des litiges) ou la section 9 (Conditions générales), ou si notre relation avec tout fournisseur de technologie tiers indépendant de notre volonté, ou pour le respect de la loi ou des demandes gouvernementales. Nous pouvons suspendre votre accès au Service si vous ne respectez pas ces conditions, ou si votre utilisation présente un risque de sécurité pour nous ou tout tiers, ou si nous soupçonnons que votre utilisation est frauduleuse ou peut nous exposer, nous ou tout tiers, à une responsabilité. .",
    block19:
      "(b) Effets de la résiliation. En cas de résiliation, vous cesserez d'utiliser le Service et retournerez ou détruirez rapidement toute information confidentielle selon nos instructions. Les dispositions des présentes conditions qui, de par leur nature, devraient survivre à la résiliation ou à l'expiration, y compris, mais sans s'y limiter, les sections 3 et 5 à 9, resteront en vigueur.",
    subTitle7: 'Indemnité; Avis de non-responsabilité ; Limitation de responsabilité',
    block20:
      "(a) Indemnisation. Vous nous défendrez, nous indemniserez et nous dégagerez de toute responsabilité, ainsi que nos sociétés affiliées et notre personnel, contre toute réclamation, perte et dépense (y compris les honoraires d'avocat) découlant de ou liés à votre utilisation du Service, y compris votre Contenu, votre l'utilisation de produits ou de services liés au Service et votre violation de ces conditions ou de la loi applicable.",
    block21:
      "(b) Avis de non-responsabilité. Le Service est fourni « tel quel ». Dans la mesure permise par la loi, nous et nos sociétés affiliées et concédants de licence ne faisons aucune déclaration ou garantie d'aucune sorte concernant le Service et n'offrons aucune garantie, y compris, mais sans s'y limiter, les garanties de qualité marchande, d'adéquation à un usage particulier, de qualité satisfaisante, de non-contrefaçon. , et la jouissance tranquille, ainsi que toutes les garanties découlant du cours des transactions ou de l'usage du commerce. Nous ne garantissons pas que le Service sera ininterrompu, précis ou sans erreur, ni que tout Contenu sera sécurisé, non perdu ou modifié.",
    block22:
      "(c) Limitation de responsabilité. Ni nous, ni nos sociétés affiliées, ni nos concédants de licence ne serons responsables de tout dommage indirect, accidentel, spécial, consécutif ou punitif, y compris la perte de profits, la perte de réputation, la perte d'utilisation, la perte de données ou d'autres pertes intangibles, même si nous avons été informés de la possibilité de tels dommages. Selon ces conditions, notre responsabilité totale ne dépassera pas les frais que vous avez payés au service au cours des douze (12) mois précédant la réclamation ou cent dollars américains (100 $), selon le montant le plus élevé. Les limitations de cette section s'appliquent dans la mesure maximale permise par la loi applicable.",
    subTitle8: 'Loi applicable et juridiction',
    block23:
      "Les Conditions et votre utilisation des Services sont régies par les lois des États-Unis, à l'exception des règles américaines de conflit de lois. Votre utilisation de l’Application peut également être soumise à d’autres lois locales, étatiques, nationales ou internationales. Toute action ou procédure judiciaire relative aux présentes Conditions sera intentée exclusivement devant les tribunaux des États-Unis, et vous acceptez de vous soumettre à la juridiction personnelle de ces tribunaux.",
    subTitle9: 'Conditions générales',
    block24:
      "(a) Relation entre les parties. Noam et vous êtes des entrepreneurs indépendants, et aucune des parties n'a le pouvoir de représenter ou de lier l'autre partie ou d'assumer des obligations pour l'autre partie sans le consentement écrit préalable de l'autre partie.",
    block25:
      "(b) Utilisation de la marque. Vous ne pouvez pas utiliser le nom, les logos ou les marques commerciales de Noam ou de l'une de ses sociétés affiliées sans notre consentement écrit préalable.",
    subTitle10: 'Divisibilité',
    block26:
      'Si une disposition de ces conditions est jugée invalide, illégale ou inapplicable, les dispositions restantes resteront pleinement en vigueur.',
  },
  contact: {
    title: 'Contactez-nous',
    block1:
      'Pour une personnalisation ou si vous avez des questions ou des suggestions, veuillez nous contacter à contact@noam.tools et nous vous répondrons immédiatement.',
    block2:
      "Soutenez-nous en nous évaluant sur la boutique d'extensions Google Apps à l'adresse ★★★★★.",
    block3: 'Aidez les autres en partageant cette extension :',
  },
  pdf: {
    toast1: 'Le PDF est en cours de chargement, veuillez réessayer plus tard !',
    toast2:
      'Le contenu PDF est trop important, ne prend pas en charge la boîte de dialogue pour le moment',
    toast3: 'Téléchargement, veuillez réessayer une fois le téléchargement terminé.',
    shareMsg1: 'Génération de liens de partage réussie',
    shareMsg2: 'Partager avec succès les mises à jour de contenu',
    shareMsg3:
      'La génération du lien de partage a échoué, veuillez réessayer plus tard ou contacter notre service client.',
    shareMsg4:
      'Échec de la mise à jour du partage de contenu. Veuillez réessayer plus tard ou contacter le service client.',
    shareBtnText1: 'Générer des liens de partage',
    shareBtnText2: 'Contenu de partage mis à jour',
    uploading: 'Téléchargement',
    uploadingMsg: 'Soyez patient car le téléchargement prend un certain temps.',
    cancelUpload: 'Annuler',
    uploadErr:
      'Le téléchargement du fichier a échoué. Veuillez réessayer plus tard ou contacter le service client',
    uploadMsg1: 'Veuillez glisser et déposer le fichier ici',
    uploadMsg2: 'Types de fichiers pris en charge : PDF |  Taille maximale du fichier : 100 Mo',
    uploadMsg3: 'Cliquez pour sélectionner ou glisser-déposer les fichiers à télécharger ici',
    searchPlaceholder: 'Recherche',
    collectionEmpty: 'Aucun contenu dans la collection~',
    kw: 'agrafe',
    video: 'vidéo',
    link: 'lien',
    dataLoading: 'Chargement des données...',
    freeBannerTips: 'Fin de la traduction PDF gratuite',
    vipBannerTips: 'Vous avez atteint votre limite de traduction PDF ce mois-ci.',
    freeBannerBtn: 'Mettez à niveau pour en profiter davantage',
    vipBannerBtn: 'Mettez à niveau pour en profiter davantage',
    dialogTitle: 'Attention',
    dialogContent: 'Êtes-vous sûr de vouloir le supprimer ?',
    okBtn: 'Supprimer',
    cancelBtn: 'Annuler',
    unLoginToast: 'Veuillez vous connecter pour utiliser cette fonction',
    parseErrTips: "Impossible d'analyser le fichier",
    new: 'nouveau fichier',
    parseErrDialogContent:
      'Le lien PDF actuel ne prend pas en charge la traduction directe, veuillez télécharger le lien PDF original après la traduction',
    okBtn2: 'reconnaître',
    notPdf: 'Fichiers PDF uniquement',
    uploadingMsg1: 'Téléchargement du fichier en cours. Veuillez réessayer plus tard.',
    uploadingMsg2:
      'Vous avez actuellement un fichier en cours de conversion, veuillez réessayer plus tard.',
    uploadingMsg3:
      'Échec de la demande de progression de la conversion. Veuillez réessayer plus tard ou contacter le service client.',
    uploadingMsg4:
      'Noam ne peut pas accéder directement à vos fichiers locaux, veuillez télécharger manuellement un PDF pour traduction.',
    uploadingMsg5:
      "Échec de l'obtention de l'URL du fichier, veuillez réessayer plus tard ou contacter le service client.",
    fileMsg: 'Volume de fichier actuel :',
    fileMsg2: 'Veuillez télécharger un fichier inférieur à 100 Mo',
    fileErrMsg:
      'La lecture du fichier a échoué, veuillez réessayer plus tard ou contacter le service client.',
    parseFileToast:
      "L'analyse du fichier a échoué, veuillez réessayer plus tard ou contacter notre service client.",
    noId: "L'ID de fichier n'existe pas",
    autoDetect: 'Détection automatique',
    hasTransTask: "Il y a une tâche en cours de traduction. S'il vous plaît, attendez.",
    transSucc: 'La traduction a réussi !',
    entry: 'Liste PDF',
    listError:
      "La liste PDF n'a pas pu être chargée, veuillez réessayer plus tard ou contacter le service client.",
    collectionTab: 'Note',
    pdfLoading: 'Analyse...',
    installTips:
      "Installez le petit plug-in, vous pouvez traduire plus de fichiers PDF, vous pouvez également bénéficier d'une traduction fluide de pages Web :",
    webTrans: 'référence croisée complète',
    hoverTrans: 'Traduction de paragraphe de survol',
    selectTrans: 'souligner les mots et traduire',
    installText: 'Un guichet unique pour tous vos besoins quotidiens en traduction',
    start: 'Commencez votre expérience gratuite',
    shareTips: 'La traduction PDF est terminée, allez-y et partagez-la avec vos amis !',
    download: {
      loading: 'Sauvé...',
      btn: 'Économisez maintenant',
      trans: 'Traduction uniquement',
      both: 'bilinguisme',
      download: 'Télécharger',
      msg1: 'Certaines traductions du document actuel sont plus longues que le texte original et le texte peut se chevaucher après le téléchargement, il est donc recommandé de le lire sur la page Web.',
      msg2: "1. Le contenu n'a pas été entièrement traduit, veuillez le télécharger après la traduction complète.",
      msg3: '2. Une partie du document de la traduction est trop longue, vous pouvez la lire sur la page Web pour éviter le chevauchement du texte.',
      msg4: 'Des tâches sont actuellement en cours de traduction, veuillez réessayer une fois la traduction terminée.',
      fileName: 'Nom de fichier:',
      fileType: 'Type de fichier à télécharger :',
      transContinue: 'Continuer la traduction',
      save: 'Enregistrer en local',
      downloadMsg1: 'PDF Progrès en traduction :',
      downloadMsg2: 'Exigences projetées',
      minute: 'Minutes...',
      downloadSucc: 'Le téléchargement du PDF est terminé, cliquez sur Enregistrer maintenant !',
      savePrint: 'Enregistrer et imprimer',
      loading2: 'Chargement...',
      desc: "Enregistrer localement : les traductions téléchargées sont enregistrées sous forme d'images ; Enregistrer et imprimer : les traductions téléchargées sont conservées dans leur format d'origine et prennent en charge la copie.",
      success: 'Télécharger avec succès',
      cancel:
        'La fermeture de la fenêtre contextuelle annulera le téléchargement du fichier PDF. Êtes-vous sûr de vouloir annuler ?',
      downloadTip:
        'Le PDF est en cours de téléchargement. Ne fermez pas la fenêtre contextuelle pendant le téléchargement pour éviter un échec du téléchargement.',
      progress:
        'Le PDF est en cours de traduction. Progression : {{progress}}, devrait prendre {{minutes}} minutes...',
      error: "Échec de l'enregistrement en local, veuillez essayer d'imprimer l'enregistrement.",
    },
    guide: {
      title1: 'Traduction bilingue',
      title2: 'Chat PDF',
      text1: 'Lecture bilingue',
      text2:
        "Lecture bilingue, afin que vous puissiez comparer le texte original et la traduction pendant la lecture pour améliorer l'efficacité de la lecture",
      text3: 'Chat PDF',
      text4:
        "L'IA analyse et résume et peut répondre à vos questions en fonction du contenu du PDF",
      text5: 'Précision et efficacité',
      text6: "PDF alimenté par l'IA pour des traductions plus précises et efficaces",
      placeholder: 'Veuillez remplir le lien PDF',
      start: 'Commencer la traduction',
      toast: 'Veuillez saisir un lien PDF en ligne valide se terminant par .pdf',
    },
    original: 'Original',
    parallel: 'Parallèle',
    trans: 'Traduction',
    initial: 'Traduire un PDF',
    thumbnail: 'catalogues',
    downloadBtn: 'Télécharger',
    shareBtn: 'Partager',
    uploadBtn: 'Télécharger',
    installText2: "Veuillez d'abord installer",
    chatPdf: {
      clearSucc: 'Effacé avec succès',
      clear: 'Clair',
      clearContent:
        "Après l'effacement, une nouvelle conversation sera créée et le contenu de cette conversation ne sera pas enregistré, êtes-vous sûr de vouloir l'effacer ?",
      clearDialogTitle: 'Attention',
      cancel: 'Annuler',
      chatTitle: 'Bienvenue sur Noam, votre résumé PDF est prêt :',
      summaryTitle: 'Questions qui pourraient vous intéresser :',
      placeholder: 'Entrez toute question sur le PDF',
      copy: 'Copie',
      copySucc: 'Copie réussie',
      historyTitle: 'Histoire',
      expand: 'Effondrement',
      noDataDesc: "Pas d'historique~",
      noMore: 'Pas plus.',
      Search: 'Recherche',
      delete: 'Supprimer',
      loading: 'chargement...',
      deleteTitle: 'Conseil',
      deleteContent:
        "Après suppression, ce PDF n'est pas récupérable, souhaitez-vous le supprimer ?",
      deleteOk: 'Supprimer',
      deleteCancel: 'Annuler',
      deleteSuccess: 'Supprimer avec succès',
      listError:
        "La liste de lecture n'a pas pu être chargée. Veuillez réessayer plus tard ou contacter le service client",
    },
    shareModal: {
      shareSetting: 'Partager les paramètres de contenu',
      shareText: 'Partager',
      text1: 'Paramètres de partage',
      text2: 'Générer des liens de partage',
      range: 'Portée:',
      custom: 'Personnalisation :',
      page: 'pages',
      sourceLang: 'Source:',
      target: 'Cible :',
      errMsg:
        "Le contenu de la plage de partage actuelle n'a pas été entièrement traduit, veuillez donc le traduire avant de le partager.",
      pageNuErr: 'Veuillez saisir la valeur correcte du numéro de page',
      trans: 'Traduire',
      cancel: 'Annuler',
      update: 'Liens de partage mis à jour',
      gen: 'Partager',
      shareTips: 'Partagez le lien PDF traduit avec vos amis pour lire :',
      copySucc: 'Copie du lien réussie',
      copy: 'Copie',
      settings: 'Paramètres de partage :',
      modify: 'modifier',
      translateTips:
        'Astuce : Si la traduction prend du temps, vous pouvez fermer la fenêtre contextuelle et la partager une fois la traduction terminée.',
      translating: 'Traduction PDF...',
      remain: 'restant',
      remain1: 'Besoins totaux projetés',
      totalPage: '1 page au total',
      search: 'Recherche',
      notSupport: "Cette langue n'est actuellement pas prise en charge",
    },
    toolbar: { Adjust: 'Ajuster', autoFit: 'Ajustement automatique' },
    memoTotalCount: 'Total de 1 contenu',
    dragTipModal: {
      press: 'Presse',
      black: 'espace',
      leftKey: 'bouton gauche',
      tips: 'à faire glisser et à visualiser.',
      ok: "J'ai compris",
    },
  },
  locale: {
    zh: 'Chinois',
    en: 'Anglais',
    es: 'Espagnol',
    ru: 'russe',
    pt: 'Portugais (Brésil)',
    id: 'indonésien',
    cs: 'tchèque',
    de: 'Allemand',
    fr: 'Français',
    it: 'italien',
    nl: 'Néerlandais',
    pl: 'polonais',
    vi: 'vietnamien',
    tr: 'turc',
    uk: 'ukrainien',
    zhHant: 'Chinois traditionnel',
    ja: 'japonais',
    ko: 'coréen',
    ar: 'arabe',
    fa: 'persan',
    th: 'thaïlandais',
    hi: 'hindi',
    noSearchLang: "Cette langue n'est pas prise en charge",
    placeholder: 'Recherche',
    af: 'afrikaans',
    am: 'Amharique',
    as: 'Assamais',
    az: 'azerbaïdjanais',
    ba: 'Bachkir',
    bg: 'bulgare',
    bn: 'Bangla',
    bo: 'Tibétain',
    bs: 'bosniaque',
    ca: 'catalan',
    cy: 'gallois',
    da: 'danois',
    dsb: 'Bas-sorabe',
    dv: 'Divéhi',
    el: 'grec',
    et: 'estonien',
    eu: 'Basque',
    fi: 'finlandais',
    fil: 'Philippin',
    fj: 'Fidjien',
    frCA: 'Français (Canada)',
    ga: 'irlandais',
    gl: 'Galicien',
    gom: 'Konkani',
    gu: 'gujarati',
    ha: 'Haoussa',
    he: 'hébreu',
    hr: 'croate',
    hsb: 'Haut-sorabe',
    ht: 'Créole haïtien',
    hu: 'hongrois',
    hy: 'arménien',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandais',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latin)',
    ka: 'géorgien',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurde (du Nord)',
    kn: 'Kannada',
    ku: 'Kurde (central)',
    ky: 'Kirghize',
    ln: 'Lingala',
    lo: 'Laotien',
    lt: 'lituanien',
    lug: 'Ganda',
    lv: 'letton',
    lzh: 'Chinois (littéraire)',
    mai: 'Maïthili',
    mg: 'malgache',
    mi: 'Maori',
    mk: 'Macédonien',
    ml: 'Malayalam',
    mnCyrl: 'Mongol (cyrillique)',
    mnMong: 'Mongol (traditionnel)',
    mr: 'Marathi',
    ms: 'malais',
    mt: 'maltais',
    mww: 'Hmong Daw',
    my: 'Myanmar (birman)',
    nb: 'norvégien',
    ne: 'Népalais',
    nso: 'Sesotho à Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Pendjabi',
    prs: 'Dari',
    ps: 'pachtou',
    ptPT: 'Portugais (Portugal)',
    ro: 'roumain',
    run: 'Rundi',
    rw: 'kinyarwanda',
    sd: 'Sindhi',
    si: 'Cinghalais',
    sk: 'slovaque',
    sl: 'slovène',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanais',
    srCyrl: 'Serbe (cyrillique)',
    srLatn: 'serbe (latin)',
    st: 'Sésotho',
    sv: 'suédois',
    sw: 'Swahili',
    ta: 'Tamoul',
    te: 'Télougou',
    ti: 'Tigrinya',
    tk: 'Turkmènes',
    tlhLatn: 'Klingon (latin)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongien',
    tt: 'tatar',
    ty: 'tahitien',
    ug: 'Ouïghour',
    ur: 'Ourdou',
    uz: 'Ouzbek (latin)',
    xh: 'Xhosa',
    yo: 'Yorouba',
    yua: 'Maya du Yucatèque',
    yue: 'Cantonais (traditionnel)',
    zhHans: 'Chinois simplifié',
    zu: 'zoulou',
    auto: 'Détection automatique',
  },
  stripe: {
    contact: 'Contactez-nous à contact@noam.tools pour toute question !',
    fail: {
      title: 'Échec du paiement',
      text1: 'Malheureusement, nous ne pouvons pas traiter votre demande de paiement.',
      text2: 'Veuillez vérifier votre connexion Internet et réessayer.',
      btn: 'Réessayer',
    },
    succ: {
      title: 'Félicitations',
      text1: 'bienvenue à Noam,',
      text2: 'commençons ensemble !',
      btn: 'Faisons un peu de magie',
    },
  },
  welfare: {
    newUser: {
      label: 'Avantages pour les nouveaux utilisateurs :',
      joinBtn: 'Inscrivez-vous maintenant',
      text: 'Seulement 100 places disponibles ! Prenez le vôtre maintenant !',
      remain: "Il n'en reste que 30",
    },
  },
  help: {
    Fixedplugin: 'Plugin fixe',
    Howtouse: 'Comment utiliser',
    try: 'Essayez-le',
    FreeTrialBenefits: "Avantages de l'essai gratuit",
    pin: 'Pin Noam, pour un accès plus facile～～',
    Video: 'Vidéo',
    ParallelTranslation: 'Traduction parallèle',
    WebSummary: 'Résumé Web',
    ChatPDF: 'Chat PDF',
    AquickOverviewOfNoam: 'Un aperçu rapide de Noam',
    WordSelectionTranslation: 'Traduction de sélection de mots',
    HoverTranslation: 'Traduction survolée',
    ImageTranslation: "Traduction d'images",
    InstantTranslation: 'Traduction instantanée',
    VideoTranslation: 'Traduction vidéo',
    benefits: 'Nous avons préparé des avantages pour vous !',
    Free: 'Gratuit',
    Membership: 'Adhésion',
    Translation: 'Traduction',
    PDFparallelTranslation: 'Traduction parallèle PDF',
    ChatPDF2: 'ChatPDF',
    Summary: 'Résumé',
    VideoSummary: 'Résumé vidéo',
    FavoritesManagement: 'Gestion des favoris',
    Focusing: 'Mise au point',
    dayFree: 'Essai gratuit de 3 jours',
    planIsNotExist: "le plan n'existe pas",
    TryItForFree: 'Essayez-le gratuitement',
    startYoutube: 'Commençons par YouTube~',
    startWiki: 'Commençons par Wikimédia~',
    tryNow: 'Essayez-le maintenant ~',
    GoWithYouTube: 'Allez avec YouTube',
    GoWithWikipedia: 'Allez avec Wikipédia',
    NoamHelp: 'Noam peut vous aider à traduire et résumer',
    SkipAllSteps: 'Passer toutes les étapes',
    next: 'Suivant',
    done: 'Fait',
  },
  trialModal: {
    title: 'Cadeau de bienvenue',
    trialText: 'Essai de 7 jours',
    desc1: "Une fois l'essai terminé, des frais annuels de 180 $ vous seront facturés.",
    desc2: 'Annuler à tout moment',
    btn: 'Commencez maintenant (seulement 0,99 $)',
    installBtn: 'Installez maintenant pour recevoir votre cadeau',
    web: 'Traduction parallèle',
    select: 'Traduction de sélection de mots',
    hover: 'Traduction survolée',
    image: "Traduction d'images",
    realtime: 'Traduction instantanée',
    video: 'Traduction vidéo',
    pdf: 'Traduction parallèle PDF',
    chat: 'ChatPDF',
    webSummary: 'Résumé Web',
    videoSummary: 'Résumé vidéo',
    memo: 'Gestion des favoris',
    focus: 'Mise au point',
  },
  privacyCollectionPanel: {
    title: 'Ce site utilise des cookies',
    text: 'Pour améliorer votre expérience, nous souhaitons utiliser des cookies pour personnaliser le contenu et les services.',
    acceptBtn: 'Acceptez tout',
  },
  installTips: {
    text: 'Installez « Noam Browser Plugin » pour débloquer plus de fonctionnalités ! Le référencement croisé web,\n    résumé vidéo Web, et plus encore !',
    btn: 'Essayez-le maintenant',
  },
}

export default TRANSLATION
