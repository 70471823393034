// import { TranslationData } from '../translation'

import { FREE_BENEFITS, PRO_BENEFITS, PRO_V_BENEFITS } from '../../../services/userService/const'

const TRANSLATION = {
  slogen: '最简单好用的AI助手',
  pageHeader: {
    help: '帮助中心',
    contact: '联系我们',
    pricing: '定价',
    signUp: '注册',
    settings: '设置中心',
    logout: '退出登录',
    logoutSucc: '退出成功',
  },
  pageFooter: {
    product: {
      title: 'Products',
      web: '网页翻译和总结',
      pdf: 'PDF翻译',
      video: '视频翻译总结',
      image: '图片翻译',
    },
    resources: {
      title: 'Resources',
    },
    follow: {
      title: 'Follow',
      twitter: 'Twitter',
      facebook: 'Facebook',
    },
    legal: {
      title: 'Legal',
      policy: '隐私政策',
      terms: '使用协议',
    },
  },
  home: {
    getStart: '去添加',
    getStartedForFree: '免费去添加',
    top: {
      title1: '一次点击',
      title2: '一次魔法',
      desc: 'Noam 是一款免费的 AI 浏览器插件，能帮您翻译和总结网页、视频和 PDF 。  加入 Noam，尽享魔法！',
    },
    howItWork: {
      title: '主要特点',
      desc: 'Noam集成AI模型，提供使用者跨语言网页翻译和总结，PDF和图片翻译、视频翻译和总结的智能化体验。',
    },
    PDFTranslate: {
      title: 'PDF翻译',
      desc: '对照式翻译+AI问答 · 更好用的PDF阅读器',
      sub1: {
        title: '双语阅读，翻译更便捷',
        desc: '使用双语阅读，让您在阅读PDF文件时可以轻松查看原文和翻译，提高阅读效率',
      },
      sub2: {
        title: 'AI问答，快速提取理解信息',
        desc: 'AI分析PDF内容建立索引，并总结摘要，当你提问时AI会围绕PDF内容进行解答',
      },
      sub3: {
        title: '高效准确，提升阅读效率',
        desc: 'AI快速、准确地将PDF文件中的文本翻译成目标语言，为您的工作/学习提供高效支持',
      },
    },
    aiAssistant: {
      title: 'AI 助手',
      desc: '让网页翻译更智能、更便捷',
      panel1: {
        title: '网页对照翻译',
        desc: '双语对照翻译可将原文和译文同时呈现在同一页面上，让你能更好地理解文本内容，降低阅读障碍',
      },
      panel2: {
        left: {
          title: '悬停翻译',
          desc: '浏览网页时，只需将鼠标悬停在需要翻译的段落上，即可以高效便捷可获得翻译结果。',
        },
        right: {
          title: '划词翻译',
          desc: '浏览网页时，遇到不认识的单词，可以直接在文本上划词，即可以高效便捷可获得翻译结果。',
        },
      },
      panel3: {
        one: {
          title: '即点即用',
          desc: '随时随地，无论任何场景都可以帮您翻译、总结网页及视频内容；带给你更流畅的体验',
        },
        two: {
          title: '更全面更便捷',
          desc: '多种语言全覆盖，双语种无障碍阅读，一键翻译；带给你无与伦比的体验',
        },
        three: {
          title: 'AI 驱动',
          desc: 'AI 驱动的大模型，确保翻译准确性和连贯性，提升阅读体验 ',
        },
      },
      panel4: {
        tab1: {
          key: '视频翻译',
          title: '视频翻译和总结',
          desc: '你的效率专家，结合 chatgpt 翻译和总结 YouTube 视频内容，节省你的观看时间',
        },
        tab2: {
          key: '图片翻译',
          title: '图片翻译',
          desc: '浏览网页图片时，点击即可精准翻译图片内容，让你的阅读体验更流畅',
        },
        tab3: {
          key: '网页总结',
          title: '网页翻译和总结',
          desc: '你的 AI 助手，快速提取网页关键信息进行总结，提高你的阅读效率',
        },
        tag1: '简单',
        tag2: '高效',
        tag3: '易理解',
      },
    },
    userReviews: {
      title: '用户之声',
      desc: '用户的真实评价',
    },
    lastBlock: {
      desc: '加入我们让你的浏览器更智能',
    },
  },
  login: {
    title: '欢迎使用Noam',
    btn: '使用Google账号登录',
    tips: '登录即同意 ',
    terms: '使用协议 ',
    and: '  和',
    policy: ' 隐私政策',
  },
  pricing: {
    pageTitle: '定价',
    desc: '比较并选择最适合您的产品',
    yearly: '每年',
    monthly: '每月',
    discount: '节省20%',
    preMonth: '每月',
    billedYearly: '按年结算',
    billedMonthly: '按月结算',
    detailedCompare: '套餐对比 ',
    FAQ: '常见问题',
    day: '天',
    month: '月',
    around: '预计',
    free: {
      benefit1: `即时翻译：${FREE_BENEFITS.realTimeCount}次/天`,
      benefit2: '网页翻译&总结',
      benefit3: `${FREE_BENEFITS.imageCount}10张图片翻译`,
      benefit4: '视频翻译&总结',
      benefit5: `${FREE_BENEFITS.pdfUploadCount}份 PDF 翻译`,
      benefit6: `${FREE_BENEFITS.tokenCount} token`,
      recommendText: '免费体验',
      btn: 'Have Fun',
    },
    pro: {
      benefit1: '客服快速支持',
      benefit2: `${PRO_BENEFITS.tokenCount} tokens/月`,
      benefit3: '网页翻译&总结',
      benefit4: `${PRO_BENEFITS.imageCount}张图片翻译`,
      benefit5: '视频翻译&总结',
      benefit6: `${PRO_BENEFITS.pdfUploadCount}份 PDF 翻译`,
      btn: '开始使用',
      recommendText: '大多数人选择',
    },
    proPlus: {
      benefit1: '客服快速支持',
      benefit2: `${PRO_V_BENEFITS.tokenCount} tokens/月`,
      benefit3: '网页翻译&总结',
      benefit4: `${PRO_V_BENEFITS.imageCount}张图片翻译`,
      benefit5: '视频翻译&总结',
      benefit6: `${PRO_V_BENEFITS.pdfUploadCount}份 PDF 翻译`,
      recommendText: '最合适的选择',
    },
    enterprise: {
      benefit1: '客服快速支持',
      benefit2: '多人使用',
      benefit3: '定制解决方案',
      benefit5: '专属客户经理',
      recommendText: '团队计划（≥3人）',
      talkToUs: '联系我们',
      btn: '联系我们',
    },
    web: {
      contrastiveTranslation: '对照翻译',
      instantTranslation: '即时翻译',
      hoverTranslation: '悬停翻译',
      dictionaryTranslation: '划词翻译',
      summary: '网页总结',
    },
    imageTranslation: '图片翻译',
    video: {
      translation: '视频翻译',
      summary: '视频总结',
    },
    pdf: {
      translation: 'PDF 翻译',
      maxPage: 'PDF 最大页数',
      chat: 'PDF 询问次数',
    },
    FQAObj: {
      q1: '可以免费使用吗？',
      a1: 'Noam有免费版本可以直接使用。',
      q2: '是否有不同的会员计划和费用选项？',
      a2: '除免费版外有Pro、Pro+和企业版三种会员计划；可以选择连续包月和连续包年两种付费方式，综合费用连续包年更优惠。',
      q3: '连续包月和连续包年有什么区别？',
      a3: '在功能权益上没有区别，综合费用连续包年低于连续每月；连续包月每月扣费一次，包年每年扣费一次。',
      q4: '如何取消自动续费计划？',
      a4: '进入后台设置页-订阅-订阅管理取消您的续费计划。',
    },
  },
  upgradeModal: {
    title: '提醒',
    limitAlertStr: '您的免费计划额度已用完，推荐您升级计划继续体验~',
    upgradeAlertStr: '您的套餐内计划额度已用完，推荐您升级计划继续体验~',
    btn: '立即升级',
    more: '更多计划',
    contactStr: '请等待下一次重置或通过 contact@noam.tools 联系我们。',
    upload: {
      free: `免费PDF上传文件数已达上限`,
      pro: `本月PDF上传文件数已达上限`,
    },
    chat: {
      free: `免费PDF询问次数已达上限`,
      pro: `本月PDF询问次数已达上限`,
    },
    pageLimit: {
      free: `该文件页数超过上限`,
    },
  },
  buyModal: {
    title: '订阅计划',
    freeTrial: '3天免费试用',
    selectLabel: '选择一个计划',
    btn: '免费使用',
    yearLabel: '每年',
    monthLabel: '每月',
    afterFree: '试用结束后',
  },
  privacy: {
    title: '隐私政策',
    updateTime: '更新时间: March 5, 2024',
    block1:
      '我们 Noam （以"Noam"，"我们"，"我们的"或"我们"共同指代）尊重你的隐私，并坚决承诺保护我们从你处获取或关于你的任何信息。本隐私政策描述了我们对我们从您使用我们的网站、应用和服务（统称为“服务”）收集或者关于您的个人信息进行的实践。本隐私政策不适用于我们代表我们的商业产品客户处理的内容，例如我们的 API。我们使用该数据受到我们的客户协议的约束，该协议涵盖了对这些产品的访问和使用。',
    subTitle1: '我们收集的个人信息',
    block2: '我们如下收集与您相关的个人信息（“个人信息”）：',
    block3:
      '你提供的个人信息：如果您创建了一个账户来使用我们的服务或与我们沟通，我们将收集个人信息，如下：',
    subBlock1:
      '账户信息：当你在我们平台上创建一个账户时，我们会收集与你的账户相关的信息，包括你的姓名、联系信息、账户凭证、支付卡信息以及交易历史（统称为“账户信息”）。',
    subBlock2:
      '用户内容：当您使用我们的服务时，我们会收集您提供给我们服务的输入，文件上传，或反馈中的个人信息（“内容”）。',
    subBlock3:
      '通信信息：如果您与我们发送消息，我们将收集您的姓名、联系方式以及您发送的消息的内容（“通信信息”）。',
    subBlock4:
      '社交媒体信息：我们在Instagram, Facebook, Medium, Twitter, YouTube 和 LinkedIn 等社交媒体网站上有页面。当你与我们的社交媒体页面互动时，我们将收集你选择向我们提供的个人信息，比如你的联系细节（统称为“社交信息”）。此外，托管我们社交媒体页面的公司可能会向我们提供关于我们社交媒体活动的汇总信息和分析。',
    block4:
      '我们从您使用服务中自动接收的个人信息：当您访问、使用或与服务互动时，我们会收到有关您访问、使用或互动的以下信息（“技术信息”）:',
    subBlock5:
      '日志数据：您的浏览器在使用我们的服务时自动发送的信息。日志数据包括您的 Internet 协议地址，浏览器类型和设置，您的请求的日期和时间，以及您如何与我们的网站互动。',
    subBlock6:
      '使用数据：我们可能会自动收集关于您对服务的使用的信息，如您查看或互动的内容类型，您使用的特性以及您采取的行动，以及您的时区，国家，访问的日期和时间，用户代理和版本，计算机或移动设备类型，和您的计算机连接等。',
    subBlock7:
      '设备信息：包括设备的名称，操作系统，设备标识符和您正在使用的浏览器。收集的信息可能依赖于您使用的设备类型和其设置。',
    subBlock8:
      'Cookies：我们使用 Cookies 来运营和管理我们的服务，并改进您的体验。"Cookie"是一个由您访问的网站发送到您的浏览器的信息。您可以设置您的浏览器接受所有 Cookies，拒绝所有 Cookies，或者在 Cookie 被提供时通知您，这样您可以每次决定是否接受它。然而，拒绝 Cookie 在某些情况下可能阻止您使用，或者负面影响网站或某些区域或功能的显示或功能。更多关于 Cookies 的详细信息，请访问 All About Cookies。',
    subBlock9:
      '分析：我们可能会使用各种在线分析产品，这些产品使用 Cookies 来帮助我们分析用户如何使用我们的服务，并在您使用服务时提高您的体验。',
    subTitle2: '我们如何使用个人信息',
    block5: '我们可能出于以下目的使用个人信息：',
    subBlock10: '提供，管理，维护和/或分析服务；',
    subBlock26: '改善我们的服务并进行研究；',
    subBlock11: '与您沟通；',
    subBlock12: '开发新的项目和服务；',
    subBlock13: '防止欺诈，刑事犯罪或滥用我们的服务，并保护我们的IT系统，架构和网络的安全；',
    subBlock14: '进行商业转让；',
    subBlock15:
      '遵守法律义务和法律程序，保护我们、我们的附属公司，您或其他第三方的权利，隐私，安全或财产。',
    block6:
      '汇总或去标识化的信息：我们可能会汇总或去标识个人信息，以免再被用于识别您，并使用此类信息来分析我们的服务的效果，改善并添加服务的功能，进行研究以及其他类似的目的。此外，有时，我们可能会分析我们服务用户的一般行为和特性，并向第三方分享汇总的信息，发布此类汇总信息或使此类汇总信息普遍可用。我们可能通过服务，通过 Cookies，以及通过本隐私政策中描述的其他方式收集汇总信息。我们将以匿名或去标识化的形式维护和使用去标识化的信息，且我们不会尝试重新识别该信息，除非法律要求。',
    subTitle3: '个人信息的披露',
    block7:
      '在某些情况下，我们可能会在不进一步通知您的情况下，除非法律要求，向第三方提供您的个人信息：',
    subBlock16:
      '供应商和服务提供商：为了帮助我们满足商业运营需求并执行某些服务和功能，我们可能向供应商和服务提供商提供个人信息，包括托管服务提供商，云服务提供商，以及其他信息技术服务提供商，电子邮件通信软件，以及网络分析服务提供商等等。按照我们的指示，这些合作方将仅在为我们执行职务的过程中访问、处理或存储个人信息。',
    subBlock17:
      '商业转让：如果我们参与战略交易，重组，破产，破产接管，或服务过渡到另一提供者（统称为“交易”），你的个人信息和其他信息可能会在尽职调查过程中与交易对手和协助交易的其他方分享，并作为交易的一部分与其他资产一同转让给一个继任者或联属公司。',
    subBlock18:
      '法律要求：我们可能会与政府当局，行业同行，或其他第三方分享您的个人信息，包括您与我们的服务的交互信息，（1）如果法律要求或者我们坚信这样的行动是符合法律义务所必需的；（2）保护和捍卫我们的权利或财产，（3）如果我们单方面确定存在违反我们的条款，政策，或者法律的情况；（4）检测或预防欺诈或其他非法活动；（5）保护我们的产品，员工，或者用户，或者公共的安全，安全，完整性；或者（vi）保护对法律责任的抵御。',
    subTitle4: '您的权利',
    block8:
      '根据地理位置，欧洲经济区，英国和全球的个人可能拥有关于他们的个人信息的一些法定权利。例如，你可能有权：',
    subBlock19: '访问你的个人信息和关于如何处理它的信息。',
    subBlock20: '从我们的记录中删除你的个人信息。',
    subBlock21: '更正或更新你的个人信息。',
    subBlock22: '将你的个人信息转移到第三方（数据可携带权）。',
    subBlock23: '限制我们如何处理你的个人信息。',
    subBlock24: '撤回你的同意——在我们依赖同意作为处理的法律依据时，你可以随时撤回你的同意。',
    subBlock25: '反对我们如何处理你的个人信息。',
    subTitle5: '其他网站的链接',
    block9:
      '该服务可能包含指向Noam没有运营或控制的其他网站的链接，包括社交媒体服务（“第三方网站”）。您与第三方网站分享的信息将受第三方网站特定隐私政策和服务条款的治理，而不受本隐私政策的保护。我们提供这些链接，并不暗示我们认可或已经审查这些网站。请直接联系第三方网站获取他们的隐私政策和条款的信息。',
    subTitle6: '安全和保留信息',
    block10:
      '我们实施商业上合理的技术，行政，和组织措施，以保护在线和离线的个人信息免受丢失，误用，并未经授权的访问，披露，改变，或敌对行为。然而，没有任何互联网或电子邮件传输能够完全保证安全或无误。特别是，发送给我们或者从我们发送的电子邮件可能不是安全的。所以，你在决定通过服务或电子邮件向我们发送什么信息时应该特别小心。此外，我们不对服务中的任何隐私设置或安全措施或第三方网站的安全设置或安全措施的任何阻挡负责。我们会根据我们提供你服务，或因其他合法商业目的比如解决纠纷，安全和安全原因，或遵守我们的法律义务的需要来保留你的个人信息。我们保留个人信息的时间长度将取决于很多因素，比如信息的数量，性质，敏感度，未经授权使用或披露的潜在风险，我们处理信息的目的，以及其他具有法律效力的。',
    subTitle7: '隐私政策的修改',
    block11:
      '我们可能不时更新本隐私政策。当我们这样做时，我们会在本页面上发布一个更新的版本，除非其他类型的通知被适用的法律所要求。',
    subTitle8: '如何联系我们',
    block12: '如果您在本隐私策略中还有任何未解答的问题或疑虑，请联系我们的客服。邮箱:',
  },

  terms: {
    title: '使用条款',
    updateTime: '更新时间: March 15, 2024',
    block1: '感谢您使用 Noam！',
    block2:
      '本使用条款适用于您使用 Noam 的服务，包括我们的应用程序编程接口、软件、工具、开发者服务、数据、文档和网站（以下统称为“服务”）。通过使用我们的服务，您同意遵守这些条款。我们的隐私政策说明了我们如何收集和使用个人信息。',
    subTitle1: ' 注册和访问',
    block3:
      '如果您未满18岁，您必须获得您父母或法定监护人的许可才能使用本服务。如果您代表其他人或实体使用本服务，您必须有权代表他们接受这些条款。您必须提供准确和完整的信息以注册帐户。您不得将您的访问凭证或帐户提供给组织外的其他人，您对使用您的凭证进行的所有活动负责。',
    subTitle2: '使用要求',
    block4:
      '(a) 使用服务。 您可以根据这些条款访问，我们授予您非排他性的使用服务的权利。在使用服务时，您将遵守这些条款和所有适用法律。我们及我们的关联公司拥有服务的所有权利、标题和利益。',
    block5:
      '(b) 反馈意见。 我们欢迎反馈、评论、想法、提案和改进建议。如果您提供了任何此类内容，我们可以毫无限制地使用，而无需向您支付任何补偿。',
    block6:
      '(c) 限制。 您不得：(1) 以侵犯、侵占或违反任何人权利的方式使用服务；(2) 反汇编、反编译、解编译、翻译或以其他方式尝试发现服务的模型、算法和系统的源代码或基础组件（除非此类限制违反适用法律）；(3) 使用服务的输出来开发与Noam 竞争的模型；(4) 除非通过 API 允许，否则使用任何自动化或编程方法从服务中提取数据或输出，包括爬取、网络收集或网络数据提取；(5) 表示服务的输出是人工生成的，而实际上并非如此，或以其他方式违反我们的使用政策；(6) 未经我们事先同意即购买、销售或转让 API 密钥；或者 (7) 向我们发送任何13岁以下儿童或适用年龄的数字同意年龄的个人信息。您将遵守我们文档中的任何速率限制和其他要求。',
    block7:
      '(d) 第三方服务。 您在与服务相关联的任何第三方软件、服务或其他产品受其自身的条款约束，我们对第三方产品不承担责任。',
    subTitle3: '内容',
    block8:
      '(a) 您的内容。 您可以向服务提供输入（“输入”），并根据输入生成和返回的输出（“输出”）（统称为“内容”）。在双方之间，并在适用法律允许的范围内，您拥有所有输入。Noam 可能会使用内容来提供和维护服务、遵守适用法律并执行我们的政策。您对内容负有责任，包括确保它不违反任何适用法律或这些条款。',
    block9:
      '(b) 准确性。 人工智能和机器学习是正在迅速发展的研究领域。我们不断努力改进我们的服务，以使其更准确、可靠、安全和有益。鉴于机器学习的概率性质，使用我们的服务可能会在某些情况下导致不正确的输出，这些输出可能无法准确反映真实的人物、地点或事实。您应该根据您的用例适当地评估任何输出的准确性，包括通过人工审核输出的方式。',
    subTitle4: '费用和支付',
    block10:
      '(a) 费用和计费。 您将根据适用定价页面上的价格和条款，或者根据我们之间书面协议的价格支付所有费用（“费用”）。即使我们已经发出发票或收到付款，我们有权纠正定价错误或失误。您将提供完整准确的结算信息，包括一个有效和授权的付款方式。我们将根据约定的周期性基础对您的付款方式进行扣费，但我们可能会合理地更改扣费的日期。您授权 Noam 及其关联公司以及第三方支付处理器从您的付款方式扣款。如果您的付款未能完成，我们将向您提供书面通知，并可能暂停访问服务，直至收到付款。除本协议另有规定外，付款不可退还。',
    block11:
      '(b) 税费。 除非另有说明，费用不包括联邦、州、地方和外国税款、关税和类似的评估（“税款”）。您需要支付与您的购买相关的所有税款，不包括基于我们净收入的税款，我们可能会为此向您开具发票。您同意及时支付此类税款，并向我们提供显示付款的法律充分隐私声明和获取必要的同意，以便处理此类数据，并向我们保证您根据适用法律处理此类数据。',
    block12:
      '(c) 价格变动。我们可能通过向您的帐户和/或我们的网站发布通知来更改我们的价格。价格增加将在发布后立即生效。任何价格变更将在变更生效日期后立即适用于您帐户的费用。',
    block13:
      '(d) 争议和逾期付款。如果您想对任何费用或税款提出异议，请在争议发票日期后的三十（30）天内联系 contact@noam.tools',
    block14:
      '(e) 免费。您不能创建多个帐户以获得免费提供的信用额度。如果我们认为您不善意使用免费，我们可能向您收取标准费用或停止提供服务的访问。',
    subTitle5: ' 机密性、安全性和数据保护',
    block15:
      '(a) 机密性。 您可能会访问 Noam的机密信息。您只能根据这些条款的规定使用机密信息，以便在这些条款下使用服务。您不得向任何第三方披露机密信息，并且您将以与保护您自己类似性质的机密信息相同的方式保护机密信息，至少要合理小心。机密信息是 Noam 指定为机密或在相关情况下应合理被视为机密的非公开信息，包括软件、规格和其他非公开的业务信息。机密信息不包括以下信息：(1) 在您无过错的情况下变得普遍可供公众访问；(2) 在根据这些条款收到它时，您已经具有不受任何保密义务的情况下拥有的信息；(3) 您在没有任何保密义务的情况下被第三方合法地披露的信息；或者 (4) 您在不使用机密信息的情况下独立开发的信息。在法律或法院或其他政府机构的有效命令要求的情况下，您可以披露机密信息，但必须事先以合理的书面方式通知 Noam，并在可能的情况下采取合理的努力限制披露的范围，包括协助我们对抗披露要求。',
    block16:
      '(b) 安全性。 您必须实施合理和适当的措施，旨在帮助保障您对服务的访问和使用。如果您发现与您使用服务有关的任何漏洞或违规行为，您必须迅速联系 Noam 并提供漏洞或违规行为的详细信息。',
    block17:
      '(c) 个人数据的处理。 如果您使用服务来处理个人数据，您必须提供合法充分的隐私声明并获得必要的处理此类数据的同意，并向我们保证您正在按照适用法律处理此类数据。',
    subTitle6: ' 期限和终止',
    block18:
      '(a) 终止；暂停。 这些条款自您首次使用服务起生效，并将持续有效，直至终止。您可以随时基于任何原因终止这些条款，方法是停止使用服务和内容。我们可以基于任何原因提前通知来终止这些条款。如果您严重违反第2节（使用要求）、第5节（机密性、安全性和数据保护）、第8节（争议解决）或第9节（一般条款），或与我们无法控制的第三方技术提供者的关系发生变化，或为了遵守法律或政府要求，我们可以立即通过向您通知的方式终止这些条款。如果您不遵守这些条款，或者您的使用对我们或任何第三方构成安全风险，或者我们怀疑您的使用是欺诈性的或可能使我们或任何第三方承担责任，我们可能会暂停您对服务的访问。',
    block19:
      '(b) 终止的影响。 终止后，您将停止使用服务，并将迅速归还或按照我们的指示销毁任何机密信息。根据其性质应继续有效或到期的这些条款，应继续有效，包括但不限于第3节和第5-9节。',
    subTitle7: '赔偿；免责声明；责任限制',
    block20:
      '(a) 赔偿。 您将保卫、补偿并使我们、我们的关联公司和我们的人员免受因您使用服务、包括您的内容、您在与服务相关的产品或服务，以及您违反这些条款或违反适用法律而产生的任何索赔、损失和费用（包括律师费）的影响。',
    block21:
      '(b) 免责声明。 服务按“原样”提供。除法律禁止的范围外，我们及我们的关联公司和许可方不对服务提供任何形式的保证（明示、默示、法定或其他），并且对服务不作任何保证，包括但不限于对适销性、适用于特定用途、满意品质、非侵权和安宁享受的保证，以及任何与交易或贸易使用相关的保证。我们不保证服务将不间断、准确或无错误，或者任何内容将安全、不丢失或不被更改。',
    block22:
      '(c) 责任限制。 我们、我们的关联公司或许可方中的任何一方都不对任何间接、附带、特殊、间接或惩戒性损害承担责任，包括利润损失、商誉损失、使用损失、数据损失或其他损失，即使我们已被告知可能发生此类损害。在这些条款下，我们的总责任不得超过您在发生索赔的12个月前支付给服务的费用或一百美元（$100），以两者中较大的为准。本节中的限制仅适用于适用法律所允许的最大范围。',
    subTitle8: '法律和司法管辖',
    block23:
      '本条款和您对服务的使用受美国法律管辖，但美国法律冲突规则除外。您对应用程序的使用还可能受到其他地方、州、国家或国际法律的约束。与这些条款有关的任何法律诉讼或程序将仅在美国的法院提起，并且您同意接受该法院的个人管辖权。',
    subTitle9: ' 一般条款',
    block24:
      '(a) 双方关系。 Noam 和您是独立承包商，双方都没有权力在未经对方事先书面同意的情况下代表对方或为对方承担义务。',
    block25:
      '(b) 品牌使用。 您不得未经我们事先书面同意使用 Noam 或其任何关联公司的名称、标志或商标。',
    subTitle10: '可分性',
    block26: '如果这些条款的任何条款被判定为无效、非法或不可执行，其余条款仍然完全有效。',
  },
  contact: {
    title: '联系我们',
    block1: '如需定制化服务或有任何问题和建议，请通过 contact@noam.tools 联系，我们会马上回复。',
    block2: '通过在谷歌应用扩展商店 ★★★★★ 上评分来支持我们。',
    block3: '通过分享此扩展帮助他人：',
  },
  pdf: {
    toast1: 'PDF 正在加载中，请稍后再试',
    toast2: 'PDF 内容过多，暂不支持对话',
    toast3: '正在下载,请下载完成后再尝试',
    shareMsg1: '分享链接生成成功',
    shareMsg2: '分享内容更新成功',
    shareMsg3: '分享链接生成失败，请稍后重试或联系客服',
    shareMsg4: '分享内容更新失败，请稍后重试或联系客服',
    shareBtnText1: '生成分享链接',
    shareBtnText2: '更新分享内容',
    uploading: '上传中...',
    uploadingMsg: '初次上传需要一定时间进行解析，请您耐心等候～',
    cancelUpload: '取消上传',
    uploadErr: '文件上传失败，请稍后重试或联系客服',
    uploadMsg1: '请将文件拖拽至此',
    uploadMsg2: '仅支持PDF格式、文件大小不超过100M',
    uploadMsg3: '点击选择或拖拽文件至此上传',
    searchPlaceholder: '请输入要搜索的内容',
    collectionEmpty: '暂无收藏内容～',
    kw: '关键内容',
    video: '视频',
    link: '链接',
    dataLoading: '正在加载数据...',
    freeBannerTips: '免费PDF上传次数已用完',
    vipBannerTips: '本月PDF上传次数已用完',
    freeBannerBtn: '开通会员，立享更多',
    vipBannerBtn: '升级会员，立享更多',
    dialogTitle: '提示',
    dialogContent: '删除后，此内容不可恢复，是否删除？',
    okBtn: '删除',
    cancelBtn: '取消',
    unLoginToast: '请登录后使用该功能',
    parseErrTips: '无法解析该文件',
    new: '新文件',
    parseErrDialogContent: '当前PDF链接不支持直接翻译，请将原链接PDF下载后进行翻译',
    okBtn2: '确认',
    notPdf: '仅支持 PDF 文件',
    uploadingMsg1: `文件上传中，请稍后重试`,
    uploadingMsg2: `您当前有文件正在转换中，请稍后重试`,
    uploadingMsg3: '转换进度查询失败，请稍后重试或联系客服',
    uploadingMsg4: 'Noam无法直接获取您的本地文件，请手动上传 PDF 进行翻译',
    uploadingMsg5: '获取文件url失败，请稍后重试或联系客服',
    fileMsg: '当前文件体积：',
    fileMsg2: '请上传小于 100M 的文件',
    fileErrMsg: '文件读取失败，请稍后重试或联系客服',
    parseFileToast: '文件解析失败，请稍后重试或联系客服',
    noId: '文件ID不存在',
    autoDetect: '自动检测',
    hasTransTask: '有正在翻译的任务,请稍后执行',
    transSucc: '翻译成功！',
    entry: 'PDF列表',
    listError: 'PDF 列表加载失败，请稍后重试或联系客服',
    collectionTab: '收藏管理',
    pdfLoading: '正在加载文件...',
    installTips: '安装Noam小插件，可以翻译更多PDF文件哦，还可以体验流畅的网页翻译：',
    webTrans: '全文对照翻译',
    hoverTrans: '悬停段落翻译',
    selectTrans: '划词翻译',
    installText: '一站式满足日常所有翻译需求',
    start: '开始免费体验',
    shareTips: 'PDF 翻译完成，快去分享给好友吧～',
    download: {
      loading: '保存中...',
      btn: '立即保存',
      trans: '仅译文',
      both: '双语对照',
      download: '下载文件',
      msg1: '当前文档部分译文比原文更长，下载后可能会出现文字重叠的情况，推荐在网页进行阅读。',
      msg2: '1、当前 PDF 未完全翻译，请翻译完成后再下载。',
      msg3: '2、当前文档部分译文比原文更长，下载后可能会出现文字重叠的情况，推荐在网页进行阅读。',
      msg4: '当前有任务正在翻译，请翻译完成后再试',
      fileName: '文件名称：',
      fileType: '下载文件类型：',
      transContinue: '翻译剩余内容',
      save: '保存至本地',
      downloadMsg1: 'PDF 正在翻译中，进度：',
      downloadMsg2: '预计需要',
      minute: '分钟...',
      downloadSucc: 'PDF 下载完成，快点击保存吧',
      desc:'【保存至本地】下载的译文为图片；【打印保存】下载的译文保留原格式支持复制',
      savePrint: '打印保存',
      loading2: '准备中...',
      success:'下载成功', 
      cancel:'关闭弹窗会取消正在下载的PDF文件，确认要取消吗？',
      downloadTip:'PDF下载中，下载过程中请勿关闭弹窗，以免下载失败。',
      progress:'PDF正在翻译，进度: {{progress}}, 预计时间 {{minutes}} 分钟...',
      error:'保存至本地失败，请尝试打印保存'
    },
    guide: {
      title1: '「对照式翻译+AI问答」',
      title2: '更好用的PDF阅读器',
      text1: '双语阅读，翻译更便捷',
      text2: '使用双语阅读，让您在阅读PDF文件时可以轻松查看原文和翻译，提高阅读效率',
      text3: 'AI问答，快速提取理解信息',
      text4: 'AI分析PDF内容建立索引，并总结摘要，当你提问时AI会围绕PDF内容进行解答',
      text5: '高效准确，提升阅读效率',
      text6: 'AI快速、准确地将PDF文件中的文本翻译成目标语言，为您的工作/学习提供高效支持',
      placeholder: '请填写 PDF 链接',
      start: '开始翻译',
      toast: '请输入有效的在线 pdf 链接，以 .pdf 结尾',
    },
    original: '原文阅读',
    parallel: '对照阅读',
    trans: '译文阅读',
    initial: '翻译PDF',
    thumbnail: '目录',
    downloadBtn: '下载',
    shareBtn: '分享',
    uploadBtn: '上传',
    installText2: '安装小插件翻译其他PDF',
    chatPdf: {
      clearSucc: '清除成功',
      clear: '清除',
      clearContent: '清除后，会新建对话，本次会话内容不会保存，确认要清除吗？',
      clearDialogTitle: '提示',
      cancel: '取消',
      chatTitle: '欢迎使用Noam，您的PDF摘要已准备好：',
      summaryTitle: '你可能感兴趣的问题：',
      placeholder: '输入任何你想问的问题，AI会基于该PDF进行回答',
      copy: '复制',
      copySucc: '复制成功',
      historyTitle: '最近阅读',
      expand: '收起',
      noDataDesc: '暂无历史记录～',
      noMore: '没有更多了～',
      Search: '请输入要搜索的内容',
      delete: '删除',
      loading: '正在加载中...',
      deleteTitle: '提示',
      deleteContent: '删除后，此PDF不可恢复，是否删除？',
      deleteOk: '删除',
      deleteCancel: '取消',
      deleteSuccess: '删除成功',
      listError: '阅读列表加载失败，请稍后重试或联系客服',
    },
    shareModal: {
      shareSetting: '分享内容设置',
      shareText: '分享',
      text1: '设置分享内容',
      text2: '生成分享链接',
      range: '分享范围:',
      custom: '自定义：',
      page: '页',
      sourceLang: '源语言:',
      target: '翻译语言:',
      errMsg: '当前分享范围内容未完全翻译，请翻译后再分享～',
      pageNuErr: '请输入正确的页码值',
      trans: '翻译',
      cancel: '取消分享',
      update: '更新分享链接',
      gen: '生成分享链接',
      shareTips: '将翻译好的PDF链接分享给好友对照阅读：',
      copySucc: '复制链接成功',
      copy: '复制',
      settings: '分享设置：',
      modify: '修改',
      translateTips: '提示：如翻译时间较长，可关闭弹窗等翻译完成后再分享哦',
      translating: 'PDF翻译中...',
      remain: '剩余',
      remain1: '预计共需要',
      totalPage: '共1页',
      search: '请输入内容',
      notSupport: '暂不支持该语言',
    },
    toolbar: {
      Adjust: '手动调整',
      autoFit: '自动适配',
    },
    memoTotalCount: '共1个内容',
    dragTipModal: {
      press: '按住',
      black: '空格',
      leftKey: '鼠标左键',
      tips: '可以拖拽查看哦～',
      ok: '我知道了',
    },
  },
  locale: {
    zh: '中文',
    en: '英语',
    es: '西班牙语',
    ru: '俄语',
    pt: '葡萄牙语（巴西）',
    id: '印尼语',
    cs: '捷克语',
    de: '德语',
    fr: '法语',
    it: '意大利语',
    nl: '荷兰语',
    pl: '波兰语',
    vi: '越南语',
    tr: '土耳其语',
    uk: '乌克兰语',
    zhHant: '中文（繁体）',
    ja: '日语',
    ko: '韩语',
    ar: '阿拉伯语',
    fa: '波斯语',
    th: '泰语',
    hi: '印地语',
    noSearchLang: '暂不支持该语言',
    placeholder: '请输入搜索内容',
    af: '南非荷兰语',
    am: '阿姆哈拉语',
    as: '阿萨姆语',
    az: '阿塞拜疆语',
    ba: '巴什基尔语',
    bg: '保加利亚语',
    bn: '孟加拉语',
    bo: '藏语',
    bs: '波斯尼亚语',
    ca: '加泰罗尼亚语',
    cy: '威尔士语',
    da: '丹麦语',
    dsb: '下索布语',
    dv: '迪维希语',
    el: '希腊语',
    et: '爱沙尼亚语',
    eu: '巴斯克语',
    fi: '芬兰语',
    fil: '菲律宾语',
    fj: '斐济语',
    frCA: '法语（加拿大）',
    ga: '爱尔兰语',
    gl: '加利西亚语',
    gom: '康卡尼语',
    gu: '古吉拉特语',
    ha: '豪萨语',
    he: '希伯来语',
    hr: '克罗地亚语',
    hsb: '上索布语',
    ht: '海地克里奥尔语',
    hu: '匈牙利语',
    hy: '亚美尼亚语',
    ig: '伊博语',
    ikt: '伊努伊纳克图特语',
    is: '冰岛语',
    iu: '因纽特语',
    iuLatn: '因纽特语（拉丁语）',
    ka: '格鲁吉亚语',
    kk: '哈萨克语',
    km: '高棉语',
    kmr: '北库尔德语',
    kn: '卡纳达语',
    ku: '中央库尔德语',
    ky: '吉尔吉斯语',
    ln: '林加拉语',
    lo: '老挝语',
    lt: '立陶宛语',
    lug: '干达语',
    lv: '拉脱维亚语',
    lzh: '文言文',
    mai: '迈蒂利语',
    mg: '马拉加斯语',
    mi: '毛利语',
    mk: '马其顿语',
    ml: '马拉雅拉姆语',
    mnCyrl: '蒙古语（西里尔字母）',
    mnMong: '蒙古语（繁体）',
    mr: '马拉地语',
    ms: '马来语',
    mt: '马耳他语',
    mww: '白苗语',
    my: '缅甸语',
    nb: '挪威语',
    ne: '尼泊尔语',
    nso: '北索托语',
    nya: '尼扬贾语',
    or: '奥里亚语',
    otq: '克雷塔罗奥托米语',
    pa: '旁遮普语',
    prs: '达里语',
    ps: '普什图语',
    ptPT: '葡萄牙语（葡萄牙）',
    ro: '罗马尼亚语',
    run: '基隆迪语',
    rw: '基尼亚万达语',
    sd: '信德语',
    si: '僧伽罗语',
    sk: '斯洛伐克语',
    sl: '斯洛文尼亚语',
    sm: '萨摩亚语',
    sn: '修纳语',
    so: '索马里语',
    sq: '阿尔巴尼亚语',
    srCyrl: '塞尔维亚语（西里尔字母）',
    srLatn: '塞尔维亚语（拉丁字母）',
    st: '塞索托语',
    sv: '瑞典语',
    sw: '斯瓦希里语',
    ta: '泰米尔语',
    te: '泰卢固语',
    ti: '提格雷语',
    tk: '土库曼语',
    tlhLatn: '克林贡语（拉丁字母）',
    tlhPiqd: '克林贡语（pIqaD）',
    tn: '茨瓦纳语',
    to: '汤加语',
    tt: '鞑靼语',
    ty: '塔希提语',
    ug: '维吾尔语',
    ur: '乌尔都语',
    uz: '乌兹别克语（拉丁字母）',
    xh: '科萨语',
    yo: '约鲁巴语',
    yua: '尤卡坦玛雅语',
    yue: '粤语（繁体）',
    zhHans: '简体中文',
    zu: '祖鲁语',
    auto: '自动检测',
  },
  stripe: {
    contact: '如有任何问题，请通过 contact@noam.tools 与我们联系！',
    fail: {
      title: '支付失败',
      text1: `很遗憾，我们无法处理您的付款请求。`,
      text2: '请检查您的网络连接并重试。',
      btn: '重试',
    },
    succ: {
      title: '恭喜',
      text1: '欢迎使用 Noam，',
      text2: `让我们一起开始使用吧！`,
      btn: '开始使用',
    },
  },

  welfare: {
    newUser: {
      label: '新人福利：',
      joinBtn: '快来参与',
      text: '限时优惠，共100名名额，赶快来抢吧！',
      remain: '仅剩 30 份',
    },
  },
  help: {
    Fixedplugin: '固定插件',
    Howtouse: '如何使用',
    try: '试一试吧',
    FreeTrialBenefits: '免费试用福利',
    pin: '固定 Noam，方便访问～～',
    Video: '视频',
    ParallelTranslation: '对照翻译',
    WebSummary: '网页总结',
    ChatPDF: 'Chat PDF',
    AquickOverviewOfNoam: 'Noam的快速概述',
    WordSelectionTranslation: '划词翻译',
    HoverTranslation: '悬浮翻译',
    ImageTranslation: '图片翻译',
    InstantTranslation: '即时翻译',
    VideoTranslation: '视频翻译',
    benefits: '我们为您准备了福利!',
    Free: '免费',
    Membership: '会员',
    Translation: '翻译',
    PDFparallelTranslation: 'PDF对照翻译',
    ChatPDF2: 'ChatPDF',
    Summary: '总结',
    VideoSummary: '视频总结',
    FavoritesManagement: '收藏管理',
    Focusing: '聚焦',
    dayFree: '3天免费试用',
    planIsNotExist: '计划不存在',
    TryItForFree: '免费试用',
    startYoutube: `让我们从YouTube开始吧~`,
    startWiki: `让我们从维基百科开始吧`,
    tryNow: '现在就试试吧~',
    GoWithYouTube: '使用YouTube',
    GoWithWikipedia: '使用Wikipedia',
    NoamHelp: 'Noam可以帮你翻译和总结',
    SkipAllSteps: '跳过全部步骤',
    next: '下一个',
    done: '完成',
  },
  trialModal: {
    title: 'Welcome gift',
    trialText: '7天试用',
    desc1: '试用期到期之后将扣除$180的年费',
    desc2: '随时取消',
    btn: '立即开始（仅 $0.99）',
    installBtn: '立即安装领取礼物',
    web: '多语种网页对照翻译',
    select: '划词翻译',
    image: '图片翻译',
    video: '视频字幕无损翻译',
    pdf: 'PDF翻译',
    hover: '悬停翻译',
    realtime: '即时翻译',
    chat: 'ChatPDF',
    webSummary: '网页总结',
    videoSummary: '视频总结',
    memo: '收藏管理',
    focus: '信息聚焦',
  },
  privacyCollectionPanel: {
    title: '本网站使用 cookie',
    text: `为了改善您的体验，我们希望使用 cookie 来个性化内容和服务。`,
    acceptBtn: '全部同意',
  },
  installTips: {
    text: `安装「Noam浏览器插件」更多功能等你解锁！网页对照翻译、网页/视频总结等`,
    btn: '立即解锁',
  },
}
export default TRANSLATION
