const TRANSLATION = {
  slogen: 'O assistente de IA mais fácil',
  pageHeader: {
    help: 'Central de Ajuda',
    contact: 'Contate-nos',
    pricing: 'Preços',
    signUp: 'Conecte-se',
    settings: 'Configurações',
    logout: 'Sair',
    logoutSucc: 'Logout bem-sucedido',
  },
  pageFooter: {
    product: {
      title: 'Produtos',
      web: 'Tradução e Resumo da Web',
      pdf: 'Tradução de PDF',
      video: 'Tradução e resumo de vídeo',
      image: 'Tradução de imagens',
    },
    resources: { title: 'Recursos' },
    follow: { title: 'Seguir', twitter: 'Twitter', facebook: 'Facebook' },
    legal: { title: 'Jurídico', policy: 'política de Privacidade', terms: 'Termos de Serviço' },
  },
  home: {
    getStart: 'Comece',
    getStartedForFree: 'Comece gratuitamente',
    top: {
      desc: 'Noam é uma extensão gratuita de navegador de IA que ajuda a traduzir páginas da web, vídeos e conteúdo PDF resumidos.\n      Junte-se a Noam e aproveite a magia!',
      title2: 'Uma magia',
      title1: 'Um clique',
    },
    howItWork: {
      title: 'Então, como funciona',
      desc: 'Noam integra modelos de IA para fornecer aos usuários uma experiência inteligente para tradução e resumo de páginas da web em vários idiomas, tradução de PDF e imagens, tradução e resumo de vídeos',
    },
    PDFTranslate: {
      title: 'Tradução de PDF',
      desc: 'Tradução Bilíngue + Chat PDF',
      sub1: {
        title: 'Leitura bilíngue',
        desc: 'Leitura bilíngue, para que você possa comparar o texto original e a tradução durante a leitura para melhorar a eficiência da leitura',
      },
      sub2: {
        title: 'Bate-papo em PDF',
        desc: 'A IA analisa e resume e pode responder às suas perguntas com base no conteúdo do PDF',
      },
      sub3: {
        title: 'Precisão e eficiência',
        desc: 'PDF alimentado por IA para traduções mais precisas e eficientes',
      },
    },
    aiAssistant: {
      title: 'Assistente de IA',
      desc: 'Tornando a tradução da Web mais inteligente e conveniente',
      panel1: {
        title: 'Tradução Bilíngue',
        desc: 'As traduções bilíngues com referências cruzadas ajudam você a comparar o texto original com a tradução para que você possa entender melhor e melhorar sua eficiência de leitura.',
      },
      panel2: {
        left: {
          title: 'Tradução flutuante',
          desc: 'Ao navegar em uma página da web, passe o mouse diretamente sobre o conteúdo que precisa ser traduzido para obter o resultado da tradução.',
        },
        right: {
          title: 'Tradução de seleção de texto',
          desc: 'Ao navegar em uma página web, selecione diretamente o texto a ser sublinhado para obter o resultado da tradução.',
        },
      },
      panel3: {
        one: {
          title: 'Aponte e clique',
          desc: 'Traduza e resuma páginas da web e conteúdo de vídeo para você a qualquer hora, em qualquer lugar, em qualquer cenário; trazendo a você uma experiência mais suave!',
        },
        two: {
          title: 'Mais abrangente e conveniente',
          desc: 'Cobertura completa em vários idiomas, leitura bilíngue sem barreiras, tradução em uma tecla; trazer-lhe uma experiência incomparável!',
        },
        three: {
          title: 'Orientado por IA',
          desc: 'Grande modelo orientado por IA para garantir precisão e consistência da tradução e aprimorar a experiência de leitura',
        },
      },
      panel4: {
        tab1: {
          key: 'Vídeo',
          title: 'Tradução e resumo de vídeo',
          desc: 'Seu especialista em eficiência, aliado ao chatgpt, traduz e resume conteúdo de vídeo do YouTube para economizar seu tempo de leitura!',
        },
        tab2: {
          key: 'Imagem',
          title: 'Tradução de imagens',
          desc: 'Clique para traduzir imagens enquanto navega na web',
        },
        tab3: {
          key: 'Rede',
          title: 'Tradução e Resumo da Web',
          desc: 'Extrair rapidamente informações importantes de páginas da web para resumo, melhorando sua eficiência de leitura',
        },
        tag1: 'Simples',
        tag2: 'Eficiência',
        tag3: 'Facilmente Entendido',
      },
    },
    userReviews: {
      title: 'Avaliações de usuários',
      desc: 'Avaliação cinco estrelas na chromewebstore',
    },
    lastBlock: { desc: 'Melhor tradução de IA e experiência de leitura mais suave' },
  },
  login: {
    title: 'Bem vindo ao Noam',
    btn: 'Faça login com o Google',
    tips: 'Ao me inscrever, eu concordo em',
    terms: 'Termos de Uso',
    and: 'e',
    policy: 'política de Privacidade',
    btn1: 'Faça login agora',
    desc: 'Faça login para desbloquear mais experiências',
  },
  pricing: {
    pageTitle: 'Preços',
    desc: 'Compare e escolha o melhor para você',
    yearly: 'Anualmente',
    monthly: 'Mensal',
    discount: '20% de desconto',
    preMonth: 'Por mês',
    billedYearly: 'Faturado anualmente',
    billedMonthly: 'Faturado Mensalmente',
    detailedCompare: 'Comparação detalhada',
    FAQ: 'Perguntas frequentes',
    day: 'dia',
    month: 'mês',
    around: 'Em volta',
    free: {
      benefit1: 'Tradução instantânea 100 vezes/dia',
      benefit2: 'Tradução e resumo da web',
      benefit3: '30 folhas de tradução de imagens',
      benefit4: 'Tradução e resumo do vídeo',
      benefit5: '10 Tradução de PDF',
      benefit6: '5.000 fichas',
      recommendText: 'Avaliação Gratuita',
      btn: 'Divirta-se',
    },
    pro: {
      benefit1: 'Suporte rápido de atendimento ao cliente',
      benefit2: '1.000.000 tokens/mês',
      benefit3: 'Tradução e resumo da web',
      benefit4: '200 folhas de tradução de imagens',
      benefit5: 'Tradução e resumo do vídeo',
      benefit6: '200 Tradução de PDF',
      btn: 'Comece',
      recommendText: 'A maioria das pessoas escolhe',
    },
    proPlus: {
      benefit1: 'Suporte rápido de atendimento ao cliente',
      benefit2: '3.000.000 tokens/mês',
      benefit3: 'Tradução e resumo da web',
      benefit4: '500 folhas de tradução de imagens',
      benefit5: 'Tradução e resumo do vídeo',
      benefit6: '500 Tradução de PDF',
      recommendText: 'A escolha mais adequada',
    },
    enterprise: {
      benefit1: 'Suporte rápido de atendimento ao cliente',
      benefit2: 'Multiusuário',
      benefit3: 'Soluções personalizadas',
      benefit5: 'Gerente de contas exclusivo',
      recommendText: 'Planos Personalizados（≥3人）',
      talkToUs: 'Fale conosco',
      btn: 'Contato',
    },
    web: {
      contrastiveTranslation: 'Tradução Bilíngue',
      instantTranslation: 'Tradução Instantânea',
      hoverTranslation: 'Tradução flutuante',
      dictionaryTranslation: 'Tradução de seleção de texto',
      summary: 'Resumo da página da web',
    },
    imageTranslation: 'Tradução de imagem',
    video: { translation: 'Tradução de vídeo', summary: 'Resumo do vídeo' },
    pdf: {
      translation: 'Tradução de PDF',
      maxPage: 'Número máximo de páginas em PDF',
      chat: 'Número de consultas em PDF',
    },
    FQAObj: {
      q1: 'Posso usá-lo gratuitamente?',
      a1: 'Existe uma versão gratuita do Noam que pode ser usada diretamente.',
      q2: 'Existem diferentes planos de adesão e opções de taxas?',
      a2: 'Além da versão gratuita, existem três planos de adesão, Pro, Pro+ e Enterprise; você pode escolher entre dois métodos de pagamento, mensal contínuo e anual contínuo, e o custo abrangente do anual contínuo é mais favorável.',
      q3: 'Qual é a diferença entre uma assinatura mensal contínua e uma assinatura anual contínua?',
      a3: 'Não há diferença na funcionalidade. O custo abrangente da assinatura anual contínua é inferior ao da assinatura mensal contínua; a assinatura mensal é cobrada uma vez por mês, enquanto a assinatura anual é cobrada uma vez por ano.',
      q4: 'Como cancelo meu plano de renovação automática?',
      a4: 'Você pode ir para a página de configurações de back-end e optar por assinar para cancelar o plano de renovação.',
    },
  },
  upgradeModal: {
    title: 'Lembretes',
    limitAlertStr:
      'Sua cota de plano gratuito se esgotou, recomendamos que você atualize seu plano para continuar a experiência ~!',
    upgradeAlertStr:
      'Sua cota de plano gratuito se esgotou, recomendamos que você atualize seu plano para continuar a experiência ~!',
    btn: 'Atualize agora',
    more: 'Mais planos',
    contactStr: 'Aguarde a próxima redefinição ou entre em contato conosco em contact@noam.tools.',
    upload: {
      free: 'Você atingiu seu limite de tradução de PDF',
      pro: 'Você atingiu seu limite de tradução de PDF neste mês',
    },
    chat: {
      free: 'Você atingiu o limite do Chatpdf excedido',
      pro: 'Você atingiu seu limite do Chatpdf neste mês',
    },
    pageLimit: { free: 'Você atingiu o limite de páginas PDF excedido' },
  },
  buyModal: {
    title: 'Plano de assinatura',
    freeTrial: 'Avaliação gratuita de 3 dias',
    selectLabel: 'Selecione um plano',
    btn: 'Experimente gratuitamente',
    yearLabel: 'ano',
    monthLabel: 'mês',
    afterFree: 'Depois do julgamento',
  },
  privacy: {
    title: 'política de Privacidade',
    updateTime: 'Atualizado: 15 de março de 2024',
    block1:
      'Nós, Noam (referidos como "Noam", "nós", "nosso" ou "nos"), respeitamos sua privacidade e estamos comprometidos em proteger qualquer informação que obtivermos de você ou sobre você. Esta Política de Privacidade descreve nossas práticas em relação à coleta ou uso de informações pessoais provenientes do uso de nosso site, aplicativos e serviços (coletivamente denominados "Serviços"). Esta Política de Privacidade não se aplica ao conteúdo que tratamos em nome de nossos clientes de produtos empresariais, como nossa API. A utilização destes dados está sujeita ao nosso contrato de cliente, que abrange o acesso e utilização destes produtos.',
    subTitle1: 'Informações pessoais que coletamos',
    block2:
      'Coletamos as seguintes informações pessoais (“Informações Pessoais”) relacionadas a você:',
    block3:
      'Informações fornecidas por você: Se você criar uma conta para usar nossos serviços ou se comunicar conosco, coletaremos informações pessoais da seguinte forma:',
    subBlock1:
      'Informações da conta: quando você cria uma conta em nossa plataforma, coletamos informações relacionadas à sua conta, incluindo seu nome, informações de contato, credenciais da conta, informações do cartão de pagamento e histórico de transações (referidas como "Informações da conta").',
    subBlock2:
      'Conteúdo do usuário: quando você usa nossos serviços, coletamos informações pessoais de suas entradas, uploads de arquivos ou comentários que nos são fornecidos (referidos como "Conteúdo").',
    subBlock3:
      'Informações de comunicação: Se você nos enviar mensagens, coletaremos seu nome, informações de contato e o conteúdo das mensagens que você enviar (referidas como "Informações de comunicação").',
    subBlock4:
      'Informações de mídia social: temos páginas em sites de mídia social como Instagram, Facebook, Medium, Twitter, YouTube e LinkedIn. Quando você interage com nossas páginas de mídia social, coletamos as informações pessoais que você escolhe nos fornecer, como seus dados de contato (referidos como “Informações Sociais”). Além disso, as empresas que hospedam as nossas páginas nas redes sociais podem nos fornecer informações agregadas e análises sobre as nossas atividades nas redes sociais.',
    block4:
      'Informações Pessoais Recebidas Automaticamente pelo Seu Uso dos Serviços: Quando você acessa, usa ou interage com os serviços, recebemos as seguintes informações sobre seu acesso, uso ou interação ("Informações Técnicas"):',
    subBlock5:
      'Dados de registro: Informações enviadas automaticamente pelo seu navegador ao utilizar nossos serviços. Os dados de registro incluem seu endereço de protocolo de Internet (IP), tipo e configurações do navegador, a data e hora de suas solicitações e como você interage com nosso site.',
    subBlock6:
      'Dados de uso: Podemos coletar automaticamente informações sobre seu uso do serviço, como os tipos de conteúdo que você visualiza ou interage, os recursos que você usa e suas ações realizadas, bem como seu fuso horário, país, data e hora de acesso, agente e versão do usuário, tipo de computador ou dispositivo móvel e conexão do computador.',
    subBlock7:
      'Informações do dispositivo: inclui o nome do dispositivo, o sistema operacional, o identificador do dispositivo e o navegador que você está usando. As informações coletadas podem depender do tipo de dispositivo que você está usando e de suas configurações.',
    subBlock8:
      'Cookies: Utilizamos cookies para operar e gerenciar nossos serviços e melhorar sua experiência. Um “cookie” é uma informação enviada por um site que você visita para o seu navegador. Você pode configurar seu navegador para aceitar todos os cookies, rejeitar todos os cookies ou notificá-lo quando um cookie for fornecido, para que você possa decidir se deseja aceitá-lo sempre. No entanto, recusar cookies pode impedir a utilização ou afetar negativamente a visualização ou funcionalidade de determinadas áreas ou funcionalidades do website. Para obter mais informações sobre cookies, visite Tudo sobre Cookies.',
    subBlock9:
      'Análise: Podemos utilizar vários produtos de análise online que utilizam cookies para nos ajudar a analisar como os utilizadores utilizam os nossos serviços e melhorar a sua experiência ao utilizar os serviços.',
    subTitle2: 'Como usamos informações pessoais',
    block5: 'Podemos usar informações pessoais para os seguintes fins:',
    subBlock10: 'Fornecer, gerenciar, manter e/ou analisar serviços;',
    subBlock26: 'Melhorar nossos serviços e realizar pesquisas;',
    subBlock11: 'Comunicando com você;',
    subBlock12: 'Desenvolvimento de novos projetos e serviços;',
    subBlock13:
      'Prevenir fraudes, atividades criminosas ou uso indevido dos nossos serviços e proteger a segurança dos nossos sistemas de TI, arquitetura e redes;',
    subBlock14: 'Realização de transferências comerciais;',
    subBlock15:
      'Cumprir obrigações legais e procedimentos legais, protegendo nossos direitos, privacidade, segurança ou propriedade, bem como os de nossas afiliadas, de você ou de outros terceiros.',
    block6:
      'Informações resumidas ou desidentificadas: podemos agregar ou desidentificar informações pessoais para evitar sua reutilização para fins de identificação e usar essas informações para analisar a eficácia de nossos serviços, melhorar e adicionar recursos aos nossos serviços, realizar pesquisas e para fins semelhantes. . Além disso, podemos analisar o comportamento geral e as características dos usuários de nossos serviços e compartilhar informações agregadas com terceiros, publicar essas informações agregadas ou disponibilizá-las ao público em geral. Poderemos coletar informações agregadas por meio dos serviços, de cookies e de outros meios descritos nesta Política de Privacidade. Manteremos e usaremos informações anonimizadas de forma anônima ou desidentificada e não tentaremos reidentificar tais informações, a menos que exigido por lei.',
    subTitle3: 'Divulgação de informações pessoais',
    block7:
      'Em certos casos, poderemos fornecer as suas informações pessoais a terceiros sem aviso prévio, a menos que exigido por lei:',
    subBlock16:
      'Fornecedores e prestadores de serviços: Para nos ajudar a atender às necessidades operacionais de negócios e a executar determinados serviços e funções, podemos fornecer informações pessoais a fornecedores e prestadores de serviços, incluindo prestadores de serviços de hospedagem, prestadores de serviços em nuvem e outros prestadores de serviços de tecnologia da informação, software de comunicação por e-mail e provedores de serviços de análise da web, etc. Esses parceiros acessarão, processarão ou armazenarão informações pessoais somente sob nossa orientação, enquanto desempenham suas funções para nós.',
    subBlock17:
      'Transferências de negócios: Se nos envolvermos em transações estratégicas, reorganizações, falências, aquisições de falência ou transição de serviços para outro fornecedor (coletivamente referidos como "Transações"), suas informações pessoais e outras informações poderão ser compartilhadas com contrapartes da transação e outras partes que auxiliam na as Transações durante a devida diligência e transferidas como parte das Transações juntamente com outros ativos para um sucessor ou empresa afiliada.',
    subBlock18:
      'Requisitos legais: Podemos compartilhar suas informações pessoais com autoridades governamentais, pares do setor ou outros terceiros, incluindo suas informações de interação com nossos serviços, (1) se exigido por lei ou se acreditarmos que tal ação é necessária para cumprir obrigações legais; (2) para proteger e defender nossos direitos ou propriedade; (3) se determinarmos unilateralmente que há uma violação dos nossos termos, políticas ou leis; (4) para detectar ou prevenir fraudes ou outras atividades ilegais; (5) para proteger nossos produtos, funcionários ou usuários, ou segurança pública, proteção e integridade; ou (vi) para se defender contra responsabilidade legal.',
    subTitle4: 'Seus direitos',
    block8:
      'Dependendo da sua localização geográfica, os indivíduos no Espaço Económico Europeu, no Reino Unido e em todo o mundo podem ter determinados direitos legais relativamente às suas informações pessoais. Por exemplo, você pode ter o direito de:',
    subBlock19: 'Acesse suas informações pessoais e informações sobre como elas são processadas.',
    subBlock20: 'Exclua suas informações pessoais de nossos registros.',
    subBlock21: 'Corrija ou atualize suas informações pessoais.',
    subBlock22: 'Transferir suas informações pessoais para terceiros (portabilidade de dados).',
    subBlock23: 'Limite a forma como processamos suas informações pessoais.',
    subBlock24:
      'Retirar o seu consentimento – quando basearmos o consentimento como base legal para o processamento, você poderá retirar o seu consentimento a qualquer momento.',
    subBlock25: 'Opor-se à forma como processamos suas informações pessoais.',
    subTitle5: 'Links para outros sites',
    block9:
      'O serviço pode conter links para outros sites não operados ou controlados pela Noam, incluindo serviços de mídia social (“Sites de Terceiros”). As informações que você compartilha com sites de terceiros serão regidas pelas políticas de privacidade e termos de serviço específicos dos sites de terceiros, e não por esta Política de Privacidade. Fornecemos esses links por conveniência e não implicam nosso endosso ou análise desses sites. Entre em contato diretamente com sites de terceiros para obter informações sobre suas políticas e termos de privacidade.',
    subTitle6: 'Segurança e retenção de informações',
    block10:
      'Implementamos medidas técnicas, administrativas e organizacionais comercialmente razoáveis ​​para proteger informações pessoais online e offline contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou atos hostis. No entanto, nenhuma transmissão pela Internet ou por e-mail pode garantir total segurança ou precisão. Em particular, os e-mails enviados para nós ou por nós podem não ser seguros. Portanto, você deve ter especial cuidado ao decidir quais informações nos enviar através do serviço ou email. Além disso, não somos responsáveis ​​por qualquer obstrução das configurações de privacidade ou medidas de segurança no serviço ou nas configurações ou medidas de segurança de sites de terceiros. Reteremos suas informações pessoais pelo tempo necessário para lhe fornecermos serviços ou para outros fins comerciais legítimos, como resolução de disputas, motivos de segurança e proteção ou cumprimento de nossas obrigações legais. O período de tempo que retemos as informações pessoais dependerá de muitos fatores, como a quantidade, a natureza, a sensibilidade das informações, os riscos potenciais de uso ou divulgação não autorizada, as finalidades para as quais processamos as informações e outras considerações juridicamente vinculativas.',
    subTitle7: 'Modificação da Política de Privacidade',
    block11:
      'Poderemos atualizar esta Política de Privacidade de tempos em tempos. Quando o fizermos, publicaremos uma versão atualizada nesta página, salvo exigência em contrário pela lei aplicável.',
    subTitle8: 'Como entrar em contato conosco',
    block12:
      'Se você tiver alguma dúvida ou preocupação não respondida sobre esta Política de Privacidade, entre em contato com nosso atendimento ao cliente. E-mail:',
  },
  terms: {
    title: 'Termos de Serviço',
    updateTime: 'Atualizado: 15 de março de 2024',
    block1: 'Obrigado por usar Noam!',
    block2:
      'Estes Termos de Uso se aplicam ao uso dos serviços da Noam, incluindo nossas interfaces de programação de aplicativos, software, ferramentas, serviços de desenvolvedor, dados, documentação e site (coletivamente denominados "Serviços"). Ao utilizar nossos Serviços, você concorda em cumprir estes termos. Nossa Política de Privacidade explica como coletamos e usamos informações pessoais.',
    subTitle1: 'Cadastro e Acesso',
    block3:
      'Se você tiver menos de 18 anos, deverá obter permissão de seus pais ou responsáveis ​​legais para usar o Serviço. Se estiver usando o Serviço em nome de outra pessoa ou entidade, você deverá estar autorizado a aceitar estes termos em nome dela. Você deve fornecer informações precisas e completas para se registrar em uma conta. Você não pode fornecer suas credenciais de acesso ou conta a ninguém fora de sua organização e é responsável por todas as atividades realizadas usando suas credenciais.',
    subTitle2: 'Requisitos de uso',
    block4:
      '(a) Uso do Serviço. Você pode acessar o Serviço sob estes termos e nós lhe concedemos o direito não exclusivo de usar o Serviço. Ao utilizar o Serviço, você cumprirá estes termos e todas as leis aplicáveis. Nós e nossas afiliadas possuímos todos os direitos, títulos e interesses no Serviço.',
    block5:
      '(b) Feedback. Agradecemos comentários, comentários, ideias, sugestões e melhorias. Se você fornecer tal conteúdo, poderemos usá-lo sem quaisquer restrições e sem compensação para você.',
    block6:
      '(c) Restrições. Você não pode: (1) usar o Serviço de maneira que infrinja, se aproprie indevidamente ou viole quaisquer direitos de terceiros; (2) fazer engenharia reversa, descompilar, desmontar, traduzir ou de outra forma tentar descobrir o código-fonte ou componentes subjacentes dos modelos, algoritmos e sistemas do Serviço (a menos que tais restrições sejam proibidas pela lei aplicável); (3) usar o resultado do Serviço para desenvolver modelos concorrentes do Noam; (4) extrair dados ou resultados do Serviço usando quaisquer métodos automatizados ou de programação, a menos que permitido pela API, incluindo scraping, coleta da web ou extração de dados da web; (5) representar a saída do Serviço como gerada artificialmente quando não o é, ou de outra forma violar nossas políticas de uso; (6) comprar, vender ou transferir chaves de API sem nosso consentimento prévio; ou (7) nos fornecer quaisquer informações pessoais de crianças menores de 13 anos ou idade aplicável de consentimento digital. Você cumprirá quaisquer limites de taxas e outros requisitos em nossa documentação.',
    block7:
      '(d) Serviços de Terceiros. Quaisquer software, serviços ou outros produtos de terceiros associados ao Serviço são regidos pelos seus próprios termos e não somos responsáveis ​​por produtos de terceiros.',
    subTitle3: 'Contente',
    block8:
      '(a) Seu Conteúdo. Você pode fornecer dados ("Entrada") ao Serviço e receber resultados ("Saída") gerados e retornados com base na Entrada (coletivamente referidos como "Conteúdo"). Entre as partes, e na medida permitida pela lei aplicável, você é o proprietário de todas as Informações. Noam poderá usar o Conteúdo para fornecer e manter o Serviço, cumprir as leis aplicáveis ​​e fazer cumprir nossas políticas. Você é responsável pelo Conteúdo, inclusive por garantir que ele não viole nenhuma lei aplicável ou estes termos.',
    block9:
      '(b) Precisão. A inteligência artificial e o aprendizado de máquina são campos de pesquisa em rápida evolução. Nós nos esforçamos continuamente para melhorar nosso serviço para torná-lo mais preciso, confiável, seguro e benéfico. Dada a natureza probabilística da aprendizagem automática, a utilização do nosso serviço pode resultar em resultados incorretos em certos casos, que podem não refletir com precisão indivíduos, lugares ou factos reais. Você deve avaliar adequadamente a precisão de qualquer saída com base em seu caso de uso, inclusive revisando manualmente as saídas.',
    subTitle4: 'Taxas e Pagamento',
    block10:
      '(a) Taxas e cobrança. Você pagará todas as taxas ("Taxas") de acordo com os preços e termos na página de preços aplicável ou conforme acordado por escrito entre nós. Reservamo-nos o direito de corrigir erros ou erros de preços, mesmo que tenhamos emitido faturas ou recebido pagamentos. Você fornecerá informações de cobrança completas e precisas, incluindo um método de pagamento válido e autorizado. Cobraremos sua forma de pagamento periodicamente conforme acordado, mas poderemos alterar razoavelmente a data de cobrança. Você autoriza a Noam, suas afiliadas e processadores de pagamento terceirizados a cobrar sua forma de pagamento. Se o seu pagamento falhar, iremos notificá-lo por escrito e poderemos suspender o acesso ao Serviço até que o pagamento seja recebido. Exceto quando especificado de outra forma neste contrato, os pagamentos não são reembolsáveis.',
    block11:
      '(b) Impostos. Salvo indicação em contrário, as Taxas não incluem quaisquer impostos, taxas e avaliações semelhantes federais, estaduais, locais e estrangeiras ("Impostos"). Você é responsável por todos os Impostos associados às suas compras, excluindo os Impostos baseados em nosso lucro líquido, pelos quais lhe faturaremos. Você concorda em pagar prontamente tais Impostos e nos fornecer declarações de privacidade legalmente válidas e obter os consentimentos necessários para o tratamento de tais dados, e você nos garante que trata tais dados de acordo com a lei aplicável.',
    block12:
      '(c) Mudanças de Preço. Podemos alterar nossos preços notificando você através de sua conta e/ou nosso site. Os aumentos de preços entrarão em vigor imediatamente após a publicação. Quaisquer alterações de preço serão aplicadas às taxas da sua conta após a data efetiva da alteração.',
    block13:
      '(d) Disputas e Pagamentos Atrasados. Se desejar contestar quaisquer Taxas ou Impostos, entre em contato com contact@noam.tools dentro de trinta (30) dias a partir da data da fatura.',
    block14:
      '(e) Uso Gratuito. Você não pode criar várias contas para obter créditos gratuitos. Se determinarmos que você não está usando os créditos gratuitos de boa fé, poderemos cobrar taxas padrão ou parar de fornecer acesso ao Serviço.',
    subTitle5: 'Confidencialidade, Segurança e Proteção de Dados',
    block15:
      '(a) Confidencialidade. Você pode ter acesso a informações confidenciais da Noam. Você só poderá usar informações confidenciais conforme permitido por estes termos para fins de uso do Serviço. Você não poderá divulgar informações confidenciais a terceiros e protegerá as informações confidenciais de maneira não menos protetora do que protege suas próprias informações confidenciais semelhantes, pelo menos com cuidado razoável. Informações confidenciais são informações não públicas designadas como confidenciais pela Noam ou que deveriam ser razoavelmente tratadas como confidenciais sob as circunstâncias, incluindo software, especificações e outras informações comerciais não públicas. As informações confidenciais não incluem informações que: (1) se tornem geralmente disponíveis ao público sem culpa de sua parte; (2) você o tinha em sua posse sem qualquer obrigação de confidencialidade antes de recebê-lo sob estes termos; (3) for legalmente divulgado a você por terceiros, sem quaisquer obrigações de confidencialidade; ou (4) for desenvolvido de forma independente por você, sem usar informações confidenciais. Você pode divulgar informações confidenciais se exigido por lei, tribunal ou outra ordem governamental, mas deve fornecer notificação prévia por escrito à Noam de maneira razoável e, na medida do possível, fazer esforços razoáveis ​​para limitar o escopo da divulgação, inclusive ajudando-nos na oposição a pedidos de divulgação.',
    block16:
      '(b) Segurança. Você deve implementar medidas razoáveis ​​e apropriadas para ajudar a proteger seu acesso e uso do Serviço. Se você descobrir quaisquer vulnerabilidades ou violações relacionadas ao uso do Serviço, deverá entrar em contato imediatamente com a Noam e fornecer detalhes da vulnerabilidade ou violação.',
    block17:
      '((c) Processamento de Dados Pessoais. Se você usar o Serviço para processar dados pessoais, deverá fornecer declarações de privacidade legalmente suficientes e obter os consentimentos necessários para o processamento de tais dados, e você nos garante que trata esses dados de acordo com a lei aplicável .',
    subTitle6: 'Prazo e Rescisão',
    block18:
      '(a) Rescisão; Suspensão. Estes termos entrarão em vigor a partir da primeira utilização do Serviço e permanecerão em vigor até serem rescindidos. Você pode rescindir estes termos a qualquer momento, por qualquer motivo, descontinuando o uso do Serviço e do Conteúdo. Poderemos rescindir estes termos com aviso prévio por qualquer motivo. Poderemos rescindir imediatamente estes termos, notificando você se você violar materialmente a Seção 2 (Requisitos de Uso), Seção 5 (Confidencialidade, Segurança e Proteção de Dados), Seção 8 (Resolução de Disputas) ou Seção 9 (Termos Gerais), ou se nosso relacionamento com qualquer fornecedor de tecnologia terceirizado fora de nosso controle, ou para conformidade com a lei ou solicitações governamentais. Poderemos suspender o seu acesso ao Serviço se você não cumprir estes termos, ou se seu uso representar um risco de segurança para nós ou para terceiros, ou se suspeitarmos que seu uso é fraudulento ou pode expor a nós ou a terceiros a responsabilidades. .',
    block19:
      '(b) Efeitos da Rescisão. Após a rescisão, você deixará de usar o Serviço e devolverá ou destruirá imediatamente qualquer informação confidencial conforme orientado por nós. As disposições destes termos que, por sua natureza, devem sobreviver à rescisão ou expiração, incluindo, mas não se limitando às Seções 3 e 5-9, sobreviverão.',
    subTitle7: 'Indenização; Isenções de responsabilidade; Limitação de responsabilidade',
    block20:
      '(a) Indenização. Você defenderá, indenizará e isentará a nós, nossas afiliadas e nosso pessoal de e contra quaisquer reivindicações, perdas e despesas (incluindo honorários advocatícios) decorrentes ou relacionadas ao seu uso do Serviço, incluindo seu Conteúdo, seu uso de produtos ou serviços relacionados ao Serviço e sua violação destes termos ou da lei aplicável.',
    block21:
      '(b) Isenções de responsabilidade. O Serviço é fornecido "como está". Na medida permitida por lei, nós e nossas afiliadas e licenciadores não fazemos representações ou garantias de qualquer tipo em relação ao Serviço e não oferecemos garantias, incluindo, entre outras, garantias de comercialização, adequação a uma finalidade específica, qualidade satisfatória, não violação e diversão tranquila, e quaisquer garantias decorrentes da negociação ou uso do comércio. Não garantimos que o Serviço será ininterrupto, preciso ou livre de erros, ou que qualquer Conteúdo será seguro, não será perdido ou não será alterado.',
    block22:
      '(c) Limitação de Responsabilidade. Nem nós, nem nossas afiliadas, nem nossos licenciadores seremos responsáveis ​​por quaisquer danos indiretos, incidentais, especiais, consequenciais ou punitivos, incluindo perda de lucros, perda de reputação, perda de uso, perda de dados ou outras perdas intangíveis, mesmo que fomos avisados ​​da possibilidade de tais danos. Sob estes termos, nossa responsabilidade total não excederá as Taxas que você pagou ao Serviço nos doze (12) meses anteriores à reclamação ou cem dólares americanos (US$ 100), o que for maior. As limitações nesta seção aplicam-se na extensão máxima permitida pela lei aplicável.',
    subTitle8: 'Lei Aplicável e Jurisdição',
    block23:
      'Os Termos e o uso dos Serviços são regidos pelas leis dos Estados Unidos, exceto pelas regras de conflito de leis dos EUA. O uso do Aplicativo também pode estar sujeito a outras leis locais, estaduais, nacionais ou internacionais. Qualquer ação ou processo legal relacionado a estes Termos será instaurado exclusivamente nos tribunais dos Estados Unidos, e você concorda em submeter-se à jurisdição pessoal de tais tribunais.',
    subTitle9: 'Termos Gerais',
    block24:
      '(a) Relacionamento das Partes. Noam e você são contratantes independentes e nenhuma das partes tem o poder de representar ou vincular a outra parte ou assumir obrigações para a outra parte sem o consentimento prévio por escrito da outra parte.',
    block25:
      '(b) Uso da Marca. Você não pode usar o nome, logotipos ou marcas registradas da Noam ou de qualquer uma de suas afiliadas sem nosso consentimento prévio por escrito.',
    subTitle10: 'Divisibilidade',
    block26:
      'Se qualquer disposição destes termos for considerada inválida, ilegal ou inexequível, as disposições restantes permanecerão em pleno vigor e efeito.',
  },
  contact: {
    title: 'Contate-nos',
    block1:
      'Para customização ou se você tiver alguma dúvida ou sugestão, entre em contato conosco pelo e-mail contact@noam.tools e responderemos imediatamente.',
    block2: 'Apoie-nos classificando-nos na loja de extensões do Google Apps em ★★★★★.',
    block3: 'Ajude outras pessoas compartilhando esta extensão:',
  },
  pdf: {
    toast1: 'O PDF está carregando, tente novamente mais tarde!',
    toast2: 'O conteúdo do PDF é muito grande, não suporta a caixa de diálogo por enquanto',
    toast3: 'Baixando, tente novamente após a conclusão do download.',
    shareMsg1: 'Compartilhar geração de link bem-sucedida',
    shareMsg2: 'Compartilhe atualizações de conteúdo com sucesso',
    shareMsg3:
      'Falha na geração do link de compartilhamento. Tente novamente mais tarde ou entre em contato com nosso atendimento ao cliente.',
    shareMsg4:
      'Falha na atualização do conteúdo de compartilhamento. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente',
    shareBtnText1: 'Gerar links de compartilhamento',
    shareBtnText2: 'Conteúdo de compartilhamento atualizado',
    uploading: 'Enviando',
    uploadingMsg: 'Por favor, seja paciente, pois leva algum tempo para carregar.',
    cancelUpload: 'Cancelar',
    uploadErr:
      'Falha no upload do arquivo. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente',
    uploadMsg1: 'Arraste e solte o arquivo aqui',
    uploadMsg2: 'Tipos de arquivos suportados: PDF |  Tamanho máximo do arquivo: 100 MB',
    uploadMsg3: 'Clique para selecionar ou arraste e solte os arquivos para fazer upload aqui',
    searchPlaceholder: 'Procurar',
    collectionEmpty: 'Nenhum conteúdo na coleção ~',
    kw: 'grampo',
    video: 'vídeo',
    link: 'link',
    dataLoading: 'Carregando dados...',
    freeBannerTips: 'Fim da tradução gratuita de PDF',
    vipBannerTips: 'Você atingiu seu limite de tradução de PDF neste mês.',
    freeBannerBtn: 'Atualize para aproveitar mais',
    vipBannerBtn: 'Atualize para aproveitar mais',
    dialogTitle: 'Atenção',
    dialogContent: 'Tem certeza de que deseja excluí-lo?',
    okBtn: 'Excluir',
    cancelBtn: 'Cancelar',
    unLoginToast: 'Faça login para usar esta função',
    parseErrTips: 'Não foi possível analisar o arquivo',
    new: 'novo arquivo',
    parseErrDialogContent:
      'O link do PDF atual não suporta tradução direta. Baixe o PDF do link original após a tradução',
    okBtn2: 'reconhecer',
    notPdf: 'Somente arquivos PDF',
    uploadingMsg1: 'Upload de arquivo em andamento. Por favor, tente novamente mais tarde.',
    uploadingMsg2: 'Atualmente você tem um arquivo sendo convertido. Tente novamente mais tarde.',
    uploadingMsg3:
      'Falha na consulta de progresso da conversão. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente',
    uploadingMsg4:
      'Noam não pode acessar seus arquivos locais diretamente. Faça upload manualmente de um PDF para tradução.',
    uploadingMsg5:
      'Falha ao obter o URL do arquivo. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente.',
    fileMsg: 'Volume de arquivo atual:',
    fileMsg2: 'Faça upload de um arquivo menor que 100 MB',
    fileErrMsg:
      'Falha na leitura do arquivo. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente.',
    parseFileToast:
      'Falha na análise do arquivo. Tente novamente mais tarde ou entre em contato com nosso atendimento ao cliente.',
    noId: 'O ID do arquivo não existe',
    autoDetect: 'Detecção automática',
    hasTransTask: 'Há uma tarefa sendo traduzida. Por favor, aguarde.',
    transSucc: 'A tradução foi bem sucedida!',
    entry: 'Lista PDF',
    listError:
      'A lista de PDF não foi carregada. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente.',
    collectionTab: 'Memorando',
    pdfLoading: 'Análise...',
    installTips:
      'Instale o pequeno plug-in, você pode traduzir mais arquivos PDF e também pode experimentar uma tradução suave de páginas da web:',
    webTrans: 'referência cruzada completa',
    hoverTrans: 'Tradução do parágrafo suspenso',
    selectTrans: 'sublinhar as palavras e traduzir',
    installText: 'Balcão único para todas as suas necessidades diárias de tradução',
    start: 'Comece sua experiência gratuita',
    shareTips: 'A tradução do PDF foi concluída, vá em frente e compartilhe com seus amigos!',
    download: {
      loading: 'Salvo...',
      btn: 'Salvar agora',
      trans: 'Somente tradução',
      both: 'bilinguismo',
      download: 'Download',
      msg1: 'Algumas das traduções do documento atual são mais longas que o texto original e o texto pode se sobrepor após o download, por isso é recomendável lê-lo na página web.',
      msg2: '1、O conteúdo não foi totalmente traduzido, faça o download após a tradução completa.',
      msg3: '2、A parte da tradução do documento é muito longa, você pode ler na página da web para evitar sobreposição de texto.',
      msg4: 'No momento, há tarefas sendo traduzidas. Tente novamente após a conclusão da tradução.',
      fileName: 'Nome do arquivo:',
      fileType: 'Baixar tipo de arquivo:',
      transContinue: 'Continuar traduzindo',
      save: 'Salvar no local',
      downloadMsg1: 'Progresso do PDF na tradução:',
      downloadMsg2: 'Requisitos projetados',
      minute: 'Minutos...',
      downloadSucc: 'O download do PDF foi concluído, clique em salvar agora!',
      savePrint: 'Salvar e imprimir',
      loading2: 'Carregando...',
      desc: 'Salvar no Local: As traduções baixadas são salvas como imagens; Salvar e Imprimir: As traduções baixadas são mantidas em seu formato original e suportam cópia.',
      success: 'Download com sucesso',
      cancel:
        'Fechar a janela pop-up cancelará o download do arquivo PDF. Tem certeza de que deseja cancelar?',
      downloadTip:
        'O PDF está sendo baixado. Não feche a janela pop-up durante o download para evitar falhas no download.',
      progress:
        'O PDF está sendo traduzido. Progresso: {{progress}}, previsto para levar {{minutos}} minutos...',
      error: 'Falha ao salvar no local. Tente imprimir o salvamento',
    },
    guide: {
      title1: 'Tradução Bilíngue',
      title2: 'Bate-papo em PDF',
      text1: 'Leitura Bilíngue',
      text2:
        'Leitura bilíngue, para que você possa comparar o texto original e a tradução durante a leitura para melhorar a eficiência da leitura',
      text3: 'Bate-papo em PDF',
      text4: 'A IA analisa e resume e pode responder às suas perguntas com base no conteúdo do PDF',
      text5: 'Precisão e eficiência',
      text6: 'PDF alimentado por IA para traduções mais precisas e eficientes',
      placeholder: 'Por favor preencha o link do PDF',
      start: 'Iniciar tradução',
      toast: 'Insira um link de PDF on-line válido que termine em .pdf',
    },
    original: 'Original',
    parallel: 'Paralelo',
    trans: 'Tradução',
    initial: 'Traduzir PDF',
    thumbnail: 'catálogos',
    downloadBtn: 'Download',
    shareBtn: 'Compartilhar',
    uploadBtn: 'Carregar',
    installText2: 'Instale primeiro',
    chatPdf: {
      clearSucc: 'Limpado com sucesso',
      clear: 'Claro',
      clearContent:
        'Após a limpeza, uma nova conversa será criada e o conteúdo desta conversa não será salvo, tem certeza que deseja limpá-la?',
      clearDialogTitle: 'Atenção',
      cancel: 'Cancelar',
      chatTitle: 'Bem-vindo ao Noam, seu resumo em PDF está pronto:',
      summaryTitle: 'Perguntas que você pode estar interessado:',
      placeholder: 'Digite qualquer pergunta sobre o PDF',
      copy: 'Cópia',
      copySucc: 'Sucesso na cópia',
      historyTitle: 'História',
      expand: 'Colapso',
      noDataDesc: 'Sem história ~',
      noMore: 'Não mais.',
      Search: 'Procurar',
      delete: 'Excluir',
      loading: 'carregando...',
      deleteTitle: 'Dica',
      deleteContent: 'Após a exclusão, este PDF não é recuperável. Deseja excluí-lo?',
      deleteOk: 'Excluir',
      deleteCancel: 'Cancelar',
      deleteSuccess: 'Excluir com sucesso',
      listError:
        'A lista de leitura não foi carregada. Tente novamente mais tarde ou entre em contato com o suporte ao cliente',
    },
    shareModal: {
      shareSetting: 'Compartilhar configurações de conteúdo',
      shareText: 'Compartilhar',
      text1: 'Configurações de compartilhamento',
      text2: 'Gerar links de compartilhamento',
      range: 'Escopo:',
      custom: 'Personalização:',
      page: 'páginas',
      sourceLang: 'Fonte:',
      target: 'Destino:',
      errMsg:
        'O conteúdo da faixa de compartilhamento atual não foi totalmente traduzido, portanto, traduza-o antes de compartilhar.',
      pageNuErr: 'Insira o valor correto do número da página',
      trans: 'Traduzir',
      cancel: 'Cancelar',
      update: 'Links de compartilhamento atualizados',
      gen: 'Compartilhar',
      shareTips: 'Compartilhe o link do PDF traduzido com seus amigos para leitura:',
      copySucc: 'Copiar link com sucesso',
      copy: 'Cópia',
      settings: 'Configurações de compartilhamento:',
      modify: 'modificar',
      translateTips:
        'Dica: Se a tradução demorar muito, você pode fechar a janela pop-up e compartilhá-la após a conclusão da tradução.',
      translating: 'Tradução de PDF...',
      remain: 'restante',
      remain1: 'Requisitos totais projetados',
      totalPage: '1 página no total',
      search: 'Procurar',
      notSupport: 'Este idioma não é suportado atualmente',
    },
    toolbar: { Adjust: 'Ajustar', autoFit: 'Ajuste automático' },
    memoTotalCount: 'Total de 1 conteúdo',
    dragTipModal: {
      press: 'Imprensa',
      black: 'espaço',
      leftKey: 'botão esquerdo',
      tips: 'para arrastar e visualizar.',
      ok: 'Eu entendi',
    },
  },
  locale: {
    zh: 'chinês',
    en: 'Inglês',
    es: 'Espanhol',
    ru: 'russo',
    pt: 'Português (Brasil)',
    id: 'indonésio',
    cs: 'Tcheco',
    de: 'Alemão',
    fr: 'Francês',
    it: 'italiano',
    nl: 'Holandês',
    pl: 'polonês',
    vi: 'vietnamita',
    tr: 'turco',
    uk: 'ucraniano',
    zhHant: 'Chinês Tradicional',
    ja: 'japonês',
    ko: 'coreano',
    ar: 'árabe',
    fa: 'persa',
    th: 'Tailandês',
    hi: 'hindi',
    noSearchLang: 'Este idioma não é compatível',
    placeholder: 'Procurar',
    af: 'afrikaans',
    am: 'amárico',
    as: 'Assamês',
    az: 'Azerbaijano',
    ba: 'Basquir',
    bg: 'búlgaro',
    bn: 'bangla',
    bo: 'tibetano',
    bs: 'Bósnio',
    ca: 'catalão',
    cy: 'galês',
    da: 'dinamarquês',
    dsb: 'Baixo Sórábio',
    dv: 'Divehi',
    el: 'grego',
    et: 'estoniano',
    eu: 'Basco',
    fi: 'finlandês',
    fil: 'filipino',
    fj: 'Fijiano',
    frCA: 'Francês (Canadá)',
    ga: 'irlandês',
    gl: 'Galego',
    gom: 'concani',
    gu: 'Guzerate',
    ha: 'Hauçá',
    he: 'hebraico',
    hr: 'croata',
    hsb: 'Alto Sorbio',
    ht: 'crioulo haitiano',
    hu: 'húngaro',
    hy: 'Armênio',
    ig: 'Ibo',
    ikt: 'Inuinnaqtun',
    is: 'islandês',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latim)',
    ka: 'Georgiano',
    kk: 'Cazaque',
    km: 'Khmer',
    kmr: 'Curdo (Norte)',
    kn: 'Kannada',
    ku: 'Curdo (Central)',
    ky: 'Quirguistão',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'letão',
    lzh: 'Chinês (Literário)',
    mai: 'Maithili',
    mg: 'malgaxe',
    mi: 'Maori',
    mk: 'Macedônio',
    ml: 'Malaiala',
    mnCyrl: 'Mongol (cirílico)',
    mnMong: 'Mongol (tradicional)',
    mr: 'Marathi',
    ms: 'malaio',
    mt: 'maltês',
    mww: 'Hmong Daw',
    my: 'Mianmar (birmanês)',
    nb: 'norueguês',
    ne: 'nepalês',
    nso: 'Sesoto em Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'punjabi',
    prs: 'Dari',
    ps: 'pashto',
    ptPT: 'Português (Portugal)',
    ro: 'romeno',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindi',
    si: 'Cingalês',
    sk: 'Eslovaco',
    sl: 'esloveno',
    sm: 'samoano',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanês',
    srCyrl: 'Sérvio (cirílico)',
    srLatn: 'Sérvio (latim)',
    st: 'Sesoto',
    sv: 'sueco',
    sw: 'suaíli',
    ta: 'tâmil',
    te: 'Telugu',
    ti: 'Tigrínia',
    tk: 'turcomano',
    tlhLatn: 'Klingon (latim)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setsuana',
    to: 'tonganês',
    tt: 'Tártaro',
    ty: 'taitiano',
    ug: 'Uigur',
    ur: 'urdu',
    uz: 'Uzbeque (latim)',
    xh: 'Xhosa',
    yo: 'Iorubá',
    yua: 'Yucateca Maia',
    yue: 'Cantonês (tradicional)',
    zhHans: 'Chinês Simplificado',
    zu: 'zulu',
    auto: 'Detecção automática',
  },
  stripe: {
    contact: 'Contate-nos em contact@noam.tools com qualquer dúvida!',
    fail: {
      title: 'Falha no pagamento',
      text1: 'Infelizmente, não podemos processar sua solicitação de pagamento.',
      text2: 'Verifique sua conexão com a Internet e tente novamente.',
      btn: 'Tentar novamente',
    },
    succ: {
      title: 'Parabéns',
      text1: 'bem-vindo ao Noam,',
      text2: 'vamos começar juntos!',
      btn: 'Vamos fazer um pouco de mágica',
    },
  },
  welfare: {
    newUser: {
      label: 'Benefícios para novos usuários:',
      joinBtn: 'Cadastre-se agora',
      text: 'Apenas 100 vagas disponíveis! Pegue o seu agora!',
      remain: 'Restam apenas 30',
    },
  },
  help: {
    Fixedplugin: 'Plug-in fixo',
    Howtouse: 'Como usar',
    try: 'Experimente',
    FreeTrialBenefits: 'Benefícios de teste gratuito',
    pin: 'Pin Noam, para acesso mais fácil～～',
    Video: 'Vídeo',
    ParallelTranslation: 'Tradução Paralela',
    WebSummary: 'Resumo da Web',
    ChatPDF: 'Bate-papo em PDF',
    AquickOverviewOfNoam: 'Uma rápida visão geral de Noam',
    WordSelectionTranslation: 'Tradução de seleção de palavras',
    HoverTranslation: 'Tradução instantânea',
    ImageTranslation: 'Tradução de imagem',
    InstantTranslation: 'Tradução instantânea',
    VideoTranslation: 'Tradução de vídeo',
    benefits: 'Preparamos benefícios para você!',
    Free: 'Livre',
    Membership: 'Associação',
    Translation: 'Tradução',
    PDFparallelTranslation: 'Tradução paralela de PDF',
    ChatPDF2: 'Bate-papo PDF',
    Summary: 'Resumo',
    VideoSummary: 'Resumo do vídeo',
    FavoritesManagement: 'Gerenciamento de favoritos',
    Focusing: 'Foco',
    dayFree: 'Avaliação gratuita de 3 dias',
    planIsNotExist: 'plano não existe',
    TryItForFree: 'Experimente gratuitamente',
    startYoutube: 'Vamos começar com o YouTube ~',
    startWiki: 'Vamos começar com a Wikimedia~',
    tryNow: 'Experimente agora ~',
    GoWithYouTube: 'Vá com o YouTube',
    GoWithWikipedia: 'Vá com a Wikipédia',
    NoamHelp: 'Noam pode ajudá-lo a traduzir e resumir',
    SkipAllSteps: 'Pular todas as etapas',
    next: 'Próximo',
    done: 'Feito',
  },
  trialModal: {
    title: 'Presente de boas-vindas',
    trialText: 'Teste de 7 dias',
    desc1: 'Após o término do teste, será cobrada uma taxa anual de US$ 180',
    desc2: 'Cancelar a qualquer momento',
    btn: 'Comece agora (apenas $ 0,99)',
    installBtn: 'Instale agora para receber seu presente',
    web: 'Tradução Paralela',
    select: 'Tradução de seleção de palavras',
    hover: 'Tradução instantânea',
    image: 'Tradução de imagem',
    realtime: 'Tradução instantânea',
    video: 'Tradução de vídeo',
    pdf: 'Tradução paralela de PDF',
    chat: 'Bate-papo PDF',
    webSummary: 'Resumo da web',
    videoSummary: 'Resumo do vídeo',
    memo: 'Gerenciamento de favoritos',
    focus: 'Foco',
  },
  privacyCollectionPanel: {
    title: 'Este site usa cookies',
    text: 'Para melhorar a sua experiência, gostaríamos de utilizar cookies para personalizar conteúdos e serviços.',
    acceptBtn: 'Aceitar tudo',
  },
  installTips: {
    text: 'Instale o “Noam Browser Plugin” para desbloquear mais recursos! Referência cruzada da Web,\n    resumo de vídeo na web e muito mais!',
    btn: 'Experimente agora',
  },
}

export default TRANSLATION
