const TRANSLATION = {
  slogen: 'De gemakkelijkste AI-assistent',
  pageHeader: {
    help: 'Helpcentrum',
    contact: 'Neem contact met ons op',
    pricing: 'Prijzen',
    signUp: 'Inloggen',
    settings: 'Instellingen',
    logout: 'Uitloggen',
    logoutSucc: 'Uitloggen gelukt',
  },
  pageFooter: {
    product: {
      title: 'Producten',
      web: 'Webvertaling en samenvatting',
      pdf: 'PDF-vertaling',
      video: 'Videovertaling en samenvatting',
      image: 'Beeldvertaling',
    },
    resources: { title: 'Bronnen' },
    follow: { title: 'Volgen', twitter: 'Twitteren', facebook: 'Facebook' },
    legal: { title: 'Legaal', policy: 'Privacybeleid', terms: 'Servicevoorwaarden' },
  },
  home: {
    getStart: 'Aan de slag',
    getStartedForFree: 'Ga gratis aan de slag',
    top: {
      desc: "Noam is een gratis AI-browserextensie waarmee u samengevatte webpagina's, video's en pdf-inhoud kunt vertalen.\n      Sluit je aan bij Noam en geniet van de magie!",
      title2: 'Eén magie',
      title1: 'Eén klik',
    },
    howItWork: {
      title: 'Dus hoe het werkt',
      desc: "Noam integreert AI-modellen om gebruikers een intelligente ervaring te bieden voor het vertalen en samenvatten van webpagina's in meerdere talen, het vertalen van PDF's en afbeeldingen, het vertalen en samenvatten van video's",
    },
    PDFTranslate: {
      title: 'PDF-vertalen',
      desc: 'Tweetalige vertaling + chat-pdf',
      sub1: {
        title: 'Tweetalig lezen',
        desc: 'Tweetalig lezen, zodat u tijdens het lezen de originele tekst en de vertaling kunt vergelijken om de leesefficiëntie te verbeteren',
      },
      sub2: {
        title: 'Chat-pdf',
        desc: 'AI analyseert en vat samen, en kan uw vragen beantwoorden op basis van de PDF-inhoud',
      },
      sub3: {
        title: 'Nauwkeurigheid en efficiëntie',
        desc: 'AI-aangedreven PDF voor nauwkeurigere en efficiëntere vertalingen',
      },
    },
    aiAssistant: {
      title: 'AI-assistent',
      desc: 'Webvertaling slimmer en handiger maken',
      panel1: {
        title: 'Tweetalige vertaling',
        desc: 'Met tweetalige kruisverwijzingen kunt u de originele tekst vergelijken met de vertaling, zodat u de tekst beter kunt begrijpen en uw leesefficiëntie kunt verbeteren.',
      },
      panel2: {
        left: {
          title: 'Zweef vertaling',
          desc: 'Wanneer u door een webpagina bladert, beweegt u uw muis rechtstreeks over de inhoud die moet worden vertaald om het vertaalresultaat te krijgen.',
        },
        right: {
          title: 'Vertaling van tekstselectie',
          desc: 'Wanneer u door een webpagina bladert, selecteert u direct de tekst die moet worden onderstreept om het vertaalresultaat te krijgen.',
        },
      },
      panel3: {
        one: {
          title: 'Wijs en klik',
          desc: "Webpagina's en video-inhoud altijd, overal en in elk scenario voor u vertalen en samenvatten; waardoor u een soepelere ervaring krijgt!",
        },
        two: {
          title: 'Uitgebreider en handiger',
          desc: 'Meertalige volledige dekking, tweetalig barrièrevrij lezen, vertaling in één sleutel; zorgen voor een ongeëvenaarde ervaring!',
        },
        three: {
          title: 'AI-aangedreven',
          desc: 'AI-aangedreven groot model om de nauwkeurigheid en consistentie van vertalingen te garanderen en de leeservaring te verbeteren',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Videovertaling en samenvatting',
          desc: 'Uw efficiëntie-expert, gecombineerd met chatgpt, vertaalt en vat de YouTube-video-inhoud samen om uw leestijd te besparen!',
        },
        tab2: {
          key: 'Afbeelding',
          title: 'Afbeelding vertalen',
          desc: 'Klik om afbeeldingen te vertalen terwijl u op internet surft',
        },
        tab3: {
          key: 'Web',
          title: 'Webvertalen en samenvatting',
          desc: "Snel belangrijke informatie uit webpagina's halen voor samenvatting, waardoor uw leesefficiëntie wordt verbeterd",
        },
        tag1: 'Eenvoudig',
        tag2: 'Efficiëntie',
        tag3: 'Gemakkelijk te begrijpen',
      },
    },
    userReviews: { title: 'Gebruikersrecensies', desc: 'Vijfsterrenrecensie op de chromewebstore' },
    lastBlock: { desc: 'Betere AI-vertaling en soepelere leeservaring' },
  },
  login: {
    title: 'Welkom bij Noam',
    btn: 'Log in met Google',
    tips: 'Door mij aan te melden, ga ik akkoord',
    terms: 'Gebruiksvoorwaarden',
    and: 'En',
    policy: 'Privacybeleid',
    btn1: 'Meld u nu aan',
    desc: 'Log in om meer ervaringen te ontgrendelen',
  },
  pricing: {
    pageTitle: 'Prijzen',
    desc: 'Vergelijk en kies de beste voor u',
    yearly: 'Jaarlijks',
    monthly: 'Maandelijks',
    discount: '20% korting',
    preMonth: 'Per maand',
    billedYearly: 'Jaarlijks gefactureerd',
    billedMonthly: 'Maandelijks gefactureerd',
    detailedCompare: 'Gedetailleerde vergelijking',
    FAQ: 'Veelgestelde vragen',
    day: 'dag',
    month: 'maand',
    around: 'Rondom',
    free: {
      benefit1: 'Directe vertaling 100 keer per dag',
      benefit2: 'Webvertaling en samenvatting',
      benefit3: '30 vellen Beeldvertaling',
      benefit4: 'Videovertaling en samenvatting',
      benefit5: '10 PDF-vertaling',
      benefit6: '5.000 tokens',
      recommendText: 'Gratis proefperiode',
      btn: 'Veel plezier',
    },
    pro: {
      benefit1: 'Klantenservice Snelle ondersteuning',
      benefit2: '1.000.000 tokens/maand',
      benefit3: 'Webvertaling en samenvatting',
      benefit4: '200 vellen Beeldvertaling',
      benefit5: 'Videovertaling en samenvatting',
      benefit6: '200 PDF-vertaling',
      btn: 'Aan de slag',
      recommendText: 'De meeste mensen kiezen',
    },
    proPlus: {
      benefit1: 'Klantenservice Snelle ondersteuning',
      benefit2: '3.000.000 tokens/maand',
      benefit3: 'Webvertaling en samenvatting',
      benefit4: '500 vel Beeldvertaling',
      benefit5: 'Videovertaling en samenvatting',
      benefit6: '500 PDF-vertaling',
      recommendText: 'De meest geschikte keuze',
    },
    enterprise: {
      benefit1: 'Klantenservice Snelle ondersteuning',
      benefit2: 'Meerdere gebruikers',
      benefit3: 'Maatwerkoplossingen',
      benefit5: 'Exclusief accountmanager',
      recommendText: 'Aangepaste plannen (≥3人)',
      talkToUs: 'Praat met ons',
      btn: 'Contact',
    },
    web: {
      contrastiveTranslation: 'Tweetalige vertaling',
      instantTranslation: 'Onmiddellijke vertaling',
      hoverTranslation: 'Zweef vertaling',
      dictionaryTranslation: 'Vertaling van tekstselectie',
      summary: 'Samenvatting van webpagina',
    },
    imageTranslation: 'Beeldvertaling',
    video: { translation: 'Videovertaling', summary: 'Video-samenvatting' },
    pdf: {
      translation: 'PDF-vertaling',
      maxPage: "Maximaal aantal pagina's in PDF",
      chat: 'Aantal PDF-aanvragen',
    },
    FQAObj: {
      q1: 'Kan ik het gratis gebruiken?',
      a1: 'Er is een gratis versie van Noam die direct gebruikt kan worden.',
      q2: 'Zijn er verschillende lidmaatschapsplannen en tariefopties?',
      a2: 'Naast de gratis versie zijn er drie lidmaatschapsplannen, Pro, Pro+ en Enterprise; u kunt kiezen uit twee betalingsmethoden: doorlopend maandelijks en doorlopend jaarlijks, en de uitgebreide kosten van doorlopend jaarlijks zijn gunstiger.',
      q3: 'Wat is het verschil tussen een doorlopend maandabonnement en een doorlopend jaarabonnement?',
      a3: 'Er is geen verschil in functionaliteit. De uitgebreide kosten van een doorlopend jaarabonnement zijn lager dan die van een doorlopend maandabonnement; Het maandabonnement wordt één keer per maand gefactureerd, terwijl het jaarabonnement één keer per jaar wordt gefactureerd.',
      q4: 'Hoe annuleer ik mijn automatische verlengingsplan?',
      a4: 'U kunt naar de backend-instellingenpagina gaan en ervoor kiezen om u te abonneren om het verlengingsplan te annuleren.',
    },
  },
  upgradeModal: {
    title: 'Herinneringen',
    limitAlertStr:
      'Uw gratis abonnementsquotum is opgebruikt. We raden u aan uw abonnement te upgraden om de ervaring voort te zetten~!',
    upgradeAlertStr:
      'Uw gratis abonnementsquotum is opgebruikt. We raden u aan uw abonnement te upgraden om de ervaring voort te zetten~!',
    btn: 'Upgrade nu',
    more: 'Meer plannen',
    contactStr:
      'Wacht alstublieft op de volgende reset of neem contact met ons op via contact@noam.tools.',
    upload: {
      free: 'Je hebt je limiet van de pdf-vertaling bereikt',
      pro: 'Je hebt deze maand je limiet van de pdf-vertaling bereikt',
    },
    chat: {
      free: 'Je hebt je limiet van de Chatpdf-limiet overschreden',
      pro: 'Je hebt deze maand je limiet van de Chatpdf bereikt',
    },
    pageLimit: { free: "U heeft uw limiet bereikt: de limiet voor PDF-pagina's is overschreden" },
  },
  buyModal: {
    title: 'Abonnementsplan',
    freeTrial: 'Gratis proefperiode van 3 dagen',
    selectLabel: 'Selecteer een abonnement',
    btn: 'Probeer het gratis',
    yearLabel: 'jaar',
    monthLabel: 'maand',
    afterFree: 'Na het proces',
  },
  privacy: {
    title: 'Privacybeleid',
    updateTime: 'Bijgewerkt: 15 maart 2024',
    block1:
      'Wij, Noam (hierna "Noam", "wij", "onze" of "ons" genoemd), respecteren uw privacy en doen er alles aan om alle informatie die we van u of over u verkrijgen te beschermen. Dit Privacybeleid beschrijft onze praktijken met betrekking tot het verzamelen of gebruiken van persoonlijke informatie over uw gebruik van onze website, applicaties en diensten (gezamenlijk de "Diensten" genoemd). Dit privacybeleid is niet van toepassing op de inhoud die we verwerken namens onze zakelijke productklanten, zoals onze API. Het gebruik van deze gegevens is onderworpen aan onze klantenovereenkomst, die de toegang tot en het gebruik van deze producten omvat.',
    subTitle1: 'Persoonlijke informatie die we verzamelen',
    block2:
      'Wij verzamelen de volgende persoonlijke informatie ("Persoonlijke Informatie") met betrekking tot u:',
    block3:
      'Door u verstrekte informatie: Als u een account aanmaakt om onze diensten te gebruiken of met ons te communiceren, verzamelen wij als volgt persoonlijke informatie:',
    subBlock1:
      'Accountinformatie: Wanneer u een account aanmaakt op ons platform, verzamelen wij informatie met betrekking tot uw account, waaronder uw naam, contactgegevens, accountgegevens, betaalkaartinformatie en transactiegeschiedenis (hierna "Accountinformatie" genoemd).',
    subBlock2:
      "Gebruikersinhoud: Wanneer u onze diensten gebruikt, verzamelen wij persoonlijke informatie uit uw invoer, bestandsuploads of aan ons verstrekte feedback (ook wel 'Inhoud' genoemd).",
    subBlock3:
      "Communicatie-informatie: Als u berichten naar ons verzendt, verzamelen wij uw naam, contactgegevens en de inhoud van de berichten die u verzendt (ook wel 'Communicatie-informatie' genoemd).",
    subBlock4:
      "Sociale media-informatie: we hebben pagina's op sociale mediawebsites zoals Instagram, Facebook, Medium, Twitter, YouTube en LinkedIn. Wanneer u interactie heeft met onze sociale mediapagina's, verzamelen wij de persoonlijke informatie die u ons verstrekt, zoals uw contactgegevens (ook wel 'Sociale informatie' genoemd). Bovendien kunnen de bedrijven die onze sociale mediapagina's hosten ons voorzien van geaggregeerde informatie en analyses over onze sociale media-activiteiten.",
    block4:
      'Persoonlijke informatie die automatisch wordt ontvangen door uw gebruik van services: Wanneer u toegang krijgt tot, gebruik maakt van of interactie heeft met de services, ontvangen wij de volgende informatie over uw toegang, gebruik of interactie ("Technische informatie"):',
    subBlock5:
      'Loggegevens: informatie die automatisch door uw browser wordt verzonden wanneer u onze diensten gebruikt. Loggegevens omvatten uw Internet Protocol (IP)-adres, browsertype en -instellingen, de datum en tijd van uw verzoeken en hoe u met onze website omgaat.',
    subBlock6:
      'Gebruiksgegevens: We kunnen automatisch informatie verzamelen over uw gebruik van de dienst, zoals de soorten inhoud die u bekijkt of waarmee u communiceert, de functies die u gebruikt en uw ondernomen acties, evenals uw tijdzone, land, datum en tijd van toegang, user-agent en versie, type computer of mobiel apparaat en uw computerverbinding.',
    subBlock7:
      'Apparaatinformatie: omvat de naam van het apparaat, het besturingssysteem, de apparaat-ID en de browser die u gebruikt. De verzamelde informatie kan afhankelijk zijn van het type apparaat dat u gebruikt en de instellingen ervan.',
    subBlock8:
      'Cookies: Wij gebruiken cookies om onze diensten te exploiteren en te beheren en uw ervaring te verbeteren. Een ‘cookie’ is informatie die door een website die u bezoekt naar uw browser wordt verzonden. U kunt uw browser zo instellen dat hij alle cookies accepteert, alle cookies weigert of u waarschuwt wanneer er een cookie wordt geplaatst, zodat u telkens kunt beslissen of u deze accepteert. Het weigeren van cookies kan er echter voor zorgen dat u bepaalde delen of functies van de website niet kunt gebruiken of de weergave of functionaliteit ervan negatief kunt beïnvloeden. Voor meer informatie over cookies kunt u terecht op Alles over Cookies.',
    subBlock9:
      'Analyse: We kunnen verschillende online analyseproducten gebruiken die cookies gebruiken om ons te helpen analyseren hoe gebruikers onze services gebruiken en om uw ervaring bij het gebruik van de services te verbeteren.',
    subTitle2: 'Hoe wij persoonlijke informatie gebruiken',
    block5: 'We kunnen persoonlijke informatie gebruiken voor de volgende doeleinden:',
    subBlock10: 'Het leveren, beheren, onderhouden en/of analyseren van diensten;',
    subBlock26: 'Het verbeteren van onze dienstverlening en het uitvoeren van onderzoek;',
    subBlock11: 'Met u communiceren;',
    subBlock12: 'Het ontwikkelen van nieuwe projecten en diensten;',
    subBlock13:
      'Het voorkomen van fraude, criminele activiteiten of misbruik van onze diensten en het beschermen van de veiligheid van onze IT-systemen, architectuur en netwerken;',
    subBlock14: 'Het uitvoeren van bedrijfsoverdrachten;',
    subBlock15:
      'Het voldoen aan wettelijke verplichtingen en juridische procedures, het beschermen van onze rechten, privacy, veiligheid of eigendommen, evenals die van onze dochterondernemingen, u of andere derde partijen.',
    block6:
      'Samenvatting of geanonimiseerde informatie: We kunnen persoonlijke informatie samenvoegen of anonimiseren om hergebruik ervan voor identificatiedoeleinden te voorkomen en dergelijke informatie gebruiken om de effectiviteit van onze diensten te analyseren, functies aan onze diensten te verbeteren en toe te voegen, onderzoek uit te voeren en voor soortgelijke doeleinden . Bovendien kunnen we het algemene gedrag en de kenmerken van onze servicegebruikers analyseren en geaggregeerde informatie delen met derden, dergelijke geaggregeerde informatie publiceren of algemeen beschikbaar maken. We kunnen geaggregeerde informatie verzamelen via de diensten, via cookies en via andere middelen die in dit Privacybeleid worden beschreven. We zullen geanonimiseerde informatie in een anonieme of geanonimiseerde vorm bewaren en gebruiken, en we zullen niet proberen dergelijke informatie opnieuw te identificeren, tenzij dit wettelijk vereist is.',
    subTitle3: 'Openbaarmaking van persoonlijke informatie',
    block7:
      'In bepaalde gevallen kunnen wij uw persoonlijke gegevens zonder verdere kennisgeving aan derden verstrekken, tenzij dit wettelijk vereist is:',
    subBlock16:
      'Leveranciers en dienstverleners: Om ons te helpen bij het voldoen aan de operationele behoeften van het bedrijf en bij het uitvoeren van bepaalde diensten en functies, kunnen we persoonlijke informatie verstrekken aan leveranciers en dienstverleners, waaronder aanbieders van hostingdiensten, aanbieders van clouddiensten en andere dienstverleners op het gebied van informatietechnologie, e-mailcommunicatiesoftware en dienstverleners voor webanalyse, enz. Deze partners zullen persoonlijke informatie alleen op onze aanwijzing openen, verwerken of opslaan terwijl ze hun taken voor ons uitvoeren.',
    subBlock17:
      "Bedrijfsoverdrachten: Als we strategische transacties, reorganisaties, faillissementen, faillissementsovernames of dienstenovergangen naar een andere aanbieder uitvoeren (gezamenlijk 'Transacties' genoemd), kunnen uw persoonlijke gegevens en andere gegevens worden gedeeld met transactiepartners en andere partijen die helpen bij de Transacties tijdens due diligence en als onderdeel van de Transacties samen met andere activa overgedragen aan een opvolger of aangesloten onderneming.",
    subBlock18:
      'Wettelijke vereisten: We kunnen uw persoonlijke gegevens delen met overheidsinstanties, sectorgenoten of andere derde partijen, inclusief uw interactie-informatie met onze diensten, (1) indien vereist door de wet of als wij van mening zijn dat dergelijke actie noodzakelijk is om te voldoen aan wettelijke verplichtingen; (2) om onze rechten of eigendommen te beschermen en verdedigen; (3) als we eenzijdig vaststellen dat er sprake is van een schending van onze voorwaarden, ons beleid of onze wetten; (4) om fraude of andere illegale activiteiten op te sporen of te voorkomen; (5) om onze producten, werknemers of gebruikers, of de openbare veiligheid, beveiliging en integriteit te beschermen; of (vi) ter verdediging tegen wettelijke aansprakelijkheid.',
    subTitle4: 'Uw rechten',
    block8:
      'Afhankelijk van uw geografische locatie kunnen personen in de Europese Economische Ruimte, het Verenigd Koninkrijk en wereldwijd bepaalde wettelijke rechten hebben met betrekking tot hun persoonlijke gegevens. U heeft bijvoorbeeld mogelijk het recht om:',
    subBlock19: 'Toegang tot uw persoonlijke gegevens en informatie over hoe deze worden verwerkt.',
    subBlock20: 'Verwijder uw persoonlijke gegevens uit onze administratie.',
    subBlock21: 'Corrigeer of update uw persoonlijke gegevens.',
    subBlock22: 'Uw persoonlijke gegevens overdragen aan een derde partij (dataportabiliteit).',
    subBlock23: 'Beperk de manier waarop wij uw persoonlijke gegevens verwerken.',
    subBlock24:
      'Uw toestemming intrekken: als wij vertrouwen op toestemming als wettelijke basis voor verwerking, kunt u uw toestemming op elk gewenst moment intrekken.',
    subBlock25: 'Maak bezwaar tegen de manier waarop wij uw persoonlijke gegevens verwerken.',
    subTitle5: 'Links naar andere websites',
    block9:
      'De dienst kan links bevatten naar andere websites die niet door Noam worden beheerd of gecontroleerd, inclusief sociale-mediadiensten ("websites van derden"). De informatie die u deelt met websites van derden valt onder het specifieke privacybeleid en de servicevoorwaarden van de websites van derden, en niet onder dit privacybeleid. Wij bieden deze links voor uw gemak aan en impliceren niet dat wij deze websites goedkeuren of beoordelen. Neem rechtstreeks contact op met websites van derden voor informatie over hun privacybeleid en voorwaarden.',
    subTitle6: 'Beveiliging en bewaring van informatie',
    block10:
      "We implementeren commercieel redelijke technische, administratieve en organisatorische maatregelen om online en offline persoonlijke informatie te beschermen tegen verlies, misbruik en ongeoorloofde toegang, openbaarmaking, wijziging of vijandige handelingen. Geen enkele verzending via internet of e-mail kan echter volledige veiligheid of nauwkeurigheid garanderen. Met name e-mails die naar ons of van ons worden verzonden, zijn mogelijk niet veilig. U moet dus bijzonder voorzichtig zijn bij het beslissen welke informatie u ons via de service of e-mail wilt sturen. Bovendien zijn wij niet verantwoordelijk voor enige belemmering van privacy-instellingen of beveiligingsmaatregelen in de service of beveiligingsinstellingen of beveiligingsmaatregelen van websites van derden. We bewaren uw persoonlijke gegevens zo lang als nodig is om u diensten te verlenen of voor andere legitieme zakelijke doeleinden, zoals het oplossen van geschillen, beveiligings- en veiligheidsredenen, of het voldoen aan onze wettelijke verplichtingen. Hoe lang we persoonlijke informatie bewaren, hangt af van vele factoren, zoals de hoeveelheid, de aard, de gevoeligheid van de informatie, de potentiële risico's van ongeoorloofd gebruik of openbaarmaking, de doeleinden waarvoor we de informatie verwerken en andere juridisch bindende overwegingen.",
    subTitle7: 'Wijziging van het privacybeleid',
    block11:
      'We kunnen dit privacybeleid van tijd tot tijd bijwerken. Wanneer we dit doen, zullen we een bijgewerkte versie op deze pagina plaatsen, tenzij anders vereist door de toepasselijke wetgeving.',
    subTitle8: 'Hoe u contact met ons kunt opnemen',
    block12:
      'Als u onbeantwoorde vragen of opmerkingen heeft over dit privacybeleid, kunt u contact opnemen met onze klantenservice. E-mail:',
  },
  terms: {
    title: 'Servicevoorwaarden',
    updateTime: 'Bijgewerkt: 15 maart 2024',
    block1: 'Bedankt dat je Noam gebruikt!',
    block2:
      'Deze Gebruiksvoorwaarden zijn van toepassing op uw gebruik van de diensten van Noam, inclusief onze applicatieprogrammeringsinterfaces, software, tools, ontwikkelaarsdiensten, gegevens, documentatie en website (gezamenlijk de "Diensten" genoemd). Door onze Services te gebruiken, gaat u ermee akkoord zich aan deze voorwaarden te houden. In ons privacybeleid wordt uitgelegd hoe we persoonlijke informatie verzamelen en gebruiken.',
    subTitle1: 'Registratie en toegang',
    block3:
      'Als je jonger bent dan 18 jaar, moet je toestemming krijgen van je ouders of wettelijke voogden om de Dienst te gebruiken. Als u de Service namens een andere persoon of entiteit gebruikt, moet u bevoegd zijn om deze voorwaarden namens hen te accepteren. U moet nauwkeurige en volledige informatie verstrekken om u voor een account te registreren. U mag uw toegangsgegevens of account niet aan iemand buiten uw organisatie verstrekken en u bent verantwoordelijk voor alle activiteiten die met uw inloggegevens worden uitgevoerd.',
    subTitle2: 'Gebruiksvereisten',
    block4:
      '(a) Gebruik van de Dienst. U heeft onder deze voorwaarden toegang tot de Dienst en wij verlenen u een niet-exclusief recht om de Dienst te gebruiken. Wanneer u de Dienst gebruikt, zult u zich houden aan deze voorwaarden en alle toepasselijke wetten. Wij en onze dochterondernemingen bezitten alle rechten, titels en belangen in de Service.',
    block5:
      '(b) Feedback. Wij zijn blij met feedback, opmerkingen, ideeën, suggesties en verbeteringen. Als u dergelijke inhoud verstrekt, kunnen wij deze zonder enige beperking en zonder compensatie aan u gebruiken.',
    block6:
      '(c) Beperkingen. U mag niet: (1) de Dienst gebruiken op een manier die de rechten van anderen schendt, misbruikt of schendt; (2) de broncode of onderliggende componenten van de modellen, algoritmen en systemen van de Dienst reverse-engineeren, decompileren, demonteren, vertalen of anderszins proberen te ontdekken (tenzij dergelijke beperkingen door de toepasselijke wetgeving verboden zijn); (3) de output van de Dienst gebruiken om modellen te ontwikkelen die concurreren met Noam; (4) gegevens of uitvoer uit de Dienst extraheren met behulp van geautomatiseerde of programmeermethoden, tenzij toegestaan ​​via de API, inclusief scraping, webharvesting of webgegevensextractie; (5) de output van de Dienst weergeven als kunstmatig gegenereerd terwijl dit niet het geval is, of anderszins in strijd zijn met ons gebruiksbeleid; (6) API-sleutels kopen, verkopen of overdragen zonder onze voorafgaande toestemming; of (7) ons persoonlijke gegevens verstrekken van kinderen jonger dan 13 jaar of de toepasselijke leeftijd voor digitale toestemming. U voldoet aan alle tarieflimieten en andere vereisten in onze documentatie.',
    block7:
      '(d) Diensten van derden. Alle software, services of andere producten van derden die verband houden met de Service zijn onderworpen aan hun eigen voorwaarden, en wij zijn niet verantwoordelijk voor producten van derden.',
    subTitle3: 'Inhoud',
    block8:
      '(a) Uw inhoud. U kunt input ("Input") leveren aan de Dienst en output ("Output") ontvangen die is gegenereerd en geretourneerd op basis van de Input (gezamenlijk "Inhoud" genoemd). Tussen de partijen, en voor zover toegestaan ​​door de toepasselijke wetgeving, bent u eigenaar van alle Invoer. Noam kan Content gebruiken om de Service te leveren en te onderhouden, om te voldoen aan de toepasselijke wetgeving en om ons beleid af te dwingen. U bent verantwoordelijk voor de inhoud en zorgt er ook voor dat deze geen toepasselijke wetten of deze voorwaarden schendt.',
    block9:
      '(b) Nauwkeurigheid. Kunstmatige intelligentie en machinaal leren zijn snel evoluerende onderzoeksgebieden. We streven er voortdurend naar om onze service te verbeteren om deze nauwkeuriger, betrouwbaarder, veiliger en voordeliger te maken. Gezien de probabilistische aard van machine learning kan het gebruik van onze service in bepaalde gevallen resulteren in onjuiste resultaten, die mogelijk geen nauwkeurige weergave zijn van echte individuen, plaatsen of feiten. U moet de nauwkeurigheid van elke uitvoer op passende wijze beoordelen op basis van uw gebruiksscenario, onder meer door de uitvoer handmatig te beoordelen.',
    subTitle4: 'Kosten en betaling',
    block10:
      '(a) Kosten en facturering. U betaalt alle kosten ("Vergoedingen") volgens de prijzen en voorwaarden op de toepasselijke prijspagina of zoals schriftelijk tussen ons overeengekomen. We behouden ons het recht voor om prijsfouten of vergissingen te corrigeren, zelfs als we facturen hebben uitgegeven of betaling hebben ontvangen. U verstrekt volledige en nauwkeurige factuurgegevens, inclusief een geldige en geautoriseerde betalingsmethode. Wij zullen uw betalingsmethode periodiek in rekening brengen zoals overeengekomen, maar wij kunnen de factuurdatum redelijkerwijs wijzigen. U geeft Noam, haar dochterondernemingen en externe betalingsverwerkers toestemming om kosten in rekening te brengen via uw betalingsmethode. Als uw betaling mislukt, zullen wij u hiervan schriftelijk op de hoogte stellen en kunnen wij de toegang tot de Dienst opschorten totdat de betaling is ontvangen. Tenzij anders aangegeven in deze overeenkomst, zijn betalingen niet-restitueerbaar.',
    block11:
      '(b) Belastingen. Tenzij anders vermeld omvatten de vergoedingen geen federale, staats-, lokale en buitenlandse belastingen, heffingen en soortgelijke aanslagen ("Belastingen"). U bent verantwoordelijk voor alle belastingen die verband houden met uw aankopen, met uitzondering van belastingen op basis van ons netto-inkomen waarvoor wij u factureren. U stemt ermee in dergelijke belastingen onmiddellijk te betalen, ons rechtsgeldige privacyverklaringen te verstrekken en de noodzakelijke toestemmingen te verkrijgen voor het verwerken van dergelijke gegevens, en u garandeert ons dat u dergelijke gegevens behandelt in overeenstemming met de toepasselijke wetgeving.',
    block12:
      '(c) Prijswijzigingen. Wij kunnen onze prijzen wijzigen door u hiervan op de hoogte te stellen via uw account en/of onze website. Prijsverhogingen worden onmiddellijk na plaatsing van kracht. Eventuele prijswijzigingen zijn van toepassing op de Vergoedingen voor uw account na de ingangsdatum van de wijziging.',
    block13:
      '(d) Geschillen en achterstallige betalingen. Als u eventuele vergoedingen of belastingen wilt betwisten, neem dan binnen dertig (30) dagen na de factuurdatum contact op met contact@noam.tools.',
    block14:
      '(e) Gratis gebruik. U mag niet meerdere accounts aanmaken om gratis credits te verkrijgen. Als we vaststellen dat u de gratis tegoeden niet te goeder trouw gebruikt, kunnen we u standaardkosten in rekening brengen of stoppen met het verlenen van toegang tot de Dienst.',
    subTitle5: 'Vertrouwelijkheid, beveiliging en gegevensbescherming',
    block15:
      '(a) Vertrouwelijkheid. Mogelijk hebt u toegang tot vertrouwelijke informatie van Noam. U mag vertrouwelijke informatie alleen gebruiken zoals toegestaan ​​onder deze voorwaarden voor het gebruik van de Dienst. U mag vertrouwelijke informatie niet bekendmaken aan derden, en u zult vertrouwelijke informatie beschermen op een manier die niet minder beschermend is dan uw eigen soortgelijke vertrouwelijke informatie, tenminste met redelijke zorg. Vertrouwelijke informatie is niet-openbare informatie die door Noam als vertrouwelijk is bestempeld of die onder de gegeven omstandigheden redelijkerwijs als vertrouwelijk moet worden behandeld, inclusief software, specificaties en andere niet-openbare bedrijfsinformatie. Vertrouwelijke informatie omvat geen informatie die: (1) zonder uw schuld algemeen beschikbaar wordt voor het publiek; (2) u in uw bezit had zonder enige verplichting tot vertrouwelijkheid voordat u het onder deze voorwaarden ontving; (3) op wettige wijze aan u wordt bekendgemaakt door een derde partij, zonder enige vertrouwelijkheidsverplichtingen; of (4) onafhankelijk door u is ontwikkeld zonder gebruik te maken van de vertrouwelijke informatie. U mag vertrouwelijke informatie openbaar maken indien vereist door de wet, de rechtbank of een ander overheidsbevel, maar u moet Noam hiervan vooraf op een redelijke manier schriftelijk op de hoogte stellen en, voor zover mogelijk, redelijke inspanningen leveren om de reikwijdte van de openbaarmaking te beperken, inclusief het assisteren van ons in het verzet tegen openbaarmakingsverzoeken.',
    block16:
      '(b) Beveiliging. U moet redelijke en passende maatregelen treffen om uw toegang tot en gebruik van de Dienst te helpen beveiligen. Als u kwetsbaarheden of overtredingen ontdekt die verband houden met uw gebruik van de Dienst, moet u onmiddellijk contact opnemen met Noam en details verstrekken over de kwetsbaarheid of overtreding.',
    block17:
      '((c) Verwerking van persoonlijke gegevens. Als u de Service gebruikt om persoonlijke gegevens te verwerken, moet u juridisch voldoende privacyverklaringen verstrekken en de noodzakelijke toestemming verkrijgen voor het verwerken van dergelijke gegevens, en u garandeert ons dat u dergelijke gegevens behandelt in overeenstemming met de toepasselijke wetgeving. .',
    subTitle6: 'Termijn en beëindiging',
    block18:
      '(a) Beëindiging; Oponthoud. Deze voorwaarden zijn van kracht vanaf uw eerste gebruik van de Service en blijven van kracht totdat ze worden beëindigd. U kunt deze voorwaarden op elk moment en om welke reden dan ook beëindigen door het gebruik van de Dienst en de Inhoud te staken. We kunnen deze voorwaarden om welke reden dan ook met voorafgaande kennisgeving beëindigen. We kunnen deze voorwaarden onmiddellijk beëindigen door u op de hoogte te stellen als u artikel 2 (Gebruiksvereisten), artikel 5 (Vertrouwelijkheid, beveiliging en gegevensbescherming), artikel 8 (Geschillenbeslechting) of artikel 9 (Algemene voorwaarden) wezenlijk schendt, of als onze relatie met een externe technologieleverancier die buiten onze macht ligt, wijzigingen of voor naleving van wetten of overheidsverzoeken. We kunnen uw toegang tot de Dienst opschorten als u deze voorwaarden niet naleeft, of als uw gebruik een veiligheidsrisico voor ons of een derde partij met zich meebrengt, of als we vermoeden dat uw gebruik frauduleus is of ons of een derde partij aan aansprakelijkheid kan blootstellen. .',
    block19:
      '(b) Gevolgen van beëindiging. Bij beëindiging stopt u met het gebruik van de Service en retourneert of vernietigt u onmiddellijk alle vertrouwelijke informatie zoals aangegeven door ons. De bepalingen van deze voorwaarden die door hun aard de beëindiging of afloop moeten overleven, inclusief maar niet beperkt tot secties 3 en 5-9, zullen van kracht blijven.',
    subTitle7: 'Schadeloosstelling; Disclaimers; Beperking van aansprakelijkheid',
    block20:
      '(a) Schadeloosstelling. U verdedigt, vrijwaart en houdt ons, onze dochterondernemingen en ons personeel schadeloos van en tegen eventuele claims, verliezen en kosten (inclusief advocatenhonoraria) die voortvloeien uit of verband houden met uw gebruik van de Service, inclusief uw Inhoud, uw gebruik van producten of diensten gerelateerd aan de Service, en uw overtreding van deze voorwaarden of toepasselijke wetgeving.',
    block21:
      '(b) Disclaimers. De Dienst wordt geleverd ‘as is’. Voor zover toegestaan ​​door de wet, geven wij en onze aangesloten bedrijven en licentiegevers geen verklaringen of garanties van welke aard dan ook met betrekking tot de Service en geven ze geen garanties, inclusief maar niet beperkt tot garanties van verkoopbaarheid, geschiktheid voor een bepaald doel, bevredigende kwaliteit, niet-inbreukmakendheid , en rustig genieten, en eventuele garanties die voortvloeien uit de handel of het gebruik van de handel. Wij garanderen niet dat de Dienst ononderbroken, accuraat of vrij van fouten zal zijn, of dat de Inhoud veilig zal zijn, niet verloren zal gaan of niet zal worden gewijzigd.',
    block22:
      '(c) Beperking van aansprakelijkheid. Noch wij, noch onze gelieerde ondernemingen, noch onze licentiegevers zijn aansprakelijk voor enige indirecte, incidentele, speciale, gevolg- of punitieve schade, inclusief winstderving, reputatieverlies, verlies van gebruik, verlies van gegevens of andere immateriële verliezen, zelfs als wij zijn op de hoogte gesteld van de mogelijkheid van dergelijke schade. Volgens deze voorwaarden zal onze totale aansprakelijkheid niet hoger zijn dan de Vergoedingen die u aan de Service hebt betaald in de twaalf (12) maanden voorafgaand aan de claim, of honderd Amerikaanse dollar ($100), afhankelijk van welke waarde het grootst is. De beperkingen in dit artikel zijn van toepassing voor zover maximaal toegestaan ​​door de toepasselijke wetgeving.',
    subTitle8: 'Toepasselijk recht en jurisdictie',
    block23:
      'Op de Voorwaarden en uw gebruik van de Services is het recht van de Verenigde Staten van toepassing, met uitzondering van de Amerikaanse conflictregels. Uw gebruik van de Applicatie kan ook onderworpen zijn aan andere lokale, provinciale, nationale of internationale wetten. Eventuele juridische stappen of procedures met betrekking tot deze Voorwaarden zullen uitsluitend worden voorgelegd aan de rechtbanken van de Verenigde Staten, en u stemt ermee in zich te onderwerpen aan de persoonlijke jurisdictie van dergelijke rechtbanken.',
    subTitle9: 'Algemene voorwaarden',
    block24:
      '(a) Relatie tussen de partijen. Noam en u zijn onafhankelijke contractanten, en geen van beide partijen heeft de macht om de andere partij te vertegenwoordigen of te binden of verplichtingen voor de andere partij op zich te nemen zonder voorafgaande schriftelijke toestemming van de andere partij.',
    block25:
      "(b) Merkgebruik. U mag de naam, logo's of handelsmerken van Noam of een van haar dochterondernemingen niet gebruiken zonder onze voorafgaande schriftelijke toestemming.",
    subTitle10: 'Deelbaarheid',
    block26:
      'Als wordt vastgesteld dat een bepaling van deze voorwaarden ongeldig, onwettig of niet-afdwingbaar is, blijven de overige bepalingen volledig van kracht.',
  },
  contact: {
    title: 'Neem contact met ons op',
    block1:
      'Voor maatwerk of als u vragen of suggesties heeft, kunt u contact met ons opnemen via contact@noam.tools en wij reageren direct.',
    block2: 'Steun ons door ons te beoordelen in de Google Apps Extension Store op ★★★★★.',
    block3: 'Help anderen door deze extensie te delen:',
  },
  pdf: {
    toast1: 'PDF wordt geladen, probeer het later opnieuw!',
    toast2: 'PDF-inhoud is te veel, ondersteunt de dialoog voorlopig niet',
    toast3: 'Bezig met downloaden. Probeer het opnieuw nadat het downloaden is voltooid.',
    shareMsg1: 'Deel linkgeneratie succesvol',
    shareMsg2: 'Deel inhoudsupdates succesvol',
    shareMsg3:
      'Het genereren van een link voor delen is mislukt. Probeer het later opnieuw of neem contact op met onze klantenservice.',
    shareMsg4:
      'Het delen van de inhoudsupdate is mislukt. Probeer het later opnieuw of neem contact op met de klantenservice',
    shareBtnText1: 'Genereer deellinks',
    shareBtnText2: 'Bijgewerkte inhoud voor delen',
    uploading: 'Uploaden',
    uploadingMsg: 'Even geduld, het uploaden duurt enige tijd.',
    cancelUpload: 'Annuleren',
    uploadErr:
      'Bestandsupload mislukt. Probeer het later opnieuw of neem contact op met de klantenservice',
    uploadMsg1: 'Sleep het bestand hierheen',
    uploadMsg2: 'Ondersteunde bestandstypen: PDF |  Maximale bestandsgrootte: 100 MB',
    uploadMsg3:
      'Klik om bestanden te selecteren of te slepen en neer te zetten om ze hier te uploaden',
    searchPlaceholder: 'Zoekopdracht',
    collectionEmpty: 'Geen inhoud in de collectie~',
    kw: 'nietje',
    video: 'video',
    link: 'link',
    dataLoading: 'Gegevens laden...',
    freeBannerTips: 'Einde van gratis pdf-vertaling',
    vipBannerTips: 'Je hebt deze maand je limiet van de pdf-vertaling bereikt.',
    freeBannerBtn: 'Upgrade om meer te genieten',
    vipBannerBtn: 'Upgrade om meer te genieten',
    dialogTitle: 'Aandacht',
    dialogContent: 'Weet je zeker dat je het wilt verwijderen?',
    okBtn: 'Verwijderen',
    cancelBtn: 'Annuleren',
    unLoginToast: 'Log in om deze functie te gebruiken',
    parseErrTips: 'Kan het bestand niet parseren',
    new: 'nieuw bestand',
    parseErrDialogContent:
      'De huidige PDF-link ondersteunt geen directe vertaling. Download na vertaling de originele PDF-link',
    okBtn2: 'herkennen',
    notPdf: 'Alleen PDF-bestanden',
    uploadingMsg1: 'Bestand wordt geüpload. Probeer het later opnieuw.',
    uploadingMsg2: 'Er wordt momenteel een bestand geconverteerd. Probeer het later opnieuw.',
    uploadingMsg3:
      'Het onderzoek naar de voortgang van de conversie is mislukt. Probeer het later opnieuw of neem contact op met de klantenservice',
    uploadingMsg4:
      'Noam heeft geen directe toegang tot uw lokale bestanden. Upload handmatig een PDF ter vertaling.',
    uploadingMsg5:
      'Kan de bestands-URL niet ophalen. Probeer het later opnieuw of neem contact op met de klantenservice.',
    fileMsg: 'Huidig ​​bestandsvolume:',
    fileMsg2: 'Upload een bestand dat kleiner is dan 100 miljoen',
    fileErrMsg:
      'Bestand lezen mislukt. Probeer het later opnieuw of neem contact op met de klantenservice.',
    parseFileToast:
      'Het parseren van bestanden is mislukt. Probeer het later opnieuw of neem contact op met onze klantenservice.',
    noId: 'Bestands-ID bestaat niet',
    autoDetect: 'Automatische detectie',
    hasTransTask: 'Er wordt een taak vertaald. Wacht alstublieft.',
    transSucc: 'De vertaling is gelukt!',
    entry: 'PDF-lijst',
    listError:
      'De PDF-lijst kan niet worden geladen. Probeer het later opnieuw of neem contact op met de klantenservice.',
    collectionTab: 'Memo',
    pdfLoading: 'Parseren...',
    installTips:
      "Installeer de kleine plug-in, u kunt meer PDF-bestanden vertalen en u kunt ook een soepele vertaling van webpagina's ervaren:",
    webTrans: 'volledige kruisverwijzing',
    hoverTrans: "Beweeg de muis over de vertaling van alinea's",
    selectTrans: 'onderstreep de woorden en vertaal ze',
    installText: 'One-stop-shop voor al uw dagelijkse vertaalbehoeften',
    start: 'Start uw gratis ervaring',
    shareTips: 'De PDF-vertaling is voltooid, ga je gang en deel het met je vrienden!',
    download: {
      loading: 'Opgeslagen...',
      btn: 'Bespaar nu',
      trans: 'Alleen vertaling',
      both: 'tweetaligheid',
      download: 'Downloaden',
      msg1: 'Sommige vertalingen van het huidige document zijn langer dan de originele tekst, en de tekst kan na het downloaden overlappen. Het wordt daarom aanbevolen om deze op de webpagina te lezen.',
      msg2: '1. De inhoud is niet volledig vertaald. Download deze na de vertaling volledig.',
      msg3: '2. Het documentgedeelte van de vertaling is te lang. U kunt het op de webpagina lezen om tekstoverlapping te voorkomen.',
      msg4: 'Er worden momenteel taken vertaald. Probeer het opnieuw nadat de vertaling is voltooid.',
      fileName: 'Bestandsnaam:',
      fileType: 'Bestandstype downloaden:',
      transContinue: 'Ga door met vertalen',
      save: 'Opslaan naar lokaal',
      downloadMsg1: 'PDF Vooruitgang in vertaling:',
      downloadMsg2: 'Geprojecteerde vereisten',
      minute: 'Notulen...',
      downloadSucc: 'De PDF-download is voltooid, klik nu op opslaan!',
      savePrint: 'Opslaan en afdrukken',
      loading2: 'Laden...',
      desc: 'Opslaan in lokaal: de gedownloade vertalingen worden opgeslagen als afbeeldingen; Opslaan en afdrukken: De gedownloade vertalingen blijven in hun oorspronkelijke formaat behouden en ondersteunen kopiëren.',
      success: 'Succesvol downloaden',
      cancel:
        'Als u het pop-upvenster sluit, wordt het downloaden van het PDF-bestand geannuleerd. Weet u zeker dat u wilt annuleren?',
      downloadTip:
        'De PDF wordt gedownload. Sluit het pop-upvenster niet tijdens het downloaden om te voorkomen dat het downloaden mislukt.',
      progress:
        'PDF wordt vertaald. Voortgang: {{progress}}, zal naar verwachting {{minutes}} minuten duren...',
      error: 'Kan niet lokaal opslaan. Probeer het bestand af te drukken',
    },
    guide: {
      title1: 'Tweetalige vertaling',
      title2: 'Chat-pdf',
      text1: 'Tweetalig lezen',
      text2:
        'Tweetalig lezen, zodat u tijdens het lezen de originele tekst en de vertaling kunt vergelijken om de leesefficiëntie te verbeteren',
      text3: 'Chat-pdf',
      text4: 'AI analyseert en vat samen, en kan uw vragen beantwoorden op basis van de PDF-inhoud',
      text5: 'Nauwkeurigheid en efficiëntie',
      text6: 'AI-aangedreven PDF voor nauwkeurigere en efficiëntere vertalingen',
      placeholder: 'Vul de PDF-link in',
      start: 'Begin met vertalen',
      toast: 'Voer een geldige online pdf-link in die eindigt op .pdf',
    },
    original: 'Origineel',
    parallel: 'Parallel',
    trans: 'Vertaling',
    initial: 'PDF vertalen',
    thumbnail: 'catalogi',
    downloadBtn: 'Downloaden',
    shareBtn: 'Deel',
    uploadBtn: 'Uploaden',
    installText2: 'Installeer eerst',
    chatPdf: {
      clearSucc: 'Succesvol gewist',
      clear: 'Duidelijk',
      clearContent:
        'Na het wissen wordt er een nieuw gesprek aangemaakt en wordt de inhoud van dit gesprek niet opgeslagen. Weet u zeker dat u het wilt wissen?',
      clearDialogTitle: 'Aandacht',
      cancel: 'Annuleren',
      chatTitle: 'Welkom bij Noam, uw PDF-samenvatting is klaar:',
      summaryTitle: 'Vragen waarin u mogelijk geïnteresseerd bent:',
      placeholder: 'Voer een vraag over de PDF in',
      copy: 'Kopiëren',
      copySucc: 'Kopieer succes',
      historyTitle: 'Geschiedenis',
      expand: 'Instorten',
      noDataDesc: 'Geen geschiedenis~',
      noMore: 'Niet meer.',
      Search: 'Zoekopdracht',
      delete: 'Verwijderen',
      loading: 'laden...',
      deleteTitle: 'Tip',
      deleteContent:
        'Na verwijdering is deze PDF niet meer te herstellen. Wilt u deze verwijderen?',
      deleteOk: 'Verwijderen',
      deleteCancel: 'Annuleren',
      deleteSuccess: 'Succesvol verwijderen',
      listError:
        'Het laden van de leeslijst is mislukt. Probeer het later opnieuw of neem contact op met de klantenservice',
    },
    shareModal: {
      shareSetting: 'Inhoudsinstellingen delen',
      shareText: 'Deel',
      text1: 'Instellingen voor delen',
      text2: 'Genereer deellinks',
      range: 'Domein:',
      custom: 'Maatwerk:',
      page: "pagina's",
      sourceLang: 'Bron:',
      target: 'Doel:',
      errMsg:
        'De inhoud van het huidige deelbereik is nog niet volledig vertaald. Vertaal deze dus voordat u deze deelt.',
      pageNuErr: 'Voer het juiste paginanummer in',
      trans: 'Vertalen',
      cancel: 'Annuleren',
      update: 'Bijgewerkte koppelingen voor delen',
      gen: 'Deel',
      shareTips: 'Deel de vertaalde PDF-link met je vrienden om tegen te lezen:',
      copySucc: 'Kopieer link succesvol',
      copy: 'Kopiëren',
      settings: 'Instellingen voor delen:',
      modify: 'bewerken',
      translateTips:
        'Tip: Als de vertaling lang duurt, kunt u het pop-upvenster sluiten en delen nadat de vertaling is voltooid.',
      translating: 'PDF-vertaling...',
      remain: 'overig',
      remain1: 'Totale verwachte vereisten',
      totalPage: "Totaal 1 pagina's",
      search: 'Zoekopdracht',
      notSupport: 'Deze taal wordt momenteel niet ondersteund',
    },
    toolbar: { Adjust: 'Aanpassen', autoFit: 'Automatische pasvorm' },
    memoTotalCount: 'Totaal 1 inhoud',
    dragTipModal: {
      press: 'Druk op',
      black: 'ruimte',
      leftKey: 'linker onderkant',
      tips: 'slepen en bekijken.',
      ok: 'Ik snap het',
    },
  },
  locale: {
    zh: 'Chinese',
    en: 'Engels',
    es: 'Spaans',
    ru: 'Russisch',
    pt: 'Portugees (Brazilië)',
    id: 'Indonesisch',
    cs: 'Tsjechisch',
    de: 'Duits',
    fr: 'Frans',
    it: 'Italiaans',
    nl: 'Nederlands',
    pl: 'Pools',
    vi: 'Vietnamees',
    tr: 'Turks',
    uk: 'Oekraïens',
    zhHant: 'Traditioneel Chinees',
    ja: 'Japanse',
    ko: 'Koreaans',
    ar: 'Arabisch',
    fa: 'Perzisch',
    th: 'Thais',
    hi: 'Hindi',
    noSearchLang: 'Deze taal wordt niet ondersteund',
    placeholder: 'Zoekopdracht',
    af: 'Afrikaans',
    am: 'Amhaars',
    as: 'Assamees',
    az: 'Azerbeidzjaans',
    ba: 'Bashkir',
    bg: 'Bulgaars',
    bn: 'Bangla',
    bo: 'Tibetaans',
    bs: 'Bosnisch',
    ca: 'Catalaans',
    cy: 'Welsh',
    da: 'Deens',
    dsb: 'Nedersorbisch',
    dv: 'Duikhi',
    el: 'Grieks',
    et: 'Ests',
    eu: 'baskisch',
    fi: 'Fins',
    fil: 'Filipijns',
    fj: 'Fijisch',
    frCA: 'Frans (Canada)',
    ga: 'Iers',
    gl: 'Galicisch',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Hebreeuws',
    hr: 'Kroatisch',
    hsb: 'Boven-Sorbisch',
    ht: 'Haïtiaans Creools',
    hu: 'Hongaars',
    hy: 'Armeens',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'IJslands',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latijn)',
    ka: 'Georgisch',
    kk: 'Kazachs',
    km: 'Khmer',
    kmr: 'Koerdisch (Noordelijk)',
    kn: 'Kanada',
    ku: 'Koerdisch (centraal)',
    ky: 'Kirgizisch',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'Litouws',
    lug: 'Ganda',
    lv: 'Lets',
    lzh: 'Chinees (literair)',
    mai: 'Maithili',
    mg: 'Madagaskar',
    mi: 'Maori',
    mk: 'Macedonisch',
    ml: 'Malayalam',
    mnCyrl: 'Mongools (Cyrillisch)',
    mnMong: 'Mongools (traditioneel)',
    mr: 'Marathi',
    ms: 'Maleis',
    mt: 'Maltees',
    mww: 'Hmong Daw',
    my: 'Myanmar (Birmees)',
    nb: 'Noors',
    ne: 'Nepalees',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    prs: 'Dari',
    ps: 'Pasjto',
    ptPT: 'Portugees (Portugal)',
    ro: 'Roemeense',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singalees',
    sk: 'Slowaaks',
    sl: 'Sloveens',
    sm: 'Samoaans',
    sn: 'Shona',
    so: 'Somalisch',
    sq: 'Albanees',
    srCyrl: 'Servisch (Cyrillisch)',
    srLatn: 'Servisch (Latijn)',
    st: 'Sesotho',
    sv: 'Zweeds',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    ti: 'Tigrinya',
    tk: 'Turkmeens',
    tlhLatn: 'Klingon (Latijn)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongaans',
    tt: 'Tataars',
    ty: 'Tahitiaans',
    ug: 'Oeigoers',
    ur: 'Urdu',
    uz: 'Oezbeeks (Latijn)',
    xh: 'Xhosa',
    yo: 'Joruba',
    yua: 'Yucateek Maya',
    yue: 'Kantonees (traditioneel)',
    zhHans: 'Chinees vereenvoudigd',
    zu: 'Zoeloe',
    auto: 'Automatische detectie',
  },
  stripe: {
    contact: 'Neem bij vragen contact met ons op via contact@noam.tools!',
    fail: {
      title: 'Betaling mislukt',
      text1: 'Helaas kunnen wij uw betaalverzoek niet verwerken.',
      text2: 'Controleer uw internetverbinding en probeer het opnieuw.',
      btn: 'Opnieuw proberen',
    },
    succ: {
      title: 'Gefeliciteerd',
      text1: 'welkom bij Noam,',
      text2: 'laten we samen aan de slag gaan!',
      btn: 'Laten we wat magie doen',
    },
  },
  welfare: {
    newUser: {
      label: 'Voordelen voor nieuwe gebruikers:',
      joinBtn: 'Sluit u nu aan',
      text: 'Nog maar 100 plekken beschikbaar! Grijp nu de jouwe!',
      remain: 'Er zijn er nog maar 30 over',
    },
  },
  help: {
    Fixedplugin: 'Vaste plug-in',
    Howtouse: 'Hoe te gebruiken',
    try: 'Probeer het eens',
    FreeTrialBenefits: 'Gratis proefvoordelen',
    pin: 'Pin Noam, voor gemakkelijkere toegang～～',
    Video: 'Video',
    ParallelTranslation: 'Parallelle vertaling',
    WebSummary: 'Websamenvatting',
    ChatPDF: 'Chat-pdf',
    AquickOverviewOfNoam: 'Een kort overzicht van Noam',
    WordSelectionTranslation: 'Vertaling van woordselectie',
    HoverTranslation: 'Beweeg vertaling',
    ImageTranslation: 'Beeldvertaling',
    InstantTranslation: 'Directe vertaling',
    VideoTranslation: 'Videovertaling',
    benefits: 'Wij hebben voordelen voor u voorbereid!',
    Free: 'Vrij',
    Membership: 'Lidmaatschap',
    Translation: 'Vertaling',
    PDFparallelTranslation: 'Parallelle vertaling van pdf',
    ChatPDF2: 'ChatPDF',
    Summary: 'Samenvatting',
    VideoSummary: 'Video-samenvatting',
    FavoritesManagement: 'Beheer van favorieten',
    Focusing: 'Focussen',
    dayFree: 'Gratis proefperiode van 3 dagen',
    planIsNotExist: 'plan bestaat niet',
    TryItForFree: 'Probeer het gratis',
    startYoutube: 'Laten we beginnen met YouTube~',
    startWiki: 'Laten we beginnen met Wikimedia~',
    tryNow: 'Probeer het nu eens~',
    GoWithYouTube: 'Ga met YouTube',
    GoWithWikipedia: 'Ga met Wikipedia',
    NoamHelp: 'Noam kan je helpen met vertalen en samenvatten',
    SkipAllSteps: 'Sla alle stappen over',
    next: 'Volgende',
    done: 'Klaar',
  },
  trialModal: {
    title: 'Welkomstgeschenk',
    trialText: '7-daagse proefperiode',
    desc1:
      'Nadat de proefperiode is afgelopen, wordt er een jaarlijkse vergoeding van $ 180 in rekening gebracht',
    desc2: 'Annuleer op elk gewenst moment',
    btn: 'Ga nu aan de slag (slechts $ 0,99)',
    installBtn: 'Installeer nu om uw geschenk te ontvangen',
    web: 'Parallelle vertaling',
    select: 'Vertaling van woordselectie',
    hover: 'Beweeg vertaling',
    image: 'Beeldvertaling',
    realtime: 'Directe vertaling',
    video: 'Videovertaling',
    pdf: 'Parallelle vertaling van pdf',
    chat: 'ChatPDF',
    webSummary: 'Websamenvatting',
    videoSummary: 'Video-samenvatting',
    memo: 'Beheer van favorieten',
    focus: 'Focussen',
  },
  privacyCollectionPanel: {
    title: 'Deze website maakt gebruik van cookies',
    text: 'Om uw ervaring te verbeteren, willen we graag cookies gebruiken om inhoud en diensten te personaliseren.',
    acceptBtn: 'Accepteer alles',
  },
  installTips: {
    text: 'Installeer "Noam Browser Plugin" om meer functies te ontgrendelen! Web-kruisverwijzingen,\n    samenvatting van webvideo\'s en meer!',
    btn: 'Probeer het nu',
  },
}

export default TRANSLATION
