const TRANSLATION = {
  "buyModal": {
    "afterFree": "Nach der Testphase",
    "btn": "Kostenlos testen",
    "freeTrial": "3 Tage kostenlose Testversion",
    "monthLabel": "Monat",
    "selectLabel": "Wählen Sie einen Plan aus",
    "title": "Abonnementplan",
    "yearLabel": "Jahr"
  },
  "contact": {
    "block1": "Für individuelle Anpassungen oder wenn du Fragen oder Anregungen hast, kontaktiere uns bitte unter contact@noam.tools. Wir werden dir umgehend antworten.",
    "block2": "Unterstütze uns, indem du uns im Google Apps Extension Store mit ★★★★★ bewertest.",
    "block3": "Hilf anderen, indem du diese Erweiterung teilst:",
    "title": "Kontakt"
  },
  "help": {
    "AquickOverviewOfNoam": "Ein kurzer Überblick über Noam",
    "ChatPDF": "Chat PDF",
    "ChatPDF2": "ChatPDF",
    "FavoritesManagement": "Favoritenverwaltung",
    "Fixedplugin": "Fixierter Plugin",
    "Focusing": "Fokussierung",
    "Free": "Kostenlos",
    "FreeTrialBenefits": "Vorteile der kostenlosen Testversion",
    "GoWithWikipedia": "Mit Wikipedia fortfahren",
    "GoWithYouTube": "Mit YouTube fortfahren",
    "HoverTranslation": "Hover-Übersetzung",
    "Howtouse": "Anwendung",
    "ImageTranslation": "Bildübersetzung",
    "InstantTranslation": "Sofortübersetzung",
    "Membership": "Mitgliedschaft",
    "NoamHelp": "Noam kann Ihnen beim Übersetzen und Zusammenfassen helfen",
    "PDFparallelTranslation": "PDF-Parallelübersetzung",
    "ParallelTranslation": "Parallele Übersetzung",
    "SkipAllSteps": "Alle Schritte überspringen",
    "Summary": "Zusammenfassung",
    "Translation": "Übersetzung",
    "TryItForFree": "Kostenlos testen",
    "Video": "Video",
    "VideoSummary": "Videozusammenfassung",
    "VideoTranslation": "Videoubersetzung",
    "WebSummary": "Webzusammenfassung",
    "WordSelectionTranslation": "Übersetzung von Wortmarkierungen",
    "benefits": "Wir haben Vorteile für Sie vorbereitet!",
    "dayFree": "3 Tage kostenlose Testversion",
    "done": "Fertig",
    "next": "Weiter",
    "pin": "Noam anheften, für einfacheren Zugriff～～",
    "planIsNotExist": "Plan existiert nicht",
    "startWiki": "Los geht's mit Wikipedia~",
    "startYoutube": "Los geht's mit YouTube~",
    "try": "Teste es",
    "tryNow": "Jetzt ausprobieren~"
  },
  "home": {
    "PDFTranslate": {
      "desc": "Zweisprachige Übersetzung + Chat-PDF",
      "sub1": {
        "desc": "Zweisprachiges Lesen, damit du den Originaltext und die Übersetzung während des Lesens vergleichen und so deine Leseeffizienz verbessern kannst.",
        "title": "Zweisprachiges Lesen"
      },
      "sub2": {
        "desc": "Die KI analysiert und fasst zusammen und kann deine Fragen zum PDF-Inhalt beantworten.",
        "title": "Chat-PDF"
      },
      "sub3": {
        "desc": "KI-gestütztes PDF für genauere und effizientere Übersetzungen",
        "title": "Genauigkeit und Effizienz"
      },
      "title": "PDF übersetzen"
    },
    "aiAssistant": {
      "desc": "Macht Übersetzungen im Internet intelligenter und komfortabler",
      "panel1": {
        "desc": "Zweisprachige Querverweisübersetzungen helfen dir, den Originaltext mit der Übersetzung zu vergleichen, damit du ihn besser verstehst und deine Leseeffizienz verbessern kannst.",
        "title": "Zweisprachige Übersetzung"
      },
      "panel2": {
        "left": {
          "desc": "Bewege beim Besuch einer Webseite den Mauszeiger direkt über den zu übersetzenden Inhalt, um das Übersetzungsergebnis zu erhalten.",
          "title": "Hover-Übersetzung"
        },
        "right": {
          "desc": "Markiere beim Besuch einer Webseite direkt den zu unterstreichenden Text, um das Übersetzungsergebnis zu erhalten.",
          "title": "Textauswahl-Übersetzung"
        }
      },
      "panel3": {
        "one": {
          "desc": "Übersetze und fasse Webseiten- und Videoinhalte jederzeit, überall und in jedem Szenario zusammen und genieße ein flüssigeres Erlebnis!",
          "title": "Zeigen und Klicken"
        },
        "three": {
          "desc": "KI-gesteuertes Großmodell, um die Genauigkeit und Konsistenz der Übersetzung zu gewährleisten und das Leseerlebnis zu verbessern.",
          "title": "KI-gesteuert"
        },
        "two": {
          "desc": "Mehrsprachige, vollständige Abdeckung, zweisprachiges, barrierefreies Lesen, Übersetzung mit einem Tastendruck - für ein unvergleichliches Erlebnis!",
          "title": "Umfassender und komfortabler"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "Dein Effizienz-Experte, kombiniert mit Chatgpt, übersetzt und fasst YouTube-Videoinhalte zusammen, um dir Lesezeit zu sparen!",
          "key": "Video",
          "title": "Videoübersetzung & -zusammenfassung"
        },
        "tab2": {
          "desc": "Klicke, um Bilder beim Surfen im Internet zu übersetzen.",
          "key": "Image",
          "title": "Bildübersetzung"
        },
        "tab3": {
          "desc": "Extrahiert schnell wichtige Informationen von Webseiten für die Zusammenfassung und verbessert so deine Leseeffizienz.",
          "key": "Web",
          "title": "Webseitenübersetzung & -zusammenfassung"
        },
        "tag1": "Einfach",
        "tag2": "Effizient",
        "tag3": "Leicht verständlich"
      },
      "title": "KI-Assistent"
    },
    "getStart": "Los geht's",
    "getStartedForFree": "Kostenlos starten",
    "howItWork": {
      "desc": "Noam integriert KI-Modelle, um Benutzern ein intelligentes Erlebnis für die Übersetzung und Zusammenfassung von Webseiten, die Übersetzung von PDFs und Bildern sowie die Übersetzung und Zusammenfassung von Videos in verschiedenen Sprachen zu bieten.",
      "title": "So，funktioniert's"
    },
    "lastBlock": {
      "desc": "Bessere KI-Übersetzung und flüssigeres Leseerlebnis"
    },
    "top": {
      "desc": "Noam ist eine kostenlose KI-Browsererweiterung, mit der du Zusammenfassungen von Webseiten, Videos und PDF-Inhalten übersetzen kannst.\\n      Schließ dich Noam an und erlebe die Magie!",
      "title1": "Ein Klick",
      "title2": "Ein Zauber"
    },
    "userReviews": {
      "desc": "Fünf-Sterne-Bewertung im Chrome Web Store",
      "title": "Benutzerbewertungen"
    }
  },
  "installTips": {
    "btn": "Jetzt testen",
    "text": "Installieren Sie das \"Noam Browser Plugin\", um weitere Funktionen freizuschalten! Web-Querverweise,\\n    Zusammenfassungen von Webvideos und mehr!"
  },
  "locale": {
    "af": "Afrikaans",
    "am": "Amharisch",
    "ar": "Arabisch",
    "as": "Assamesisch",
    "auto": "Automatisch erkennen",
    "az": "Aserbaidschanisch",
    "ba": "Baschkirisch",
    "bg": "Bulgarisch",
    "bn": "Bengalisch",
    "bo": "Tibetisch",
    "bs": "Bosnisch",
    "ca": "Katalanisch",
    "cs": "Tschechisch",
    "cy": "Walisisch",
    "da": "Dänisch",
    "de": "Deutsch",
    "dsb": "Niedersorbisch",
    "dv": "Dhivehi",
    "el": "Griechisch",
    "en": "Englisch",
    "es": "Spanisch",
    "et": "Estnisch",
    "eu": "Baskisch",
    "fa": "Persisch",
    "fi": "Finnisch",
    "fil": "Philippinisch",
    "fj": "Fidschi",
    "fr": "Französisch",
    "frCA": "Französisch (Kanada)",
    "ga": "Irisch",
    "gl": "Galizisch",
    "gom": "Konkani",
    "gu": "Gujarati",
    "ha": "Haussa",
    "he": "Hebräisch",
    "hi": "Hindi",
    "hr": "Kroatisch",
    "hsb": "Obersorbisch",
    "ht": "Haitianisches Kreol",
    "hu": "Ungarisch",
    "hy": "Armenisch",
    "id": "Indonesisch",
    "ig": "Igbo",
    "ikt": "Inuinnaqtun",
    "is": "Isländisch",
    "it": "Italienisch",
    "iu": "Inuktitut",
    "iuLatn": "Inuktitut (Lateinisch)",
    "ja": "Japanisch",
    "ka": "Georgisch",
    "kk": "Kasachisch",
    "km": "Khmer",
    "kmr": "Kurdisch (Nord)",
    "kn": "Kannada",
    "ko": "Koreanisch",
    "ku": "Kurdisch (Zentral)",
    "ky": "Kirgisisch",
    "ln": "Lingala",
    "lo": "Laotisch",
    "lt": "Litauisch",
    "lug": "Luganda",
    "lv": "Lettisch",
    "lzh": "Chinesisch (Literarisch)",
    "mai": "Maithili",
    "mg": "Madagassisch",
    "mi": "Maori",
    "mk": "Mazedonisch",
    "ml": "Malayalam",
    "mnCyrl": "Mongolisch (Kyrillisch)",
    "mnMong": "Mongolisch (Traditionell)",
    "mr": "Marathi",
    "ms": "Malaiisch",
    "mt": "Maltesisch",
    "mww": "Hmong Daw",
    "my": "Myanmar (Birmanisch)",
    "nb": "Norwegisch",
    "ne": "Nepalesisch",
    "nl": "Niederländisch",
    "noSearchLang": "Diese Sprache wird nicht unterstützt",
    "nso": "Nord-Sotho",
    "nya": "Nyanja",
    "or": "Oriya",
    "otq": "Querétaro Otomi",
    "pa": "Punjabi",
    "pl": "Polnisch",
    "placeholder": "Suche",
    "prs": "Dari",
    "ps": "Paschtu",
    "pt": "Portugiesisch (Brasilien)",
    "ptPT": "Portugiesisch (Portugal)",
    "ro": "Rumänisch",
    "ru": "Russisch",
    "run": "Rundi",
    "rw": "Kinyarwanda",
    "sd": "Sindhi",
    "si": "Singhalesisch",
    "sk": "Slowakisch",
    "sl": "Slowenisch",
    "sm": "Samoanisch",
    "sn": "Shona",
    "so": "Somali",
    "sq": "Albanisch",
    "srCyrl": "Serbisch (Kyrillisch)",
    "srLatn": "Serbisch (Lateinisch)",
    "st": "Süd-Sotho",
    "sv": "Schwedisch",
    "sw": "Suaheli",
    "ta": "Tamil",
    "te": "Telugu",
    "th": "Thailändisch",
    "ti": "Tigrinya",
    "tk": "Turkmenisch",
    "tlhLatn": "Klingonisch (Lateinisch)",
    "tlhPiqd": "Klingonisch (pIqaD)",
    "tn": "Setswana",
    "to": "Tongaisch",
    "tr": "Türkisch",
    "tt": "Tatarisch",
    "ty": "Tahitianisch",
    "ug": "Uigurisch",
    "uk": "Ukrainisch",
    "ur": "Urdu",
    "uz": "Usbekisch (Lateinisch)",
    "vi": "Vietnamesisch",
    "xh": "Xhosa",
    "yo": "Yoruba",
    "yua": "Yucatec Maya",
    "yue": "Kantonesisch (Traditionell)",
    "zh": "Chinesisch",
    "zhHans": "Chinesisch (Vereinfacht)",
    "zhHant": "Chinesisch (traditionell)",
    "zu": "Zulu"
  },
  "login": {
    "and": "  und",
    "btn": "Mit Google anmelden",
    "btn1": "Jetzt anmelden",
    "desc": "Melden Sie sich an, um weitere Erfahrungen freizuschalten",
    "policy": " Datenschutzrichtlinie",
    "terms": "Nutzungsbedingungen ",
    "tips": "Mit der Registrierung stimme ich den ",
    "title": "Willkommen bei Noam"
  },
  "pageFooter": {
    "follow": {
      "facebook": "Facebook",
      "title": "Folgen Sie uns",
      "twitter": "Twitter"
    },
    "legal": {
      "policy": "Datenschutzrichtlinie",
      "terms": "Nutzungsbedingungen",
      "title": "Rechtliches"
    },
    "product": {
      "image": "Bildübersetzung",
      "pdf": "PDF-Übersetzung",
      "title": "Produkte",
      "video": "Videountertitel & Zusammenfassung",
      "web": "Webübersetzung & Zusammenfassung"
    },
    "resources": {
      "title": "Ressourcen"
    }
  },
  "pageHeader": {
    "contact": "Kontakt",
    "help": "Hilfe",
    "logout": "Abmelden",
    "logoutSucc": "Abmeldung erfolgreich",
    "pricing": "Preise",
    "settings": "Einstellungen",
    "signUp": "Anmelden"
  },
  "pdf": {
    "autoDetect": "Automatisch erkennen",
    "cancelBtn": "Abbrechen",
    "cancelUpload": "Abbrechen",
    "chatPdf": {
      "Search": "Suchen",
      "cancel": "Abbrechen",
      "chatTitle": "Willkommen bei Noam. Ihre PDF-Zusammenfassung ist fertig:",
      "clear": "Löschen",
      "clearContent": "Nach dem Löschen wird eine neue Unterhaltung erstellt und der Inhalt dieser Unterhaltung wird nicht gespeichert. Möchten Sie sie wirklich löschen?",
      "clearDialogTitle": "Achtung",
      "clearSucc": "Erfolgreich gelöscht",
      "copy": "Kopieren",
      "copySucc": "Erfolgreich kopiert",
      "delete": "Löschen",
      "deleteCancel": "Abbrechen",
      "deleteContent": "Nach dem Löschen kann diese PDF-Datei nicht wiederhergestellt werden. Möchten Sie sie wirklich löschen?",
      "deleteOk": "Löschen",
      "deleteSuccess": "Erfolgreich gelöscht",
      "deleteTitle": "Tipp",
      "expand": "Reduzieren",
      "historyTitle": "Verlauf",
      "listError": "Die Leseliste konnte nicht geladen werden. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundensupport.",
      "loading": "Lädt...",
      "noDataDesc": "Kein Verlauf~ ",
      "noMore": "Keine weiteren Einträge.",
      "placeholder": "Geben Sie eine beliebige Frage zur PDF-Datei ein",
      "summaryTitle": "Fragen, die Sie interessieren könnten:"
    },
    "collectionEmpty": "Keine Inhalte in der Sammlung~",
    "collectionTab": "Memo",
    "dataLoading": "Daten werden geladen...",
    "dialogContent": "Möchten Sie das wirklich löschen?",
    "dialogTitle": "Achtung",
    "download": {
      "both": "Zweisprachigkeit",
      "btn": "Jetzt speichern",
      "cancel": "Durch Schließen des Popup-Fensters wird das Herunterladen der PDF-Datei abgebrochen. Möchten Sie wirklich abbrechen?",
      "desc": "Lokal speichern: Die heruntergeladenen Übersetzungen werden als Bilder gespeichert. Speichern und Drucken: Die heruntergeladenen Übersetzungen werden im Originalformat beibehalten und unterstützen das Kopieren.",
      "download": "Herunterladen",
      "downloadMsg1": "Fortschritt der PDF-Übersetzung:",
      "downloadMsg2": "Voraussichtlicher Bedarf",
      "downloadSucc": "PDF kann heruntergeladen werden. Klicken Sie jetzt auf Speichern!",
      "downloadTip": "Die PDF-Datei wird heruntergeladen. Schließen Sie das Popup-Fenster während des Downloads nicht, um Downloadfehler zu vermeiden.",
      "error": "Fehler beim lokalen Speichern. Bitte versuchen Sie es mit Drucken und Speichern.",
      "fileName": "Dateiname:",
      "fileType": "Download-Dateityp:",
      "loading": "Wird gespeichert...",
      "loading2": "Lädt...",
      "minute": "Minuten...",
      "msg1": "Einige Übersetzungen des aktuellen Dokuments sind länger als der Originaltext, und der Text kann sich nach dem Herunterladen überschneiden. Es wird daher empfohlen, ihn auf der Webseite zu lesen.",
      "msg2": "1. Der Inhalt wurde nicht vollständig übersetzt. Bitte laden Sie ihn herunter, nachdem die Übersetzung abgeschlossen ist.",
      "msg3": "2. Ein Teil der Übersetzung des Dokuments ist zu lang. Sie können die Webseite lesen, um Textüberschneidungen zu vermeiden.",
      "msg4": "Derzeit werden Aufgaben übersetzt. Bitte versuchen Sie es erneut, sobald die Übersetzung abgeschlossen ist.",
      "progress": "PDF wird übersetzt. Fortschritt: {progress}, voraussichtliche Dauer: {minutes} Minuten...",
      "save": "Lokal speichern",
      "savePrint": "Speichern und Drucken",
      "success": "Erfolgreich heruntergeladen",
      "trans": "Nur Übersetzung",
      "transContinue": "Übersetzung fortsetzen"
    },
    "downloadBtn": "Herunterladen",
    "dragTipModal": {
      "black": "Leertaste",
      "leftKey": "linke Taste",
      "ok": "Verstanden",
      "press": "Drücken Sie",
      "tips": "zum Ziehen und Anzeigen."
    },
    "entry": "PDF-Liste",
    "fileErrMsg": "Fehler beim Lesen der Datei. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.",
    "fileMsg": "Aktuelles Dateivolumen:",
    "fileMsg2": "Bitte laden Sie eine Datei hoch, die kleiner als 100 MB ist.",
    "freeBannerBtn": "Upgrade für mehr Funktionen",
    "freeBannerTips": "Ende der kostenlosen PDF-Übersetzung",
    "guide": {
      "placeholder": "Bitte geben Sie den PDF-Link ein",
      "start": "Übersetzung starten",
      "text1": "Zweisprachiges Lesen",
      "text2": "Zweisprachiges Lesen, damit Sie den Originaltext und die Übersetzung während des Lesens vergleichen und so die Leseeffizienz verbessern können",
      "text3": "PDF chatten",
      "text4": "KI analysiert und fasst zusammen und kann Ihre Fragen zum PDF-Inhalt beantworten",
      "text5": "Genauigkeit und Effizienz",
      "text6": "KI-gestützte PDF-Datei für genauere und effizientere Übersetzungen",
      "title1": "Zweisprachige Übersetzung",
      "title2": "PDF chatten",
      "toast": "Bitte geben Sie einen gültigen Online-PDF-Link ein, der auf .pdf endet."
    },
    "hasTransTask": "Es wird gerade eine Aufgabe übersetzt. Bitte warten Sie.",
    "hoverTrans": "Übersetzung von Hover-Absätzen",
    "initial": "PDF übersetzen",
    "installText": "Alles, was Sie für Ihre täglichen Übersetzungen benötigen, aus einer Hand",
    "installText2": "Bitte installieren Sie zuerst ",
    "installTips": "Installieren Sie das kleine Plugin, um mehr PDF-Dateien zu übersetzen und eine reibungslose Übersetzung von Webseiten zu erleben:",
    "kw": "Heften",
    "link": "Link",
    "listError": "PDF-Liste konnte nicht geladen werden. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.",
    "memoTotalCount": "Insgesamt 1 Inhalt",
    "new": "neue Datei",
    "noId": "Datei-ID existiert nicht",
    "notPdf": "Nur PDF-Dateien",
    "okBtn": "Löschen",
    "okBtn2": "Erkennen",
    "original": "Original",
    "parallel": "Parallel",
    "parseErrDialogContent": "Der aktuelle PDF-Link unterstützt keine direkte Übersetzung. Bitte laden Sie die Original-PDF-Datei herunter, nachdem die Übersetzung abgeschlossen ist.",
    "parseErrTips": "Datei kann nicht analysiert werden",
    "parseFileToast": "Fehler beim Analysieren der Datei. Bitte versuchen Sie es später erneut oder wenden Sie sich an unseren Kundenservice.",
    "pdfLoading": "Analysieren...",
    "searchPlaceholder": "Suchen",
    "selectTrans": "Wörter unterstreichen und übersetzen",
    "shareBtn": "Teilen",
    "shareBtnText1": "Freigabelinks generieren",
    "shareBtnText2": "Freigabeinhalt aktualisiert",
    "shareModal": {
      "cancel": "Abbrechen",
      "copy": "Kopieren",
      "copySucc": "Link erfolgreich kopiert",
      "custom": "Anpassung:",
      "errMsg": "Der Inhalt des aktuellen Freigabebereichs wurde nicht vollständig übersetzt. Bitte übersetzen Sie ihn vor dem Teilen.",
      "gen": "Teilen",
      "modify": "Ändern",
      "notSupport": "Diese Sprache wird derzeit nicht unterstützt.",
      "page": "Seiten",
      "pageNuErr": "Bitte geben Sie einen gültigen Seitenzahlwert ein.",
      "range": "Bereich:",
      "remain": "verbleibend",
      "remain1": "Voraussichtlicher Gesamtbedarf",
      "search": "Suchen",
      "settings": "Freigabeeinstellungen:",
      "shareSetting": "Einstellungen für Freigabeinhalte",
      "shareText": "Teilen",
      "shareTips": "Teilen Sie den Link zur übersetzten PDF-Datei mit Ihren Freunden zum Vergleich:",
      "sourceLang": "Ausgangssprache:",
      "target": "Zielsprache:",
      "text1": "Freigabeeinstellungen",
      "text2": "Freigabelinks generieren",
      "totalPage": "Insgesamt 1 Seiten",
      "trans": "Übersetzen",
      "translateTips": "Tipp: Wenn die Übersetzung lange dauert, können Sie das Popup-Fenster schließen und die Datei teilen, sobald die Übersetzung abgeschlossen ist.",
      "translating": "PDF wird übersetzt...",
      "update": "Freigabelinks aktualisiert"
    },
    "shareMsg1": "Freigabelink erfolgreich generiert",
    "shareMsg2": "Freigabeinhalt erfolgreich aktualisiert",
    "shareMsg3": "Fehler beim Erstellen des Freigabelinks. Bitte versuchen Sie es später erneut oder wenden Sie sich an unseren Kundenservice.",
    "shareMsg4": "Fehler beim Aktualisieren des Freigabeinhalts. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.",
    "shareTips": "Die PDF-Übersetzung ist abgeschlossen. Teilen Sie sie mit Ihren Freunden!",
    "start": "Starten Sie Ihr kostenloses Erlebnis",
    "thumbnail": "Kataloge",
    "toast1": "PDF wird geladen, bitte versuchen Sie es später erneut!",
    "toast2": "Der PDF-Inhalt ist zu umfangreich. Derzeit wird das Dialogfeld nicht unterstützt.",
    "toast3": "Herunterladen, bitte versuchen Sie es erneut, nachdem der Download abgeschlossen ist.",
    "toolbar": {
      "Adjust": "Anpassen",
      "autoFit": "Automatisch anpassen"
    },
    "trans": "Übersetzung",
    "transSucc": "Die Übersetzung war erfolgreich!",
    "unLoginToast": "Bitte melden Sie sich an, um diese Funktion nutzen zu können.",
    "uploadBtn": "Hochladen",
    "uploadErr": "Datei-Upload fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.",
    "uploadMsg1": "Bitte ziehen Sie die Datei hierher",
    "uploadMsg2": "Unterstützte Dateitypen: PDF  |  Maximale Dateigröße: 100 MB",
    "uploadMsg3": "Klicken Sie hier, um Dateien auszuwählen oder ziehen Sie sie per Drag & Drop zum Hochladen hierher",
    "uploading": "Hochladen",
    "uploadingMsg": "Bitte haben Sie etwas Geduld, der Upload dauert einige Zeit.",
    "uploadingMsg1": "Datei wird hochgeladen. Bitte versuchen Sie es später erneut.",
    "uploadingMsg2": "Derzeit wird eine Datei konvertiert. Bitte versuchen Sie es später erneut.",
    "uploadingMsg3": "Fehler bei der Anfrage zum Konvertierungsfortschritt. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.",
    "uploadingMsg4": "Noam kann nicht direkt auf Ihre lokalen Dateien zugreifen. Bitte laden Sie eine PDF-Datei zur Übersetzung manuell hoch.",
    "uploadingMsg5": "Fehler beim Abrufen der Datei-URL. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.",
    "video": "Video",
    "vipBannerBtn": "Upgrade für mehr Funktionen",
    "vipBannerTips": "Sie haben Ihr Limit für die PDF-Übersetzung in diesem Monat erreicht.",
    "webTrans": "vollständige Querverweise"
  },
  "pricing": {
    "FAQ": "FAQ",
    "FQAObj": {
      "a1": "Es gibt eine kostenlose Version von Noam, die direkt verwendet werden kann.",
      "a2": "Neben der kostenlosen Version gibt es drei Mitgliedschaftspläne, Pro, Pro+ und Enterprise; Sie können zwischen zwei Zahlungsmethoden wählen, kontinuierlich monatlich und kontinuierlich jährlich, und die Gesamtkosten von kontinuierlich jährlich sind günstiger.",
      "a3": "Es gibt keinen Unterschied in der Funktionalität. Die Gesamtkosten eines fortlaufenden Jahresabonnements sind niedriger als die eines fortlaufenden Monatsabonnements; das Monatsabonnement wird einmal pro Monat abgerechnet, während das Jahresabonnement einmal pro Jahr abgerechnet wird.",
      "a4": "Sie können auf der Einstellungsseite im Backend die Option zum Abonnement wählen, um den Verlängerungsplan zu kündigen.",
      "q1": "Kann ich es kostenlos nutzen?",
      "q2": "Gibt es verschiedene Mitgliedschaftspläne und Gebührenoptionen?",
      "q3": "Was ist der Unterschied zwischen einem fortlaufenden Monatsabonnement und einem fortlaufenden Jahresabonnement?",
      "q4": "Wie kann ich mein Abonnement mit automatischer Verlängerung kündigen?"
    },
    "around": "Ungefähr",
    "billedMonthly": "Monatliche Abrechnung",
    "billedYearly": "Jährliche Abrechnung",
    "day": "Tag",
    "desc": "Vergleichen und wählen Sie das Beste für Sie",
    "detailedCompare": "Detaillierter Vergleich ",
    "discount": "20% Rabatt",
    "enterprise": {
      "benefit1": "Schneller Kundenservice-Support",
      "benefit2": "Mehrbenutzer",
      "benefit3": "Individuelle Lösungen",
      "benefit5": "Exklusiver Account Manager",
      "btn": "Kontakt",
      "recommendText": "Individuelle Tarife（≥3人）",
      "talkToUs": "Sprechen Sie mit uns"
    },
    "free": {
      "benefit1": "Sofortübersetzung ${FREE_BENEFITS.realTimeCount} Mal/Tag",
      "benefit2": "Webübersetzung & Zusammenfassung",
      "benefit3": "${FREE_BENEFITS.imageCount} Seiten Bildübersetzung",
      "benefit4": "Videountertitel & Zusammenfassung",
      "benefit5": "${FREE_BENEFITS.pdfUploadCount} PDF-Übersetzung",
      "benefit6": "${FREE_BENEFITS.tokenCount} Token",
      "btn": "Viel Spaß",
      "recommendText": "Kostenlos testen"
    },
    "imageTranslation": "Bildübersetzung",
    "month": "Monat",
    "monthly": "Monatlich",
    "pageTitle": "Preise",
    "pdf": {
      "chat": "Anzahl der PDF-Anfragen",
      "maxPage": "Maximale Seitenzahl im PDF",
      "translation": "PDF-Übersetzung"
    },
    "preMonth": "Pro Monat",
    "pro": {
      "benefit1": "Schneller Kundenservice-Support",
      "benefit2": "${PRO_BENEFITS.tokenCount} Token/Monat",
      "benefit3": "Webübersetzung & Zusammenfassung",
      "benefit4": "${PRO_BENEFITS.imageCount} Seiten Bildübersetzung",
      "benefit5": "Videountertitel & Zusammenfassung",
      "benefit6": "${PRO_BENEFITS.pdfUploadCount} PDF-Übersetzung",
      "btn": "Loslegen",
      "recommendText": "Die Wahl der meisten Leute"
    },
    "proPlus": {
      "benefit1": "Schneller Kundenservice-Support",
      "benefit2": "${PRO_V_BENEFITS.tokenCount} Token/Monat",
      "benefit3": "Webübersetzung & Zusammenfassung",
      "benefit4": "${PRO_V_BENEFITS.imageCount} Seiten Bildübersetzung",
      "benefit5": "Videountertitel & Zusammenfassung",
      "benefit6": "${PRO_V_BENEFITS.pdfUploadCount} PDF-Übersetzung",
      "recommendText": "Die passendste Wahl"
    },
    "video": {
      "summary": "Videozusammenfassung",
      "translation": "Videountertitel"
    },
    "web": {
      "contrastiveTranslation": "Zweisprachige Übersetzung",
      "dictionaryTranslation": "Textauswahlübersetzung",
      "hoverTranslation": "Hover-Übersetzung",
      "instantTranslation": "Sofortübersetzung",
      "summary": "Zusammenfassung von Webseiten"
    },
    "yearly": "Jährlich"
  },
  "privacy": {
    "block1": "Wir, Noam (im Folgenden als „Noam“, „wir“, „unser“ oder „uns“ bezeichnet), respektieren Ihre Privatsphäre und verpflichten uns, alle Informationen zu schützen, die wir von Ihnen oder über Sie erhalten. Diese Datenschutzrichtlinie beschreibt unsere Praktiken in Bezug auf die Erhebung oder Verwendung personenbezogener Daten aus Ihrer Nutzung unserer Website, Anwendungen und Dienste (zusammenfassend als „Dienste“ bezeichnet). Diese Datenschutzrichtlinie gilt nicht für Inhalte, die wir im Auftrag unserer Geschäftskunden verarbeiten, wie z. B. unsere API. Die Verwendung dieser Daten unterliegt unserer Kundenvereinbarung, die den Zugriff auf und die Nutzung dieser Produkte regelt.",
    "block10": "Wir implementieren wirtschaftlich angemessene technische, administrative und organisatorische Maßnahmen, um personenbezogene Daten online und offline vor Verlust, Missbrauch und unbefugtem Zugriff, Offenlegung, Änderung oder feindseligen Handlungen zu schützen. Allerdings kann keine Internet- oder E-Mail-Übertragung absolute Sicherheit oder Richtigkeit garantieren. Insbesondere E-Mails, die an uns oder von uns gesendet werden, sind möglicherweise nicht sicher. Daher sollten Sie besonders vorsichtig sein, wenn Sie entscheiden, welche Informationen Sie uns über den Dienst oder per E-Mail senden. Darüber hinaus sind wir nicht verantwortlich für die Umgehung von Datenschutzeinstellungen oder Sicherheitsmaßnahmen im Dienst oder von Sicherheitseinstellungen oder Sicherheitsmaßnahmen auf Websites Dritter. Wir bewahren Ihre personenbezogenen Daten so lange auf, wie es für uns erforderlich ist, um Ihnen Dienste bereitzustellen, oder für andere legitime Geschäftszwecke, wie z. B. die Beilegung von Streitigkeiten, Sicherheits- und Schutzgründe oder die Erfüllung unserer gesetzlichen Verpflichtungen. Die Dauer der Aufbewahrung personenbezogener Daten hängt von vielen Faktoren ab, wie z. B. Umfang, Art, Sensibilität der Daten, potenzielle Risiken einer unbefugten Nutzung oder Offenlegung, Zwecke, für die wir die Daten verarbeiten, und andere rechtlich bindende Erwägungen.",
    "block11": "Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. In diesem Fall werden wir eine aktualisierte Version auf dieser Seite veröffentlichen, sofern dies nicht durch geltendes Recht anders vorgeschrieben ist.",
    "block12": "Wenn Sie unbeantwortete Fragen oder Bedenken zu dieser Datenschutzrichtlinie haben, wenden Sie sich bitte an unseren Kundenservice. E-Mail: ",
    "block2": "Wir erheben die folgenden personenbezogenen Daten („personenbezogene Daten“) in Bezug auf Sie:",
    "block3": "Von Ihnen bereitgestellte Informationen: Wenn Sie ein Konto erstellen, um unsere Dienste zu nutzen oder mit uns zu kommunizieren, erheben wir personenbezogene Daten wie folgt:",
    "block4": "Personenbezogene Daten, die wir automatisch aus Ihrer Nutzung der Dienste erhalten: Wenn Sie auf die Dienste zugreifen, diese nutzen oder mit ihnen interagieren, erhalten wir die folgenden Informationen über Ihren Zugriff, Ihre Nutzung oder Ihre Interaktion („technische Informationen“):",
    "block5": "Wir können personenbezogene Daten für die folgenden Zwecke verwenden:",
    "block6": "Zusammengefasste oder de-identifizierte Informationen: Wir können personenbezogene Daten aggregieren oder de-identifizieren, um ihre Wiederverwendung zu Identifikationszwecken zu verhindern, und solche Informationen verwenden, um die Effektivität unserer Dienste zu analysieren, unsere Dienste zu verbessern und um Funktionen zu erweitern, Forschung zu betreiben und für ähnliche Zwecke. Darüber hinaus können wir das allgemeine Verhalten und die Merkmale der Nutzer unserer Dienste analysieren und aggregierte Informationen an Dritte weitergeben, solche aggregierten Informationen veröffentlichen oder allgemein zugänglich machen. Wir können aggregierte Informationen über die Dienste, durch Cookies und durch andere in dieser Datenschutzrichtlinie beschriebenen Mittel erheben. Wir werden de-identifizierte Informationen in anonymisierter oder de-identifizierter Form aufbewahren und verwenden und wir werden nicht versuchen, solche Informationen erneut zu identifizieren, es sei denn, dies ist gesetzlich vorgeschrieben.",
    "block7": "In bestimmten Fällen können wir Ihre personenbezogenen Daten ohne weitere Mitteilung an Sie an Dritte weitergeben, es sei denn, dies ist gesetzlich vorgeschrieben:",
    "block8": "Abhängig von Ihrem geografischen Standort können Personen im Europäischen Wirtschaftsraum, im Vereinigten Königreich und weltweit bestimmte gesetzliche Rechte in Bezug auf ihre personenbezogenen Daten haben. Sie haben beispielsweise das Recht:",
    "block9": "Der Dienst kann Links zu anderen Websites enthalten, die nicht von Noam betrieben oder kontrolliert werden, einschließlich Social-Media-Diensten („Websites Dritter“). Die Informationen, die Sie an Websites Dritter weitergeben, unterliegen den spezifischen Datenschutzrichtlinien und Nutzungsbedingungen der Websites Dritter, nicht dieser Datenschutzrichtlinie. Wir stellen diese Links aus Gründen der Benutzerfreundlichkeit zur Verfügung und implizieren keine Billigung oder Überprüfung dieser Websites durch uns. Bitte wenden Sie sich direkt an die Websites Dritter, um Informationen über deren Datenschutzrichtlinien und -bedingungen zu erhalten.",
    "subBlock1": "Kontoinformationen: Wenn Sie ein Konto auf unserer Plattform erstellen, erheben wir Informationen zu Ihrem Konto, einschließlich Ihres Namens, Ihrer Kontaktinformationen, Kontodaten, Zahlungskarteninformationen und Transaktionshistorie (im Folgenden als „Kontoinformationen“ bezeichnet).",
    "subBlock10": "Bereitstellung, Verwaltung, Pflege und/oder Analyse von Diensten;",
    "subBlock11": "Kommunikation mit Ihnen;",
    "subBlock12": "Entwicklung neuer Projekte und Dienstleistungen;",
    "subBlock13": "Verhinderung von Betrug, kriminellen Aktivitäten oder Missbrauch unserer Dienste und Schutz der Sicherheit unserer IT-Systeme, Architektur und Netzwerke;",
    "subBlock14": "Durchführung von Unternehmensübertragungen;",
    "subBlock15": "Erfüllung gesetzlicher Verpflichtungen und Gerichtsverfahren, Schutz unserer Rechte, Privatsphäre, Sicherheit oder Eigentums sowie der unserer verbundenen Unternehmen, Ihrer oder anderer Dritter.",
    "subBlock16": "Lieferanten und Dienstleister: Um uns bei der Erfüllung geschäftlicher betrieblicher Anforderungen und der Durchführung bestimmter Dienste und Funktionen zu unterstützen, können wir personenbezogene Daten an Lieferanten und Dienstleister weitergeben, einschließlich Hosting-Dienstleister, Cloud-Dienstleister und andere Informationstechnologie-Dienstleister, E-Mail-Kommunikationssoftware und Webanalyse-Dienstleister usw. Diese Partner werden nur auf unsere Anweisung hin auf personenbezogene Daten zugreifen, diese verarbeiten oder speichern, während sie ihre Aufgaben für uns wahrnehmen.",
    "subBlock17": "Unternehmensübertragungen: Wenn wir strategische Transaktionen, Umstrukturierungen, Konkurse, Konkursübernahmen oder Dienstleistungsübergänge an einen anderen Anbieter durchführen (zusammenfassend als „Transaktionen“ bezeichnet), können Ihre personenbezogenen Daten und andere Informationen an die Transaktionsgegenpartei und andere Parteien weitergegeben werden, die uns bei den Transaktionen im Rahmen der Due Diligence unterstützen, und im Rahmen der Transaktionen zusammen mit anderen Vermögenswerten an einen Nachfolger oder ein verbundenes Unternehmen übertragen werden.",
    "subBlock18": "Gesetzliche Anforderungen: Wir können Ihre personenbezogenen Daten an Regierungsbehörden, Branchenkollegen oder andere Dritte weitergeben, einschließlich Ihrer Interaktionsinformationen mit unseren Diensten, (1) wenn dies gesetzlich vorgeschrieben ist oder wir der Ansicht sind, dass dies zur Erfüllung gesetzlicher Verpflichtungen erforderlich ist; (2) zum Schutz und zur Verteidigung unserer Rechte oder unseres Eigentums; (3) wenn wir einseitig feststellen, dass ein Verstoß gegen unsere Bedingungen, Richtlinien oder Gesetze vorliegt; (4) zur Aufdeckung oder Verhinderung von Betrug oder anderen illegalen Aktivitäten; (5) zum Schutz unserer Produkte, Mitarbeiter oder Benutzer oder der öffentlichen Sicherheit, Gefahrenabwehr, Integrität; oder (vi) zur Abwehr von Rechtsansprüchen.",
    "subBlock19": "auf Auskunft über Ihre personenbezogenen Daten und darüber, wie diese verarbeitet werden.",
    "subBlock2": "Benutzerinhalte: Wenn Sie unsere Dienste nutzen, erheben wir personenbezogene Daten aus Ihren Eingaben, Datei-Uploads oder Feedback, das Sie uns zur Verfügung stellen (im Folgenden als „Inhalte“ bezeichnet).",
    "subBlock20": "auf Löschung Ihrer personenbezogenen Daten aus unseren Aufzeichnungen.",
    "subBlock21": "auf Berichtigung oder Aktualisierung Ihrer personenbezogenen Daten.",
    "subBlock22": "auf Übertragung Ihrer personenbezogenen Daten an einen Dritten (Datenübertragbarkeit).",
    "subBlock23": "die Art und Weise, wie wir Ihre personenbezogenen Daten verarbeiten, einzuschränken.",
    "subBlock24": "Ihre Einwilligung zu widerrufen - wenn wir uns bei der Verarbeitung auf die Einwilligung als Rechtsgrundlage stützen, können Sie Ihre Einwilligung jederzeit widerrufen.",
    "subBlock25": "Widerspruch gegen die Art und Weise, wie wir Ihre personenbezogenen Daten verarbeiten, einzulegen.",
    "subBlock26": "Verbesserung unserer Dienste und Durchführung von Forschung;",
    "subBlock3": "Kommunikationsinformationen: Wenn Sie uns Nachrichten senden, erheben wir Ihren Namen, Ihre Kontaktinformationen und den Inhalt der von Ihnen gesendeten Nachrichten (im Folgenden als „Kommunikationsinformationen“ bezeichnet).",
    "subBlock4": "Social-Media-Informationen: Wir haben Seiten auf Social-Media-Websites wie Instagram, Facebook, Medium, Twitter, YouTube und LinkedIn. Wenn Sie mit unseren Social-Media-Seiten interagieren, erheben wir die personenbezogenen Daten, die Sie uns zur Verfügung stellen, wie z. B. Ihre Kontaktdaten (im Folgenden als „soziale Informationen“ bezeichnet). Darüber hinaus können uns die Unternehmen, die unsere Social-Media-Seiten hosten, aggregierte Informationen und Analysen über unsere Social-Media-Aktivitäten zur Verfügung stellen.",
    "subBlock5": "Protokolldaten: Informationen, die Ihr Browser automatisch sendet, wenn Sie unsere Dienste nutzen. Zu den Protokolldaten gehören Ihre Internet Protocol (IP)-Adresse, Browsertyp und -einstellungen, Datum und Uhrzeit Ihrer Anfragen und wie Sie mit unserer Website interagieren.",
    "subBlock6": "Nutzungsdaten: Wir können automatisch Informationen über Ihre Nutzung des Dienstes erheben, wie z. B. die Arten von Inhalten, die Sie ansehen oder mit denen Sie interagieren, die Funktionen, die Sie nutzen, und die von Ihnen ergriffenen Maßnahmen sowie Ihre Zeitzone, Ihr Land, Datum und Uhrzeit des Zugriffs, Benutzeragent und -version, Computer- oder Mobilgerätetyp und Ihre Computerverbindung.",
    "subBlock7": "Geräteinformationen: Umfasst den Namen des Geräts, das Betriebssystem, die Gerätekennung und den von Ihnen verwendeten Browser. Die erhobenen Informationen können von dem von Ihnen verwendeten Gerätetyp und seinen Einstellungen abhängen.",
    "subBlock8": "Cookies: Wir verwenden Cookies, um unsere Dienste zu betreiben und zu verwalten und Ihre Erfahrung zu verbessern. Ein „Cookie“ ist eine Information, die von einer von Ihnen besuchten Website an Ihren Browser gesendet wird. Sie können Ihren Browser so einstellen, dass er alle Cookies akzeptiert, alle Cookies ablehnt oder Sie benachrichtigt, wenn ein Cookie bereitgestellt wird, so dass Sie jedes Mal entscheiden können, ob Sie es akzeptieren möchten. Die Ablehnung von Cookies kann jedoch dazu führen, dass Sie bestimmte Bereiche oder Funktionen der Website nicht nutzen können oder dass die Darstellung oder Funktionalität beeinträchtigt wird. Weitere Informationen über Cookies finden Sie unter All About Cookies.",
    "subBlock9": "Analysen: Wir können verschiedene Online-Analyseprodukte verwenden, die Cookies verwenden, um zu analysieren, wie Benutzer unsere Dienste nutzen, und um Ihre Erfahrung bei der Nutzung der Dienste zu verbessern.",
    "subTitle1": "Personenbezogene Daten, die wir erheben",
    "subTitle2": "Wie wir personenbezogene Daten verwenden",
    "subTitle3": "Weitergabe personenbezogener Daten",
    "subTitle4": "Ihre Rechte",
    "subTitle5": "Links zu anderen Websites",
    "subTitle6": "Sicherheit und Aufbewahrung von Informationen",
    "subTitle7": "Änderung der Datenschutzrichtlinie",
    "subTitle8": "So erreichen Sie uns",
    "title": "Datenschutzrichtlinie",
    "updateTime": "Aktualisiert: 15. März 2024"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "Alle akzeptieren",
    "text": "Um dein Erlebnis zu verbessern, möchten wir Cookies verwenden, um Inhalte und Dienste zu personalisieren.",
    "title": "Diese Webseite verwendet Cookies"
  },
  "slogen": "Der einfachste KI-Assistent",
  "stripe": {
    "contact": "Kontaktieren Sie uns bei Fragen unter contact@noam.tools!",
    "fail": {
      "btn": "Erneut versuchen",
      "text1": "Ihre Zahlungsanforderung konnte leider nicht verarbeitet werden.",
      "text2": "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
      "title": "Zahlung fehlgeschlagen"
    },
    "succ": {
      "btn": "Los geht's",
      "text1": "Willkommen bei Noam, ",
      "text2": "Los geht's!",
      "title": "Herzlichen Glückwunsch"
    }
  },
  "terms": {
    "block1": "Vielen Dank, dass Sie Noam nutzen!",
    "block10": "(a) Gebühren und Rechnungsstellung. Sie zahlen alle Gebühren (\"Gebühren\") gemäß den Preisen und Bedingungen auf der entsprechenden Preisliste oder wie zwischen uns schriftlich vereinbart. Wir behalten uns das Recht vor, Preisfehler oder Irrtümer zu korrigieren, auch wenn wir Rechnungen ausgestellt oder Zahlungen erhalten haben. Sie werden vollständige und genaue Rechnungsinformationen angeben, einschließlich einer gültigen und autorisierten Zahlungsmethode. Wir werden Ihre Zahlungsmethode regelmäßig wie vereinbart belasten, aber wir können das Rechnungsdatum angemessen ändern. Sie ermächtigen Noam, seine verbundenen Unternehmen und Drittanbieter von Zahlungsabwicklungen, Ihre Zahlungsmethode zu belasten. Wenn Ihre Zahlung fehlschlägt, werden wir Sie schriftlich benachrichtigen und können den Zugang zu dem Dienst aussetzen, bis die Zahlung eingegangen ist. Sofern in dieser Vereinbarung nicht anders angegeben, sind Zahlungen nicht erstattungsfähig.",
    "block11": "(b) Steuern. Sofern nicht anders angegeben, enthalten die Gebühren keine Bundes-, Landes-, Kommunal- und ausländischen Steuern, Zölle und ähnliche Abgaben (\"Steuern\"). Sie sind für alle Steuern verantwortlich, die im Zusammenhang mit Ihren Käufen anfallen, mit Ausnahme von Steuern, die auf unserem Nettoeinkommen basieren und für die wir Ihnen eine Rechnung stellen. Sie erklären sich damit einverstanden, diese Steuern umgehend zu zahlen und uns rechtsgültige Datenschutzerklärungen zur Verfügung zu stellen und die erforderlichen Einwilligungen für den Umgang mit diesen Daten einzuholen, und Sie sichern uns zu, dass Sie mit diesen Daten in Übereinstimmung mit geltendem Recht umgehen.",
    "block12": "(c) Preisänderungen. Wir können unsere Preise ändern, indem wir Sie über Ihr Konto und/oder unsere Website informieren. Preiserhöhungen werden mit der Veröffentlichung wirksam. Alle Preisänderungen gelten für Gebühren für Ihr Konto nach dem Datum des Inkrafttretens der Änderung.",
    "block13": "(d) Streitigkeiten und überfällige Zahlungen. Wenn Sie Gebühren oder Steuern anfechten möchten, wenden Sie sich bitte innerhalb von dreißig (30) Tagen nach Rechnungsdatum an contact@noam.tools.",
    "block14": "(e) Kostenlose Nutzung. Sie dürfen nicht mehrere Konten erstellen, um kostenlose Credits zu erhalten. Wenn wir feststellen, dass Sie die kostenlosen Credits nicht in gutem Glauben nutzen, können wir Ihnen Standardgebühren berechnen oder Ihnen den Zugang zu dem Dienst sperren.",
    "block15": "(a) Vertraulichkeit. Möglicherweise haben Sie Zugang zu vertraulichen Informationen von Noam. Sie dürfen vertrauliche Informationen nur wie in diesen Bedingungen erlaubt zum Zwecke der Nutzung des Dienstes verwenden. Sie dürfen vertrauliche Informationen nicht an Dritte weitergeben und werden vertrauliche Informationen in einer Weise schützen, die nicht weniger schützend ist als der Schutz Ihrer eigenen ähnlichen vertraulichen Informationen, zumindest mit angemessener Sorgfalt. Vertrauliche Informationen sind nicht öffentliche Informationen, die von Noam als vertraulich gekennzeichnet sind oder die unter den gegebenen Umständen vernünftigerweise als vertraulich zu behandeln sind, einschließlich Software, Spezifikationen und anderer nicht öffentlicher Geschäftsinformationen. Vertrauliche Informationen umfassen keine Informationen, die: (1) ohne Ihr Verschulden allgemein zugänglich werden; (2) sich bereits vor Erhalt im Rahmen dieser Bedingungen ohne Vertraulichkeitsverpflichtung in Ihrem Besitz befanden; (3) Ihnen von einem Dritten ohne Vertraulichkeitsverpflichtung rechtmäßig offengelegt werden; oder (4) von Ihnen unabhängig und ohne Verwendung der vertraulichen Informationen entwickelt wurden. Sie dürfen vertrauliche Informationen offenlegen, wenn dies gesetzlich, gerichtlich oder durch eine andere behördliche Anordnung vorgeschrieben ist, müssen Noam jedoch vorher schriftlich und in angemessener Weise benachrichtigen und, soweit möglich, angemessene Anstrengungen unternehmen, um den Umfang der Offenlegung zu begrenzen, einschließlich der Unterstützung bei der Bekämpfung von Offenlegungsanträgen.",
    "block16": "(b) Sicherheit. Sie müssen angemessene und geeignete Maßnahmen ergreifen, um den Zugang zu und die Nutzung des Dienstes zu schützen. Wenn Sie Schwachstellen oder Verstöße im Zusammenhang mit Ihrer Nutzung des Dienstes feststellen, müssen Sie Noam unverzüglich kontaktieren und Einzelheiten zu der Schwachstelle oder dem Verstoß mitteilen.",
    "block17": "((c) Verarbeitung personenbezogener Daten. Wenn Sie den Dienst zur Verarbeitung personenbezogener Daten nutzen, müssen Sie rechtswirksame Datenschutzerklärungen abgeben und die erforderlichen Einwilligungen für die Verarbeitung dieser Daten einholen, und Sie sichern uns zu, dass Sie mit diesen Daten in Übereinstimmung mit geltendem Recht umgehen.",
    "block18": "(a) Beendigung; Aussetzung. Diese Bedingungen treten mit Ihrer ersten Nutzung des Dienstes in Kraft und bleiben bis zur Beendigung in Kraft. Sie können diese Bedingungen jederzeit aus beliebigem Grund kündigen, indem Sie die Nutzung des Dienstes und der Inhalte einstellen. Wir können diese Bedingungen mit vorheriger Ankündigung aus beliebigem Grund kündigen. Wir können diese Bedingungen durch Benachrichtigung an Sie mit sofortiger Wirkung kündigen, wenn Sie gegen Abschnitt 2 (Nutzungsbestimmungen), Abschnitt 5 (Vertraulichkeit, Sicherheit und Datenschutz), Abschnitt 8 (Streitbeilegung) oder Abschnitt 9 (Allgemeine Bedingungen) verstoßen oder wenn sich unsere Beziehung zu einem Drittanbieter von Technologie außerhalb unserer Kontrolle ändert, oder um Gesetzen oder behördlichen Aufforderungen nachzukommen. Wir können Ihren Zugang zu dem Dienst aussetzen, wenn Sie diese Bedingungen nicht einhalten oder wenn Ihre Nutzung ein Sicherheitsrisiko für uns oder Dritte darstellt oder wenn wir vermuten, dass Ihre Nutzung betrügerisch ist oder uns oder Dritte einer Haftung aussetzen könnte.",
    "block19": "(b) Folgen der Beendigung. Mit der Beendigung werden Sie die Nutzung des Dienstes einstellen und alle vertraulichen Informationen auf unsere Anweisung hin unverzüglich zurückgeben oder vernichten. Die Bestimmungen dieser Bedingungen, die ihrer Natur nach die Beendigung oder den Ablauf überdauern sollten, einschließlich, aber nicht beschränkt auf die Abschnitte 3 und 5-9, bleiben auch nach der Beendigung in Kraft.",
    "block2": "Diese Nutzungsbedingungen gelten für Ihre Nutzung der Dienste von Noam, einschließlich unserer Anwendungsprogrammierschnittstellen, Software, Tools, Entwicklerdienste, Daten, Dokumentation und Website (zusammenfassend als die \"Dienste\" bezeichnet). Durch die Nutzung unserer Dienste erklären Sie sich mit diesen Bedingungen einverstanden. Unsere Datenschutzrichtlinie erläutert, wie wir personenbezogene Daten erfassen und verwenden.",
    "block20": "(a) Haftungsfreistellung. Sie werden uns, unsere verbundenen Unternehmen und unsere Mitarbeiter von und gegen alle Ansprüche, Verluste und Ausgaben (einschließlich Anwaltskosten) verteidigen, freistellen und schadlos halten, die sich aus oder im Zusammenhang mit Ihrer Nutzung des Dienstes ergeben, einschließlich Ihrer Inhalte, Ihrer Nutzung von Produkten oder Dienstleistungen im Zusammenhang mit dem Dienst und Ihrer Verletzung dieser Bedingungen oder geltenden Rechts.",
    "block21": "(b) Haftungsausschluss. Der Dienst wird \"wie besehen\" bereitgestellt. Soweit gesetzlich zulässig, geben wir, unsere verbundenen Unternehmen und Lizenzgeber keine Zusicherungen oder Gewährleistungen jeglicher Art in Bezug auf den Dienst ab und übernehmen keine Gewährleistungen, einschließlich, aber nicht beschränkt auf Gewährleistungen der Marktgängigkeit, der Eignung für einen bestimmten Zweck, der zufriedenstellenden Qualität, der Nichtverletzung von Rechten Dritter und des ungestörten Genusses, sowie alle Gewährleistungen, die sich aus dem Geschäftsverkehr oder Handelsbrauch ergeben. Wir garantieren nicht, dass der Dienst ununterbrochen, korrekt oder fehlerfrei ist oder dass Inhalte sicher, nicht verloren oder nicht verändert werden.",
    "block22": "(c) Haftungsbeschränkung. Weder wir noch unsere verbundenen Unternehmen oder Lizenzgeber haften für indirekte, zufällige, besondere, Folgeschäden oder Strafschadenersatz, einschließlich entgangenen Gewinns, Rufschädigung, Nutzungsausfall, Datenverlust oder anderer immaterieller Verluste, selbst wenn wir auf die Möglichkeit solcher Schäden hingewiesen wurden. Im Rahmen dieser Bedingungen übersteigt unsere Gesamthaftung nicht die Gebühren, die Sie in den zwölf (12) Monaten vor dem Anspruch für den Dienst gezahlt haben, oder einhundert US-Dollar (100 US-Dollar), je nachdem, welcher Betrag höher ist. Die Beschränkungen in diesem Abschnitt gelten im größtmöglichen Umfang, der nach geltendem Recht zulässig ist.",
    "block23": "Die Bedingungen und Ihre Nutzung der Dienste unterliegen den Gesetzen der Vereinigten Staaten von Amerika, mit Ausnahme der Regeln des Kollisionsrechts der USA. Ihre Nutzung der Anwendung kann auch anderen lokalen, bundesstaatlichen, nationalen oder internationalen Gesetzen unterliegen. Alle Klagen oder Verfahren im Zusammenhang mit diesen Bedingungen werden ausschließlich vor den Gerichten der Vereinigten Staaten von Amerika verhandelt, und Sie erklären sich damit einverstanden, sich der persönlichen Gerichtsbarkeit dieser Gerichte zu unterwerfen.",
    "block24": "(a) Verhältnis der Parteien. Noam und Sie sind unabhängige Vertragspartner, und keine Partei ist befugt, die andere Partei zu vertreten oder zu verpflichten oder Verpflichtungen für die andere Partei ohne deren vorherige schriftliche Zustimmung einzugehen.",
    "block25": "(b) Markenverwendung. Sie dürfen den Namen, die Logos oder die Marken von Noam oder eines seiner verbundenen Unternehmen nicht ohne unsere vorherige schriftliche Zustimmung verwenden.",
    "block26": "Sollte eine Bestimmung dieser Bedingungen für ungültig, rechtswidrig oder nicht durchsetzbar befunden werden, so bleiben die übrigen Bestimmungen in vollem Umfang in Kraft und wirksam.",
    "block3": "Wenn Sie unter 18 Jahre alt sind, müssen Sie die Erlaubnis Ihrer Eltern oder Erziehungsberechtigten einholen, um den Dienst nutzen zu dürfen. Wenn Sie den Dienst im Auftrag einer anderen Person oder Organisation nutzen, müssen Sie befugt sein, diese Bedingungen in deren Namen zu akzeptieren. Sie müssen genaue und vollständige Informationen angeben, um sich für ein Konto zu registrieren. Sie dürfen Ihre Zugangsdaten oder Ihr Konto nicht an Personen außerhalb Ihrer Organisation weitergeben, und Sie sind für alle Aktivitäten verantwortlich, die unter Verwendung Ihrer Zugangsdaten durchgeführt werden.",
    "block4": "(a) Nutzung des Dienstes. Sie dürfen auf den Dienst gemäß diesen Bedingungen zugreifen, und wir gewähren Ihnen ein nicht ausschließliches Recht zur Nutzung des Dienstes. Bei der Nutzung des Dienstes werden Sie diese Bedingungen und alle anwendbaren Gesetze einhalten. Wir und unsere verbundenen Unternehmen besitzen alle Rechte, Titel und Interessen an dem Dienst.",
    "block5": "(b) Feedback. Wir freuen uns über Feedback, Kommentare, Ideen, Vorschläge und Verbesserungen. Wenn Sie uns solche Inhalte zur Verfügung stellen, dürfen wir diese ohne Einschränkungen und ohne Entschädigung an Sie verwenden.",
    "block6": "(c) Einschränkungen. Sie dürfen nicht: (1) den Dienst in einer Weise nutzen, die Rechte anderer verletzt, missbraucht oder verletzt; (2) die Dienstmodelle, Algorithmen und Systeme zurückentwickeln, dekompilieren, disassemblieren, übersetzen oder anderweitig versuchen, den Quellcode oder die zugrunde liegenden Komponenten zu entdecken (es sei denn, solche Einschränkungen sind nach geltendem Recht verboten); (3) die Ausgabe des Dienstes verwenden, um Modelle zu entwickeln, die mit Noam konkurrieren; (4) Daten oder Ausgaben aus dem Dienst mit automatisierten oder programmgesteuerten Methoden extrahieren, es sei denn, dies ist über die API zulässig, einschließlich Scraping, Web Harvesting oder Web Data Extraction; (5) die Ausgabe des Dienstes als künstlich generiert darstellen, wenn dies nicht der Fall ist, oder anderweitig gegen unsere Nutzungsrichtlinien verstoßen; (6) API-Schlüssel ohne unsere vorherige Zustimmung kaufen, verkaufen oder übertragen; oder (7) uns personenbezogene Daten von Kindern unter 13 Jahren oder dem entsprechenden Alter der digitalen Zustimmung zur Verfügung stellen. Sie werden alle Ratenbegrenzungen und anderen Anforderungen in unserer Dokumentation einhalten.",
    "block7": "(d) Dienste Dritter. Jegliche Software, Dienste oder andere Produkte von Drittanbietern, die mit dem Dienst verbunden sind, unterliegen deren eigenen Bedingungen, und wir sind nicht verantwortlich für Produkte von Drittanbietern.",
    "block8": "(a) Ihre Inhalte. Sie können Eingaben (\"Eingabe\") an den Dienst übermitteln und Ausgaben (\"Ausgabe\") erhalten, die auf der Grundlage der Eingabe generiert und zurückgegeben werden (zusammenfassend als \"Inhalte\" bezeichnet). Zwischen den Parteien und soweit nach geltendem Recht zulässig, sind Sie Eigentümer aller Eingaben. Noam darf Inhalte verwenden, um den Dienst bereitzustellen und zu warten, geltende Gesetze einzuhalten und unsere Richtlinien durchzusetzen. Sie sind für die Inhalte verantwortlich, einschließlich der Sicherstellung, dass sie nicht gegen geltende Gesetze oder diese Bedingungen verstoßen.",
    "block9": "(b) Genauigkeit. Künstliche Intelligenz und maschinelles Lernen sind sich schnell entwickelnde Forschungsgebiete. Wir sind ständig bestrebt, unseren Dienst zu verbessern, um ihn genauer, zuverlässiger, sicherer und nützlicher zu machen. Angesichts der probabilistischen Natur des maschinellen Lernens kann die Nutzung unseres Dienstes in bestimmten Fällen zu falschen Ausgaben führen, die möglicherweise keine realen Personen, Orte oder Fakten widerspiegeln. Sie sollten die Richtigkeit jeder Ausgabe entsprechend Ihrem Anwendungsfall angemessen beurteilen, auch durch manuelle Überprüfung der Ausgaben.",
    "subTitle1": "Registrierung und Zugang",
    "subTitle10": "Salvatorische Klausel",
    "subTitle2": "Nutzungsbestimmungen",
    "subTitle3": "Inhalt",
    "subTitle4": "Gebühren und Zahlung",
    "subTitle5": " Vertraulichkeit, Sicherheit und Datenschutz",
    "subTitle6": "Laufzeit und Beendigung",
    "subTitle7": "Haftungsfreistellung; Haftungsausschluss; Haftungsbeschränkung",
    "subTitle8": "Geltendes Recht und Gerichtsstand",
    "subTitle9": "Allgemeine Bedingungen",
    "title": "Nutzungsbedingungen",
    "updateTime": "Aktualisiert: 15. März 2024"
  },
  "trialModal": {
    "btn": "Jetzt loslegen (Nur 0,99 $)",
    "chat": "ChatPDF",
    "desc1": "Nach Ablauf des Testzeitraums wird eine Jahresgebühr von 180 $ erhoben.",
    "desc2": "Jederzeit kündbar",
    "focus": "Fokus",
    "hover": "Mouseover-Übersetzung",
    "image": "Bildübersetzung",
    "installBtn": "Jetzt installieren und Geschenk erhalten",
    "memo": "Favoritenverwaltung",
    "pdf": "Parallele PDF-Übersetzung",
    "realtime": "Sofortübersetzung",
    "select": "Worübersetzung",
    "title": "Willkommensgeschenk",
    "trialText": "7-Tage-Testversion",
    "video": "Videountertitel",
    "videoSummary": "Video Zusammenfassen",
    "web": "Parallele Übersetzung",
    "webSummary": "Webseite Zusammenfassen"
  },
  "upgradeModal": {
    "btn": "Jetzt upgraden",
    "chat": {
      "free": "Sie haben Ihr Chatpdf-Limit erreicht",
      "pro": "Sie haben Ihr Chatpdf-Limit in diesem Monat erreicht"
    },
    "contactStr": "Bitte warten Sie auf das nächste Zurücksetzen oder kontaktieren Sie uns unter contact@noam.tools.",
    "limitAlertStr": "Ihr kostenloses Kontingent ist aufgebraucht. Wir empfehlen Ihnen, Ihren Plan zu aktualisieren, um die Erfahrung fortzusetzen!",
    "more": "Mehr Pläne",
    "pageLimit": {
      "free": "Sie haben Ihr PDF-Seitenlimit erreicht"
    },
    "title": "Erinnerungen",
    "upgradeAlertStr": "Ihr kostenloses Kontingent ist aufgebraucht. Wir empfehlen Ihnen, Ihren Plan zu aktualisieren, um die Erfahrung fortzusetzen!",
    "upload": {
      "free": "Sie haben Ihr Limit für die PDF-Übersetzung erreicht",
      "pro": "Sie haben Ihr Limit für die PDF-Übersetzung in diesem Monat erreicht"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "Jetzt beitreten",
      "label": "Vorteile für neue Nutzer:",
      "remain": "Nur noch 30 übrig",
      "text": "Nur noch 100 Plätze verfügbar! Jetzt zugreifen!"
    }
  }
}

export default TRANSLATION
