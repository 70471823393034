const TRANSLATION = {
  slogen: "L'assistente AI più semplice",
  pageHeader: {
    help: 'Centro assistenza',
    contact: 'Contattaci',
    pricing: 'Prezzi',
    signUp: 'Login',
    settings: 'Impostazioni',
    logout: 'Esci',
    logoutSucc: 'Disconnessione riuscita',
  },
  pageFooter: {
    product: {
      title: 'Prodotti',
      web: 'Traduzione Web e riepilogo',
      pdf: 'Traduzione PDF',
      video: 'Traduzione video e riepilogo',
      image: 'Traduzione di immagini',
    },
    resources: { title: 'Risorse' },
    follow: { title: 'Seguire', twitter: 'Twitter', facebook: 'Facebook' },
    legal: { title: 'Legale', policy: 'politica sulla riservatezza', terms: 'Termini di servizio' },
  },
  home: {
    getStart: 'Inizia',
    getStartedForFree: 'Inizia gratuitamente',
    top: {
      desc: "Noam è un'estensione gratuita del browser AI che ti aiuta a tradurre pagine Web riepilogate, video e contenuti PDF.\n      Unisciti a Noam e goditi la magia!",
      title2: 'Una magia',
      title1: 'Un clic',
    },
    howItWork: {
      title: 'Allora, come funziona',
      desc: "Noam integra modelli di intelligenza artificiale per fornire agli utenti un'esperienza intelligente per la traduzione e il riepilogo di pagine Web in più lingue, la traduzione di PDF e immagini, la traduzione e il riepilogo di video",
    },
    PDFTranslate: {
      title: 'PDF Traduci',
      desc: 'Traduzione bilingue + Chat PDF',
      sub1: {
        title: 'Lettura bilingue',
        desc: "Lettura bilingue, così puoi confrontare il testo originale e la traduzione durante la lettura per migliorare l'efficienza della lettura",
      },
      sub2: {
        title: 'Chatta PDF',
        desc: "L'intelligenza artificiale analizza e riassume e può rispondere alle tue domande in base al contenuto del PDF",
      },
      sub3: {
        title: 'Precisione ed efficienza',
        desc: "PDF basato sull'intelligenza artificiale per traduzioni più accurate ed efficienti",
      },
    },
    aiAssistant: {
      title: 'Assistente AI',
      desc: 'Rendere la traduzione web più intelligente e conveniente',
      panel1: {
        title: 'Traduzione bilingue',
        desc: 'Le traduzioni bilingue con riferimenti incrociati ti aiutano a confrontare il testo originale con la traduzione in modo da poter capire meglio e migliorare la tua efficienza di lettura.',
      },
      panel2: {
        left: {
          title: 'Traduzione al passaggio del mouse',
          desc: 'Durante la navigazione in una pagina Web, posiziona il mouse direttamente sul contenuto che deve essere tradotto per ottenere il risultato della traduzione.',
        },
        right: {
          title: 'Traduzione della selezione del testo',
          desc: 'Durante la navigazione di una pagina web, seleziona direttamente il testo da sottolineare per ottenere il risultato della traduzione.',
        },
      },
      panel3: {
        one: {
          title: 'Punta e clicca',
          desc: "Traduci e riassumi pagine web e contenuti video per te sempre, ovunque e in qualsiasi scenario; offrendoti un'esperienza più fluida!",
        },
        two: {
          title: 'Più completo e conveniente',
          desc: "Copertura completa multilingue, lettura bilingue senza barriere, traduzione con una sola chiave; offrirti un'esperienza senza precedenti!",
        },
        three: {
          title: "Guidato dall'intelligenza artificiale",
          desc: "Grande modello basato sull'intelligenza artificiale per garantire l'accuratezza e la coerenza della traduzione e migliorare l'esperienza di lettura",
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Traduzione video e riepilogo',
          desc: 'Il tuo esperto di efficienza, combinato con chatgpt, traduce e riassume i contenuti video di YouTube per risparmiare tempo di lettura!',
        },
        tab2: {
          key: 'Immagine',
          title: 'Traduzione di immagini',
          desc: 'Fare clic per tradurre le immagini durante la navigazione sul Web',
        },
        tab3: {
          key: 'Rete',
          title: 'Traduzione e riepilogo Web',
          desc: "Estrazione rapida delle informazioni chiave dalle pagine Web per il riepilogo, migliorando l'efficienza della lettura",
        },
        tag1: 'Semplice',
        tag2: 'Efficienza',
        tag3: 'Facilmente comprensibile',
      },
    },
    userReviews: {
      title: 'Recensioni degli utenti',
      desc: 'Recensione a cinque stelle sul chromewebstore',
    },
    lastBlock: {
      desc: 'Migliore traduzione tramite intelligenza artificiale ed esperienza di lettura più fluida',
    },
  },
  login: {
    title: 'Benvenuto a Noam',
    btn: 'Accedi con Google',
    tips: 'Iscrivendomi, acconsento',
    terms: 'Termini di utilizzo',
    and: 'E',
    policy: 'politica sulla riservatezza',
    btn1: 'Accedi adesso',
    desc: 'Accedi per sbloccare più esperienze',
  },
  pricing: {
    pageTitle: 'Prezzi',
    desc: 'Confronta e Scegli il meglio per te',
    yearly: 'Annualmente',
    monthly: 'Mensile',
    discount: '20% di sconto',
    preMonth: 'Al mese',
    billedYearly: 'Fatturato annualmente',
    billedMonthly: 'Fatturazione mensile',
    detailedCompare: 'Confronto dettagliato',
    FAQ: 'Domande frequenti',
    day: 'giorno',
    month: 'mese',
    around: 'In giro',
    free: {
      benefit1: 'Traduzione istantanea 100 volte al giorno',
      benefit2: 'Traduzione web e riepilogo',
      benefit3: '30 fogli di traduzione di immagini',
      benefit4: 'Traduzione video e riepilogo',
      benefit5: '10 Traduzione PDF',
      benefit6: '5.000 gettoni',
      recommendText: 'Prova gratuita',
      btn: 'Divertiti',
    },
    pro: {
      benefit1: 'Servizio clienti Supporto rapido',
      benefit2: '1.000.000 di token/mese',
      benefit3: 'Traduzione web e riepilogo',
      benefit4: '200 fogli di traduzione di immagini',
      benefit5: 'Traduzione video e riepilogo',
      benefit6: '200 Traduzione PDF',
      btn: 'Inizia',
      recommendText: 'La maggior parte delle persone sceglie',
    },
    proPlus: {
      benefit1: 'Servizio clienti Supporto rapido',
      benefit2: '3.000.000 di token/mese',
      benefit3: 'Traduzione web e riepilogo',
      benefit4: '500 fogli di traduzione di immagini',
      benefit5: 'Traduzione video e riepilogo',
      benefit6: '500 traduzioni PDF',
      recommendText: 'La scelta più adatta',
    },
    enterprise: {
      benefit1: 'Servizio clienti Supporto rapido',
      benefit2: 'Multiutente',
      benefit3: 'Soluzioni personalizzate',
      benefit5: 'Account Manager esclusivo',
      recommendText: 'Piani personalizzati（≥3人）',
      talkToUs: 'Parla con noi',
      btn: 'Contatto',
    },
    web: {
      contrastiveTranslation: 'Traduzione bilingue',
      instantTranslation: 'Traduzione istantanea',
      hoverTranslation: 'Traduzione al passaggio del mouse',
      dictionaryTranslation: 'Traduzione della selezione del testo',
      summary: 'Riepilogo della pagina Web',
    },
    imageTranslation: 'Traduzione di immagini',
    video: { translation: 'Traduzione video', summary: 'Riepilogo video' },
    pdf: {
      translation: 'Traduzione PDF',
      maxPage: 'Numero massimo di pagine nel PDF',
      chat: 'Numero di richieste PDF',
    },
    FQAObj: {
      q1: 'Posso usarlo gratuitamente?',
      a1: 'Esiste una versione gratuita di Noam che può essere utilizzata direttamente.',
      q2: 'Esistono piani di abbonamento e opzioni tariffarie diversi?',
      a2: "Oltre alla versione gratuita sono disponibili tre piani di abbonamento, Pro, Pro+ ed Enterprise; puoi scegliere tra due modalità di pagamento, mensile continuo e annuale continuo, e il costo complessivo dell'annuale continuo è più favorevole.",
      q3: 'Qual è la differenza tra un abbonamento mensile continuo e un abbonamento annuale continuo?',
      a3: "Non c'è differenza nella funzionalità. Il costo complessivo dell'abbonamento annuale continuo è inferiore a quello dell'abbonamento mensile continuo; l'abbonamento mensile viene fatturato una volta al mese, mentre l'abbonamento annuale viene fatturato una volta all'anno.",
      q4: 'Come posso annullare il mio piano di rinnovo automatico?',
      a4: 'Puoi andare alla pagina delle impostazioni del backend e scegliere di iscriverti per annullare il piano di rinnovo.',
    },
  },
  upgradeModal: {
    title: 'Promemoria',
    limitAlertStr:
      "La quota del tuo piano gratuito è esaurita, ti consigliamo di aggiornare il tuo piano per continuare l'esperienza~!",
    upgradeAlertStr:
      "La quota del tuo piano gratuito è esaurita, ti consigliamo di aggiornare il tuo piano per continuare l'esperienza~!",
    btn: 'Aggiorna ora',
    more: 'Più piani',
    contactStr: "Attendi il prossimo ripristino o contattaci all'indirizzo contact@noam.tools.",
    upload: {
      free: 'Hai raggiunto il limite della traduzione in pdf',
      pro: 'Hai raggiunto il limite di traduzione in pdf in questo mese',
    },
    chat: {
      free: 'Hai raggiunto il limite del limite di Chatpdf superato',
      pro: 'Hai raggiunto il limite di Chatpdf in questo mese',
    },
    pageLimit: { free: 'Hai raggiunto il limite di pagine PDF superato' },
  },
  buyModal: {
    title: 'Piano di abbonamento',
    freeTrial: 'Prova gratuita di 3 giorni',
    selectLabel: 'Seleziona un piano',
    btn: 'Provalo gratuitamente',
    yearLabel: 'anno',
    monthLabel: 'mese',
    afterFree: 'Dopo il processo',
  },
  privacy: {
    title: 'politica sulla riservatezza',
    updateTime: 'Aggiornato: 15 marzo 2024',
    block1:
      'Noi, Noam (denominati "Noam", "noi", "nostro" o "ci"), rispettiamo la tua privacy e ci impegniamo a proteggere qualsiasi informazione che otteniamo da te o su di te. La presente Informativa sulla privacy descrive le nostre pratiche relative alla raccolta o all\'utilizzo delle informazioni personali derivanti dall\'utilizzo del nostro sito Web, delle applicazioni e dei servizi (collettivamente denominati "Servizi"). La presente Informativa sulla privacy non si applica ai contenuti che gestiamo per conto dei nostri clienti di prodotti aziendali, come la nostra API. L\'utilizzo di questi dati è soggetto al nostro contratto con il cliente, che copre l\'accesso e l\'utilizzo di questi prodotti.',
    subTitle1: 'Informazioni personali che raccogliamo',
    block2:
      'Raccogliamo le seguenti informazioni personali ("Informazioni personali") relative all\'utente:',
    block3:
      "Informazioni fornite dall'utente: se crei un account per utilizzare i nostri servizi o comunicare con noi, raccoglieremo informazioni personali come segue:",
    subBlock1:
      "Informazioni sull'account: quando crei un account sulla nostra piattaforma, raccogliamo informazioni relative al tuo account, inclusi nome, informazioni di contatto, credenziali dell'account, informazioni sulla carta di pagamento e cronologia delle transazioni (di seguito \"Informazioni sull'account\").",
    subBlock2:
      'Contenuti dell\'utente: quando utilizzi i nostri servizi, raccogliamo informazioni personali dai tuoi input, caricamenti di file o feedback forniti (di seguito "Contenuti").',
    subBlock3:
      'Informazioni sulla comunicazione: se ci invii messaggi, raccoglieremo il tuo nome, le informazioni di contatto e il contenuto dei messaggi che invii (denominati "Informazioni sulla comunicazione").',
    subBlock4:
      'Informazioni sui social media: abbiamo pagine sui siti Web di social media come Instagram, Facebook, Medium, Twitter, YouTube e LinkedIn. Quando interagisci con le nostre pagine sui social media, raccogliamo le informazioni personali che scegli di fornirci, come i tuoi dettagli di contatto (di seguito "Informazioni social"). Inoltre, le società che ospitano le nostre pagine sui social media possono fornirci informazioni aggregate e analisi sulle nostre attività sui social media.',
    block4:
      'Informazioni personali ricevute automaticamente dal tuo utilizzo dei servizi: quando accedi, utilizzi o interagisci con i servizi, riceviamo le seguenti informazioni sul tuo accesso, utilizzo o interazione ("Informazioni tecniche"):',
    subBlock5:
      "Dati di registro: informazioni inviate automaticamente dal tuo browser quando utilizzi i nostri servizi. I dati di registro includono il tuo indirizzo IP (protocollo Internet), il tipo e le impostazioni del browser, la data e l'ora delle tue richieste e il modo in cui interagisci con il nostro sito web.",
    subBlock6:
      "Dati di utilizzo: potremmo raccogliere automaticamente informazioni sul tuo utilizzo del servizio, come i tipi di contenuti che visualizzi o con cui interagisci, le funzionalità che utilizzi e le azioni intraprese, nonché il fuso orario, il Paese, la data e l'ora del servizio. accesso, agente utente e versione, tipo di computer o dispositivo mobile e connessione al computer.",
    subBlock7:
      "Informazioni sul dispositivo: include il nome del dispositivo, il sistema operativo, l'identificatore del dispositivo e il browser che stai utilizzando. Le informazioni raccolte possono dipendere dal tipo di dispositivo che stai utilizzando e dalle sue impostazioni.",
    subBlock8:
      'Cookie: utilizziamo i cookie per operare e gestire i nostri servizi e migliorare la tua esperienza. Un "cookie" è un\'informazione inviata da un sito web visitato al tuo browser. Puoi impostare il tuo browser per accettare tutti i cookie, rifiutare tutti i cookie o avvisarti quando viene fornito un cookie in modo che tu possa decidere se accettarlo ogni volta. Tuttavia, rifiutare i cookie potrebbe impedirti di utilizzare o influenzare negativamente la visualizzazione o la funzionalità di determinate aree o funzionalità del sito web. Per ulteriori informazioni sui cookie, visitare Tutto sui cookie.',
    subBlock9:
      "Analisi: potremmo utilizzare vari prodotti di analisi online che utilizzano cookie per aiutarci ad analizzare il modo in cui gli utenti utilizzano i nostri servizi e migliorare la tua esperienza durante l'utilizzo dei servizi.",
    subTitle2: 'Come utilizziamo le informazioni personali',
    block5: 'Potremmo utilizzare le informazioni personali per i seguenti scopi:',
    subBlock10: 'Fornire, gestire, mantenere e/o analizzare servizi;',
    subBlock26: 'Migliorare i nostri servizi e condurre ricerche;',
    subBlock11: 'Comunicare con te;',
    subBlock12: 'Sviluppo di nuovi progetti e servizi;',
    subBlock13:
      'Prevenire frodi, attività criminali o uso improprio dei nostri servizi e proteggere la sicurezza dei nostri sistemi IT, architettura e reti;',
    subBlock14: "Conduzione di trasferimenti d'impresa;",
    subBlock15:
      "Rispettare obblighi legali e procedimenti legali, proteggere i nostri diritti, la privacy, la sicurezza o la proprietà, nonché quelli dei nostri affiliati, dell'utente o di altre terze parti.",
    block6:
      "Informazioni riepilogative o anonimizzate: possiamo aggregare o anonimizzare le informazioni personali per impedirne il riutilizzo a fini di identificazione e utilizzare tali informazioni per analizzare l'efficacia dei nostri servizi, migliorare e aggiungere funzionalità ai nostri servizi, condurre ricerche e per scopi simili . Inoltre, potremmo analizzare il comportamento generale e le caratteristiche degli utenti dei nostri servizi e condividere informazioni aggregate con terze parti, pubblicare tali informazioni aggregate o renderle generalmente disponibili. Possiamo raccogliere informazioni aggregate attraverso i servizi, attraverso i cookie e attraverso altri mezzi descritti nella presente Informativa sulla privacy. Manterremo e utilizzeremo le informazioni deidentificate in forma anonima o deidentificata e non tenteremo di identificare nuovamente tali informazioni se non richiesto dalla legge.",
    subTitle3: 'Divulgazione di informazioni personali',
    block7:
      'In alcuni casi, potremmo fornire le tue informazioni personali a terzi senza ulteriore avviso, a meno che non sia richiesto dalla legge:',
    subBlock16:
      'Fornitori e prestatori di servizi: per aiutarci a soddisfare le esigenze operative aziendali e a eseguire determinati servizi e funzioni, potremmo fornire informazioni personali a fornitori e prestatori di servizi, inclusi fornitori di servizi di hosting, fornitori di servizi cloud e altri fornitori di servizi informatici, software di comunicazione e-mail e fornitori di servizi di analisi web, ecc. Questi partner accederanno, elaboreranno o memorizzeranno le informazioni personali solo su nostra indicazione mentre svolgono i loro compiti per noi.',
    subBlock17:
      'Trasferimenti aziendali: se ci impegniamo in transazioni strategiche, riorganizzazioni, fallimenti, acquisizioni di fallimento o transizione di servizi a un altro fornitore (collettivamente denominati "Transazioni"), le tue informazioni personali e altre informazioni potrebbero essere condivise con le controparti della transazione e altre parti che assistono in le Operazioni durante la due diligence e trasferite come parte delle Operazioni insieme ad altri beni a una società successore o affiliata.',
    subBlock18:
      'Requisiti legali: potremmo condividere le tue informazioni personali con autorità governative, colleghi del settore o altre terze parti, comprese le informazioni sulla tua interazione con i nostri servizi, (1) se richiesto dalla legge o se riteniamo che tale azione sia necessaria per rispettare gli obblighi legali; (2) per proteggere e difendere i nostri diritti o proprietà; (3) se determiniamo unilateralmente che esiste una violazione dei nostri termini, politiche o leggi; (4) per individuare o prevenire frodi o altre attività illegali; (5) per proteggere i nostri prodotti, dipendenti o utenti o la pubblica sicurezza, protezione e integrità; o (vi) per difendersi da responsabilità legali.',
    subTitle4: 'I tuoi diritti',
    block8:
      'A seconda della posizione geografica, gli individui nello Spazio Economico Europeo, nel Regno Unito e nel mondo potrebbero avere determinati diritti legali in merito alle proprie informazioni personali. Ad esempio, potresti avere il diritto di:',
    subBlock19:
      'Accedi alle tue informazioni personali e alle informazioni su come vengono elaborate.',
    subBlock20: 'Elimina le tue informazioni personali dai nostri archivi.',
    subBlock21: 'Correggere o aggiornare le tue informazioni personali.',
    subBlock22: 'Trasferire le tue informazioni personali a terzi (portabilità dei dati).',
    subBlock23: 'Limita il modo in cui trattiamo le tue informazioni personali.',
    subBlock24:
      'Revocare il tuo consenso: laddove facciamo affidamento sul consenso come base giuridica per il trattamento, puoi revocare il tuo consenso in qualsiasi momento.',
    subBlock25: 'Opporsi al modo in cui trattiamo le tue informazioni personali.',
    subTitle5: 'Collegamenti ad altri siti Web',
    block9:
      'Il servizio può contenere collegamenti ad altri siti Web non gestiti o controllati da Noam, inclusi servizi di social media ("Siti Web di terze parti"). Le informazioni condivise con i siti Web di terze parti saranno regolate dalle politiche sulla privacy e dai termini di servizio specifici dei siti Web di terze parti, non dalla presente Informativa sulla privacy. Forniamo questi collegamenti per comodità e non implicano la nostra approvazione o revisione di questi siti web. Si prega di contattare direttamente i siti Web di terze parti per informazioni sulle loro politiche e termini sulla privacy.',
    subTitle6: 'Sicurezza e conservazione delle informazioni',
    block10:
      "Implementiamo misure tecniche, amministrative e organizzative commercialmente ragionevoli per proteggere le informazioni personali online e offline da perdita, uso improprio e accesso non autorizzato, divulgazione, alterazione o atti ostili. Tuttavia, nessuna trasmissione via Internet o e-mail può garantire la completa sicurezza o accuratezza. In particolare, le e-mail inviate a noi o da noi potrebbero non essere sicure. Pertanto, dovresti prestare particolare attenzione nel decidere quali informazioni inviarci tramite il servizio o l'e-mail. Inoltre, non siamo responsabili per qualsiasi ostruzione delle impostazioni sulla privacy o delle misure di sicurezza nel servizio o nelle impostazioni di sicurezza o nelle misure di sicurezza dei siti Web di terze parti. Conserveremo le tue informazioni personali per tutto il tempo necessario per fornirti servizi o per altri scopi commerciali legittimi, come la risoluzione di controversie, motivi di sicurezza e protezione o il rispetto dei nostri obblighi legali. Il periodo di tempo in cui conserviamo le informazioni personali dipenderà da molti fattori, come la quantità, la natura, la sensibilità delle informazioni, i potenziali rischi di utilizzo o divulgazione non autorizzati, gli scopi per i quali trattiamo le informazioni e altre considerazioni giuridicamente vincolanti.",
    subTitle7: 'Modifica della politica sulla privacy',
    block11:
      'Potremmo aggiornare la presente Informativa sulla privacy di tanto in tanto. Quando lo faremo, pubblicheremo una versione aggiornata su questa pagina, se non diversamente richiesto dalla legge applicabile.',
    subTitle8: 'Come contattarci',
    block12:
      'In caso di domande o dubbi senza risposta sulla presente Informativa sulla privacy, contattare il nostro servizio clienti. E-mail:',
  },
  terms: {
    title: 'Termini di servizio',
    updateTime: 'Aggiornato: 15 marzo 2024',
    block1: 'Grazie per aver utilizzato Noam!',
    block2:
      'Le presenti Condizioni d\'uso si applicano all\'utilizzo dei servizi Noam, comprese le nostre interfacce di programmazione delle applicazioni, software, strumenti, servizi per sviluppatori, dati, documentazione e sito Web (collettivamente denominati "Servizi"). Utilizzando i nostri Servizi, accetti di rispettare questi termini. La nostra Informativa sulla privacy spiega come raccogliamo e utilizziamo le informazioni personali.',
    subTitle1: 'Registrazione e accesso',
    block3:
      "Se hai meno di 18 anni, devi ottenere il permesso dai tuoi genitori o tutori legali per utilizzare il Servizio. Se utilizzi il Servizio per conto di un'altra persona o entità, devi essere autorizzato ad accettare questi termini per loro conto. È necessario fornire informazioni accurate e complete per registrare un account. Non puoi fornire le tue credenziali di accesso o il tuo account a nessuno al di fuori della tua organizzazione e sei responsabile di tutte le attività condotte utilizzando le tue credenziali.",
    subTitle2: 'Requisiti di utilizzo',
    block4:
      '(a) Utilizzo del Servizio. Puoi accedere al Servizio secondo questi termini e ti concediamo un diritto non esclusivo di utilizzare il Servizio. Quando utilizzi il Servizio, rispetterai questi termini e tutte le leggi applicabili. Noi e i nostri affiliati possediamo tutti i diritti, titoli e interessi nel Servizio.',
    block5:
      '(b) Feedback. Accogliamo con favore feedback, commenti, idee, suggerimenti e miglioramenti. Se fornisci tali contenuti, potremmo utilizzarli senza alcuna restrizione e senza alcun compenso per te.',
    block6:
      "(c) Restrizioni. Non è possibile: (1) utilizzare il Servizio in un modo che violi, si appropri indebitamente o violi i diritti di altri; (2) decodificare, decompilare, disassemblare, tradurre o tentare in altro modo di scoprire il codice sorgente o i componenti sottostanti dei modelli, degli algoritmi e dei sistemi del Servizio (a meno che tali restrizioni non siano proibite dalla legge applicabile); (3) utilizzare i risultati del Servizio per sviluppare modelli concorrenti di Noam; (4) estrarre dati o output dal Servizio utilizzando qualsiasi metodo automatizzato o di programmazione se non consentito tramite l'API, inclusi scraping, raccolta web o estrazione di dati web; (5) rappresentare l'output del Servizio come generato artificialmente quando non lo è, o violare in altro modo le nostre politiche di utilizzo; (6) acquistare, vendere o trasferire chiavi API senza il nostro previo consenso; o (7) fornirci informazioni personali di bambini di età inferiore a 13 anni o all'età applicabile del consenso digitale. Rispetterai eventuali limiti tariffari e altri requisiti indicati nella nostra documentazione.",
    block7:
      '(d) Servizi di terze parti. Eventuali software, servizi o altri prodotti di terze parti associati al Servizio sono regolati dai rispettivi termini e non siamo responsabili per i prodotti di terze parti.',
    subTitle3: 'Contenuto',
    block8:
      '(a) I tuoi contenuti. L\'utente può fornire input ("Input") al Servizio e ricevere output ("Output") generato e restituito in base all\'Input (collettivamente denominato "Contenuto"). Tra le parti, e nella misura consentita dalla legge applicabile, sei proprietario di tutti gli Input. Noam può utilizzare i Contenuti per fornire e mantenere il Servizio, rispettare le leggi applicabili e far rispettare le nostre politiche. L\'utente è responsabile del Contenuto, inclusa la garanzia che non violi alcuna legge applicabile o questi termini.',
    block9:
      "(b) Precisione. L’intelligenza artificiale e l’apprendimento automatico sono campi di ricerca in rapida evoluzione. Ci impegniamo costantemente a migliorare il nostro servizio per renderlo più accurato, affidabile, sicuro e vantaggioso. Data la natura probabilistica dell'apprendimento automatico, in alcuni casi l'utilizzo del nostro servizio potrebbe comportare risultati errati, che potrebbero non riflettere accuratamente persone, luoghi o fatti reali. Dovresti valutare adeguatamente l'accuratezza di qualsiasi output in base al tuo caso d'uso, anche rivedendo manualmente gli output.",
    subTitle4: 'Commissioni e pagamenti',
    block10:
      '(a) Commissioni e fatturazione. Pagherai tutte le tariffe ("Commissioni") in base ai prezzi e ai termini indicati nella pagina dei prezzi applicabile o come concordato per iscritto tra di noi. Ci riserviamo il diritto di correggere errori o errori nei prezzi anche se abbiamo emesso fatture o ricevuto pagamenti. Fornirai informazioni di fatturazione complete e accurate, incluso un metodo di pagamento valido e autorizzato. Addebiteremo periodicamente il tuo metodo di pagamento come concordato, ma potremmo ragionevolmente modificare la data di fatturazione. Autorizzi Noam, le sue affiliate e i processori di pagamento di terze parti ad addebitare il tuo metodo di pagamento. Se il pagamento non va a buon fine, ti forniremo un avviso scritto e potremmo sospendere l\'accesso al Servizio fino al ricevimento del pagamento. Salvo quanto diversamente specificato nel presente accordo, i pagamenti non sono rimborsabili.',
    block11:
      '(b) Imposte. Salvo diversa indicazione, le Tariffe non includono tasse, dazi e imposte federali, statali, locali ed estere ("Tasse"). Sei responsabile di tutte le tasse associate ai tuoi acquisti, escluse le tasse basate sul nostro reddito netto per il quale ti fattureremo. Accetti di pagare tempestivamente tali tasse e di fornirci informative sulla privacy legalmente valide e di ottenere i consensi necessari per il trattamento di tali dati, e ci garantisci di gestire tali dati in conformità con la legge applicabile.',
    block12:
      '(c) Variazioni di prezzo. Potremmo modificare i nostri prezzi avvisandoti tramite il tuo account e/o il nostro sito web. Gli aumenti di prezzo entreranno in vigore immediatamente dopo la pubblicazione. Eventuali modifiche di prezzo verranno applicate alle Commissioni per il tuo account dopo la data di entrata in vigore della modifica.',
    block13:
      '(d) Controversie e pagamenti scaduti. Se desideri contestare eventuali commissioni o tasse, contatta contact@noam.tools entro trenta (30) giorni dalla data della fattura.',
    block14:
      "(e) Utilizzo gratuito. Non è possibile creare più account per ottenere crediti gratuiti. Se stabiliamo che non stai utilizzando i crediti gratuiti in buona fede, potremmo addebitarti tariffe standard o interrompere l'accesso al Servizio.",
    subTitle5: 'Riservatezza, sicurezza e protezione dei dati',
    block15:
      "(a) Riservatezza. Potresti avere accesso a informazioni riservate di Noam. È possibile utilizzare solo le informazioni riservate consentite dai presenti termini allo scopo di utilizzare il Servizio. Non puoi divulgare informazioni riservate a terzi e proteggerai le informazioni riservate in un modo non meno protettivo di quello con cui proteggi le tue informazioni riservate simili, almeno con ragionevole cura. Le informazioni riservate sono informazioni non pubbliche designate come riservate da Noam o che dovrebbero ragionevolmente essere trattate come riservate date le circostanze, inclusi software, specifiche e altre informazioni commerciali non pubbliche. Le informazioni riservate non includono informazioni che: (1) diventano generalmente disponibili al pubblico senza colpa da parte tua; (2) eri in tuo possesso senza alcun obbligo di riservatezza prima di riceverlo ai sensi dei presenti termini; (3) ti vengono legalmente divulgati da terzi senza alcun obbligo di riservatezza; o (4) è sviluppato in modo indipendente dall'utente senza utilizzare le informazioni riservate. Puoi divulgare informazioni riservate se richiesto dalla legge, da un tribunale o da un altro ordine governativo, ma devi fornire previa comunicazione scritta a Noam in modo ragionevole e, per quanto possibile, compiere sforzi ragionevoli per limitare l'ambito della divulgazione, inclusa l'assistenza nell'opporsi alle richieste di divulgazione.",
    block16:
      "(b) Sicurezza. È necessario implementare misure ragionevoli e appropriate per contribuire a salvaguardare l'accesso e l'utilizzo del Servizio. Se scopri eventuali vulnerabilità o violazioni relative al tuo utilizzo del Servizio, devi contattare tempestivamente Noam e fornire i dettagli della vulnerabilità o violazione.",
    block17:
      '((c) Trattamento dei dati personali. Se utilizzi il Servizio per trattare dati personali, devi fornire informative sulla privacy legalmente sufficienti e ottenere i consensi necessari per il trattamento di tali dati, e ci garantisci di gestire tali dati in conformità con la legge applicabile .',
    subTitle6: 'Durata e risoluzione',
    block18:
      "(a) Risoluzione; Sospensione. Questi termini entreranno in vigore dal primo utilizzo del Servizio e rimarranno in vigore fino alla risoluzione. L'utente può risolvere questi termini in qualsiasi momento e per qualsiasi motivo interrompendo l'uso del Servizio e dei Contenuti. Possiamo risolvere questi termini con preavviso per qualsiasi motivo. Potremo risolvere immediatamente i presenti termini avvisandoti in caso di violazione materiale della Sezione 2 (Requisiti di utilizzo), Sezione 5 (Riservatezza, sicurezza e protezione dei dati), Sezione 8 (Risoluzione delle controversie) o Sezione 9 (Condizioni generali), o se il nostro rapporto con qualsiasi fornitore di tecnologia di terze parti al di fuori del nostro controllo, modifiche o per conformità con la legge o richieste governative. Potremo sospendere il tuo accesso al Servizio se non rispetti questi termini, o se il tuo utilizzo rappresenta un rischio per la sicurezza nostra o di terze parti, o se sospettiamo che il tuo utilizzo sia fraudolento o possa esporre noi o terze parti a responsabilità .",
    block19:
      '(b) Effetti della risoluzione. Al momento della risoluzione, cesserai di utilizzare il Servizio e restituirai o distruggerai tempestivamente qualsiasi informazione riservata come da noi indicato. Le disposizioni di questi termini che per loro natura dovrebbero sopravvivere alla risoluzione o alla scadenza, incluse ma non limitate alle Sezioni 3 e 5-9, sopravvivranno.',
    subTitle7: 'Indennizzo; dichiarazioni di non responsabilità; Limitazione di responsabilità',
    block20:
      "(a) Indennizzo. Difenderai, indennizzerai e manterrai noi, i nostri affiliati e il nostro personale indenni da e contro qualsiasi reclamo, perdita e spesa (comprese le spese legali) derivanti da o correlate al tuo utilizzo del Servizio, inclusi i tuoi Contenuti, i tuoi utilizzo di prodotti o servizi relativi al Servizio e violazione da parte dell'utente di questi termini o della legge applicabile.",
    block21:
      '(b) Esclusioni di responsabilità. Il Servizio viene fornito "così com\'è". Nella misura consentita dalla legge, noi e i nostri affiliati e licenziatari non rilasciamo dichiarazioni o garanzie di alcun tipo in merito al Servizio e non forniamo alcuna garanzia, incluse ma non limitate a garanzie di commerciabilità, idoneità per uno scopo particolare, qualità soddisfacente, non violazione e il pacifico godimento e qualsiasi garanzia derivante dal corso delle trattative o dall\'uso commerciale. Non garantiamo che il Servizio sarà ininterrotto, accurato o privo di errori, o che qualsiasi Contenuto sarà sicuro, non perso o non alterato.',
    block22:
      '(c) Limitazione di responsabilità. Né noi, né i nostri affiliati, né i nostri licenziatari saremo responsabili per eventuali danni indiretti, incidentali, speciali, consequenziali o punitivi, inclusa la perdita di profitti, perdita di reputazione, perdita di utilizzo, perdita di dati o altre perdite immateriali, anche se siamo stati informati della possibilità di tali danni. Secondo questi termini, la nostra responsabilità totale non supererà le Commissioni pagate per il Servizio nei dodici (12) mesi precedenti il ​​reclamo o cento dollari statunitensi ($ 100), a seconda di quale valore sia maggiore. Le limitazioni in questa sezione si applicano nella misura massima consentita dalla legge applicabile.',
    subTitle8: 'Legge applicabile e giurisdizione',
    block23:
      "I Termini e l'utilizzo dei Servizi sono regolati dalle leggi degli Stati Uniti, ad eccezione delle norme sui conflitti di leggi statunitensi. L'utilizzo dell'Applicazione può essere soggetto anche ad altre leggi locali, statali, nazionali o internazionali. Qualsiasi azione legale o procedimento relativo ai presenti Termini sarà portato esclusivamente nei tribunali degli Stati Uniti e l'utente accetta di sottoporsi alla giurisdizione personale di tali tribunali.",
    subTitle9: 'Condizioni generali',
    block24:
      "(a) Rapporto tra le parti. Noam e tu siete contraenti indipendenti e nessuna delle parti ha il potere di rappresentare o vincolare l'altra parte o assumere obblighi per l'altra parte senza il previo consenso scritto dell'altra parte.",
    block25:
      '(b) Utilizzo del marchio. Non è possibile utilizzare il nome, i loghi o i marchi di Noam o di una delle sue affiliate senza il nostro previo consenso scritto.',
    subTitle10: 'Separabilità',
    block26:
      'Se una qualsiasi disposizione di questi termini viene ritenuta non valida, illegale o inapplicabile, le restanti disposizioni rimarranno in vigore a tutti gli effetti.',
  },
  contact: {
    title: 'Contattaci',
    block1:
      "Per la personalizzazione o se hai domande o suggerimenti, contattaci all'indirizzo contact@noam.tools e ti risponderemo immediatamente.",
    block2:
      "Sostienici valutandoci nello store delle estensioni di Google Apps all'indirizzo ★★★★★.",
    block3: 'Aiuta gli altri condividendo questa estensione:',
  },
  pdf: {
    toast1: 'Il PDF è in caricamento, riprova più tardi!',
    toast2: 'Il contenuto PDF è eccessivo, per il momento non supporta la finestra di dialogo',
    toast3: 'Download in corso, riprova una volta completato il download.',
    shareMsg1: 'Generazione del collegamento di condivisione riuscita',
    shareMsg2: 'Condividi gli aggiornamenti dei contenuti correttamente',
    shareMsg3:
      'Generazione del collegamento di condivisione non riuscita, riprova più tardi o contatta il nostro servizio clienti.',
    shareMsg4:
      'Condivisione aggiornamento contenuto non riuscita, riprova più tardi o contatta il servizio clienti',
    shareBtnText1: 'Genera collegamenti di condivisione',
    shareBtnText2: 'Contenuti di condivisione aggiornati',
    uploading: 'Caricamento in corso',
    uploadingMsg: 'Si prega di pazientare perché il caricamento richiede del tempo.',
    cancelUpload: 'Cancellare',
    uploadErr: 'Caricamento file non riuscito. Riprova più tardi o contatta il servizio clienti',
    uploadMsg1: 'Trascina e rilascia il file qui',
    uploadMsg2: 'Tipi di file supportati: PDF |  Dimensione massima del file: 100 MB',
    uploadMsg3: 'Fare clic per selezionare o trascinare e rilasciare i file da caricare qui',
    searchPlaceholder: 'Ricerca',
    collectionEmpty: 'Nessun contenuto nella raccolta~',
    kw: 'di base',
    video: 'video',
    link: 'collegamento',
    dataLoading: 'Caricamento dati...',
    freeBannerTips: 'Fine della traduzione gratuita dei PDF',
    vipBannerTips: 'Hai raggiunto il limite di traduzione in pdf in questo mese.',
    freeBannerBtn: 'Aggiorna per divertirti di più',
    vipBannerBtn: 'Aggiorna per divertirti di più',
    dialogTitle: 'Attenzione',
    dialogContent: 'Sei sicuro di volerlo eliminare?',
    okBtn: 'Eliminare',
    cancelBtn: 'Cancellare',
    unLoginToast: 'Effettua il login per utilizzare questa funzione',
    parseErrTips: 'Impossibile analizzare il file',
    new: 'nuovo file',
    parseErrDialogContent:
      'Il collegamento PDF corrente non supporta la traduzione diretta, scaricare il PDF del collegamento originale dopo la traduzione',
    okBtn2: 'riconoscere',
    notPdf: 'Solo file PDF',
    uploadingMsg1: 'Caricamento file in corso. Per favore riprova più tardi.',
    uploadingMsg2: 'Al momento hai un file in fase di conversione, riprova più tardi.',
    uploadingMsg3:
      'La richiesta di avanzamento della conversione non è riuscita, riprova più tardi o contatta il servizio clienti',
    uploadingMsg4:
      'Noam non può accedere direttamente ai tuoi file locali, carica manualmente un PDF per la traduzione.',
    uploadingMsg5:
      "Impossibile ottenere l'URL del file, riprova più tardi o contatta il servizio clienti.",
    fileMsg: 'Volume file corrente:',
    fileMsg2: 'Carica un file di dimensioni inferiori a 100 MB',
    fileErrMsg: 'Lettura del file non riuscita, riprova più tardi o contatta il servizio clienti.',
    parseFileToast:
      'Analisi del file non riuscita, riprova più tardi o contatta il nostro servizio clienti.',
    noId: "L'ID del file non esiste",
    autoDetect: 'Rilevamento automatico',
    hasTransTask: "C'è un'attività in fase di traduzione. Attendere prego.",
    transSucc: 'La traduzione ha avuto successo!',
    entry: 'Elenco PDF',
    listError:
      "Impossibile caricare l'elenco PDF, riprova più tardi o contatta il servizio clienti.",
    collectionTab: 'Promemoria',
    pdfLoading: 'Analisi in corso...',
    installTips:
      'Installa il piccolo plug-in, potrai tradurre più file PDF e potrai anche sperimentare una traduzione fluida delle pagine web:',
    webTrans: 'riferimento incrociato completo',
    hoverTrans: 'Traduzione del paragrafo al passaggio del mouse',
    selectTrans: 'sottolinea le parole e traduci',
    installText: 'Uno sportello unico per tutte le tue esigenze quotidiane di traduzione',
    start: 'Inizia la tua esperienza gratuita',
    shareTips: 'La traduzione del PDF è terminata, vai avanti e condividila con i tuoi amici!',
    download: {
      loading: 'Salvato...',
      btn: 'Salva adesso',
      trans: 'Solo traduzione',
      both: 'bilinguismo',
      download: 'Scaricamento',
      msg1: 'Alcune traduzioni del documento corrente sono più lunghe del testo originale e il testo potrebbe sovrapporsi dopo il download, quindi si consiglia di leggerlo sulla pagina web.',
      msg2: '1、Il contenuto non è stato completamente tradotto, scaricalo dopo averlo tradotto completamente.',
      msg3: '2、La parte del documento della traduzione è troppo lunga, è possibile leggerla nella pagina web per evitare sovrapposizioni di testo.',
      msg4: 'Al momento ci sono attività in fase di traduzione, riprova una volta completata la traduzione.',
      fileName: 'Nome del file:',
      fileType: 'Scarica il tipo di file:',
      transContinue: 'Continua a tradurre',
      save: 'Salva in locale',
      downloadMsg1: 'PDF Progressi nella traduzione:',
      downloadMsg2: 'Requisiti previsti',
      minute: 'Minuti...',
      downloadSucc: 'Il download del PDF è completo, fai clic su Salva ora!',
      savePrint: 'Salva e stampa',
      loading2: 'Caricamento...',
      desc: 'Salva in locale: le traduzioni scaricate vengono salvate come immagini; Salva e stampa: le traduzioni scaricate vengono conservate nel loro formato originale e supportano la copia.',
      success: 'Scaricato con successo',
      cancel:
        'La chiusura della finestra popup annullerà il download del file PDF. Sei sicuro di voler annullare?',
      downloadTip:
        'Il PDF è in fase di download. Non chiudere la finestra popup durante il download per evitare errori di download.',
      progress:
        'Il PDF è in fase di traduzione. Avanzamento: {{progress}}, la durata prevista è di {{minutes}} minuti...',
      error: 'Impossibile salvare in locale, prova a stampare il salvataggio',
    },
    guide: {
      title1: 'Traduzione bilingue',
      title2: 'Chatta PDF',
      text1: 'Lettura bilingue',
      text2:
        "Lettura bilingue, così puoi confrontare il testo originale e la traduzione durante la lettura per migliorare l'efficienza della lettura",
      text3: 'Chatta PDF',
      text4:
        "L'intelligenza artificiale analizza e riassume e può rispondere alle tue domande in base al contenuto del PDF",
      text5: 'Precisione ed efficienza',
      text6: "PDF basato sull'intelligenza artificiale per traduzioni più accurate ed efficienti",
      placeholder: 'Si prega di compilare il collegamento PDF',
      start: 'Inizia la traduzione',
      toast: 'Inserisci un collegamento PDF online valido che termina con .pdf',
    },
    original: 'Originale',
    parallel: 'Parallelo',
    trans: 'Traduzione',
    initial: 'Traduci PDF',
    thumbnail: 'cataloghi',
    downloadBtn: 'Scaricamento',
    shareBtn: 'Condividere',
    uploadBtn: 'Caricamento',
    installText2: 'Si prega di installare prima',
    chatPdf: {
      clearSucc: 'Cancellato con successo',
      clear: 'Chiaro',
      clearContent:
        'Dopo la cancellazione, verrà creata una nuova conversazione e il contenuto di questa conversazione non verrà salvato, sei sicuro di volerla cancellare?',
      clearDialogTitle: 'Attenzione',
      cancel: 'Cancellare',
      chatTitle: 'Benvenuto in Noam, il tuo riepilogo in PDF è pronto:',
      summaryTitle: 'Domande che potrebbero interessarti:',
      placeholder: 'Inserisci qualsiasi domanda sul PDF',
      copy: 'Copia',
      copySucc: 'Copia successo',
      historyTitle: 'Storia',
      expand: 'Crollo',
      noDataDesc: 'Nessuna storia~',
      noMore: 'Non più.',
      Search: 'Ricerca',
      delete: 'Eliminare',
      loading: 'caricamento...',
      deleteTitle: 'Mancia',
      deleteContent: "Dopo l'eliminazione, questo PDF non è recuperabile, vuoi eliminarlo?",
      deleteOk: 'Eliminare',
      deleteCancel: 'Cancellare',
      deleteSuccess: 'Elimina con successo',
      listError:
        "Impossibile caricare l'elenco di lettura. Riprova più tardi o contatta l'assistenza clienti",
    },
    shareModal: {
      shareSetting: 'Condividi le impostazioni del contenuto',
      shareText: 'Condividere',
      text1: 'Impostazioni di condivisione',
      text2: 'Genera collegamenti di condivisione',
      range: 'Ambito:',
      custom: 'Personalizzazione:',
      page: 'pagine',
      sourceLang: 'Fonte:',
      target: 'Destinazione:',
      errMsg:
        "Il contenuto dell'attuale intervallo di condivisione non è stato completamente tradotto, quindi traducilo prima di condividerlo.",
      pageNuErr: 'Inserisci il valore corretto del numero di pagina',
      trans: 'Tradurre',
      cancel: 'Cancellare',
      update: 'Collegamenti di condivisione aggiornati',
      gen: 'Condividere',
      shareTips: 'Condividi il collegamento PDF tradotto con i tuoi amici per leggere contro:',
      copySucc: 'Copia collegamento riuscita',
      copy: 'Copia',
      settings: 'Condividi impostazioni:',
      modify: 'modificare',
      translateTips:
        'Suggerimento: se la traduzione richiede molto tempo, puoi chiudere la finestra pop-up e condividerla al termine della traduzione.',
      translating: 'Traduzione PDF...',
      remain: 'rimanente',
      remain1: 'Requisiti totali previsti',
      totalPage: '1 pagine in totale',
      search: 'Ricerca',
      notSupport: 'Questa lingua non è attualmente supportata',
    },
    toolbar: { Adjust: 'Regolare', autoFit: 'Adattamento automatico' },
    memoTotalCount: 'Totale di 1 contenuto',
    dragTipModal: {
      press: 'Premere',
      black: 'spazio',
      leftKey: 'pulsante sinistro',
      tips: 'per trascinare e visualizzare.',
      ok: 'capito',
    },
  },
  locale: {
    zh: 'cinese',
    en: 'Inglese',
    es: 'spagnolo',
    ru: 'russo',
    pt: 'Portoghese (Brasile)',
    id: 'indonesiano',
    cs: 'ceco',
    de: 'tedesco',
    fr: 'francese',
    it: 'Italiano',
    nl: 'Olandese',
    pl: 'Polacco',
    vi: 'vietnamita',
    tr: 'turco',
    uk: 'ucraino',
    zhHant: 'Cinese tradizionale',
    ja: 'giapponese',
    ko: 'coreano',
    ar: 'arabo',
    fa: 'persiano',
    th: 'tailandese',
    hi: 'hindi',
    noSearchLang: 'Questa lingua non è supportata',
    placeholder: 'Ricerca',
    af: 'afrikaans',
    am: 'Amarico',
    as: 'Assamese',
    az: 'Azero',
    ba: 'Baschiro',
    bg: 'bulgaro',
    bn: 'bengalese',
    bo: 'tibetano',
    bs: 'Bosniaco',
    ca: 'catalano',
    cy: 'gallese',
    da: 'danese',
    dsb: 'Sorabo inferiore',
    dv: 'Divehi',
    el: 'greco',
    et: 'Estone',
    eu: 'Basco',
    fi: 'finlandese',
    fil: 'filippino',
    fj: 'Figiano',
    frCA: 'Francese (Canada)',
    ga: 'irlandese',
    gl: 'galiziano',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'ebraico',
    hr: 'croato',
    hsb: 'Alto sorabo',
    ht: 'Creolo haitiano',
    hu: 'ungherese',
    hy: 'armeno',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandese',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latino)',
    ka: 'georgiano',
    kk: 'Kazakistan',
    km: 'Khmer',
    kmr: 'Curdo (settentrionale)',
    kn: 'Kannada',
    ku: 'Curdo (centrale)',
    ky: 'Kirghizistan',
    ln: 'Lingala',
    lo: 'Laotiano',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'lettone',
    lzh: 'Cinese (letterario)',
    mai: 'Maithili',
    mg: 'malgascio',
    mi: 'Maori',
    mk: 'macedone',
    ml: 'Malayalam',
    mnCyrl: 'Mongolo (cirillico)',
    mnMong: 'Mongolo (tradizionale)',
    mr: 'Marathi',
    ms: 'malese',
    mt: 'maltese',
    mww: 'Hmong Daw',
    my: 'Myanmar (birmano)',
    nb: 'norvegese',
    ne: 'nepalese',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    prs: 'Dari',
    ps: 'Pashtu',
    ptPT: 'Portoghese (Portogallo)',
    ro: 'rumeno',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singalese',
    sk: 'slovacco',
    sl: 'sloveno',
    sm: 'Samoano',
    sn: 'Shona',
    so: 'Somalo',
    sq: 'albanese',
    srCyrl: 'Serbo (cirillico)',
    srLatn: 'Serbo (latino)',
    st: 'Sesotho',
    sv: 'svedese',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    ti: 'Tigrino',
    tk: 'turkmeno',
    tlhLatn: 'Klingon (latino)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongano',
    tt: 'Tartaro',
    ty: 'Tahitiano',
    ug: 'Uiguro',
    ur: 'Urdu',
    uz: 'Uzbeco (latino)',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Maya yucatechi',
    yue: 'Cantonese (tradizionale)',
    zhHans: 'Cinese semplificato',
    zu: 'Zulù',
    auto: 'Rilevamento automatico',
  },
  stripe: {
    contact: 'Contattaci a contact@noam.tools per qualsiasi domanda!',
    fail: {
      title: 'Pagamento non riuscito',
      text1: 'Purtroppo non possiamo elaborare la tua richiesta di pagamento.',
      text2: 'Controlla la tua connessione Internet e riprova.',
      btn: 'Riprova',
    },
    succ: {
      title: 'Congratulazioni',
      text1: 'benvenuto a Noam,',
      text2: 'iniziamo insieme!',
      btn: "Facciamo un po' di magia",
    },
  },
  welfare: {
    newUser: {
      label: 'Nuovi vantaggi per gli utenti:',
      joinBtn: 'Iscriviti ora',
      text: 'Solo 100 posti disponibili! Prendi il tuo adesso!',
      remain: 'Ne sono rimasti solo 30',
    },
  },
  help: {
    Fixedplugin: 'Plug-in corretto',
    Howtouse: 'Come usare',
    try: 'Provatelo',
    FreeTrialBenefits: 'Vantaggi della prova gratuita',
    pin: 'Pin Noam, per un accesso più facile～～',
    Video: 'Video',
    ParallelTranslation: 'Traduzione parallela',
    WebSummary: 'Riepilogo Web',
    ChatPDF: 'Chatta PDF',
    AquickOverviewOfNoam: 'Una rapida panoramica di Noam',
    WordSelectionTranslation: 'Traduzione della selezione di parole',
    HoverTranslation: 'Traduzione al passaggio del mouse',
    ImageTranslation: 'Traduzione di immagini',
    InstantTranslation: 'Traduzione istantanea',
    VideoTranslation: 'Traduzione video',
    benefits: 'Abbiamo preparato dei vantaggi per te!',
    Free: 'Gratuito',
    Membership: 'Appartenenza',
    Translation: 'Traduzione',
    PDFparallelTranslation: 'Traduzione parallela PDF',
    ChatPDF2: 'ChatPDF',
    Summary: 'Riepilogo',
    VideoSummary: 'Riepilogo video',
    FavoritesManagement: 'Gestione dei preferiti',
    Focusing: 'Messa a fuoco',
    dayFree: 'Prova gratuita di 3 giorni',
    planIsNotExist: 'il piano non esiste',
    TryItForFree: 'Provalo gratuitamente',
    startYoutube: 'Cominciamo con YouTube~',
    startWiki: 'Cominciamo con Wikimedia~',
    tryNow: 'Provalo adesso~',
    GoWithYouTube: 'Vai con YouTube',
    GoWithWikipedia: 'Vai con Wikipedia',
    NoamHelp: 'Noam può aiutarti a tradurre e riassumere',
    SkipAllSteps: 'Salta tutti i passaggi',
    next: 'Prossimo',
    done: 'Fatto',
  },
  trialModal: {
    title: 'Regalo di benvenuto',
    trialText: 'Prova di 7 giorni',
    desc1: 'Al termine del periodo di prova, ti verrà addebitata una tariffa annuale di $ 180',
    desc2: 'Annulla in qualsiasi momento',
    btn: 'Inizia ora（Solo $ 0,99）',
    installBtn: 'Installalo ora per ricevere il tuo regalo',
    web: 'Traduzione parallela',
    select: 'Traduzione della selezione di parole',
    hover: 'Traduzione al passaggio del mouse',
    image: 'Traduzione di immagini',
    realtime: 'Traduzione istantanea',
    video: 'Traduzione video',
    pdf: 'Traduzione parallela PDF',
    chat: 'ChatPDF',
    webSummary: 'Riepilogo web',
    videoSummary: 'Riepilogo video',
    memo: 'Gestione dei preferiti',
    focus: 'Messa a fuoco',
  },
  privacyCollectionPanel: {
    title: 'Questo sito web utilizza i cookie',
    text: 'Per migliorare la tua esperienza, vorremmo utilizzare i cookie per personalizzare contenuti e servizi.',
    acceptBtn: 'Accetta tutto',
  },
  installTips: {
    text: 'Installa "Noam Browser Plugin" per sbloccare più funzionalità! Riferimenti Web,\n    riepilogo video web e altro ancora!',
    btn: 'Provalo adesso',
  },
}

export default TRANSLATION
