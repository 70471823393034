const TRANSLATION = {
  "buyModal": {
    "afterFree": "試用結束後",
    "btn": "免費試用",
    "freeTrial": "3 天免費試用",
    "monthLabel": "月",
    "selectLabel": "選擇方案",
    "title": "訂閱方案",
    "yearLabel": "年"
  },
  "contact": {
    "block1": "如有客製化需求或任何問題及建議，請發送郵件至contact@noam.tools，我們會立即回覆您。",
    "block2": "歡迎在 Google 應用程式商店評分，支持我們：★★★★★。",
    "block3": "分享此擴充功能，幫助更多人：",
    "title": "聯絡我們"
  },
  "help": {
    "AquickOverviewOfNoam": "Noam 功能總覽",
    "ChatPDF": "ChatPDF",
    "ChatPDF2": "ChatPDF",
    "FavoritesManagement": "我的收藏",
    "Fixedplugin": "固定外掛",
    "Focusing": "專注模式",
    "Free": "免費",
    "FreeTrialBenefits": "免費試用福利",
    "GoWithWikipedia": "使用維基百科體驗",
    "GoWithYouTube": "使用 YouTube 體驗",
    "HoverTranslation": "滑鼠懸停翻譯",
    "Howtouse": "使用方法",
    "ImageTranslation": "圖片翻譯",
    "InstantTranslation": "即時翻譯",
    "Membership": "會員",
    "NoamHelp": "Noam 可以協助您翻譯和摘要",
    "PDFparallelTranslation": "PDF 平行翻譯",
    "ParallelTranslation": "平行翻譯",
    "SkipAllSteps": "跳過所有步驟",
    "Summary": "摘要",
    "Translation": "翻譯",
    "TryItForFree": "免費試用",
    "Video": "影片",
    "VideoSummary": "影片摘要",
    "VideoTranslation": "影片翻譯",
    "WebSummary": "網頁摘要",
    "WordSelectionTranslation": "劃詞翻譯",
    "benefits": "我們為您準備了福利！",
    "dayFree": "3 天免費試用",
    "done": "完成",
    "next": "下一步",
    "pin": "固定 Noam，方便隨時使用～～",
    "planIsNotExist": "方案不存在",
    "startWiki": "從維基百科開始～",
    "startYoutube": "從 YouTube 開始～",
    "try": "立即試用",
    "tryNow": "立即試用～"
  },
  "home": {
    "PDFTranslate": {
      "desc": "雙語翻譯 + Chat PDF",
      "sub1": {
        "desc": "雙語閱讀，讓您在閱讀時可以對照原文和譯文，提高閱讀效率",
        "title": "雙語閱讀"
      },
      "sub2": {
        "desc": "AI 分析和摘要，並可根據 PDF 內容回答您的問題",
        "title": "Chat PDF"
      },
      "sub3": {
        "desc": "AI 驅動 PDF 翻譯，更準確、更高效",
        "title": "準確且高效"
      },
      "title": "PDF 翻譯"
    },
    "aiAssistant": {
      "desc": "讓網頁翻譯更智慧、更便捷",
      "panel1": {
        "desc": "雙語對照翻譯，協助您比對原文和譯文，理解更透徹，提高閱讀效率。",
        "title": "雙語翻譯"
      },
      "panel2": {
        "left": {
          "desc": "瀏覽網頁時，將滑鼠直接懸停在需要翻譯的內容上，即可獲得翻譯結果。",
          "title": "滑鼠懸停翻譯"
        },
        "right": {
          "desc": "瀏覽網頁時，直接選取需要翻譯的文字，即可獲得翻譯結果。",
          "title": "劃詞翻譯"
        }
      },
      "panel3": {
        "one": {
          "desc": "隨時隨地、任何情境，為您翻譯和摘要網頁及影片內容；帶給您更流暢的體驗！",
          "title": "一鍵翻譯"
        },
        "three": {
          "desc": "AI 驅動的大型模型，確保翻譯準確性和一致性，提升閱讀體驗",
          "title": "AI 驅動"
        },
        "two": {
          "desc": "多語種全面覆蓋，雙語無障礙閱讀，一鍵翻譯；帶給您無與倫比的體驗！",
          "title": "更全面、更便捷"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "您的效率專家，結合 ChatGPT 翻譯和摘要 YouTube 影片內容，節省您的閱讀時間！",
          "key": "Video",
          "title": "影片翻譯與摘要"
        },
        "tab2": {
          "desc": "瀏覽網頁時，一鍵翻譯圖片",
          "key": "Image",
          "title": "圖片翻譯"
        },
        "tab3": {
          "desc": "快速提取網頁關鍵資訊進行摘要，提升您的閱讀效率",
          "key": "Web",
          "title": "網頁翻譯與摘要"
        },
        "tag1": "簡便",
        "tag2": "高效",
        "tag3": "易懂"
      },
      "title": "AI 助理"
    },
    "getStart": "開始使用",
    "getStartedForFree": "免費開始使用",
    "howItWork": {
      "desc": "Noam 整合 AI 模型，為使用者提供智慧型跨語言網頁翻譯和摘要、PDF 和圖片翻譯、影片翻譯和摘要等功能",
      "title": "運作方式"
    },
    "lastBlock": {
      "desc": "更優質的 AI 翻譯和更流暢的閱讀體驗"
    },
    "top": {
      "desc": "Noam 是一款免費的 AI 瀏覽器擴充功能，可以協助您翻譯及摘要網頁、影片和 PDF 內容。\\n      立即加入 Noam，體驗神奇功能！",
      "title1": "神奇功能",
      "title2": "一鍵啟動"
    },
    "userReviews": {
      "desc": "Chrome 線上應用程式商店五星好評",
      "title": "使用者評價"
    }
  },
  "installTips": {
    "btn": "立即體驗",
    "text": "安裝「Noam 瀏覽器插件」解鎖更多功能！網頁交叉引用、\n    網頁影片摘要等！"
  },
  "locale": {
    "af": "南非荷蘭文",
    "am": "阿姆哈拉文",
    "ar": "阿拉伯文",
    "as": "阿薩姆文",
    "auto": "自動偵測",
    "az": "亞塞拜然文",
    "ba": "巴什基爾文",
    "bg": "保加利亞文",
    "bn": "孟加拉文",
    "bo": "藏文",
    "bs": "波士尼亞文",
    "ca": "加泰隆尼亞文",
    "cs": "捷克文",
    "cy": "威爾斯文",
    "da": "丹麥文",
    "de": "德文",
    "dsb": "低地索布語",
    "dv": "迪維希文",
    "el": "希臘文",
    "en": "英文",
    "es": "西班牙文",
    "et": "愛沙尼亞文",
    "eu": "巴斯克文",
    "fa": "波斯文",
    "fi": "芬蘭文",
    "fil": "菲律賓文",
    "fj": "斐濟文",
    "fr": "法文",
    "frCA": "法文（加拿大）",
    "ga": "愛爾蘭文",
    "gl": "加利西亞文",
    "gom": "孔卡尼文",
    "gu": "古吉拉特文",
    "ha": "豪薩文",
    "he": "希伯來文",
    "hi": "印地文",
    "hr": "克羅埃西亞文",
    "hsb": "上索布語",
    "ht": "海地克里奧爾文",
    "hu": "匈牙利文",
    "hy": "亞美尼亞文",
    "id": "印尼文",
    "ig": "伊博文",
    "ikt": "伊努克提圖特文",
    "is": "冰島文",
    "it": "義大利文",
    "iu": "伊努伊特文",
    "iuLatn": "伊努伊特文（拉丁）",
    "ja": "日文",
    "ka": "喬治亞文",
    "kk": "哈薩克文",
    "km": "高棉文",
    "kmr": "庫德文（北方）",
    "kn": "坎那達文",
    "ko": "韓文",
    "ku": "庫德文（中部）",
    "ky": "吉爾吉斯文",
    "ln": "林加拉文",
    "lo": "寮文",
    "lt": "立陶宛文",
    "lug": "甘達文",
    "lv": "拉脫維亞文",
    "lzh": "中文（文言文）",
    "mai": "邁蒂利文",
    "mg": "馬達加斯加文",
    "mi": "毛利文",
    "mk": "馬其頓文",
    "ml": "馬來亞拉姆文",
    "mnCyrl": "蒙古文（西里爾字母）",
    "mnMong": "蒙古文（傳統）",
    "mr": "馬拉地文",
    "ms": "馬來文",
    "mt": "馬爾他文",
    "mww": "苗文",
    "my": "緬甸文",
    "nb": "挪威文",
    "ne": "尼泊爾文",
    "nl": "荷蘭文",
    "noSearchLang": "不支援此語言",
    "nso": "北索托語",
    "nya": "尼揚扎文",
    "or": "奧里亞文",
    "otq": "克雷塔羅奧托米語",
    "pa": "旁遮普文",
    "pl": "波蘭文",
    "placeholder": "搜尋",
    "prs": "達利文",
    "ps": "普什圖文",
    "pt": "葡萄牙文（巴西）",
    "ptPT": "葡萄牙文（葡萄牙）",
    "ro": "羅馬尼亞文",
    "ru": "俄文",
    "run": "倫迪文",
    "rw": "盧安達文",
    "sd": "信德文",
    "si": "僧伽羅文",
    "sk": "斯洛伐克文",
    "sl": "斯洛維尼亞文",
    "sm": "薩摩亞文",
    "sn": "修納文",
    "so": "索馬里文",
    "sq": "阿爾巴尼亞文",
    "srCyrl": "塞爾維亞文（西里爾字母）",
    "srLatn": "塞爾維亞文（拉丁字母）",
    "st": "塞索托文",
    "sv": "瑞典文",
    "sw": "史瓦希里文",
    "ta": "坦米爾文",
    "te": "泰盧固文",
    "th": "泰文",
    "ti": "提格里尼亞文",
    "tk": "土庫曼文",
    "tlhLatn": "克林貢文（拉丁字母）",
    "tlhPiqd": "克林貢文（pIqaD）",
    "tn": "茨瓦納文",
    "to": "東加文",
    "tr": "土耳其文",
    "tt": "韃靼文",
    "ty": "大溪地文",
    "ug": "維吾爾文",
    "uk": "烏克蘭文",
    "ur": "烏爾都文",
    "uz": "烏茲別克文（拉丁字母）",
    "vi": "越南文",
    "xh": "科薩文",
    "yo": "約魯巴文",
    "yua": "猶加敦馬雅語",
    "yue": "粵語",
    "zh": "中文",
    "zhHans": "簡體中文",
    "zhHant": "繁體中文",
    "zu": "祖魯文"
  },
  "login": {
    "and": " 和",
    "btn": "使用 Google 帳號登入",
    "btn1": "立即登入",
    "desc": "登入以解鎖更多體驗",
    "policy": "隱私權政策",
    "terms": "使用條款",
    "tips": "註冊即表示我同意",
    "title": "歡迎使用 Noam"
  },
  "pageFooter": {
    "follow": {
      "facebook": "臉書",
      "title": "追蹤",
      "twitter": "推特"
    },
    "legal": {
      "policy": "隱私權政策",
      "terms": "服務條款",
      "title": "法律"
    },
    "product": {
      "image": "圖片翻譯",
      "pdf": "PDF 翻譯",
      "title": "產品",
      "video": "影片翻譯與摘要",
      "web": "網頁翻譯與摘要"
    },
    "resources": {
      "title": "資源"
    }
  },
  "pageHeader": {
    "contact": "聯絡我們",
    "help": "說明中心",
    "logout": "登出",
    "logoutSucc": "已成功登出",
    "pricing": "價格",
    "settings": "設定",
    "signUp": "登入"
  },
  "pdf": {
    "autoDetect": "自動偵測",
    "cancelBtn": "取消",
    "cancelUpload": "取消",
    "chatPdf": {
      "Search": "搜尋",
      "cancel": "取消",
      "chatTitle": "歡迎使用 Noam，您的 PDF 摘要已準備就緒：",
      "clear": "清除",
      "clearContent": "清除後將建立新的對話，且本次對話內容將不會被儲存，確定要清除嗎？",
      "clearDialogTitle": "提醒",
      "clearSucc": "清除成功",
      "copy": "複製",
      "copySucc": "複製成功",
      "delete": "刪除",
      "deleteCancel": "取消",
      "deleteContent": "刪除後，此 PDF 將無法恢復，是否確認刪除？",
      "deleteOk": "刪除",
      "deleteSuccess": "刪除成功",
      "deleteTitle": "提示",
      "expand": "收起",
      "historyTitle": "歷史記錄",
      "listError": "閱讀清單載入失敗。請稍後再試或聯繫客服",
      "loading": "載入中...",
      "noDataDesc": "沒有歷史記錄～ ",
      "noMore": "沒有更多了。",
      "placeholder": "輸入任何關於 PDF 的問題",
      "summaryTitle": "您可能感興趣的問題："
    },
    "collectionEmpty": "收藏夾中沒有內容～",
    "collectionTab": "備忘錄",
    "dataLoading": "載入資料...",
    "dialogContent": "您確定要刪除嗎？",
    "dialogTitle": "提醒",
    "download": {
      "both": "雙語",
      "btn": "立即儲存",
      "cancel": "關閉彈出視窗將取消正在下載的 PDF 檔案。您確定要取消嗎？",
      "desc": "儲存到本地：下載的翻譯結果將以圖片形式儲存；儲存並列印：下載的翻譯結果將保留原始格式，並支援複製。",
      "download": "下載",
      "downloadMsg1": "PDF 翻譯進度：",
      "downloadMsg2": "預計需要",
      "downloadSucc": "PDF 已可下載，點擊立即儲存！",
      "downloadTip": "PDF 正在下載中。下載過程中請勿關閉彈出視窗，以免下載失敗。",
      "error": "儲存到本地失敗，請嘗試列印儲存",
      "fileName": "檔案名稱：",
      "fileType": "下載檔案類型：",
      "loading": "儲存中...",
      "loading2": "載入中...",
      "minute": "分鐘...",
      "msg1": "目前文件中部分翻譯的內容比原文較長，下載後文字可能會重疊，建議在網頁上閱讀。",
      "msg2": "1、內容尚未翻譯完成，請翻譯完成後再下載。",
      "msg3": "2、文件部分翻譯過長，您可以在網頁上閱讀，避免文字重疊。",
      "msg4": "目前有正在翻譯的任務，請翻譯完成後再試。",
      "progress": "PDF 正在翻譯中。進度：{progress}，預計還需 {minutes} 分鐘...",
      "save": "儲存到本地",
      "savePrint": "儲存並列印",
      "success": "下載成功",
      "trans": "僅限翻譯",
      "transContinue": "繼續翻譯"
    },
    "downloadBtn": "下載",
    "dragTipModal": {
      "black": "空白",
      "leftKey": "滑鼠左鍵",
      "ok": "我知道了",
      "press": "按住",
      "tips": "拖動檢視。"
    },
    "entry": "PDF 清單",
    "fileErrMsg": "檔案讀取失敗，請稍後再試或聯繫客服。",
    "fileMsg": "目前檔案大小：",
    "fileMsg2": "請上傳小於 100M 的檔案",
    "freeBannerBtn": "升級以享受更多服務",
    "freeBannerTips": "免費 PDF 翻譯額度已用完",
    "guide": {
      "placeholder": "請填寫 PDF 連結",
      "start": "開始翻譯",
      "text1": "雙語閱讀",
      "text2": "雙語閱讀，讓您在閱讀時可以對照原文和譯文，提高閱讀效率",
      "text3": "Chat PDF",
      "text4": "AI 分析和摘要，並可根據 PDF 內容回答您的問題",
      "text5": "準確且高效",
      "text6": "AI 驅動 PDF 翻譯，更準確、更高效",
      "title1": "雙語翻譯",
      "title2": "Chat PDF",
      "toast": "請輸入以 .pdf 結尾的有效線上 PDF 連結"
    },
    "hasTransTask": "目前有正在翻譯的任務。請稍候。",
    "hoverTrans": "滑鼠懸停段落翻譯",
    "initial": "翻譯 PDF",
    "installText": "滿足您日常所有翻譯需求的一站式服務",
    "installText2": "請先安裝",
    "installTips": "安裝小外掛，即可翻譯更多 PDF 檔案，還可體驗流暢的網頁翻譯：",
    "kw": "釘選",
    "link": "連結",
    "listError": "PDF 清單載入失敗，請稍後再試或聯繫客服。",
    "memoTotalCount": "共 1 個內容",
    "new": "新檔案",
    "noId": "檔案 ID 不存在",
    "notPdf": "僅限 PDF 檔案",
    "okBtn": "刪除",
    "okBtn2": "識別",
    "original": "原文",
    "parallel": "平行",
    "parseErrDialogContent": "目前 PDF 連結不支援直接翻譯，請下載原文連結 PDF 後再進行翻譯",
    "parseErrTips": "無法解析檔案",
    "parseFileToast": "檔案解析失敗，請稍後再試或聯繫客服。",
    "pdfLoading": "解析中...",
    "searchPlaceholder": "搜尋",
    "selectTrans": "劃詞翻譯",
    "shareBtn": "分享",
    "shareBtnText1": "產生分享連結",
    "shareBtnText2": "更新分享內容",
    "shareModal": {
      "cancel": "取消",
      "copy": "複製",
      "copySucc": "複製連結成功",
      "custom": "自訂：",
      "errMsg": "當前分享範圍的內容尚未翻譯完成，請先翻譯再分享。",
      "gen": "分享",
      "modify": "修改",
      "notSupport": "目前不支援此語言",
      "page": "頁",
      "pageNuErr": "請輸入正確的頁碼值",
      "range": "範圍：",
      "remain": "剩餘",
      "remain1": "總計預計需要",
      "search": "搜尋",
      "settings": "分享設定：",
      "shareSetting": "分享內容設定",
      "shareText": "分享",
      "shareTips": "將翻譯後的 PDF 連結分享給您的朋友，一起閱讀：",
      "sourceLang": "原文：",
      "target": "目標語言：",
      "text1": "分享設定",
      "text2": "產生分享連結",
      "totalPage": "共 1 頁",
      "trans": "翻譯",
      "translateTips": "提示：如果翻譯時間較長，您可以關閉彈出視窗，翻譯完成後再進行分享。",
      "translating": "PDF 翻譯中...",
      "update": "更新分享連結"
    },
    "shareMsg1": "分享連結產生成功",
    "shareMsg2": "分享內容更新成功",
    "shareMsg3": "分享連結產生失敗，請稍後再試或聯繫客服。",
    "shareMsg4": "分享內容更新失敗，請稍後再試或聯繫客服",
    "shareTips": "PDF 翻譯完成，快分享給您的朋友吧！",
    "start": "開始免費體驗",
    "thumbnail": "目錄",
    "toast1": "PDF 載入中，請稍後再試！",
    "toast2": "PDF 內容過多，暫不支援對話",
    "toast3": "下載中，請下載完成後再試。",
    "toolbar": {
      "Adjust": "調整",
      "autoFit": "自動調整"
    },
    "trans": "翻譯",
    "transSucc": "翻譯成功！",
    "unLoginToast": "請登入以使用此功能",
    "uploadBtn": "上傳",
    "uploadErr": "檔案上傳失敗。請稍後再試或聯繫客服",
    "uploadMsg1": "請將檔案拖放到此處",
    "uploadMsg2": "支援的檔案類型：PDF  |  最大檔案大小：100MB",
    "uploadMsg3": "點擊選擇或拖放檔案至此處上傳",
    "uploading": "上傳中",
    "uploadingMsg": "上傳需要一些時間，請耐心等待。",
    "uploadingMsg1": "檔案上傳中。請稍後再試。",
    "uploadingMsg2": "您目前有一個檔案正在轉換中，請稍後再試。",
    "uploadingMsg3": "轉換進度查詢失敗，請稍後再試或聯繫客服",
    "uploadingMsg4": "Noam 無法直接存取您的本地檔案，請手動上傳 PDF 進行翻譯。",
    "uploadingMsg5": "無法取得檔案網址，請稍後再試或聯繫客服。",
    "video": "影片",
    "vipBannerBtn": "升級以享受更多服務",
    "vipBannerTips": "您本月的 PDF 翻譯額度已達上限。",
    "webTrans": "全文對照"
  },
  "pricing": {
    "FAQ": "常見問題",
    "FQAObj": {
      "a1": "Noam 有免費版本可直接使用。",
      "a2": "除了免費版本外，還有三種會員方案：Pro、Pro+ 和企業版；您可以選擇兩種付費方式：連續包月和連續包年，連續包年的綜合費用更優惠。",
      "a3": "功能上沒有區別。連續包年的綜合費用比連續包月低；包月每月扣款一次，包年每年扣款一次。",
      "a4": "您可以前往後台設定頁面，選擇訂閱取消續訂方案。",
      "q1": "我可以免費使用嗎？",
      "q2": "是否有不同的會員方案和付費方式？",
      "q3": "連續包月和連續包年有什麼區別？",
      "q4": "如何取消自動續訂方案？"
    },
    "around": "約",
    "billedMonthly": "按月計費",
    "billedYearly": "按年計費",
    "day": "天",
    "desc": "比較並選擇最適合您的方案",
    "detailedCompare": "詳細比較",
    "discount": "八折",
    "enterprise": {
      "benefit1": "客戶服務快速支援",
      "benefit2": "多用戶",
      "benefit3": "客製化解決方案",
      "benefit5": "專屬客戶經理",
      "btn": "聯繫",
      "recommendText": "客製化方案（≥3人）",
      "talkToUs": "聯絡我們"
    },
    "free": {
      "benefit1": "即時翻譯 ${FREE_BENEFITS.realTimeCount} 次/天",
      "benefit2": "網頁翻譯與摘要",
      "benefit3": "${FREE_BENEFITS.imageCount} 張圖片翻譯",
      "benefit4": "影片翻譯與摘要",
      "benefit5": "${FREE_BENEFITS.pdfUploadCount} 個 PDF 翻譯",
      "benefit6": "${FREE_BENEFITS.tokenCount} 個 token",
      "btn": "開始體驗",
      "recommendText": "免費試用"
    },
    "imageTranslation": "圖片翻譯",
    "month": "個月",
    "monthly": "月度",
    "pageTitle": "價格方案",
    "pdf": {
      "chat": "PDF 詢問次數",
      "maxPage": "PDF最大頁數",
      "translation": "PDF 翻譯"
    },
    "preMonth": "每月",
    "pro": {
      "benefit1": "客戶服務快速支援",
      "benefit2": "${PRO_BENEFITS.tokenCount} 個 tokens/月",
      "benefit3": "網頁翻譯與摘要",
      "benefit4": "${PRO_BENEFITS.imageCount} 張圖片翻譯",
      "benefit5": "影片翻譯與摘要",
      "benefit6": "${PRO_BENEFITS.pdfUploadCount} 個 PDF 翻譯",
      "btn": "開始使用",
      "recommendText": "大多數人的選擇"
    },
    "proPlus": {
      "benefit1": "客戶服務快速支援",
      "benefit2": "${PRO_V_BENEFITS.tokenCount} 個 tokens/月",
      "benefit3": "網頁翻譯與摘要",
      "benefit4": "${PRO_V_BENEFITS.imageCount} 張圖片翻譯",
      "benefit5": "影片翻譯與摘要",
      "benefit6": "${PRO_V_BENEFITS.pdfUploadCount} 個 PDF 翻譯",
      "recommendText": "最超值的選擇"
    },
    "video": {
      "summary": "影片摘要",
      "translation": "影片翻譯"
    },
    "web": {
      "contrastiveTranslation": "雙語翻譯",
      "dictionaryTranslation": "選取文字翻譯",
      "hoverTranslation": "懸停翻譯",
      "instantTranslation": "即時翻譯",
      "summary": "網頁摘要"
    },
    "yearly": "年度"
  },
  "privacy": {
    "block1": "我們，Noam（以下簡稱「Noam」、「我們」或「本公司」）尊重您的隱私權，並承諾保護我們從您或關於您獲得的任何資訊。本隱私權政策說明了我們在您使用我們的網站、應用程式和服務（統稱為「服務」）時，關於收集或使用個人資訊的做法。本隱私權政策不適用於我們代表商業產品客戶處理的內容，例如我們的 API。這些資料的使用受制於我們的客戶協議，其中涵蓋了對這些產品的存取和使用。",
    "block10": "我們實施商業上合理的技術、管理和組織措施，以保護線上和離線個人資訊免遭遺失、誤用和未經授權的存取、披露、竄改或惡意行為。但是，任何網際網路或電子郵件傳輸都無法保證完全的安全或準確性。特別是，發送給我們或從我們發送的電子郵件可能不安全。因此，在決定透過服務或電子郵件向我們發送哪些資訊時，您應該格外小心。此外，我們不對服務或第三方網站的安全設定或安全措施中的任何隱私權設定或安全措施的阻礙負責。只要我們需要向您提供服務或出於其他合法商業目的（例如解決爭議、安全和安全原因或遵守我們的法律義務），我們就會保留您的個人資訊。我們保留個人資訊的時間長度將取決於許多因素，例如資訊的數量、性質、敏感性、未經授權使用或披露的潛在風險、我們處理資訊的目的以及其他具有法律約束力的考量因素。",
    "block11": "我們可能會不時更新本隱私權政策。當我們這樣做時，我們將在本頁面上發佈更新後的版本，除非適用法律另有規定。",
    "block12": "如果您對本隱私權政策有任何未獲解答的問題或疑慮，請聯繫我們的客戶服務。電子郵件：",
    "block2": "我們會收集與您相關的以下個人資訊（「個人資訊」）：",
    "block3": "您提供的資訊：如果您建立帳戶以使用我們的服務或與我們聯繫，我們將收集以下個人資訊：",
    "block4": "從您使用服務中自動接收的個人資訊：當您存取、使用或與服務互動時，我們會收到有關您存取、使用或互動的以下資訊（「技術資訊」）：",
    "block5": "我們可能會將個人資訊用於以下目的：",
    "block6": "彙總或去識別化資訊：我們可能會彙總或去識別化個人資訊，以防止其重複用於識別目的，並使用這些資訊來分析我們服務的有效性、改進和新增服務功能、進行研究以及類似目的。此外，我們可能會分析服務使用者的總體行為和特徵，並與第三方分享彙總資訊、發佈這些彙總資訊或將其公開。我們可能會透過服務、透過 Cookie 以及透過本隱私權政策中描述的其他方式收集彙總資訊。我們將以匿名或去識別化的形式維護和使用去識別化資訊，並且除非法律要求，否則我們不會嘗試重新識別這些資訊。",
    "block7": "在某些情況下，我們可能會在未經您進一步通知的情況下向第三方提供您的個人資訊，除非法律要求：",
    "block8": "根據您所在的地理位置，歐洲經濟區、英國和全球的個人可能擁有與其個人資訊相關的某些法定權利。例如，您可能有權：",
    "block9": "該服務可能包含指向 Noam 未運營或控制的其他網站的連結，包括社群媒體服務（「第三方網站」）。您與第三方網站分享的資訊將受第三方網站的特定隱私權政策和服務條款的約束，而不受本隱私權政策的約束。我們提供這些連結是為了方便起見，並不意味著我們對這些網站的認可或審查。請直接聯繫第三方網站以獲取有關其隱私權政策和條款的資訊。",
    "subBlock1": "帳戶資訊：當您在我們的平台上建立帳戶時，我們會收集與您的帳戶相關的資訊，包括您的姓名、聯繫資訊、帳戶憑證、付款卡資訊和交易記錄（稱為「帳戶資訊」）。",
    "subBlock10": "提供、管理、維護和/或分析服務；",
    "subBlock11": "與您聯繫；",
    "subBlock12": "開發新專案和服務；",
    "subBlock13": "防止詐欺、犯罪活動或濫用我們的服務，並保護我們的 IT 系統、架構和網路的安全；",
    "subBlock14": "進行業務轉讓；",
    "subBlock15": "遵守法律義務和法律程序，保護我們、我們的關聯公司、您或其他第三方的權利、隱私權、安全或財產。",
    "subBlock16": "供應商和服務提供者：為了協助我們滿足業務營運需求並執行某些服務和功能，我們可能會向供應商和服務提供者提供個人資訊，包括託管服務提供者、雲端服務提供者和其他資訊技術服務提供者、電子郵件通訊軟體和網路分析服務提供者等。這些合作夥伴僅在為我們履行其職責時，才會按照我們的指示存取、處理或儲存個人資訊。",
    "subBlock17": "業務轉讓：如果我們參與策略性交易、重組、破產、破產接管或服務轉移給另一個提供者（統稱為「交易」），您的個人資訊和其他資訊可能會在盡職調查期間與交易對方和協助交易的其他各方分享，並作為交易的一部分與其他資產一起轉移給繼任者或關聯公司。",
    "subBlock18": "法律要求：我們可能會與政府當局、同業或其他第三方分享您的個人資訊，包括您與我們服務的互動資訊，(1) 如果法律要求或我們認為此類行為對於遵守法律義務是必要的；(2) 保護和捍衛我們的權利或財產；(3) 如果我們單方面確定違反了我們的條款、政策或法律；(4) 偵測或防止詐欺或其他非法活動；(5) 保護我們的產品、員工或使用者，或公共安全、安全、完整性；或 (vi) 進行法律責任抗辯。",
    "subBlock19": "存取您的個人資訊以及有關如何處理這些資訊的資訊。",
    "subBlock2": "使用者內容：當您使用我們的服務時，我們會從您的輸入、檔案上傳或提供給我們的意見回饋中收集個人資訊（稱為「內容」）。",
    "subBlock20": "從我們的記錄中刪除您的個人資訊。",
    "subBlock21": "更正或更新您的個人資訊。",
    "subBlock22": "將您的個人資訊轉移給第三方（資料可攜性）。",
    "subBlock23": "限制我們處理您個人資訊的方式。",
    "subBlock24": "撤回您的同意—如果我們依賴同意作為處理的法律依據，您可以隨時撤回您的同意。",
    "subBlock25": "反對我們處理您個人資訊的方式。",
    "subBlock26": "改進我們的服務並進行研究；",
    "subBlock3": "通訊資訊：如果您向我們發送訊息，我們將收集您的姓名、聯繫資訊以及您發送的訊息內容（稱為「通訊資訊」）。",
    "subBlock4": "社群媒體資訊：我們在 Instagram、Facebook、Medium、Twitter、YouTube 和 LinkedIn 等社群媒體網站上設有頁面。當您與我們的社群媒體頁面互動時，我們會收集您選擇提供給我們的個人資訊，例如您的聯繫方式（稱為「社群資訊」）。此外，託管我們社群媒體頁面的公司可能會向我們提供有關我們社群媒體活動的彙總資訊和分析。",
    "subBlock5": "日誌資料：您的瀏覽器在使用我們的服務時自動發送的資訊。日誌資料包括您的網際網路協定 (IP) 位址、瀏覽器類型和設定、您請求的日期和時間，以及您與我們網站的互動方式。",
    "subBlock6": "使用資料：我們可能會自動收集有關您使用服務的資訊，例如您檢視或互動的內容類型、您使用的功能以及您採取的操作，以及您的時區、國家/地區、存取日期和時間、使用者代理和版本、電腦或行動裝置類型，以及您的電腦連線。",
    "subBlock7": "裝置資訊：包括裝置名稱、作業系統、裝置識別碼以及您正在使用的瀏覽器。收集的資訊可能會因您使用的裝置類型及其設定而異。",
    "subBlock8": "Cookie：我們使用 Cookie 來運營和管理我們的服務並改善您的體驗。「Cookie」是您造訪的網站發送到您瀏覽器的資訊。您可以將您的瀏覽器設定為接受所有 Cookie、拒絕所有 Cookie 或在提供 Cookie 時通知您，以便您每次都可以決定是否接受。但是，拒絕 Cookie可能會阻止您使用或負面影響網站某些區域或功能的顯示或功能。有關 Cookie 的更多資訊，請造訪 All About Cookies。",
    "subBlock9": "分析：我們可能會使用各種使用 Cookie 的線上分析產品，以協助我們分析使用者如何使用我們的服務並改善您在使用服務時的體驗。",
    "subTitle1": "我們收集的個人資訊",
    "subTitle2": "我們如何使用個人資訊",
    "subTitle3": "個人資訊的披露",
    "subTitle4": "您的權利",
    "subTitle5": "其他網站的連結",
    "subTitle6": "資訊安全和保留",
    "subTitle7": "隱私權政策的修改",
    "subTitle8": "如何聯繫我們",
    "title": "隱私權政策",
    "updateTime": "更新日期：2024 年 3 月 15 日"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "全部接受",
    "text": "為了提升您的瀏覽體驗，我們想使用 Cookie 來提供個人化內容和服務。",
    "title": "本網站使用 Cookie"
  },
  "slogen": "最簡單的 AI 小幫手",
  "stripe": {
    "contact": "如有任何問題，請透過 contact@noam.tools 與我們聯繫！",
    "fail": {
      "btn": "重試",
      "text1": "很抱歉，我們無法處理您的付款請求。",
      "text2": "請檢查您的網路連線並重試。",
      "title": "付款失敗"
    },
    "succ": {
      "btn": "開始體驗",
      "text1": "歡迎來到Noam，",
      "text2": "讓我們一起開始吧！",
      "title": "恭喜"
    }
  },
  "terms": {
    "block1": "感謝您使用 Noam！",
    "block10": "（a）費用和帳單。您將根據適用的價格頁面上的價格和條款或我們之間書面約定的方式支付所有費用（「費用」）。我們保留更正定價錯誤或錯誤的權利，即使我們已開具發票或收到付款。您將提供完整且準確的帳單資訊，包括有效的授權付款方式。我們將按照約定的方式定期向您的付款方式收費，但我們可能會合理地更改帳單日期。您授權 Noam、其關係企業和第三方支付處理商向您的付款方式收費。如果您的付款失敗，我們將向您發出書面通知，並可能暫停您對本服務的存取權，直到收到付款。除非本協議另有規定，否則付款不可退款。",
    "block11": "（b）稅金。除非另有說明，否則費用不包括任何聯邦、州、地方和外國稅金、關稅和類似評估（「稅金」）。您有責任支付與您的購買相關的所有稅金，但我們將向您開具基於我們淨收入的稅金除外。您同意及時繳納此類稅金，並向我們提供合法有效的隱私聲明，並獲得處理此類數據的必要同意，並且您向我們保證，您會根據適用法律處理此類數據。",
    "block12": "（c）價格變動。我們可能會變更價格，並透過您的帳戶和/或我們的網站通知您。價格上漲將在發布後立即生效。任何價格變動將適用於變動生效日期後您帳戶的費用。",
    "block13": "（d）爭議和逾期付款。如果您想對任何費用或稅金提出異議，請在發票日期後三十 (30) 天內聯絡 contact@noam.tools。",
    "block14": "（e）免費使用。您不得創建多個帳戶以獲取免費額度。如果我們認定您並非善意使用免費額度，我們可能會向您收取標準費用或停止提供您對本服務的存取權。",
    "block15": "（a）機密性。您可能可以存取 Noam 的機密資訊。您只能根據這些條款允許的方式使用機密資訊，以便使用本服務。您不得將機密資訊洩露給任何第三方，並且您將以不亞於您保護自身類似機密資訊的方式保護機密資訊，至少要採取合理的注意措施。機密資訊是指由 Noam 指定為機密或根據情況應合理視為機密的非公開資訊，包括軟體、規格和其他非公開商業資訊。機密資訊不包括以下資訊：(1) 非因您的過錯而公開的資訊；(2) 您在本條款下收到之前已擁有且無需承擔保密義務的資訊；(3) 由第三方合法披露給您的資訊，而無需承擔任何保密義務；或 (4) 由您獨立開發且未使用機密資訊的資訊。如果法律、法院或其他政府命令要求，您可以披露機密資訊，但您必須以合理的方式事先書面通知 Noam，並盡可能採取合理措施限制披露範圍，包括協助我們反對披露請求。",
    "block16": "（b）安全性。您必須採取合理且適當的措施，以幫助保護您對本服務的存取和使用。如果您發現與您使用本服務相關的任何漏洞或違規行為，您必須立即聯絡 Noam，並提供有關漏洞或違規行為的詳細資訊。",
    "block17": "（c）個人數據的處理。如果您使用本服務處理個人數據，則您必須提供合法且充分的隱私聲明，並獲得處理此類數據的必要同意，並且您向我們保證，您會根據適用法律處理此類數據。",
    "block18": "（a）終止；暫停。這些條款將從您首次使用本服務時生效，並將持續有效，直至終止。您可以隨時以任何理由終止這些條款，方法是停止使用本服務和內容。我們可能會以任何理由在事先通知的情況下終止這些條款。如果您嚴重違反第 2 條（使用要求）、第 5 條（機密性、安全性與數據保護）、第 8 條（爭議解決）或第 9 條（一般條款），或者如果我們與超出我們控制範圍的任何第三方技術供應商的關係發生變化，或者為了遵守法律或政府要求，我們可能會透過通知您立即終止這些條款。如果您未能遵守這些條款，或者您的使用對我們或任何第三方構成安全風險，或者我們懷疑您的使用存在欺詐行為或可能使我們或任何第三方承擔責任，我們可能會暫停您對本服務的存取權。",
    "block19": "（b）終止的效力。終止後，您將停止使用本服務，並按照我們的指示立即退回或銷毀任何機密資訊。這些條款中就其性質而言應在終止或到期後繼續有效的條款，包括但不限於第 3 條和第 5-9 條，應繼續有效。",
    "block2": "這些使用條款適用於您對 Noam 服務的使用，包括我們的應用程式介面、軟體、工具、開發人員服務、數據、說明文件和網站（統稱為「服務」）。使用我們的服務即表示您同意遵守這些條款。我們的隱私權政策說明了我們如何收集和使用個人資訊。",
    "block20": "（a）賠償。對於因以下原因或與以下原因相關的任何索賠、損失和費用（包括律師費），您將為我們、我們的關係企業和我們的人員提供辯護、賠償並使其免受損害：您對本服務的使用，包括您的內容、您對與本服務相關的產品或服務的使用，以及您違反這些條款或適用法律的行為。",
    "block21": "（b）免責聲明。本服務係依「現狀」提供。在法律允許的範圍內，我們和我們的關係企業以及授權人對於本服務不做任何形式的陳述或保證，並且不提供任何保證，包括但不限於適銷性、適用於特定用途、品質滿意度、不侵權和安靜享有的保證，以及因交易習慣或貿易慣例而產生的任何保證。我們不保證本服務將不間斷、準確或無錯誤，也不保證任何內容將是安全的、不會丟失或不會被更改。",
    "block22": "（c）責任限制。對於任何間接、附帶、特殊、後果性或懲罰性損害，包括利潤損失、商譽損失、使用損失、數據損失或其他無形損失，我們、我們的關係企業或我們的授權人均不承擔任何責任，即使我們已被告知此類損害的可能性。根據這些條款，我們的總責任不超過您在索賠前十二 (12) 個月內支付給本服務的費用或一百美元 ($100)，以較高者為準。本節中的限制適用於適用法律允許的最大範圍。",
    "block23": "這些條款以及您對本服務的使用受美國法律管轄，但美國法律衝突規則除外。您對本應用程式的使用也可能受其他當地、州、國家或國際法律的約束。與這些條款相關的任何法律訴訟或程序將僅在美國法院提起，並且您同意服從此類法院的屬人管轄權。",
    "block24": "（a）雙方關係。Noam 和您是獨立的承包商，任何一方均無權在未經對方事先書面同意的情況下代表或約束對方或為對方承擔義務。",
    "block25": "（b）品牌使用。未經我們事先書面同意，您不得使用 Noam 或其任何關係企業的名稱、標誌或商標。",
    "block26": "如果這些條款中的任何條款被確定為無效、非法或不可執行，則其餘條款將繼續完全有效。",
    "block3": "如果您未滿 18 歲，則必須先徵得您的父母或法定監護人的同意才能使用本服務。如果您代表其他人或實體使用本服務，則您必須獲得授權才能代表他們接受這些條款。您必須提供準確且完整的資訊才能註冊帳戶。您不得向組織以外的任何人提供您的存取憑證或帳戶，並且您必須對使用您的憑證進行的所有活動負責。",
    "block4": "（a）服務的使用。您可以根據這些條款存取本服務，而我們授予您非專屬權利以使用本服務。使用本服務時，您將遵守這些條款以及所有適用的法律。我們和我們的關係企業擁有本服務的所有權利、所有權和利益。",
    "block5": "（b）意見回饋。我們歡迎您提供意見回饋、評論、想法、建議和改進。如果您提供任何此類內容，我們可能會在不加限制且不給予您任何補償的情況下使用這些內容。",
    "block6": "（c）限制。您不得：(1) 以侵犯、盜用或違反他人權利的方式使用本服務；(2) 對服務模型、演算法和系統進行逆向工程、反編譯、反組譯、翻譯或以其他方式嘗試發現其原始碼或基礎組成部分（除非此類限制被適用法律禁止）；(3) 使用本服務的輸出開發與 Noam 競爭的模型；(4) 使用任何自動化或程式設計方法從本服務中提取數據或輸出，除非透過 API 允許，包括抓取、網路抓取或網路數據提取；(5) 在並非人工生成的情況下將本服務的輸出表示為人工生成，或以其他方式違反我們的使用政策；(6) 未經我們事先同意，購買、銷售或轉讓 API 金鑰；或 (7) 向我們提供任何未滿 13 歲或適用數位同意年齡的兒童的個人資訊。您將遵守我們文件中規定的任何速率限制和其他要求。",
    "block7": "（d）第三方服務。與本服務相關聯的任何第三方軟體、服務或其他產品均受其自身條款的約束，而我們不對第三方產品負責。",
    "block8": "（a）您的內容。您可以向本服務提供輸入（「輸入」），並接收根據輸入生成和返回的輸出（「輸出」）（統稱為「內容」）。在雙方之間，以及在適用法律允許的範圍內，您擁有所有輸入。Noam 可以使用內容來提供和維護服務、遵守適用法律並執行我們的政策。您對內容負責，包括確保內容不違反任何適用法律或這些條款。",
    "block9": "（b）準確性。人工智慧和機器學習是快速發展的研究領域。我們不斷努力改進我們的服務，使其更加準確、可靠、安全和有益。考慮到機器學習的概率性質，在某些情況下，使用我們的服務可能會導致輸出錯誤，這可能無法準確反映真實的個人、地點或事實。您應該根據您的使用案例適當評估任何輸出的準確性，包括手動審查輸出。",
    "subTitle1": "註冊與存取",
    "subTitle10": "可分割性",
    "subTitle2": "使用要求",
    "subTitle3": "內容",
    "subTitle4": "費用與付款",
    "subTitle5": "機密性、安全性與數據保護",
    "subTitle6": "期限與終止",
    "subTitle7": "賠償；免責聲明；責任限制",
    "subTitle8": "準據法與管轄權",
    "subTitle9": "一般條款",
    "title": "服務條款",
    "updateTime": "更新日期：2024 年 3 月 15 日"
  },
  "trialModal": {
    "btn": "立即開始（僅需 $0.99）",
    "chat": "ChatPDF",
    "desc1": "試用期結束後，你將每年支付 $180",
    "desc2": "隨時取消",
    "focus": "專注模式",
    "hover": "懸停翻譯",
    "image": "圖片翻譯",
    "installBtn": "立即安裝領取",
    "memo": "我的最愛",
    "pdf": "PDF 對照翻譯",
    "realtime": "即時翻譯",
    "select": "劃詞翻譯",
    "title": "歡迎禮包",
    "trialText": "7 天免費試用",
    "video": "影片翻譯",
    "videoSummary": "影片摘要",
    "web": "網頁對照翻譯",
    "webSummary": "網頁摘要"
  },
  "upgradeModal": {
    "btn": "立即升級",
    "chat": {
      "free": "你已達到 Chatpdf 使用上限",
      "pro": "你本月已達到 Chatpdf 使用上限"
    },
    "contactStr": "請等待下次重置或聯繫我們：contact@noam.tools。",
    "limitAlertStr": "你的免費額度已用完，建議升級方案繼續體驗～",
    "more": "更多方案",
    "pageLimit": {
      "free": "你已達到 PDF 頁數上限"
    },
    "title": "溫馨提示",
    "upgradeAlertStr": "你的免費額度已用完，建議升級方案繼續體驗～",
    "upload": {
      "free": "你已達到 PDF 翻譯上限",
      "pro": "你本月已達到 PDF 翻譯上限"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "立即加入",
      "label": "新用戶福利：",
      "remain": "剩餘 30 個",
      "text": "僅限前 100 名！立即領取！"
    }
  }
}

export default TRANSLATION
