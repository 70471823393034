const TRANSLATION = {
  "buyModal": {
    "afterFree": "بعد انتهاء الفترة التجريبية",
    "btn": "جرّبها مجانًا",
    "freeTrial": "تجربة مجانية لمدة 3 أيام",
    "monthLabel": "شهر",
    "selectLabel": "اختر خطة",
    "title": "خطة الاشتراك",
    "yearLabel": "سنة"
  },
  "contact": {
    "block1": "للتخصيص أو إذا كانت لديك أي أسئلة أو اقتراحات، فيُرجى الاتصال بنا على contact@noam.tools وسنرد على الفور.",
    "block2": "ادعمنا من خلال تقييمنا في متجر إضافات تطبيقات Google على ★★★★★.",
    "block3": "ساعد الآخرين من خلال مشاركة هذا الامتداد:",
    "title": "اتصل بنا"
  },
  "help": {
    "AquickOverviewOfNoam": "نظرة عامة سريعة على Noam",
    "ChatPDF": "دردشة PDF",
    "ChatPDF2": "دردشة PDF",
    "FavoritesManagement": "إدارة المفضلة",
    "Fixedplugin": "إضافة ثابتة",
    "Focusing": "التركيز",
    "Free": "مجاني",
    "FreeTrialBenefits": "مزايا الإصدار التجريبي المجاني",
    "GoWithWikipedia": "الانتقال إلى ويكيبيديا",
    "GoWithYouTube": "الانتقال إلى YouTube",
    "HoverTranslation": "الترجمة بالمرور",
    "Howtouse": "طريقة الاستخدام",
    "ImageTranslation": "ترجمة الصور",
    "InstantTranslation": "الترجمة الفورية",
    "Membership": "عضويه",
    "NoamHelp": "يمكن لـ Noam مساعدتك في الترجمة والتلخيص",
    "PDFparallelTranslation": "ترجمة PDF متوازية",
    "ParallelTranslation": "الترجمة المتوازية",
    "SkipAllSteps": "تخطي جميع الخطوات",
    "Summary": "ملخص",
    "Translation": "ترجمة",
    "TryItForFree": "جرّبها مجانًا",
    "Video": "فيديو",
    "VideoSummary": "ملخص الفيديو",
    "VideoTranslation": "ترجمة الفيديو",
    "WebSummary": "ملخص الويب",
    "WordSelectionTranslation": "ترجمة اختيار الكلمات",
    "benefits": "لقد أعددنا لك المزايا!",
    "dayFree": "إصدار تجريبي مجاني لمدة 3 أيام",
    "done": "تمّ",
    "next": "التالي",
    "pin": "تثبيت Noam، لسهولة الوصول إليه~~",
    "planIsNotExist": "الخطة غير موجودة",
    "startWiki": "لنبدأ باستخدام ويكيبيديا~",
    "startYoutube": "لنبدأ باستخدام YouTube~",
    "try": "جرّبها",
    "tryNow": "جرّبها الآن~"
  },
  "home": {
    "PDFTranslate": {
      "desc": "ترجمة ثنائية اللغة + دردشة PDF",
      "sub1": {
        "desc": "قراءة ثنائية اللغة، بحيث يمكنك مقارنة النص الأصلي بالترجمة أثناء القراءة لتحسين كفاءة القراءة",
        "title": "قراءة ثنائية اللغة"
      },
      "sub2": {
        "desc": "يقوم الذكاء الاصطناعي بالتحليل والتجميع، ويمكنه الإجابة على أسئلتك بناءً على محتوى PDF",
        "title": "دردشة PDF"
      },
      "sub3": {
        "desc": "ملف PDf مدعوم بالذكاء الاصطناعي لترجمات أكثر دقة وكفاءة",
        "title": "الدقة والكفاءة"
      },
      "title": "ترجمة PDF"
    },
    "aiAssistant": {
      "desc": "جعل ترجمة الويب أذكى وأكثر ملاءمة",
      "panel1": {
        "desc": "تساعدك الترجمات المرجعية المتقاطعة ثنائية اللغة على مقارنة النص الأصلي بالترجمة حتى تتمكن من الفهم بشكل أفضل وتحسين كفاءة القراءة.",
        "title": "الترجمة ثنائية اللغة"
      },
      "panel2": {
        "left": {
          "desc": "عند تصفح صفحة ويب، مرر مؤشر الماوس فوق المحتوى الذي تريد ترجمته للحصول على نتيجة الترجمة.",
          "title": "الترجمة بالمرور فوق النص"
        },
        "right": {
          "desc": "عند تصفح صفحة ويب، حدد مباشرةً النص المراد تسطيره للحصول على نتيجة الترجمة.",
          "title": "ترجمة النص المحدد"
        }
      },
      "panel3": {
        "one": {
          "desc": "ترجمة وتلخيص صفحات الويب ومحتوى الفيديو لك في أي وقت وفي أي مكان وفي أي سيناريو؛ مما يوفر لك تجربة أكثر سلاسة!",
          "title": "انقر"
        },
        "three": {
          "desc": "نموذج كبير مدعوم بالذكاء الاصطناعي لضمان دقة الترجمة واتساقها وتعزيز تجربة القراءة",
          "title": "مدعوم بالذكاء الاصطناعي"
        },
        "two": {
          "desc": "تغطية كاملة متعددة اللغات، قراءة ثنائية اللغة خالية من الحواجز، ترجمة بنقرة واحدة؛ مما يوفر لك تجربة لا مثيل لها!",
          "title": "أكثر شمولية وراحة"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "خبير الكفاءة لديك، فهو يترجم ويلخص محتوى فيديو YouTube جنبًا إلى جنب مع chatgpt لتوفير وقت القراءة!",
          "key": "Video",
          "title": "ترجمة الفيديو وتلخيصه"
        },
        "tab2": {
          "desc": "انقر لترجمة الصور أثناء تصفح الويب",
          "key": "Image",
          "title": "ترجمة الصور"
        },
        "tab3": {
          "desc": "استخراج المعلومات الأساسية من صفحات الويب بسرعة للتلخيص، مما يعزز كفاءة القراءة",
          "key": "Web",
          "title": "ترجمة الويب وتلخيصها"
        },
        "tag1": "بسيط",
        "tag2": "كفاءة",
        "tag3": "سهل الفهم"
      },
      "title": "مساعد الذكاء الاصطناعي"
    },
    "getStart": "ابدأ",
    "getStartedForFree": "ابدأ  مجانًا",
    "howItWork": {
      "desc": "يدمج Noam نماذج الذكاء الاصطناعي لتزويد المستخدمين بتجربة ذكية لترجمة صفحات الويب وتلخيصها عبر اللغات، وترجمة ملفات PDF والصور، وترجمة الفيديو وتلخيصه",
      "title": "إذًا，كيف يعمل"
    },
    "lastBlock": {
      "desc": "ترجمة أفضل بالذكاء الاصطناعي وتجربة قراءة أكثر سلاسة"
    },
    "top": {
      "desc": "Noam هو امتداد متصفح مجاني  يعمل بالذكاء الاصطناعية  يساعدك على ترجمة صفحات الويب وملخصاتها ومحتوى الفيديو وملفات PDF.\\n      انضم إلى Noam واستمتع بالسحر!",
      "title1": "نقرة واحدة",
      "title2": "سحر واحد"
    },
    "userReviews": {
      "desc": "تقييم خمس نجوم على chromewebstore",
      "title": "آراء المستخدمين"
    }
  },
  "installTips": {
    "btn": "جرّبه الآن",
    "text": "ثبّت “ملحق متصفح Noam” لفتح المزيد من الميزات!  المراجع المتقاطعة على الويب،\\n    وتلخيص مقاطع فيديو الويب، والمزيد!"
  },
  "locale": {
    "af": "الأفريقانية",
    "am": "الأمهرية",
    "ar": "العربية",
    "as": "الآسامية",
    "auto": "الكشف التلقائي",
    "az": "الأذربيجانية",
    "ba": "الباشكيرية",
    "bg": "البلغارية",
    "bn": "البنغالية",
    "bo": "التبتية",
    "bs": "البوسنية",
    "ca": "الكاتالونية",
    "cs": "التشيكية",
    "cy": "الويلزية",
    "da": "الدانماركية",
    "de": "الألمانية",
    "dsb": "السوربية الدنيا",
    "dv": "الديفيهية",
    "el": "اليونانية",
    "en": "الإنجليزية",
    "es": "الأسبانية",
    "et": "الإستونية",
    "eu": "لغة الباسك",
    "fa": "الفارسية",
    "fi": "الفنلندية",
    "fil": "الفلبينية",
    "fj": "الفيجية",
    "fr": "الفرنسية",
    "frCA": "الفرنسية (كندا)",
    "ga": "الأيرلندية",
    "gl": "الجاليكية",
    "gom": "الكونكانية",
    "gu": "الغوجاراتية",
    "ha": "الهوسية",
    "he": "العبرية",
    "hi": "الهندية",
    "hr": "الكرواتية",
    "hsb": "السوربية العليا",
    "ht": "اللغة الكريولية الهايتية",
    "hu": "المجرية",
    "hy": "الأرمينية",
    "id": "الإندونيسية",
    "ig": "الإيغبو",
    "ikt": "الإنكتيتوتية",
    "is": "الأيسلندية",
    "it": "الإيطالية",
    "iu": "الإنويتية",
    "iuLatn": "الإنويتية (لاتينية)",
    "ja": "اليابانية",
    "ka": "الجورجية",
    "kk": "الكازاخستانية",
    "km": "الخميرية",
    "kmr": "الكردية (الشمالية)",
    "kn": "الكانادية",
    "ko": "الكورية",
    "ku": "الكردية (المركزية)",
    "ky": "القرغيزية",
    "ln": "اللينغالية",
    "lo": "اللاوية",
    "lt": "الليتوانية",
    "lug": "الغاندا",
    "lv": "اللاتفية",
    "lzh": "الصينية (الأدبية)",
    "mai": "الميثيلية",
    "mg": "الملغاشية",
    "mi": "الماورية",
    "mk": "المقدونية",
    "ml": "الماليالامية",
    "mnCyrl": "المنغولية (السيريلية)",
    "mnMong": "المنغولية (التقليدية)",
    "mr": "المراثية",
    "ms": "الملايوية",
    "mt": "المالطية",
    "mww": "همونغ داو",
    "my": "ميانمار (البورمية)",
    "nb": "النرويجية",
    "ne": "النيبالية",
    "nl": "الهولندية",
    "noSearchLang": "هذه اللغة غير مدعومة",
    "nso": "السيسوتو الشمالية",
    "nya": "النيانجا",
    "or": "الأودية",
    "otq": "الأوتومي كويريتارو",
    "pa": "البنجابية",
    "pl": "البولندية",
    "placeholder": "بحث",
    "prs": "الدارية",
    "ps": "الباشتو",
    "pt": "البرتغالية (البرازيل)",
    "ptPT": "البرتغالية (البرتغال)",
    "ro": "الرومانية",
    "ru": "الروسية",
    "run": "الروندية",
    "rw": "الكينيارواندا",
    "sd": "السندية",
    "si": "السنهالية",
    "sk": "السلوفاكية",
    "sl": "السلوفينية",
    "sm": "الساموية",
    "sn": "الشونا",
    "so": "الصومالية",
    "sq": "الألبانية",
    "srCyrl": "الصربية (السيريلية)",
    "srLatn": "الصربية (اللاتينية)",
    "st": "السيسوتو",
    "sv": "السويدية",
    "sw": "السواحيلية",
    "ta": "التاميلية",
    "te": "التيلوغوية",
    "th": "التايلاندية",
    "ti": "التغرينية",
    "tk": "التركمانية",
    "tlhLatn": "الكلينغونية (لاتينية)",
    "tlhPiqd": "الكلينغونية (pIqaD)",
    "tn": "تسوانا",
    "to": "التونغية",
    "tr": "التركية",
    "tt": "التترية",
    "ty": "التاهيتية",
    "ug": "الأويغورية",
    "uk": "الأوكرانية",
    "ur": "الأردية",
    "uz": "الأوزبكية (لاتينية)",
    "vi": "الفيتنامية",
    "xh": "كوسا",
    "yo": "اليوروبا",
    "yua": "يوكاتيك مايا",
    "yue": "الكانتونية (التقليدية)",
    "zh": "صيني",
    "zhHans": "الصينية المبسطة",
    "zhHant": "الصينية التقليدية",
    "zu": "الزولو"
  },
  "login": {
    "and": "  و",
    "btn": "تسجيل الدخول باستخدام Google",
    "btn1": "تسجيل الدخول الآن",
    "desc": "سجّل الدخول لفتح المزيد من التجارب",
    "policy": " سياسة الخصوصية",
    "terms": "بنود الخدمة ",
    "tips": "بالتسجيل، أوافق على ",
    "title": "مرحبًا بكم في Noam"
  },
  "pageFooter": {
    "follow": {
      "facebook": "فيسبوك",
      "title": "متابعة",
      "twitter": "تويتر"
    },
    "legal": {
      "policy": "سياسة خاصة",
      "terms": "شروط الخدمة",
      "title": "قانوني"
    },
    "product": {
      "image": "ترجمة الصور",
      "pdf": "ترجمة PDF",
      "title": "المنتجات",
      "video": "ترجمة الفيديو وتلخيصها",
      "web": "ترجمة الويب وتلخيصها"
    },
    "resources": {
      "title": "المصادر"
    }
  },
  "pageHeader": {
    "contact": "تواصل معنا",
    "help": "مركز المساعدة",
    "logout": "تسجيل الخروج",
    "logoutSucc": "تم تسجيل الخروج بنجاح",
    "pricing": "الأسعار",
    "settings": "الإعدادات",
    "signUp": "تسجيل الدخول"
  },
  "pdf": {
    "autoDetect": "الكشف التلقائي",
    "cancelBtn": "إلغاء",
    "cancelUpload": "إلغاء",
    "chatPdf": {
      "Search": "بحث",
      "cancel": "إلغاء",
      "chatTitle": "مرحبًا بك في Noam، ملخص PDF جاهز:",
      "clear": "مسح",
      "clearContent": "بعد المسح، سيتم إنشاء محادثة جديدة ولن يتم حفظ محتويات هذه المحادثة، هل أنت متأكد أنك تريد المسح؟",
      "clearDialogTitle": "تنبيه",
      "clearSucc": "تمت عملية المسح بنجاح",
      "copy": "نسخ",
      "copySucc": "تم النسخ بنجاح",
      "delete": "حذف",
      "deleteCancel": "إلغاء",
      "deleteContent": "بعد الحذف، لا يمكن استرداد ملف PDF هذا، هل تريد حذفه؟",
      "deleteOk": "حذف",
      "deleteSuccess": "تم الحذف بنجاح",
      "deleteTitle": "نصيحة",
      "expand": "طي",
      "historyTitle": "السجل",
      "listError": "فشل تحميل قائمة القراءة. يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بدعم العملاء",
      "loading": "جارٍ التحميل...",
      "noDataDesc": "لا يوجد سجل~ ",
      "noMore": "لا يوجد المزيد.",
      "placeholder": "أدخل أي سؤال حول ملف PDF",
      "summaryTitle": "أسئلة قد تهمك:"
    },
    "collectionEmpty": "لا يوجد محتوى في المجموعة~",
    "collectionTab": "مذكرة",
    "dataLoading": "جارٍ تحميل البيانات...",
    "dialogContent": "هل أنت متأكد أنك تريد حذف هذا؟",
    "dialogTitle": "تنبيه",
    "download": {
      "both": "ثنائي اللغة",
      "btn": "حفظ الآن",
      "cancel": "سيؤدي إغلاق النافذة المنبثقة إلى إلغاء تنزيل ملف PDF. هل أنت متأكد أنك تريد الإلغاء؟",
      "desc": "حفظ محليًا: يتم حفظ الترجمات التي تم تنزيلها كصور؛ حفظ وطباعة: يتم الاحتفاظ بالترجمات التي تم تنزيلها بتنسيقها الأصلي وتدعم النسخ.",
      "download": "تنزيل",
      "downloadMsg1": "تقدم ترجمة PDF:",
      "downloadMsg2": "المتطلبات المتوقعة",
      "downloadSucc": "جاهز للتنزيل! انقر على حفظ الآن لتنزيل ملف PDF.",
      "downloadTip": "جارٍ تنزيل ملف PDF. لا تغلق النافذة المنبثقة أثناء التنزيل لتجنب فشل التنزيل.",
      "error": "فشل الحفظ على الجهاز، يرجى محاولة الطباعة",
      "fileName": "اسم الملف:",
      "fileType": "نوع الملف الذي سيتم تنزيله:",
      "guide": {
        "placeholder": "الرجاء إدخال رابط PDF",
        "start": "بدء الترجمة",
        "text1": "قراءة ثنائية اللغة",
        "text2": "قراءة ثنائية اللغة، بحيث يمكنك مقارنة النص الأصلي بالترجمة أثناء القراءة لتحسين كفاءة القراءة",
        "text3": "دردشة PDF",
        "text4": "يقوم الذكاء الاصطناعي بالتحليل والتجميع، ويمكنه الإجابة على أسئلتك بناءً على محتوى PDF",
        "text5": "الدقة والكفاءة",
        "text6": "ملف PDf مدعوم بالذكاء الاصطناعي لترجمات أكثر دقة وكفاءة",
        "title1": "الترجمة ثنائية اللغة ",
        "title2": "دردشة PDF",
        "toast": "الرجاء إدخال رابط pdf عبر الإنترنت صالح ينتهي بـ .pdf"
      },
      "loading": "جارٍ الحفظ...",
      "loading2": "جارٍ التحميل...",
      "minute": "دقائق...",
      "msg1": "بعض ترجمات المستند الحالي أطول من النص الأصلي، وقد يتداخل النص بعد التنزيل، لذلك يوصى بقراءته على صفحة الويب.",
      "msg2": "1、لم تتم ترجمة المحتوى بالكامل، يرجى تنزيله بعد اكتمال الترجمة.",
      "msg3": "2、جزء من ترجمة المستند طويل جدًا، يمكنك قراءته في صفحة الويب لتجنب تداخل النص.",
      "msg4": "توجد حاليًا مهام قيد الترجمة، يُرجى المحاولة مرة أخرى بعد اكتمال الترجمة.",
      "progress": "جارٍ ترجمة ملف PDF. التقدم: {progress}، من المتوقع أن يستغرق الأمر {minutes} دقيقة...",
      "save": "حفظ محليًا",
      "savePrint": "حفظ وطباعة",
      "success": "تم التنزيل بنجاح",
      "trans": "الترجمة فقط",
      "transContinue": "متابعة الترجمة"
    },
    "downloadBtn": "تحميل",
    "dragTipModal": {
      "black": "المسافة",
      "leftKey": "الزر الأيسر",
      "ok": "فهمت",
      "press": "اضغط على",
      "tips": "لسحبها وعرضها."
    },
    "entry": "قائمة ملفات PDF",
    "fileErrMsg": "فشل قراءة الملف، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.",
    "fileMsg": "حجم الملف الحالي:",
    "fileMsg2": "يرجى تحميل ملف أصغر من 100 ميجابايت",
    "freeBannerBtn": "قم بالترقية للاستمتاع بالمزيد",
    "freeBannerTips": "تم الوصول إلى نهاية ترجمة PDF المجانية",
    "hasTransTask": "هناك مهمة قيد الترجمة. الرجاء الإنتظار.",
    "hoverTrans": "ترجمة الفقرة بالمرور فوقها",
    "initial": "ترجمة PDF",
    "installText": "مصدر واحد لجميع احتياجاتك اليومية من الترجمة",
    "installText2": "الرجاء تثبيت  أولاً",
    "installTips": "ثبّت المكوّن الإضافي الصغير، يمكنك ترجمة المزيد من ملفات PDF، ويمكنك أيضًا تجربة ترجمة سلسة لصفحات الويب:",
    "kw": "دبوس",
    "link": "رابط",
    "listError": "فشل تحميل قائمة PDF، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.",
    "memoTotalCount": "إجمالي المحتوى 1",
    "new": "ملف جديد",
    "noId": "معرف الملف غير موجود",
    "notPdf": "ملفات PDF فقط",
    "okBtn": "حذف",
    "okBtn2": "التعرف على",
    "original": "الأصل",
    "parallel": "متوازي",
    "parseErrDialogContent": "لا يدعم رابط PDF الحالي الترجمة المباشرة، يرجى تنزيل ملف PDF الأصلي بعد الترجمة",
    "parseErrTips": "تعذر تحليل الملف",
    "parseFileToast": "فشل تحليل الملف، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.",
    "pdfLoading": "جارٍ التحليل...",
    "searchPlaceholder": "البحث",
    "selectTrans": "تسطير الكلمات وترجمتها",
    "shareBtn": "مشاركة",
    "shareBtnText1": "إنشاء روابط المشاركة",
    "shareBtnText2": "محتوى المشاركة المُحدّث",
    "shareModal": {
      "cancel": "إلغاء",
      "copy": "نسخ",
      "copySucc": "تم نسخ الرابط بنجاح",
      "custom": "تخصيص:",
      "errMsg": "لم تتم ترجمة محتوى نطاق المشاركة الحالي بالكامل، لذا يرجى ترجمته قبل مشاركته.",
      "gen": "مشاركة",
      "modify": "تعديل",
      "notSupport": "هذه اللغة غير مدعومة حاليًا",
      "page": "صفحات",
      "pageNuErr": "الرجاء إدخال قيمة رقم الصفحة الصحيحة",
      "range": "النطاق:",
      "remain": "متبقي",
      "remain1": "إجمالي المتطلبات المتوقعة",
      "search": "بحث",
      "settings": "إعدادات المشاركة:",
      "shareSetting": "إعدادات مشاركة المحتوى",
      "shareText": "مشاركة",
      "shareTips": "شارك رابط PDF المترجم مع أصدقائك لقراءته:",
      "sourceLang": "المصدر:",
      "target": "الهدف:",
      "text1": "إعدادات المشاركة",
      "text2": "إنشاء روابط المشاركة",
      "totalPage": "إجمالي الصفحات 1",
      "trans": "ترجمة",
      "translateTips": "نصيحة: إذا استغرقت الترجمة وقتًا طويلاً، يمكنك إغلاق النافذة المنبثقة ومشاركتها بعد انتهاء الترجمة.",
      "translating": "جارٍ ترجمة PDF...",
      "update": "تم تحديث روابط المشاركة"
    },
    "shareMsg1": "تم إنشاء رابط المشاركة بنجاح",
    "shareMsg2": "تم تحديث محتوى المشاركة بنجاح",
    "shareMsg3": "فشل إنشاء رابط المشاركة، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.",
    "shareMsg4": "فشل تحديث محتوى المشاركة، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء",
    "shareTips": "تم الانتهاء من ترجمة PDF، شاركها مع أصدقائك!",
    "start": "ابدأ تجربتك المجانية",
    "thumbnail": "الفهرس",
    "toast1": "جارٍ تحميل ملف PDF، يُرجى المحاولة مرة أخرى لاحقًا!",
    "toast2": "محتوى ملف PDF كبير جدًا، لا ندعم الحوار في الوقت الحالي",
    "toast3": "جارٍ التنزيل، يُرجى المحاولة مرة أخرى بعد اكتمال التنزيل.",
    "toolbar": {
      "Adjust": "ضبط",
      "autoFit": "ملاءمة تلقائية"
    },
    "trans": "الترجمة",
    "transSucc": "تمت الترجمة بنجاح!",
    "unLoginToast": "يرجى تسجيل الدخول لاستخدام هذه الوظيفة",
    "uploadBtn": "رفع",
    "uploadErr": "فشل تحميل الملف. يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء",
    "uploadMsg1": "يُرجى سحب الملف وإفلاته هنا",
    "uploadMsg2": "أنواع الملفات المدعومة: PDF  |  أقصى حجم للملف: 100 ميجابايت",
    "uploadMsg3": "انقر لاختيار ملفات أو سحبها وإفلاتها هنا للرفع",
    "uploading": "جارٍ الرفع",
    "uploadingMsg": "يُرجى التحلي بالصبر حيث يستغرق الرفع بعض الوقت.",
    "uploadingMsg1": "عملية تحميل الملف قيد التقدم. الرجاء المحاولة مرة أخرى لاحقاً.",
    "uploadingMsg2": "لديك ملف قيد التحويل حاليًا، يُرجى المحاولة مرة أخرى لاحقًا.",
    "uploadingMsg3": "فشل طلب تقدم التحويل، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء",
    "uploadingMsg4": "لا يمكن لـ Noam الوصول إلى ملفاتك المحلية مباشرةً، يُرجى تحميل ملف PDF يدويًا للترجمة.",
    "uploadingMsg5": "فشل الحصول على عنوان URL، يُرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.",
    "video": "فيديو",
    "vipBannerBtn": "قم بالترقية للاستمتاع بالمزيد",
    "vipBannerTips": "لقد وصلت إلى الحد الأقصى لترجمة ملفات PDF لهذا الشهر.",
    "webTrans": "مرجع متقاطع كامل"
  },
  "pricing": {
    "FAQ": "الأسئلة الشائعة",
    "FQAObj": {
      "a1": "يوجد إصدار مجاني من Noam يمكن استخدامه مباشرة.",
      "a2": "بالإضافة إلى الإصدار المجاني، هناك ثلاث خطط عضوية، Pro و Pro + و Enterprise؛ يمكنك الاختيار بين طريقتين للدفع، شهرية مستمرة وسنوية مستمرة، والتكلفة الإجمالية للخطة السنوية المستمرة أكثر ملاءمة.",
      "a3": "لا يوجد فرق في الوظائف. التكلفة الإجمالية للاشتراك السنوي المستمر أقل من تكلفة الاشتراك الشهري المستمر؛ يتم دفع الاشتراك الشهري مرة واحدة شهريًا، بينما يتم دفع الاشتراك السنوي مرة واحدة سنويًا.",
      "a4": "يمكنك الانتقال إلى صفحة إعدادات الواجهة الخلفية واختيار الاشتراك لإلغاء خطة التجديد.",
      "q1": "هل يمكنني استخدامه مجانًا؟",
      "q2": "هل هناك خطط عضوية وخيارات رسوم مختلفة؟",
      "q3": "ما الفرق بين الاشتراك الشهري المستمر والاشتراك السنوي المستمر؟",
      "q4": "كيف يمكنني إلغاء خطة التجديد التلقائي الخاصة بي؟"
    },
    "around": "تقريبًا",
    "billedMonthly": "يتم الدفع شهريًا",
    "billedYearly": "يتم الدفع سنويًا",
    "day": "يوميًا",
    "desc": "قارن واختر الأفضل لك",
    "detailedCompare": "مقارنة تفصيلية ",
    "discount": "خصم 20%",
    "enterprise": {
      "benefit1": "دعم سريع من خدمة العملاء",
      "benefit2": "متعدد المستخدمين",
      "benefit3": "حلول مخصصة",
      "benefit5": "مدير حساب حصري",
      "btn": "اتصل بنا",
      "recommendText": "خطط مخصصة（≥3人）",
      "talkToUs": "تحدث إلينا"
    },
    "free": {
      "benefit1": "ترجمة فورية ${FREE_BENEFITS.realTimeCount} مرات/يوميًا",
      "benefit2": "ترجمة وملخص صفحة الويب",
      "benefit3": "ترجمة ${FREE_BENEFITS.imageCount} صفحات من الصور",
      "benefit4": "ترجمة وملخص الفيديو",
      "benefit5": "ترجمة ${FREE_BENEFITS.pdfUploadCount} ملفات PDF",
      "benefit6": "${FREE_BENEFITS.tokenCount} رمز مميز",
      "btn": "استمتع",
      "recommendText": "تجربة مجانية"
    },
    "imageTranslation": "ترجمة الصور",
    "month": "شهريًا",
    "monthly": "شهريًا",
    "pageTitle": "التسعير",
    "pdf": {
      "chat": "عدد استفسارات PDF",
      "maxPage": "الحد الأقصى لعدد الصفحات في PDF",
      "translation": "ترجمة PDF"
    },
    "preMonth": "شهريًا",
    "pro": {
      "benefit1": "دعم سريع من خدمة العملاء",
      "benefit2": "${PRO_BENEFITS.tokenCount} رموز مميزة/شهريًا",
      "benefit3": "ترجمة وملخص صفحة الويب",
      "benefit4": "ترجمة ${PRO_BENEFITS.imageCount} صفحات من الصور",
      "benefit5": "ترجمة وملخص الفيديو",
      "benefit6": "ترجمة ${PRO_BENEFITS.pdfUploadCount} ملفات PDF",
      "btn": "ابدء",
      "recommendText": "يختار معظم الناس"
    },
    "proPlus": {
      "benefit1": "دعم سريع من خدمة العملاء",
      "benefit2": "${PRO_V_BENEFITS.tokenCount} رموز مميزة/شهريًا",
      "benefit3": "ترجمة وملخص صفحة الويب",
      "benefit4": "ترجمة ${PRO_V_BENEFITS.imageCount} صفحات من الصور",
      "benefit5": "ترجمة وملخص الفيديو",
      "benefit6": "ترجمة ${PRO_V_BENEFITS.pdfUploadCount} ملفات PDF",
      "recommendText": "الخيار الأنسب"
    },
    "video": {
      "summary": "ملخص الفيديو",
      "translation": "ترجمة الفيديو"
    },
    "web": {
      "contrastiveTranslation": "ترجمة ثنائية اللغة",
      "dictionaryTranslation": "ترجمة اختيار النص",
      "hoverTranslation": "الترجمة بالمرور",
      "instantTranslation": "الترجمة الفورية",
      "summary": "ملخص صفحة الويب"
    },
    "yearly": "سنويًا"
  },
  "privacy": {
    "block1": "نحن، Noam (المشار إليها فيما يلي باسم \"Noam\" أو \"نحن\" أو \"ضمير المتكلم\" أو \"ضمير ملكية المتكلم\")، نحترم خصوصيتك ونلتزم بحماية أي معلومات نحصل عليها منك أو عنك. تصف سياسة الخصوصية هذه ممارساتنا المتعلقة بجمع أو استخدام المعلومات الشخصية من استخدامك لموقعنا الإلكتروني وتطبيقاتنا وخدماتنا (يشار إليها إجمالاً باسم \"الخدمات\"). لا تنطبق سياسة الخصوصية هذه على المحتوى الذي نتعامل معه نيابةً عن عملاء منتجاتنا التجارية، مثل واجهة برمجة التطبيقات الخاصة بنا. يخضع استخدام هذه البيانات لاتفاقية العميل الخاصة بنا، والتي تغطي الوصول إلى هذه المنتجات واستخدامها.",
    "block10": "نحن ننفذ تدابير تقنية وإدارية وتنظيمية معقولة تجاريًا لحماية المعلومات الشخصية عبر الإنترنت وغير المتصلة بالإنترنت من الفقدان أو إساءة الاستخدام أو الوصول غير المصرح به أو الكشف أو التغيير أو الأعمال العدائية. ومع ذلك، لا يمكن لأي إرسال عبر الإنترنت أو البريد الإلكتروني ضمان الأمان أو الدقة الكاملة. على وجه الخصوص، قد لا تكون رسائل البريد الإلكتروني المرسلة إلينا أو منا آمنة. لذلك، يجب أن تكون حذرًا بشكل خاص عند تحديد المعلومات التي ترسلها إلينا من خلال الخدمة أو البريد الإلكتروني. بالإضافة إلى ذلك، لسنا مسؤولين عن أي عرقلة لإعدادات الخصوصية أو تدابير الأمان في الخدمة أو إعدادات أمان مواقع الطرف الثالث أو تدابير الأمان الخاصة بها. سنحتفظ بمعلوماتك الشخصية طالما كان ذلك ضروريًا لنا لتزويدك بالخدمات أو لأغراض تجارية مشروعة أخرى، مثل حل النزاعات أو أسباب تتعلق بالأمان أو السلامة أو الامتثال لالتزاماتنا القانونية. ستعتمد المدة الزمنية التي نحتفظ فيها بالمعلومات الشخصية على العديد من العوامل، مثل كمية المعلومات وطبيعتها وحساسيتها، والمخاطر المحتملة للاستخدام أو الكشف غير المصرح به، والأغراض التي نعالج من أجلها المعلومات، والاعتبارات الأخرى الملزمة قانونًا. ",
    "block11": "قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. عندما نفعل ذلك، سننشر نسخة محدثة على هذه الصفحة، ما لم يكن مطلوبًا خلاف ذلك بموجب القانون المعمول به.",
    "block12": "إذا كانت لديك أي أسئلة أو استفسارات لم تتم الإجابة عليها بخصوص سياسة الخصوصية هذه، فيرجى الاتصال بخدمة العملاء لدينا. البريد الإلكتروني: ",
    "block2": "نقوم بجمع المعلومات الشخصية التالية (\"المعلومات الشخصية\") المتعلقة بك:",
    "block3": "المعلومات التي تقدمها أنت: إذا قمت بإنشاء حساب لاستخدام خدماتنا أو التواصل معنا، فسنقوم بجمع معلومات شخصية على النحو التالي:",
    "block4": "المعلومات الشخصية التي يتم تلقيها تلقائيًا من استخدامك للخدمات: عندما تقوم بالوصول إلى الخدمات أو استخدامها أو التفاعل معها، فإننا نتلقى المعلومات التالية حول وصولك أو استخدامك أو تفاعلك (\"المعلومات الفنية\"):",
    "block5": "قد نستخدم المعلومات الشخصية للأغراض التالية:",
    "block6": "الملخص أو المعلومات مجهولة المصدر: قد نقوم بتجميع أو إخفاء هوية المعلومات الشخصية لمنع إعادة استخدامها لأغراض التعريف واستخدام هذه المعلومات لتحليل فعالية خدماتنا، وتحسين خدماتنا وإضافة ميزات إليها، وإجراء البحوث، ولأغراض مماثلة. بالإضافة إلى ذلك، قد نقوم بتحليل السلوك العام وخصائص مستخدمي خدماتنا ومشاركة المعلومات المجمعة مع أطراف ثالثة، ونشر هذه المعلومات المجمعة، أو إتاحتها بشكل عام. قد نقوم بجمع معلومات مجمعة من خلال الخدمات، ومن خلال ملفات تعريف الارتباط، ومن خلال وسائل أخرى موصوفة في سياسة الخصوصية هذه. سنحتفظ بالمعلومات مجهولة المصدر ونستخدمها في شكل مجهول أو مجهول المصدر، ولن نحاول إعادة تحديد هوية هذه المعلومات إلا إذا كان ذلك مطلوبًا بموجب القانون.",
    "block7": "في حالات معينة، قد نقدم معلوماتك الشخصية إلى أطراف ثالثة دون إشعار آخر لك، ما لم يكن ذلك مطلوبًا بموجب القانون:",
    "block8": "اعتمادًا على موقعك الجغرافي، قد يكون للأفراد في المنطقة الاقتصادية الأوروبية والمملكة المتحدة والعالم بشكل عام حقوق قانونية معينة فيما يتعلق بمعلوماتهم الشخصية. على سبيل المثال، قد يكون لديك الحق في:",
    "block9": "قد تحتوي الخدمة على روابط لمواقع ويب أخرى لا يتم تشغيلها أو التحكم فيها بواسطة Noam، بما في ذلك خدمات التواصل الاجتماعي (\"مواقع الطرف الثالث\"). ستخضع المعلومات التي تشاركها مع مواقع الطرف الثالث لسياسات الخصوصية وشروط خدمة مواقع الطرف الثالث المحددة، وليس بموجب سياسة الخصوصية هذه. نحن نقدم هذه الروابط من أجل الراحة ولا تعني موافقتنا على مواقع الويب هذه أو مراجعتها. يرجى الاتصال بمواقع الطرف الثالث مباشرةً للحصول على معلومات حول سياسات الخصوصية وشروطها.",
    "subBlock1": "معلومات الحساب: عندما تقوم بإنشاء حساب على نظامنا الأساسي، فإننا نجمع معلومات متعلقة بحسابك، بما في ذلك اسمك ومعلومات الاتصال بك وبيانات اعتماد حسابك ومعلومات بطاقة الدفع وسجل المعاملات (يشار إليها باسم \"معلومات الحساب\").",
    "subBlock10": "توفير الخدمات وإدارتها وصيانتها و/أو تحليلها؛",
    "subBlock11": "للتواصل معك؛",
    "subBlock12": "لتطوير مشاريع وخدمات جديدة؛",
    "subBlock13": "منع الاحتيال أو النشاط الإجرامي أو إساءة استخدام خدماتنا، وحماية أمن أنظمة تكنولوجيا المعلومات والهندسة المعمارية والشبكات الخاصة بنا؛",
    "subBlock14": "إجراء عمليات نقل الأعمال؛",
    "subBlock15": "الامتثال للالتزامات القانونية والإجراءات القانونية، وحماية حقوقنا أو خصوصيتنا أو أمننا أو ممتلكاتنا، وكذلك تلك الخاصة بالشركات التابعة لنا أو أنت أو أطراف ثالثة أخرى.",
    "subBlock16": "الموردون ومقدمو الخدمات: لمساعدتنا في تلبية احتياجات التشغيل التجاري وأداء خدمات ووظائف معينة، قد نقدم معلومات شخصية إلى الموردين ومقدمي الخدمات، بما في ذلك مقدمو خدمات الاستضافة، ومقدمو خدمات السحابة، ومقدمو خدمات تكنولوجيا المعلومات الآخرون، وبرامج اتصال البريد الإلكتروني، ومقدمو خدمات تحليلات الويب، إلخ. سيقوم هؤلاء الشركاء بالوصول إلى المعلومات الشخصية أو معالجتها أو تخزينها فقط بناءً على توجيهاتنا أثناء أداء واجباتهم تجاهنا.",
    "subBlock17": "عمليات نقل الأعمال: إذا شاركنا في معاملات استراتيجية، أو إعادة تنظيم، أو إفلاس، أو عمليات استحواذ على الإفلاس، أو انتقال الخدمات إلى مزود آخر (يشار إليها إجمالاً باسم \"المعاملات\")، فقد تتم مشاركة معلوماتك الشخصية والمعلومات الأخرى مع نظرائنا في المعاملات والأطراف الأخرى التي تساعد في المعاملات أثناء العناية الواجبة ونقلها كجزء من المعاملات جنبًا إلى جنب مع الأصول الأخرى إلى شركة خلف أو تابعة.",
    "subBlock18": "المتطلبات القانونية: قد نشارك معلوماتك الشخصية مع السلطات الحكومية، أو أقران الصناعة، أو أطراف ثالثة أخرى، بما في ذلك معلومات تفاعلك مع خدماتنا، (1) إذا كان ذلك مطلوبًا بموجب القانون أو إذا اعتقدنا أن مثل هذا الإجراء ضروري للامتثال للالتزامات القانونية؛ (2) لحماية حقوقنا أو ممتلكاتنا والدفاع عنها؛ (3) إذا قررنا من جانب واحد أن هناك انتهاكًا لشروطنا أو سياساتنا أو قوانيننا؛ (4) للكشف عن الاحتيال أو الأنشطة غير القانونية الأخرى أو منعها؛ (5) لحماية منتجاتنا أو موظفينا أو مستخدمينا، أو السلامة العامة، أو الأمن، أو السلامة؛ أو (6) للدفاع ضد المسؤولية القانونية.",
    "subBlock19": "الوصول إلى معلوماتك الشخصية ومعلومات حول كيفية معالجتها.",
    "subBlock2": "محتوى المستخدم: عندما تستخدم خدماتنا، فإننا نجمع معلومات شخصية من مدخلاتك أو تحميلات الملفات أو التعليقات المقدمة لنا (يشار إليها باسم \"المحتوى\").",
    "subBlock20": "حذف معلوماتك الشخصية من سجلاتنا.",
    "subBlock21": "تصحيح معلوماتك الشخصية أو تحديثها.",
    "subBlock22": "نقل معلوماتك الشخصية إلى طرف ثالث (إمكانية نقل البيانات).",
    "subBlock23": "الحد من كيفية معالجتنا لمعلوماتك الشخصية.",
    "subBlock24": "سحب موافقتك - حيث نعتمد على الموافقة كأساس قانوني للمعالجة، يمكنك سحب موافقتك في أي وقت.",
    "subBlock25": "الاعتراض على كيفية معالجتنا لمعلوماتك الشخصية.",
    "subBlock26": "تحسين خدماتنا وإجراء البحوث؛",
    "subBlock3": "معلومات الاتصال: إذا أرسلت إلينا رسائل، فسنقوم بجمع اسمك ومعلومات الاتصال بك ومحتوى الرسائل التي ترسلها (يشار إليها باسم \"معلومات الاتصال\").",
    "subBlock4": "معلومات وسائل التواصل الاجتماعي: لدينا صفحات على مواقع التواصل الاجتماعي مثل Instagram وFacebook وMedium وTwitter وYouTube وLinkedIn. عندما تتفاعل مع صفحات التواصل الاجتماعي الخاصة بنا، فإننا نجمع المعلومات الشخصية التي تختار تقديمها إلينا، مثل بيانات الاتصال بك (يشار إليها باسم \"معلومات التواصل الاجتماعي\"). بالإضافة إلى ذلك، قد تزودنا الشركات التي تستضيف صفحات التواصل الاجتماعي الخاصة بنا بمعلومات مجمعة وتحليلات حول أنشطتنا على وسائل التواصل الاجتماعي.",
    "subBlock5": "بيانات السجل: المعلومات التي يرسلها متصفحك تلقائيًا عند استخدام خدماتنا. تتضمن بيانات السجل عنوان بروتوكول الإنترنت (IP) ونوع المتصفح وإعداداته وتاريخ ووقت طلباتك وكيفية تفاعلك مع موقعنا الإلكتروني.",
    "subBlock6": "بيانات الاستخدام: قد نقوم تلقائيًا بجمع معلومات حول استخدامك للخدمة، مثل أنواع المحتوى التي تعرضها أو تتفاعل معها، والميزات التي تستخدمها، والإجراءات التي تتخذها، بالإضافة إلى منطقتك الزمنية وبلدك وتاريخ ووقت الوصول ووكيل المستخدم والإصدار ونوع جهاز الكمبيوتر أو الهاتف المحمول واتصال الكمبيوتر الخاص بك.",
    "subBlock7": "معلومات الجهاز: تتضمن اسم الجهاز ونظام التشغيل ومعرّف الجهاز والمتصفح الذي تستخدمه. قد تعتمد المعلومات التي تم جمعها على نوع الجهاز الذي تستخدمه وإعداداته.",
    "subBlock8": "ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط لتشغيل وإدارة خدماتنا وتحسين تجربتك. \"ملف تعريف الارتباط\" هو معلومات يرسلها موقع ويب تزوره إلى متصفحك. يمكنك ضبط متصفحك لقبول جميع ملفات تعريف الارتباط أو رفض جميع ملفات تعريف الارتباط أو إخطارك عند تقديم ملف تعريف ارتباط حتى تتمكن من تحديد ما إذا كنت تريد قبوله في كل مرة. ومع ذلك، قد يؤدي رفض ملفات تعريف الارتباط إلى منعك من استخدام أو التأثير سلبًا على عرض أو وظائف مناطق أو ميزات معينة من موقع الويب. لمزيد من المعلومات حول ملفات تعريف الارتباط، يرجى زيارة All About Cookies.",
    "subBlock9": "التحليلات: قد نستخدم منتجات تحليلية متنوعة عبر الإنترنت تستخدم ملفات تعريف الارتباط لمساعدتنا في تحليل كيفية استخدام المستخدمين لخدماتنا وتحسين تجربتك عند استخدام الخدمات.",
    "subTitle1": "المعلومات الشخصية التي نجمعها",
    "subTitle2": "كيف نستخدم المعلومات الشخصية",
    "subTitle3": "الكشف عن المعلومات الشخصية",
    "subTitle4": "حقوقك",
    "subTitle5": "روابط لمواقع إلكترونية أخرى",
    "subTitle6": "أمان المعلومات والاحتفاظ بها",
    "subTitle7": "تعديل سياسة الخصوصية",
    "subTitle8": "كيف تتواصل معنا",
    "title": "سياسة الخصوصية",
    "updateTime": "تاريخ التحديث: 15 مارس 2024"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "قبول الكل",
    "text": "لتحسين تجربتك، نود استخدام ملفات تعريف الارتباط لتخصيص المحتوى والخدمات.",
    "title": "يستخدم هذا الموقع ملفات تعريف الارتباط"
  },
  "slogen": "مساعد الذكاء الاصطناعي الأسهل",
  "stripe": {
    "contact": "تواصل معنا على contact@noam.tools  إذا كانت لديك أي أسئلة!",
    "fail": {
      "btn": "إعادة المحاولة",
      "text1": "للأسف، لا يمكننا معالجة طلب الدفع الخاص بك.",
      "text2": "يرجى التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى.",
      "title": "فشل الدفع"
    },
    "succ": {
      "btn": "لنقم ببعض السحر",
      "text1": "مرحبًا بك في Noam، ",
      "text2": "لنبدأ معًا!",
      "title": "تهانينا"
    }
  },
  "terms": {
    "block1": "نشكرك على استخدامك Noam!",
    "block10": "(أ) الرسوم والفواتير. ستدفع جميع الرسوم (\"الرسوم\") وفقًا للتسعير والشروط الموضحة في صفحة التسعير المعمول بها أو كما هو متفق عليه كتابيًا بيننا. نحتفظ بالحق في تصحيح أخطاء التسعير أو الأخطاء حتى لو قمنا بإصدار فواتير أو تلقينا الدفع. ستقدم معلومات فوترة كاملة ودقيقة، بما في ذلك طريقة دفع صالحة ومصرح بها. سنقوم بخصم الرسوم من طريقة الدفع الخاصة بك بشكل دوري كما هو متفق عليه، ولكن يجوز لنا تغيير تاريخ إصدار الفاتورة بشكل معقول. أنت تفوض Noam والشركات التابعة لها ومعالجي الدفع الخارجيين بتحصيل الرسوم من طريقة الدفع الخاصة بك. في حالة فشل الدفع، سنقدم لك إشعارًا كتابيًا وقد نقوم بتعليق الوصول إلى الخدمة حتى يتم استلام الدفع. باستثناء ما هو محدد خلاف ذلك في هذه الاتفاقية، فإن المدفوعات غير قابلة للاسترداد.",
    "block11": "(ب) الضرائب. ما لم يُنص على خلاف ذلك، لا تشمل الرسوم أي ضرائب أو رسوم جمركية أو تقييمات مماثلة محلية أو فيدرالية أو حكومية أو أجنبية (\"الضرائب\"). أنت مسؤول عن جميع الضرائب المرتبطة بمشترياتك، باستثناء الضرائب المستندة إلى صافي دخلنا والتي سنقوم بإرسال فاتورة لك بها. أنت توافق على سداد هذه الضرائب على الفور وتزويدنا ببيانات خصوصية صالحة قانونًا والحصول على الموافقات اللازمة للتعامل مع هذه البيانات، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به.",
    "block12": "(ج) تغييرات الأسعار. يجوز لنا تغيير أسعارنا من خلال إخطارك عبر حسابك و/أو موقعنا الإلكتروني. ستصبح الزيادات في الأسعار سارية فور نشرها. ستنطبق أي تغييرات في الأسعار على الرسوم الخاصة بحسابك بعد تاريخ سريان التغيير.",
    "block13": "(د) النزاعات والمدفوعات المتأخرة. إذا كنت ترغب في الاعتراض على أي رسوم أو ضرائب، فيرجى الاتصال بـ contact@noam.tools في غضون ثلاثين (30) يومًا من تاريخ الفاتورة.",
    "block14": "(هـ) الاستخدام المجاني. لا يجوز لك إنشاء حسابات متعددة للحصول على أرصدة مجانية. إذا قررنا أنك لا تستخدم الأرصدة المجانية بحسن نية، فقد نقوم بتحصيل الرسوم القياسية منك أو إيقاف تقديم الخدمة لك.",
    "block15": "(أ) السرية. قد تتمكن من الوصول إلى معلومات سرية خاصة بـ Noam. يجوز لك استخدام المعلومات السرية فقط على النحو المسموح به بموجب هذه الشروط لغرض استخدام الخدمة. لا يجوز لك الكشف عن المعلومات السرية لأي طرف ثالث، وستحمي المعلومات السرية بطريقة لا تقل حماية عن الطريقة التي تحمي بها معلوماتك السرية المماثلة، على الأقل بعناية معقولة. المعلومات السرية هي معلومات غير عامة تم تصنيفها على أنها سرية بواسطة Noam أو التي يجب معاملتها بشكل معقول على أنها سرية في ظل هذه الظروف، بما في ذلك البرامج والمواصفات وغيرها من معلومات العمل غير العامة. لا تتضمن المعلومات السرية المعلومات التي: (1) تصبح متاحة للجمهور بشكل عام دون أي خطأ من جانبك؛ (2) كانت بحوزتك دون أي التزام بالسرية قبل استلامها بموجب هذه الشروط؛ (3) تم الكشف عنها لك بشكل قانوني بواسطة طرف ثالث دون أي التزامات بالسرية؛ أو (4) تم تطويرها بشكل مستقل من قبلك دون استخدام المعلومات السرية. يجوز لك الكشف عن المعلومات السرية إذا كان ذلك مطلوبًا بموجب القانون أو المحكمة أو أمر حكومي آخر، ولكن يجب عليك تقديم إشعار كتابي مسبق إلى Noam بطريقة معقولة، وبقدر الإمكان، بذل جهود معقولة للحد من نطاق الكشف، بما في ذلك مساعدتنا في معارضة طلبات الكشف.",
    "block16": "(ب) الأمان. يجب عليك تنفيذ تدابير معقولة ومناسبة للمساعدة في حماية وصولك إلى الخدمة واستخدامها. إذا اكتشفت أي ثغرات أمنية أو انتهاكات تتعلق باستخدامك للخدمة، فيجب عليك الاتصال بـ Noam على الفور وتقديم تفاصيل عن الثغرة الأمنية أو الانتهاك.",
    "block17": "((ج) معالجة البيانات الشخصية. إذا كنت تستخدم الخدمة لمعالجة البيانات الشخصية، فيجب عليك تقديم بيانات خصوصية كافية قانونًا والحصول على الموافقات اللازمة لمعالجة هذه البيانات، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به. )",
    "block18": "(أ) الإنهاء؛ التعليق. ستصبح هذه الشروط سارية المفعول من أول استخدام لك للخدمة وستظل سارية حتى يتم إنهاؤها. يجوز لك إنهاء هذه الشروط في أي وقت ولأي سبب من خلال التوقف عن استخدام الخدمة والمحتوى. يجوز لنا إنهاء هذه الشروط بإشعار مسبق لأي سبب من الأسباب. يجوز لنا إنهاء هذه الشروط على الفور من خلال إخطارك إذا انتهكت بشكل جوهري القسم 2 (متطلبات الاستخدام) أو القسم 5 (السرية والأمان وحماية البيانات) أو القسم 8 (حل النزاعات) أو القسم 9 (الشروط العامة)، أو إذا تغيرت علاقتنا مع أي مزود تكنولوجيا خارج عن إرادتنا، أو للامتثال للقانون أو الطلبات الحكومية. يجوز لنا تعليق وصولك إلى الخدمة إذا فشلت في الامتثال لهذه الشروط، أو إذا كان استخدامك يشكل خطرًا أمنيًا علينا أو على أي طرف ثالث، أو إذا اشتبهنا في أن استخدامك احتيالي أو قد يعرضنا أو يعرض أي طرف ثالث للمسؤولية.",
    "block19": "(ب) آثار الإنهاء. عند الإنهاء، ستتوقف عن استخدام الخدمة وستقوم على الفور بإعادة أو إتلاف أي معلومات سرية وفقًا لتوجيهاتنا. ستظل أحكام هذه الشروط التي يجب أن تظل سارية بعد الإنهاء أو انتهاء الصلاحية، بما في ذلك على سبيل المثال لا الحصر الأقسام 3 و5-9، سارية.",
    "block2": "تنطبق شروط الاستخدام هذه على استخدامك لخدمات Noam، بما في ذلك واجهات برمجة التطبيقات والبرامج والأدوات وخدمات المطوّرين والبيانات والوثائق والموقع الإلكتروني (يشار إليها مجتمعة باسم \"الخدمات\"). باستخدامك لخدماتنا، فإنك توافق على الالتزام بهذه الشروط. تشرح سياسة الخصوصية الخاصة بنا كيفية جمعنا للمعلومات الشخصية واستخدامها.",
    "block20": "(أ) التعويض. ستقوم بالدفاع عنا وتعويضنا وحمايتنا والشركات التابعة لنا وموظفينا من وضد أي مطالبات أو خسائر أو نفقات (بما في ذلك أتعاب المحاماة) تنشأ عن أو تتعلق باستخدامك للخدمة، بما في ذلك المحتوى الخاص بك، أو استخدامك للمنتجات أو الخدمات المتعلقة بالخدمة، وانتهاكك لهذه الشروط أو القانون المعمول به.",
    "block21": "(ب) إخلاء المسؤولية. يتم توفير الخدمة \"كما هي\". بالقدر الذي يسمح به القانون، لا نقدم نحن أو الشركات التابعة لنا أو المرخصون لنا أي إقرارات أو ضمانات من أي نوع فيما يتعلق بالخدمة ولا نقدم أي ضمانات، بما في ذلك على سبيل المثال لا الحصر ضمانات القابلية للتسويق أو الملاءمة لغرض معين أو الجودة المرضية أو عدم الانتهاك أو الهدوء أو أي ضمانات ناشئة عن التعامل أو استخدام التجارة. لا نضمن أن تكون الخدمة غير منقطعة أو دقيقة أو خالية من الأخطاء، أو أن أي محتوى سيكون آمنًا أو غير مفقود أو غير متغير.",
    "block22": "(ج) تحديد المسؤولية. لن نكون نحن أو الشركات التابعة لنا أو المرخصون لنا مسؤولين عن أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو تأديبية، بما في ذلك خسارة الأرباح أو الإضرار بالسمعة أو فقدان الاستخدام أو فقدان البيانات أو غيرها من الخسائر غير الملموسة، حتى لو تم إخطارنا بإمكانية حدوث مثل هذه الأضرار. بموجب هذه الشروط، لن تتجاوز مسؤوليتنا الإجمالية الرسوم التي دفعتها للخدمة في الأشهر الاثني عشر (12) التي تسبق المطالبة أو مائة دولار أمريكي (100 دولار أمريكي)، أيهما أكبر. تنطبق القيود الواردة في هذا القسم إلى أقصى حد يسمح به القانون المعمول به.",
    "block23": "تخضع الشروط واستخدامك للخدمات لقوانين الولايات المتحدة، باستثناء قواعد تنازع القوانين الأمريكية. قد يخضع استخدامك للتطبيق أيضًا لقوانين محلية أو حكومية أو وطنية أو دولية أخرى. سيتم رفع أي دعوى قضائية أو إجراء قانوني يتعلق بهذه الشروط حصريًا في محاكم الولايات المتحدة، وأنت توافق على الخضوع للاختصاص القضائي الشخصي لهذه المحاكم.",
    "block24": "(أ) علاقة الأطراف. إن Noam وأنت متعاقدان مستقلان، وليس لأي من الطرفين سلطة تمثيل أو إلزام الطرف الآخر أو تحمل التزامات نيابة عن الطرف الآخر دون موافقة كتابية مسبقة من الطرف الآخر.",
    "block25": "(ب) استخدام العلامة التجارية. لا يجوز لك استخدام اسم أو شعارات أو علامات تجارية Noam أو أي من الشركات التابعة لها دون موافقتنا الكتابية المسبقة.",
    "block26": "إذا تم تحديد أن أي حكم من أحكام هذه الشروط غير صالح أو غير قانوني أو غير قابل للتنفيذ، فستظل الأحكام المتبقية سارية المفعول والتأثير الكامل.",
    "block3": "إذا كان عمرك أقل من 18 عامًا، فيجب الحصول على إذن من والديك أو الأوصياء القانونيين عليك لاستخدام الخدمة. إذا كنت تستخدم الخدمة نيابةً عن شخص آخر أو كيان آخر، فيجب أن تكون مخولًا لقبول هذه الشروط نيابةً عنه. يجب عليك تقديم معلومات دقيقة وكاملة للتسجيل للحصول على حساب. لا يجوز لك تقديم بيانات اعتماد الوصول أو حسابك لأي شخص خارج مؤسستك، وأنت مسؤول عن جميع الأنشطة التي تتم باستخدام بيانات اعتمادك.",
    "block4": "(أ) استخدام الخدمة. يجوز لك الوصول إلى الخدمة بموجب هذه الشروط، ونمنحك حقًا غير حصري لاستخدام الخدمة. عند استخدام الخدمة، ستلتزم بهذه الشروط وجميع القوانين المعمول بها. نحن والشركات التابعة لنا نمتلك جميع الحقوق والعناوين والمصالح في الخدمة.",
    "block5": "(ب) الملاحظات. نرحب بالتعليقات والملاحظات والأفكار والاقتراحات والتحسينات. إذا قمت بتقديم أي محتوى من هذا القبيل، فيجوز لنا استخدامه دون أي قيود ودون أي تعويض لك.",
    "block6": "(ج) القيود. لا يجوز لك: (1) استخدام الخدمة بطريقة تنتهك أو تختلس أو تنتهك أي حقوق للآخرين؛ (2) إجراء الهندسة العكسية أو فك تجميع أو تفكيك أو ترجمة أو محاولة اكتشاف التعليمات البرمجية المصدرية أو المكونات الأساسية لنماذج الخدمة والخوارزميات والأنظمة (ما لم تكن هذه القيود محظورة بموجب القانون المعمول به)؛ (3) استخدام ناتج الخدمة لتطوير نماذج تتنافس مع Noam؛ (4) استخراج البيانات أو المخرجات من الخدمة باستخدام أي أساليب آلية أو برمجة ما لم يُسمح بذلك من خلال واجهة برمجة التطبيقات، بما في ذلك كشط الويب أو حصاد الويب أو استخراج بيانات الويب؛ (5) تمثيل ناتج الخدمة على أنه تم إنشاؤه صناعيًا في حين أنه ليس كذلك، أو انتهاك سياسات الاستخدام الخاصة بنا بطريقة أخرى؛ (6) شراء أو بيع أو نقل مفاتيح واجهة برمجة التطبيقات دون موافقتنا المسبقة؛ أو (7) تزويدنا بأي معلومات شخصية عن أطفال تقل أعمارهم عن 13 عامًا أو العمر المعمول به للموافقة الرقمية. ستلتزم بأي حدود للمعدلات والمتطلبات الأخرى في وثائقنا.",
    "block7": "(د) خدمات الطرف الثالث. تخضع أي برامج أو خدمات أو منتجات أخرى تابعة لجهات خارجية مرتبطة بالخدمة لشروطها الخاصة، ونحن لسنا مسؤولين عن منتجات الطرف الثالث.",
    "block8": "(أ) المحتوى الخاص بك. يجوز لك تقديم مُدخلات (\"المُدخلات\") إلى الخدمة وتلقي مُخرجات (\"المُخرجات\") التي تم إنشاؤها وإرجاعها بناءً على المُدخلات (يشار إليها مجتمعة باسم \"المحتوى\"). فيما بين الطرفين، وبالقدر الذي يسمح به القانون المعمول به، فإنك تمتلك جميع المُدخلات. يجوز لـ Noam استخدام المحتوى لتقديم الخدمة والحفاظ عليها والامتثال للقوانين المعمول بها وتنفيذ سياساتنا. أنت مسؤول عن المحتوى، بما في ذلك ضمان عدم انتهاكه لأي قوانين معمول بها أو هذه الشروط.",
    "block9": "(ب) الدقة. إن الذكاء الاصطناعي والتعلم الآلي مجالان من مجالات البحث سريعة التطور. نسعى باستمرار لتحسين خدمتنا لجعلها أكثر دقة وموثوقية وأمانًا وفائدة. نظرًا للطبيعة الاحتمالية للتعلم الآلي، قد يؤدي استخدام خدمتنا إلى الحصول على مُخرجات غير صحيحة في حالات معينة، والتي قد لا تعكس بدقة الأفراد أو الأماكن أو الحقائق الحقيقية. يجب عليك تقييم دقة أي مُخرجات بشكل مناسب بناءً على حالة الاستخدام الخاصة بك، بما في ذلك من خلال المراجعة اليدوية للمُخرجات.",
    "subTitle1": "التسجيل والوصول",
    "subTitle10": "قابلية الفصل",
    "subTitle2": "متطلبات الاستخدام",
    "subTitle3": "المحتوى",
    "subTitle4": "الرسوم والدفع",
    "subTitle5": " السرية والأمان وحماية البيانات",
    "subTitle6": "المدة وإنهاء الخدمة",
    "subTitle7": "التعويض؛ إخلاء المسؤولية؛ تحديد المسؤولية",
    "subTitle8": "القانون الحاكم والاختصاص القضائي",
    "subTitle9": "الشروط العامة",
    "title": "شروط الخدمة",
    "updateTime": "تم التحديث في: 15 مارس 2024"
  },
  "trialModal": {
    "btn": "ابدأ الآن（فقط 0.99 دولارًا أمريكيًا）",
    "chat": "دردشة PDF",
    "desc1": "بعد انتهاء الفترة التجريبية، سيتم تحصيل رسوم سنوية قدرها 180 دولارًا أمريكيًا",
    "desc2": "يمكنك إلغاء الاشتراك في أي وقت",
    "focus": "التركيز",
    "hover": "الترجمة بالمرور",
    "image": "ترجمة الصور",
    "installBtn": "ثبّت الآن لاستلام هديتك",
    "memo": "إدارة المفضلة",
    "pdf": "الترجمة المتوازية لملفات PDF",
    "realtime": "الترجمة الفورية",
    "select": "ترجمة اختيار الكلمات",
    "title": "هدية ترحيبية",
    "trialText": "إصدار تجريبي لمدة 7 أيام",
    "video": "ترجمة الفيديو",
    "videoSummary": "تلخيص الفيديو",
    "web": "الترجمة المتوازية",
    "webSummary": "تلخيص الويب"
  },
  "upgradeModal": {
    "btn": "ترقية الآن",
    "chat": {
      "free": "لقد وصلت إلى الحد الأقصى لـ Chatpdf",
      "pro": "لقد وصلت إلى الحد الأقصى لـ Chatpdf لهذا الشهر"
    },
    "contactStr": "يرجى انتظار إعادة التعيين التالية أو الاتصال بنا على contact@noam.tools.",
    "limitAlertStr": "لقد تم استخدام حصة خطتك المجانية بالكامل، نوصيك بالترقية إلى خطة أخرى لمواصلة التجربة~! ",
    "more": "خطط أخرى",
    "pageLimit": {
      "free": "لقد وصلت إلى الحد الأقصى لصفحات PDF",
      "pro": "لقد وصلت إلى الحد الأقصى لصفحات PDF لهذا الشهر"
    },
    "title": "تذكير",
    "upgradeAlertStr": "لقد تم استخدام حصة خطتك المجانية بالكامل، نوصيك بالترقية إلى خطة أخرى لمواصلة التجربة~! ",
    "upload": {
      "free": "لقد وصلت إلى الحد الأقصى لترجمة ملفات PDF",
      "pro": "لقد وصلت إلى الحد الأقصى لترجمة ملفات PDF لهذا الشهر"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "انضم الآن",
      "label": "مزايا المستخدم الجديد:",
      "remain": "30 متبقية فقط",
      "text": "متوفر 100 مكان فقط! احصل على مكانك الآن!"
    }
  }
}

export default TRANSLATION
