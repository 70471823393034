const TRANSLATION = {
  slogen: 'Najłatwiejszy asystent AI',
  pageHeader: {
    help: 'Centrum pomocy',
    contact: 'Skontaktuj się z nami',
    pricing: 'Wycena',
    signUp: 'Zaloguj się',
    settings: 'Ustawienia',
    logout: 'Wyloguj się',
    logoutSucc: 'Wylogowanie powiodło się',
  },
  pageFooter: {
    product: {
      title: 'Produkty',
      web: 'Tłumaczenie internetowe i podsumowanie',
      pdf: 'Tłumaczenie PDF',
      video: 'Tłumaczenie i podsumowanie wideo',
      image: 'Tłumaczenie obrazu',
    },
    resources: { title: 'Zasoby' },
    follow: { title: 'Podążać', twitter: 'Świergot', facebook: 'Facebooku' },
    legal: { title: 'Prawny', policy: 'Polityka prywatności', terms: 'Warunki świadczenia usług' },
  },
  home: {
    getStart: 'Rozpocznij',
    getStartedForFree: 'Rozpocznij bezpłatnie',
    top: {
      desc: 'Noam to bezpłatne rozszerzenie przeglądarki AI, które pomaga w tłumaczeniu podsumowań stron internetowych, filmów i treści PDF.\n      Dołącz do Noama i ciesz się magią!',
      title2: 'Jedna magia',
      title1: 'Jedno kliknięcie',
    },
    howItWork: {
      title: 'A więc jak to działa',
      desc: 'Noam integruje modele sztucznej inteligencji, aby zapewnić użytkownikom inteligentne doświadczenie w zakresie tłumaczenia i podsumowań stron internetowych w wielu językach, tłumaczenia plików PDF i obrazów, tłumaczenia i podsumowań wideo',
    },
    PDFTranslate: {
      title: 'Tłumacz PDF',
      desc: 'Tłumaczenie dwujęzyczne + Czat PDF',
      sub1: {
        title: 'Czytanie dwujęzyczne',
        desc: 'Czytanie dwujęzyczne, dzięki czemu możesz porównać tekst oryginalny i tłumaczenie podczas czytania, aby poprawić efektywność czytania',
      },
      sub2: {
        title: 'Czat w formacie PDF',
        desc: 'AI analizuje i podsumowuje oraz może odpowiedzieć na Twoje pytania w oparciu o zawartość pliku PDF',
      },
      sub3: {
        title: 'Dokładność i wydajność',
        desc: 'Pliki PDF zasilane sztuczną inteligencją zapewniają dokładniejsze i wydajniejsze tłumaczenia',
      },
    },
    aiAssistant: {
      title: 'Asystent AI',
      desc: 'Dzięki czemu tłumaczenie internetowe jest mądrzejsze i wygodniejsze',
      panel1: {
        title: 'Tłumaczenie dwujęzyczne',
        desc: 'Dwujęzyczne tłumaczenia porównawcze pomagają porównać tekst oryginalny z tłumaczeniem, dzięki czemu możesz lepiej zrozumieć i poprawić efektywność czytania.',
      },
      panel2: {
        left: {
          title: 'Tłumaczenie kursora',
          desc: 'Przeglądając stronę internetową, najedź myszką bezpośrednio na treść, która ma zostać przetłumaczona, aby uzyskać wynik tłumaczenia.',
        },
        right: {
          title: 'Tłumaczenie wyboru tekstu',
          desc: 'Podczas przeglądania strony internetowej bezpośrednio zaznacz tekst, który ma zostać podkreślony, aby uzyskać wynik tłumaczenia.',
        },
      },
      panel3: {
        one: {
          title: 'Wskaż i kliknij',
          desc: 'Tłumacz i podsumowuj strony internetowe i treści wideo w dowolnym miejscu i czasie, w dowolnym scenariuszu; zapewniając płynniejsze wrażenia!',
        },
        two: {
          title: 'Bardziej wszechstronne i wygodne',
          desc: 'Pełne pokrycie w wielu językach, dwujęzyczne czytanie bez barier, tłumaczenie jednym klawiszem; zapewnią Ci niezrównane wrażenia!',
        },
        three: {
          title: 'Oparte na sztucznej inteligencji',
          desc: 'Duży model oparty na sztucznej inteligencji zapewniający dokładność i spójność tłumaczenia oraz poprawiający komfort czytania',
        },
      },
      panel4: {
        tab1: {
          key: 'Wideo',
          title: 'Tłumaczenie i podsumowanie wideo',
          desc: 'Twój ekspert ds. wydajności w połączeniu z chatgpt tłumaczy i podsumowuje treści wideo YouTube, aby zaoszczędzić czas na czytaniu!',
        },
        tab2: {
          key: 'Obraz',
          title: 'Tłumaczenie obrazu',
          desc: 'Kliknij, aby przetłumaczyć obrazy podczas przeglądania Internetu',
        },
        tab3: {
          key: 'Sieć',
          title: 'Tłumaczenie internetowe i podsumowanie',
          desc: 'Szybkie wyodrębnianie kluczowych informacji ze stron internetowych w celu podsumowania, zwiększając efektywność czytania',
        },
        tag1: 'Prosty',
        tag2: 'Efektywność',
        tag3: 'Łatwo zrozumiałe',
      },
    },
    userReviews: {
      title: 'Recenzje użytkowników',
      desc: 'Pięciogwiazdkowa recenzja w sklepie chromewebstore',
    },
    lastBlock: { desc: 'Lepsze tłumaczenie AI i płynniejsze czytanie' },
  },
  login: {
    title: 'Witamy w Noamie',
    btn: 'Zaloguj się za pomocą Google',
    tips: 'Rejestrując się, wyrażam zgodę',
    terms: 'Warunki użytkowania',
    and: 'I',
    policy: 'Polityka prywatności',
    btn1: 'Zaloguj się teraz',
    desc: 'Zaloguj się, aby odblokować więcej wrażeń',
  },
  pricing: {
    pageTitle: 'Wycena',
    desc: 'Porównaj i wybierz najlepsze dla siebie',
    yearly: 'Rocznie',
    monthly: 'Miesięczny',
    discount: '20% zniżki',
    preMonth: 'Na miesiąc',
    billedYearly: 'Rozliczane rocznie',
    billedMonthly: 'Rozliczane miesięcznie',
    detailedCompare: 'Szczegółowe porównanie',
    FAQ: 'Często zadawane pytania',
    day: 'dzień',
    month: 'miesiąc',
    around: 'Wokół',
    free: {
      benefit1: 'Natychmiastowe tłumaczenie 100 razy dziennie',
      benefit2: 'Tłumaczenie internetowe i podsumowanie',
      benefit3: '30 arkuszy Tłumaczenie obrazu',
      benefit4: 'Tłumaczenie i podsumowanie wideo',
      benefit5: '10 Tłumaczenie PDF',
      benefit6: '5000 żetonów',
      recommendText: 'Bezpłatny okres próbny',
      btn: 'Miłej zabawy',
    },
    pro: {
      benefit1: 'Szybkie wsparcie obsługi klienta',
      benefit2: '1 000 000 tokenów/miesiąc',
      benefit3: 'Tłumaczenie internetowe i podsumowanie',
      benefit4: '200 arkuszy Tłumaczenie obrazu',
      benefit5: 'Tłumaczenie i podsumowanie wideo',
      benefit6: '200 tłumaczeń PDF',
      btn: 'Rozpocznij',
      recommendText: 'Większość ludzi wybiera',
    },
    proPlus: {
      benefit1: 'Szybkie wsparcie obsługi klienta',
      benefit2: '3 000 000 tokenów/miesiąc',
      benefit3: 'Tłumaczenie internetowe i podsumowanie',
      benefit4: '500 arkuszy Tłumaczenie obrazu',
      benefit5: 'Tłumaczenie i podsumowanie wideo',
      benefit6: 'Tłumaczenie 500 plików PDF',
      recommendText: 'Najbardziej odpowiedni wybór',
    },
    enterprise: {
      benefit1: 'Szybkie wsparcie obsługi klienta',
      benefit2: 'Wielu użytkowników',
      benefit3: 'Indywidualne rozwiązania',
      benefit5: 'Ekskluzywny menedżer konta',
      recommendText: 'Plany niestandardowe (≥3人)',
      talkToUs: 'Porozmawiaj z nami',
      btn: 'Kontakt',
    },
    web: {
      contrastiveTranslation: 'Tłumaczenie dwujęzyczne',
      instantTranslation: 'Natychmiastowe tłumaczenie',
      hoverTranslation: 'Tłumaczenie kursora',
      dictionaryTranslation: 'Tłumaczenie wyboru tekstu',
      summary: 'Podsumowanie strony internetowej',
    },
    imageTranslation: 'Tłumaczenie obrazu',
    video: { translation: 'Tłumaczenie wideo', summary: 'Podsumowanie wideo' },
    pdf: {
      translation: 'Tłumaczenie PDF',
      maxPage: 'Maksymalna liczba stron w formacie PDF',
      chat: 'Liczba zapytań w formacie PDF',
    },
    FQAObj: {
      q1: 'Czy mogę z niego korzystać bezpłatnie?',
      a1: 'Istnieje darmowa wersja Noama, z której można korzystać bezpośrednio.',
      q2: 'Czy istnieją różne plany członkostwa i opcje opłat?',
      a2: 'Oprócz wersji bezpłatnej dostępne są trzy plany członkostwa: Pro, Pro+ i Enterprise; możesz wybierać pomiędzy dwiema metodami płatności, ciągłą miesięczną i ciągłą roczną, a całkowity koszt ciągłego rocznego jest korzystniejszy.',
      q3: 'Jaka jest różnica pomiędzy ciągłą subskrypcją miesięczną a ciągłą subskrypcją roczną?',
      a3: 'Nie ma różnicy w funkcjonalności. Całkowity koszt ciągłej subskrypcji rocznej jest niższy niż w przypadku ciągłej subskrypcji miesięcznej; subskrypcja miesięczna jest rozliczana raz w miesiącu, natomiast subskrypcja roczna jest rozliczana raz w roku.',
      q4: 'Jak anulować plan automatycznego odnawiania?',
      a4: 'Możesz przejść do strony ustawień zaplecza i wybrać subskrypcję, aby anulować plan odnowienia.',
    },
  },
  upgradeModal: {
    title: 'Przypomnienia',
    limitAlertStr:
      'Twój limit bezpłatnego planu został wyczerpany. Zalecamy uaktualnienie planu, aby kontynuować korzystanie z usługi ~!',
    upgradeAlertStr:
      'Twój limit bezpłatnego planu został wyczerpany. Zalecamy uaktualnienie planu, aby kontynuować korzystanie z usługi ~!',
    btn: 'Uaktualnij teraz',
    more: 'Więcej planów',
    contactStr:
      'Poczekaj na następny reset lub skontaktuj się z nami pod adresem contact@noam.tools.',
    upload: {
      free: 'Osiągnąłeś limit tłumaczenia PDF',
      pro: 'Osiągnąłeś limit tłumaczeń PDF w tym miesiącu',
    },
    chat: {
      free: 'Osiągnąłeś limit przekroczony w Chatpdf',
      pro: 'Osiągnąłeś limit Chatpdf w tym miesiącu',
    },
    pageLimit: { free: 'Osiągnąłeś limit przekroczony limit stron PDF' },
  },
  buyModal: {
    title: 'Abonament',
    freeTrial: '3-dniowy bezpłatny okres próbny',
    selectLabel: 'Wybierz plan',
    btn: 'Wypróbuj za darmo',
    yearLabel: 'rok',
    monthLabel: 'miesiąc',
    afterFree: 'Po rozprawie',
  },
  privacy: {
    title: 'Polityka prywatności',
    updateTime: 'Aktualizacja: 15 marca 2024 r',
    block1:
      'My, Noam (określani jako „Noam”, „my”, „nasz” lub „nas”), szanujemy Twoją prywatność i zobowiązujemy się chronić wszelkie informacje, które uzyskujemy od Ciebie lub na Twój temat. Niniejsza Polityka prywatności opisuje nasze praktyki dotyczące gromadzenia lub wykorzystywania danych osobowych podczas korzystania z naszej witryny internetowej, aplikacji i usług (zwanych łącznie „Usługami”). Niniejsza Polityka prywatności nie ma zastosowania do treści, którymi zarządzamy w imieniu naszych klientów korzystających z produktów biznesowych, takich jak nasze API. Wykorzystanie tych danych podlega naszej umowie z klientem, która obejmuje dostęp do tych produktów i korzystanie z nich.',
    subTitle1: 'Dane osobowe, które gromadzimy',
    block2: 'Gromadzimy następujące dane osobowe („Dane osobowe”) dotyczące Ciebie:',
    block3:
      'Informacje podawane przez Ciebie: Jeśli utworzysz konto, aby korzystać z naszych usług lub komunikować się z nami, będziemy zbierać dane osobowe w następujący sposób:',
    subBlock1:
      'Informacje o koncie: Kiedy tworzysz konto na naszej platformie, zbieramy informacje związane z Twoim kontem, w tym Twoje imię i nazwisko, dane kontaktowe, dane uwierzytelniające do konta, dane karty płatniczej i historię transakcji (zwane „Informacjami o koncie”).',
    subBlock2:
      'Treści użytkownika: Kiedy korzystasz z naszych usług, zbieramy dane osobowe na podstawie Twoich danych wejściowych, przesłanych plików lub przekazanych nam opinii (zwanych „Treścią”).',
    subBlock3:
      'Informacje komunikacyjne: Jeśli wysyłasz do nas wiadomości, zbieramy Twoje imię i nazwisko, dane kontaktowe oraz treść wysyłanych wiadomości (zwane „Informacjami komunikacyjnymi”).',
    subBlock4:
      'Informacje o mediach społecznościowych: Mamy strony w serwisach społecznościowych, takich jak Instagram, Facebook, Medium, Twitter, YouTube i LinkedIn. Kiedy wchodzisz w interakcję z naszymi stronami w mediach społecznościowych, zbieramy dane osobowe, które nam przekazujesz, takie jak dane kontaktowe (zwane „Danymi społecznościowymi”). Ponadto firmy hostujące nasze strony w mediach społecznościowych mogą dostarczać nam zbiorcze informacje i analizy dotyczące naszych działań w mediach społecznościowych.',
    block4:
      'Dane osobowe otrzymywane automatycznie w wyniku korzystania przez Ciebie z usług: Kiedy uzyskujesz dostęp do usług, korzystasz z nich lub wchodzisz z nimi w interakcję, otrzymujemy następujące informacje o Twoim dostępie, użytkowaniu lub interakcji („Informacje techniczne”):',
    subBlock5:
      'Dane dziennika: Informacje automatycznie wysyłane przez Twoją przeglądarkę podczas korzystania z naszych usług. Dane dziennika obejmują adres protokołu internetowego (IP), typ i ustawienia przeglądarki, datę i godzinę Twoich żądań oraz sposób interakcji z naszą witryną.',
    subBlock6:
      'Dane o użytkowaniu: Możemy automatycznie zbierać informacje o korzystaniu przez Ciebie z usługi, takie jak rodzaje treści, które przeglądasz lub z którymi wchodzisz w interakcję, funkcje, z których korzystasz i podejmowane przez Ciebie działania, a także Twoja strefa czasowa, kraj, data i godzina dostęp, agent użytkownika i wersja, typ komputera lub urządzenia mobilnego oraz połączenie z komputerem.',
    subBlock7:
      'Informacje o urządzeniu: obejmują nazwę urządzenia, system operacyjny, identyfikator urządzenia i używaną przeglądarkę. Gromadzone informacje mogą zależeć od rodzaju urządzenia, z którego korzystasz i jego ustawień.',
    subBlock8:
      'Pliki cookie: Używamy plików cookie do obsługi i zarządzania naszymi usługami oraz poprawy komfortu korzystania z nich. „Cookie” to informacja wysyłana przez odwiedzaną przez Ciebie stronę internetową do Twojej przeglądarki. Możesz ustawić swoją przeglądarkę tak, aby akceptowała wszystkie pliki cookie, odrzucała wszystkie pliki cookie lub powiadamiała Cię o udostępnieniu pliku cookie, abyś mógł za każdym razem zdecydować, czy go zaakceptować. Jednakże odrzucenie plików cookie może uniemożliwić korzystanie z niektórych obszarów lub funkcji witryny internetowej lub negatywnie wpłynąć na wyświetlanie lub funkcjonalność niektórych obszarów lub funkcji witryny. Więcej informacji na temat plików cookie można znaleźć na stronie Wszystko o plikach cookie.',
    subBlock9:
      'Analityka: Możemy korzystać z różnych produktów do analityki internetowej, które wykorzystują pliki cookie, aby pomóc nam analizować, w jaki sposób użytkownicy korzystają z naszych usług i poprawiać komfort korzystania z usług.',
    subTitle2: 'Jak wykorzystujemy dane osobowe',
    block5: 'Możemy wykorzystywać dane osobowe w następujących celach:',
    subBlock10: 'Świadczenie, zarządzanie, utrzymywanie i/lub analizowanie usług;',
    subBlock26: 'Udoskonalanie naszych usług i prowadzenie badań;',
    subBlock11: 'Komunikowanie się z Tobą;',
    subBlock12: 'Opracowywanie nowych projektów i usług;',
    subBlock13:
      'Zapobieganie oszustwom, działalności przestępczej lub niewłaściwemu korzystaniu z naszych usług oraz ochrona bezpieczeństwa naszych systemów informatycznych, architektury i sieci;',
    subBlock14: 'Przeprowadzanie transferów biznesowych;',
    subBlock15:
      'Przestrzeganie obowiązków prawnych i postępowań prawnych, ochrona naszych praw, prywatności, bezpieczeństwa lub własności, a także praw naszych podmiotów stowarzyszonych, Ciebie lub innych stron trzecich.',
    block6:
      'Informacje podsumowujące lub pozbawione danych identyfikacyjnych: Możemy agregować lub usuwać dane osobowe, aby zapobiec ich ponownemu wykorzystaniu do celów identyfikacyjnych i wykorzystywać takie informacje do analizy skuteczności naszych usług, ulepszania i dodawania funkcji do naszych usług, prowadzenia badań i do podobnych celów . Ponadto możemy analizować ogólne zachowanie i cechy użytkowników naszych usług oraz udostępniać zbiorcze informacje stronom trzecim, publikować takie zbiorcze informacje lub udostępniać je powszechnie. Możemy gromadzić informacje zbiorcze za pośrednictwem usług, plików cookie i innych środków opisanych w niniejszej Polityce prywatności. Będziemy przechowywać i wykorzystywać dane pozbawione cech identyfikacyjnych w formie anonimowej lub pozbawionej możliwości identyfikacji i nie będziemy podejmować prób ponownej identyfikacji takich informacji, chyba że będzie to wymagane przez prawo.',
    subTitle3: 'Ujawnianie danych osobowych',
    block7:
      'W niektórych przypadkach możemy udostępniać Twoje dane osobowe stronom trzecim bez dodatkowego powiadomienia, chyba że jest to wymagane przez prawo:',
    subBlock16:
      'Dostawcy i usługodawcy: Aby pomóc nam w zaspokajaniu potrzeb operacyjnych firmy oraz wykonywaniu określonych usług i funkcji, możemy udostępniać dane osobowe dostawcom i usługodawcom, w tym dostawcom usług hostingowych, dostawcom usług w chmurze i innym dostawcom usług technologii informatycznych, oprogramowaniu do komunikacji e-mailowej oraz dostawcy usług analityki internetowej itp. Partnerzy ci będą uzyskiwać dostęp do danych osobowych, je przetwarzać i przechowywać wyłącznie na nasze polecenie podczas wykonywania swoich obowiązków dla nas.',
    subBlock17:
      'Przeniesienie działalności: Jeśli angażujemy się w strategiczne transakcje, reorganizacje, bankructwa, przejęcia upadłościowe lub przeniesienie usług do innego dostawcy (zwane łącznie „Transakcjami”), Twoje dane osobowe i inne informacje mogą zostać udostępnione kontrahentom transakcji i innym stronom pomagającym w Transakcji podczas badania due diligence i przekazane w ramach Transakcji wraz z innymi aktywami następcy lub spółce stowarzyszonej.',
    subBlock18:
      'Wymogi prawne: Możemy udostępniać Twoje dane osobowe organom rządowym, partnerom z branży lub innym stronom trzecim, w tym informacje o Twoich interakcjach z naszymi usługami, (1) jeśli jest to wymagane przez prawo lub jeśli uważamy, że takie działanie jest konieczne w celu wywiązania się z obowiązków prawnych; (2) w celu ochrony i obrony naszych praw lub własności; (3) jeśli jednostronnie stwierdzimy, że nastąpiło naruszenie naszych warunków, zasad lub przepisów; (4) w celu wykrywania lub zapobiegania oszustwom lub innym nielegalnym działaniom; (5) w celu ochrony naszych produktów, pracowników lub użytkowników lub bezpieczeństwa publicznego, ochrony i integralności; lub (vi) w celu obrony przed odpowiedzialnością prawną.',
    subTitle4: 'Twoje prawa',
    block8:
      'W zależności od Twojej lokalizacji geograficznej osobom w Europejskim Obszarze Gospodarczym, Wielkiej Brytanii i na całym świecie mogą przysługiwać pewne ustawowe prawa dotyczące ich danych osobowych. Na przykład możesz mieć prawo do:',
    subBlock19:
      'Uzyskaj dostęp do swoich danych osobowych i informacji o sposobie ich przetwarzania.',
    subBlock20: 'Usuń swoje dane osobowe z naszych rejestrów.',
    subBlock21: 'Popraw lub zaktualizuj swoje dane osobowe.',
    subBlock22: 'Prześlij swoje dane osobowe stronie trzeciej (przenoszenie danych).',
    subBlock23: 'Ogranicz sposób, w jaki przetwarzamy Twoje dane osobowe.',
    subBlock24:
      'Wycofać swoją zgodę – jeżeli podstawą prawną przetwarzania jest zgoda, możesz ją wycofać w dowolnym momencie.',
    subBlock25: 'Sprzeciw wobec sposobu, w jaki przetwarzamy Twoje dane osobowe.',
    subTitle5: 'Linki do innych stron internetowych',
    block9:
      'Usługa może zawierać łącza do innych stron internetowych, które nie są obsługiwane ani kontrolowane przez firmę Noam, w tym do serwisów społecznościowych („Strony internetowe osób trzecich”). Informacje, które udostępniasz witrynom stron trzecich, będą podlegać szczegółowym zasadom ochrony prywatności i warunkom korzystania z witryn stron trzecich, a nie niniejszej Polityce prywatności. Linki te udostępniamy dla wygody i nie oznacza to naszego poparcia ani recenzji tych witryn. Aby uzyskać informacje na temat ich polityki prywatności i warunków, należy skontaktować się bezpośrednio z witrynami stron trzecich.',
    subTitle6: 'Bezpieczeństwo i przechowywanie informacji',
    block10:
      'Wdrażamy uzasadnione z handlowego punktu widzenia środki techniczne, administracyjne i organizacyjne w celu ochrony danych osobowych w Internecie i poza nim przed utratą, niewłaściwym wykorzystaniem i nieuprawnionym dostępem, ujawnieniem, zmianą lub wrogimi działaniami. Żadna transmisja internetowa ani e-mailowa nie gwarantuje jednak całkowitego bezpieczeństwa ani dokładności. W szczególności wiadomości e-mail wysyłane do nas lub od nas mogą nie być bezpieczne. Dlatego też należy zachować szczególną ostrożność przy podejmowaniu decyzji, jakie informacje przesłać nam za pośrednictwem serwisu lub poczty elektronicznej. Ponadto nie ponosimy odpowiedzialności za jakiekolwiek utrudnianie ustawień prywatności lub środków bezpieczeństwa w serwisie lub ustawieniach bezpieczeństwa lub środkach bezpieczeństwa Stron Trzecich. Będziemy przechowywać Twoje dane osobowe tak długo, jak będzie to konieczne, abyśmy mogli świadczyć Ci usługi lub w innych uzasadnionych celach biznesowych, takich jak rozwiązywanie sporów, względy bezpieczeństwa lub wywiązywanie się z naszych zobowiązań prawnych. Długość czasu, przez jaki przechowujemy dane osobowe, będzie zależeć od wielu czynników, takich jak ilość, charakter, wrażliwość informacji, potencjalne ryzyko nieuprawnionego użycia lub ujawnienia, cele, dla których przetwarzamy informacje, oraz inne prawnie wiążące względy.',
    subTitle7: 'Modyfikacja Polityki Prywatności',
    block11:
      'Od czasu do czasu możemy aktualizować niniejszą Politykę prywatności. Gdy to zrobimy, opublikujemy zaktualizowaną wersję na tej stronie, chyba że obowiązujące prawo stanowi inaczej.',
    subTitle8: 'Jak się z nami skontaktować',
    block12:
      'Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące niniejszej Polityki prywatności, na które nie odpowiedziałeś, skontaktuj się z naszym działem obsługi klienta. E-mail:',
  },
  terms: {
    title: 'Warunki świadczenia usług',
    updateTime: 'Aktualizacja:15 marca 2024 r',
    block1: 'Dziękujemy za korzystanie z Noama!',
    block2:
      'Niniejsze Warunki użytkowania mają zastosowanie do korzystania przez Ciebie z usług Noam, w tym z naszych interfejsów programowania aplikacji, oprogramowania, narzędzi, usług programistycznych, danych, dokumentacji i strony internetowej (zwanych łącznie „Usługami”). Korzystając z naszych Usług, wyrażasz zgodę na przestrzeganie niniejszych warunków. Nasza Polityka prywatności wyjaśnia, w jaki sposób zbieramy i wykorzystujemy dane osobowe.',
    subTitle1: 'Rejestracja i dostęp',
    block3:
      'Jeśli nie masz ukończonych 18 lat, musisz uzyskać zgodę rodziców lub opiekunów prawnych na korzystanie z Usługi. Jeśli korzystasz z Usługi w imieniu innej osoby lub podmiotu, musisz być upoważniony do zaakceptowania niniejszych warunków w jej imieniu. Aby zarejestrować konto, musisz podać dokładne i pełne dane. Nie możesz udostępniać swoich danych dostępu ani konta nikomu spoza swojej organizacji i jesteś odpowiedzialny za wszystkie działania prowadzone przy użyciu Twoich danych uwierzytelniających.',
    subTitle2: 'Wymagania dotyczące użytkowania',
    block4:
      '(a) Korzystanie z Usługi. Możesz uzyskać dostęp do Usługi na niniejszych warunkach, a my udzielamy Ci niewyłącznego prawa do korzystania z Usługi. Korzystając z Usługi, będziesz przestrzegać niniejszego Regulaminu oraz wszystkich obowiązujących przepisów. Zarówno my, jak i nasze podmioty stowarzyszone posiadamy wszelkie prawa, tytuły i udziały w Usłudze.',
    block5:
      '(b) Informacje zwrotne. Czekamy na opinie, komentarze, pomysły, sugestie i ulepszenia. Jeśli udostępnisz taką treść, możemy z niej korzystać bez żadnych ograniczeń i bez wynagrodzenia dla Ciebie.',
    block6:
      '(c) Ograniczenia. Użytkownikowi nie wolno: (1) korzystać z Usługi w sposób naruszający, przywłaszczający lub naruszający jakiekolwiek prawa innych osób; (2) odtwarzać kodu źródłowego, dekompilować, dezasemblować, tłumaczyć ani podejmować w inny sposób prób odkrycia kodu źródłowego lub podstawowych komponentów modeli, algorytmów i systemów Usługi (chyba że takie ograniczenia są zabronione przez obowiązujące prawo); (3) wykorzystywać wyniki Usługi do opracowywania modeli konkurencyjnych wobec Noam; (4) wyodrębniać dane lub dane wyjściowe z Usługi przy użyciu jakichkolwiek metod automatycznych lub programistycznych, chyba że jest to dozwolone przez interfejs API, w tym skrobanie, zbieranie danych z sieci lub ekstrakcja danych z sieci; (5) przedstawiać wyników Usługi jako sztucznie wygenerowanych, podczas gdy tak nie jest, lub w inny sposób naruszać nasze zasady użytkowania; (6) kupować, sprzedawać lub przekazywać klucze API bez naszej uprzedniej zgody; lub (7) przekazywać nam jakiekolwiek dane osobowe dzieci poniżej 13 roku życia lub obowiązującego wieku wyrażonego zgodą cyfrową. Będziesz przestrzegać wszelkich limitów stawek i innych wymagań zawartych w naszej dokumentacji.',
    block7:
      '(d) Usługi stron trzecich. Wszelkie oprogramowanie, usługi lub inne produkty stron trzecich powiązane z Usługą podlegają odrębnym warunkom i nie ponosimy odpowiedzialności za produkty stron trzecich.',
    subTitle3: 'Treść',
    block8:
      '(a) Twoje treści. Możesz przekazać dane wejściowe („Wejście”) do Usługi i otrzymać dane wyjściowe („Wyjście”) wygenerowane i zwrócone na podstawie Wkładów (zwanych łącznie „Treścią”). Między stronami, w zakresie dozwolonym przez obowiązujące prawo, jesteś właścicielem wszystkich Wkładów. Noam może wykorzystywać Treści w celu świadczenia i utrzymywania Usługi, przestrzegania obowiązujących przepisów i egzekwowania naszych zasad. Użytkownik ponosi odpowiedzialność za Treści, w tym za zapewnienie, że nie naruszają one żadnego obowiązującego prawa ani niniejszych warunków.',
    block9:
      '(b) Dokładność. Sztuczna inteligencja i uczenie maszynowe to szybko rozwijające się obszary badań. Nieustannie staramy się ulepszać nasze usługi, aby były dokładniejsze, niezawodne, bezpieczne i korzystne. Biorąc pod uwagę probabilistyczny charakter uczenia maszynowego, korzystanie z naszych usług może w niektórych przypadkach powodować nieprawidłowe wyniki, które mogą nie odzwierciedlać dokładnie rzeczywistych osób, miejsc lub faktów. Powinieneś odpowiednio ocenić dokładność dowolnego wyniku w oparciu o swój przypadek użycia, w tym poprzez ręczne przeglądanie wyników.',
    subTitle4: 'Opłaty i płatności',
    block10:
      '(a) Opłaty i rozliczenia. Zapłacisz wszystkie opłaty („Opłaty”) zgodnie z cenami i warunkami podanymi na odpowiedniej stronie z cenami lub zgodnie z pisemnymi ustaleniami pomiędzy nami. Zastrzegamy sobie prawo do poprawiania błędów lub pomyłek cenowych, nawet jeśli wystawiliśmy faktury lub otrzymaliśmy płatność. Podasz pełne i dokładne informacje rozliczeniowe, w tym ważną i autoryzowaną metodę płatności. Będziemy okresowo obciążać Twoją metodę płatności, zgodnie z ustaleniami, ale możemy zasadnie zmienić datę rozliczenia. Upoważniasz firmę Noam, jej podmioty stowarzyszone i zewnętrzne podmioty przetwarzające płatności do obciążania Twojej metody płatności. Jeśli Twoja płatność nie powiedzie się, przekażemy Ci pisemne powiadomienie i możemy zawiesić dostęp do Usługi do czasu otrzymania płatności. O ile w niniejszej umowie nie określono inaczej, płatności nie podlegają zwrotowi.',
    block11:
      '(b) Podatki. O ile nie określono inaczej, Opłaty nie obejmują żadnych federalnych, stanowych, lokalnych i zagranicznych podatków, ceł ani podobnych naliczeń („Podatki”). Jesteś odpowiedzialny za uiszczenie wszystkich podatków związanych z Twoimi zakupami, z wyłączeniem podatków opartych na naszym dochodzie netto, za które będziemy Ci wystawiać faktury. Zgadzasz się niezwłocznie zapłacić takie Podatki i przekazać nam prawnie obowiązujące oświadczenia o ochronie prywatności oraz uzyskać niezbędne zgody na przetwarzanie takich danych, a także gwarantujesz nam, że obchodzisz się z tymi danymi zgodnie z obowiązującym prawem.',
    block12:
      '(c) Zmiany cen. Możemy zmienić nasze ceny, powiadamiając Cię o tym za pośrednictwem Twojego konta i/lub naszej strony internetowej. Podwyżki cen będą obowiązywać natychmiast po ich zaksięgowaniu. Wszelkie zmiany cen będą miały zastosowanie do Opłat za Twoje konto po dacie wejścia zmiany w życie.',
    block13:
      '(d) Spory i zaległe płatności. Jeżeli chcesz zakwestionować jakiekolwiek opłaty lub podatki, skontaktuj się z nami pod adresem contact@noam.tools w ciągu trzydziestu (30) dni od daty wystawienia faktury.',
    block14:
      '(e) Bezpłatne korzystanie. Nie możesz tworzyć wielu kont w celu uzyskania darmowych kredytów. Jeśli ustalimy, że nie korzystasz z bezpłatnych kredytów w dobrej wierze, możemy naliczyć Ci standardowe opłaty lub zaprzestać zapewniania dostępu do Usługi.',
    subTitle5: 'Poufność, bezpieczeństwo i ochrona danych',
    block15:
      '(a) Poufność. Możesz mieć dostęp do poufnych informacji firmy Noam. Do celów korzystania z Usługi możesz wykorzystywać informacje poufne wyłącznie w sposób dozwolony na mocy niniejszych warunków. Nie możesz ujawniać informacji poufnych żadnej stronie trzeciej i będziesz chronić informacje poufne w sposób nie mniej ochronny niż chronisz własne podobne informacje poufne, przynajmniej z należytą starannością. Informacje poufne to informacje niepubliczne określone przez firmę Noam jako poufne lub które w danych okolicznościach powinny być traktowane jako poufne, w tym oprogramowanie, specyfikacje i inne niepubliczne informacje biznesowe. Do informacji poufnych nie zalicza się informacji, które: (1) staną się powszechnie dostępne bez Twojej winy; (2) posiadałeś je bez obowiązku zachowania poufności przed otrzymaniem ich na niniejszych warunkach; (3) zostały zgodnie z prawem ujawnione Tobie przez osobę trzecią bez żadnych zobowiązań dotyczących poufności; lub (4) zostało przez Ciebie niezależnie opracowane bez wykorzystania informacji poufnych. Możesz ujawnić informacje poufne, jeśli jest to wymagane przez prawo, sąd lub inne nakazy rządowe, ale musisz uprzednio powiadomić firmę Noam na piśmie w rozsądny sposób i, w miarę możliwości, podjąć uzasadnione wysiłki, aby ograniczyć zakres ujawnienia, w tym pomóc nam sprzeciwiając się prośbom o ujawnienie informacji.',
    block16:
      '(b) Bezpieczeństwo. Musisz wdrożyć rozsądne i odpowiednie środki, aby zabezpieczyć swój dostęp do Usługi i korzystanie z niej. Jeśli odkryjesz jakiekolwiek luki w zabezpieczeniach lub naruszenia związane z korzystaniem z Usługi, musisz niezwłocznie skontaktować się z Noam i podać szczegóły dotyczące luki lub naruszenia.',
    block17:
      '((c) Przetwarzanie danych osobowych. Jeśli korzystasz z Usługi do przetwarzania danych osobowych, musisz przedstawić wystarczające prawnie oświadczenia o ochronie prywatności i uzyskać niezbędne zgody na przetwarzanie takich danych, a także gwarantujesz nam, że obchodzisz się z tymi danymi zgodnie z obowiązującym prawem .',
    subTitle6: 'Termin i zakończenie',
    block18:
      '(a) Zakończenie; Zawieszenie. Niniejsze warunki będą obowiązywać od pierwszego użycia Usługi i pozostaną w mocy aż do ich rozwiązania. Użytkownik może wypowiedzieć niniejsze warunki w dowolnym momencie i z dowolnego powodu, zaprzestając korzystania z Usługi i Treści. Możemy rozwiązać niniejsze warunki za uprzednim powiadomieniem z dowolnego powodu. Możemy natychmiast rozwiązać niniejsze warunki, powiadamiając Cię, jeśli w istotny sposób naruszysz sekcję 2 (Wymagania dotyczące użytkowania), sekcję 5 (Poufność, bezpieczeństwo i ochrona danych), sekcję 8 (Rozstrzyganie sporów) lub sekcję 9 (Warunki ogólne) lub jeśli nasze relacji z jakimkolwiek zewnętrznym dostawcą technologii będącym poza naszą kontrolą, zmiany lub w celu zapewnienia zgodności z prawem lub żądaniami rządowymi. Możemy zawiesić Twój dostęp do Usługi, jeśli nie zastosujesz się do niniejszych warunków lub jeśli Twoje korzystanie stwarza ryzyko bezpieczeństwa dla nas lub jakiejkolwiek strony trzeciej, lub jeśli podejrzewamy, że Twoje korzystanie jest oszukańcze lub może narazić nas lub jakąkolwiek osobę trzecią na odpowiedzialność .',
    block19:
      '(b) Skutki rozwiązania umowy. Po rozwiązaniu umowy zaprzestaniesz korzystania z Usługi i niezwłocznie zwrócisz lub zniszczysz wszelkie informacje poufne zgodnie z naszym poleceniem. Postanowienia niniejszych warunków, które ze swej natury powinny obowiązywać po rozwiązaniu lub wygaśnięciu, w tym między innymi sekcje 3 i 5-9, pozostają w mocy.',
    subTitle7: 'Odszkodowanie; Zastrzeżenia; Ograniczenie odpowiedzialności',
    block20:
      '(a) Odszkodowanie. Będziesz bronić, zabezpieczać i chronić nas, nasze podmioty stowarzyszone i nasz personel przed wszelkimi roszczeniami, stratami i wydatkami (w tym honorariami adwokackimi) wynikającymi lub związanymi z korzystaniem przez Ciebie z Usługi, w tym z Treściami, Twoimi korzystania z produktów lub usług związanych z Usługą oraz naruszenia przez Ciebie niniejszego regulaminu lub obowiązującego prawa.',
    block21:
      '(b) Zastrzeżenia. Usługa jest świadczona „tak jak jest”. W zakresie dozwolonym przez prawo my, nasi partnerzy i licencjodawcy nie składamy żadnych oświadczeń ani zapewnień jakiegokolwiek rodzaju w odniesieniu do Usługi i nie udzielamy żadnych gwarancji, w tym między innymi gwarancji wartości handlowej, przydatności do określonego celu, zadowalającej jakości, nienaruszania praw i spokojnej przyjemności oraz wszelkie gwarancje wynikające z przebiegu transakcji lub korzystania z handlu. Nie gwarantujemy, że Usługa będzie nieprzerwana, dokładna i wolna od błędów, ani że jakakolwiek Treść będzie bezpieczna, nieutracona ani niezmieniona.',
    block22:
      '(c) Ograniczenie odpowiedzialności. Ani my, ani nasi partnerzy, ani nasi licencjodawcy nie ponosimy odpowiedzialności za jakiekolwiek szkody pośrednie, przypadkowe, szczególne, wynikowe lub karne, w tym utratę zysków, utratę reputacji, utratę możliwości użytkowania, utratę danych lub inne straty niematerialne, nawet jeśli zostaliśmy poinformowani o możliwości wystąpienia takich szkód. Zgodnie z niniejszymi warunkami nasza całkowita odpowiedzialność nie przekroczy Opłat uiszczonych przez Państwa na rzecz Usługi w ciągu dwunastu (12) miesięcy poprzedzających roszczenie lub stu dolarów amerykańskich (100 dolarów), w zależności od tego, która kwota jest większa. Ograniczenia zawarte w tej sekcji mają zastosowanie w maksymalnym zakresie dozwolonym przez obowiązujące prawo.',
    subTitle8: 'Prawo właściwe i jurysdykcja',
    block23:
      'Warunki i sposób korzystania z Usług podlegają prawu Stanów Zjednoczonych, z wyjątkiem przepisów kolizyjnych obowiązujących w USA. Korzystanie z Aplikacji może również podlegać innym przepisom prawa lokalnego, stanowego, krajowego lub międzynarodowego. Wszelkie działania prawne lub postępowania dotyczące niniejszych Warunków będą toczone wyłącznie przed sądami Stanów Zjednoczonych, a użytkownik wyraża zgodę na poddanie się osobistej jurysdykcji takich sądów.',
    subTitle9: 'Warunki ogólne',
    block24:
      '(a) Stosunki Stron. Noam i Ty jesteście niezależnymi wykonawcami i żadna ze stron nie ma uprawnień do reprezentowania drugiej strony, wiązania drugiej strony ani podejmowania zobowiązań w jej imieniu bez uprzedniej pisemnej zgody drugiej strony.',
    block25:
      '(b) Wykorzystanie marki. Nie możesz używać nazwy, logo ani znaków towarowych firmy Noam ani żadnego z jej podmiotów stowarzyszonych bez naszej uprzedniej pisemnej zgody.',
    subTitle10: 'Rozdzielność',
    block26:
      'Jeżeli którekolwiek postanowienie niniejszych warunków zostanie uznane za nieważne, niezgodne z prawem lub niewykonalne, pozostałe postanowienia pozostaną w pełnej mocy.',
  },
  contact: {
    title: 'Skontaktuj się z nami',
    block1:
      'W celu dostosowania lub jeśli masz jakieś pytania lub sugestie, skontaktuj się z nami pod adresem contact@noam.tools, a my odpowiemy natychmiast.',
    block2: 'Wesprzyj nas, oceniając nas w sklepie z rozszerzeniami Google Apps pod adresem ★★★★★.',
    block3: 'Pomóż innym, udostępniając to rozszerzenie:',
  },
  pdf: {
    toast1: 'Trwa ładowanie pliku PDF. Spróbuj ponownie później!',
    toast2: 'Zawartość pliku PDF jest za duża, na razie nie obsługują okna dialogowego',
    toast3: 'Trwa pobieranie. Spróbuj ponownie po zakończeniu pobierania.',
    shareMsg1: 'Udostępnij Link wygenerowany pomyślnie',
    shareMsg2: 'Udostępnij aktualizacje treści pomyślnie',
    shareMsg3:
      'Wygenerowanie linku do udostępniania nie powiodło się. Spróbuj ponownie później lub skontaktuj się z naszym działem obsługi klienta.',
    shareMsg4:
      'Aktualizacja udostępniania treści nie powiodła się. Spróbuj ponownie później lub skontaktuj się z obsługą klienta',
    shareBtnText1: 'Wygeneruj linki do udostępniania',
    shareBtnText2: 'Zaktualizowano udostępnianie treści',
    uploading: 'Przesyłanie',
    uploadingMsg: 'Prosimy o cierpliwość, ponieważ przesyłanie zajmuje trochę czasu.',
    cancelUpload: 'Anulować',
    uploadErr:
      'Przesyłanie pliku nie powiodło się. Spróbuj ponownie później lub skontaktuj się z obsługą klienta',
    uploadMsg1: 'Proszę przeciągnąć i upuścić plik tutaj',
    uploadMsg2: 'Obsługiwane typy plików: PDF |  Maksymalny rozmiar pliku: 100MB',
    uploadMsg3: 'Kliknij, aby wybrać lub przeciągnij i upuść pliki, które chcesz przesłać tutaj',
    searchPlaceholder: 'Szukaj',
    collectionEmpty: 'Brak zawartości w kolekcji~',
    kw: 'zszywka',
    video: 'wideo',
    link: 'połączyć',
    dataLoading: 'Ładowanie danych...',
    freeBannerTips: 'Koniec bezpłatnego tłumaczenia plików PDF',
    vipBannerTips: 'Osiągnąłeś limit tłumaczeń PDF w tym miesiącu.',
    freeBannerBtn: 'Uaktualnij, aby cieszyć się więcej',
    vipBannerBtn: 'Uaktualnij, aby cieszyć się więcej',
    dialogTitle: 'Uwaga',
    dialogContent: 'Czy na pewno chcesz to usunąć?',
    okBtn: 'Usuwać',
    cancelBtn: 'Anulować',
    unLoginToast: 'Aby skorzystać z tej funkcji, zaloguj się',
    parseErrTips: 'Nie można przeanalizować pliku',
    new: 'nowy plik',
    parseErrDialogContent:
      'Obecny link do pliku PDF nie obsługuje bezpośredniego tłumaczenia. Po przetłumaczeniu pobierz oryginalny plik PDF z linkiem',
    okBtn2: 'rozpoznać',
    notPdf: 'Tylko pliki PDF',
    uploadingMsg1: 'Trwa przesyłanie pliku. Spróbuj ponownie później.',
    uploadingMsg2: 'Aktualnie konwertowany jest plik. Spróbuj ponownie później.',
    uploadingMsg3:
      'Zapytanie o postęp konwersji nie powiodło się. Spróbuj ponownie później lub skontaktuj się z obsługą klienta',
    uploadingMsg4:
      'Noam nie może uzyskać bezpośredniego dostępu do plików lokalnych. Prześlij ręcznie plik PDF do tłumaczenia.',
    uploadingMsg5:
      'Nie udało się uzyskać adresu URL pliku. Spróbuj ponownie później lub skontaktuj się z obsługą klienta.',
    fileMsg: 'Bieżący wolumen pliku:',
    fileMsg2: 'Prześlij plik mniejszy niż 100 MB',
    fileErrMsg:
      'Odczyt pliku nie powiódł się. Spróbuj ponownie później lub skontaktuj się z obsługą klienta.',
    parseFileToast:
      'Analiza pliku nie powiodła się. Spróbuj ponownie później lub skontaktuj się z naszym działem obsługi klienta.',
    noId: 'Identyfikator pliku nie istnieje',
    autoDetect: 'Automatyczne wykrywanie',
    hasTransTask: 'Trwa tłumaczenie zadania. Proszę czekać.',
    transSucc: 'Tłumaczenie powiodło się!',
    entry: 'Lista PDF',
    listError:
      'Nie udało się załadować listy PDF. Spróbuj ponownie później lub skontaktuj się z obsługą klienta.',
    collectionTab: 'Notatka',
    pdfLoading: 'Rozbiór gramatyczny zdania...',
    installTips:
      'Zainstaluj małą wtyczkę, możesz przetłumaczyć więcej plików PDF, możesz także doświadczyć płynnego tłumaczenia stron internetowych:',
    webTrans: 'pełne odniesienie',
    hoverTrans: 'Najedź kursorem na tłumaczenie akapitu',
    selectTrans: 'podkreśl słowa i przetłumacz',
    installText:
      'Kompleksowa obsługa wszystkich Twoich codziennych potrzeb związanych z tłumaczeniami',
    start: 'Rozpocznij bezpłatne korzystanie',
    shareTips: 'Tłumaczenie PDF zostało zakończone, śmiało, udostępnij je znajomym!',
    download: {
      loading: 'Zapisano...',
      btn: 'Zapisz teraz',
      trans: 'Tylko tłumaczenie',
      both: 'dwujęzyczność',
      download: 'Pobierać',
      msg1: 'Niektóre tłumaczenia aktualnego dokumentu są dłuższe od tekstu oryginalnego, a po pobraniu teksty mogą się na siebie nakładać, dlatego zaleca się zapoznanie się z nimi na stronie internetowej.',
      msg2: '1. Treść nie została w pełni przetłumaczona. Pobierz ją po całkowitym przetłumaczeniu.',
      msg3: '2. Część tłumaczenia w dokumencie jest za długa. Możesz przeczytać na stronie internetowej, aby uniknąć nakładania się tekstu.',
      msg4: 'Aktualnie tłumaczone są zadania. Spróbuj ponownie po zakończeniu tłumaczenia.',
      fileName: 'Nazwa pliku:',
      fileType: 'Pobierz typ pliku:',
      transContinue: 'Kontynuuj tłumaczenie',
      save: 'Zapisz lokalnie',
      downloadMsg1: 'PDF Postęp w tłumaczeniu:',
      downloadMsg2: 'Prognozowane wymagania',
      minute: 'Protokół...',
      downloadSucc: 'Pobieranie pliku PDF zostało zakończone. Kliknij Zapisz teraz!',
      savePrint: 'Zapisz i wydrukuj',
      loading2: 'Załadunek...',
      desc: 'Zapisz w lokalizacji lokalnej: pobrane tłumaczenia są zapisywane jako obrazy; Zapisz i wydrukuj: pobrane tłumaczenia są zachowywane w oryginalnym formacie i umożliwiają kopiowanie.',
      success: 'Pobierz pomyślnie',
      cancel:
        'Zamknięcie wyskakującego okna spowoduje anulowanie pobierania pliku PDF. Czy na pewno chcesz anulować?',
      downloadTip:
        'Trwa pobieranie pliku PDF. Nie zamykaj wyskakującego okna podczas pobierania, aby uniknąć niepowodzenia pobierania.',
      progress:
        'PDF jest tłumaczony. Postęp: {{postęp}}, oczekuje się, że zajmie to {{minuty}} minut...',
      error: 'Nie udało się zapisać lokalnie. Spróbuj wydrukować zapis',
    },
    guide: {
      title1: 'Tłumaczenie dwujęzyczne',
      title2: 'Czat w formacie PDF',
      text1: 'Czytanie dwujęzyczne',
      text2:
        'Czytanie dwujęzyczne, dzięki czemu możesz porównać tekst oryginalny i tłumaczenie podczas czytania, aby poprawić efektywność czytania',
      text3: 'Czat w formacie PDF',
      text4:
        'AI analizuje i podsumowuje oraz może odpowiedzieć na Twoje pytania w oparciu o zawartość pliku PDF',
      text5: 'Dokładność i wydajność',
      text6:
        'Pliki PDF zasilane sztuczną inteligencją zapewniają dokładniejsze i wydajniejsze tłumaczenia',
      placeholder: 'Proszę wypełnić link do pliku PDF',
      start: 'Rozpocznij tłumaczenie',
      toast: 'Proszę wprowadzić prawidłowy link do pliku PDF online kończący się na .pdf',
    },
    original: 'Oryginalny',
    parallel: 'Równoległy',
    trans: 'Tłumaczenie',
    initial: 'Przetłumacz PDF',
    thumbnail: 'katalogi',
    downloadBtn: 'Pobierać',
    shareBtn: 'Udział',
    uploadBtn: 'Wgrywać',
    installText2: 'Najpierw zainstaluj',
    chatPdf: {
      clearSucc: 'Pomyślnie wyczyszczono',
      clear: 'Jasne',
      clearContent:
        'Po wyczyszczeniu zostanie utworzona nowa rozmowa, a treść tej rozmowy nie zostanie zapisana. Czy na pewno chcesz ją wyczyścić?',
      clearDialogTitle: 'Uwaga',
      cancel: 'Anulować',
      chatTitle: 'Witamy w Noam, Twoje podsumowanie w formacie PDF jest gotowe:',
      summaryTitle: 'Pytania, które mogą Cię zainteresować:',
      placeholder: 'Wpisz dowolne pytanie dotyczące pliku PDF',
      copy: 'Kopia',
      copySucc: 'Skopiuj sukces',
      historyTitle: 'Historia',
      expand: 'Zawalić się',
      noDataDesc: 'Żadnej historii~',
      noMore: 'Więcej nie.',
      Search: 'Szukaj',
      delete: 'Usuwać',
      loading: 'załadunek...',
      deleteTitle: 'Wskazówka',
      deleteContent: 'Po usunięciu tego pliku PDF nie można odzyskać. Czy chcesz go usunąć?',
      deleteOk: 'Usuwać',
      deleteCancel: 'Anulować',
      deleteSuccess: 'Usuń pomyślnie',
      listError:
        'Nie udało się załadować listy do przeczytania. Spróbuj ponownie później lub skontaktuj się z obsługą klienta',
    },
    shareModal: {
      shareSetting: 'Udostępnij ustawienia treści',
      shareText: 'Udział',
      text1: 'Ustawienia udostępniania',
      text2: 'Wygeneruj linki do udostępniania',
      range: 'Zakres:',
      custom: 'Personalizacja:',
      page: 'strony',
      sourceLang: 'Źródło:',
      target: 'Cel:',
      errMsg:
        'Treść bieżącego zakresu udostępniania nie została w pełni przetłumaczona, dlatego prosimy o jej przetłumaczenie przed udostępnieniem.',
      pageNuErr: 'Proszę wprowadzić poprawną wartość numeru strony',
      trans: 'Tłumaczyć',
      cancel: 'Anulować',
      update: 'Zaktualizowano linki do udostępniania',
      gen: 'Udział',
      shareTips: 'Udostępnij link do przetłumaczonego pliku PDF znajomym, aby przeczytać:',
      copySucc: 'Skopiuj link pomyślnie',
      copy: 'Kopia',
      settings: 'Udostępnij ustawienia:',
      modify: 'modyfikować',
      translateTips:
        'Wskazówka: Jeśli tłumaczenie zajmuje dużo czasu, możesz zamknąć wyskakujące okienko i udostępnić je po zakończeniu tłumaczenia.',
      translating: 'Tłumaczenie PDF...',
      remain: 'pozostały',
      remain1: 'Całkowite przewidywane wymagania',
      totalPage: 'Łącznie 1 strona',
      search: 'Szukaj',
      notSupport: 'Ten język nie jest obecnie obsługiwany',
    },
    toolbar: { Adjust: 'Regulować', autoFit: 'Automatyczne dopasowanie' },
    memoTotalCount: 'Łącznie 1 treść',
    dragTipModal: {
      press: 'Naciskać',
      black: 'przestrzeń',
      leftKey: 'lewy dół',
      tips: 'przeciągnąć i wyświetlić.',
      ok: 'Zrozumiałem',
    },
  },
  locale: {
    zh: 'chiński',
    en: 'angielski',
    es: 'hiszpański',
    ru: 'rosyjski',
    pt: 'portugalski (Brazylia)',
    id: 'indonezyjski',
    cs: 'czeski',
    de: 'niemiecki',
    fr: 'francuski',
    it: 'włoski',
    nl: 'Holenderski',
    pl: 'Polski',
    vi: 'wietnamski',
    tr: 'turecki',
    uk: 'ukraiński',
    zhHant: 'Chiński tradycyjny',
    ja: 'japoński',
    ko: 'koreański',
    ar: 'arabski',
    fa: 'perski',
    th: 'tajski',
    hi: 'hinduski',
    noSearchLang: 'Ten język nie jest obsługiwany',
    placeholder: 'Szukaj',
    af: 'Afrykanerski',
    am: 'amharski',
    as: 'asamski',
    az: 'azerbejdżański',
    ba: 'Baszkir',
    bg: 'bułgarski',
    bn: 'Bangla',
    bo: 'tybetański',
    bs: 'bośniacki',
    ca: 'kataloński',
    cy: 'walijski',
    da: 'duński',
    dsb: 'dolnołużycki',
    dv: 'Divehi',
    el: 'grecki',
    et: 'estoński',
    eu: 'baskijski',
    fi: 'fiński',
    fil: 'Filipiński',
    fj: 'Fidżi',
    frCA: 'Francuski (Kanada)',
    ga: 'irlandzki',
    gl: 'galicyjski',
    gom: 'Konkani',
    gu: 'gudżarati',
    ha: 'Hausa',
    he: 'hebrajski',
    hr: 'chorwacki',
    hsb: 'Górnołużycki',
    ht: 'Kreolski haitański',
    hu: 'węgierski',
    hy: 'ormiański',
    ig: 'Ibo',
    ikt: 'Inuinnaqtun',
    is: 'islandzki',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (łac.)',
    ka: 'gruziński',
    kk: 'kazachski',
    km: 'Khmer',
    kmr: 'kurdyjski (północny)',
    kn: 'Kannada',
    ku: 'Kurdyjski (centralny)',
    ky: 'Kirgiski',
    ln: 'Lingala',
    lo: 'Laotański',
    lt: 'litewski',
    lug: 'Ganda',
    lv: 'łotewski',
    lzh: 'chiński (literacki)',
    mai: 'Maithili',
    mg: 'malgaski',
    mi: 'Maorysi',
    mk: 'Macedoński',
    ml: 'malajalam',
    mnCyrl: 'mongolski (cyrylica)',
    mnMong: 'mongolski (tradycyjny)',
    mr: 'Marathi',
    ms: 'malajski',
    mt: 'maltański',
    mww: 'Hmong Daw',
    my: 'Birma (birmański)',
    nb: 'norweski',
    ne: 'Nepalski',
    nso: 'Sesotho na Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Pendżabski',
    prs: 'Dari',
    ps: 'paszto',
    ptPT: 'portugalski (Portugalia)',
    ro: 'rumuński',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'syngaleski',
    sk: 'słowacki',
    sl: 'słoweński',
    sm: 'Samoański',
    sn: 'Shona',
    so: 'somalijski',
    sq: 'albański',
    srCyrl: 'serbski (cyrylica)',
    srLatn: 'serbski (łaciński)',
    st: 'Sesoto',
    sv: 'szwedzki',
    sw: 'Suahili',
    ta: 'Tamil',
    te: 'telugu',
    ti: 'Tigrinia',
    tk: 'Turkmeni',
    tlhLatn: 'klingoński (łac.)',
    tlhPiqd: 'klingoński (pIqaD)',
    tn: 'Setswany',
    to: 'Tongijski',
    tt: 'Tatar',
    ty: 'tahitański',
    ug: 'Ujgur',
    ur: 'urdu',
    uz: 'uzbecki (łac.)',
    xh: 'Xhosa',
    yo: 'Joruba',
    yua: 'Maja Yucatec',
    yue: 'Kantoński (tradycyjny)',
    zhHans: 'Chiński uproszczony',
    zu: 'Zulus',
    auto: 'Automatyczne wykrywanie',
  },
  stripe: {
    contact:
      'W przypadku jakichkolwiek pytań skontaktuj się z nami pod adresem contact@noam.tools!',
    fail: {
      title: 'Płatność nie powiodła się',
      text1: 'Niestety nie możemy przetworzyć Twojego żądania płatności.',
      text2: 'Sprawdź swoje połączenie internetowe i spróbuj ponownie.',
      btn: 'Spróbować ponownie',
    },
    succ: {
      title: 'Gratulacje',
      text1: 'witaj w Noamie,',
      text2: 'zacznijmy razem!',
      btn: 'Zróbmy trochę magii',
    },
  },
  welfare: {
    newUser: {
      label: 'Nowe korzyści dla użytkownika:',
      joinBtn: 'Dołącz teraz',
      text: 'Dostępne tylko 100 miejsc! Złap swoje teraz!',
      remain: 'Zostało tylko 30',
    },
  },
  help: {
    Fixedplugin: 'Naprawiono wtyczkę',
    Howtouse: 'Jak używać',
    try: 'Spróbuj',
    FreeTrialBenefits: 'Korzyści z bezpłatnego okresu próbnego',
    pin: 'Pin Noam, dla łatwiejszego dostępu～～',
    Video: 'Wideo',
    ParallelTranslation: 'Tłumaczenie równoległe',
    WebSummary: 'Podsumowanie internetowe',
    ChatPDF: 'Czat w formacie PDF',
    AquickOverviewOfNoam: 'Krótki przegląd Noama',
    WordSelectionTranslation: 'Tłumaczenie wyboru słów',
    HoverTranslation: 'Tłumaczenie kursora',
    ImageTranslation: 'Tłumaczenie obrazu',
    InstantTranslation: 'Natychmiastowe tłumaczenie',
    VideoTranslation: 'Tłumaczenie wideo',
    benefits: 'Przygotowaliśmy dla Ciebie korzyści!',
    Free: 'Bezpłatny',
    Membership: 'Członkostwo',
    Translation: 'Tłumaczenie',
    PDFparallelTranslation: 'Tłumaczenie równoległe PDF',
    ChatPDF2: 'CzatPDF',
    Summary: 'Streszczenie',
    VideoSummary: 'Podsumowanie wideo',
    FavoritesManagement: 'Zarządzanie ulubionymi',
    Focusing: 'Skupienie',
    dayFree: '3-dniowy bezpłatny okres próbny',
    planIsNotExist: 'planu nie ma',
    TryItForFree: 'Wypróbuj za darmo',
    startYoutube: 'Zacznijmy od YouTube~',
    startWiki: 'Zacznijmy od Wikimediów~',
    tryNow: 'Spróbuj teraz~',
    GoWithYouTube: "Korzystaj z YouTube'a",
    GoWithWikipedia: 'Idź z Wikipedią',
    NoamHelp: 'Noam może pomóc Ci w tłumaczeniu i podsumowywaniu',
    SkipAllSteps: 'Pomiń wszystkie kroki',
    next: 'Następny',
    done: 'Zrobione',
  },
  trialModal: {
    title: 'Prezent powitalny',
    trialText: '7-dniowy okres próbny',
    desc1: 'Po zakończeniu okresu próbnego zostanie naliczona roczna opłata w wysokości 180 USD',
    desc2: 'Anuluj w dowolnym momencie',
    btn: 'Zacznij już teraz (tylko 0,99 USD)',
    installBtn: 'Zainstaluj teraz, aby otrzymać prezent',
    web: 'Tłumaczenie równoległe',
    select: 'Tłumaczenie wyboru słów',
    hover: 'Tłumaczenie kursora',
    image: 'Tłumaczenie obrazu',
    realtime: 'Natychmiastowe tłumaczenie',
    video: 'Tłumaczenie wideo',
    pdf: 'Tłumaczenie równoległe PDF',
    chat: 'CzatPDF',
    webSummary: 'Podsumowanie internetowe',
    videoSummary: 'Podsumowanie wideo',
    memo: 'Zarządzanie ulubionymi',
    focus: 'Skupienie',
  },
  privacyCollectionPanel: {
    title: 'Ta strona korzysta z plików cookies',
    text: 'Aby poprawić Twoje doświadczenie, chcielibyśmy używać plików cookie w celu personalizacji treści i usług.',
    acceptBtn: 'Zaakceptuj wszystko',
  },
  installTips: {
    text: 'Zainstaluj „Wtyczkę do przeglądarki Noam”, aby odblokować więcej funkcji! Odsyłacze internetowe,\n    podsumowanie wideo w Internecie i nie tylko!',
    btn: 'Wypróbuj teraz',
  },
}

export default TRANSLATION
