const TRANSLATION = {
  "buyModal": {
    "afterFree": "Sau khi kết thúc bản dùng thử",
    "btn": "Dùng thử miễn phí",
    "freeTrial": "Dùng thử miễn phí 3 ngày",
    "monthLabel": "tháng",
    "selectLabel": "Chọn gói",
    "title": "Gói đăng ký",
    "yearLabel": "năm"
  },
  "contact": {
    "block1": "Để tùy chỉnh hoặc nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào, vui lòng liên hệ với chúng tôi tại contact@noam.tools và chúng tôi sẽ phản hồi ngay lập tức.",
    "block2": "Hãy hỗ trợ chúng tôi bằng cách đánh giá  ★★★★★ trên cửa hàng tiện ích mở rộng của Google Apps.",
    "block3": "Giúp người khác bằng cách chia sẻ tiện ích mở rộng này:",
    "title": "Liên Hệ"
  },
  "help": {
    "AquickOverviewOfNoam": "Tổng quan về Noam",
    "ChatPDF": "Trò chuyện PDF",
    "ChatPDF2": "Trò chuyện PDF",
    "FavoritesManagement": "Quản lý mục yêu thích",
    "Fixedplugin": "Plugin cố định",
    "Focusing": "Đang tập trung",
    "Free": "Miễn phí",
    "FreeTrialBenefits": "Lợi ích dùng thử miễn phí",
    "GoWithWikipedia": "Sử dụng với Wikipedia",
    "GoWithYouTube": "Sử dụng với YouTube",
    "HoverTranslation": "Dịch khi di chuột",
    "Howtouse": "Cách sử dụng",
    "ImageTranslation": "Dịch hình ảnh",
    "InstantTranslation": "Dịch tức thời",
    "Membership": "Thành viên",
    "NoamHelp": "Noam có thể giúp bạn dịch và tóm tắt",
    "PDFparallelTranslation": "Dịch song song PDF",
    "ParallelTranslation": "Dịch song song",
    "SkipAllSteps": "Bỏ qua tất cả các bước",
    "Summary": "Tóm tắt",
    "Translation": "Dịch",
    "TryItForFree": "Dùng thử miễn phí",
    "Video": "Video",
    "VideoSummary": "Tóm tắt video",
    "VideoTranslation": "Dịch video",
    "WebSummary": "Tóm tắt trang web",
    "WordSelectionTranslation": "Dịch theo lựa chọn từ",
    "benefits": "Chúng tôi đã chuẩn bị những lợi ích dành cho bạn!",
    "dayFree": "Dùng thử miễn phí 3 ngày",
    "done": "Hoàn tất",
    "next": "Tiếp theo",
    "pin": "Ghim Noam để truy cập dễ dàng hơn～～",
    "planIsNotExist": "gói không tồn tại",
    "startWiki": "Bắt đầu với Wikimedia~",
    "startYoutube": "Bắt đầu với YouTube~",
    "try": "Dùng thử",
    "tryNow": "Dùng thử ngay~"
  },
  "home": {
    "PDFTranslate": {
      "desc": "Dịch song ngữ + Trò chuyện PDF",
      "sub1": {
        "desc": "Đọc song ngữ, vì vậy bạn có thể so sánh văn bản gốc và bản dịch trong khi đọc để cải thiện hiệu quả đọc",
        "title": "Đọc song ngữ"
      },
      "sub2": {
        "desc": "AI phân tích và tóm tắt và có thể trả lời câu hỏi của bạn dựa trên nội dung PDF",
        "title": "Trò chuyện PDF"
      },
      "sub3": {
        "desc": "PDf hỗ trợ AI cho bản dịch chính xác và hiệu quả hơn",
        "title": "Độ chính xác và hiệu quả"
      },
      "title": "Dịch PDF"
    },
    "aiAssistant": {
      "desc": "Làm cho Dịch Web Thông minh hơn và Tiện lợi hơn",
      "panel1": {
        "desc": "Bản dịch đối chiếu song ngữ giúp bạn so sánh văn bản gốc với bản dịch để bạn có thể hiểu rõ hơn và cải thiện hiệu quả đọc của mình.",
        "title": "Dịch song ngữ"
      },
      "panel2": {
        "left": {
          "desc": "Khi duyệt trang web, hãy di chuột trực tiếp qua nội dung cần dịch để nhận kết quả dịch.",
          "title": "Dịch khi di chuột"
        },
        "right": {
          "desc": "Khi duyệt trang web, hãy chọn trực tiếp văn bản cần gạch chân để nhận kết quả dịch.",
          "title": "Dịch văn bản được chọn"
        }
      },
      "panel3": {
        "one": {
          "desc": "Dịch và tóm tắt nội dung trang web và video cho bạn mọi lúc, mọi nơi, trong mọi tình huống; mang đến cho bạn trải nghiệm mượt mà hơn!",
          "title": "Trọn gọn và dễ dàng"
        },
        "three": {
          "desc": "Mô hình lớn do AI điều khiển để đảm bảo tính nhất quán và chính xác của bản dịch và nâng cao trải nghiệm đọc",
          "title": "Trạy bằng AI"
        },
        "two": {
          "desc": "Phủ sóng đa ngôn ngữ đầy đủ, đọc song ngữ không rào cản, dịch một phím; mang đến cho bạn trải nghiệm tuyệt vời!",
          "title": "Toàn diện và tiện lợi hơn"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "Chuyên gia hiệu quả của bạn, kết hợp với chatgpt dịch và tóm tắt nội dung video YouTube để tiết kiệm thời gian đọc của bạn!",
          "key": "Video",
          "title": "Dịch & tóm tắt video"
        },
        "tab2": {
          "desc": "Nhấp để dịch hình ảnh trong khi duyệt web",
          "key": "Image",
          "title": "Dịch hình ảnh"
        },
        "tab3": {
          "desc": "Nhanh chóng trích xuất thông tin chính từ các trang web để tóm tắt, nâng cao hiệu quả đọc của bạn",
          "key": "Web",
          "title": "Dịch & tóm tắt Web"
        },
        "tag1": "Đơn giản",
        "tag2": "Hiệu quả",
        "tag3": "Dễ hiểu"
      },
      "title": "Trợ lý AI"
    },
    "getStart": "Bắt đầu",
    "getStartedForFree": "Dùng thử miễn phí",
    "howItWork": {
      "desc": "Noam tích hợp các mô hình AI để cung cấp cho người dùng trải nghiệm thông minh cho dịch và tóm tắt trang web đa ngôn ngữ, dịch PDF và hình ảnh, dịch và tóm tắt video",
      "title": "Nó hoạt động như thế nào"
    },
    "lastBlock": {
      "desc": "Trải nghiệm đọc mượt mà hơn và dịch AI tốt hơn"
    },
    "top": {
      "desc": "Noam là một tiện ích mở rộng trình duyệt AI miễn phí giúp bạn dịch các trang web, video và nội dung PDF được tóm tắt.\\n      Tham gia Noam và tận hưởng điều kỳ diệu!",
      "title1": "Một cú nhấp chuột",
      "title2": "Một phép thuật"
    },
    "userReviews": {
      "desc": "Đánh giá năm sao trên chromewebstore",
      "title": "Đánh giá của người dùng"
    }
  },
  "installTips": {
    "btn": "Dùng Thử Ngay",
    "text": "Cài đặt “Tiện Ích Trình Duyệt Noam” để mở khóa thêm nhiều tính năng! Tra cứu chéo trên web,\\n    tóm tắt video web và hơn thế nữa!"
  },
  "locale": {
    "af": "Tiếng Afrikaans",
    "am": "Tiếng Amharic",
    "ar": "Tiếng Ả Rập",
    "as": "Tiếng Assam",
    "auto": "Tự động phát hiện",
    "az": "Tiếng Azerbaijan",
    "ba": "Tiếng Bashkir",
    "bg": "Tiếng Bulgaria",
    "bn": "Tiếng Bengal",
    "bo": "Tiếng Tây Tạng",
    "bs": "Tiếng Bosnia",
    "ca": "Tiếng Catalan",
    "cs": "Tiếng Séc",
    "cy": "Tiếng Wales",
    "da": "Tiếng Đan Mạch",
    "de": "Tiếng Đức",
    "dsb": "Tiếng Hạ Sorbia",
    "dv": "Tiếng Divehi",
    "el": "Tiếng Hy Lạp",
    "en": "Tiếng Anh",
    "es": "Tiếng Tây Ban Nha",
    "et": "Tiếng Estonia",
    "eu": "Tiếng Basque",
    "fa": "Tiếng Ba Tư",
    "fi": "Tiếng Phần Lan",
    "fil": "Tiếng Philippines",
    "fj": "Tiếng Fiji",
    "fr": "Tiếng Pháp",
    "frCA": "Tiếng Pháp (Canada)",
    "ga": "Tiếng Ireland",
    "gl": "Tiếng Galicia",
    "gom": "Tiếng Konkani",
    "gu": "Tiếng Gujarati",
    "ha": "Tiếng Hausa",
    "he": "Tiếng Do Thái",
    "hi": "Tiếng Hindi",
    "hr": "Tiếng Croatia",
    "hsb": "Tiếng Thượng Sorbia",
    "ht": "Tiếng Creole Haiti",
    "hu": "Tiếng Hungary",
    "hy": "Tiếng Armenia",
    "id": "Tiếng Indonesia",
    "ig": "Tiếng Igbo",
    "ikt": "Tiếng Inuinnaqtun",
    "is": "Tiếng Iceland",
    "it": "Tiếng Ý",
    "iu": "Tiếng Inuktitut",
    "iuLatn": "Tiếng Inuktitut (Latin)",
    "ja": "Tiếng Nhật",
    "ka": "Tiếng Gruzia",
    "kk": "Tiếng Kazakh",
    "km": "Tiếng Khmer",
    "kmr": "Tiếng Kurd (phía Bắc)",
    "kn": "Tiếng Kannada",
    "ko": "Tiếng Hàn",
    "ku": "Tiếng Kurd (Trung tâm)",
    "ky": "Tiếng Kyrgyz",
    "ln": "Tiếng Lingala",
    "lo": "Tiếng Lào",
    "lt": "Tiếng Litva",
    "lug": "Tiếng Ganda",
    "lv": "Tiếng Latvia",
    "lzh": "Tiếng Trung (Văn chương)",
    "mai": "Tiếng Maithili",
    "mg": "Tiếng Malagasy",
    "mi": "Tiếng Māori",
    "mk": "Tiếng Macedonia",
    "ml": "Tiếng Malayalam",
    "mnCyrl": "Tiếng Mông Cổ (Cyrillic)",
    "mnMong": "Tiếng Mông Cổ (Truyền thống)",
    "mr": "Tiếng Marathi",
    "ms": "Tiếng Mã Lai",
    "mt": "Tiếng Malta",
    "mww": "Tiếng Hmong Daw",
    "my": "Tiếng Myanmar (Burmese)",
    "nb": "Tiếng Na Uy",
    "ne": "Tiếng Nepal",
    "nl": "Tiếng Hà Lan",
    "noSearchLang": "Ngôn ngữ này không được hỗ trợ",
    "nso": "Tiếng Bắc Sotho",
    "nya": "Tiếng Nyanja",
    "or": "Tiếng Odia",
    "otq": "Tiếng Otomi Querétaro",
    "pa": "Tiếng Punjabi",
    "pl": "Tiếng Ba Lan",
    "placeholder": "Tìm kiếm",
    "prs": "Tiếng Dari",
    "ps": "Tiếng Pashto",
    "pt": "Tiếng Bồ Đào Nha (Brazil)",
    "ptPT": "Tiếng Bồ Đào Nha (Bồ Đào Nha)",
    "ro": "Tiếng Rumani",
    "ru": "Tiếng Nga",
    "run": "Tiếng Rundi",
    "rw": "Tiếng Kinyarwanda",
    "sd": "Tiếng Sindhi",
    "si": "Tiếng Sinhala",
    "sk": "Tiếng Slovak",
    "sl": "Tiếng Slovenia",
    "sm": "Tiếng Samoa",
    "sn": "Tiếng Shona",
    "so": "Tiếng Somali",
    "sq": "Tiếng Albania",
    "srCyrl": "Tiếng Serbia (Cyrillic)",
    "srLatn": "Tiếng Serbia (Latin)",
    "st": "Tiếng Sotho",
    "sv": "Tiếng Thụy Điển",
    "sw": "Tiếng Swahili",
    "ta": "Tiếng Tamil",
    "te": "Tiếng Telugu",
    "th": "Tiếng Thái",
    "ti": "Tiếng Tigrinya",
    "tk": "Tiếng Turkmen",
    "tlhLatn": "Tiếng Klingon (Latin)",
    "tlhPiqd": "Tiếng Klingon (pIqaD)",
    "tn": "Tiếng Setswana",
    "to": "Tiếng Tonga",
    "tr": "Tiếng Thổ Nhĩ Kỳ",
    "tt": "Tiếng Tatar",
    "ty": "Tiếng Tahiti",
    "ug": "Tiếng Uyghur",
    "uk": "Tiếng Ukraina",
    "ur": "Tiếng Urdu",
    "uz": "Tiếng Uzbek (Latin)",
    "vi": "Tiếng Việt",
    "xh": "Tiếng Xhosa",
    "yo": "Tiếng Yoruba",
    "yua": "Tiếng Maya Yucatec",
    "yue": "Tiếng Quảng Đông (Phồn thể)",
    "zh": "Tiếng Trung",
    "zhHans": "Tiếng Trung Giản thể",
    "zhHant": "Tiếng Trung Phồn thể",
    "zu": "Tiếng Zulu"
  },
  "login": {
    "and": " và",
    "btn": "Đăng nhập bằng Google",
    "btn1": "Đăng nhập ngay",
    "desc": "Đăng nhập để mở khóa thêm trải nghiệm",
    "policy": " Chính sách bảo mật",
    "terms": "Điều khoản sử dụng",
    "tips": "Bằng cách đăng ký, tôi đồng ý với",
    "title": "Chào mừng đến với Noam"
  },
  "pageFooter": {
    "follow": {
      "facebook": "Facebook",
      "title": "Theo dõi",
      "twitter": "Twitter"
    },
    "legal": {
      "policy": "Chính sách Bảo mật",
      "terms": "Điều khoản Dịch vụ",
      "title": "Pháp lý"
    },
    "product": {
      "image": "Dịch Hình ảnh",
      "pdf": "Dịch PDF",
      "title": "Sản phẩm",
      "video": "Dịch & Tóm tắt Video",
      "web": "Dịch & Tóm tắt Trang web"
    },
    "resources": {
      "title": "Tài nguyên"
    }
  },
  "pageHeader": {
    "contact": "Liên hệ",
    "help": "Trung tâm trợ giúp",
    "logout": "Đăng xuất",
    "logoutSucc": "Đăng xuất thành công",
    "pricing": "Bảng giá",
    "settings": "Cài đặt",
    "signUp": "Đăng nhập"
  },
  "pdf": {
    "autoDetect": "Tự động phát hiện",
    "cancelBtn": "Hủy",
    "cancelUpload": "Hủy",
    "chatPdf": {
      "Search": "Tìm kiếm",
      "cancel": "Hủy",
      "chatTitle": "Chào mừng đến với Noam, bản tóm tắt PDF của bạn đã sẵn sàng:",
      "clear": "Xóa",
      "clearContent": "Sau khi xóa, một cuộc trò chuyện mới sẽ được tạo và nội dung của cuộc trò chuyện này sẽ không được lưu, bạn có chắc chắn muốn xóa nó không?",
      "clearDialogTitle": "Lưu ý",
      "clearSucc": "Đã xóa thành công",
      "copy": "Sao chép",
      "copySucc": "Sao chép thành công",
      "delete": "Xóa",
      "deleteCancel": "Hủy",
      "deleteContent": "Sau khi xóa, PDF này sẽ không thể khôi phục, bạn có muốn xóa nó không?",
      "deleteOk": "Xóa",
      "deleteSuccess": "Xóa thành công",
      "deleteTitle": "Mẹo",
      "expand": "Thu gọn",
      "historyTitle": "Lịch sử",
      "listError": "Không tải được danh sách đọc. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ khách hàng",
      "loading": "đang tải...",
      "noDataDesc": "Không có lịch sử~ ",
      "noMore": "Hết.",
      "placeholder": "Nhập bất kỳ câu hỏi nào về PDF",
      "summaryTitle": "Những câu hỏi bạn có thể quan tâm:"
    },
    "collectionEmpty": "Không có nội dung trong bộ sưu tập~",
    "collectionTab": "Bản ghi nhớ",
    "dataLoading": "Đang tải dữ liệu...",
    "dialogContent": "Bạn có chắc chắn muốn xóa nó không?",
    "dialogTitle": "Lưu ý",
    "download": {
      "both": "song ngữ",
      "btn": "Lưu ngay",
      "cancel": "Đóng cửa sổ bật lên sẽ hủy tệp PDF đang được tải xuống. Bạn có chắc chắn muốn hủy không?",
      "desc": "Lưu vào máy tính: Bản dịch đã tải xuống được lưu dưới dạng hình ảnh; Lưu và in: Bản dịch đã tải xuống được giữ nguyên định dạng ban đầu và hỗ trợ sao chép.",
      "download": "Tải xuống",
      "downloadMsg1": "Tiến độ dịch PDF:",
      "downloadMsg2": "Dự kiến yêu cầu",
      "downloadSucc": "PDF đã sẵn sàng để tải xuống, nhấp để lưu ngay!",
      "downloadTip": "PDF đang được tải xuống. Không đóng cửa sổ bật lên trong khi tải xuống để tránh tải xuống không thành công.",
      "error": "Lưu vào máy tính không thành công, vui lòng thử in bản lưu",
      "fileName": "Tên tệp:",
      "fileType": "Loại tệp tải xuống:",
      "loading": "Đã lưu...",
      "loading2": "Đang tải...",
      "minute": "Phút...",
      "msg1": "Một số bản dịch của tài liệu hiện tại dài hơn văn bản gốc và văn bản có thể bị chồng chéo sau khi tải xuống, vì vậy bạn nên đọc nó trên trang web.",
      "msg2": "1、Nội dung chưa được dịch đầy đủ, vui lòng tải xuống sau khi dịch xong.",
      "msg3": "2、Một phần bản dịch của tài liệu quá dài, bạn có thể đọc trong trang web để tránh văn bản bị chồng chéo.",
      "msg4": "Hiện tại đang có tác vụ đang được dịch, vui lòng thử lại sau khi quá trình dịch hoàn tất.",
      "progress": "PDF đang được dịch. Tiến độ: {progress}, dự kiến sẽ mất {minutes} phút...",
      "save": "Lưu vào máy tính",
      "savePrint": "Lưu và In",
      "success": "Tải xuống thành công",
      "trans": "Chỉ dịch",
      "transContinue": "Tiếp tục dịch"
    },
    "downloadBtn": "Tải xuống",
    "dragTipModal": {
      "black": "dấu cách",
      "leftKey": "phím mũi tên trái",
      "ok": "Tôi đã hiểu",
      "press": "ấn",
      "tips": "để kéo và xem."
    },
    "entry": "Danh sách PDF",
    "fileErrMsg": "Đọc tệp không thành công, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng.",
    "fileMsg": "Dung lượng tệp hiện tại:",
    "fileMsg2": "Vui lòng tải lên một tệp nhỏ hơn 100M",
    "freeBannerBtn": "Nâng cấp để trải nghiệm thêm",
    "freeBannerTips": "Kết thúc bản dịch PDF miễn phí",
    "guide": {
      "placeholder": "Vui lòng điền liên kết PDF",
      "start": "Bắt đầu dịch",
      "text1": "Đọc song ngữ",
      "text2": "Đọc song ngữ, vì vậy bạn có thể so sánh văn bản gốc và bản dịch trong khi đọc để cải thiện hiệu quả đọc",
      "text3": "Trò chuyện PDF",
      "text4": "AI phân tích và tóm tắt và có thể trả lời các câu hỏi của bạn dựa trên nội dung PDF",
      "text5": "Độ chính xác và hiệu quả",
      "text6": "PDf được hỗ trợ bởi AI để có bản dịch chính xác và hiệu quả hơn",
      "title1": "Dịch song ngữ ",
      "title2": "Trò chuyện PDF",
      "toast": "Vui lòng nhập liên kết pdf trực tuyến hợp lệ kết thúc bằng .pdf"
    },
    "hasTransTask": "Đang có một tác vụ đang được dịch. Vui lòng chờ.",
    "hoverTrans": "Di chuột để dịch đoạn văn",
    "initial": "Dịch PDF",
    "installText": "Giải pháp một cửa cho mọi nhu cầu dịch hàng ngày của bạn",
    "installText2": "Vui lòng cài đặt  trước",
    "installTips": "Cài đặt tiện ích mở rộng nhỏ, bạn có thể dịch thêm nhiều tệp PDF, bạn cũng có thể trải nghiệm dịch trang web mượt mà:",
    "kw": "ghim",
    "link": "liên kết",
    "listError": "Không tải được danh sách PDF, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng.",
    "memoTotalCount": "Tổng cộng 1 nội dung",
    "new": "tệp mới",
    "noId": "ID tệp không tồn tại",
    "notPdf": "Chỉ tệp PDF",
    "okBtn": "Xóa",
    "okBtn2": "nhận biết",
    "original": "Bản gốc",
    "parallel": "Song song",
    "parseErrDialogContent": "Liên kết PDF hiện tại không hỗ trợ dịch trực tiếp, vui lòng tải xuống liên kết PDF gốc sau khi dịch",
    "parseErrTips": "Không thể phân tích tệp",
    "parseFileToast": "Phân tích cú pháp tệp không thành công, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng của chúng tôi.",
    "pdfLoading": "Đang phân tích...",
    "searchPlaceholder": "Tìm kiếm",
    "selectTrans": "Gạch chân các từ và dịch",
    "shareBtn": "Chia sẻ",
    "shareBtnText1": "Tạo liên kết chia sẻ",
    "shareBtnText2": "Cập nhật nội dung chia sẻ",
    "shareModal": {
      "cancel": "Hủy",
      "copy": "Sao chép",
      "copySucc": "Sao chép liên kết thành công",
      "custom": "Tùy chỉnh:",
      "errMsg": "Nội dung của phạm vi chia sẻ hiện tại chưa được dịch đầy đủ, vui lòng dịch trước khi chia sẻ.",
      "gen": "Chia sẻ",
      "modify": "chỉnh sửa",
      "notSupport": "Ngôn ngữ này hiện chưa được hỗ trợ",
      "page": "trang",
      "pageNuErr": "Vui lòng nhập đúng giá trị số trang",
      "range": "Phạm vi:",
      "remain": "còn lại",
      "remain1": "Tổng dự kiến yêu cầu",
      "search": "Tìm kiếm",
      "settings": "Cài đặt chia sẻ:",
      "shareSetting": "Cài đặt nội dung chia sẻ",
      "shareText": "Chia sẻ",
      "shareTips": "Chia sẻ liên kết PDF đã dịch với bạn bè của bạn để đọc đối chiếu:",
      "sourceLang": "Nguồn:",
      "target": "Mục tiêu:",
      "text1": "Cài đặt chia sẻ",
      "text2": "Tạo liên kết chia sẻ",
      "totalPage": "Tổng cộng 1 trang",
      "trans": "Dịch",
      "translateTips": "Mẹo: Nếu việc dịch mất nhiều thời gian, bạn có thể đóng cửa sổ bật lên và chia sẻ sau khi bản dịch hoàn tất.",
      "translating": "Đang dịch PDF...",
      "update": "Đã cập nhật liên kết chia sẻ"
    },
    "shareMsg1": "Tạo liên kết chia sẻ thành công",
    "shareMsg2": "Cập nhật nội dung chia sẻ thành công",
    "shareMsg3": "Tạo liên kết chia sẻ không thành công, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng của chúng tôi.",
    "shareMsg4": "Cập nhật nội dung chia sẻ không thành công, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng",
    "shareTips": "Bản dịch PDF đã hoàn tất, hãy tiếp tục và chia sẻ nó với bạn bè của bạn!",
    "start": "Bắt đầu trải nghiệm miễn phí",
    "thumbnail": "danh mục",
    "toast1": "Đang tải PDF, vui lòng thử lại sau!",
    "toast2": "Nội dung PDF quá nhiều, hiện tại chưa hỗ trợ hộp thoại",
    "toast3": "Đang tải xuống, vui lòng thử lại sau khi tải xuống xong.",
    "toolbar": {
      "Adjust": "Điều chỉnh",
      "autoFit": "Tự động vừa"
    },
    "trans": "Bản dịch",
    "transSucc": "Bản dịch đã thành công!",
    "unLoginToast": "Vui lòng đăng nhập để sử dụng chức năng này",
    "uploadBtn": "Tải lên",
    "uploadErr": "Tải tệp lên không thành công. Vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng",
    "uploadMsg1": "Vui lòng kéo và thả tệp vào đây",
    "uploadMsg2": "Các loại tệp được hỗ trợ: PDF  |  Dung lượng tệp tối đa: 100MB",
    "uploadMsg3": "Nhấp để chọn hoặc kéo và thả tệp để tải lên ở đây",
    "uploading": "Đang tải lên",
    "uploadingMsg": "Vui lòng kiên nhẫn vì quá trình tải lên sẽ mất một chút thời gian.",
    "uploadingMsg1": "Tệp đang được tải lên. Vui lòng thử lại sau.",
    "uploadingMsg2": "Hiện tại bạn đang có một tệp đang được chuyển đổi, vui lòng thử lại sau.",
    "uploadingMsg3": "Yêu cầu tiến độ chuyển đổi không thành công, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng",
    "uploadingMsg4": "Noam không thể truy cập trực tiếp vào các tệp cục bộ của bạn, vui lòng tải lên PDF để dịch theo cách thủ công.",
    "uploadingMsg5": "Không lấy được url của tệp, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng.",
    "video": "video",
    "vipBannerBtn": "Nâng cấp để trải nghiệm thêm",
    "vipBannerTips": "Bạn đã đạt đến giới hạn dịch pdf trong tháng này.",
    "webTrans": "Tham khảo chéo đầy đủ"
  },
  "pricing": {
    "FAQ": "Câu hỏi thường gặp",
    "FQAObj": {
      "a1": "Có phiên bản Noam miễn phí để bạn sử dụng trực tiếp.",
      "a2": "Ngoài phiên bản miễn phí, còn có ba gói thành viên: Pro, Pro+ và Doanh nghiệp; bạn có thể chọn giữa hai phương thức thanh toán, trả hàng tháng và trả hàng năm, chi phí trả hàng năm sẽ ưu đãi hơn.",
      "a3": "Không có sự khác biệt về chức năng. Chi phí trả hàng năm sẽ thấp hơn so với trả hàng tháng; đăng ký hàng tháng được lập hóa đơn một lần mỗi tháng, trong khi đăng ký hàng năm được lập hóa đơn một lần mỗi năm.",
      "a4": "Bạn có thể truy cập trang cài đặt phụ trợ và chọn hủy gói gia hạn.",
      "q1": "Tôi có thể dùng thử miễn phí không?",
      "q2": "Có các gói thành viên và tùy chọn phí khác nhau không?",
      "q3": "Sự khác biệt giữa đăng ký trả hàng tháng và đăng ký trả hàng năm là gì?",
      "q4": "Làm cách nào để hủy gói tự động gia hạn?"
    },
    "around": "Xấp xỉ",
    "billedMonthly": "Thanh toán hàng tháng",
    "billedYearly": "Thanh toán hàng năm",
    "day": "ngày",
    "desc": "So sánh và lựa chọn gói phù hợp nhất với bạn",
    "detailedCompare": "So sánh chi tiết ",
    "discount": "Giảm 20%",
    "enterprise": {
      "benefit1": "Hỗ trợ khách hàng nhanh chóng",
      "benefit2": "Nhiều người dùng",
      "benefit3": "Giải pháp tùy chỉnh",
      "benefit5": "Quản lý tài khoản chuyên dụng",
      "btn": "Liên hệ",
      "recommendText": "Gói tùy chỉnh（≥3人）",
      "talkToUs": "Liên hệ với chúng tôi"
    },
    "free": {
      "benefit1": "Dịch tức thì ${FREE_BENEFITS.realTimeCount} lần/ngày",
      "benefit2": "Dịch & tóm tắt trang web",
      "benefit3": "Dịch hình ảnh ${FREE_BENEFITS.imageCount} trang",
      "benefit4": "Dịch & tóm tắt video",
      "benefit5": "Dịch ${FREE_BENEFITS.pdfUploadCount} tệp PDF",
      "benefit6": "${FREE_BENEFITS.tokenCount} token",
      "btn": "Trải nghiệm ngay",
      "recommendText": "Dùng thử miễn phí"
    },
    "imageTranslation": "Dịch hình ảnh",
    "month": "tháng",
    "monthly": "Hàng tháng",
    "pageTitle": "Gói cước",
    "pdf": {
      "chat": "Số lượng yêu cầu PDF",
      "maxPage": "Số trang tối đa trong PDF",
      "translation": "Dịch PDF"
    },
    "preMonth": "Mỗi tháng",
    "pro": {
      "benefit1": "Hỗ trợ khách hàng nhanh chóng",
      "benefit2": "${PRO_BENEFITS.tokenCount} tokens/tháng",
      "benefit3": "Dịch & tóm tắt trang web",
      "benefit4": "Dịch hình ảnh ${PRO_BENEFITS.imageCount} trang",
      "benefit5": "Dịch & tóm tắt video",
      "benefit6": "Dịch ${PRO_BENEFITS.pdfUploadCount} tệp PDF",
      "btn": "Bắt đầu nào",
      "recommendText": "Lựa chọn phổ biến"
    },
    "proPlus": {
      "benefit1": "Hỗ trợ khách hàng nhanh chóng",
      "benefit2": "${PRO_V_BENEFITS.tokenCount} tokens/tháng",
      "benefit3": "Dịch & tóm tắt trang web",
      "benefit4": "Dịch hình ảnh ${PRO_V_BENEFITS.imageCount} trang",
      "benefit5": "Dịch & tóm tắt video",
      "benefit6": "Dịch ${PRO_V_BENEFITS.pdfUploadCount} tệp PDF",
      "recommendText": "Lựa chọn phù hợp nhất"
    },
    "video": {
      "summary": "Tóm tắt video",
      "translation": "Dịch video"
    },
    "web": {
      "contrastiveTranslation": "Dịch song ngữ",
      "dictionaryTranslation": "Chọn văn bản để dịch",
      "hoverTranslation": "Di chuột để dịch",
      "instantTranslation": "Dịch tức thì",
      "summary": "Tóm tắt trang web"
    },
    "yearly": "Hàng năm"
  },
  "privacy": {
    "block1": "Chúng tôi, Noam (được gọi là \"Noam,\" \"chúng tôi,\" \"của chúng tôi,\" hoặc \"chúng ta\"), tôn trọng quyền riêng tư của bạn và cam kết bảo vệ bất kỳ thông tin nào chúng tôi thu thập được từ bạn hoặc về bạn. Chính sách Bảo mật này mô tả các hoạt động của chúng tôi liên quan đến việc thu thập hoặc sử dụng thông tin cá nhân từ việc bạn sử dụng trang web, ứng dụng và dịch vụ của chúng tôi (gọi chung là \"Dịch vụ\"). Chính sách Bảo mật này không áp dụng cho nội dung chúng tôi xử lý thay mặt khách hàng sản phẩm doanh nghiệp của mình, chẳng hạn như API của chúng tôi. Việc sử dụng dữ liệu này tuân theo thỏa thuận khách hàng của chúng tôi, trong đó bao gồm quyền truy cập và sử dụng các sản phẩm này.",
    "block10": "Chúng tôi thực hiện các biện pháp kỹ thuật, hành chính và tổ chức hợp lý về mặt thương mại để bảo vệ thông tin cá nhân trực tuyến và ngoại tuyến khỏi bị mất mát, sử dụng sai mục đích và truy cập trái phép, tiết lộ, thay đổi hoặc hành vi thù địch. Tuy nhiên, không có đường truyền internet hoặc email nào có thể đảm bảo bảo mật hoặc chính xác hoàn toàn. Đặc biệt, email được gửi đến chúng tôi hoặc từ chúng tôi có thể không an toàn. Vì vậy, bạn nên đặc biệt cẩn thận khi quyết định thông tin nào sẽ gửi cho chúng tôi thông qua dịch vụ hoặc email. Ngoài ra, chúng tôi không chịu trách nhiệm cho bất kỳ sự cản trở nào đối với cài đặt quyền riêng tư hoặc biện pháp bảo mật trong dịch vụ hoặc cài đặt bảo mật hoặc biện pháp bảo mật của Trang web của bên thứ ba. Chúng tôi sẽ lưu giữ thông tin cá nhân của bạn trong thời gian cần thiết để chúng tôi cung cấp dịch vụ cho bạn hoặc cho các mục đích kinh doanh hợp pháp khác, chẳng hạn như giải quyết tranh chấp, lý do bảo mật và an toàn hoặc tuân thủ các nghĩa vụ pháp lý của chúng tôi. Khoảng thời gian chúng tôi lưu giữ thông tin cá nhân sẽ phụ thuộc vào nhiều yếu tố, chẳng hạn như số lượng, bản chất, mức độ nhạy cảm của thông tin, rủi ro tiềm ẩn của việc sử dụng hoặc tiết lộ trái phép, mục đích chúng tôi xử lý thông tin và các cân nhắc ràng buộc pháp lý khác.",
    "block11": "Chúng tôi có thể cập nhật Chính sách Bảo mật này theo thời gian. Khi chúng tôi thực hiện việc này, chúng tôi sẽ đăng phiên bản cập nhật trên trang này, trừ khi luật hiện hành yêu cầu khác.",
    "block12": "Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc chưa được giải đáp nào về Chính sách Bảo mật này, vui lòng liên hệ với dịch vụ khách hàng của chúng tôi. Email: ",
    "block2": "Chúng tôi thu thập các thông tin cá nhân sau đây (\"Thông tin Cá nhân\") liên quan đến bạn:",
    "block3": "Thông tin do bạn cung cấp: Nếu bạn tạo tài khoản để sử dụng dịch vụ của chúng tôi hoặc liên lạc với chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân như sau:",
    "block4": "Thông tin cá nhân được nhận tự động từ việc bạn sử dụng Dịch vụ: Khi bạn truy cập, sử dụng hoặc tương tác với các dịch vụ, chúng tôi nhận được thông tin sau về quyền truy cập, sử dụng hoặc tương tác của bạn (\"Thông tin Kỹ thuật\"):",
    "block5": "Chúng tôi có thể sử dụng thông tin cá nhân cho các mục đích sau:",
    "block6": "Thông tin tóm tắt hoặc được mã hóa: Chúng tôi có thể tổng hợp hoặc mã hóa thông tin cá nhân để ngăn việc sử dụng lại cho mục đích nhận dạng và sử dụng thông tin đó để phân tích hiệu quả của dịch vụ, cải thiện và thêm tính năng cho dịch vụ của chúng tôi, tiến hành nghiên cứu và cho các mục đích tương tự. Ngoài ra, chúng tôi có thể phân tích hành vi và đặc điểm chung của người dùng dịch vụ của mình và chia sẻ thông tin tổng hợp với bên thứ ba, xuất bản thông tin tổng hợp đó hoặc cung cấp thông tin đó cho công chúng. Chúng tôi có thể thu thập thông tin tổng hợp thông qua các dịch vụ, thông qua cookie và thông qua các phương tiện khác được mô tả trong Chính sách Bảo mật này. Chúng tôi sẽ duy trì và sử dụng thông tin được mã hóa ở dạng ẩn danh hoặc được mã hóa và chúng tôi sẽ không cố gắng xác định lại thông tin đó trừ khi luật pháp yêu cầu.",
    "block7": "Trong một số trường hợp nhất định, chúng tôi có thể cung cấp thông tin cá nhân của bạn cho bên thứ ba mà không cần thông báo thêm cho bạn, trừ khi luật pháp yêu cầu:",
    "block8": "Tùy thuộc vào vị trí địa lý của bạn, các cá nhân trong Khu vực Kinh tế Châu Âu, Vương quốc Anh và trên toàn cầu có thể có một số quyền theo luật định nhất định liên quan đến thông tin cá nhân của họ. Ví dụ: bạn có thể có quyền:",
    "block9": "Dịch vụ có thể chứa các liên kết đến các trang web khác không do Noam vận hành hoặc kiểm soát, bao gồm các dịch vụ mạng xã hội (\"Trang web của bên thứ ba\"). Thông tin bạn chia sẻ với Trang web của bên thứ ba sẽ được điều chỉnh bởi các chính sách bảo mật và điều khoản dịch vụ cụ thể của Trang web của bên thứ ba, chứ không phải bởi Chính sách Bảo mật này. Chúng tôi cung cấp các liên kết này để thuận tiện cho bạn và không ngụ ý sự chứng thực hoặc đánh giá của chúng tôi đối với các trang web này. Vui lòng liên hệ trực tiếp với Trang web của bên thứ ba để biết thông tin về chính sách bảo mật và điều khoản của họ.",
    "subBlock1": "Thông tin tài khoản: Khi bạn tạo tài khoản trên nền tảng của chúng tôi, chúng tôi sẽ thu thập thông tin liên quan đến tài khoản của bạn, bao gồm tên, thông tin liên lạc, thông tin đăng nhập tài khoản, thông tin thẻ thanh toán và lịch sử giao dịch của bạn (được gọi là \"Thông tin Tài khoản\").",
    "subBlock10": "Cung cấp, quản lý, duy trì và/hoặc phân tích dịch vụ;",
    "subBlock11": "Liên lạc với bạn;",
    "subBlock12": "Phát triển các dự án và dịch vụ mới;",
    "subBlock13": "Ngăn chặn gian lận, hoạt động tội phạm hoặc lạm dụng dịch vụ của chúng tôi và bảo vệ tính bảo mật cho hệ thống CNTT, kiến ​​trúc và mạng của chúng tôi;",
    "subBlock14": "Tiến hành chuyển giao kinh doanh;",
    "subBlock15": "Tuân thủ các nghĩa vụ pháp lý và tố tụng pháp lý, bảo vệ quyền, quyền riêng tư, bảo mật hoặc tài sản của chúng tôi, cũng như của các chi nhánh của chúng tôi, bạn hoặc các bên thứ ba khác.",
    "subBlock16": "Nhà cung cấp và Nhà cung cấp dịch vụ: Để hỗ trợ chúng tôi đáp ứng nhu cầu hoạt động kinh doanh và thực hiện một số dịch vụ và chức năng nhất định, chúng tôi có thể cung cấp thông tin cá nhân cho các nhà cung cấp và nhà cung cấp dịch vụ, bao gồm nhà cung cấp dịch vụ lưu trữ, nhà cung cấp dịch vụ đám mây và nhà cung cấp dịch vụ công nghệ thông tin khác, phần mềm truyền thông qua email và nhà cung cấp dịch vụ phân tích web, v.v. Các đối tác này sẽ chỉ truy cập, xử lý hoặc lưu trữ thông tin cá nhân theo chỉ đạo của chúng tôi khi thực hiện nhiệm vụ của họ cho chúng tôi.",
    "subBlock17": "Chuyển giao kinh doanh: Nếu chúng tôi tham gia vào các giao dịch chiến lược, tái tổ chức, phá sản, tiếp quản phá sản hoặc chuyển đổi dịch vụ sang nhà cung cấp khác (gọi chung là \"Giao dịch\"), thông tin cá nhân của bạn và thông tin khác có thể được chia sẻ với các bên tham gia giao dịch và các bên khác hỗ trợ Giao dịch trong quá trình thẩm định và chuyển giao như một phần của Giao dịch cùng với các tài sản khác cho công ty kế nhiệm hoặc công ty liên kết.",
    "subBlock18": "Yêu cầu pháp lý: Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với cơ quan chính phủ, đồng nghiệp trong ngành hoặc bên thứ ba khác, bao gồm thông tin tương tác của bạn với dịch vụ của chúng tôi, (1) nếu luật pháp yêu cầu hoặc nếu chúng tôi tin rằng hành động đó là cần thiết để tuân thủ pháp lý nghĩa vụ; (2) để bảo vệ và bảo vệ quyền hoặc tài sản của chúng tôi; (3) nếu chúng tôi đơn phương xác định rằng có sự vi phạm các điều khoản, chính sách hoặc luật của chúng tôi; (4) để phát hiện hoặc ngăn chặn gian lận hoặc các hoạt động bất hợp pháp khác; (5) để bảo vệ sản phẩm, nhân viên hoặc người dùng của chúng tôi hoặc an toàn công cộng, an ninh, toàn vẹn; hoặc (vi) để bảo vệ chống lại trách nhiệm pháp lý.",
    "subBlock19": "Truy cập thông tin cá nhân của bạn và thông tin về cách thức xử lý thông tin đó.",
    "subBlock2": "Nội dung người dùng: Khi bạn sử dụng dịch vụ của chúng tôi, chúng tôi thu thập thông tin cá nhân từ thông tin đầu vào, tệp tải lên hoặc phản hồi được cung cấp cho chúng tôi (được gọi là \"Nội dung\").",
    "subBlock20": "Xóa thông tin cá nhân của bạn khỏi hồ sơ của chúng tôi.",
    "subBlock21": "Sửa hoặc cập nhật thông tin cá nhân của bạn.",
    "subBlock22": "Chuyển thông tin cá nhân của bạn cho bên thứ ba (khả năng chuyển dữ liệu).",
    "subBlock23": "Giới hạn cách chúng tôi xử lý thông tin cá nhân của bạn.",
    "subBlock24": "Rút lại sự đồng ý của bạn — trong trường hợp chúng tôi dựa vào sự đồng ý làm cơ sở pháp lý để xử lý, bạn có thể rút lại sự đồng ý của mình bất kỳ lúc nào.",
    "subBlock25": "Phản đối cách chúng tôi xử lý thông tin cá nhân của bạn.",
    "subBlock26": "Cải thiện dịch vụ của chúng tôi và tiến hành nghiên cứu;",
    "subBlock3": "Thông tin liên lạc: Nếu bạn gửi tin nhắn cho chúng tôi, chúng tôi sẽ thu thập tên, thông tin liên lạc của bạn và nội dung tin nhắn bạn gửi (được gọi là \"Thông tin Liên lạc\").",
    "subBlock4": "Thông tin mạng xã hội: Chúng tôi có các trang trên các trang web mạng xã hội như Instagram, Facebook, Medium, Twitter, YouTube và LinkedIn. Khi bạn tương tác với các trang mạng xã hội của chúng tôi, chúng tôi thu thập thông tin cá nhân mà bạn chọn cung cấp cho chúng tôi, chẳng hạn như thông tin liên lạc của bạn (được gọi là \"Thông tin Xã hội\"). Ngoài ra, các công ty lưu trữ các trang mạng xã hội của chúng tôi có thể cung cấp cho chúng tôi thông tin tổng hợp và phân tích về các hoạt động trên mạng xã hội của chúng tôi.",
    "subBlock5": "Dữ liệu nhật ký: Thông tin được gửi tự động bởi trình duyệt của bạn khi sử dụng dịch vụ của chúng tôi. Dữ liệu nhật ký bao gồm địa chỉ Giao thức Internet (IP) của bạn, loại và cài đặt trình duyệt, ngày và giờ của yêu cầu của bạn và cách bạn tương tác với trang web của chúng tôi.",
    "subBlock6": "Dữ liệu sử dụng: Chúng tôi có thể tự động thu thập thông tin về việc bạn sử dụng dịch vụ, chẳng hạn như loại nội dung bạn xem hoặc tương tác, các tính năng bạn sử dụng và hành động bạn thực hiện, cũng như múi giờ, quốc gia, ngày và giờ truy cập của bạn, tác nhân người dùng và phiên bản, loại máy tính hoặc thiết bị di động và kết nối máy tính của bạn.",
    "subBlock7": "Thông tin thiết bị: Bao gồm tên thiết bị, hệ điều hành, mã định danh thiết bị và trình duyệt bạn đang sử dụng. Thông tin được thu thập có thể phụ thuộc vào loại thiết bị bạn đang sử dụng và cài đặt của nó.",
    "subBlock8": "Cookie: Chúng tôi sử dụng cookie để vận hành và quản lý dịch vụ của mình và cải thiện trải nghiệm của bạn. \"Cookie\" là thông tin được gửi bởi một trang web bạn truy cập vào trình duyệt của bạn. Bạn có thể đặt trình duyệt của mình để chấp nhận tất cả cookie, từ chối tất cả cookie hoặc thông báo cho bạn khi có cookie được cung cấp để bạn có thể quyết định có chấp nhận cookie đó hay không mỗi lần. Tuy nhiên, việc từ chối cookie có thể khiến bạn không thể sử dụng hoặc ảnh hưởng tiêu cực đến cách hiển thị hoặc chức năng của một số khu vực hoặc tính năng nhất định của trang web. Để biết thêm thông tin về cookie, vui lòng truy cập Tất cả về Cookie.",
    "subBlock9": "Phân tích: Chúng tôi có thể sử dụng nhiều sản phẩm phân tích trực tuyến khác nhau sử dụng cookie để giúp chúng tôi phân tích cách người dùng sử dụng dịch vụ của chúng tôi và cải thiện trải nghiệm của bạn khi sử dụng dịch vụ.",
    "subTitle1": "Thông tin cá nhân chúng tôi thu thập",
    "subTitle2": "Cách chúng tôi sử dụng thông tin cá nhân",
    "subTitle3": "Tiết lộ thông tin cá nhân",
    "subTitle4": "Quyền của bạn",
    "subTitle5": "Liên kết đến các trang web khác",
    "subTitle6": "Bảo mật và Lưu giữ Thông tin",
    "subTitle7": "Sửa đổi Chính sách Bảo mật",
    "subTitle8": "Cách liên hệ với chúng tôi",
    "title": "Chính sách bảo mật",
    "updateTime": "Cập nhật: 15 Tháng Ba, 2024"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "Chấp nhận tất cả",
    "text": "Để cải thiện trải nghiệm của bạn, chúng tôi muốn sử dụng cookie để cá nhân hóa nội dung và dịch vụ.",
    "title": "Trang web này sử dụng cookie"
  },
  "slogen": "Trợ lý AI dễ sử dụng nhất",
  "stripe": {
    "contact": "Liên hệ với chúng tôi tại contact@noam.tools nếu có thắc mắc!",
    "fail": {
      "btn": "Thử Lại",
      "text1": "Rất tiếc, chúng tôi không thể xử lý yêu cầu thanh toán của bạn.",
      "text2": "Vui lòng kiểm tra kết nối Internet của bạn và thử lại.",
      "title": "Thanh toán thất bại"
    },
    "succ": {
      "btn": "Bắt Đầu Nào",
      "text1": "chào mừng bạn đến với Noam, ",
      "text2": "hãy bắt đầu nào!",
      "title": "Chúc mừng"
    }
  },
  "terms": {
    "block1": "Cảm ơn bạn đã sử dụng Noam!",
    "block10": "(a) Phí và Lập hóa đơn. Bạn sẽ thanh toán tất cả các khoản phí (\"Phí\") theo giá cả và điều khoản trên trang giá hiện hành hoặc theo thỏa thuận bằng văn bản giữa chúng tôi. Chúng tôi có quyền sửa chữa lỗi giá hoặc sai sót ngay cả khi chúng tôi đã xuất hóa đơn hoặc nhận được thanh toán. Bạn sẽ cung cấp thông tin thanh toán đầy đủ và chính xác, bao gồm phương thức thanh toán hợp lệ và được ủy quyền. Chúng tôi sẽ tính phí phương thức thanh toán của bạn theo định kỳ như đã thỏa thuận, nhưng chúng tôi có thể thay đổi ngày lập hóa đơn một cách hợp lý. Bạn ủy quyền cho Noam, các chi nhánh của Noam và các bên xử lý thanh toán bên thứ ba tính phí vào phương thức thanh toán của bạn. Nếu thanh toán của bạn không thành công, chúng tôi sẽ thông báo bằng văn bản cho bạn và có thể tạm ngừng quyền truy cập vào Dịch vụ cho đến khi nhận được thanh toán. Trừ khi có quy định khác trong thỏa thuận này, các khoản thanh toán sẽ không được hoàn lại.",
    "block11": "(b) Thuế. Trừ khi có quy định khác, Phí không bao gồm bất kỳ loại thuế liên bang, tiểu bang, địa phương và nước ngoài, thuế quan và các khoản đánh giá tương tự (\"Thuế\"). Bạn chịu trách nhiệm cho tất cả Thuế liên quan đến giao dịch mua của mình, ngoại trừ Thuế dựa trên thu nhập ròng của chúng tôi mà chúng tôi sẽ lập hóa đơn cho bạn. Bạn đồng ý thanh toán kịp thời các khoản Thuế đó và cung cấp cho chúng tôi các tuyên bố về quyền riêng tư hợp lệ và nhận được sự đồng ý cần thiết để xử lý dữ liệu đó, đồng thời bạn đảm bảo với chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành.",
    "block12": "(c) Thay đổi giá. Chúng tôi có thể thay đổi giá bằng cách thông báo cho bạn thông qua tài khoản của bạn và/hoặc trang web của chúng tôi. Việc tăng giá sẽ có hiệu lực ngay lập tức sau khi đăng. Mọi thay đổi về giá sẽ được áp dụng cho Phí cho tài khoản của bạn sau ngày thay đổi có hiệu lực.",
    "block13": "(d) Tranh chấp và Thanh toán quá hạn. Nếu bạn muốn tranh chấp bất kỳ khoản Phí hoặc Thuế nào, vui lòng liên hệ contact@noam.tools trong vòng ba mươi (30) ngày kể từ ngày lập hóa đơn.",
    "block14": "(e) Sử dụng Miễn phí. Bạn không được tạo nhiều tài khoản để nhận tín dụng miễn phí. Nếu chúng tôi xác định rằng bạn không sử dụng tín dụng miễn phí một cách thiện chí, chúng tôi có thể tính phí tiêu chuẩn cho bạn hoặc ngừng cung cấp quyền truy cập vào Dịch vụ.",
    "block15": "(a) Bảo mật. Bạn có thể có quyền truy cập vào thông tin mật của Noam. Bạn chỉ có thể sử dụng thông tin mật theo như được phép theo các điều khoản này cho mục đích sử dụng Dịch vụ. Bạn không được tiết lộ thông tin mật cho bất kỳ bên thứ ba nào và bạn sẽ bảo vệ thông tin mật theo cách thức không kém phần bảo vệ so với cách bạn bảo vệ thông tin mật tương tự của chính mình, ít nhất là với sự quan tâm hợp lý. Thông tin mật là thông tin không công khai được Noam chỉ định là bí mật hoặc thông tin đó phải được coi là bí mật một cách hợp lý trong các trường hợp nhất định, bao gồm phần mềm, thông số kỹ thuật và thông tin kinh doanh không công khai khác. Thông tin mật không bao gồm thông tin: (1) được công chúng biết đến rộng rãi mà không phải do lỗi của bạn; (2) bạn đã sở hữu mà không có bất kỳ nghĩa vụ bảo mật nào trước khi nhận được thông tin đó theo các điều khoản này; (3) được bên thứ ba tiết lộ hợp pháp cho bạn mà không có bất kỳ nghĩa vụ bảo mật nào; hoặc (4) do bạn tự phát triển mà không sử dụng thông tin mật. Bạn có thể tiết lộ thông tin mật nếu luật pháp, tòa án hoặc lệnh của chính phủ yêu cầu, nhưng bạn phải thông báo trước bằng văn bản cho Noam một cách hợp lý và, trong phạm vi có thể, hãy nỗ lực hợp lý để hạn chế phạm vi tiết lộ, bao gồm hỗ trợ chúng tôi trong việc phản đối các yêu cầu tiết lộ.",
    "block16": "(b) An ninh. Bạn phải thực hiện các biện pháp hợp lý và thích hợp để giúp bảo vệ quyền truy cập và sử dụng Dịch vụ của bạn. Nếu bạn phát hiện bất kỳ lỗ hổng hoặc vi phạm nào liên quan đến việc bạn sử dụng Dịch vụ, bạn phải liên hệ ngay với Noam và cung cấp thông tin chi tiết về lỗ hổng hoặc vi phạm.",
    "block17": "((c) Xử lý Dữ liệu Cá nhân. Nếu bạn sử dụng Dịch vụ để xử lý dữ liệu cá nhân, bạn phải cung cấp các tuyên bố về quyền riêng tư đầy đủ về mặt pháp lý và nhận được sự đồng ý cần thiết để xử lý dữ liệu đó, đồng thời bạn đảm bảo với chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành.",
    "block18": "(a) Chấm dứt; Đình chỉ. Các điều khoản này sẽ có hiệu lực kể từ lần đầu tiên bạn sử dụng Dịch vụ và sẽ tiếp tục có hiệu lực cho đến khi bị chấm dứt. Bạn có thể chấm dứt các điều khoản này bất cứ lúc nào vì bất kỳ lý do gì bằng cách ngừng sử dụng Dịch vụ và Nội dung. Chúng tôi có thể chấm dứt các điều khoản này với thông báo trước vì bất kỳ lý do gì. Chúng tôi có thể chấm dứt ngay lập tức các điều khoản này bằng cách thông báo cho bạn nếu bạn vi phạm nghiêm trọng Mục 2 (Yêu cầu sử dụng), Mục 5 (Bảo mật, An ninh và Bảo vệ Dữ liệu), Mục 8 (Giải quyết Tranh chấp) hoặc Mục 9 (Điều khoản Chung) hoặc nếu mối quan hệ của chúng tôi với bất kỳ nhà cung cấp công nghệ bên thứ ba nào ngoài tầm kiểm soát của chúng tôi thay đổi hoặc để tuân thủ luật pháp hoặc yêu cầu của chính phủ. Chúng tôi có thể tạm ngừng quyền truy cập của bạn vào Dịch vụ nếu bạn không tuân thủ các điều khoản này hoặc nếu việc sử dụng của bạn gây rủi ro bảo mật cho chúng tôi hoặc bất kỳ bên thứ ba nào hoặc nếu chúng tôi nghi ngờ việc sử dụng của bạn là gian lận hoặc có thể khiến chúng tôi hoặc bất kỳ bên thứ ba nào phải chịu trách nhiệm pháp lý.",
    "block19": "(b) Hiệu lực của việc Chấm dứt. Khi chấm dứt, bạn sẽ ngừng sử dụng Dịch vụ và nhanh chóng trả lại hoặc tiêu hủy mọi thông tin mật theo chỉ dẫn của chúng tôi. Các điều khoản của các điều khoản này mà theo bản chất của chúng sẽ tiếp tục có hiệu lực sau khi chấm dứt hoặc hết hạn, bao gồm nhưng không giới hạn ở Mục 3 và 5-9, sẽ tiếp tục có hiệu lực.",
    "block2": "Các Điều khoản Sử dụng này được áp dụng cho việc bạn sử dụng các dịch vụ của Noam, bao gồm giao diện lập trình ứng dụng, phần mềm, công cụ, dịch vụ dành cho nhà phát triển, dữ liệu, tài liệu và trang web của chúng tôi (gọi chung là \"Dịch vụ\"). Khi sử dụng Dịch vụ của chúng tôi, bạn đồng ý tuân thủ các điều khoản này. Chính sách Quyền riêng tư của chúng tôi giải thích cách chúng tôi thu thập và sử dụng thông tin cá nhân.",
    "block20": "(a) Bồi thường. Bạn sẽ bảo vệ, bồi thường và giữ cho chúng tôi, các chi nhánh của chúng tôi và nhân viên của chúng tôi không bị tổn hại trước bất kỳ khiếu nại, tổn thất và chi phí nào (bao gồm cả phí luật sư) phát sinh từ hoặc liên quan đến việc bạn sử dụng Dịch vụ, bao gồm Nội dung của bạn, việc bạn sử dụng các sản phẩm hoặc dịch vụ liên quan đến Dịch vụ và việc bạn vi phạm các điều khoản này hoặc luật hiện hành.",
    "block21": "(b) Miễn trừ trách nhiệm. Dịch vụ được cung cấp \"nguyên trạng\". Trong phạm vi luật pháp cho phép, chúng tôi và các chi nhánh và người cấp phép của chúng tôi không đưa ra bất kỳ tuyên bố hoặc bảo đảm nào về Dịch vụ và không đưa ra bất kỳ bảo đảm nào, bao gồm nhưng không giới hạn ở bảo đảm về khả năng bán được, tính phù hợp cho một mục đích cụ thể, chất lượng thỏa đáng, không vi phạm và hưởng thụ yên tĩnh và bất kỳ bảo đảm nào phát sinh từ quá trình giao dịch hoặc thông lệ thương mại. Chúng tôi không đảm bảo rằng Dịch vụ sẽ không bị gián đoạn, chính xác hoặc không có lỗi hoặc bất kỳ Nội dung nào sẽ được an toàn, không bị mất hoặc không bị thay đổi.",
    "block22": "(c) Giới hạn Trách nhiệm pháp lý. Cả chúng tôi, các chi nhánh của chúng tôi cũng như người cấp phép của chúng tôi sẽ không chịu trách nhiệm cho bất kỳ thiệt hại gián tiếp, ngẫu nhiên, đặc biệt, do hậu quả hoặc thiệt hại mang tính trừng phạt nào, bao gồm mất lợi nhuận, mất uy tín, mất quyền sử dụng, mất dữ liệu hoặc các tổn thất vô hình khác, ngay cả khi chúng tôi đã được thông báo về khả năng xảy ra các thiệt hại đó. Theo các điều khoản này, tổng trách nhiệm pháp lý của chúng tôi sẽ không vượt quá Phí bạn đã trả cho Dịch vụ trong mười hai (12) tháng trước khiếu nại hoặc một trăm đô la Mỹ ($100), tùy theo mức nào cao hơn. Các giới hạn trong mục này được áp dụng trong phạm vi tối đa mà luật hiện hành cho phép.",
    "block23": "Các Điều khoản và việc bạn sử dụng Dịch vụ được điều chỉnh bởi luật pháp Hoa Kỳ, ngoại trừ các quy tắc xung đột luật pháp của Hoa Kỳ. Việc bạn sử dụng Ứng dụng cũng có thể phải tuân theo các luật địa phương, tiểu bang, quốc gia hoặc quốc tế khác. Bất kỳ hành động hoặc thủ tục pháp lý nào liên quan đến các Điều khoản này sẽ chỉ được đưa ra tại các tòa án Hoa Kỳ và bạn đồng ý tuân theo thẩm quyền xét xử đối với các vụ kiện cá nhân của các tòa án đó.",
    "block24": "(a) Mối quan hệ của các Bên. Noam và bạn là những nhà thầu độc lập và không bên nào có quyền đại diện hoặc ràng buộc bên kia hoặc nhận nghĩa vụ cho bên kia mà không có sự đồng ý trước bằng văn bản của bên kia.",
    "block25": "(b) Sử dụng Thương hiệu. Bạn không được sử dụng tên, biểu trưng hoặc nhãn hiệu của Noam hoặc bất kỳ chi nhánh nào của Noam mà không có sự đồng ý trước bằng văn bản của chúng tôi.",
    "block26": "Nếu bất kỳ điều khoản nào của các điều khoản này được xác định là không hợp lệ, bất hợp pháp hoặc không thể thực thi, thì các điều khoản còn lại sẽ vẫn có hiệu lực thi hành đầy đủ.",
    "block3": "Nếu bạn dưới 18 tuổi, bạn phải được cha mẹ hoặc người giám hộ hợp pháp cho phép sử dụng Dịch vụ. Nếu bạn sử dụng Dịch vụ thay mặt cho người hoặc tổ chức khác, bạn phải được ủy quyền chấp nhận các điều khoản này thay mặt họ. Bạn phải cung cấp thông tin chính xác và đầy đủ để đăng ký tài khoản. Bạn không được cung cấp thông tin đăng nhập hoặc tài khoản của mình cho bất kỳ ai bên ngoài tổ chức của bạn và bạn chịu trách nhiệm cho tất cả các hoạt động được thực hiện bằng thông tin đăng nhập của bạn.",
    "block4": "(a) Sử dụng Dịch vụ. Bạn có thể truy cập Dịch vụ theo các điều khoản này và chúng tôi cấp cho bạn quyền không độc quyền để sử dụng Dịch vụ. Khi sử dụng Dịch vụ, bạn sẽ tuân thủ các điều khoản này và tất cả các luật hiện hành. Chúng tôi và các chi nhánh của chúng tôi sở hữu tất cả các quyền, tiêu đề và lợi ích trong Dịch vụ.",
    "block5": "(b) Phản hồi. Chúng tôi hoan nghênh phản hồi, nhận xét, ý tưởng, đề xuất và cải tiến. Nếu bạn cung cấp bất kỳ nội dung nào như vậy, chúng tôi có thể sử dụng nó mà không có bất kỳ hạn chế nào và không bồi thường cho bạn.",
    "block6": "(c) Hạn chế. Bạn không được: (1) sử dụng Dịch vụ theo cách vi phạm, chiếm đoạt hoặc vi phạm bất kỳ quyền nào của người khác; (2) thiết kế ngược, dịch ngược, tháo rời, dịch hoặc cố gắng khám phá mã nguồn hoặc các thành phần cơ bản của các mô hình, thuật toán và hệ thống Dịch vụ (trừ khi các hạn chế đó bị luật hiện hành cấm); (3) sử dụng kết quả của Dịch vụ để phát triển các mô hình cạnh tranh với Noam; (4) trích xuất dữ liệu hoặc kết quả từ Dịch vụ bằng bất kỳ phương pháp tự động hoặc lập trình nào trừ khi được phép thông qua API, bao gồm cạo, thu thập web hoặc trích xuất dữ liệu web; (5) thể hiện kết quả của Dịch vụ là do con người tạo ra khi không phải như vậy hoặc vi phạm chính sách sử dụng của chúng tôi; (6) mua, bán hoặc chuyển nhượng các khóa API mà không có sự đồng ý trước của chúng tôi; hoặc (7) cung cấp cho chúng tôi bất kỳ thông tin cá nhân nào của trẻ em dưới 13 tuổi hoặc độ tuổi đồng ý kỹ thuật số hiện hành. Bạn sẽ tuân thủ mọi giới hạn tỷ lệ và các yêu cầu khác trong tài liệu của chúng tôi.",
    "block7": "(d) Dịch vụ của bên thứ ba. Mọi phần mềm, dịch vụ của bên thứ ba hoặc các sản phẩm khác liên quan đến Dịch vụ đều được điều chỉnh bởi các điều khoản riêng của họ và chúng tôi không chịu trách nhiệm cho các sản phẩm của bên thứ ba.",
    "block8": "(a) Nội dung của bạn. Bạn có thể cung cấp thông tin đầu vào (\"Thông tin đầu vào\") cho Dịch vụ và nhận kết quả đầu ra (\"Kết quả đầu ra\") được tạo và trả về dựa trên Thông tin đầu vào (gọi chung là \"Nội dung\"). Giữa các bên và trong phạm vi luật hiện hành cho phép, bạn sở hữu tất cả Thông tin đầu vào. Noam có thể sử dụng Nội dung để cung cấp và duy trì Dịch vụ, tuân thủ luật hiện hành và thực thi các chính sách của chúng tôi. Bạn chịu trách nhiệm về Nội dung, bao gồm cả việc đảm bảo rằng Nội dung đó không vi phạm bất kỳ luật hiện hành nào hoặc các điều khoản này.",
    "block9": "(b) Độ chính xác. Trí tuệ nhân tạo và học máy là những lĩnh vực nghiên cứu đang phát triển nhanh chóng. Chúng tôi liên tục nỗ lực cải thiện dịch vụ của mình để dịch vụ chính xác, đáng tin cậy, an toàn và có lợi hơn. Do tính chất xác suất của học máy, việc sử dụng dịch vụ của chúng tôi có thể dẫn đến kết quả đầu ra không chính xác trong một số trường hợp nhất định, có thể không phản ánh chính xác các cá nhân, địa điểm hoặc sự kiện thực tế. Bạn nên đánh giá một cách thích hợp độ chính xác của bất kỳ kết quả đầu ra nào dựa trên trường hợp sử dụng của bạn, bao gồm cả việc xem xét thủ công các kết quả đầu ra.",
    "subTitle1": "Đăng ký và Truy cập",
    "subTitle10": "Khả năng phân tách",
    "subTitle2": "Yêu cầu sử dụng",
    "subTitle3": "Nội dung",
    "subTitle4": "Phí và thanh toán",
    "subTitle5": " Bảo mật, An ninh và Bảo vệ Dữ liệu",
    "subTitle6": "Thời hạn và Chấm dứt",
    "subTitle7": "Bồi thường; Miễn trừ trách nhiệm; Giới hạn trách nhiệm pháp lý",
    "subTitle8": "Luật áp dụng và Thẩm quyền",
    "subTitle9": "Điều khoản chung",
    "title": "Điều khoản dịch vụ",
    "updateTime": "Cập nhật: ngày 15 tháng 3 năm 2024"
  },
  "trialModal": {
    "btn": "Bắt đầu ngay bây giờ (Chỉ $0.99)",
    "chat": "Trò chuyện PDF",
    "desc1": "Sau khi kết thúc bản dùng thử, bạn sẽ bị tính phí hàng năm là $180",
    "desc2": "Hủy bỏ bất cứ lúc nào",
    "focus": "Tập trung",
    "hover": "Dịch khi di chuột",
    "image": "Dịch hình ảnh",
    "installBtn": "Cài đặt ngay để nhận quà",
    "memo": "Quản lý yêu thích",
    "pdf": "Dịch song song PDF",
    "realtime": "Dịch tức thì",
    "select": "Dịch từ được chọn",
    "title": "Quà tặng chào mừng",
    "trialText": "Dùng thử 7 ngày",
    "video": "Dịch video",
    "videoSummary": "Tóm tắt video",
    "web": "Dịch song song",
    "webSummary": "Tóm tắt web"
  },
  "upgradeModal": {
    "btn": "Nâng Cấp Ngay",
    "chat": {
      "free": "Bạn đã đạt đến giới hạn Chatpdf",
      "pro": "Bạn đã đạt đến giới hạn Chatpdf trong tháng này"
    },
    "contactStr": "Vui lòng đợi đến lần đặt lại tiếp theo hoặc liên hệ với chúng tôi theo địa chỉ contact@noam.tools.",
    "limitAlertStr": "Bạn đã sử dụng hết hạn mức gói miễn phí, chúng tôi khuyên bạn nên nâng cấp gói của mình để tiếp tục trải nghiệm~!",
    "more": "Nhiều Gói Hơn",
    "pageLimit": {
      "free": "Bạn đã đạt đến giới hạn số trang PDF",
      "pro": "Bạn đã đạt đến giới hạn số trang PDF trong tháng này"
    },
    "title": "Lời Nhắc",
    "upgradeAlertStr": "Bạn đã sử dụng hết hạn mức gói miễn phí, chúng tôi khuyên bạn nên nâng cấp gói của mình để tiếp tục trải nghiệm~!",
    "upload": {
      "free": "Bạn đã đạt đến giới hạn dịch PDF",
      "pro": "Bạn đã đạt đến giới hạn dịch PDF trong tháng này"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "Tham gia ngay",
      "label": "Ưu đãi Người dùng mới:",
      "remain": "Chỉ còn 30 suất",
      "text": "Chỉ còn 100 suất! Nhanh tay đăng ký!"
    }
  }
}

export default TRANSLATION
