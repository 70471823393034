const TRANSLATION = {
  "buyModal": {
    "afterFree": "체험 기간 후",
    "btn": "무료로 사용해 보기",
    "freeTrial": "3일 무료 체험",
    "monthLabel": "개월",
    "selectLabel": "플랜을 선택하세요",
    "title": "구독 플랜",
    "yearLabel": "년"
  },
  "contact": {
    "block1": "맞춤 설정이나 질문이나 제안 사항이 있는 경우 contact@noam.tools로 문의해 주시면 바로 답변해 드리겠습니다.",
    "block2": "Google Apps 확장 프로그램 스토어에서 별점 ★★★★★으로 평가하여 저희를 지원해 주세요.",
    "block3": "이 확장 프로그램을 공유하여 다른 사람들을 도와주세요.",
    "title": "문의하기"
  },
  "help": {
    "AquickOverviewOfNoam": "Noam 빠르게 살펴보기",
    "ChatPDF": "ChatPDF",
    "ChatPDF2": "ChatPDF",
    "FavoritesManagement": "즐겨찾기 관리",
    "Fixedplugin": "고정 플러그인",
    "Focusing": "집중",
    "Free": "무료",
    "FreeTrialBenefits": "무료 체험 혜택",
    "GoWithWikipedia": "위키백과로 이동",
    "GoWithYouTube": "YouTube로 이동",
    "HoverTranslation": "마우스 오버 번역",
    "Howtouse": "사용 방법",
    "ImageTranslation": "이미지 번역",
    "InstantTranslation": "즉시 번역",
    "Membership": "멤버십",
    "NoamHelp": "Noam은 번역 및 요약을 도와드립니다",
    "PDFparallelTranslation": "PDF 병렬 번역",
    "ParallelTranslation": "병렬 번역",
    "SkipAllSteps": "모든 단계 건너뛰기",
    "Summary": "요약",
    "Translation": "번역",
    "TryItForFree": "무료로 사용해 보세요",
    "Video": "비디오",
    "VideoSummary": "비디오 요약",
    "VideoTranslation": "비디오 번역",
    "WebSummary": "웹 요약",
    "WordSelectionTranslation": "단어 선택 번역",
    "benefits": "준비된 혜택이 있습니다!",
    "dayFree": "3일 무료 체험",
    "done": "완료",
    "next": "다음",
    "pin": "쉽게 액세스하려면 Noam을 고정하세요～～",
    "planIsNotExist": "플랜이 존재하지 않습니다",
    "startWiki": "위키백과에서 시작해 보세요~",
    "startYoutube": "YouTube에서 시작해 보세요~",
    "try": "사용해 보세요",
    "tryNow": "지금 바로 사용해 보세요~"
  },
  "home": {
    "PDFTranslate": {
      "desc": "이중 언어 번역 + Chat PDF",
      "sub1": {
        "desc": "이중 언어 읽기를 통해 읽는 동안 원문과 번역문을 비교하여 읽기 효율성을 높일 수 있습니다.",
        "title": "이중 언어 읽기"
      },
      "sub2": {
        "desc": "AI가 분석 및 요약하고 PDF 콘텐츠를 기반으로 질문에 답변할 수 있습니다.",
        "title": "Chat PDF"
      },
      "sub3": {
        "desc": "더 정확하고 효율적인 번역을 위한 AI 기반 PDF",
        "title": "정확성 및 효율성"
      },
      "title": "PDF 번역"
    },
    "aiAssistant": {
      "desc": "웹 번역을 더 스마트하고 편리하게",
      "panel1": {
        "desc": "이중 언어 상호 참조 번역을 통해 원문과 번역문을 비교하여 더 잘 이해하고 읽기 효율성을 높일 수 있습니다.",
        "title": "이중 언어 번역"
      },
      "panel2": {
        "left": {
          "desc": "웹 페이지를 탐색할 때 번역이 필요한 콘텐츠 위로 마우스를 가져가면 번역 결과를 얻을 수 있습니다.",
          "title": "마우스 오버 번역"
        },
        "right": {
          "desc": "웹 페이지를 탐색할 때 밑줄을 긋고 싶은 텍스트를 직접 선택하면 번역 결과를 얻을 수 있습니다.",
          "title": "텍스트 선택 번역"
        }
      },
      "panel3": {
        "one": {
          "desc": "언제 어디서나 어떤 상황에서든 웹 페이지와 비디오 콘텐츠를 번역하고 요약하여 더욱 원활한 경험을 제공합니다!",
          "title": "클릭 한 번으로"
        },
        "three": {
          "desc": "AI 기반 대규모 모델을 통해 번역의 정확성과 일관성을 보장하고 독서 경험을 향상시킵니다.",
          "title": "AI 기반"
        },
        "two": {
          "desc": "다국어 전체 지원, 이중 언어 장벽 없는 읽기, 원키 번역으로 비교할 수 없는 경험을 제공합니다!",
          "title": "더욱 포괄적이고 편리하게"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "chatgpt와 결합된 효율성 전문가가 YouTube 비디오 콘텐츠를 번역하고 요약하여 읽는 시간을 절약해 줍니다!",
          "key": "Video",
          "title": "비디오 번역 및 요약"
        },
        "tab2": {
          "desc": "웹 서핑 중 이미지를 클릭하여 번역",
          "key": "Image",
          "title": "이미지 번역"
        },
        "tab3": {
          "desc": "웹 페이지에서 주요 정보를 신속하게 추출하여 요약하여 독서 효율성을 높입니다.",
          "key": "Web",
          "title": "웹 번역 및 요약"
        },
        "tag1": "간단",
        "tag2": "효율성",
        "tag3": "이해하기 쉬운"
      },
      "title": "AI 어시스턴트"
    },
    "getStart": "시작하기",
    "getStartedForFree": "무료로 시작하기",
    "howItWork": {
      "desc": "Noam은 AI 모델을 통합하여 사용자에게 여러 언어 웹 페이지 번역 및 요약, PDF 및 이미지 번역, 비디오 번역 및 요약을 위한 지능형 경험을 제공합니다.",
      "title": "작동 방식"
    },
    "lastBlock": {
      "desc": "더 나은 AI 번역과 더 부드러운 독서 경험"
    },
    "top": {
      "desc": "Noam은 요약된 웹 페이지, 비디오 및 PDF 콘텐츠를 번역하는 데 도움이 되는 무료 AI 브라우저 확장 프로그램입니다.\\n      Noam에 가입하고 마법을 경험해 보세요!",
      "title1": "한 번의 클릭",
      "title2": "한 번의 마법"
    },
    "userReviews": {
      "desc": "Chrome 웹 스토어에서 별 5개 리뷰",
      "title": "사용자 리뷰"
    }
  },
  "installTips": {
    "btn": "지금 사용해 보기",
    "text": "\"Noam 브라우저 플러그인\"을 설치하여 더 많은 기능을 이용해 보세요! 웹 상호 참조,\\n    웹 비디오 요약 등!"
  },
  "locale": {
    "af": "아프리칸스어",
    "am": "암하라어",
    "ar": "아랍어",
    "as": "아삼어",
    "auto": "자동 감지",
    "az": "아제르바이잔어",
    "ba": "바시키르어",
    "bg": "불가리아어",
    "bn": "벵골어",
    "bo": "티베트어",
    "bs": "보스니아어",
    "ca": "카탈루냐어",
    "cs": "체코어",
    "cy": "웨일스어",
    "da": "덴마크어",
    "de": "독일어",
    "dsb": "저지 소르브어",
    "dv": "디베히어",
    "el": "그리스어",
    "en": "영어",
    "es": "스페인어",
    "et": "에스토니아어",
    "eu": "바스크어",
    "fa": "페르시아어",
    "fi": "핀란드어",
    "fil": "필리핀어",
    "fj": "피지어",
    "fr": "프랑스어",
    "frCA": "프랑스어(캐나다)",
    "ga": "아일랜드어",
    "gl": "갈리시아어",
    "gom": "콘카니어",
    "gu": "구자라트어",
    "ha": "하우사어",
    "he": "히브리어",
    "hi": "힌디어",
    "hr": "크로아티아어",
    "hsb": "상류 소르브어",
    "ht": "아이티 크리올어",
    "hu": "헝가리어",
    "hy": "아르메니아어",
    "id": "인도네시아어",
    "ig": "이보어",
    "ikt": "이누이나크툰어",
    "is": "아이슬란드어",
    "it": "이탈리아어",
    "iu": "이누크티투트어",
    "iuLatn": "이누크티투트어(라틴어)",
    "ja": "일본어",
    "ka": "조지아어",
    "kk": "카자흐어",
    "km": "크메르어",
    "kmr": "쿠르드어(북부)",
    "kn": "칸나다어",
    "ko": "한국어",
    "ku": "쿠르드어(중부)",
    "ky": "키르기스어",
    "ln": "링갈라어",
    "lo": "라오어",
    "lt": "리투아니아어",
    "lug": "간다어",
    "lv": "라트비아어",
    "lzh": "중국어(문어)",
    "mai": "마이틸리어",
    "mg": "말라가시어",
    "mi": "마오리어",
    "mk": "마케도니아어",
    "ml": "マラヤーラム語",
    "mnCyrl": "몽골어(키릴어)",
    "mnMong": "몽골어(전통 문자)",
    "mr": "마라티어",
    "ms": "말레이어",
    "mt": "몰타어",
    "mww": "몽어",
    "my": "미얀마어(버마어)",
    "nb": "노르웨이어",
    "ne": "네팔어",
    "nl": "네덜란드어",
    "noSearchLang": "지원하지 않는 언어입니다",
    "nso": "북소토어",
    "nya": "체와어",
    "or": "오리야어",
    "otq": "케레타로 오토미어",
    "pa": "펀자브어",
    "pl": "폴란드어",
    "placeholder": "검색",
    "prs": "다리어",
    "ps": "파슈토어",
    "pt": "포르투갈어(브라질)",
    "ptPT": "포르투갈어(포르투갈)",
    "ro": "루마니아어",
    "ru": "러시아어",
    "run": "룬디어",
    "rw": "키냐르완다어",
    "sd": "신디어",
    "si": "싱할라어",
    "sk": "슬로바키아어",
    "sl": "슬로베니아어",
    "sm": "사모아어",
    "sn": "쇼나어",
    "so": "소말리아어",
    "sq": "알바니아어",
    "srCyrl": "세르비아어(키릴어)",
    "srLatn": "세르비아어(라틴어)",
    "st": "남소토어",
    "sv": "스웨덴어",
    "sw": "스와힐리어",
    "ta": "타밀어",
    "te": "텔루구어",
    "th": "태국어",
    "ti": "티그리냐어",
    "tk": "투르크멘어",
    "tlhLatn": "클링온어(라틴어)",
    "tlhPiqd": "클링온어(pIqaD)",
    "tn": "츠와나어",
    "to": "통가어",
    "tr": "터키어",
    "tt": "타타르어",
    "ty": "타히티어",
    "ug": "위구르어",
    "uk": "우크라이나어",
    "ur": "우르두어",
    "uz": "우즈베크어(라틴어)",
    "vi": "베트남어",
    "xh": "코사어",
    "yo": "요루바어",
    "yua": "유카텍 마야어",
    "yue": "광둥어(번체)",
    "zh": "중국어",
    "zhHans": "중국어(간체)",
    "zhHant": "중국어(번체)",
    "zu": "줄루어"
  },
  "login": {
    "and": " 및 ",
    "btn": "Google로 로그인",
    "btn1": "지금 로그인",
    "desc": "로그인하여 더 많은 경험을 해보세요",
    "policy": " 개인정보 보호정책",
    "terms": "이용 약관 ",
    "tips": "가입하면 ",
    "title": "Noam에 오신 것을 환영합니다"
  },
  "pageFooter": {
    "follow": {
      "facebook": "페이스북",
      "title": "팔로우",
      "twitter": "트위터"
    },
    "legal": {
      "policy": "개인정보 보호정책",
      "terms": "서비스 약관",
      "title": "법률 정보"
    },
    "product": {
      "image": "이미지 번역",
      "pdf": "PDF 번역",
      "title": "제품",
      "video": "동영상 번역 & 요약",
      "web": "웹 번역 & 요약"
    },
    "resources": {
      "title": "리소스"
    }
  },
  "pageHeader": {
    "contact": "문의하기",
    "help": "고객센터",
    "logout": "로그아웃",
    "logoutSucc": "로그아웃 성공",
    "pricing": "가격",
    "settings": "설정",
    "signUp": "로그인"
  },
  "pdf": {
    "autoDetect": "자동 감지",
    "cancelBtn": "취소",
    "cancelUpload": "취소",
    "chatPdf": {
      "Search": "검색",
      "cancel": "취소",
      "chatTitle": "Noam에 오신 것을 환영합니다. PDF 요약이 준비되었습니다.",
      "clear": "삭제",
      "clearContent": "삭제하면 새 대화가 생성되고 이 대화의 내용은 저장되지 않습니다. 삭제하시겠습니까?",
      "clearDialogTitle": "주의",
      "clearSucc": "성공적으로 삭제되었습니다.",
      "copy": "복사",
      "copySucc": "복사 성공",
      "delete": "삭제",
      "deleteCancel": "취소",
      "deleteContent": "삭제 후에는 이 PDF를 복구할 수 없습니다. 삭제하시겠습니까?",
      "deleteOk": "삭제",
      "deleteSuccess": "삭제 성공",
      "deleteTitle": "팁",
      "expand": "접기",
      "historyTitle": "히스토리",
      "listError": "읽기 목록을 불러오지 못했습니다. 나중에 다시 시도하거나 고객 지원에 문의하세요.",
      "loading": "불러오는 중...",
      "noDataDesc": "히스토리가 없습니다~",
      "noMore": "더 이상 없습니다.",
      "placeholder": "PDF에 대한 질문을 입력하세요.",
      "summaryTitle": "관심 있는 질문:"
    },
    "collectionEmpty": "컬렉션에 콘텐츠가 없습니다~",
    "collectionTab": "메모",
    "dataLoading": "데이터를 불러오는 중...",
    "dialogContent": "정말로 삭제하시겠습니까?",
    "dialogTitle": "주의",
    "download": {
      "both": "이중 언어",
      "btn": "지금 저장",
      "cancel": "팝업 창을 닫으면 다운로드 중인 PDF 파일이 취소됩니다. 취소하시겠습니까?",
      "desc": "로컬에 저장: 다운로드한 번역은 이미지로 저장됩니다. 저장 후 인쇄: 다운로드한 번역은 원래 형식으로 유지되며 복사를 지원합니다.",
      "download": "다운로드",
      "downloadMsg1": "PDF 번역 진행 상황:",
      "downloadMsg2": "예상 소요 시간",
      "downloadSucc": "PDF 다운로드 준비 완료, 지금 저장을 클릭하세요!",
      "downloadTip": "PDF를 다운로드하는 중입니다. 다운로드 중에 팝업 창을 닫지 마세요. 다운로드에 실패할 수 있습니다.",
      "error": "로컬에 저장하지 못했습니다. 인쇄 저장을 시도하세요.",
      "fileName": "파일 이름:",
      "fileType": "다운로드 파일 형식:",
      "loading": "저장 중...",
      "loading2": "불러오는 중...",
      "minute": "분...",
      "msg1": "현재 문서의 일부 번역이 원본 텍스트보다 길어서 다운로드 후 텍스트가 겹칠 수 있으므로 웹 페이지에서 읽는 것이 좋습니다.",
      "msg2": "1. 콘텐츠가 완전히 번역되지 않았습니다. 완전히 번역된 후 다운로드하세요.",
      "msg3": "2. 문서의 일부 번역이 너무 길어서 텍스트 겹침을 방지하려면 웹 페이지에서 읽을 수 있습니다.",
      "msg4": "현재 번역 중인 작업이 있습니다. 번역이 완료된 후 다시 시도하세요.",
      "progress": "PDF를 번역하는 중입니다. 진행 상황: {progress}, 예상 소요 시간: {minutes}분...",
      "save": "로컬에 저장",
      "savePrint": "저장 후 인쇄",
      "success": "다운로드 성공",
      "trans": "번역만",
      "transContinue": "번역 계속"
    },
    "downloadBtn": "다운로드",
    "dragTipModal": {
      "black": "스페이스",
      "leftKey": "왼쪽 버튼",
      "ok": "알겠습니다",
      "press": "누르세요",
      "tips": "을 끌어서 봅니다."
    },
    "entry": "PDF 목록",
    "fileErrMsg": "파일을 읽지 못했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "fileMsg": "현재 파일 크기:",
    "fileMsg2": "100MB보다 작은 파일을 업로드하세요.",
    "freeBannerBtn": "업그레이드하여 더 많은 기능을 즐기세요.",
    "freeBannerTips": "무료 PDF 번역 종료",
    "guide": {
      "placeholder": "PDF 링크를 입력하세요.",
      "start": "번역 시작",
      "text1": "병렬 읽기",
      "text2": "병렬 읽기를 통해 읽으면서 원문과 번역을 비교하여 읽기 효율성을 높일 수 있습니다.",
      "text3": "PDF 채팅",
      "text4": "AI가 분석하고 요약하며 PDF 콘텐츠를 기반으로 질문에 답변할 수 있습니다.",
      "text5": "정확성 및 효율성",
      "text6": "AI 기반 PDf로 더 정확하고 효율적인 번역 제공",
      "title1": "병렬 번역",
      "title2": "PDF 채팅",
      "toast": ".pdf로 끝나는 유효한 온라인 pdf 링크를 입력하세요."
    },
    "hasTransTask": "번역 중인 작업이 있습니다. 기다려 주세요.",
    "hoverTrans": "마우스 오버 단락 번역",
    "initial": "PDF 번역",
    "installText": "모든 일상적인 번역 요구 사항을 한 번에 해결",
    "installText2": "먼저  을(를) 설치하세요.",
    "installTips": "작은 플러그인을 설치하면 더 많은 PDF 파일을 번역하고 부드러운 웹 페이지 번역을 경험할 수 있습니다.",
    "kw": "스테이플",
    "link": "링크",
    "listError": "PDF 목록을 불러오지 못했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "memoTotalCount": "총 콘텐츠 1개",
    "new": "새 파일",
    "noId": "파일 ID가 존재하지 않습니다.",
    "notPdf": "PDF 파일만 가능",
    "okBtn": "삭제",
    "okBtn2": "인식",
    "original": "원본",
    "parallel": "병렬",
    "parseErrDialogContent": "현재 PDF 링크는 직접 번역을 지원하지 않습니다. 번역 후 원본 링크 PDF를 다운로드하세요.",
    "parseErrTips": "파일을 구문 분석할 수 없습니다.",
    "parseFileToast": "파일 구문 분석에 실패했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "pdfLoading": "구문 분석 중...",
    "searchPlaceholder": "검색",
    "selectTrans": "단어에 밑줄을 긋고 번역",
    "shareBtn": "공유",
    "shareBtnText1": "공유 링크 생성",
    "shareBtnText2": "공유 콘텐츠 업데이트",
    "shareModal": {
      "cancel": "취소",
      "copy": "복사",
      "copySucc": "링크 복사 성공",
      "custom": "사용자 지정:",
      "errMsg": "현재 공유 범위의 콘텐츠가 완전히 번역되지 않았습니다. 공유하기 전에 번역하세요.",
      "gen": "공유",
      "modify": "수정",
      "notSupport": "이 언어는 현재 지원되지 않습니다.",
      "page": "페이지",
      "pageNuErr": "올바른 페이지 번호 값을 입력하세요.",
      "range": "범위:",
      "remain": "남은 시간",
      "remain1": "총 예상 소요 시간",
      "search": "검색",
      "settings": "공유 설정:",
      "shareSetting": "공유 콘텐츠 설정",
      "shareText": "공유",
      "shareTips": "번역된 PDF 링크를 친구들과 공유하여 비교하며 읽어보세요.",
      "sourceLang": "출발어:",
      "target": "도착어:",
      "text1": "공유 설정",
      "text2": "공유 링크 생성",
      "totalPage": "총 1페이지",
      "trans": "번역",
      "translateTips": "팁: 번역 시간이 오래 걸리면 팝업 창을 닫고 번역이 완료된 후 공유할 수 있습니다.",
      "translating": "PDF 번역 중...",
      "update": "공유 링크 업데이트됨"
    },
    "shareMsg1": "공유 링크 생성 성공",
    "shareMsg2": "공유 콘텐츠 업데이트 성공",
    "shareMsg3": "공유 링크 생성에 실패했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "shareMsg4": "공유 콘텐츠 업데이트에 실패했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "shareTips": "PDF 번역이 완료되었습니다. 친구들과 공유하세요!",
    "start": "무료 체험 시작",
    "thumbnail": "목차",
    "toast1": "PDF를 불러오는 중입니다. 나중에 다시 시도하세요!",
    "toast2": "PDF 내용이 너무 많아 현재 대화를 지원하지 않습니다.",
    "toast3": "다운로드 중입니다. 다운로드가 완료된 후 다시 시도하세요.",
    "toolbar": {
      "Adjust": "조정",
      "autoFit": "자동 맞춤"
    },
    "trans": "번역",
    "transSucc": "번역이 완료되었습니다!",
    "unLoginToast": "이 기능을 사용하려면 로그인하세요.",
    "uploadBtn": "업로드",
    "uploadErr": "파일 업로드에 실패했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "uploadMsg1": "파일을 여기에 끌어다 놓으세요.",
    "uploadMsg2": "지원되는 파일 형식: PDF | 최대 파일 크기: 100MB",
    "uploadMsg3": "여기를 클릭하여 파일을 선택하거나 끌어다 놓아 업로드하세요.",
    "uploading": "업로드 중",
    "uploadingMsg": "업로드하는 데 시간이 걸리므로 잠시 기다려주세요.",
    "uploadingMsg1": "파일 업로드가 진행 중입니다. 나중에 다시 시도하세요.",
    "uploadingMsg2": "현재 변환 중인 파일이 있습니다. 나중에 다시 시도하세요.",
    "uploadingMsg3": "변환 진행 상황 문의에 실패했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "uploadingMsg4": "Noam은 로컬 파일에 직접 액세스할 수 없습니다. 번역할 PDF를 직접 업로드하세요.",
    "uploadingMsg5": "파일 URL을 가져오지 못했습니다. 나중에 다시 시도하거나 고객 서비스에 문의하세요.",
    "video": "동영상",
    "vipBannerBtn": "업그레이드하여 더 많은 기능을 즐기세요.",
    "vipBannerTips": "이번 달 PDF 번역 한도에 도달했습니다.",
    "webTrans": "전체 상호 참조"
  },
  "pricing": {
    "FAQ": "FAQ",
    "FQAObj": {
      "a1": "Noam은 무료 버전을 직접 사용할 수 있습니다.",
      "a2": "무료 버전 외에도 Pro, Pro+, Enterprise 등 세 가지 멤버십 플랜이 있습니다. 결제 방식은 연속 월간 결제와 연속 연간 결제 중에서 선택할 수 있으며, 연속 연간 결제의 종합 비용이 더 저렴합니다.",
      "a3": "기능상의 차이는 없습니다. 연속 연간 구독의 종합 비용이 연속 월간 구독보다 저렴합니다. 월간 구독은 매월 1회, 연간 구독은 매년 1회 청구됩니다.",
      "a4": "백엔드 설정 페이지로 이동하여 구독 취소를 선택하면 갱신 플랜을 취소할 수 있습니다.",
      "q1": "무료로 사용할 수 있나요?",
      "q2": "멤버십 플랜과 요금 옵션이 다양한가요?",
      "q3": "연속 월간 구독과 연속 연간 구독의 차이점은 무엇인가요?",
      "q4": "자동 갱신 플랜을 취소하려면 어떻게 해야 하나요?"
    },
    "around": "약",
    "billedMonthly": "월간 청구",
    "billedYearly": "연간 청구",
    "day": "일",
    "desc": "비교 후 가장 적합한 플랜을 선택하세요",
    "detailedCompare": "자세히 비교",
    "discount": "20% 할인",
    "enterprise": {
      "benefit1": "고객 지원 신속 지원",
      "benefit2": "다중 사용자",
      "benefit3": "맞춤형 솔루션",
      "benefit5": "전담 계정 관리자",
      "btn": "문의하기",
      "recommendText": "맞춤형 플랜(≥3인)",
      "talkToUs": "문의하기"
    },
    "free": {
      "benefit1": "즉시 번역 ${FREE_BENEFITS.realTimeCount}회/일",
      "benefit2": "웹 번역 및 요약",
      "benefit3": "이미지 번역 ${FREE_BENEFITS.imageCount}장",
      "benefit4": "동영상 번역 및 요약",
      "benefit5": "PDF 번역 ${FREE_BENEFITS.pdfUploadCount}개",
      "benefit6": "${FREE_BENEFITS.tokenCount} 토큰",
      "btn": "무료로 이용하기",
      "recommendText": "무료 체험"
    },
    "imageTranslation": "이미지 번역",
    "month": "개월",
    "monthly": "월간",
    "pageTitle": "가격",
    "pdf": {
      "chat": "PDF 문의 수",
      "maxPage": "PDF 최대 페이지 수",
      "translation": "PDF 번역"
    },
    "preMonth": "월별",
    "pro": {
      "benefit1": "고객 지원 신속 지원",
      "benefit2": "매월 ${PRO_BENEFITS.tokenCount} 토큰",
      "benefit3": "웹 번역 및 요약",
      "benefit4": "이미지 번역 ${PRO_BENEFITS.imageCount}장",
      "benefit5": "동영상 번역 및 요약",
      "benefit6": "PDF 번역 ${PRO_BENEFITS.pdfUploadCount}개",
      "btn": "시작하기",
      "recommendText": "가장 인기 있는 플랜"
    },
    "proPlus": {
      "benefit1": "고객 지원 신속 지원",
      "benefit2": "매월 ${PRO_V_BENEFITS.tokenCount} 토큰",
      "benefit3": "웹 번역 및 요약",
      "benefit4": "이미지 번역 ${PRO_V_BENEFITS.imageCount}장",
      "benefit5": "동영상 번역 및 요약",
      "benefit6": "PDF 번역 ${PRO_V_BENEFITS.pdfUploadCount}개",
      "recommendText": "가장 적합한 선택",
      "talkToUs": "문의하기"
    },
    "video": {
      "summary": "동영상 요약",
      "translation": "동영상 번역"
    },
    "web": {
      "contrastiveTranslation": "병렬 번역",
      "dictionaryTranslation": "텍스트 선택 번역",
      "hoverTranslation": "마우스 오버 번역",
      "instantTranslation": "즉시 번역",
      "summary": "웹 페이지 요약"
    },
    "yearly": "연간"
  },
  "privacy": {
    "block1": "Noam(\"Noam\", \"당사\" 또는 \"저희\")은 귀하의 개인정보를 존중하며 귀하로부터 또는 귀하에 대해 얻은 모든 정보를 보호하기 위해 최선을 다하고 있습니다. 본 개인정보 보호정책은 귀하가 당사 웹사이트, 애플리케이션 및 서비스(통칭하여 \"서비스\")를 사용하는 것과 관련하여 당사가 개인정보를 수집하거나 사용하는 방법에 대해 설명합니다. 본 개인정보 보호정책은 당사 API와 같이 당사 비즈니스 제품 고객을 대신하여 처리하는 콘텐츠에는 적용되지 않습니다. 이러한 데이터 사용에는 이러한 제품에 대한 액세스 및 사용을 다루는 고객 계약이 적용됩니다.",
    "block10": "당사는 온라인 및 오프라인 개인정보를 손실, 오용 및 무단 액세스, 공개, 변경 또는 적대 행위로부터 보호하기 위해 상업적으로 합리적인 기술적, 관리적 및 조직적 조치를 구현합니다. 그러나 인터넷 또는 이메일 전송은 완벽한 보안이나 정확성을 보장할 수 없습니다. 특히 당사에 전송되거나 당사에서 전송된 이메일은 안전하지 않을 수 있습니다. 따라서 서비스 또는 이메일을 통해 당사에 어떤 정보를 보낼지 결정할 때 특히 주의해야 합니다. 또한 당사는 서비스 또는 제3자 웹사이트의 보안 설정 또는 보안 조치의 개인정보 설정 또는 보안 조치 방해에 대해 책임을 지지 않습니다. 당사는 분쟁 해결, 보안 및 안전상의 이유 또는 법적 의무 준수와 같이 서비스를 제공하거나 기타 합법적인 사업 목적으로 필요한 기간 동안 귀하의 개인정보를 보유합니다. 당사가 개인정보를 보유하는 기간은 정보의 양, 특성, 민감도, 무단 사용 또는 공개의 잠재적 위험, 정보 처리 목적 및 기타 법적 구속력 있는 고려 사항과 같은 여러 요인에 따라 다릅니다.",
    "block11": "당사는 본 개인정보 보호정책을 수시로 업데이트할 수 있습니다. 이 경우 관련 법률에서 달리 요구하지 않는 한 이 페이지에 업데이트된 버전을 게시합니다.",
    "block12": "본 개인정보 보호정책에 대해 답변을 받지 못한 질문이나 우려 사항이 있는 경우 고객 서비스에 문의하십시오. 이메일: ",
    "block2": "당사는 귀하와 관련하여 다음과 같은 개인정보(\"개인정보\")를 수집합니다.",
    "block3": "귀하가 제공한 정보: 귀하가 당사 서비스를 이용하기 위해 계정을 생성하거나 당사와 소통하는 경우 당사는 다음과 같은 개인정보를 수집합니다.",
    "block4": "서비스 사용으로부터 자동으로 수신된 개인정보: 귀하가 서비스에 액세스, 사용 또는 상호 작용하는 경우 당사는 귀하의 액세스, 사용 또는 상호 작용에 대한 다음 정보(\"기술 정보\")를 수신합니다.",
    "block5": "당사는 다음과 같은 목적으로 개인정보를 사용할 수 있습니다.",
    "block6": "요약 또는 비식별 정보: 당사는 개인정보를 집계하거나 비식별화하여 식별 목적으로 재사용되는 것을 방지하고, 이러한 정보를 사용하여 서비스의 효율성을 분석하고, 서비스에 기능을 개선 및 추가하고, 연구를 수행하고, 유사한 목적으로 사용할 수 있습니다. 또한 당사는 서비스 사용자의 일반적인 행동 및 특징을 분석하고 집계된 정보를 제3자와 공유하고, 해당 집계된 정보를 게시하거나, 일반적으로 이용 가능하도록 할 수 있습니다. 당사는 서비스, 쿠키 및 본 개인정보 보호정책에 설명된 기타 수단을 통해 집계된 정보를 수집할 수 있습니다. 당사는 익명 또는 비식별 형식으로 비식별 정보를 유지 및 사용하며, 법률에 의해 요구되는 경우를 제외하고 해당 정보를 재식별하려는 시도를 하지 않습니다.",
    "block7": "경우에 따라 당사는 법률에 의해 요구되는 경우를 제외하고 귀하에게 별도의 통지 없이 귀하의 개인정보를 제3자에게 제공할 수 있습니다.",
    "block8": "지리적 위치에 따라 유럽 경제 지역, 영국 및 전 세계 개인은 자신의 개인정보와 관련하여 특정 법적 권리를 가질 수 있습니다. 예를 들어 귀하는 다음과 같은 권리를 가질 수 있습니다.",
    "block9": "서비스에는 소셜 미디어 서비스를 포함하여 Noam에서 운영하거나 제어하지 않는 다른 웹사이트(\"제3자 웹사이트\") 링크가 포함될 수 있습니다. 제3자 웹사이트와 공유하는 정보는 본 개인정보 보호정책이 아닌 해당 제3자 웹사이트의 특정 개인정보 보호정책 및 이용 약관의 적용을 받습니다. 당사는 편의를 위해 이러한 링크를 제공하며 이러한 웹사이트에 대한 당사의 보증 또는 검토를 의미하지 않습니다. 개인정보 보호정책 및 약관에 대한 자세한 내용은 제3자 웹사이트에 직접 문의하십시오.",
    "subBlock1": "계정 정보: 귀하가 당사 플랫폼에서 계정을 생성하는 경우 당사는 귀하의 이름, 연락처 정보, 계정 자격 증명, 결제 카드 정보 및 거래 내역(\"계정 정보\")을 포함한 귀하의 계정과 관련된 정보를 수집합니다.",
    "subBlock10": "서비스 제공, 관리, 유지보수 및/또는 분석",
    "subBlock11": "귀하와의 소통",
    "subBlock12": "새로운 프로젝트 및 서비스 개발",
    "subBlock13": "사기, 범죄 행위 또는 당사 서비스의 오용을 방지하고 당사 IT 시스템, 아키텍처 및 네트워크의 보안 보호",
    "subBlock14": "사업 양도 수행",
    "subBlock15": "법적 의무 및 법적 절차 준수, 당사 및 당사 계열사, 귀하 또는 기타 제3자의 권리, 개인정보, 보안 또는 재산 보호",
    "subBlock16": "공급업체 및 서비스 제공업체: 비즈니스 운영 요구 사항을 충족하고 특정 서비스 및 기능을 수행하는 데 당사를 지원하기 위해 호스팅 서비스 제공업체, 클라우드 서비스 제공업체 및 기타 정보 기술 서비스 제공업체, 이메일 커뮤니케이션 소프트웨어 및 웹 분석 서비스 제공업체 등을 포함한 공급업체 및 서비스 제공업체에게 개인정보를 제공할 수 있습니다. 이러한 파트너는 당사를 대신하여 업무를 수행하는 동안 당사의 지시에 따라서만 개인정보에 액세스, 처리 또는 저장합니다.",
    "subBlock17": "사업 양도: 당사가 전략적 거래, 조직 개편, 파산, 파산 인수 또는 다른 제공업체로의 서비스 이전(통칭하여 \"거래\")에 참여하는 경우 귀하의 개인정보 및 기타 정보는 거래 상대방 및 실사 중에 거래를 지원하는 다른 당사자와 공유될 수 있으며 거래의 일부로 다른 자산과 함께 승계 회사 또는 계열사에 양도될 수 있습니다.",
    "subBlock18": "법적 요구 사항: 당사는 정부 당국, 업계 동료 또는 당사 서비스와의 상호 작용 정보를 포함한 기타 제3자와 귀하의 개인정보를 공유할 수 있습니다. (1) 법률에 의해 요구되거나 당사가 법적 의무를 준수하기 위해 필요하다고 판단하는 경우 (2) 당사의 권리 또는 재산을 보호하고 방어하기 위해 (3) 당사가 일방적으로 당사의 약관, 정책 또는 법률을 위반했다고 판단하는 경우 (4) 사기 또는 기타 불법 행위를 감지하거나 예방하기 위해 (5) 당사 제품, 직원 또는 사용자 또는 공공 안전, 보안, 무결성을 보호하기 위해 또는 (vi) 법적 책임을 방어하기 위해",
    "subBlock19": "귀하의 개인정보 및 처리 방법에 대한 정보에 액세스합니다.",
    "subBlock2": "사용자 콘텐츠: 귀하가 당사 서비스를 사용하는 경우 당사는 귀하의 입력, 파일 업로드 또는 당사에 제공된 피드백(\"콘텐츠\")으로부터 개인정보를 수집합니다.",
    "subBlock20": "당사 기록에서 귀하의 개인정보를 삭제합니다.",
    "subBlock21": "귀하의 개인정보를 수정하거나 업데이트합니다.",
    "subBlock22": "귀하의 개인정보를 제3자에게 전송합니다(데이터 이동성).",
    "subBlock23": "당사가 귀하의 개인정보를 처리하는 방식을 제한합니다.",
    "subBlock24": "동의 철회 - 당사가 처리의 법적 근거로 동의에 의존하는 경우 귀하는 언제든지 동의를 철회할 수 있습니다.",
    "subBlock25": "당사가 귀하의 개인정보를 처리하는 방식에 이의를 제기합니다.",
    "subBlock26": "서비스 개선 및 연구 수행",
    "subBlock3": "커뮤니케이션 정보: 귀하가 당사에 메시지를 보내는 경우 당사는 귀하의 이름, 연락처 정보 및 귀하가 보낸 메시지의 내용(\"커뮤니케이션 정보\")을 수집합니다.",
    "subBlock4": "소셜 미디어 정보: 당사는 Instagram, Facebook, Medium, Twitter, YouTube 및 LinkedIn과 같은 소셜 미디어 웹사이트에 페이지를 운영하고 있습니다. 귀하가 당사 소셜 미디어 페이지와 상호 작용하는 경우 당사는 귀하의 연락처 정보와 같이 귀하가 당사에 제공하기로 선택한 개인정보(\"소셜 정보\")를 수집합니다. 또한 당사 소셜 미디어 페이지를 호스팅하는 회사는 당사 소셜 미디어 활동에 대한 집계된 정보 및 분석을 당사에 제공할 수 있습니다.",
    "subBlock5": "로그 데이터: 당사 서비스 이용 시 귀하의 브라우저에서 자동으로 전송되는 정보. 로그 데이터에는 인터넷 프로토콜(IP) 주소, 브라우저 유형 및 설정, 요청 날짜 및 시간, 웹사이트와의 상호 작용 방식이 포함됩니다.",
    "subBlock6": "사용 데이터: 당사는 귀하가 보거나 상호 작용하는 콘텐츠 유형, 귀하가 사용하는 기능 및 귀하가 취한 조치뿐만 아니라 시간대, 국가, 액세스 날짜 및 시간, 사용자 에이전트 및 버전, 컴퓨터 또는 모바일 기기 유형, 컴퓨터 연결과 같은 서비스 사용에 대한 정보를 자동으로 수집할 수 있습니다.",
    "subBlock7": "기기 정보: 기기 이름, 운영 체제, 기기 식별자 및 사용 중인 브라우저가 포함됩니다. 수집되는 정보는 사용 중인 기기 유형 및 설정에 따라 다를 수 있습니다.",
    "subBlock8": "쿠키: 당사는 서비스를 운영 및 관리하고 귀하의 경험을 개선하기 위해 쿠키를 사용합니다. \"쿠키\"는 귀하가 방문하는 웹사이트에서 귀하의 브라우저로 전송되는 정보입니다. 귀하는 브라우저에서 모든 쿠키를 허용하거나, 모든 쿠키를 거부하거나, 쿠키가 제공될 때 알림을 표시하도록 설정하여 매번 쿠키를 수락할지 여부를 결정할 수 있습니다. 그러나 쿠키를 거부하면 웹사이트의 특정 영역이나 기능을 사용하지 못하거나 표시 또는 기능에 부정적인 영향을 미칠 수 있습니다. 쿠키에 대한 자세한 내용은 All About Cookies를 참조하십시오.",
    "subBlock9": "분석: 당사는 쿠키를 사용하는 다양한 온라인 분석 제품을 사용하여 사용자가 당사 서비스를 사용하는 방식을 분석하고 서비스 사용 시 귀하의 경험을 개선할 수 있습니다.",
    "subTitle1": "당사가 수집하는 개인정보",
    "subTitle2": "당사가 개인정보를 사용하는 방법",
    "subTitle3": "개인정보 공개",
    "subTitle4": "귀하의 권리",
    "subTitle5": "다른 웹사이트 링크",
    "subTitle6": "정보 보안 및 보존",
    "subTitle7": "개인정보 보호정책 수정",
    "subTitle8": "문의 방법",
    "title": "개인정보 보호정책",
    "updateTime": "업데이트 날짜: 2024년 3월 15일"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "모두 허용",
    "text": "경험 개선을 위해 쿠키를 사용하여 콘텐츠와 서비스를 개인화하고자 합니다.",
    "title": "이 웹사이트는 쿠키를 사용합니다"
  },
  "slogen": "가장 쉬운 AI 어시스턴트",
  "stripe": {
    "contact": "궁금한 사항은 contact@noam.tools로 문의해 주세요!",
    "fail": {
      "btn": "다시 시도",
      "text1": "죄송합니다. 결제 요청을 처리할 수 없습니다.",
      "text2": "인터넷 연결을 확인하고 다시 시도해 주세요.",
      "title": "결제 실패"
    },
    "succ": {
      "btn": "마법을 부려 봅시다",
      "text1": "Noam에 오신 것을 환영합니다, ",
      "text2": "함께 시작해 봅시다!",
      "title": "축하합니다"
    }
  },
  "terms": {
    "block1": "Noam을 이용해 주셔서 감사합니다!",
    "block10": "(a) 수수료 및 청구. 귀하는 해당 가격 페이지에 명시된 가격 및 조건 또는 당사 간 서면으로 합의된 바에 따라 모든 수수료(\"수수료\")를 지불해야 합니다. 당사는 송장을 발행했거나 결제를 받았더라도 가격 오류 또는 실수를 수정할 권리가 있습니다. 귀하는 유효하고 승인된 결제 수단을 포함하여 완전하고 정확한 청구 정보를 제공해야 합니다. 당사는 합의된 바에 따라 정기적으로 귀하의 결제 수단으로 요금을 청구하지만, 합리적인 사유가 있는 경우 청구 날짜를 변경할 수 있습니다. 귀하는 Noam, 그 계열사 및 제3자 결제 처리업체가 귀하의 결제 수단으로 요금을 청구할 수 있도록 승인합니다. 결제가 실패할 경우 당사는 귀하에게 서면으로 통지하고 결제가 완료될 때까지 서비스에 대한 액세스를 중단할 수 있습니다. 본 계약에 달리 명시된 경우를 제외하고, 결제는 환불되지 않습니다.",
    "block11": "(b) 세금. 달리 명시되지 않는 한, 수수료에는 연방, 주, 지방 및 해외 세금, 관세 및 이와 유사한 평가(\"세금\")가 포함되어 있지 않습니다. 귀하는 당사가 귀하에게 청구서를 발행할 당사의 순이익에 대한 세금을 제외하고 귀하의 구매와 관련된 모든 세금을 지불할 책임이 있습니다. 귀하는 그러한 세금을 즉시 지불하고 그러한 데이터 처리와 관련하여 법적으로 유효한 개인정보 보호정책을 제공하고 필요한 동의를 얻는 데 동의하며, 관련 법률에 따라 그러한 데이터를 처리함을 당사에 보증합니다.",
    "block12": "(c) 가격 변경. 당사는 귀하의 계정 및/또는 당사 웹사이트를 통해 귀하에게 통지함으로써 가격을 변경할 수 있습니다. 가격 인상은 게시 즉시 효력이 발생합니다. 모든 가격 변경은 변경 시행일 이후 귀하 계정의 수수료에 적용됩니다.",
    "block13": "(d) 분쟁 및 연체료. 수수료 또는 세금에 대해 이의를 제기하려면 송장 발행일로부터 30일 이내에 contact@noam.tools로 문의하십시오.",
    "block14": "(e) 무료 이용. 무료 크레딧을 얻기 위해 여러 계정을 만들 수 없습니다. 당사는 귀하가 무료 크레딧을 선의로 사용하지 않는다고 판단하는 경우 표준 요금을 청구하거나 서비스 이용을 중단할 수 있습니다.",
    "block15": "(a) 기밀 유지. 귀하는 Noam의 기밀 정보에 액세스할 수 있습니다. 귀하는 서비스 이용 목적으로만 본 약관에 따라 허용된 대로 기밀 정보를 이용할 수 있습니다. 귀하는 기밀 정보를 제3자에게 공개해서는 안 되며, 최소한 합리적인 주의를 기울여 자신의 유사한 기밀 정보를 보호하는 것과 동일한 수준으로 기밀 정보를 보호해야 합니다. 기밀 정보는 Noam이 기밀로 지정했거나 상황에 따라 기밀로 취급해야 하는 비공개 정보(소프트웨어, 사양 및 기타 비공개 비즈니스 정보 포함)입니다. 기밀 정보에는 다음이 포함되지 않습니다. (1) 귀하의 잘못 없이 일반에 공개되는 정보 (2) 본 약관에 따라 수령하기 전에 기밀 유지 의무 없이 귀하가 소지하고 있던 정보 (3) 기밀 유지 의무 없이 제3자가 귀하에게 합법적으로 공개한 정보 (4) 기밀 정보를 사용하지 않고 귀하가 독자적으로 개발한 정보. 귀하는 법률, 법원 또는 기타 정부 명령에 따라 요구되는 경우 기밀 정보를 공개할 수 있지만, Noam에 사전에 서면으로 합리적인 방식으로 통지하고, 가능한 한 공개 범위를 제한하기 위해 합리적인 노력(공개 요청에 반대하는 데 당사를 지원하는 행위 포함)을 기울여야 합니다.",
    "block16": "(b) 보안. 귀하는 서비스에 대한 액세스 및 이용을 보호하기 위해 합리적이고 적절한 조치를 취해야 합니다. 서비스 이용과 관련하여 취약점이나 위반 사항을 발견한 경우 즉시 Noam에 연락하여 취약점이나 위반 사항에 대한 세부 정보를 제공해야 합니다.",
    "block17": "((c) 개인 데이터 처리. 서비스를 사용하여 개인 데이터를 처리하는 경우, 귀하는 법적으로 충분한 개인정보 보호정책을 제공하고 그러한 데이터 처리에 필요한 동의를 얻어야 하며, 관련 법률에 따라 그러한 데이터를 처리함을 당사에 보증합니다.",
    "block18": "(a) 해지, 정지. 본 약관은 귀하가 서비스를 처음 이용하는 시점부터 효력이 발생하며 해지될 때까지 유효합니다. 귀하는 서비스 및 콘텐츠 이용을 중단함으로써 언제든지 어떤 이유로든 본 약관을 해지할 수 있습니다. 당사는 어떤 이유로든 사전 통지 후 본 약관을 해지할 수 있습니다. 귀하가 2조(이용 요건), 5조(기밀 유지, 보안 및 데이터 보호), 8조(분쟁 해결) 또는 9조(일반 조항)를 중대하게 위반하는 경우, 또는 당사가 통제할 수 없는 제3자 기술 제공업체와의 관계가 변경되는 경우, 또는 법률 또는 정부 요청을 준수하기 위해 귀하에게 통지함으로써 본 약관을 즉시 해지할 수 있습니다. 당사는 귀하가 본 약관을 준수하지 않거나, 귀하의 이용이 당사 또는 제3자에게 보안 위험을 초래하거나, 귀하의 이용이 사기성이 있거나 당사 또는 제3자에게 책임을 부담시킬 수 있다고 의심되는 경우 귀하의 서비스 액세스를 정지할 수 있습니다.",
    "block19": "(b) 해지의 효력. 해지 시, 귀하는 서비스 이용을 중단하고 당사의 지시에 따라 기밀 정보를 즉시 반환하거나 파기해야 합니다. 3조, 5~9조를 포함하되 이에 국한되지 않고 본 약관의 조항 중 그 성격상 해지 또는 만료 후에도 존속되어야 하는 조항은 존속됩니다.",
    "block2": "본 이용 약관은 애플리케이션 프로그래밍 인터페이스, 소프트웨어, 도구, 개발자 서비스, 데이터, 문서 및 웹사이트를 포함하여(이하 \"서비스\"라 총칭) Noam의 서비스 이용에 적용됩니다. 당사 서비스를 이용함으로써 귀하는 본 약관을 준수하는 데 동의하게 됩니다. 당사의 개인정보 보호정책은 당사가 개인 정보를 수집하고 사용하는 방법을 설명합니다.",
    "block20": "(a) 면책. 귀하는 귀하의 콘텐츠, 서비스와 관련된 제품 또는 서비스의 이용, 본 약관 또는 관련 법률 위반을 포함하여 서비스 이용으로 인해 또는 이와 관련하여 발생하는 모든 청구, 손실 및 비용(변호사 비용 포함)으로부터 당사, 당사의 계열사 및 당사 직원을 방어하고 면책하며 책임을 지지 않습니다.",
    "block21": "(b) 부인. 서비스는 \"있는 그대로\" 제공됩니다. 법률이 허용하는 한도 내에서, 당사 및 당사의 계열사 및 라이선스 제공자는 서비스와 관련하여 어떠한 종류의 진술이나 보증도 하지 않으며, 상품성, 특정 목적에의 적합성, 만족스러운 품질, 비침해 및 평온한 이용에 대한 보증, 그리고 거래 과정 또는 거래 관행에서 발생하는 모든 보증을 포함하되 이에 국한되지 않는 어떠한 보증도 하지 않습니다. 당사는 서비스가 중단되지 않거나, 정확하거나, 오류가 없거나, 콘텐츠가 안전하거나, 손실되지 않거나, 변경되지 않을 것이라고 보증하지 않습니다.",
    "block22": "(c) 책임의 제한. 당사, 당사의 계열사 또는 당사의 라이선스 제공자는 그러한 손해 가능성을 사전에 고지받은 경우에도 이익 손실, 평판 손실, 이용 손실, 데이터 손실 또는 기타 결과적 손해를 포함한 간접적, 부수적, 특별적, 결과적 또는 징벌적 손해에 대해 책임을 지지 않습니다. 본 약관에 따라, 당사의 총 책임은 청구일 이전 12개월 동안 귀하가 서비스에 지불한 수수료 또는 미화 100달러 중 더 큰 금액을 초과하지 않습니다. 본 조항의 제한은 관련 법률이 허용하는 최대 한도 내에서 적용됩니다.",
    "block23": "본 약관 및 귀하의 서비스 이용은 미국 법률의 적용을 받으며, 단, 법률 조항의 충돌에 관한 규정은 제외됩니다. 귀하의 애플리케이션 이용에는 기타 지역, 주, 국가 또는 국제 법률이 적용될 수도 있습니다. 본 약관과 관련된 모든 법적 조치 또는 소송은 미국 법원에서만 제기되며, 귀하는 그러한 법원의 인적 관할권에 따르는 데 동의합니다.",
    "block24": "(a) 당사자 간의 관계. Noam과 귀하는 독립적인 계약자이며, 어느 당사자도 상대방의 사전 서면 동의 없이 상대방을 대리하거나 구속하거나 상대방을 위해 의무를 부담할 권한이 없습니다.",
    "block25": "(b) 브랜드 사용. 귀하는 당사의 사전 서면 동의 없이 Noam 또는 그 계열사의 이름, 로고 또는 상표를 사용할 수 없습니다.",
    "block26": "본 약관의 조항이 무효, 불법 또는 집행 불가능한 것으로 판단되는 경우, 나머지 조항은 완전한 효력을 유지합니다.",
    "block3": "만 18세 미만인 경우 서비스를 이용하려면 부모 또는 법정 후견인의 허가를 받아야 합니다. 다른 개인 또는 단체를 대신하여 서비스를 이용하는 경우 해당 개인 또는 단체를 대신하여 본 약관에 동의할 권한이 있어야 합니다. 계정을 등록하려면 정확하고 완전한 정보를 제공해야 합니다. 조직 외부의 누구에게도 액세스 자격 증명이나 계정을 제공할 수 없으며 귀하의 자격 증명을 사용하여 수행되는 모든 활동에 대한 책임은 귀하에게 있습니다.",
    "block4": "(a) 서비스 이용. 귀하는 본 약관에 따라 서비스에 액세스할 수 있으며, 당사는 귀하에게 서비스를 이용할 수 있는 비독점적 권리를 부여합니다. 서비스를 이용하는 동안 귀하는 본 약관 및 모든 관련 법률을 준수해야 합니다. 당사 및 당사의 계열사는 서비스에 대한 모든 권리, 소유권 및 이권을 소유합니다.",
    "block5": "(b) 피드백. 당사는 피드백, 의견, 아이디어, 제안 및 개선 사항을 환영합니다. 귀하가 그러한 콘텐츠를 제공하는 경우, 당사는 귀하에게 제한 없이 그리고 귀하에게 보상하지 않고 이를 사용할 수 있습니다.",
    "block6": "(c) 제한 사항. 귀하는 다음을 수행할 수 없습니다. (1) 타인의 권리를 침해, 남용 또는 위반하는 방식으로 서비스를 이용하는 행위 (2) 관련 법률에 의해 금지된 경우를 제외하고 서비스 모델, 알고리즘 및 시스템의 소스 코드 또는 기본 구성 요소를 리버스 엔지니어링, 디컴파일, 디스어셈블, 번역 또는 기타 방법으로 발견하려는 시도 (3) 서비스의 결과물을 사용하여 Noam과 경쟁하는 모델을 개발하는 행위 (4) 스크래핑, 웹 하베스팅 또는 웹 데이터 추출을 포함하여 API를 통해 허용된 경우를 제외하고 자동화된 방법이나 프로그래밍 방법을 사용하여 서비스에서 데이터 또는 결과물을 추출하는 행위 (5) 서비스의 결과물이 인위적으로 생성되지 않았는데도 불구하고 인위적으로 생성된 것처럼 표현하거나 기타 당사의 이용 정책을 위반하는 행위 (6) 당사의 사전 동의 없이 API 키를 구매, 판매 또는 양도하는 행위 (7) 만 13세 미만의 어린이나 해당되는 디지털 동의 연령 미만의 어린이의 개인 정보를 당사에 제공하는 행위. 귀하는 당사 문서에 명시된 모든 요금 제한 및 기타 요구 사항을 준수해야 합니다.",
    "block7": "(d) 제3자 서비스. 서비스와 관련된 모든 제3자 소프트웨어, 서비스 또는 기타 제품은 자체 약관의 적용을 받으며 당사는 제3자 제품에 대해 책임을 지지 않습니다.",
    "block8": "(a) 귀하의 콘텐츠. 귀하는 서비스에 입력(\"입력\")을 제공하고 입력을 기반으로 생성 및 반환된 출력(\"출력\")을 수신할 수 있습니다(이하 \"콘텐츠\"라 총칭). 당사자 간 그리고 관련 법률이 허용하는 한, 귀하는 모든 입력을 소유합니다. Noam은 콘텐츠를 사용하여 서비스를 제공 및 유지 관리하고, 관련 법률을 준수하고, 당사의 정책을 시행할 수 있습니다. 귀하는 콘텐츠에 대한 책임이 있으며, 여기에는 콘텐츠가 관련 법률이나 본 약관을 위반하지 않도록 하는 것이 포함됩니다.",
    "block9": "(b) 정확성. 인공 지능과 머신 러닝은 빠르게 발전하는 연구 분야입니다. 당사는 서비스를 보다 정확하고 안정적이며 안전하고 유익하게 만들기 위해 지속적으로 노력하고 있습니다. 머신 러닝의 확률적 특성상 당사 서비스를 이용하면 특정 경우에 실제 개인, 장소 또는 사실을 정확하게 반영하지 못하는 잘못된 결과가 나올 수 있습니다. 결과물을 수동으로 검토하는 등 사용 사례에 따라 결과물의 정확성을 적절하게 평가해야 합니다.",
    "subTitle1": "등록 및 액세스",
    "subTitle10": "분리 가능성",
    "subTitle2": "이용 요건",
    "subTitle3": "콘텐츠",
    "subTitle4": "수수료 및 결제",
    "subTitle5": " 기밀 유지, 보안 및 데이터 보호",
    "subTitle6": "기간 및 해지",
    "subTitle7": "면책 조항, 책임의 제한",
    "subTitle8": "준거법 및 관할권",
    "subTitle9": "일반 조항",
    "title": "서비스 약관",
    "updateTime": "업데이트 날짜: 2024년 3월 15일"
  },
  "trialModal": {
    "btn": "지금 시작하기(단 $0.99)",
    "chat": "ChatPDF",
    "desc1": "체험 기간이 종료되면 연간 $180의 요금이 부과됩니다.",
    "desc2": "언제든지 취소 가능",
    "focus": "집중",
    "hover": "마우스 오버 번역",
    "image": "이미지 번역",
    "installBtn": "지금 설치하고 선물 받으세요",
    "memo": "즐겨찾기 관리",
    "pdf": "PDF 병렬 번역",
    "realtime": "실시간 번역",
    "select": "단어 선택 번역",
    "title": "환영 선물",
    "trialText": "7일 체험",
    "video": "동영상 번역",
    "videoSummary": "동영상 요약",
    "web": "병렬 번역",
    "webSummary": "웹 요약"
  },
  "upgradeModal": {
    "btn": "지금 업그레이드",
    "chat": {
      "free": "Chatpdf 한도를 초과했습니다.",
      "pro": "이번 달 Chatpdf 한도에 도달했습니다."
    },
    "contactStr": "다음 초기화를 기다리거나 contact@noam.tools로 문의하세요.",
    "limitAlertStr": "무료 플랜 할당량이 모두 소진되었습니다. 계속 이용하려면 플랜을 업그레이드하는 것이 좋습니다~!",
    "more": "더 많은 플랜 보기",
    "pageLimit": {
      "free": "PDF 페이지 한도를 초과했습니다."
    },
    "title": "알림",
    "upgradeAlertStr": "무료 플랜 할당량이 모두 소진되었습니다. 계속 이용하려면 플랜을 업그레이드하는 것이 좋습니다~!",
    "upload": {
      "free": "PDF 번역 한도에 도달했습니다.",
      "pro": "이번 달 PDF 번역 한도에 도달했습니다."
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "지금 가입",
      "label": "신규 사용자 혜택:",
      "remain": "30개 남았습니다",
      "text": "단 100자리! 지금 바로 신청하세요!"
    }
  }
}

export default TRANSLATION
