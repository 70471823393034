const TRANSLATION = {
  "buyModal": {
    "afterFree": "Después de la prueba",
    "btn": "Pruébalo gratis",
    "freeTrial": "Prueba gratuita de 3 días",
    "monthLabel": "mes",
    "selectLabel": "Selecciona un plan",
    "title": "Plan de suscripción",
    "yearLabel": "año"
  },
  "contact": {
    "block1": "Para personalizaciones o si tienes alguna pregunta o sugerencia, contáctanos a contact@noam.tools y te responderemos de inmediato.",
    "block2": "Apóyanos calificándonos en la tienda de extensiones de Google Apps en ★★★★★.",
    "block3": "Ayuda a otros compartiendo esta extensión:",
    "title": "Contáctanos"
  },
  "help": {
    "AquickOverviewOfNoam": "Una descripción general rápida de Noam",
    "ChatPDF": "Chatear con PDF",
    "ChatPDF2": "Chatear con PDF",
    "FavoritesManagement": "Gestión de favoritos",
    "Fixedplugin": "Complemento fijo",
    "Focusing": "Concentración",
    "Free": "Gratis",
    "FreeTrialBenefits": "Ventajas de la prueba gratuita",
    "GoWithWikipedia": "Ir con Wikipedia",
    "GoWithYouTube": "Ir con YouTube",
    "HoverTranslation": "Traducción al pasar el cursor",
    "Howtouse": "Cómo utilizar",
    "ImageTranslation": "Traducción de imágenes",
    "InstantTranslation": "Traducción instantánea",
    "Membership": "Membresía",
    "NoamHelp": "Noam puede ayudarte a traducir y resumir",
    "PDFparallelTranslation": "Traducción paralela de PDF",
    "ParallelTranslation": "Traducción paralela",
    "SkipAllSteps": "Saltar todos los pasos",
    "Summary": "Resumen",
    "Translation": "Traducción",
    "TryItForFree": "Pruébalo gratis",
    "Video": "Vídeo",
    "VideoSummary": "Resumen de vídeo",
    "VideoTranslation": "Traducción de vídeo",
    "WebSummary": "Resumen web",
    "WordSelectionTranslation": "Traducción por selección de palabras",
    "benefits": "¡Hemos preparado ventajas para ti!",
    "dayFree": "Prueba gratuita de 3 días",
    "done": "Listo",
    "next": "Siguiente",
    "pin": "¡Fija Noam para acceder más fácilmente～～!",
    "planIsNotExist": "El plan no existe",
    "startWiki": "¡Empecemos con Wikipedia~!",
    "startYoutube": "¡Empecemos con YouTube~!",
    "try": "Pruébalo",
    "tryNow": "¡Pruébalo ahora~!"
  },
  "home": {
    "PDFTranslate": {
      "desc": "Traducción bilingüe + Chat PDF",
      "sub1": {
        "desc": "Lectura bilingüe, para que puedas comparar el texto original y la traducción mientras lees para mejorar la eficiencia de lectura",
        "title": "Lectura bilingüe"
      },
      "sub2": {
        "desc": "La IA analiza y resume, y puede responder a tus preguntas en función del contenido del PDF",
        "title": "Chat PDF"
      },
      "sub3": {
        "desc": "PDf impulsado por IA para traducciones más precisas y eficientes",
        "title": "Precisión y eficiencia"
      },
      "title": "Traducir PDF"
    },
    "aiAssistant": {
      "desc": "Haciendo que la traducción web sea más inteligente y conveniente",
      "panel1": {
        "desc": "Las traducciones bilingües de referencias cruzadas te ayudan a comparar el texto original con la traducción para que puedas comprender mejor y mejorar tu eficiencia de lectura.",
        "title": "Traducción bilingüe"
      },
      "panel2": {
        "left": {
          "desc": "Al navegar por una página web, pasa el ratón directamente sobre el contenido que necesita ser traducido para obtener el resultado de la traducción.",
          "title": "Traducción al pasar el ratón"
        },
        "right": {
          "desc": "Al navegar por una página web, selecciona directamente el texto que se subrayará para obtener el resultado de la traducción.",
          "title": "Traducción de selección de texto"
        }
      },
      "panel3": {
        "one": {
          "desc": "Traduce y resume páginas web y contenido de vídeo para ti en cualquier momento, en cualquier lugar y en cualquier escenario; ¡brindándote una experiencia más fluida!",
          "title": "Apuntar y hacer clic"
        },
        "three": {
          "desc": "Gran modelo impulsado por IA para garantizar la precisión y la coherencia de la traducción y mejorar la experiencia de lectura",
          "title": "Impulsado por IA"
        },
        "two": {
          "desc": "Cobertura completa en varios idiomas, lectura bilingüe sin barreras, traducción con una sola tecla; ¡te brindan una experiencia incomparable!",
          "title": "Más completo y conveniente"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "¡Tu experto en eficiencia, combinado con chatgpt traduce y resume el contenido de los vídeos de YouTube para ahorrarte tiempo de lectura!",
          "key": "Video",
          "title": "Traducción y resumen de vídeo"
        },
        "tab2": {
          "desc": "Haz clic para traducir imágenes mientras navegas por la web",
          "key": "Image",
          "title": "Traducir imagen"
        },
        "tab3": {
          "desc": "Extrae rápidamente información clave de las páginas web para resumirlas y mejorar tu eficiencia de lectura",
          "key": "Web",
          "title": "Traducir y resumir la web"
        },
        "tag1": "Simple",
        "tag2": "Eficiencia",
        "tag3": "Fácil de entender"
      },
      "title": "Asistente de IA"
    },
    "getStart": "Comienza ahora",
    "getStartedForFree": "Comienza gratis",
    "howItWork": {
      "desc": "Noam integra modelos de IA para proporcionar a los usuarios una experiencia inteligente para la traducción y el resumen de páginas web en varios idiomas, la traducción de PDF e imágenes, la traducción y el resumen de vídeo",
      "title": "Así es como funciona"
    },
    "lastBlock": {
      "desc": "Mejor traducción de IA y experiencia de lectura más fluida"
    },
    "top": {
      "desc": "Noam es una extensión de navegador de IA gratuita que te ayuda a traducir páginas web resumidas, vídeos y contenido PDF.\\n      ¡Únete a Noam y disfruta de la magia!",
      "title1": "Un Clic",
      "title2": "Una Magia"
    },
    "userReviews": {
      "desc": "Reseña de cinco estrellas en Chrome Web Store",
      "title": "Reseñas de usuarios"
    }
  },
  "installTips": {
    "btn": "Pruébalo ahora",
    "text": "Instala el “Complemento de Noam para navegador” para desbloquear más funciones. Referencias cruzadas web,\\n    resumen de video web ¡y mucho más!"
  },
  "locale": {
    "af": "Afrikáans",
    "am": "Amárico",
    "ar": "Árabe",
    "as": "Asamés",
    "auto": "Detectar idioma",
    "az": "Azerbaiyano",
    "ba": "Bashkir",
    "bg": "Búlgaro",
    "bn": "Bengalí",
    "bo": "Tibetano",
    "bs": "Bosnio",
    "ca": "Catalán",
    "cs": "Checo",
    "cy": "Galés",
    "da": "Danés",
    "de": "Alemán",
    "dsb": "Sorabo inferior",
    "dv": "Divehi",
    "el": "Griego",
    "en": "Inglés",
    "es": "Español",
    "et": "Estonio",
    "eu": "Vasco",
    "fa": "Persa",
    "fi": "Finlandés",
    "fil": "Filipino",
    "fj": "Fiyiano",
    "fr": "Francés",
    "frCA": "Francés (Canadá)",
    "ga": "Irlandés",
    "gl": "Gallego",
    "gom": "Konkani",
    "gu": "Gujarati",
    "ha": "Hausa",
    "he": "Hebreo",
    "hi": "Hindi",
    "hr": "Croata",
    "hsb": "Sorabo superior",
    "ht": "Criollo haitiano",
    "hu": "Húngaro",
    "hy": "Armenio",
    "id": "Indonesio",
    "ig": "Igbo",
    "ikt": "Inuinnaqtun",
    "is": "Islandés",
    "it": "Italiano",
    "iu": "Inuktitut",
    "iuLatn": "Inuktitut (latín)",
    "ja": "Japonés",
    "ka": "Georgiano",
    "kk": "Kazajo",
    "km": "Jemer",
    "kmr": "Kurdo (norte)",
    "kn": "Canarés",
    "ko": "Coreano",
    "ku": "Kurdo (central)",
    "ky": "Kirguís",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Lituano",
    "lug": "Luganda",
    "lv": "Letón",
    "lzh": "Chino (literario)",
    "mai": "Maithili",
    "mg": "Malgache",
    "mi": "Maorí",
    "mk": "Macedonio",
    "ml": "Malayalam",
    "mnCyrl": "Mongol (cirílico)",
    "mnMong": "Mongol (tradicional)",
    "mr": "Maratí",
    "ms": "Malayo",
    "mt": "Maltés",
    "mww": "Hmong Daw",
    "my": "Birmano",
    "nb": "Noruego",
    "ne": "Nepalí",
    "nl": "Holandés",
    "noSearchLang": "Este idioma no está soportado",
    "nso": "Sesotho sa Leboa",
    "nya": "Nyanja",
    "or": "Odia",
    "otq": "Otomí de Querétaro",
    "pa": "Panyabí",
    "pl": "Polaco",
    "placeholder": "Buscar",
    "prs": "Dari",
    "ps": "Pastún",
    "pt": "Portugués (Brasil)",
    "ptPT": "Portugués (Portugal)",
    "ro": "Rumano",
    "ru": "Ruso",
    "run": "Kirundi",
    "rw": "Kinyarwanda",
    "sd": "Sindhi",
    "si": "Cingalés",
    "sk": "Eslovaco",
    "sl": "Esloveno",
    "sm": "Samoano",
    "sn": "Shona",
    "so": "Somalí",
    "sq": "Albanés",
    "srCyrl": "Serbio (cirílico)",
    "srLatn": "Serbio (latín)",
    "st": "Sesotho",
    "sv": "Sueco",
    "sw": "Suajili",
    "ta": "Tamil",
    "te": "Telugu",
    "th": "Tailandés",
    "ti": "Tigriña",
    "tk": "Turcomano",
    "tlhLatn": "Klingon (latín)",
    "tlhPiqd": "Klingon (pIqaD)",
    "tn": "Setsuana",
    "to": "Tongano",
    "tr": "Turco",
    "tt": "Tártaro",
    "ty": "Tahitiano",
    "ug": "Uigur",
    "uk": "Ucraniano",
    "ur": "Urdu",
    "uz": "Uzbeko (latín)",
    "vi": "Vietnamita",
    "xh": "Xhosa",
    "yo": "Yoruba",
    "yua": "Maya yucateco",
    "yue": "Cantonés (tradicional)",
    "zh": "Chino",
    "zhHans": "Chino simplificado",
    "zhHant": "Chino tradicional",
    "zu": "Zulú"
  },
  "login": {
    "and": "  y la",
    "btn": "Iniciar sesión con Google",
    "btn1": "Iniciar sesión ahora",
    "desc": "Inicia sesión para desbloquear más experiencias",
    "policy": " Política de Privacidad",
    "terms": "Términos de Uso",
    "tips": "Al registrarme, acepto los ",
    "title": "Bienvenido a Noam"
  },
  "pageFooter": {
    "follow": {
      "facebook": "Facebook",
      "title": "Seguir",
      "twitter": "Twitter"
    },
    "legal": {
      "policy": "Política de Privacidad",
      "terms": "Condiciones del Servicio",
      "title": "Legal"
    },
    "product": {
      "image": "Traducción de Imágenes",
      "pdf": "Traducción de PDF",
      "title": "Productos",
      "video": "Traducción y Resumen de Video",
      "web": "Traducción y Resumen Web"
    },
    "resources": {
      "title": "Recursos"
    }
  },
  "pageHeader": {
    "contact": "Contáctanos",
    "help": "Centro de ayuda",
    "logout": "Cerrar sesión",
    "logoutSucc": "Cierre de sesión exitoso",
    "pricing": "Precios",
    "settings": "Configuración",
    "signUp": "Iniciar sesión"
  },
  "pdf": {
    "autoDetect": "Detección automática",
    "cancelBtn": "Cancelar",
    "cancelUpload": "Cancelar",
    "chatPdf": {
      "Search": "Buscar",
      "cancel": "Cancelar",
      "chatTitle": "Bienvenido a Noam, tu resumen del PDF está listo:",
      "clear": "Borrar",
      "clearContent": "Después de borrar, se creará una nueva conversación y el contenido de esta conversación no se guardará, ¿estás seguro de que quieres borrarla?",
      "clearDialogTitle": "Atención",
      "clearSucc": "Borrado correctamente",
      "copy": "Copiar",
      "copySucc": "Copia correcta",
      "delete": "Eliminar",
      "deleteCancel": "Cancelar",
      "deleteContent": "Después de eliminar este PDF no se podrá recuperar, ¿quieres eliminarlo?",
      "deleteOk": "Eliminar",
      "deleteSuccess": "Eliminado correctamente",
      "deleteTitle": "Consejo",
      "expand": "Contraer",
      "historyTitle": "Historial",
      "listError": "Error al cargar la lista de lectura. Inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente",
      "loading": "cargando...",
      "noDataDesc": "No hay historial~",
      "noMore": "No hay más.",
      "placeholder": "Introduce cualquier pregunta sobre el PDF",
      "summaryTitle": "Preguntas que te pueden interesar:"
    },
    "collectionEmpty": "¡No hay contenido en la colección~!",
    "collectionTab": "Nota",
    "dataLoading": "Cargando datos...",
    "dialogContent": "¿Estás seguro de que quieres eliminarlo?",
    "dialogTitle": "Atención",
    "download": {
      "both": "bilingüe",
      "btn": "Guardar ahora",
      "cancel": "Al cerrar la ventana emergente se cancelará la descarga del archivo PDF. ¿Estás seguro de que quieres cancelarla?",
      "desc": "Guardar localmente: las traducciones descargadas se guardan como imágenes; Guardar e imprimir: las traducciones descargadas conservan su formato original y permiten copiarlas.",
      "download": "Descargar",
      "downloadMsg1": "Progreso del PDF en traducción:",
      "downloadMsg2": "Requisitos proyectados",
      "downloadSucc": "¡El PDF está listo para descargar, haz clic en guardar ahora!",
      "downloadTip": "El PDF se está descargando. No cierres la ventana emergente durante la descarga para evitar que falle.",
      "error": "Error al guardar localmente, intenta imprimir guardar",
      "fileName": "Nombre del archivo:",
      "fileType": "Tipo de archivo de descarga:",
      "loading": "Guardando...",
      "loading2": "Cargando...",
      "minute": "Minutos...",
      "msg1": "Algunas de las traducciones del documento actual son más largas que el texto original, y el texto puede solaparse después de la descarga, por lo que se recomienda leerlo en la página web.",
      "msg2": "1、El contenido no se ha traducido completamente, descárgalo después de que la traducción se haya completado.",
      "msg3": "2、Parte de la traducción del documento es demasiado larga, puedes leerla en la página web para evitar que el texto se solape.",
      "msg4": "Actualmente hay tareas en proceso de traducción, inténtalo de nuevo cuando se complete la traducción.",
      "progress": "El PDF se está traduciendo. Progreso: {progress}, se estima que tardará {minutes} minutos...",
      "save": "Guardar localmente",
      "savePrint": "Guardar e imprimir",
      "success": "Descarga correcta",
      "trans": "Solo traducción",
      "transContinue": "Continuar traduciendo"
    },
    "downloadBtn": "Descargar",
    "dragTipModal": {
      "black": "espacio",
      "leftKey": "botón izquierdo",
      "ok": "Entendido",
      "press": "Pulsa",
      "tips": "para arrastrar y ver."
    },
    "entry": "Lista de PDF",
    "fileErrMsg": "Error al leer el archivo, inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente.",
    "fileMsg": "Volumen del archivo actual:",
    "fileMsg2": "Carga un archivo de menos de 100 MB",
    "freeBannerBtn": "Actualiza para disfrutar de más",
    "freeBannerTips": "Fin de la traducción gratuita de PDF",
    "guide": {
      "placeholder": "Introduce el enlace del PDF",
      "start": "Iniciar traducción",
      "text1": "Lectura bilingüe",
      "text2": "Lectura bilingüe, para que puedas comparar el texto original y la traducción mientras lees para mejorar la eficiencia de la lectura",
      "text3": "Chatear con PDF",
      "text4": "La IA analiza y resume, y puede responder a tus preguntas basándose en el contenido del PDF",
      "text5": "Precisión y eficiencia",
      "text6": "PDF impulsado por IA para traducciones más precisas y eficientes",
      "title1": "Traducción bilingüe",
      "title2": "Chatear con PDF",
      "toast": "Introduce un enlace válido a un PDF en línea que termine en .pdf"
    },
    "hasTransTask": "Hay una tarea en proceso de traducción. Por favor, espera.",
    "hoverTrans": "Traducción de párrafos al pasar el cursor",
    "initial": "Traducir PDF",
    "installText": "Una solución integral para todas tus necesidades diarias de traducción",
    "installText2": "Instala primero",
    "installTips": "Instala el pequeño complemento, podrás traducir más archivos PDF y también podrás disfrutar de una traducción fluida de las páginas web:",
    "kw": "grapa",
    "link": "enlace",
    "listError": "Error al cargar la lista de PDF, inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente.",
    "memoTotalCount": "Total de 1 contenido",
    "new": "nuevo archivo",
    "noId": "El ID del archivo no existe",
    "notPdf": "Solo archivos PDF",
    "okBtn": "Eliminar",
    "okBtn2": "reconocer",
    "original": "Original",
    "parallel": "Paralelo",
    "parseErrDialogContent": "El enlace PDF actual no admite la traducción directa, descarga el PDF del enlace original después de la traducción",
    "parseErrTips": "No se ha podido analizar el archivo",
    "parseFileToast": "Error al analizar el archivo, inténtalo de nuevo más tarde o ponte en contacto con nuestro servicio de atención al cliente.",
    "pdfLoading": "Analizando...",
    "searchPlaceholder": "Buscar",
    "selectTrans": "subraya las palabras y traduce",
    "shareBtn": "Compartir",
    "shareBtnText1": "Generar enlaces para compartir",
    "shareBtnText2": "Contenido para compartir actualizado",
    "shareModal": {
      "cancel": "Cancelar",
      "copy": "Copiar",
      "copySucc": "Enlace copiado correctamente",
      "custom": "Personalización:",
      "errMsg": "El contenido del rango de uso compartido actual no se ha traducido completamente, tradúcelo antes de compartirlo.",
      "gen": "Compartir",
      "modify": "modificar",
      "notSupport": "Este idioma no está soportado actualmente",
      "page": "páginas",
      "pageNuErr": "Introduce un valor correcto para el número de página",
      "range": "Ámbito:",
      "remain": "restante",
      "remain1": "Requisitos totales proyectados",
      "search": "Buscar",
      "settings": "Configuración para compartir:",
      "shareSetting": "Configuración del contenido para compartir",
      "shareText": "Compartir",
      "shareTips": "Comparte el enlace del PDF traducido con tus amigos para que lo lean:",
      "sourceLang": "Origen:",
      "target": "Destino:",
      "text1": "Configuración para compartir",
      "text2": "Generar enlaces para compartir",
      "totalPage": "1 página en total",
      "trans": "Traducir",
      "translateTips": "Consejo: si la traducción tarda mucho tiempo, puedes cerrar la ventana emergente y compartirla una vez finalizada la traducción.",
      "translating": "Traduciendo PDF...",
      "update": "Enlaces para compartir actualizados"
    },
    "shareMsg1": "Generación del enlace para compartir correcta",
    "shareMsg2": "Actualización del contenido para compartir correcta",
    "shareMsg3": "Error al generar el enlace para compartir, inténtalo de nuevo más tarde o ponte en contacto con nuestro servicio de atención al cliente.",
    "shareMsg4": "Error al actualizar el contenido para compartir, inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente",
    "shareTips": "¡La traducción del PDF ha finalizado, compártelo con tus amigos!",
    "start": "Comienza tu experiencia gratuita",
    "thumbnail": "catálogos",
    "toast1": "¡El PDF se está cargando, inténtalo de nuevo más tarde!",
    "toast2": "El contenido del PDF es demasiado extenso, por el momento no se admite el diálogo",
    "toast3": "Descargando, inténtalo de nuevo cuando se complete la descarga.",
    "toolbar": {
      "Adjust": "Ajustar",
      "autoFit": "Ajustar automáticamente"
    },
    "trans": "Traducción",
    "transSucc": "¡La traducción se ha realizado correctamente!",
    "unLoginToast": "Inicia sesión para utilizar esta función",
    "uploadBtn": "Cargar",
    "uploadErr": "Error al cargar el archivo. Inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente",
    "uploadMsg1": "Arrastra y suelta el archivo aquí",
    "uploadMsg2": "Tipos de archivo admitidos: PDF  |  Tamaño máximo de archivo: 100 MB",
    "uploadMsg3": "Haz clic para seleccionar o arrastra y suelta los archivos para cargarlos aquí",
    "uploading": "Cargando",
    "uploadingMsg": "Ten paciencia, ya que la carga puede llevar algún tiempo.",
    "uploadingMsg1": "Carga de archivos en curso. Inténtalo de nuevo más tarde.",
    "uploadingMsg2": "Actualmente hay un archivo en proceso de conversión, inténtalo de nuevo más tarde.",
    "uploadingMsg3": "Error en la consulta del progreso de la conversión, inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente",
    "uploadingMsg4": "Noam no puede acceder directamente a tus archivos locales, carga manualmente un PDF para traducirlo.",
    "uploadingMsg5": "Error al obtener la URL del archivo, inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente.",
    "video": "vídeo",
    "vipBannerBtn": "Actualiza para disfrutar de más",
    "vipBannerTips": "Has alcanzado tu límite de traducción de PDF este mes.",
    "webTrans": "referencia cruzada completa"
  },
  "pricing": {
    "FAQ": "Preguntas frecuentes",
    "FQAObj": {
      "a1": "Existe una versión gratuita de Noam que se puede utilizar directamente.",
      "a2": "Además de la versión gratuita, hay tres planes de membresía, Pro, Pro+ y Enterprise; puedes elegir entre dos métodos de pago, mensual continuo y anual continuo, y el coste total del anual continuo es más favorable.",
      "a3": "No hay diferencia en cuanto a la funcionalidad. El coste total de la suscripción anual continua es inferior al de la suscripción mensual continua; la suscripción mensual se factura una vez al mes, mientras que la suscripción anual se factura una vez al año.",
      "a4": "Puedes ir a la página de configuración del backend y elegir suscribirte para cancelar el plan de renovación.",
      "q1": "¿Puedo usarlo gratis?",
      "q2": "¿Hay diferentes planes de membresía y opciones de pago?",
      "q3": "¿Cuál es la diferencia entre una suscripción mensual continua y una suscripción anual continua?",
      "q4": "¿Cómo puedo cancelar mi plan de renovación automática?"
    },
    "around": "Alrededor de",
    "billedMonthly": "Facturación mensual",
    "billedYearly": "Facturación anual",
    "day": "día",
    "desc": "Compara y elige lo mejor para ti",
    "detailedCompare": "Comparación detallada",
    "discount": "20% de descuento",
    "enterprise": {
      "benefit1": "Soporte rápido del servicio de atención al cliente",
      "benefit2": "Multiusuario",
      "benefit3": "Soluciones personalizadas",
      "benefit5": "Gestor de cuentas exclusivo",
      "btn": "Contacto",
      "recommendText": "Planes personalizados（≥3人）",
      "talkToUs": "Háblanos"
    },
    "free": {
      "benefit1": "Traducción instantánea ${FREE_BENEFITS.realTimeCount} veces/día",
      "benefit2": "Traducción y resumen web",
      "benefit3": "Traducción de imágenes ${FREE_BENEFITS.imageCount} hojas",
      "benefit4": "Traducción y resumen de vídeo",
      "benefit5": "Traducción de ${FREE_BENEFITS.pdfUploadCount} PDF",
      "benefit6": "${FREE_BENEFITS.tokenCount} tokens",
      "btn": "Disfrútalo",
      "recommendText": "Prueba gratuita"
    },
    "imageTranslation": "Traducción de imágenes",
    "month": "mes",
    "monthly": "Mensual",
    "pageTitle": "Precios",
    "pdf": {
      "chat": "Número de consultas de PDF",
      "maxPage": "Número máximo de páginas en PDF",
      "translation": "Traducción de PDF"
    },
    "preMonth": "Al mes",
    "pro": {
      "benefit1": "Soporte rápido del servicio de atención al cliente",
      "benefit2": "${PRO_BENEFITS.tokenCount} tokens/mes",
      "benefit3": "Traducción y resumen web",
      "benefit4": "Traducción de imágenes ${PRO_BENEFITS.imageCount} hojas",
      "benefit5": "Traducción y resumen de vídeo",
      "benefit6": "Traducción de ${PRO_BENEFITS.pdfUploadCount} PDF",
      "btn": "Empezar",
      "recommendText": "La mayoría de la gente elige"
    },
    "proPlus": {
      "benefit1": "Soporte rápido del servicio de atención al cliente",
      "benefit2": "${PRO_V_BENEFITS.tokenCount} tokens/mes",
      "benefit3": "Traducción y resumen web",
      "benefit4": "Traducción de imágenes ${PRO_V_BENEFITS.imageCount} hojas",
      "benefit5": "Traducción y resumen de vídeo",
      "benefit6": "Traducción de ${PRO_V_BENEFITS.pdfUploadCount} PDF",
      "recommendText": "La opción más adecuada"
    },
    "video": {
      "summary": "Resumen de vídeo",
      "translation": "Traducción de vídeo"
    },
    "web": {
      "contrastiveTranslation": "Traducción bilingüe",
      "dictionaryTranslation": "Traducción por selección de texto",
      "hoverTranslation": "Traducción al pasar el cursor",
      "instantTranslation": "Traducción instantánea",
      "summary": "Resumen de la página web"
    },
    "yearly": "Anual"
  },
  "privacy": {
    "block1": "En Noam (en adelante, “Noam”, “nosotros” o “nuestro”) respetamos tu privacidad y nos comprometemos a proteger la información que obtenemos de ti o sobre ti. Esta Política de privacidad describe nuestras prácticas con respecto a la recopilación o el uso de información personal a partir de tu uso de nuestro sitio web, aplicaciones y servicios (denominados colectivamente los “Servicios”). Esta Política de privacidad no se aplica al contenido que manejamos en nombre de nuestros clientes de productos comerciales, como nuestra API. El uso de estos datos está sujeto a nuestro acuerdo con el cliente, que cubre el acceso y el uso de estos productos.",
    "block10": "Implementamos medidas técnicas, administrativas y organizativas comercialmente razonables para proteger la información personal en línea y fuera de línea contra la pérdida, el uso indebido y el acceso no autorizado, la divulgación, la alteración o los actos hostiles. Sin embargo, ninguna transmisión por Internet o correo electrónico puede garantizar la seguridad o precisión completas. En particular, es posible que los correos electrónicos que nos envían o que enviamos nosotros no sean seguros. Por lo tanto, debes tener especial cuidado al decidir qué información nos envías a través del servicio o correo electrónico. Además, no somos responsables de ninguna obstrucción de la configuración de privacidad o las medidas de seguridad en el servicio o la configuración de seguridad o las medidas de seguridad de los sitios web de terceros. Conservaremos tu información personal durante el tiempo que sea necesario para que podamos proporcionarte servicios o para otros fines comerciales legítimos, como resolver disputas, por razones de seguridad y protección, o para cumplir con nuestras obligaciones legales. El tiempo que conservemos la información personal dependerá de muchos factores, como la cantidad, la naturaleza, la sensibilidad de la información, los riesgos potenciales de uso o divulgación no autorizados, los fines para los que procesamos la información y otras consideraciones legalmente vinculantes.",
    "block11": "Es posible que actualicemos esta Política de privacidad de vez en cuando. Cuando lo hagamos, publicaremos una versión actualizada en esta página, a menos que la ley aplicable exija lo contrario.",
    "block12": "Si tienes alguna pregunta o inquietud sin respuesta sobre esta Política de privacidad, comunícate con nuestro servicio de atención al cliente. Correo electrónico: ",
    "block2": "Recopilamos la siguiente información personal (“Información personal”) relacionada contigo:",
    "block3": "Información proporcionada por ti: Si creas una cuenta para utilizar nuestros servicios o comunicarte con nosotros, recopilaremos información personal de la siguiente manera:",
    "block4": "Información personal recibida automáticamente de tu uso de los Servicios: Cuando accedes, utilizas o interactúas con los servicios, recibimos la siguiente información sobre tu acceso, uso o interacción (“Información técnica”):",
    "block5": "Podemos utilizar la información personal para los siguientes fines:",
    "block6": "Información resumida o no identificada: Podemos agregar o eliminar la identificación de la información personal para evitar su reutilización con fines de identificación y utilizar dicha información para analizar la eficacia de nuestros servicios, mejorar y agregar funciones a nuestros servicios, realizar investigaciones y para fines similares. Además, podemos analizar el comportamiento general y las características de los usuarios de nuestro servicio y compartir información agregada con terceros, publicar dicha información agregada o ponerla a disposición del público en general. Podemos recopilar información agregada a través de los servicios, a través de cookies y a través de otros medios descritos en esta Política de privacidad. Mantendremos y utilizaremos la información no identificada de forma anónima o no identificada, y no intentaremos volver a identificar dicha información a menos que lo exija la ley.",
    "block7": "En ciertos casos, podemos proporcionar tu información personal a terceros sin previo aviso, a menos que lo exija la ley:",
    "block8": "Dependiendo de tu ubicación geográfica, las personas en el Espacio Económico Europeo, el Reino Unido y en todo el mundo pueden tener ciertos derechos legales con respecto a su información personal. Por ejemplo, puedes tener derecho a:",
    "block9": "El servicio puede contener enlaces a otros sitios web que Noam no opera ni controla, incluidos los servicios de redes sociales (“Sitios web de terceros”). La información que compartes con los Sitios web de terceros se regirá por las políticas de privacidad y los términos de servicio específicos de los Sitios web de terceros, no por esta Política de privacidad. Proporcionamos estos enlaces para tu comodidad y no implican nuestra aprobación o revisión de estos sitios web. Comunícate directamente con los sitios web de terceros para obtener información sobre sus políticas de privacidad y términos.",
    "subBlock1": "Información de la cuenta: Cuando creas una cuenta en nuestra plataforma, recopilamos información relacionada con tu cuenta, incluido tu nombre, información de contacto, credenciales de la cuenta, información de la tarjeta de pago e historial de transacciones (denominada “Información de la cuenta”).",
    "subBlock10": "Proporcionar, administrar, mantener y/o analizar servicios;",
    "subBlock11": "Comunicarnos contigo;",
    "subBlock12": "Desarrollar nuevos proyectos y servicios;",
    "subBlock13": "Prevenir el fraude, la actividad delictiva o el uso indebido de nuestros servicios, y proteger la seguridad de nuestros sistemas de TI, arquitectura y redes;",
    "subBlock14": "Realizar transferencias comerciales;",
    "subBlock15": "Cumplir con las obligaciones legales y los procedimientos legales, proteger nuestros derechos, privacidad, seguridad o propiedad, así como los de nuestros afiliados, tú u otros terceros.",
    "subBlock16": "Proveedores y proveedores de servicios: Para ayudarnos a satisfacer las necesidades operativas del negocio y realizar ciertos servicios y funciones, podemos proporcionar información personal a proveedores y proveedores de servicios, incluidos proveedores de servicios de alojamiento, proveedores de servicios en la nube y otros proveedores de servicios de tecnología de la información, software de comunicación por correo electrónico y análisis web. proveedores de servicios, etc. Estos socios accederán, procesarán o almacenarán información personal solo bajo nuestra dirección mientras realizan sus funciones para nosotros.",
    "subBlock17": "Transferencias comerciales: Si participamos en transacciones estratégicas, reorganizaciones, quiebras, adquisiciones por quiebra o transición de servicios a otro proveedor (denominados colectivamente “Transacciones”), tu información personal y otra información pueden compartirse con las contrapartes de la transacción y otras partes que ayuden en las Transacciones durante la debida diligencia y transferirse como parte de las Transacciones junto con otros activos a un sucesor o empresa afiliada.",
    "subBlock18": "Requisitos legales: Podemos compartir tu información personal con autoridades gubernamentales, pares de la industria u otros terceros, incluida tu información de interacción con nuestros servicios, (1) si lo exige la ley o si creemos que dicha acción es necesaria para cumplir con las obligaciones legales; (2) para proteger y defender nuestros derechos o propiedad; (3) si determinamos unilateralmente que existe una violación de nuestros términos, políticas o leyes; (4) para detectar o prevenir fraudes u otras actividades ilegales; (5) para proteger nuestros productos, empleados o usuarios, o la seguridad, la integridad o la seguridad pública; o (vi) para defendernos de la responsabilidad legal.",
    "subBlock19": "Acceder a tu información personal e información sobre cómo se procesa.",
    "subBlock2": "Contenido del usuario: Cuando utilizas nuestros servicios, recopilamos información personal de tus entradas, cargas de archivos o comentarios que nos proporciones (denominado “Contenido”).",
    "subBlock20": "Eliminar tu información personal de nuestros registros.",
    "subBlock21": "Corregir o actualizar tu información personal.",
    "subBlock22": "Transferir tu información personal a un tercero (portabilidad de datos).",
    "subBlock23": "Limitar cómo procesamos tu información personal.",
    "subBlock24": "Retirar tu consentimiento: cuando nos basamos en el consentimiento como base legal para el procesamiento, puedes retirar tu consentimiento en cualquier momento.",
    "subBlock25": "Objetar cómo procesamos tu información personal.",
    "subBlock26": "Mejorar nuestros servicios y realizar investigaciones;",
    "subBlock3": "Información de comunicación: Si nos envías mensajes, recopilaremos tu nombre, información de contacto y el contenido de los mensajes que envíes (denominada “Información de comunicación”).",
    "subBlock4": "Información de redes sociales: Tenemos páginas en sitios web de redes sociales como Instagram, Facebook, Medium, Twitter, YouTube y LinkedIn. Cuando interactúas con nuestras páginas de redes sociales, recopilamos la información personal que eliges proporcionarnos, como tus datos de contacto (denominada “Información social”). Además, las empresas que alojan nuestras páginas de redes sociales pueden proporcionarnos información agregada y análisis sobre nuestras actividades en las redes sociales.",
    "subBlock5": "Datos de registro: Información enviada automáticamente por tu navegador cuando utilizas nuestros servicios. Los datos de registro incluyen tu dirección de Protocolo de Internet (IP), el tipo y la configuración del navegador, la fecha y hora de tus solicitudes y cómo interactúas con nuestro sitio web.",
    "subBlock6": "Datos de uso: Podemos recopilar automáticamente información sobre tu uso del servicio, como los tipos de contenido que ves o con los que interactúas, las funciones que utilizas y las acciones que realizas, así como tu zona horaria, país, fecha y hora de acceso, agente de usuario y versión, tipo de computadora o dispositivo móvil y tu conexión de computadora.",
    "subBlock7": "Información del dispositivo: Incluye el nombre del dispositivo, el sistema operativo, el identificador del dispositivo y el navegador que estás utilizando. La información recopilada puede depender del tipo de dispositivo que estés utilizando y su configuración.",
    "subBlock8": "Cookies: Utilizamos cookies para operar y administrar nuestros servicios y mejorar tu experiencia. Una “cookie” es información que un sitio web que visitas envía a tu navegador. Puedes configurar tu navegador para que acepte todas las cookies, rechace todas las cookies o te notifique cuando se proporciona una cookie para que puedas decidir si la aceptas cada vez. Sin embargo, rechazar las cookies puede impedirte utilizar o afectar negativamente la visualización o funcionalidad de ciertas áreas o características del sitio web. Para obtener más información sobre las cookies, visita All About Cookies.",
    "subBlock9": "Análisis: Podemos utilizar varios productos de análisis en línea que utilizan cookies para ayudarnos a analizar cómo los usuarios utilizan nuestros servicios y mejorar tu experiencia al utilizar los servicios.",
    "subTitle1": "Información personal que recopilamos",
    "subTitle2": "Cómo utilizamos la información personal",
    "subTitle3": "Divulgación de información personal",
    "subTitle4": "Tus derechos",
    "subTitle5": "Enlaces a otros sitios web",
    "subTitle6": "Seguridad y retención de información",
    "subTitle7": "Modificación de la Política de Privacidad",
    "subTitle8": "Cómo contactarnos",
    "title": "Política de privacidad",
    "updateTime": "Actualizado: 15 de marzo de 2024"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "Aceptar todo",
    "text": "Para mejorar tu experiencia, nos gustaría utilizar cookies para personalizar el contenido y los servicios.",
    "title": "Este sitio web utiliza cookies"
  },
  "slogen": "El asistente IA más sencillo",
  "stripe": {
    "contact": "Para cualquier duda, contáctanos a contact@noam.tools.",
    "fail": {
      "btn": "Reintentar",
      "text1": "Lamentablemente, no podemos procesar tu solicitud de pago.",
      "text2": "Comprueba tu conexión a Internet y vuelve a intentarlo.",
      "title": "Error en el pago"
    },
    "succ": {
      "btn": "¡Hagamos algo de magia!",
      "text1": "¡Bienvenido a Noam, ",
      "text2": "¡Comencemos juntos!",
      "title": "¡Felicitaciones!"
    }
  },
  "terms": {
    "block1": "¡Gracias por usar Noam!",
    "block10": "(a) Tarifas y Facturación. Pagará todas las tarifas (\"Tarifas\") de acuerdo con los precios y términos de la página de precios aplicable o según lo acordado por escrito entre nosotros. Nos reservamos el derecho de corregir errores de precios o equivocaciones, incluso si hemos emitido facturas o recibido el pago. Proporcionará información de facturación completa y precisa, incluido un método de pago válido y autorizado. Cargaremos a su método de pago de forma periódica según lo acordado, pero podemos cambiar razonablemente la fecha de facturación. Usted autoriza a Noam, sus afiliados y procesadores de pago externos a cobrar a su método de pago. Si su pago falla, le enviaremos una notificación por escrito y podemos suspender el acceso al Servicio hasta que se reciba el pago. Salvo que se especifique lo contrario en este acuerdo, los pagos no son reembolsables.",
    "block11": "(b) Impuestos. A menos que se indique lo contrario, las Tarifas no incluyen ningún impuesto federal, estatal, local o extranjero, aranceles y evaluaciones similares (\"Impuestos\"). Usted es responsable de todos los Impuestos asociados con sus compras, excluyendo los Impuestos basados en nuestros ingresos netos por los cuales le facturaremos. Usted acepta pagar puntualmente dichos Impuestos y proporcionarnos declaraciones de privacidad legalmente válidas y obtener los consentimientos necesarios para el manejo de dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable.",
    "block12": "(c) Cambios de Precio. Podemos cambiar nuestros precios notificándoselo a través de su cuenta y/o nuestro sitio web. Los aumentos de precios entrarán en vigor inmediatamente después de su publicación. Cualquier cambio de precio se aplicará a las Tarifas de su cuenta después de la fecha de entrada en vigor del cambio.",
    "block13": "(d) Controversias y Pagos Atrasados. Si desea disputar alguna Tarifa o Impuesto, comuníquese con contact@noam.tools dentro de los treinta (30) días posteriores a la fecha de la factura.",
    "block14": "(e) Uso Gratuito. No puede crear varias cuentas para obtener créditos gratuitos. Si determinamos que no está utilizando los créditos gratuitos de buena fe, podemos cobrarle tarifas estándar o dejar de proporcionarle acceso al Servicio.",
    "block15": "(a) Confidencialidad. Puede tener acceso a información confidencial de Noam. Solo puede usar información confidencial según lo permita estos términos con el propósito de usar el Servicio. No puede divulgar información confidencial a ningún tercero, y protegerá la información confidencial de una manera no menos protectora de lo que protege su propia información confidencial similar, al menos con un cuidado razonable. La información confidencial es información no pública designada como confidencial por Noam o que razonablemente debe tratarse como confidencial dadas las circunstancias, incluido el software, las especificaciones y otra información comercial no pública. La información confidencial no incluye información que: (1) esté generalmente disponible al público sin culpa suya; (2) tenía en su poder sin ninguna obligación de confidencialidad antes de recibirla bajo estos términos; (3) le es legalmente divulgada por un tercero sin ninguna obligación de confidencialidad; o (4) usted la desarrolla de forma independiente sin utilizar la información confidencial. Puede divulgar información confidencial si así lo exige la ley, un tribunal u otra orden gubernamental, pero debe notificarlo por escrito a Noam de manera razonable y, en la medida de lo posible, hacer esfuerzos razonables para limitar el alcance de la divulgación, incluida la asistencia en la oposición a las solicitudes de divulgación.",
    "block16": "(b) Seguridad. Debe implementar medidas razonables y apropiadas para ayudar a salvaguardar su acceso y uso del Servicio. Si descubre alguna vulnerabilidad o violación relacionada con su uso del Servicio, debe comunicarse de inmediato con Noam y proporcionar detalles de la vulnerabilidad o violación.",
    "block17": "((c) Procesamiento de Datos Personales. Si utiliza el Servicio para procesar datos personales, debe proporcionar declaraciones de privacidad legalmente suficientes y obtener los consentimientos necesarios para procesar dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable.",
    "block18": "(a) Terminación; Suspensión. Estos términos entrarán en vigor desde su primer uso del Servicio y permanecerán en vigor hasta su terminación. Puede rescindir estos términos en cualquier momento y por cualquier motivo suspendiendo el uso del Servicio y el Contenido. Podemos rescindir estos términos con previo aviso por cualquier motivo. Podemos rescindir estos términos de inmediato notificándole si usted incumple materialmente la Sección 2 (Requisitos de Uso), la Sección 5 (Confidencialidad, Seguridad y Protección de Datos), la Sección 8 (Resolución de Disputas) o la Sección 9 (Términos Generales), o si nuestra relación con cualquier proveedor de tecnología externo fuera de nuestro control cambia, o para cumplir con la ley o las solicitudes gubernamentales. Podemos suspender su acceso al Servicio si no cumple con estos términos, o si su uso representa un riesgo de seguridad para nosotros o para cualquier tercero, o si sospechamos que su uso es fraudulento o puede exponernos a nosotros o a cualquier tercero a responsabilidad.",
    "block19": "(b) Efectos de la Terminación. Tras la terminación, dejará de utilizar el Servicio y devolverá o destruirá de inmediato cualquier información confidencial según lo indiquemos. Las disposiciones de estos términos que por su naturaleza deban sobrevivir a la terminación o expiración, incluidas, entre otras, las Secciones 3 y 5 a 9, sobrevivirán.",
    "block2": "Estas Condiciones de Uso se aplican a su uso de los servicios de Noam, incluidas nuestras interfaces de programación de aplicaciones, software, herramientas, servicios para desarrolladores, datos, documentación y sitio web (denominados colectivamente como los \"Servicios\"). Al utilizar nuestros Servicios, usted acepta cumplir con estos términos. Nuestra Política de Privacidad explica cómo recopilamos y usamos la información personal.",
    "block20": "(a) Indemnización. Usted nos defenderá, indemnizará y eximirá de responsabilidad a nosotros, nuestros afiliados y nuestro personal de y contra cualquier reclamo, pérdida y gasto (incluidos los honorarios de abogados) que surjan de o estén relacionados con su uso del Servicio, incluido su Contenido, su uso de productos o servicios relacionados con el Servicio, y su violación de estos términos o la ley aplicable.",
    "block21": "(b) Descargos de Responsabilidad. El Servicio se proporciona \"tal cual\". En la medida en que lo permita la ley, nosotros y nuestros afiliados y licenciantes no hacemos representaciones ni garantías de ningún tipo con respecto al Servicio y no ofrecemos garantías, incluidas, entre otras, garantías de comerciabilidad, idoneidad para un propósito particular, calidad satisfactoria, no infracción y disfrute tranquilo, y cualquier garantía que surja del curso del trato o el uso comercial. No garantizamos que el Servicio sea ininterrumpido, preciso o libre de errores, o que cualquier Contenido sea seguro, no se pierda o no se altere.",
    "block22": "(c) Limitación de Responsabilidad. Ni nosotros, ni nuestros afiliados ni nuestros licenciantes seremos responsables de ningún daño indirecto, incidental, especial, consecuente o punitivo, incluida la pérdida de beneficios, la pérdida de reputación, la pérdida de uso, la pérdida de datos u otras pérdidas intangibles, incluso si se nos ha informado de la posibilidad de tales daños. Bajo estos términos, nuestra responsabilidad total no excederá las Tarifas que pagó al Servicio en los doce (12) meses anteriores al reclamo o cien dólares estadounidenses ($100), lo que sea mayor. Las limitaciones de esta sección se aplican en la máxima medida permitida por la ley aplicable.",
    "block23": "Los Términos y su uso de los Servicios se rigen por las leyes de los Estados Unidos, a excepción de las normas sobre conflictos de leyes de los Estados Unidos. Su uso de la Aplicación también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales. Cualquier acción legal o procedimiento relacionado con estos Términos se iniciará exclusivamente en los tribunales de los Estados Unidos, y usted acepta someterse a la jurisdicción personal de dichos tribunales.",
    "block24": "(a) Relación de las Partes. Noam y usted son contratistas independientes, y ninguna de las partes tiene el poder de representar o obligar a la otra parte o asumir obligaciones por la otra parte sin el consentimiento previo por escrito de la otra parte.",
    "block25": "(b) Uso de la Marca. No puede usar el nombre, los logotipos o las marcas comerciales de Noam ni de ninguno de sus afiliados sin nuestro consentimiento previo por escrito.",
    "block26": "Si alguna disposición de estos términos se considera inválida, ilegal o inaplicable, las disposiciones restantes permanecerán en pleno vigor y efecto.",
    "block3": "Si usted es menor de 18 años, debe obtener el permiso de sus padres o tutores legales para usar el Servicio. Si está utilizando el Servicio en nombre de otra persona o entidad, debe estar autorizado para aceptar estos términos en su nombre. Debe proporcionar información precisa y completa para registrar una cuenta. No puede proporcionar sus credenciales de acceso o cuenta a nadie fuera de su organización, y usted es responsable de todas las actividades que se realicen utilizando sus credenciales.",
    "block4": "(a) Uso del Servicio. Puede acceder al Servicio bajo estos términos, y le otorgamos un derecho no exclusivo para usar el Servicio. Al usar el Servicio, usted cumplirá con estos términos y todas las leyes aplicables. Nosotros y nuestros afiliados somos propietarios de todos los derechos, títulos e intereses del Servicio.",
    "block5": "(b) Comentarios. Agradecemos sus comentarios, ideas, sugerencias y mejoras. Si proporciona dicho contenido, podemos usarlo sin restricciones y sin compensación para usted.",
    "block6": "(c) Restricciones. No puede: (1) usar el Servicio de una manera que infrinja, se apropie indebidamente o viole los derechos de terceros; (2) aplicar ingeniería inversa, descompilar, desarmar, traducir o intentar descubrir el código fuente o los componentes subyacentes de los modelos, algoritmos y sistemas del Servicio (a menos que dichas restricciones estén prohibidas por la ley aplicable); (3) usar el resultado del Servicio para desarrollar modelos que compitan con Noam; (4) extraer datos o resultados del Servicio utilizando cualquier método automatizado o de programación a menos que esté permitido a través de la API, incluido el raspado, la recolección web o la extracción de datos web; (5) presentar el resultado del Servicio como generado artificialmente cuando no lo es, o violar de otro modo nuestras políticas de uso; (6) comprar, vender o transferir claves API sin nuestro consentimiento previo; o (7) proporcionarnos cualquier información personal de niños menores de 13 años o la edad aplicable de consentimiento digital. Cumplirá con todos los límites de frecuencia y otros requisitos de nuestra documentación.",
    "block7": "(d) Servicios de Terceros. Cualquier software, servicio u otro producto de terceros asociado con el Servicio se rige por sus propios términos, y no somos responsables de los productos de terceros.",
    "block8": "(a) Su Contenido. Puede proporcionar información (\"Información\") al Servicio y recibir resultados (\"Resultados\") generados y devueltos en función de la Información (denominados colectivamente como \"Contenido\"). Entre las partes, y en la medida en que lo permita la ley aplicable, usted es propietario de toda la Información. Noam puede usar el Contenido para proporcionar y mantener el Servicio, cumplir con las leyes aplicables y hacer cumplir nuestras políticas. Usted es responsable del Contenido, lo que incluye asegurarse de que no viole ninguna ley aplicable o estos términos.",
    "block9": "(b) Precisión. La inteligencia artificial y el aprendizaje automático son campos de investigación en rápida evolución. Nos esforzamos continuamente por mejorar nuestro servicio para que sea más preciso, confiable, seguro y beneficioso. Dada la naturaleza probabilística del aprendizaje automático, el uso de nuestro servicio puede dar lugar a resultados incorrectos en determinados casos, que pueden no reflejar con precisión a personas, lugares o hechos reales. Debe evaluar adecuadamente la precisión de cualquier resultado en función de su caso de uso, incluso mediante la revisión manual de los resultados.",
    "subTitle1": "Registro y Acceso",
    "subTitle10": "Divisibilidad",
    "subTitle2": "Requisitos de Uso",
    "subTitle3": "Contenido",
    "subTitle4": "Tarifas y Pago",
    "subTitle5": " Confidencialidad, Seguridad y Protección de Datos",
    "subTitle6": "Plazo y Terminación",
    "subTitle7": "Indemnización; Descargos de Responsabilidad; Limitación de Responsabilidad",
    "subTitle8": "Ley Aplicable y Jurisdicción",
    "subTitle9": "Términos Generales",
    "title": "Condiciones del Servicio",
    "updateTime": "Actualizado:15 de marzo de 2024"
  },
  "trialModal": {
    "btn": "Comenzar ahora (solo $0.99)",
    "chat": "ChatPDF",
    "desc1": "Una vez finalizada la prueba, se le cobrará una tarifa anual de $180",
    "desc2": "Cancelar en cualquier momento",
    "focus": "Enfocando",
    "hover": "Traducción al pasar el ratón",
    "image": "Traducción de imágenes",
    "installBtn": "Instala ahora para recibir tu regalo",
    "memo": "Gestión de favoritos",
    "pdf": "Traducción paralela de PDF",
    "realtime": "Traducción instantánea",
    "select": "Traducción de selección de palabras",
    "title": "Regalo de bienvenida",
    "trialText": "Prueba de 7 días",
    "video": "Traducción de vídeo",
    "videoSummary": "Resumen de vídeo",
    "web": "Traducción paralela",
    "webSummary": "Resumen web"
  },
  "upgradeModal": {
    "btn": "Actualizar Ahora",
    "chat": {
      "free": "Has alcanzado tu límite de Chatpdf",
      "pro": "Has alcanzado tu límite de Chatpdf este mes"
    },
    "contactStr": "Espera al siguiente reinicio o contáctanos a contact@noam.tools.",
    "limitAlertStr": "Se ha agotado tu cuota del plan gratuito, te recomendamos que actualices tu plan para continuar con la experiencia.",
    "more": "Más Planes",
    "pageLimit": {
      "free": "Has alcanzado tu límite de páginas PDF",
      "pro": "Has alcanzado tu límite de páginas PDF este mes"
    },
    "title": "Recordatorios",
    "upgradeAlertStr": "Se ha agotado tu cuota del plan gratuito, te recomendamos que actualices tu plan para continuar con la experiencia.",
    "upload": {
      "free": "Has alcanzado tu límite de traducción de PDF",
      "pro": "Has alcanzado tu límite de traducción de PDF este mes"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "Unirse Ahora",
      "label": "Beneficios para Nuevos Usuarios:",
      "remain": "Solo quedan 30",
      "text": "¡Solo quedan 100 lugares disponibles! ¡Consigue el tuyo ahora!"
    }
  }
}

export default TRANSLATION
