const TRANSLATION = {
  "buyModal": {
    "afterFree": "トライアル終了後",
    "btn": "無料で試す",
    "freeTrial": "3日間無料トライアル",
    "monthLabel": "ヶ月",
    "selectLabel": "プランを選択",
    "title": "サブスクリプションプラン",
    "yearLabel": "年"
  },
  "contact": {
    "block1": "カスタマイズについて、またはご質問やご提案がございましたら、contact@noam.toolsまでご連絡ください。すぐに対応いたします。",
    "block2": "Googleアプリ拡張機能ストアで★★★★★と評価して、私たちをサポートしてください。",
    "block3": "この拡張機能を共有して、他の人を助けましょう。",
    "title": "お問い合わせ"
  },
  "help": {
    "AquickOverviewOfNoam": "Noamの概要",
    "ChatPDF": "ChatPDF",
    "ChatPDF2": "ChatPDF",
    "FavoritesManagement": "お気に入り管理",
    "Fixedplugin": "固定プラグイン",
    "Focusing": "フォーカシング",
    "Free": "無料",
    "FreeTrialBenefits": "無料トライアル特典",
    "GoWithWikipedia": "ウィキペディアで試す",
    "GoWithYouTube": "YouTubeで試す",
    "HoverTranslation": "ホバー翻訳",
    "Howtouse": "使い方",
    "ImageTranslation": "画像翻訳",
    "InstantTranslation": "インスタント翻訳",
    "Membership": "メンバーシップ",
    "NoamHelp": "Noamは翻訳と要約をお手伝いします",
    "PDFparallelTranslation": "PDF並列翻訳",
    "ParallelTranslation": "並列翻訳",
    "SkipAllSteps": "すべての手順をスキップ",
    "Summary": "要約",
    "Translation": "翻訳",
    "TryItForFree": "無料で試してみる",
    "Video": "動画",
    "VideoSummary": "動画要約",
    "VideoTranslation": "動画翻訳",
    "WebSummary": "ウェブ要約",
    "WordSelectionTranslation": "単語選択翻訳",
    "benefits": "特典をご用意しました！",
    "dayFree": "3日間無料トライアル",
    "done": "完了",
    "next": "次へ",
    "pin": "簡単にアクセスできるようにNoamをピン留めする〜",
    "planIsNotExist": "プランが存在しません",
    "startWiki": "ウィキペディアから始めましょう〜",
    "startYoutube": "YouTubeから始めましょう〜",
    "try": "試してみる",
    "tryNow": "今すぐ試してみましょう〜"
  },
  "home": {
    "PDFTranslate": {
      "desc": "バイリンガル翻訳+ Chat PDF",
      "sub1": {
        "desc": "バイリンガルで読むことができるので、原文と翻訳文を読み比べながら読解力アップ！",
        "title": "バイリンガルリーディング"
      },
      "sub2": {
        "desc": "AIが分析・要約し、PDFの内容に基づいて質問に答えることができます",
        "title": "Chat PDF"
      },
      "sub3": {
        "desc": "AI搭載PDfで、より正確で効率的な翻訳を実現",
        "title": "正確性と効率性"
      },
      "title": "PDF翻訳"
    },
    "aiAssistant": {
      "desc": "ウェブ翻訳をよりスマートに、より便利に",
      "panel1": {
        "desc": "原文と翻訳文を相互参照できるバイリンガル翻訳により、より深く理解し、読解力を高めることができます。",
        "title": "バイリンガル翻訳"
      },
      "panel2": {
        "left": {
          "desc": "ウェブページを閲覧しているときに、翻訳が必要なコンテンツにマウスを合わせると、翻訳結果が表示されます。",
          "title": "ホバー翻訳"
        },
        "right": {
          "desc": "ウェブページを閲覧しているときに、翻訳したいテキストを直接選択して下線を引くと、翻訳結果が表示されます。",
          "title": "テキスト選択翻訳"
        }
      },
      "panel3": {
        "one": {
          "desc": "いつでも、どこでも、どんな場面でも、ウェブページや動画コンテンツを翻訳して要約します。よりスムーズな体験をお届けします！",
          "title": "ポイントアンドクリック"
        },
        "three": {
          "desc": "AI駆動の大規模モデルにより、翻訳の正確性と一貫性を確保し、読書体験を向上させます。",
          "title": "AI駆動"
        },
        "two": {
          "desc": "多言語をフルカバーし、バイリンガルでバリアフリーな読書体験を提供します。ワンキー翻訳で、比類のない体験を！",
          "title": "より包括的で便利に"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "あなたの効率アップのエキスパート、chatgptと連携してYouTube動画コンテンツを翻訳・要約し、あなたの読書時間を節約します！",
          "key": "Video",
          "title": "動画翻訳＆要約"
        },
        "tab2": {
          "desc": "ウェブ閲覧中に画像をクリックして翻訳",
          "key": "Image",
          "title": "画像翻訳"
        },
        "tab3": {
          "desc": "ウェブページから重要な情報をすばやく抽出して要約し、読書効率を向上させます",
          "key": "Web",
          "title": "ウェブ翻訳＆要約"
        },
        "tag1": "シンプル",
        "tag2": "効率的",
        "tag3": "分かりやすい"
      },
      "title": "AIアシスタント"
    },
    "getStart": "開始する",
    "getStartedForFree": "無料で開始",
    "howItWork": {
      "desc": "NoamはAIモデルを統合し、ユーザーにクロスランゲージウェブページ翻訳と要約、PDFと画像の翻訳、ビデオの翻訳と要約のためのインテリジェントなエクスペリエンスを提供します",
      "title": "仕組み"
    },
    "lastBlock": {
      "desc": "より良いAI翻訳と、よりスムーズな読書体験を"
    },
    "top": {
      "desc": "Noamは、要約されたウェブページ、ビデオ、PDFコンテンツの翻訳を支援する無料のAIブラウザ拡張機能です。\\n      Noamに参加して、魔法をお楽しみください！",
      "title1": "ワンクリック",
      "title2": "ワンクリックの魔法"
    },
    "userReviews": {
      "desc": "chromeウェブストアで五つ星評価",
      "title": "ユーザーレビュー"
    }
  },
  "installTips": {
    "btn": "今すぐ試す",
    "text": "「Noamブラウザプラグイン」をインストールして、さらに多くの機能をアンロックしましょう！ウェブ相互参照、\n    ウェブビデオ要約など！"
  },
  "locale": {
    "af": "アフリカーンス語",
    "am": "アムハラ語",
    "ar": "アラビア語",
    "as": "アッサム語",
    "auto": "自動検出",
    "az": "アゼルバイジャン語",
    "ba": "バシキール語",
    "bg": "ブルガリア語",
    "bn": "ベンガル語",
    "bo": "チベット語",
    "bs": "ボスニア語",
    "ca": "カタルーニャ語",
    "cs": "チェコ語",
    "cy": "ウェールズ語",
    "da": "デンマーク語",
    "de": "ドイツ語",
    "dsb": "低地ソルブ語",
    "dv": "ディベヒ語",
    "el": "ギリシャ語",
    "en": "英語",
    "es": "スペイン語",
    "et": "エストニア語",
    "eu": "バスク語",
    "fa": "ペルシャ語",
    "fi": "フィンランド語",
    "fil": "フィリピン語",
    "fj": "フィジー語",
    "fr": "フランス語",
    "frCA": "フランス語（カナダ）",
    "ga": "アイルランド語",
    "gl": "ガリシア語",
    "gom": "コンカニ語",
    "gu": "グジャラート語",
    "ha": "ハウサ語",
    "he": "ヘブライ語",
    "hi": "ヒンディー語",
    "hr": "クロアチア語",
    "hsb": "高地ソルブ語",
    "ht": "ハイチクレオール語",
    "hu": "ハンガリー語",
    "hy": "アルメニア語",
    "id": "インドネシア語",
    "ig": "イボ語",
    "ikt": "イヌイナクトゥン語",
    "is": "アイスランド語",
    "it": "イタリア語",
    "iu": "イヌクティトゥット語",
    "iuLatn": "イヌクティトゥット語（ラテン文字）",
    "ja": "日本語",
    "ka": "グルジア語",
    "kk": "カザフ語",
    "km": "クメール語",
    "kmr": "クルド語（北部）",
    "kn": "カンナダ語",
    "ko": "韓国語",
    "ku": "クルド語（中央）",
    "ky": "キルギス語",
    "ln": "リンガラ語",
    "lo": "ラオス語",
    "lt": "リトアニア語",
    "lug": "ガンダ語",
    "lv": "ラトビア語",
    "lzh": "中国語（文語）",
    "mai": "マイティリー語",
    "mg": "マラガシ語",
    "mi": "マオリ語",
    "mk": "マケドニア語",
    "ml": "マラヤーラム語",
    "mnCyrl": "モンゴル語（キリル文字）",
    "mnMong": "モンゴル語（伝統的な文字）",
    "mr": "マラーティー語",
    "ms": "マレー語",
    "mt": "マルタ語",
    "mww": "モン語",
    "my": "ミャンマー語（ビルマ語）",
    "nb": "ノルウェー語",
    "ne": "ネパール語",
    "nl": "オランダ語",
    "noSearchLang": "この言語はサポートされていません",
    "nso": "北部ソト語",
    "nya": "ニャンジャ語",
    "or": "オディア語",
    "otq": "ケレタロ・オトミ語",
    "pa": "パンジャブ語",
    "pl": "ポーランド語",
    "placeholder": "検索",
    "prs": "ダリー語",
    "ps": "パシュトー語",
    "pt": "ポルトガル語（ブラジル）",
    "ptPT": "ポルトガル語（ポルトガル）",
    "ro": "ルーマニア語",
    "ru": "ロシア語",
    "run": "ルンディ語",
    "rw": "ルワンダ語",
    "sd": "シンド語",
    "si": "シンハラ語",
    "sk": "スロバキア語",
    "sl": "スロベニア語",
    "sm": "サモア語",
    "sn": "ショナ語",
    "so": "ソマリ語",
    "sq": "アルバニア語",
    "srCyrl": "セルビア語（キリル文字）",
    "srLatn": "セルビア語（ラテン文字）",
    "st": "南部ソト語",
    "sv": "スウェーデン語",
    "sw": "スワヒリ語",
    "ta": "タミル語",
    "te": "テルグ語",
    "th": "タイ語",
    "ti": "ティグリニャ語",
    "tk": "トルクメン語",
    "tlhLatn": "クリンゴン語（ラテン文字）",
    "tlhPiqd": "クリンゴン語（pIqaD）",
    "tn": "ツワナ語",
    "to": "トンガ語",
    "tr": "トルコ語",
    "tt": "タタール語",
    "ty": "タヒチ語",
    "ug": "ウイグル語",
    "uk": "ウクライナ語",
    "ur": "ウルドゥ語",
    "uz": "ウズベク語（ラテン文字）",
    "vi": "ベトナム語",
    "xh": "コサ語",
    "yo": "ヨルバ語",
    "yua": "ユカテコ・マヤ語",
    "yue": "広東語（繁体字）",
    "zh": "中国語",
    "zhHans": "中国語（簡体字）",
    "zhHant": "中国語（繁体字）",
    "zu": "ズールー語"
  },
  "login": {
    "and": "および",
    "btn": "Googleアカウントでログイン",
    "btn1": "今すぐログイン",
    "desc": "ログインして、より多くの体験をアンロックしましょう",
    "policy": "プライバシーポリシー",
    "terms": "利用規約",
    "tips": "サインアップすることにより、私は",
    "title": "Noamへようこそ"
  },
  "pageFooter": {
    "follow": {
      "facebook": "フェイスブック",
      "title": "フォローする",
      "twitter": "ツイッター"
    },
    "legal": {
      "policy": "プライバシーポリシー",
      "terms": "利用規約",
      "title": "法的"
    },
    "product": {
      "image": "画像翻訳",
      "pdf": "PDF翻訳",
      "title": "製品",
      "video": "動画翻訳＆要約",
      "web": "ウェブ翻訳＆要約"
    },
    "resources": {
      "title": "リソース"
    }
  },
  "pageHeader": {
    "contact": "お問い合わせ",
    "help": "ヘルプセンター",
    "logout": "ログアウト",
    "logoutSucc": "ログアウトしました",
    "pricing": "料金プラン",
    "settings": "設定",
    "signUp": "ログイン"
  },
  "pdf": {
    "autoDetect": "自動検出",
    "cancelBtn": "キャンセル",
    "cancelUpload": "キャンセル",
    "chatPdf": {
      "Search": "検索",
      "cancel": "キャンセル",
      "chatTitle": "Noamへようこそ。PDFの要約ができました:",
      "clear": "クリア",
      "clearContent": "クリアすると、新しい会話が作成され、この会話の内容は保存されません。本当にクリアしますか？",
      "clearDialogTitle": "注意",
      "clearSucc": "クリアに成功しました",
      "copy": "コピー",
      "copySucc": "コピーに成功しました",
      "delete": "削除",
      "deleteCancel": "キャンセル",
      "deleteContent": "削除すると、このPDFは復元できません。削除しますか？",
      "deleteOk": "削除",
      "deleteSuccess": "削除に成功しました",
      "deleteTitle": "ヒント",
      "expand": "折りたたむ",
      "historyTitle": "履歴",
      "listError": "読書リストの読み込みに失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサポートまでご連絡ください。",
      "loading": "読み込み中...",
      "noDataDesc": "履歴はありません〜",
      "noMore": "これ以上ありません。",
      "placeholder": "PDFに関する質問を入力してください",
      "summaryTitle": "興味のある質問:"
    },
    "collectionEmpty": "コレクションにコンテンツがありません〜",
    "collectionTab": "メモ",
    "dataLoading": "データを読み込んでいます...",
    "dialogContent": "本当に削除してもよろしいですか？",
    "dialogTitle": "注意",
    "download": {
      "both": "バイリンガル",
      "btn": "今すぐ保存",
      "cancel": "ポップアップウィンドウを閉じると、ダウンロード中のPDFファイルがキャンセルされます。本当にキャンセルしますか？",
      "desc": "ローカルに保存: ダウンロードした翻訳は画像として保存されます。保存して印刷: ダウンロードした翻訳は元の形式で保存され、コピーがサポートされます。",
      "download": "ダウンロード",
      "downloadMsg1": "PDFの翻訳進捗状況:",
      "downloadMsg2": "予想所要時間",
      "downloadSucc": "PDFのダウンロード準備ができました。[今すぐ保存]をクリックしてください！",
      "downloadTip": "PDFをダウンロード中です。ダウンロード中にポップアップウィンドウを閉じないでください。ダウンロードに失敗する可能性があります。",
      "error": "ローカルへの保存に失敗しました。印刷して保存してください。",
      "fileName": "ファイル名:",
      "fileType": "ダウンロードファイルの種類:",
      "loading": "保存中...",
      "loading2": "読み込み中...",
      "minute": "分...",
      "msg1": "現在のドキュメントの一部の翻訳は原文よりも長いため、ダウンロード後にテキストが重なる可能性があります。ウェブページで読むことをお勧めします。",
      "msg2": "1、コンテンツは完全に翻訳されていません。翻訳が完了してからダウンロードしてください。",
      "msg3": "2、ドキュメントの一部の翻訳が長すぎます。テキストの重複を避けるためにウェブページで読むことができます。",
      "msg4": "現在翻訳中のタスクがあります。翻訳が完了してからもう一度お試しください。",
      "progress": "PDFを翻訳中です。進捗状況: {progress}、予想所要時間 {minutes} 分...",
      "save": "ローカルに保存",
      "savePrint": "保存して印刷",
      "success": "ダウンロードに成功しました",
      "trans": "翻訳のみ",
      "transContinue": "翻訳を続ける"
    },
    "downloadBtn": "ダウンロード",
    "dragTipModal": {
      "black": "スペース",
      "leftKey": "左ボタン",
      "ok": "わかりました",
      "press": "押す",
      "tips": "ドラッグして表示します。"
    },
    "entry": "PDFリスト",
    "fileErrMsg": "ファイルの読み込みに失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "fileMsg": "現在のファイルサイズ:",
    "fileMsg2": "100MB未満のファイルをアップロードしてください",
    "freeBannerBtn": "アップグレードしてさらに楽しむ",
    "freeBannerTips": "無料PDF翻訳の終了",
    "guide": {
      "placeholder": "PDFのリンクを入力してください",
      "start": "翻訳開始",
      "text1": "バイリンガルリーディング",
      "text2": "バイリンガルリーディングでは、原文と翻訳を比較しながら読むことができるため、読解効率が向上します。",
      "text3": "PDFチャット",
      "text4": "AIが分析と要約を行い、PDFの内容に基づいて質問に答えることができます。",
      "text5": "正確性と効率性",
      "text6": "AIを搭載したPDFで、より正確で効率的な翻訳を実現します。",
      "title1": "対訳",
      "title2": "PDFチャット",
      "toast": ".pdfで終わる有効なオンラインPDFリンクを入力してください。"
    },
    "hasTransTask": "翻訳中のタスクがあります。しばらくお待ちください。",
    "hoverTrans": "ホバーによる段落翻訳",
    "initial": "PDFの翻訳",
    "installText": "毎日のあらゆる翻訳ニーズに対応するワンストップショップ",
    "installText2": "最初にインストールしてください",
    "installTips": "この小さなプラグインをインストールすると、より多くのPDFファイルを翻訳できるようになり、スムーズなウェブページ翻訳も体験できます:",
    "kw": "ホッチキス",
    "link": "リンク",
    "listError": "PDFリストの読み込みに失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "memoTotalCount": "合計1件のコンテンツ",
    "new": "新しいファイル",
    "noId": "ファイルIDが存在しません",
    "notPdf": "PDFファイルのみ",
    "okBtn": "削除",
    "okBtn2": "認識する",
    "original": "原文",
    "parallel": "並列表示",
    "parseErrDialogContent": "現在のPDFリンクは直接翻訳に対応していません。元のリンクのPDFをダウンロードしてから翻訳してください。",
    "parseErrTips": "ファイルの解析エラー",
    "parseFileToast": "ファイルの解析に失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "pdfLoading": "解析中...",
    "searchPlaceholder": "検索",
    "selectTrans": "単語に下線を引いて翻訳",
    "shareBtn": "共有",
    "shareBtnText1": "共有リンクを生成",
    "shareBtnText2": "共有コンテンツを更新",
    "shareModal": {
      "cancel": "キャンセル",
      "copy": "コピー",
      "copySucc": "リンクのコピーに成功しました",
      "custom": "カスタマイズ:",
      "errMsg": "現在の共有範囲の内容は完全に翻訳されていません。共有する前に翻訳してください。",
      "gen": "共有",
      "modify": "変更",
      "notSupport": "この言語は現在サポートされていません",
      "page": "ページ",
      "pageNuErr": "正しいページ番号の値を入力してください。",
      "range": "範囲:",
      "remain": "残り",
      "remain1": "合計予想所要時間",
      "search": "検索",
      "settings": "共有設定:",
      "shareSetting": "共有コンテンツ設定",
      "shareText": "共有",
      "shareTips": "翻訳されたPDFのリンクを友達と共有して、比較しながら読んでみましょう:",
      "sourceLang": "原文:",
      "target": "翻訳先:",
      "text1": "共有設定",
      "text2": "共有リンクの生成",
      "totalPage": "合計1ページ",
      "trans": "翻訳",
      "translateTips": "ヒント: 翻訳に時間がかかる場合は、ポップアップウィンドウを閉じて、翻訳が完了してから共有することができます。",
      "translating": "PDFを翻訳中...",
      "update": "共有リンクを更新しました"
    },
    "shareMsg1": "共有リンクの生成に成功しました",
    "shareMsg2": "共有コンテンツの更新に成功しました",
    "shareMsg3": "共有リンクの生成に失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "shareMsg4": "共有コンテンツの更新に失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "shareTips": "PDFの翻訳が完了しました。友達と共有しましょう！",
    "start": "無料体験を始める",
    "thumbnail": "目次",
    "toast1": "PDFを読み込んでいます。しばらくしてからもう一度お試しください。",
    "toast2": "PDFのコンテンツが多すぎて、現在はダイアログをサポートしていません。",
    "toast3": "ダウンロード中です。ダウンロードが完了してからもう一度お試しください。",
    "toolbar": {
      "Adjust": "調整",
      "autoFit": "自動調整"
    },
    "trans": "翻訳",
    "transSucc": "翻訳が完了しました！",
    "unLoginToast": "この機能を使用するには、ログインしてください",
    "uploadBtn": "アップロード",
    "uploadErr": "ファイルのアップロードに失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "uploadMsg1": "ここにファイルをドラッグアンドドロップしてください",
    "uploadMsg2": "サポートされているファイルの種類: PDF  |  ファイルサイズ上限: 100MB",
    "uploadMsg3": "ここをクリックするか、ファイルをドラッグアンドドロップしてアップロードしてください",
    "uploading": "アップロード中",
    "uploadingMsg": "アップロードには時間がかかる場合がありますので、しばらくお待ちください。",
    "uploadingMsg1": "ファイルのアップロード中です。しばらくしてからもう一度お試しください。",
    "uploadingMsg2": "現在変換中のファイルがあります。しばらくしてからもう一度お試しください。",
    "uploadingMsg3": "変換進捗状況の問い合わせに失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "uploadingMsg4": "Noamはローカルファイルに直接アクセスできません。翻訳するPDFを手動でアップロードしてください。",
    "uploadingMsg5": "ファイルURLの取得に失敗しました。しばらくしてからもう一度お試しいただくか、カスタマーサービスまでご連絡ください。",
    "video": "ビデオ",
    "vipBannerBtn": "アップグレードしてさらに楽しむ",
    "vipBannerTips": "今月のPDF翻訳の上限に達しました。",
    "webTrans": "完全な相互参照"
  },
  "pricing": {
    "FAQ": "よくある質問",
    "FQAObj": {
      "a1": "Noamは無料版があり、直接ご利用いただけます。",
      "a2": "無料版の他に、Pro、Pro+、Enterpriseの3つの会員プランがあります。お支払い方法は、月額継続と年間継続の2種類からお選びいただけます。年間継続の方がお得です。",
      "a3": "機能に違いはありません。年間継続の方が、月額継続よりも総合的に費用が安くなります。月額は毎月請求され、年間は1年に1回請求されます。",
      "a4": "バックエンドの設定ページで、購読の解約を選択してください。",
      "q1": "無料で使えますか？",
      "q2": "会員プランや料金オプションはありますか？",
      "q3": "月額継続と年間継続の違いは何ですか？",
      "q4": "自動更新プランの解約方法は？"
    },
    "around": "約",
    "billedMonthly": "月間請求",
    "billedYearly": "年間請求",
    "day": "日",
    "desc": "最適なプランをお選びください",
    "detailedCompare": "詳細比較",
    "discount": "20%オフ",
    "enterprise": {
      "benefit1": "優先的なカスタマーサポート",
      "benefit2": "複数ユーザー",
      "benefit3": "カスタマイズソリューション",
      "benefit5": "専任アカウントマネージャー",
      "btn": "相談する",
      "recommendText": "カスタムプラン（≥3人）",
      "talkToUs": "お問い合わせ"
    },
    "free": {
      "benefit1": "1日${FREE_BENEFITS.realTimeCount}回まで瞬時に翻訳",
      "benefit2": "ウェブページ翻訳と要約",
      "benefit3": "画像翻訳（${FREE_BENEFITS.imageCount}枚まで）",
      "benefit4": "動画翻訳と要約",
      "benefit5": "PDF翻訳（${FREE_BENEFITS.pdfUploadCount}ファイルまで）",
      "benefit6": "${FREE_BENEFITS.tokenCount}トークン",
      "btn": "使ってみる",
      "recommendText": "無料トライアル"
    },
    "imageTranslation": "画像翻訳",
    "month": "ヶ月",
    "monthly": "月間",
    "pageTitle": "料金プラン",
    "pdf": {
      "chat": "PDFの質問回数",
      "maxPage": "PDFの最大ページ数",
      "translation": "PDF翻訳"
    },
    "preMonth": "月額",
    "pro": {
      "benefit1": "優先的なカスタマーサポート",
      "benefit2": "${PRO_BENEFITS.tokenCount}トークン/月",
      "benefit3": "ウェブページ翻訳と要約",
      "benefit4": "画像翻訳（${PRO_BENEFITS.imageCount}枚まで）",
      "benefit5": "動画翻訳と要約",
      "benefit6": "PDF翻訳（${PRO_BENEFITS.pdfUploadCount}ファイルまで）",
      "btn": "始める",
      "recommendText": "一番人気"
    },
    "proPlus": {
      "benefit1": "優先的なカスタマーサポート",
      "benefit2": "${PRO_V_BENEFITS.tokenCount}トークン/月",
      "benefit3": "ウェブページ翻訳と要約",
      "benefit4": "画像翻訳（${PRO_V_BENEFITS.imageCount}枚まで）",
      "benefit5": "動画翻訳と要約",
      "benefit6": "PDF翻訳（${PRO_V_BENEFITS.pdfUploadCount}ファイルまで）",
      "recommendText": "最もおすすめ"
    },
    "video": {
      "summary": "動画要約",
      "translation": "動画翻訳"
    },
    "web": {
      "contrastiveTranslation": "対訳表示",
      "dictionaryTranslation": "選択範囲翻訳",
      "hoverTranslation": "ホバー翻訳",
      "instantTranslation": "リアルタイム翻訳",
      "summary": "ウェブページ要約"
    },
    "yearly": "年間"
  },
  "privacy": {
    "block1": "私たちNoam（以下「Noam」、「私たち」）は、お客様のプライバシーを尊重し、お客様からまたは、お客様に関して取得した情報の保護に努めています。本プライバシーポリシーは、お客様が当社のウェブサイト、アプリケーション、およびサービス（以下総称して「サービス」といいます）をご利用になる際に、当社が個人情報を収集または利用する方法について説明するものです。本プライバシーポリシーは、APIなど、当社のビジネス製品のお客様に代わって当社が処理するコンテンツには適用されません。このデータの使用は、これらの製品へのアクセスと使用を規定するお客様との契約の対象となります。",
    "block10": "当社は、オンラインおよびオフラインの個人情報を、紛失、誤用、不正アクセス、開示、改ざん、または敵対行為から保護するために、商業的に合理的な技術的、管理的、および組織的な対策を実施しています。ただし、インターネットや電子メールによる送信は、完全なセキュリティや正確性を保証するものではありません。特に、当社に送信された電子メールまたは当社から送信された電子メールは、安全ではない可能性があります。そのため、サービスまたは電子メールを通じて当社にどのような情報を送信するかを決定する際には、特に注意する必要があります。さらに、当社は、サービスまたは第三者ウェブサイトのセキュリティ設定またはセキュリティ対策におけるプライバシー設定またはセキュリティ対策の妨害については、一切責任を負いません。当社は、お客様にサービスを提供するため、または紛争の解決、セキュリティと安全上の理由、または当社の法的義務の遵守などのその他の正当な事業目的のために必要な期間、お客様の個人情報を保持します。当社が個人情報を保持する期間は、情報の量、性質、機密性、不正使用または開示の可能性のあるリスク、当社が情報を処理する目的、その他の法的拘束力のある考慮事項など、多くの要因によって異なります。",
    "block11": "当社は、本プライバシーポリシーを随時更新する場合があります。更新する場合は、適用される法律で別途義務付けられている場合を除き、このページに更新版を掲載します。",
    "block12": "本プライバシーポリシーについてご不明な点やご質問がございましたら、カスタマーサービスまでお問い合わせください。メールアドレス：",
    "block2": "当社は、お客様に関する以下の個人情報（以下「個人情報」といいます）を収集します。",
    "block3": "お客様から提供された情報：お客様が当社のサービスを利用するためにアカウントを作成したり、当社と連絡を取ったりする場合、当社は以下のような個人情報を収集します。",
    "block4": "お客様のサービス利用に伴い自動的に受信する個人情報：お客様がサービスにアクセス、利用、または操作する際、当社は、お客様のアクセス、利用、または操作に関する以下の情報を受信します（以下「技術情報」といいます）。",
    "block5": "当社は、以下の目的で個人情報を利用する場合があります。",
    "block6": "要約または匿名化された情報：当社は、個人情報が識別目的で再利用されるのを防ぐために、個人情報を集計または匿名化し、そのような情報をサービスの有効性の分析、サービスの機能の改善と追加、調査の実施、および同様の目的のために利用する場合があります。さらに、当社は、当社のサービスユーザーの一般的な行動や特性を分析し、集計された情報を第三者と共有したり、そのような集計された情報を公開したり、一般に利用できるようにしたりする場合があります。当社は、サービスを通じて、Cookieを通じて、および本プライバシーポリシーに記載されているその他の方法を通じて、集計された情報を収集する場合があります。当社は、匿名化または非特定化された形式で、非特定化された情報を維持および使用し、法律で義務付けられている場合を除き、そのような情報の再特定化を試みません。",
    "block7": "場合によっては、法律で義務付けられている場合を除き、お客様への事前の通知なしに、お客様の個人情報を第三者に提供する場合があります。",
    "block8": "地理的な場所によっては、欧州経済地域、英国、および世界中の個人が、自分の個人情報に関して特定の法的権利を有する場合があります。例えば、お客様は、以下のような権利を有する場合があります。",
    "block9": "本サービスには、ソーシャルメディアサービスを含む、Noamが運営または管理していない他のウェブサイトへのリンクが含まれている場合があります（以下「第三者ウェブサイト」といいます）。お客様が第三者ウェブサイトと共有する情報は、本プライバシーポリシーではなく、第三者ウェブサイトの特定のプライバシーポリシーおよび利用規約に準拠します。当社は、これらのリンクを便宜のために提供するものであり、これらのウェブサイトを推奨またはレビューするものではありません。プライバシーポリシーや利用規約については、第三者ウェブサイトに直接お問い合わせください。",
    "subBlock1": "アカウント情報：お客様が当社のプラットフォームでアカウントを作成する際、当社は、お客様の氏名、連絡先情報、アカウント認証情報、支払いカード情報、取引履歴など、お客様のアカウントに関する情報を収集します（以下「アカウント情報」といいます）。",
    "subBlock10": "サービスの提供、管理、維持、および/または分析。",
    "subBlock11": "お客様とのコミュニケーション。",
    "subBlock12": "新しいプロジェクトやサービスの開発。",
    "subBlock13": "詐欺、犯罪行為、または当社のサービスの不正利用を防止し、当社のITシステム、アーキテクチャ、およびネットワークのセキュリティを保護すること。",
    "subBlock14": "事業譲渡の実施。",
    "subBlock15": "法的義務および法的手続きの遵守、当社、当社の子会社、お客様、またはその他の第三者の権利、プライバシー、セキュリティ、または財産の保護。",
    "subBlock16": "サプライヤーおよびサービスプロバイダー：事業運営のニーズを満たし、特定のサービスおよび機能を実行するために、当社は、ホスティングサービスプロバイダー、クラウドサービスプロバイダー、その他の情報技術サービスプロバイダー、電子メールコミュニケーションソフトウェア、ウェブ解析サービスプロバイダーなどのサプライヤーおよびサービスプロバイダーに個人情報を提供する場合があります。これらのパートナーは、当社のために業務を遂行する際に、当社の指示に従ってのみ、個人情報にアクセス、処理、または保管します。",
    "subBlock17": "事業譲渡：当社が戦略的取引、再編、破産、破産による事業譲渡、または別のプロバイダーへのサービスの移行（以下総称して「取引」といいます）を行う場合、お客様の個人情報およびその他の情報は、取引相手方およびデューデリジェンス中に取引を支援するその他の当事者と共有され、取引の一環として、その他の資産とともに、後継会社または関連会社に譲渡される場合があります。",
    "subBlock18": "法的要件：当社は、お客様の個人情報を、お客様と当社のサービスとのやり取りの情報を含め、政府当局、業界団体、またはその他の第三者と共有する場合があります。（1）法律で義務付けられている場合、または当社が法的義務を遵守するために必要であると判断した場合、（2）当社の権利または財産を保護および防御するため、（3）当社が単独で当社の規約、ポリシー、または法律に違反していると判断した場合、（4）詐欺またはその他の違法行為を検出または防止するため、（5）当社の製品、従業員、またはユーザー、または公共の安全、セキュリティ、完全性を保護するため、または（vi）法的責任を回避するため。",
    "subBlock19": "お客様の個人情報およびその処理方法に関する情報へのアクセス。",
    "subBlock2": "ユーザーコンテンツ：お客様が当社のサービスを利用する際、当社は、お客様の入力、ファイルのアップロード、または当社に提供されたフィードバックから個人情報を収集します（以下「コンテンツ」といいます）。",
    "subBlock20": "当社の記録からお客様の個人情報を削除すること。",
    "subBlock21": "お客様の個人情報を修正または更新すること。",
    "subBlock22": "お客様の個人情報を第三者に転送すること（データポータビリティ）。",
    "subBlock23": "当社によるお客様の個人情報の処理方法を制限すること。",
    "subBlock24": "同意の撤回 - 当社が処理の法的根拠として同意に依拠している場合、お客様はいつでも同意を撤回することができます。",
    "subBlock25": "当社によるお客様の個人情報の処理方法に反対すること。",
    "subBlock26": "当社のサービスの改善および調査の実施。",
    "subBlock3": "コミュニケーション情報：お客様が当社にメッセージを送信された場合、当社は、お客様の氏名、連絡先情報、およびお客様が送信されたメッセージの内容を収集します（以下「コミュニケーション情報」といいます）。",
    "subBlock4": "ソーシャルメディア情報：当社は、Instagram、Facebook、Medium、Twitter、YouTube、LinkedInなどのソーシャルメディアのウェブサイトにページを持っています。お客様が当社のソーシャルメディアページを操作する際、当社は、お客様が当社に提供することを選択した個人情報（お客様の連絡先など）を収集します（以下「ソーシャル情報」といいます）。さらに、当社のソーシャルメディアページをホストしている企業は、当社のソーシャルメディア活動に関する集計された情報と分析を当社に提供する場合があります。",
    "subBlock5": "ログデータ：お客様が当社のサービスを利用する際に、お客様のブラウザから自動的に送信される情報。ログデータには、お客様のインターネットプロトコル（IP）アドレス、ブラウザの種類と設定、リクエストの日時、およびお客様が当社のウェブサイトをどのように操作したかが含まれます。",
    "subBlock6": "利用状況データ：当社は、お客様が閲覧または操作するコンテンツの種類、お客様が使用する機能、お客様が実行した操作、お客様のタイムゾーン、国、アクセス日時、ユーザーエージェントとバージョン、コンピュータまたはモバイルデバイスの種類、お客様のコンピュータ接続など、お客様によるサービスの利用に関する情報を自動的に収集する場合があります。",
    "subBlock7": "デバイス情報：デバイス名、オペレーティングシステム、デバイス識別子、およびお客様が使用しているブラウザが含まれます。収集される情報は、お客様が使用しているデバイスの種類とその設定によって異なる場合があります。",
    "subBlock8": "Cookie：当社は、サービスの運営と管理、およびお客様のエクスペリエンスの向上を目的として、Cookieを使用しています。「Cookie」とは、お客様が訪問したウェブサイトからお客様のブラウザに送信される情報のことです。お客様は、すべてのCookieを受け入れるか、すべてのCookieを拒否するか、Cookieが提供されたときに通知を受け、その都度受け入れるかどうかを決定するようにブラウザを設定することができます。ただし、Cookieを拒否すると、ウェブサイトの特定の領域や機能を使用できなくなったり、表示や機能に悪影響が及んだりする可能性があります。Cookieの詳細については、All About Cookiesをご覧ください。",
    "subBlock9": "アナリティクス：当社は、ユーザーによるサービスの利用状況を分析し、サービス利用時のエクスペリエンスを向上させるために、Cookieを使用するさまざまなオンラインアナリティクス製品を使用する場合があります。",
    "subTitle1": "当社が収集する個人情報",
    "subTitle2": "個人情報の利用目的",
    "subTitle3": "個人情報の開示",
    "subTitle4": "お客様の権利",
    "subTitle5": "他のウェブサイトへのリンク",
    "subTitle6": "情報のセキュリティと保持",
    "subTitle7": "プライバシーポリシーの変更",
    "subTitle8": "お問い合わせ",
    "title": "プライバシーポリシー",
    "updateTime": "更新日：2024年3月15日"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "すべて許可",
    "text": "お客様のエクスペリエンスを向上させるために、コンテンツとサービスをパーソナライズするためにクッキーを使用したいと思います。",
    "title": "このウェブサイトはクッキーを使用しています"
  },
  "slogen": "最も簡単なAIアシスタント",
  "stripe": {
    "contact": "ご質問は contact@noam.tools までご連絡ください。",
    "fail": {
      "btn": "再試行",
      "text1": "申し訳ありませんが、支払リクエストを処理できません。",
      "text2": "インターネット接続を確認して、もう一度お試しください。",
      "title": "支払いに失敗しました"
    },
    "succ": {
      "btn": "さあ、始めましょう",
      "text1": "Noamへようこそ、",
      "text2": "一緒に始めましょう！",
      "title": "おめでとうございます"
    }
  },
  "terms": {
    "block1": "Noamをご利用いただきありがとうございます！",
    "block10": "(a) 料金と請求。 お客様は、適用される料金ページに記載されている料金および条件に従って、または書面による当社との合意に従って、すべての料金（以下「料金」といいます）を支払うものとします。当社は、請求書を発行した場合または支払いを受け取った場合でも、料金の誤りまたは間違いを修正する権利を留保します。お客様は、有効で承認された支払い方法を含む、完全かつ正確な請求情報を提供するものとします。当社は、合意に基づき、お客様の支払い方法に対して定期的に請求を行います。ただし、請求日は合理的な理由により変更される場合があります。お客様は、Noam、その関連会社、およびサードパーティの決済処理業者に対し、お客様の支払い方法に請求することを承認するものとします。お支払いが滞った場合、当社は、お客様に書面で通知し、お支払いが完了するまで本サービスへのアクセスを一時停止する場合があります。本契約に別途定めがある場合を除き、支払いは返金不可とします。",
    "block11": "(b) 税金。 別途記載がない限り、料金には、連邦税、州税、地方税、外国税、関税、および同様の賦課金（以下「税金」といいます）は含まれていません。お客様は、お客様の購入に関連するすべての税金（当社の純利益に基づく税金を除く）について責任を負うものとし、当社は、お客様に請求書を発行します。お客様は、かかる税金を速やかに支払い、法的効力のあるプライバシーに関する声明を当社に提供し、かかるデータを処理するために必要な同意を得ることに同意するものとします。また、お客様は、適用法に従ってかかるデータを処理することを当社に保証するものとします。",
    "block12": "(c) 料金の変更。 当社は、お客様のアカウントおよび/または当社のウェブサイトを通じてお客様に通知することにより、料金を変更することができます。料金の値上げは、掲載後すぐに有効となります。料金の変更は、変更の発効日以降にお客様のアカウントに対して行われた料金に適用されます。",
    "block13": "(d) 紛争と延滞料金。 料金または税金について異議を申し立てる場合は、請求書の日付から30日以内にcontact@noam.toolsまでご連絡ください。",
    "block14": "(e) 無料利用。 お客様は、無料クレジットを取得するために複数のアカウントを作成することはできません。お客様が誠実に無料クレジットを使用していないと当社が判断した場合、当社は、お客様に標準料金を請求するか、本サービスへのアクセスを停止することがあります。",
    "block15": "(a) 機密保持。 お客様は、Noamの機密情報にアクセスできる場合があります。お客様は、本サービスを利用する目的で、本規約で許可されている場合に限り、機密情報を使用することができます。お客様は、機密情報を第三者に開示してはならず、少なくとも合理的な注意を払って、お客様自身の同様の機密情報を保護するのと同じ程度の保護をもって機密情報を保護するものとします。機密情報とは、Noamが機密であると指定した、または状況に応じて機密として扱うべき合理的な根拠がある非公開情報であり、ソフトウェア、仕様、その他の非公開の事業情報を含みます。機密情報には、以下の情報は含まれません。(1) お客様の過失なく一般に公開されるようになった情報。(2) 本規約に基づいて受領する前に、お客様が機密保持義務を負うことなく所有していた情報。(3) 機密保持義務を負うことなく、第三者から合法的に開示された情報。(4) 機密情報を使用せずに、お客様が独自に開発した情報。お客様は、法律、裁判所、またはその他の政府機関の命令により要求された場合、機密情報を開示することができます。ただし、お客様は、合理的な方法で事前に書面でNoamに通知し、可能な限り、開示の範囲を制限するための合理的な努力（開示要求に反対するために当社を支援することを含む）をする必要があります。",
    "block16": "(b) セキュリティ。 お客様は、本サービスへのアクセスおよび利用を保護するために、合理的かつ適切な措置を講じる必要があります。お客様は、本サービスの利用に関連して脆弱性または違反を発見した場合、速やかにNoamに連絡し、脆弱性または違反の詳細を報告する必要があります。",
    "block17": "((c) 個人データの処理。 お客様が本サービスを利用して個人データを処理する場合、お客様は、法的効力のあるプライバシーに関する声明を提供し、かかるデータを処理するために必要な同意を得る必要があります。また、お客様は、適用法に従ってかかるデータを処理することを当社に保証するものとします。",
    "block18": "(a) 終了、一時停止。 本規約は、お客様が本サービスを初めて利用した時点から有効となり、終了するまで有効となります。お客様は、本サービスおよびコンテンツの利用を中止することにより、いつでも、いかなる理由でも、本規約を終了することができます。当社は、いかなる理由でも、事前に通知することにより、本規約を終了することができます。当社は、お客様が第2条（使用要件）、第5条（機密保持、セキュリティ、データ保護）、第8条（紛争の解決）、または第9条（一般条項）に重大な違反をした場合、または当社の支配の及ばない第三者の技術プロバイダーとの関係が変更された場合、または法律または政府の要求に従うために、お客様に通知することにより、本規約を直ちに終了することができます。当社は、お客様が本規約を遵守しなかった場合、お客様による利用が当社または第三者のセキュリティ上のリスクとなる場合、またはお客様による利用が不正であるか、当社または第三者を法的責任にさらす可能性があると当社が疑う場合、本サービスへのアクセスを一時停止することができます。",
    "block19": "(b) 終了の効果。 終了と同時に、お客様は、本サービスの利用を中止し、当社の指示に従って、機密情報を速やかに返却または廃棄するものとします。第3条および第5条から第9条を含むがこれらに限定されない、本規約の規定のうち、その性質上、終了または満了後も有効であるべきものは、終了後も有効となります。",
    "block2": "本利用規約は、当社のアプリケーションプログラミングインターフェース、ソフトウェア、ツール、開発者サービス、データ、ドキュメント、ウェブサイト（以下「本サービス」といいます）を含む、お客様によるNoamのサービスの利用に適用されます。お客様は、本サービスを利用することにより、本規約を遵守することに同意するものとします。当社のプライバシーポリシーでは、当社が個人情報をどのように収集し、使用するかについて説明しています。",
    "block20": "(a) 補償。 お客様は、お客様による本サービスの利用（お客様のコンテンツ、本サービスに関連する製品またはサービスの利用、および本規約または適用法の違反を含む）に起因または関連して発生した、あらゆる請求、損失、および費用（弁護士費用を含む）から、当社、当社の関連会社、および当社の従業員を防御し、補償し、免責するものとします。",
    "block21": "(b) 免責事項。 本サービスは、「現状のまま」提供されます。法律で認められる範囲において、当社および当社の関連会社およびライセンサーは、本サービスに関して、いかなる種類の表明または保証も行わず、商品性、特定の目的への適合性、満足のいく品質、非侵害、および平穏享有の保証、ならびに取引の過程または取引の慣習から生じる保証を含むがこれらに限定されない、いかなる保証も行いません。当社は、本サービスが中断されないこと、正確であること、またはエラーがないこと、またはコンテンツが安全であること、紛失しないこと、または改ざんされないことを保証しません。",
    "block22": "(c) 責任の制限。 当社、当社の関連会社、または当社のライセンサーは、いかなる間接的、偶発的、特別的、結果的、または懲罰的な損害（逸失利益、評判の失墜、使用機会の喪失、データの喪失、またはその他の無形の損失を含む）についても、たとえ当社がかかる損害の可能性について知らされていたとしても、責任を負いません。本規約に基づき、当社の責任総額は、請求の対象となる日からさかのぼって12か月間に本サービスに対して支払われた料金または100米ドルのいずれか大きい方を上限とします。本条の制限は、適用法で認められる最大限の範囲で適用されます。",
    "block23": "本規約およびお客様による本サービスの利用は、米国法の抵触法に関する規則を除き、米国法に準拠します。お客様による本アプリケーションの利用は、その他の現地、州、国、または国際的な法律の対象となる場合もあります。本規約に関連する法的措置または訴訟は、すべて米国カリフォルニア州北部地区の連邦裁判所または州裁判所にのみ提起されるものとし、お客様は、かかる裁判所の対人管轄権に服することに同意するものとします。",
    "block24": "(a) 当事者の関係。 Noamとお客様は、独立した契約者であり、いずれの当事者も、相手方当事者の事前の書面による同意なしに、相手方当事者を代理したり、拘束したり、相手方当事者の義務を負ったりする権限を有しません。",
    "block25": "(b) ブランドの使用。 お客様は、当社の事前の書面による同意なしに、Noamまたはその関連会社の名称、ロゴ、または商標を使用することはできません。",
    "block26": "本規約のいずれかの条項が無効、違法、または執行不能と判断された場合でも、残りの条項は、引き続き完全な効力を有するものとします。",
    "block3": "お客様が18歳未満の場合、本サービスを利用するには、親権者または後見人の許可を得る必要があります。お客様が、他の人または事業体の代理として本サービスを利用する場合、お客様は、その代理として本規約に同意する権限を有している必要があります。アカウント登録の際には、正確かつ完全な情報を入力する必要があります。お客様は、お客様のアクセス資格情報またはアカウントを、お客様の組織外の誰にも提供してはならず、お客様の資格情報を使用して行われたすべての活動について責任を負うものとします。",
    "block4": "(a) 本サービスの利用。 お客様は、本規約に基づいて本サービスにアクセスすることができます。また、当社は、お客様に本サービスを利用するための非独占的な権利を付与します。本サービスを利用する際には、お客様は、本規約および適用されるすべての法律を遵守するものとします。当社およびその関連会社は、本サービスに関するすべての権利、権原、および利益を所有します。",
    "block5": "(b) フィードバック。 当社は、フィードバック、コメント、アイデア、提案、および改善点を歓迎します。お客様がそのようなコンテンツを提供する場合、当社は、お客様への制限や報酬なしに、それを使用することができます。",
    "block6": "(c) 制限事項。 お客様は、以下を行ってはなりません。(1) 他者の権利を侵害、不正流用、または侵害するような方法で本サービスを利用すること。(2) 本サービスのモデル、アルゴリズム、およびシステムのソースコードまたは基礎となるコンポーネントをリバースエンジニアリング、逆コンパイル、逆アセンブル、翻訳、またはその他の方法で発見しようとすること（ただし、そのような制限が適用法で禁止されている場合を除く）。(3) 本サービスの出力を利用して、Noamと競合するモデルを開発すること。(4) APIを通じて許可されている場合を除き、スクレイピング、ウェブハーベスティング、またはウェブデータ抽出を含む、自動化された方法またはプログラミング方法を使用して、本サービスからデータまたは出力を抽出すること。(5) 本サービスの出力が人工的に生成されたものではない場合に、それを人工的に生成されたものとして表現すること、またはその他の方法で当社の利用規約に違反すること。(6) 当社の事前の同意なしに、APIキーを購入、販売、または譲渡すること。(7) 13歳未満の子供または適用されるデジタル同意年齢の子供の個人情報を当社に提供すること。お客様は、当社のドキュメントに記載されているレート制限およびその他の要件を遵守するものとします。",
    "block7": "(d) サードパーティサービス。 本サービスに関連するサードパーティのソフトウェア、サービス、またはその他の製品は、それぞれの規約に準拠しており、当社は、サードパーティ製品について責任を負いません。",
    "block8": "(a) お客様のコンテンツ。 お客様は、本サービスに入力（以下「入力」といいます）を提供し、入力に基づいて生成および返却された出力（以下「出力」といいます）を受け取ることができます（以下、総称して「コンテンツ」といいます）。当事者間において、および適用法で認められる範囲において、お客様は、すべての入力の権利を所有するものとします。Noamは、本サービスを提供および維持するため、適用される法律を遵守するため、および当社のポリシーを実施するために、コンテンツを使用することがあります。お客様は、コンテンツについて責任を負うものとし、これには、コンテンツが適用される法律または本規約に違反しないことを保証することが含まれます。",
    "block9": "(b) 正確性。 人工知能と機械学習は、急速に進化している研究分野です。当社は、本サービスをより正確で、信頼性が高く、安全で、有益なものにするために、継続的に改善に努めています。機械学習の確率的性質を考えると、本サービスの使用により、特定のケースにおいて、実際の人物、場所、または事実に正確に反映されない誤った出力が生成される可能性があります。お客様は、出力の手動による確認を含め、お客様のユースケースに基づいて、出力の正確性を適切に評価する必要があります。",
    "subTitle1": "登録とアクセス",
    "subTitle10": "分離可能性",
    "subTitle2": "使用要件",
    "subTitle3": "コンテンツ",
    "subTitle4": "料金と支払い",
    "subTitle5": " 機密保持、セキュリティ、データ保護",
    "subTitle6": "期間と終了",
    "subTitle7": "補償、免責事項、責任の制限",
    "subTitle8": "準拠法と裁判管轄",
    "subTitle9": "一般条項",
    "title": "利用規約",
    "updateTime": "更新日: 2024年3月15日"
  },
  "trialModal": {
    "btn": "今すぐ始める（¥0.99のみ）",
    "chat": "ChatPDF",
    "desc1": "トライアル終了後は、年間180ドルの料金が発生します。",
    "desc2": "いつでもキャンセルできます",
    "focus": "フォーカス",
    "hover": "ホバー翻訳",
    "image": "画像翻訳",
    "installBtn": "今すぐインストールしてギフトを受け取る",
    "memo": "お気に入り管理",
    "pdf": "PDFパラレル翻訳",
    "realtime": "インスタント翻訳",
    "select": "単語選択翻訳",
    "title": "ウェルカムギフト",
    "trialText": "7日間トライアル",
    "video": "動画翻訳",
    "videoSummary": "動画要約",
    "web": "パラレル翻訳",
    "webSummary": "ウェブ要約"
  },
  "upgradeModal": {
    "btn": "今すぐアップグレード",
    "chat": {
      "free": "Chatpdf の上限に達しました",
      "pro": "今月の Chatpdf の上限に達しました"
    },
    "contactStr": "次回のリセットをお待ちいただくか、contact@noam.tools までご連絡ください。",
    "limitAlertStr": "無料プランの quota が上限に達しました。引き続きご利用いただくには、プランのアップグレードをお勧めします。",
    "more": "その他のプラン",
    "pageLimit": {
      "free": "PDFページ数の上限に達しました"
    },
    "title": "リマインダー",
    "upgradeAlertStr": "無料プランの quota が上限に達しました。引き続きご利用いただくには、プランのアップグレードをお勧めします。",
    "upload": {
      "free": "PDF翻訳の上限に達しました",
      "pro": "今月のPDF翻訳の上限に達しました"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "今すぐ参加",
      "label": "新規ユーザー特典:",
      "remain": "残り30個",
      "text": "残りわずか100名様限定！今すぐゲットしよう！"
    }
  }
}

export default TRANSLATION
