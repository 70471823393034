const TRANSLATION = {
  "buyModal": {
    "afterFree": "Po skončení zkušební verze",
    "btn": "Vyzkoušet zdarma",
    "freeTrial": "3denní bezplatná zkušební verze",
    "monthLabel": "měsíc",
    "selectLabel": "Vyberte si tarif",
    "title": "Předplatné",
    "yearLabel": "rok"
  },
  "contact": {
    "block1": "Pro případné dotazy, návrhy nebo požadavky na úpravy nás prosím kontaktujte na adrese contact@noam.tools a my vám obratem odpovíme.",
    "block2": "Podpořte nás hodnocením v obchodě Google Apps Extension: ★★★★★",
    "block3": "Pomozte ostatním sdílením tohoto rozšíření:",
    "title": "Kontaktujte nás"
  },
  "help": {
    "AquickOverviewOfNoam": "Stručný přehled o Noamu",
    "ChatPDF": "Chat PDF",
    "ChatPDF2": "ChatPDF",
    "FavoritesManagement": "Správa oblíbených",
    "Fixedplugin": "Pevný plugin",
    "Focusing": "Zaostřování",
    "Free": "Zdarma",
    "FreeTrialBenefits": "Výhody bezplatné zkušební verze",
    "GoWithWikipedia": "Pokračovat s Wikipedií",
    "GoWithYouTube": "Pokračovat s YouTube",
    "HoverTranslation": "Překlad při najetí myší",
    "Howtouse": "Jak používat",
    "ImageTranslation": "Překlad obrázků",
    "InstantTranslation": "Okamžitý překlad",
    "Membership": "Členství",
    "NoamHelp": "Noam vám pomůže s překladem a shrnutím",
    "PDFparallelTranslation": "Paralelní překlad PDF",
    "ParallelTranslation": "Paralelní překlad",
    "SkipAllSteps": "Přeskočit všechny kroky",
    "Summary": "Shrnutí",
    "Translation": "Překlad",
    "TryItForFree": "Vyzkoušejte to zdarma",
    "Video": "Video",
    "VideoSummary": "Shrnutí videa",
    "VideoTranslation": "Překlad videa",
    "WebSummary": "Shrnutí webu",
    "WordSelectionTranslation": "Překlad vybraných slov",
    "benefits": "Připravili jsme pro vás výhody!",
    "dayFree": "3denní bezplatná zkušební verze",
    "done": "Hotovo",
    "next": "Další",
    "pin": "Připnout Noam pro snadnější přístup～～",
    "planIsNotExist": "Tarif neexistuje",
    "startWiki": "Začněme s Wikipedií~",
    "startYoutube": "Začněme s YouTube~",
    "try": "Vyzkoušejte to",
    "tryNow": "Vyzkoušejte to hned teď~"
  },
  "home": {
    "PDFTranslate": {
      "desc": "Dvojjazyčný překlad + Chat PDF",
      "sub1": {
        "desc": "Dvojjazyčné čtení vám umožňuje porovnat originální text s překladem a zlepšit tak efektivitu čtení",
        "title": "Dvojjazyčné čtení"
      },
      "sub2": {
        "desc": "Umělá inteligence analyzuje a shrnuje obsah PDF a dokáže na základě něj odpovídat na vaše otázky",
        "title": "Chat PDF"
      },
      "sub3": {
        "desc": "PDF s umělou inteligencí pro přesnější a efektivnější překlady",
        "title": "Přesnost a efektivita"
      },
      "title": "Překlad PDF"
    },
    "aiAssistant": {
      "desc": "Webový překlad chytřeji a pohodlněji",
      "panel1": {
        "desc": "Dvojjazyčné překlady s křížovými odkazy vám pomohou porovnat originální text s překladem, abyste lépe porozuměli a zlepšili efektivitu čtení.",
        "title": "Dvojjazyčný překlad"
      },
      "panel2": {
        "left": {
          "desc": "Při procházení webové stránky najeďte myší na obsah, který chcete přeložit, a zobrazí se vám překlad.",
          "title": "Překlad pod kurzorem"
        },
        "right": {
          "desc": "Při procházení webové stránky vyberte text, který chcete přeložit, a zobrazí se vám překlad.",
          "title": "Překlad vybraného textu"
        }
      },
      "panel3": {
        "one": {
          "desc": "Překládejte a shrnujte webové stránky a video obsah kdykoli, kdekoli a v jakékoli situaci; pro plynulejší zážitek!",
          "title": "Ukaž a klikni"
        },
        "three": {
          "desc": "Velký model s umělou inteligencí zajišťuje přesnost a konzistenci překladu a zlepšuje zážitek ze čtení",
          "title": "S umělou inteligencí"
        },
        "two": {
          "desc": "Plné pokrytí více jazyků, dvojjazyčné čtení bez bariér, překlad jedním kliknutím; pro bezkonkurenční zážitek!",
          "title": "Komplexnější a pohodlnější"
        }
      },
      "panel4": {
        "tab1": {
          "desc": "Váš expert na efektivitu v kombinaci s chatgpt překládá a shrnuje obsah videí YouTube, abyste ušetřili čas strávený čtením!",
          "key": "Video",
          "title": "Překlad a shrnutí videa"
        },
        "tab2": {
          "desc": "Kliknutím přeložíte obrázky při procházení webu",
          "key": "Image",
          "title": "Překlad obrázku"
        },
        "tab3": {
          "desc": "Rychlá extrakce klíčových informací z webových stránek pro shrnutí a zefektivnění čtení",
          "key": "Web",
          "title": "Překlad a shrnutí webu"
        },
        "tag1": "Jednoduché",
        "tag2": "Efektivní",
        "tag3": "Srozumitelné"
      },
      "title": "Asistent s umělou inteligencí"
    },
    "getStart": "Začít",
    "getStartedForFree": "Začít zdarma",
    "howItWork": {
      "desc": "Noam integruje modely umělé inteligence, aby uživatelům poskytl inteligentní prostředí pro vícejazyčný překlad a shrnutí webových stránek, překlad PDF a obrázků, překlad a shrnutí videa",
      "title": "Jak to funguje"
    },
    "lastBlock": {
      "desc": "Lepší překlad s umělou inteligencí a plynulejší zážitek ze čtení"
    },
    "top": {
      "desc": "Noam je bezplatné rozšíření prohlížeče s umělou inteligencí, které vám pomůže překládat a shrnovat webové stránky, videa a obsah souborů PDF. \\n      Připojte se k Noamovi a užijte si kouzlo!",
      "title1": "Jedním kliknutím",
      "title2": "Kouzlo na dosah"
    },
    "userReviews": {
      "desc": "Pětihvězdičkové hodnocení v obchodě Chrome Web Store",
      "title": "Uživatelské recenze"
    }
  },
  "installTips": {
    "btn": "Vyzkoušet",
    "text": "Nainstalujte si “Noam Browser Plugin” a odemkněte další funkce! Webové křížové odkazy,\\n    shrnutí webových videí a další!"
  },
  "locale": {
    "af": "Afrikánština",
    "am": "Amharština",
    "ar": "Arabština",
    "as": "Asámština",
    "auto": "Automatická detekce",
    "az": "Ázerbájdžánština",
    "ba": "Baškirština",
    "bg": "Bulharština",
    "bn": "Bengálština",
    "bo": "Tibetština",
    "bs": "Bostonská",
    "ca": "Katalánština",
    "cs": "Čeština",
    "cy": "Velština",
    "da": "Dánština",
    "de": "Němčina",
    "dsb": "Dolnolužická srbština",
    "dv": "Divehi",
    "el": "Řečtina",
    "en": "Angličtina",
    "es": "Španělština",
    "et": "Estonsko",
    "eu": "Baskičtina",
    "fa": "Perština",
    "fi": "Finština",
    "fil": "Filipínština",
    "fj": "Fidžijština",
    "fr": "Francouzština",
    "frCA": "Francouzština (Kanada)",
    "ga": "Irština",
    "gl": "Galicijština",
    "gom": "Konkánština",
    "gu": "Gudžarátština",
    "ha": "Hausa",
    "he": "Hebrejština",
    "hi": "Hindština",
    "hr": "Chorvatština",
    "hsb": "Hornolužická srbština",
    "ht": "Haitijský kreolský",
    "hu": "Maďarština",
    "hy": "Arménština",
    "id": "Indonéština",
    "ig": "Igbo",
    "ikt": "Inuinnaqtun",
    "is": "Islandština",
    "it": "Italština",
    "iu": "Inuktitut",
    "iuLatn": "Inuktitut (latinka)",
    "ja": "Japonština",
    "ka": "Gruzínština",
    "kk": "Kazaština",
    "km": "Khmerština",
    "kmr": "Kurština (severní)",
    "kn": "Kannadština",
    "ko": "Korejština",
    "ku": "Kurština (střední)",
    "ky": "Kyrgyzština",
    "ln": "Lingalština",
    "lo": "Laoština",
    "lt": "Litevština",
    "lug": "Ganda",
    "lv": "Lotyština",
    "lzh": "Klasická čínština",
    "mai": "Maithili",
    "mg": "Malgaština",
    "mi": "Maorština",
    "mk": "Makedonština",
    "ml": "Malajálamština",
    "mnCyrl": "Mongolština (cyrilice)",
    "mnMong": "Mongolština (tradiční)",
    "mr": "Maráthština",
    "ms": "Malajština",
    "mt": "Maltština",
    "mww": "Hmong Daw",
    "my": "Barmština",
    "nb": "Norština",
    "ne": "Nepálština",
    "nl": "Nizozemština",
    "noSearchLang": "Tento jazyk není podporován",
    "nso": "Severní Sotho",
    "nya": "Čečenské",
    "or": "Orijština",
    "otq": "Querétaro Otomi",
    "pa": "Paňdžábština",
    "pl": "Polština",
    "placeholder": "Hledat",
    "prs": "Darí",
    "ps": "Paštština",
    "pt": "Portugština (Brazílie)",
    "ptPT": "Portugalština (Portugalsko)",
    "ro": "Rumunština",
    "ru": "Ruština",
    "run": "Kirundi",
    "rw": "Kinyarwanda",
    "sd": "Sindhština",
    "si": "Sinhálština",
    "sk": "Slovenština",
    "sl": "Slovinština",
    "sm": "Samoanština",
    "sn": "Shona",
    "so": "Somálština",
    "sq": "Albánština",
    "srCyrl": "Srbština (cyrilice)",
    "srLatn": "Srbština (latinka)",
    "st": "Jižní Sotho",
    "sv": "Švédština",
    "sw": "Svahilština",
    "ta": "Tamilština",
    "te": "Telugština",
    "th": "Thajština",
    "ti": "Tigrinya",
    "tk": "Turkmenština",
    "tlhLatn": "Klingonština (latinka)",
    "tlhPiqd": "Klingonština (pIqaD)",
    "tn": "Tswana",
    "to": "Tongánština",
    "tr": "Turečtina",
    "tt": "Tatarština",
    "ty": "Tahitština",
    "ug": "Ujgurština",
    "uk": "Ukrajinština",
    "ur": "Urdština",
    "uz": "Uzbečtina (latinka)",
    "vi": "Vietnamština",
    "xh": "Xhosa",
    "yo": "Yorubština",
    "yua": "Yucatánská Mayština",
    "yue": "Kantonština (tradiční)",
    "zh": "Čínština",
    "zhHans": "Zjednodušená čínština",
    "zhHant": "Tradiční čínština",
    "zu": "Zulu"
  },
  "login": {
    "and": "  a",
    "btn": "Přihlásit se pomocí Google",
    "btn1": "Přihlásit se",
    "desc": "Přihlaste se a odemkněte si další možnosti",
    "policy": " Zásadami ochrany osobních údajů",
    "terms": "Podmínkami používání ",
    "tips": "Registrací souhlasím s",
    "title": "Vítejte v Noam"
  },
  "pageFooter": {
    "follow": {
      "facebook": "Facebook",
      "title": "Sledujte nás",
      "twitter": "Twitter"
    },
    "legal": {
      "policy": "Zásady ochrany osobních údajů",
      "terms": "Podmínky služby",
      "title": "Právní informace"
    },
    "product": {
      "image": "Překlad obrázků",
      "pdf": "Překlad PDF",
      "title": "Produkty",
      "video": "Překlad a shrnutí videa",
      "web": "Překlad a shrnutí webu"
    },
    "resources": {
      "title": "Zdroje"
    }
  },
  "pageHeader": {
    "contact": "Kontaktujte nás",
    "help": "Centrum nápovědy",
    "logout": "Odhlásit se",
    "logoutSucc": "Odhlášení proběhlo úspěšně",
    "pricing": "Ceník",
    "settings": "Nastavení",
    "signUp": "Přihlásit se"
  },
  "pdf": {
    "autoDetect": "Automaticky detekovat",
    "cancelBtn": "Zrušit",
    "cancelUpload": "Zrušit",
    "chatPdf": {
      "Search": "Hledat",
      "cancel": "Zrušit",
      "chatTitle": "Vítejte v Noamu, shrnutí vašeho PDF je připraveno:",
      "clear": "Vymazat",
      "clearContent": "Po vymazání bude vytvořena nová konverzace a obsah této konverzace nebude uložen, opravdu chcete vymazat?",
      "clearDialogTitle": "Upozornění",
      "clearSucc": "Vymazáno úspěšně",
      "copy": "Kopírovat",
      "copySucc": "Úspěšně zkopírováno",
      "delete": "Smazat",
      "deleteCancel": "Zrušit",
      "deleteContent": "Po smazání nebude toto PDF obnovitelné, chcete ho smazat?",
      "deleteOk": "Smazat",
      "deleteSuccess": "Úspěšně smazáno",
      "deleteTitle": "Tip",
      "expand": "Sbalit",
      "historyTitle": "Historie",
      "listError": "Nepodařilo se načíst seznam četby. Zkuste to prosím znovu později nebo kontaktujte zákaznickou podporu",
      "loading": "načítání...",
      "noDataDesc": "Žádná historie~ ",
      "noMore": "Už nic dalšího.",
      "placeholder": "Zadejte jakýkoli dotaz týkající se PDF",
      "summaryTitle": "Otázky, které by vás mohly zajímat:"
    },
    "collectionEmpty": "V kolekci není žádný obsah~",
    "collectionTab": "Poznámky",
    "dataLoading": "Načítání dat...",
    "dialogContent": "Opravdu to chcete smazat?",
    "dialogTitle": "Upozornění",
    "download": {
      "both": "dvojjazyčný",
      "btn": "Uložit",
      "cancel": "Zavřením vyskakovacího okna zrušíte stahování souboru PDF. Opravdu chcete zrušit?",
      "desc": "Uložit lokálně: Stažené překlady se uloží jako obrázky; Uložit a vytisknout: Stažené překlady si zachovají původní formátování a podporují kopírování.",
      "download": "Stáhnout",
      "downloadMsg1": "Průběh překladu PDF:",
      "downloadMsg2": "Předpokládané požadavky",
      "downloadSucc": "PDF je připraveno ke stažení, klikněte na tlačítko Uložit!",
      "downloadTip": "Probíhá stahování souboru PDF. Během stahování nezavírejte vyskakovací okno, aby nedošlo k chybě stahování.",
      "error": "Nepodařilo se uložit lokálně, zkuste tisk s uložením.",
      "fileName": "Název souboru:",
      "fileType": "Typ stahovaného souboru:",
      "loading": "Ukládání...",
      "loading2": "Načítání...",
      "minute": "Minut...",
      "msg1": "Některé překlady v aktuálním dokumentu jsou delší než originální text, a proto se po stažení může text překrývat. Doporučujeme číst dokument na webové stránce.",
      "msg2": "1、Obsah nebyl kompletně přeložen, stáhněte si ho prosím až po dokončení překladu.",
      "msg3": "2、Část překladu dokumentu je příliš dlouhá, můžete si ji přečíst na webové stránce, abyste se vyhnuli překrývání textu.",
      "msg4": "Momentálně probíhá překládání úloh, zkuste to prosím znovu po dokončení překladu.",
      "progress": "PDF se překládá. Průběh: {progress}, předpokládaný čas dokončení: {minutes} minut...",
      "save": "Uložit lokálně",
      "savePrint": "Uložit a vytisknout",
      "success": "Staženo úspěšně",
      "trans": "Pouze překlad",
      "transContinue": "Pokračovat v překladu"
    },
    "downloadBtn": "Stáhnout",
    "dragTipModal": {
      "black": "mezerník",
      "leftKey": "levé tlačítko myši",
      "ok": "Rozumím",
      "press": "Stiskněte",
      "tips": "pro přetažení a zobrazení."
    },
    "entry": "Seznam PDF",
    "fileErrMsg": "Nepodařilo se načíst soubor, zkuste to prosím znovu později nebo kontaktujte zákaznický servis.",
    "fileMsg": "Aktuální velikost souboru:",
    "fileMsg2": "Nahrajte prosím soubor menší než 100 MB",
    "freeBannerBtn": "Přejděte na vyšší verzi a užijte si více",
    "freeBannerTips": "Konec bezplatného překladu PDF",
    "guide": {
      "placeholder": "Zadejte odkaz na PDF",
      "start": "Zahájit překlad",
      "text1": "Dvojjazyčné čtení",
      "text2": "Dvojjazyčné čtení vám umožňuje porovnávat originální text s překladem během čtení, čímž se zlepší efektivita čtení",
      "text3": "Chat s PDF",
      "text4": "Umělá inteligence analyzuje a shrnuje obsah PDF a odpovídá na vaše dotazy",
      "text5": "Přesnost a efektivita",
      "text6": "Překlady PDF s podporou umělé inteligence pro větší přesnost a efektivitu",
      "title1": "Dvojjazyčný překlad",
      "title2": "Chat s PDF",
      "toast": "Zadejte prosím platný online odkaz na PDF končící příponou .pdf"
    },
    "hasTransTask": "Probíhá překládání úlohy. Počkejte prosím.",
    "hoverTrans": "Překlad odstavce po najetí myší",
    "initial": "Přeložit PDF",
    "installText": "Vše, co potřebujete pro každodenní překlad, na jednom místě",
    "installText2": "Nejprve nainstalujte",
    "installTips": "Nainstalujte si malý plugin, budete moci překládat více PDF souborů a vyzkoušet si plynulý překlad webových stránek:",
    "kw": "sešívačka",
    "link": "odkaz",
    "listError": "Nepodařilo se načíst seznam PDF, zkuste to prosím znovu později nebo kontaktujte zákaznický servis.",
    "memoTotalCount": "Celkem 1 obsah",
    "new": "nový soubor",
    "noId": "ID souboru neexistuje",
    "notPdf": "Pouze soubory PDF",
    "okBtn": "Smazat",
    "okBtn2": "rozpoznat",
    "original": "Originál",
    "parallel": "Paralelní",
    "parseErrDialogContent": "Aktuální odkaz na PDF nepodporuje přímý překlad, stáhněte si prosím originální PDF z odkazu a přeložte jej.",
    "parseErrTips": "Soubor nelze analyzovat",
    "parseFileToast": "Analýza souboru se nezdařila, zkuste to prosím znovu později nebo kontaktujte náš zákaznický servis.",
    "pdfLoading": "Probíhá analýza...",
    "searchPlaceholder": "Hledat",
    "selectTrans": "podtrhněte slova a přeložte",
    "shareBtn": "Sdílet",
    "shareBtnText1": "Generovat odkaz pro sdílení",
    "shareBtnText2": "Aktualizovat obsah sdílení",
    "shareModal": {
      "cancel": "Zrušit",
      "copy": "Kopírovat",
      "copySucc": "Odkaz byl zkopírován",
      "custom": "Přizpůsobení:",
      "errMsg": "Obsah aktuálně sdíleného rozsahu nebyl kompletně přeložen, přeložte jej prosím před sdílením.",
      "gen": "Sdílet",
      "modify": "upravit",
      "notSupport": "Tento jazyk momentálně není podporován",
      "page": "stránky",
      "pageNuErr": "Zadejte prosím správné číslo stránky",
      "range": "Rozsah:",
      "remain": "zbývá",
      "remain1": "Celkové předpokládané požadavky",
      "search": "Hledat",
      "settings": "Nastavení sdílení:",
      "shareSetting": "Nastavení sdílení obsahu",
      "shareText": "Sdílet",
      "shareTips": "Sdílejte odkaz na přeložené PDF se svými přáteli:",
      "sourceLang": "Zdrojový jazyk:",
      "target": "Cílový jazyk:",
      "text1": "Nastavení sdílení",
      "text2": "Generovat odkaz pro sdílení",
      "totalPage": "Celkem 1 stránek",
      "trans": "Přeložit",
      "translateTips": "Tip: Pokud překlad trvá příliš dlouho, můžete vyskakovací okno zavřít a sdílet ho až po dokončení překladu.",
      "translating": "Probíhá překládání PDF...",
      "update": "Odkaz pro sdílení byl aktualizován"
    },
    "shareMsg1": "Odkaz pro sdílení byl úspěšně vygenerován",
    "shareMsg2": "Obsah sdílení byl úspěšně aktualizován",
    "shareMsg3": "Vytvoření odkazu pro sdílení se nezdařilo, zkuste to prosím znovu později nebo kontaktujte náš zákaznický servis.",
    "shareMsg4": "Aktualizace obsahu sdílení se nezdařila, zkuste to prosím znovu později nebo kontaktujte zákaznický servis",
    "shareTips": "Překlad PDF je hotový, sdílejte ho se svými přáteli!",
    "start": "Začněte s bezplatným vyzkoušením",
    "thumbnail": "obsah",
    "toast1": "PDF se načítá, zkuste to prosím znovu později!",
    "toast2": "Obsah PDF je příliš velký, dialogové okno momentálně není podporováno",
    "toast3": "Stahování, zkuste to prosím znovu po dokončení stahování.",
    "toolbar": {
      "Adjust": "Upravit",
      "autoFit": "Automaticky přizpůsobit"
    },
    "trans": "Překlad",
    "transSucc": "Překlad byl úspěšný!",
    "unLoginToast": "Pro použití této funkce se prosím přihlaste",
    "uploadBtn": "Nahrát",
    "uploadErr": "Nepodařilo se nahrát soubor. Zkuste to prosím znovu později nebo kontaktujte zákaznický servis",
    "uploadMsg1": "Přetáhněte soubor sem",
    "uploadMsg2": "Podporované typy souborů: PDF  |  Maximální velikost souboru: 100 MB",
    "uploadMsg3": "Kliknutím vyberte nebo sem přetáhněte soubory k nahrání",
    "uploading": "Nahrávání",
    "uploadingMsg": "Nahrávání souboru může chvíli trvat, prosím, buďte trpěliví.",
    "uploadingMsg1": "Probíhá nahrávání souboru. Zkuste to prosím znovu později.",
    "uploadingMsg2": "Momentálně se převádí jiný soubor, zkuste to prosím znovu později.",
    "uploadingMsg3": "Požadavek na průběh převodu se nezdařil, zkuste to prosím znovu později nebo kontaktujte zákaznický servis",
    "uploadingMsg4": "Noam nemá přístup k vašim lokálním souborům, pro překlad nahrajte PDF soubor ručně.",
    "uploadingMsg5": "Nepodařilo se získat adresu URL souboru, zkuste to prosím znovu později nebo kontaktujte zákaznický servis.",
    "video": "video",
    "vipBannerBtn": "Přejděte na vyšší verzi a užijte si více",
    "vipBannerTips": "Tento měsíc jste dosáhli limitu pro překlad PDF.",
    "webTrans": "úplné křížové odkazy"
  },
  "pricing": {
    "FAQ": "Často kladené dotazy",
    "FQAObj": {
      "a1": "Existuje bezplatná verze Noam, kterou lze používat přímo.",
      "a2": "Kromě bezplatné verze existují tři tarify členství: Pro, Pro+ a Enterprise. Můžete si vybrat ze dvou platebních metod: průběžné měsíční a průběžné roční. Komplexní náklady na průběžnou roční fakturaci jsou výhodnější.",
      "a3": "Ve funkčnosti není žádný rozdíl. Komplexní náklady na průběžné roční předplatné jsou nižší než náklady na průběžné měsíční předplatné. Měsíční předplatné se účtuje jednou měsíčně, zatímco roční předplatné se účtuje jednou ročně.",
      "a4": "Na stránce nastavení backendu můžete zvolit možnost zrušit obnovení předplatného.",
      "q1": "Mohu to používat zdarma?",
      "q2": "Existují různé tarify členství a možnosti poplatků?",
      "q3": "Jaký je rozdíl mezi průběžným měsíčním a průběžným ročním předplatným?",
      "q4": "Jak mohu zrušit automatické obnovení předplatného?"
    },
    "around": "Přibližně",
    "billedMonthly": "Fakturace měsíčně",
    "billedYearly": "Fakturace ročně",
    "day": "den",
    "desc": "Porovnejte a vyberte si tu nejlepší pro vás",
    "detailedCompare": "Podrobné srovnání ",
    "discount": "20% sleva",
    "enterprise": {
      "benefit1": "Rychlá podpora od zákaznického servisu",
      "benefit2": "Pro více uživatelů",
      "benefit3": "Řešení na míru",
      "benefit5": "Vyhrazený manažer účtu",
      "btn": "Kontakt",
      "recommendText": "Plány na míru (≥ 3 osoby)",
      "talkToUs": "Promluvte si s námi"
    },
    "free": {
      "benefit1": "Okamžitý překlad ${FREE_BENEFITS.realTimeCount}krát/den",
      "benefit2": "Překlad a shrnutí webových stránek",
      "benefit3": "Překlad obrázků z ${FREE_BENEFITS.imageCount} listů",
      "benefit4": "Překlad a shrnutí videa",
      "benefit5": "Překlad ${FREE_BENEFITS.pdfUploadCount} PDF",
      "benefit6": "${FREE_BENEFITS.tokenCount} tokenů",
      "btn": "Užijte si to",
      "recommendText": "Zkusit zdarma"
    },
    "imageTranslation": "Překlad obrázků",
    "month": "měsíc",
    "monthly": "Měsíčně",
    "pageTitle": "Cenové tarify",
    "pdf": {
      "chat": "Počet dotazů na PDF",
      "maxPage": "Maximální počet stran v PDF",
      "translation": "Překlad PDF"
    },
    "preMonth": "Za měsíc",
    "pro": {
      "benefit1": "Rychlá podpora od zákaznického servisu",
      "benefit2": "${PRO_BENEFITS.tokenCount} tokenů/měsíc",
      "benefit3": "Překlad a shrnutí webových stránek",
      "benefit4": "Překlad obrázků z ${PRO_BENEFITS.imageCount} listů",
      "benefit5": "Překlad a shrnutí videa",
      "benefit6": "Překlad ${PRO_BENEFITS.pdfUploadCount} PDF",
      "btn": "Začít",
      "recommendText": "Většina lidí volí"
    },
    "proPlus": {
      "benefit1": "Rychlá podpora od zákaznického servisu",
      "benefit2": "${PRO_V_BENEFITS.tokenCount} tokenů/měsíc",
      "benefit3": "Překlad a shrnutí webových stránek",
      "benefit4": "Překlad obrázků z ${PRO_V_BENEFITS.imageCount} listů",
      "benefit5": "Překlad a shrnutí videa",
      "benefit6": "Překlad ${PRO_V_BENEFITS.pdfUploadCount} PDF",
      "recommendText": "Nejvhodnější volba"
    },
    "video": {
      "summary": "Shrnutí videa",
      "translation": "Překlad videa"
    },
    "web": {
      "contrastiveTranslation": "Dvojjazyčný překlad",
      "dictionaryTranslation": "Překlad vybraného textu",
      "hoverTranslation": "Překlad po najetí myší",
      "instantTranslation": "Okamžitý překlad",
      "summary": "Shrnutí webové stránky"
    },
    "yearly": "Ročně"
  },
  "privacy": {
    "block1": "My, Noam (dále jen „Noam“, „my“, „naše“ nebo „nás“), respektujeme vaše soukromí a zavázali jsme se chránit veškeré informace, které od vás nebo o vás získáme. Tyto Zásady ochrany osobních údajů popisují naše postupy týkající se shromažďování nebo používání osobních údajů z vašeho používání našich webových stránek, aplikací a služeb (souhrnně označovaných jako „Služby“). Tyto Zásady ochrany osobních údajů se nevztahují na obsah, který zpracováváme jménem našich zákazníků z obchodní oblasti produktů, jako je naše rozhraní API. Používání těchto dat podléhá naší smlouvě se zákazníkem, která se vztahuje na přístup k těmto produktům a jejich používání.",
    "block10": "Zavádíme komerčně přiměřená technická, administrativní a organizační opatření k ochraně online i offline osobních údajů před ztrátou, zneužitím a neautorizovaným přístupem, zveřejněním, pozměněním nebo nepřátelskými činy. Žádný internetový ani e-mailový přenos však nemůže zaručit absolutní bezpečnost ani přesnost. Zejména e-maily, které nám posíláte nebo které vám posíláme, nemusí být zabezpečené. Proto byste měli být obzvláště opatrní, když se rozhodujete, jaké informace nám prostřednictvím služby nebo e-mailu zašlete. Kromě toho neneseme odpovědnost za žádné narušení nastavení ochrany osobních údajů nebo bezpečnostních opatření ve službě ani za nastavení zabezpečení nebo bezpečnostní opatření na webových stránkách třetích stran. Vaše osobní údaje budeme uchovávat tak dlouho, jak bude nezbytné pro to, abychom vám mohli poskytovat služby, nebo pro jiné oprávněné obchodní účely, jako je řešení sporů, bezpečnostní důvody nebo plnění našich zákonných povinností. Doba, po kterou osobní údaje uchováváme, bude záviset na mnoha faktorech, jako je množství, povaha, citlivost údajů, potenciální rizika neoprávněného použití nebo zveřejnění, účely, pro které údaje zpracováváme, a další právně závazné aspekty.",
    "block11": "Tyto Zásady ochrany osobních údajů můžeme čas od času aktualizovat. Pokud tak učiníme, zveřejníme aktualizovanou verzi na této stránce, pokud to nevyžaduje platné právo.",
    "block12": "Pokud máte jakékoli nezodpovězené otázky nebo obavy ohledně těchto Zásad ochrany osobních údajů, obraťte se prosím na náš zákaznický servis. E-mail: ",
    "block2": "Shromažďujeme následující osobní údaje („Osobní údaje“) týkající se vaší osoby:",
    "block3": "Informace, které nám poskytnete: Pokud si vytvoříte účet pro používání našich služeb nebo komunikaci s námi, budeme shromažďovat osobní údaje takto:",
    "block4": "Osobní údaje automaticky přijímané z vašeho používání služeb: Když přistupujete ke službám, používáte je nebo s nimi komunikujete, přijímáme následující informace o vašem přístupu, použití nebo interakci („Technické informace“):",
    "block5": "Osobní údaje můžeme použít k následujícím účelům:",
    "block6": "Souhrnné nebo anonymizované informace: Osobní údaje můžeme agregovat nebo anonymizovat, abychom zabránili jejich opětovnému použití pro účely identifikace, a tyto informace můžeme použít k analýze efektivity našich služeb, ke zlepšování a doplňování funkcí našich služeb, k provádění výzkumu a k podobným účelům. Kromě toho můžeme analyzovat obecné chování a charakteristiky uživatelů našich služeb a sdílet agregované informace se třetími stranami, zveřejňovat je nebo je zpřístupnit široké veřejnosti. Agregované informace můžeme shromažďovat prostřednictvím služeb, souborů cookie a dalších prostředků popsaných v těchto Zásadách ochrany osobních údajů. Anonymizované informace budeme uchovávat a používat v anonymní nebo anonymizované podobě a nebudeme se snažit o opětovnou identifikaci těchto informací, pokud to nebude vyžadovat zákon.",
    "block7": "V určitých případech můžeme vaše osobní údaje poskytnout třetím stranám bez dalšího upozornění, pokud to nevyžaduje zákon:",
    "block8": "V závislosti na vaší zeměpisné poloze mohou mít osoby v Evropském hospodářském prostoru, Spojeném království a na celém světě určitá zákonná práva týkající se jejich osobních údajů. Můžete mít například právo:",
    "block9": "Služba může obsahovat odkazy na jiné webové stránky, které nejsou provozovány ani kontrolovány společností Noam, včetně služeb sociálních médií („Webové stránky třetích stran“). Na informace, které sdílíte s webovými stránkami třetích stran, se vztahují konkrétní zásady ochrany osobních údajů a podmínky používání těchto webových stránek třetích stran, nikoli tyto Zásady ochrany osobních údajů. Tyto odkazy poskytujeme pro vaše pohodlí a nevyplývá z nich naše schválení ani kontrola těchto webových stránek. Obraťte se prosím přímo na webové stránky třetích stran, abyste získali informace o jejich zásadách ochrany osobních údajů a podmínkách.",
    "subBlock1": "Informace o účtu: Když si vytvoříte účet na naší platformě, shromažďujeme informace o vašem účtu, včetně vašeho jména, kontaktních údajů, přihlašovacích údajů k účtu, informací o platební kartě a historie transakcí (označované jako „Informace o účtu“).",
    "subBlock10": "K poskytování, správě, údržbě a/nebo analýze služeb;",
    "subBlock11": "Ke komunikaci s vámi;",
    "subBlock12": "K vývoji nových projektů a služeb;",
    "subBlock13": "K předcházení podvodům, trestné činnosti nebo zneužití našich služeb a k ochraně bezpečnosti našich IT systémů, architektury a sítí;",
    "subBlock14": "K provádění převodů podniků;",
    "subBlock15": "K dodržování právních závazků a právních řízení, k ochraně našich práv, soukromí, bezpečnosti nebo majetku, jakož i práv našich přidružených společností, vás nebo jiných třetích stran.",
    "subBlock16": "Dodavatelé a poskytovatelé služeb: Abychom si usnadnili plnění obchodních provozních potřeb a poskytování určitých služeb a funkcí, můžeme osobní údaje poskytnout dodavatelům a poskytovatelům služeb, včetně poskytovatelů hostingových služeb, poskytovatelů cloudových služeb a dalších poskytovatelů služeb v oblasti informačních technologií, softwaru pro e-mailovou komunikaci a poskytovatelů služeb webové analýzy atd. Tito partneři budou mít přístup k osobním údajům, budou je zpracovávat nebo ukládat pouze na náš pokyn při plnění svých povinností vůči nám.",
    "subBlock17": "Převody podniků: Pokud se budeme podílet na strategických transakcích, reorganizacích, bankrotech, převzetích z konkurzní podstaty nebo přechodech služeb na jiného poskytovatele (souhrnně označovaných jako „Transakce“), mohou být vaše osobní údaje a další informace sdíleny s protistranami transakcí a dalšími stranami, které se na Transakcích podílejí, v rámci due diligence a převedeny jako součást Transakcí spolu s dalšími aktivy na nástupnickou nebo přidruženou společnost.",
    "subBlock18": "Zákonné požadavky: Vaše osobní údaje můžeme sdílet s vládními orgány, subjekty z oboru nebo jinými třetími stranami, včetně informací o vaší interakci s našimi službami, (1) pokud to vyžaduje zákon nebo pokud se domníváme, že je takový postup nezbytný k dodržení zákonných povinností; (2) k ochraně a obraně našich práv nebo majetku; (3) pokud jednostranně rozhodneme, že došlo k porušení našich podmínek, zásad nebo zákonů; (4) za účelem odhalení nebo prevence podvodu nebo jiné nezákonné činnosti; (5) k ochraně našich produktů, zaměstnanců nebo uživatelů nebo veřejné bezpečnosti, zabezpečení, integrity; nebo (vi) k obraně proti právní odpovědnosti.",
    "subBlock19": "Na přístup ke svým osobním údajům a informacím o tom, jak jsou zpracovávány.",
    "subBlock2": "Uživatelský obsah: Když používáte naše služby, shromažďujeme osobní údaje z vašich vstupů, nahraných souborů nebo zpětné vazby, kterou nám poskytnete (označované jako „Obsah“).",
    "subBlock20": "Na výmaz svých osobních údajů z našich záznamů.",
    "subBlock21": "Na opravu nebo aktualizaci svých osobních údajů.",
    "subBlock22": "Na přenesení svých osobních údajů třetí straně (přenositelnost údajů).",
    "subBlock23": "Na omezení zpracování vašich osobních údajů.",
    "subBlock24": "Na odvolání souhlasu - pokud se spoléháme na souhlas jakožto právní základ pro zpracování, můžete svůj souhlas kdykoli odvolat.",
    "subBlock25": "Vznést námitku proti způsobu, jakým vaše osobní údaje zpracováváme.",
    "subBlock26": "Ke zlepšování našich služeb a provádění výzkumu;",
    "subBlock3": "Informace o komunikaci: Pokud nám zašlete zprávy, budeme shromažďovat vaše jméno, kontaktní údaje a obsah zpráv, které odešlete (označované jako „Informace o komunikaci“).",
    "subBlock4": "Informace o sociálních médiích: Máme stránky na webových stránkách sociálních médií, jako jsou Instagram, Facebook, Medium, Twitter, YouTube a LinkedIn. Když budete komunikovat s našimi stránkami na sociálních médiích, budeme shromažďovat osobní údaje, které se nám rozhodnete poskytnout, například vaše kontaktní údaje (označované jako „Informace o sociálních médiích“). Společnosti provozující naše stránky na sociálních médiích nám navíc mohou poskytovat souhrnné informace a analýzy o našich aktivitách na sociálních médiích.",
    "subBlock5": "Data protokolu: Informace, které váš prohlížeč automaticky odesílá při používání našich služeb. Data protokolu zahrnují vaši adresu internetového protokolu (IP), typ a nastavení prohlížeče, datum a čas vašich požadavků a způsob interakce s našimi webovými stránkami.",
    "subBlock6": "Údaje o používání: Můžeme automaticky shromažďovat informace o vašem používání služby, například o typech obsahu, který si prohlížíte nebo se kterým komunikujete, o funkcíc, které používáte, a o provedených akcích, jakož i o vašem časovém pásmu, zemi, datu a čase přístupu, uživatelském agentovi a verzi, typu počítače nebo mobilního zařízení a vašem počítačovém připojení.",
    "subBlock7": "Informace o zařízení: Zahrnují název zařízení, operační systém, identifikátor zařízení a prohlížeč, který používáte. Shromažďované informace se mohou lišit v závislosti na typu zařízení, které používáte, a jeho nastavení.",
    "subBlock8": "Soubory cookie: Používáme soubory cookie k provozování a správě našich služeb a ke zlepšování vašich zkušeností. „Soubor cookie“ je informace, kterou webová stránka, kterou navštívíte, odešle do vašeho prohlížeče. Svůj prohlížeč můžete nastavit tak, aby přijímal všechny soubory cookie, odmítal všechny soubory cookie nebo vás informoval o každém poskytnutí souboru cookie, abyste se mohli rozhodnout, zda ho přijmete. Odmítnutí souborů cookie však může zabránit používání určitých oblastí nebo funkcí webových stránek nebo negativně ovlivnit jejich zobrazení nebo funkčnost. Více informací o souborech cookie naleznete na adrese Vše o souborech cookie.",
    "subBlock9": "Analytika: Můžeme používat různé online analytické produkty, které používají soubory cookie, aby nám pomohly analyzovat, jak uživatelé naše služby používají, a zlepšovat vaše zkušenosti s používáním služeb.",
    "subTitle1": "Osobní údaje, které shromažďujeme",
    "subTitle2": "Jak používáme osobní údaje",
    "subTitle3": "Zpřístupnění osobních údajů",
    "subTitle4": "Vaše práva",
    "subTitle5": "Odkazy na jiné webové stránky",
    "subTitle6": "Zabezpečení a uchovávání informací",
    "subTitle7": "Změna Zásad ochrany osobních údajů",
    "subTitle8": "Jak nás kontaktovat",
    "title": "Zásady ochrany osobních údajů",
    "updateTime": "Aktualizováno: 15. března 2024"
  },
  "privacyCollectionPanel": {
    "acceptBtn": "Přijmout vše",
    "text": "Abychom zlepšili vaše prostředí, rádi bychom používali soubory cookie k personalizaci obsahu a služeb.",
    "title": "Tato webová stránka používá soubory cookie"
  },
  "slogen": "Nejjednodušší AI asistent",
  "stripe": {
    "contact": "V případě jakýchkoli dotazů nás kontaktujte na adrese contact@noam.tools!",
    "fail": {
      "btn": "Zkusit znovu",
      "text1": "Omlouváme se, vaši platební žádost se nám nepodařilo zpracovat.",
      "text2": "Zkontrolujte připojení k internetu a zkuste to znovu.",
      "title": "Platba se nezdařila"
    },
    "succ": {
      "btn": "Začněme s magií",
      "text1": "vítejte v Noamu, ",
      "text2": "pojďme společně začít!",
      "title": "Gratulujeme"
    }
  },
  "terms": {
    "block1": "Děkujeme, že používáte Noam!",
    "block10": "(a) Poplatky a fakturace. Veškeré poplatky (\"poplatky\") budete platit v souladu s ceníkem a podmínkami uvedenými na příslušné stránce s ceníkem nebo podle písemné dohody mezi námi. Vyhrazujeme si právo opravit cenové chyby nebo omyly, a to i v případě, že jsme již vystavili faktury nebo obdrželi platbu. Poskytnete úplné a přesné fakturační údaje, včetně platného a autorizovaného způsobu platby. Váš způsob platby budeme účtovat pravidelně podle dohody, ale datum fakturace můžeme z rozumných důvodů změnit. Zmocňujete Noam, jeho přidružené společnosti a zpracovatele plateb třetích stran k initování platby z vašeho způsobu platby. Pokud se platba nezdaří, zašleme vám písemné oznámení a můžeme pozastavit přístup ke službě, dokud nebude platba uhrazena. Není-li v této smlouvě uvedeno jinak, platby jsou nevratné.",
    "block11": "(b) Daně. Není-li uvedeno jinak, poplatky nezahrnují žádné federální, státní, místní ani zahraniční daně, cla a podobné poplatky (\"daně\"). Odpovídáte za všechny daně spojené s vašimi nákupy, s výjimkou daní z našeho čistého příjmu, za které vám vystavíme fakturu. Souhlasíte s tím, že budete tyto daně platit, že nám poskytnete právně závazná prohlášení o ochraně osobních údajů a získáte potřebné souhlasy se zpracováním těchto údajů, a zaručujete se nám, že s těmito údaji nakládáte v souladu s platnými právními předpisy.",
    "block12": "(c) Změny cen. Ceny můžeme měnit, o čemž vás budeme informovat prostřednictvím vašeho účtu a/nebo našich webových stránek. Zvýšení cen nabývá účinnosti okamžikem zveřejnění. Veškeré změny cen se budou vztahovat na poplatky za váš účet po datu účinnosti změny.",
    "block13": "(d) Spory a po splatnosti neuhrazené platby. Pokud si přejete zpochybnit jakékoli poplatky nebo daně, kontaktujte prosím adresu contact@noam.tools do třiceti (30) dnů od data vystavení faktury.",
    "block14": "(e) Bezplatné používání. Nesmíte si vytvářet více účtů za účelem získání bezplatných kreditů. Pokud zjistíme, že bezplatné kredity nevyužíváte v dobré víře, můžeme vám naúčtovat standardní poplatky nebo vám zablokovat přístup ke službě.",
    "block15": "(a) Důvěrnost. Můžete mít přístup k důvěrným informacím Noamu. Důvěrné informace smíte používat pouze způsobem povoleným těmito podmínkami pro účely používání služby. Nesmíte sdělovat důvěrné informace žádné třetí straně a budete chránit důvěrné informace způsobem, který není méně ochranný než způsob, jakým chráníte své vlastní podobné důvěrné informace, přinejmenším s přiměřenou péčí. Důvěrné informace jsou neveřejné informace označené jako důvěrné společností Noam nebo informace, které by za daných okolností měly být považovány za důvěrné, včetně softwaru, specifikací a dalších neveřejných obchodních informací. Důvěrné informace nezahrnují informace, které: (1) se stanou obecně dostupnými veřejnosti bez vašeho zavinění; (2) jste měli ve svém držení bez jakéhokoli závazku mlčenlivosti před jejich obdržením na základě těchto podmínek; (3) vám byly zákonně zpřístupněny třetí stranou bez jakýchkoli závazků mlčenlivosti; nebo (4) jste je nezávisle vyvinuli bez použití důvěrných informací. Důvěrné informace smíte sdělit, pokud to vyžaduje zákon, soud nebo jiný státní orgán, avšak musíte o tom Noam předem písemně vyrozumět s přiměřeným předstihem a v maximální možné míře se snažit omezit rozsah sdělení, včetně pomoci při odporování proti žádostem o sdělení.",
    "block16": "(b) Zabezpečení. Musíte zavést přiměřená a vhodná opatření, která pomohou chránit váš přístup ke službě a její používání. Pokud zjistíte jakékoli zranitelnosti nebo porušení související s vaším používáním služby, musíte neprodleně kontaktovat Noam a poskytnout podrobnosti o zranitelnosti nebo porušení.",
    "block17": "((c) Zpracování osobních údajů. Pokud službu používáte ke zpracování osobních údajů, musíte poskytnout právně dostatečná prohlášení o ochraně osobních údajů a získat potřebné souhlasy se zpracováním těchto údajů, a zaručujete se nám, že s těmito údaji nakládáte v souladu s platnými právními předpisy.",
    "block18": "(a) Ukončení; pozastavení. Tyto podmínky nabývají účinnosti prvním použitím služby a zůstávají v platnosti až do ukončení. Tyto podmínky můžete kdykoli z jakéhokoli důvodu vypovědět ukončením používání služby a obsahu. My můžeme tyto podmínky vypovědět s předchozím upozorněním z jakéhokoli důvodu. Tyto podmínky můžeme okamžitě vypovědět tak, že vás o tom vyrozumíme, pokud závažným způsobem porušíte článek 2 (Požadavky na používání), článek 5 (Důvěrnost, bezpečnost a ochrana údajů), článek 8 (Řešení sporů) nebo článek 9 (Všeobecná ustanovení), nebo pokud se změní náš vztah s jakýmkoli poskytovatelem technologií třetí strany, který je mimo naši kontrolu, nebo z důvodu dodržení zákona nebo požadavků státních orgánů. Váš přístup ke službě můžeme pozastavit, pokud nedodržíte tyto podmínky nebo pokud vaše používání představuje bezpečnostní riziko pro nás nebo jakoukoli třetí stranu, nebo pokud máme podezření, že je vaše používání podvodné nebo by mohlo nás nebo jakoukoli třetí stranu vystavit odpovědnosti.",
    "block19": "(b) Důsledky ukončení. Po ukončení platnosti těchto podmínek přestanete službu používat a neprodleně nám vrátíte nebo zničíte veškeré důvěrné informace podle našich pokynů. Ustanovení těchto podmínek, která by svou povahou měla zůstat v platnosti i po ukončení nebo uplynutí jejich platnosti, včetně, ale bez omezení na články 3 a 5–9, zůstávají v platnosti.",
    "block2": "Tyto podmínky použití se vztahují na vaše používání služeb Noam, včetně našich rozhraní pro programování aplikací, softwaru, nástrojů, vývojářských služeb, dat, dokumentace a webových stránek (souhrnně označovaných jako \"služby\"). Používáním našich služeb souhlasíte s dodržováním těchto podmínek. Naše zásady ochrany osobních údajů vysvětlují, jak shromažďujeme a používáme osobní údaje.",
    "block20": "(a) Odškodnění. Zavazujete se, že nás, naše přidružené společnosti a naše zaměstnance budete chránit a odškodňovat před všemi nároky, ztrátami a výdaji (včetně nákladů na právní zastoupení) vzniklými v souvislosti s vaším používáním služby, včetně vašeho obsahu, vašeho používání produktů nebo služeb souvisejících se službou a vaším porušením těchto podmínek nebo platných právních předpisů.",
    "block21": "(b) Vyloučení odpovědnosti. Služba je poskytována \"tak, jak je\". V rozsahu povoleném zákonem my ani naše přidružené společnosti a poskytovatelé licencí neposkytujeme žádná prohlášení ani záruky jakéhokoli druhu týkající se služby a neposkytujeme žádné záruky, včetně, ale bez omezení na záruky prodejnosti, vhodnosti pro určitý účel, uspokojivé kvality, neporušení práv a nerušeného užívání a jakékoli záruky vyplývající z obchodního styku nebo obchodních zvyklostí. Nezaručujeme, že služba bude fungovat nepřerušovaně, přesně nebo bezchybně, ani že jakýkoli obsah bude bezpečný, že nedojde k jeho ztrátě ani změně.",
    "block22": "(c) Omezení odpovědnosti. My ani naše přidružené společnosti ani poskytovatelé licencí neneseme odpovědnost za žádné nepřímé, náhodné, zvláštní, následné ani sankční škody, včetně ušlého zisku, újmy na dobré pověsti, ztráty možnosti užívání, ztráty dat nebo jiných nehmotných škod, a to i v případě, že jsme byli na možnost vzniku takových škod upozorněni. Naše celková odpovědnost podle těchto podmínek nepřesáhne poplatky, které jste zaplatili za službu v průběhu dvanácti (12) měsíců předcházejících vzniku nároku, nebo sto amerických dolarů (100 USD), podle toho, která z těchto částek je vyšší. Omezení v tomto článku se použijí v maximálním rozsahu povoleném platnými právními předpisy.",
    "block23": "Na tyto podmínky a vaše používání služeb se vztahují zákony Spojených států amerických, s výjimkou kolizních norem USA. Na vaše používání aplikace se mohou vztahovat i jiné místní, státní, národní nebo mezinárodní zákony. Veškeré soudní spory nebo řízení týkající se těchto podmínek budou probíhat výhradně u soudů Spojených států amerických a vy souhlasíte s tím, že se podrobíte osobní jurisdikci těchto soudů.",
    "block24": "(a) Vztah smluvních stran. Noam a vy jste nezávislými smluvními stranami a žádná ze stran nemá právo zastupovat ani zavazovat druhou stranu ani přejímat závazky za druhou stranu bez předchozího písemného souhlasu druhé strany.",
    "block25": "(b) Používání značky. Název, loga ani ochranné známky společnosti Noam ani žádné z jejích přidružených společností nesmíte používat bez našeho předchozího písemného souhlasu.",
    "block26": "Pokud bude shledáno, že je některé ustanovení těchto podmínek neplatné, nezákonné nebo nevymahatelné, zbývající ustanovení zůstanou v plné platnosti a účinnosti.",
    "block3": "Pokud je vám méně než 18 let, musíte k používání služby získat povolení od rodičů nebo zákonných zástupců. Pokud službu používáte jménem jiné osoby nebo subjektu, musíte být oprávněni přijmout tyto podmínky jejich jménem. Při registraci k účtu musíte uvést přesné a úplné informace. Nesmíte sdělovat své přístupové údaje ani účet nikomu mimo vaši organizaci a nesete odpovědnost za veškeré aktivity prováděné s použitím vašich přihlašovacích údajů.",
    "block4": "(a) Používání služby. Službu smíte používat v souladu s těmito podmínkami a udělujeme vám nevýhradní právo k používání služby. Při používání služby budete dodržovat tyto podmínky a všechny platné zákony. My a naše přidružené společnosti vlastníme veškerá práva, tituly a podíly na službě.",
    "block5": "(b) Zpětná vazba. Vítáme zpětnou vazbu, komentáře, nápady, návrhy a vylepšení. Pokud poskytnete jakýkoli takový obsah, můžeme jej použít bez jakýchkoli omezení a bez náhrad vám.",
    "block6": "(c) Omezení. Nesmíte: (1) používat službu způsobem, který porušuje, zneužívá nebo narušuje jakákoli práva ostatních; (2) provádět reverzní inženýrství, dekompilaci, demontáž, překlad nebo se jinak pokoušet zjistit zdrojový kód nebo podkladové komponenty modelů, algoritmů a systémů služby (pokud taková omezení nezakazují platné právní předpisy); (3) používat výstup služby k vývoji modelů, které by konkurovaly Noamu; (4) extrahovat data nebo výstup ze služby pomocí automatizovaných nebo programovacích metod, pokud to není povoleno prostřednictvím API, včetně škrábání, shromažďování webových dat nebo extrakce webových dat; (5) vydávat výstup služby za uměle generovaný, pokud není, nebo jinak porušovat naše zásady používání; (6) nakupovat, prodávat nebo převádět klíče API bez našeho předchozího souhlasu; ani (7) poskytovat nám jakékoli osobní údaje dětí mladších 13 let nebo příslušného věku pro digitální souhlas. Dodržíte veškerá omezení rychlosti a další požadavky uvedené v naší dokumentaci.",
    "block7": "(d) Služby třetích stran. Na veškerý software, služby nebo jiné produkty třetích stran související se službou se vztahují jejich vlastní podmínky a my neneseme odpovědnost za produkty třetích stran.",
    "block8": "(a) Váš obsah. Službě můžete poskytnout vstup (\"vstup\") a obdržet výstup (\"výstup\") vygenerovaný a vrácený na základě vstupu (souhrnně označované jako \"obsah\"). Mezi stranami a v rozsahu povoleném platnými právními předpisy vlastníte veškerý vstup. Noam může obsah používat k poskytování a údržbě služby, dodržování platných zákonů a vymáhání našich zásad. Za obsah odpovídáte vy, a to včetně zajištění, aby neporušoval žádné platné zákony ani tyto podmínky.",
    "block9": "(b) Přesnost. Umělá inteligence a strojové učení jsou rychle se rozvíjejícími oblastmi výzkumu. Neustále se snažíme naši službu vylepšovat, aby byla přesnější, spolehlivější, bezpečnější a užitečnější. Vzhledem k pravděpodobnostní povaze strojového učení může používání naší služby v některých případech vést k nesprávným výstupům, které nemusí přesně odrážet skutečné osoby, místa nebo skutečnosti. Přesnost jakéhokoli výstupu byste měli náležitě posoudit na základě vašeho případu použití, a to i manuálním ověřením výstupů.",
    "subTitle1": "Registrace a přístup",
    "subTitle10": "Oddělitelnost",
    "subTitle2": "Požadavky na používání",
    "subTitle3": "Obsah",
    "subTitle4": "Poplatky a platby",
    "subTitle5": " Důvěrnost, bezpečnost a ochrana údajů",
    "subTitle6": "Doba trvání a ukončení",
    "subTitle7": "Odškodnění; vyloučení odpovědnosti; omezení odpovědnosti",
    "subTitle8": "Rozhodné právo a jurisdikce",
    "subTitle9": "Všeobecná ustanovení",
    "title": "Podmínky služby",
    "updateTime": "Aktualizováno: 15. března 2024"
  },
  "trialModal": {
    "btn": "Začít hned (pouze 0,99 $)",
    "chat": "ChatPDF",
    "desc1": "Po skončení zkušební doby vám bude účtován roční poplatek ve výši 180 $",
    "desc2": "Zrušit můžete kdykoli",
    "focus": "Zaostřování",
    "hover": "Překlad pod kurzorem",
    "image": "Překlad obrázku",
    "installBtn": "Nainstalujte si a získejte dárek",
    "memo": "Správa oblíbených",
    "pdf": "Paralelní překlad PDF",
    "realtime": "Okamžitý překlad",
    "select": "Překlad vybraného textu",
    "title": "Uvítací dárek",
    "trialText": "7denní zkušební verze",
    "video": "Překlad videa",
    "videoSummary": "Shrnutí videa",
    "web": "Paralelní překlad",
    "webSummary": "Shrnutí webu"
  },
  "upgradeModal": {
    "btn": "Upgradovat",
    "chat": {
      "free": "Dosáhli jste limitu pro Chatpdf",
      "pro": "Tento měsíc jste dosáhli limitu pro Chatpdf"
    },
    "contactStr": "Počkejte na další reset nebo nás kontaktujte na adrese contact@noam.tools.",
    "limitAlertStr": "Váš bezplatný tarif je již vyčerpán, pro pokračování doporučujeme přejít na placený tarif.",
    "more": "Další tarify",
    "pageLimit": {
      "free": "Dosáhli jste limitu pro počet stránek PDF",
      "pro": "Tento měsíc jste dosáhli limitu pro počet stránek PDF"
    },
    "title": "Připomínky",
    "upgradeAlertStr": "Váš bezplatný tarif je již vyčerpán, pro pokračování doporučujeme přejít na placený tarif.",
    "upload": {
      "free": "Dosáhli jste limitu pro překlad PDF",
      "pro": "Tento měsíc jste dosáhli limitu pro překlad PDF"
    }
  },
  "welfare": {
    "newUser": {
      "joinBtn": "Připojit se",
      "label": "Výhoda pro nové uživatele:",
      "remain": "Zbývá pouze 30",
      "text": "Zbývá posledních 100 míst! Získejte to své!"
    }
  }
}

export default TRANSLATION
