const TRANSLATION = {
  slogen: 'En kolay AI asistanı',
  pageHeader: {
    help: 'Yardım Merkezi',
    contact: 'Bize Ulaşın',
    pricing: 'Fiyatlandırma',
    signUp: 'Giriş yapmak',
    settings: 'Ayarlar',
    logout: 'Oturumu kapat',
    logoutSucc: 'Çıkış başarılı',
  },
  pageFooter: {
    product: {
      title: 'Ürünler',
      web: 'Web Çevirisi ve Özeti',
      pdf: 'PDF Çevirisi',
      video: 'Video Çevirisi ve Özeti',
      image: 'Görüntü Çevirisi',
    },
    resources: { title: 'Kaynaklar' },
    follow: { title: 'Takip etmek', twitter: 'heyecan', facebook: 'Facebook' },
    legal: { title: 'Yasal', policy: 'Gizlilik Politikası', terms: 'Hizmet Şartları' },
  },
  home: {
    getStart: 'Başlayın',
    getStartedForFree: 'Ücretsiz başlayın',
    top: {
      desc: "Noam, özetlenmiş web sayfalarını, videoları ve PDF içeriğini çevirmenize yardımcı olan ücretsiz bir AI tarayıcı uzantısıdır.\n      Noam'a katılın ve büyünün tadını çıkarın!",
      title2: 'Bir Büyü',
      title1: 'Tek Tıklama',
    },
    howItWork: {
      title: 'Peki nasıl çalışıyor',
      desc: 'Noam, kullanıcılara diller arası web sayfası çevirisi ve özetleme, PDF ve görüntü çevirisi, video çevirisi ve özetleme için akıllı bir deneyim sunmak amacıyla yapay zeka modellerini entegre ediyor',
    },
    PDFTranslate: {
      title: 'PDF Çevirisi',
      desc: 'İki Dilli Çeviri + Sohbet PDF',
      sub1: {
        title: 'İki dilli okuma',
        desc: 'İki dilli okuma, böylece okuma verimliliğini artırmak için okurken orijinal metni ve çeviriyi karşılaştırabilirsiniz',
      },
      sub2: {
        title: "Sohbet PDF'si",
        desc: 'Yapay zeka analiz eder, özetler ve PDF içeriğine göre sorularınızı yanıtlayabilir',
      },
      sub3: {
        title: 'Doğruluk ve Verimlilik',
        desc: 'Daha doğru ve verimli çeviriler için yapay zeka destekli PDF',
      },
    },
    aiAssistant: {
      title: 'Yapay Zeka Asistanı',
      desc: 'Web Çevirisini Daha Akıllı ve Daha Kullanışlı Hale Getirme',
      panel1: {
        title: 'İki Dilli Çeviri',
        desc: 'İki dilli çapraz referanslı çeviriler, orijinal metni çeviriyle karşılaştırmanıza yardımcı olur, böylece daha iyi anlayabilir ve okuma verimliliğinizi artırabilirsiniz.',
      },
      panel2: {
        left: {
          title: 'Vurgulu Çeviri',
          desc: 'Bir web sayfasına göz atarken, çeviri sonucunu almak için farenizi doğrudan çevrilmesi gereken içeriğin üzerine getirin.',
        },
        right: {
          title: 'Metin Seçimi Çevirisi',
          desc: 'Bir web sayfasına göz atarken, çeviri sonucunu almak için doğrudan altı çizili metni seçin.',
        },
      },
      panel3: {
        one: {
          title: 'İşaret Et ve Tıkla',
          desc: 'Web sayfalarını ve video içeriğini sizin için istediğiniz zaman, istediğiniz yerde, herhangi bir senaryoda çevirin ve özetleyin; size daha yumuşak bir deneyim getiriyor!',
        },
        two: {
          title: 'Daha Kapsamlı ve Kullanışlı',
          desc: 'Çok dilli tam kapsam, iki dilli engelsiz okuma, tek tuşla çeviri; size eşsiz bir deneyim getiriyor!',
        },
        three: {
          title: 'Yapay Zeka Odaklı',
          desc: 'Çeviri doğruluğunu ve tutarlılığını sağlamak ve okuma deneyimini geliştirmek için yapay zeka destekli büyük model',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Video Çevirisi ve Özeti',
          desc: 'Verimlilik uzmanınız, chatgpt ile birlikte, okuma sürenizden tasarruf etmek için YouTube video içeriğini çevirir ve özetler!',
        },
        tab2: {
          key: 'Resim',
          title: 'Resim Çevirisi',
          desc: "Web'de gezinirken görselleri çevirmek için tıklayın",
        },
        tab3: {
          key: 'ağ',
          title: 'Web Çevirisi ve Özeti',
          desc: 'Özetleme için web sayfalarından önemli bilgileri hızlı bir şekilde çıkarmak, okuma verimliliğinizi artırmak',
        },
        tag1: 'Basit',
        tag2: 'Yeterlik',
        tag3: 'Kolayca Anlaşılır',
      },
    },
    userReviews: { title: 'Kullanıcı Yorumları', desc: "Chromewebstore'da beş yıldızlı inceleme" },
    lastBlock: { desc: 'Daha iyi yapay zeka çevirisi ve daha sorunsuz okuma deneyimi' },
  },
  login: {
    title: "Noam'a hoş geldiniz",
    btn: 'Google ile Giriş Yapın',
    tips: 'Kaydolarak şunu kabul ediyorum:',
    terms: 'Kullanım Koşulları',
    and: 'Ve',
    policy: 'Gizlilik Politikası',
    btn1: 'Şimdi Giriş Yapın',
    desc: 'Daha fazla deneyimin kilidini açmak için giriş yapın',
  },
  pricing: {
    pageTitle: 'Fiyatlandırma',
    desc: 'Sizin için en iyisini karşılaştırın ve seçin',
    yearly: 'Yıllık',
    monthly: 'Aylık',
    discount: '%20 indirim',
    preMonth: 'Aylık',
    billedYearly: 'Yıllık Faturalandırılır',
    billedMonthly: 'Aylık Faturalandırılır',
    detailedCompare: 'Detaylı Karşılaştırma',
    FAQ: 'SSS',
    day: 'gün',
    month: 'ay',
    around: 'Etrafında',
    free: {
      benefit1: 'Günde 100 kez anında çeviri',
      benefit2: 'Web çevirisi ve özeti',
      benefit3: '30 sayfa Görüntü Çevirisi',
      benefit4: 'Video çevirisi ve özeti',
      benefit5: '10 PDF Çevirisi',
      benefit6: '5.000 jeton',
      recommendText: 'Ücretsiz Deneme',
      btn: 'İyi eğlenceler',
    },
    pro: {
      benefit1: 'Müşteri Hizmetleri Hızlı Destek',
      benefit2: '1.000.000 jeton/ay',
      benefit3: 'Web çevirisi ve özeti',
      benefit4: '200 sayfa Görüntü Çevirisi',
      benefit5: 'Video çevirisi ve özeti',
      benefit6: '200 PDF Çevirisi',
      btn: 'Başlarken',
      recommendText: 'Çoğu insan seçiyor',
    },
    proPlus: {
      benefit1: 'Müşteri Hizmetleri Hızlı Destek',
      benefit2: '3.000.000 jeton/ay',
      benefit3: 'Web çevirisi ve özeti',
      benefit4: '500 sayfa Görüntü Çevirisi',
      benefit5: 'Video çevirisi ve özeti',
      benefit6: '500 PDF Çevirisi',
      recommendText: 'En uygun seçim',
    },
    enterprise: {
      benefit1: 'Müşteri Hizmetleri Hızlı Destek',
      benefit2: 'Çok kullanıcılı',
      benefit3: 'Özelleştirilmiş çözümler',
      benefit5: 'Özel Hesap Yöneticisi',
      recommendText: 'Özelleştirilmiş Planlar（≥3人）',
      talkToUs: 'Bizimle konuşun',
      btn: 'Temas etmek',
    },
    web: {
      contrastiveTranslation: 'İki Dilli Çeviri',
      instantTranslation: 'Anında Çeviri',
      hoverTranslation: 'Vurgulu Çeviri',
      dictionaryTranslation: 'Metin Seçimi Çevirisi',
      summary: 'Web sayfası özeti',
    },
    imageTranslation: 'Resim çevirisi',
    video: { translation: 'Video çevirisi', summary: 'Video özeti' },
    pdf: {
      translation: 'PDF çevirisi',
      maxPage: "PDF'deki maksimum sayfa sayısı",
      chat: 'PDF sorgularının sayısı',
    },
    FQAObj: {
      q1: 'Ücretsiz olarak kullanabilir miyim?',
      a1: "Noam'ın doğrudan kullanılabilecek ücretsiz bir sürümü var.",
      q2: 'Farklı üyelik planları ve ücret seçenekleri var mı?',
      a2: 'Ücretsiz sürüme ek olarak üç üyelik planı vardır: Pro, Pro+ ve Enterprise; Sürekli aylık ve sürekli yıllık olmak üzere iki ödeme yöntemi arasından seçim yapabilirsiniz ve sürekli yıllık kapsamlı maliyetin daha uygun olduğunu görürsünüz.',
      q3: 'Sürekli aylık abonelik ile sürekli yıllık abonelik arasındaki fark nedir?',
      a3: 'İşlevsellik açısından hiçbir fark yoktur. Sürekli yıllık aboneliğin kapsamlı maliyeti, sürekli aylık aboneliğin maliyetinden daha düşüktür; aylık abonelik ayda bir kez, yıllık abonelik ise yılda bir kez faturalandırılır.',
      q4: 'Otomatik yenileme planımı nasıl iptal edebilirim?',
      a4: 'Yenileme planını iptal etmek için arka uç ayarları sayfasına gidebilir ve abone olmayı seçebilirsiniz.',
    },
  },
  upgradeModal: {
    title: 'Hatırlatıcılar',
    limitAlertStr:
      'Ücretsiz plan kotanız doldu, deneyime devam etmek için planınızı yükseltmenizi öneririz~!',
    upgradeAlertStr:
      'Ücretsiz plan kotanız doldu, deneyime devam etmek için planınızı yükseltmenizi öneririz~!',
    btn: 'Şimdi Yükselt',
    more: 'Daha Fazla Plan',
    contactStr:
      'Lütfen bir sonraki sıfırlamayı bekleyin veya contact@noam.tools adresinden bizimle iletişime geçin.',
    upload: {
      free: 'PDF çevirisinde sınırınıza ulaştınız',
      pro: 'Bu ay pdf çevirisi sınırınıza ulaştınız',
    },
    chat: {
      free: 'Chatpdf sınırı aşıldı sınırınıza ulaştınız',
      pro: 'Bu ay Chatpdf sınırınıza ulaştınız',
    },
    pageLimit: { free: 'PDF sayfa sınırı aşıldı' },
  },
  buyModal: {
    title: 'Abonelik Planı',
    freeTrial: '3 Günlük Ücretsiz Deneme',
    selectLabel: 'Bir plan seçin',
    btn: 'Ücretsiz Deneyin',
    yearLabel: 'yıl',
    monthLabel: 'ay',
    afterFree: 'Duruşmadan sonra',
  },
  privacy: {
    title: 'Gizlilik Politikası',
    updateTime: 'Güncelleme: 15 Mart 2024',
    block1:
      'Biz, Noam ("Noam", "biz", "bizim" veya "bize" olarak anılacaktır), gizliliğinize saygı duyuyoruz ve sizden veya sizin hakkınızda elde ettiğimiz her türlü bilgiyi korumaya kararlıyız. Bu Gizlilik Politikası, web sitemizi, uygulamalarımızı ve hizmetlerimizi (toplu olarak "Hizmetler" olarak anılacaktır) kullanımınızdan kaynaklanan kişisel bilgilerin toplanması veya kullanılmasına ilişkin uygulamalarımızı açıklamaktadır. Bu Gizlilik Politikası, API\'miz gibi ticari ürün müşterilerimiz adına işlediğimiz içerikler için geçerli değildir. Bu verilerin kullanımı, bu ürünlere erişimi ve bunların kullanımını kapsayan müşteri sözleşmemize tabidir.',
    subTitle1: 'Topladığımız Kişisel Bilgiler',
    block2: 'Sizinle ilgili aşağıdaki kişisel bilgileri ("Kişisel Bilgiler") topluyoruz:',
    block3:
      'Tarafınızdan Sağlanan Bilgiler: Hizmetlerimizi kullanmak veya bizimle iletişim kurmak için bir hesap oluşturursanız, kişisel bilgileri aşağıdaki şekilde toplayacağız:',
    subBlock1:
      'Hesap Bilgileri: Platformumuzda bir hesap oluşturduğunuzda, adınız, iletişim bilgileriniz, hesap kimlik bilgileriniz, ödeme kartı bilgileriniz ve işlem geçmişiniz dahil olmak üzere hesabınızla ilgili bilgileri toplarız ("Hesap Bilgileri" olarak anılır).',
    subBlock2:
      'Kullanıcı İçeriği: Hizmetlerimizi kullandığınızda, girdilerinizden, dosya yüklemelerinizden veya bize sağladığınız geri bildirimlerden ("İçerik" olarak anılacaktır) kişisel bilgiler toplarız.',
    subBlock3:
      'İletişim Bilgileri: Bize mesaj gönderirseniz adınızı, iletişim bilgilerinizi ve gönderdiğiniz mesajların içeriğini ("İletişim Bilgileri" olarak anılacaktır) toplayacağız.',
    subBlock4:
      'Sosyal Medya Bilgileri: Instagram, Facebook, Medium, Twitter, YouTube ve LinkedIn gibi sosyal medya sitelerinde sayfalarımız bulunmaktadır. Sosyal medya sayfalarımızla etkileşimde bulunduğunuzda, iletişim bilgileriniz gibi bize vermeyi seçtiğiniz kişisel bilgileri ("Sosyal Bilgiler" olarak anılacaktır) toplarız. Ayrıca sosyal medya sayfalarımızı barındıran şirketler bize sosyal medya faaliyetlerimiz hakkında toplu bilgi ve analiz sağlayabilir.',
    block4:
      'Hizmetleri Kullanımınızdan Otomatik Olarak Alınan Kişisel Bilgiler: Hizmetlere eriştiğinizde, bunları kullandığınızda veya bunlarla etkileşimde bulunduğunuzda, erişiminiz, kullanımınız veya etkileşiminiz hakkında aşağıdaki bilgileri alırız ("Teknik Bilgiler"):',
    subBlock5:
      'Günlük Verileri: Hizmetlerimizi kullanırken tarayıcınız tarafından otomatik olarak gönderilen bilgiler. Günlük verileri İnternet Protokolü (IP) adresinizi, tarayıcı türünüzü ve ayarlarınızı, taleplerinizin tarih ve saatini ve web sitemizle nasıl etkileşimde bulunduğunuzu içerir.',
    subBlock6:
      'Kullanım Verileri: Görüntülediğiniz veya etkileşimde bulunduğunuz içerik türleri, kullandığınız özellikler ve gerçekleştirdiğiniz işlemler ile saat diliminiz, ülkeniz, tarih ve saatiniz gibi hizmeti kullanımınızla ilgili bilgileri otomatik olarak toplayabiliriz. erişim, kullanıcı aracısı ve sürümü, bilgisayar veya mobil cihaz türü ve bilgisayar bağlantınız.',
    subBlock7:
      'Cihaz Bilgileri: Cihazın adını, işletim sistemini, cihaz tanımlayıcısını ve kullandığınız tarayıcıyı içerir. Toplanan bilgiler kullandığınız cihazın türüne ve ayarlarına bağlı olabilir.',
    subBlock8:
      'Çerezler: Hizmetlerimizi işletmek ve yönetmek ve deneyiminizi geliştirmek için çerezleri kullanırız. "Çerez", ziyaret ettiğiniz bir web sitesi tarafından tarayıcınıza gönderilen bilgilerdir. Tarayıcınızı tüm çerezleri kabul edecek, tüm çerezleri reddedecek veya bir çerez sağlandığında size bildirimde bulunacak şekilde ayarlayabilirsiniz, böylece her seferinde kabul edip etmeyeceğinize karar verebilirsiniz. Ancak çerezlerin reddedilmesi, web sitesinin belirli alanlarının veya özelliklerinin kullanılmasını veya görüntülenmesini veya işlevselliğini olumsuz yönde etkilemenizi engelleyebilir. Çerezler hakkında daha fazla bilgi için lütfen Çerezler Hakkında Her Şey sayfasını ziyaret edin.',
    subBlock9:
      'Analizler: Kullanıcıların hizmetlerimizi nasıl kullandıklarını analiz etmemize ve hizmetleri kullanırken deneyiminizi iyileştirmemize yardımcı olması için çerezleri kullanan çeşitli çevrimiçi analiz ürünlerini kullanabiliriz.',
    subTitle2: 'Kişisel Bilgileri Nasıl Kullanıyoruz',
    block5: 'Kişisel bilgileri aşağıdaki amaçlarla kullanabiliriz:',
    subBlock10: 'Hizmetlerin sağlanması, yönetilmesi, sürdürülmesi ve/veya analiz edilmesi;',
    subBlock26: 'Hizmetlerimizi geliştirmek ve araştırma yapmak;',
    subBlock11: 'Sizinle iletişim kurmak;',
    subBlock12: 'Yeni proje ve hizmetler geliştirmek;',
    subBlock13:
      'Dolandırıcılığı, suç faaliyetlerini veya hizmetlerimizin kötüye kullanımını önlemek ve BT sistemlerimizin, mimarimizin ve ağlarımızın güvenliğini korumak;',
    subBlock14: 'Ticari transferlerin gerçekleştirilmesi;',
    subBlock15:
      'Yasal yükümlülüklere ve yasal işlemlere uymak, bizim haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizin yanı sıra bağlı kuruluşlarımızın, sizin veya diğer üçüncü tarafların haklarını korumak.',
    block6:
      'Özet veya Kimlik Bilgilerinden Arındırılmış Bilgiler: Kimlik belirleme amacıyla yeniden kullanılmasını önlemek için kişisel bilgileri toplayabilir veya kimlik bilgilerini kaldırabiliriz ve bu bilgileri hizmetlerimizin etkinliğini analiz etmek, hizmetlerimizi geliştirmek ve bunlara özellikler eklemek, araştırma yapmak ve benzer amaçlarla kullanabiliriz. . Ayrıca, hizmet kullanıcılarımızın genel davranışlarını ve özelliklerini analiz edebilir ve toplu bilgileri üçüncü taraflarla paylaşabilir, bu tür toplu bilgileri yayınlayabilir veya genel kullanıma sunabiliriz. Toplu bilgileri hizmetler aracılığıyla, çerezler aracılığıyla ve bu Gizlilik Politikasında açıklanan diğer yollarla toplayabiliriz. Kimlik bilgileri kaldırılmış bilgileri anonim veya kimlik bilgileri kaldırılmış bir biçimde saklayacağız ve kullanacağız ve yasalar gerektirmedikçe bu tür bilgileri yeniden tanımlama girişiminde bulunmayacağız.',
    subTitle3: 'Kişisel Bilgilerin Açıklanması',
    block7:
      'Belirli durumlarda, kanunen zorunlu kılınmadığı sürece, kişisel bilgilerinizi size önceden bildirimde bulunmaksızın üçüncü taraflara verebiliriz:',
    subBlock16:
      'Tedarikçiler ve Hizmet Sağlayıcılar: İşletmenin operasyonel ihtiyaçlarını karşılamamıza ve belirli hizmet ve işlevleri yerine getirmemize yardımcı olmak için, barındırma hizmeti sağlayıcıları, bulut hizmeti sağlayıcıları ve diğer bilgi teknolojisi hizmet sağlayıcıları, e-posta iletişim yazılımı dahil olmak üzere tedarikçilere ve hizmet sağlayıcılara kişisel bilgiler sağlayabiliriz. ve web analizi hizmet sağlayıcıları vb. Bu ortaklar, bizim için görevlerini yerine getirirken kişisel bilgilere yalnızca bizim yönlendirmemiz doğrultusunda erişecek, işleyecek veya saklayacaklardır.',
    subBlock17:
      'Ticari Transferler: Stratejik işlemler, yeniden yapılanmalar, iflaslar, iflas devralmaları veya başka bir sağlayıcıya hizmet geçişi (topluca "İşlemler" olarak anılır) gerçekleştirirsek, kişisel bilgileriniz ve diğer bilgileriniz işlem muhatapları ve bu işlemlerde yardımcı olan diğer taraflarla paylaşılabilir. İşlemlerin durum tespiti sırasında yapılması ve İşlemlerin bir parçası olarak diğer varlıklarla birlikte bir halef veya bağlı şirkete devredilmesi.',
    subBlock18:
      'Yasal Gereksinimler: Hizmetlerimizle etkileşim bilgileriniz de dahil olmak üzere kişisel bilgilerinizi devlet yetkilileriyle, sektördeki meslektaşlarımızla veya diğer üçüncü taraflarla paylaşabiliriz; (1) yasaların gerektirmesi halinde veya yasal yükümlülüklere uymak için bu tür bir eylemin gerekli olduğuna inanırsak; (2) haklarımızı veya mülkiyetimizi korumak ve savunmak; (3) şartlarımızın, politikalarımızın veya yasalarımızın ihlal edildiğini tek taraflı olarak tespit edersek; (4) dolandırıcılığı veya diğer yasa dışı faaliyetleri tespit etmek veya önlemek; (5) ürünlerimizi, çalışanlarımızı veya kullanıcılarımızı ya da kamu güvenliğini, emniyetini, bütünlüğünü korumak; veya (vi) yasal sorumluluğa karşı savunma yapmak.',
    subTitle4: 'Haklarınız',
    block8:
      'Coğrafi konumunuza bağlı olarak, Avrupa Ekonomik Alanı, Birleşik Krallık ve dünya genelindeki bireyler, kişisel bilgileriyle ilgili belirli yasal haklara sahip olabilir. Örneğin, aşağıdaki haklara sahip olabilirsiniz:',
    subBlock19: 'Kişisel bilgilerinize ve bunların nasıl işlendiğine ilişkin bilgilere erişin.',
    subBlock20: 'Kişisel bilgilerinizi kayıtlarımızdan silin.',
    subBlock21: 'Kişisel bilgilerinizi düzeltin veya güncelleyin.',
    subBlock22: 'Kişisel bilgilerinizi üçüncü bir tarafa aktarın (veri taşınabilirliği).',
    subBlock23: 'Kişisel bilgilerinizi işleme şeklimizi sınırlayın.',
    subBlock24:
      'Onayınızı geri çekin; işlemenin yasal dayanağı olarak onaya dayandığımız durumlarda, onayınızı istediğiniz zaman geri çekebilirsiniz.',
    subBlock25: 'Kişisel bilgilerinizi nasıl işlediğimize itiraz edin.',
    subTitle5: 'Diğer Web Sitelerine Bağlantılar',
    block9:
      'Hizmet, sosyal medya hizmetleri de dahil olmak üzere Noam tarafından işletilmeyen veya kontrol edilmeyen diğer web sitelerine ("Üçüncü Taraf Web Siteleri") bağlantılar içerebilir. Üçüncü Taraf Web Siteleriyle paylaştığınız bilgiler, bu Gizlilik Politikasına değil, Üçüncü Taraf Web Sitelerinin özel gizlilik politikalarına ve hizmet koşullarına tabi olacaktır. Bu bağlantıları kolaylık sağlamak amacıyla sağlıyoruz ve bu web sitelerini onayladığımızı veya incelediğimizi ima etmiyoruz. Gizlilik politikaları ve koşulları hakkında bilgi almak için lütfen Üçüncü Taraf Web Siteleriyle doğrudan iletişime geçin.',
    subTitle6: 'Bilgilerin Güvenliği ve Saklanması',
    block10:
      'Çevrimiçi ve çevrimdışı kişisel bilgileri kayıp, kötüye kullanım ve yetkisiz erişim, ifşa, değiştirme veya düşmanca eylemlere karşı korumak için ticari açıdan makul teknik, idari ve organizasyonel önlemler uyguluyoruz. Ancak hiçbir internet veya e-posta iletimi tam güvenliği veya doğruluğu garanti edemez. Özellikle bize gönderilen veya bizden gönderilen e-postalar güvenli olmayabilir. Bu nedenle, hizmet veya e-posta yoluyla bize hangi bilgileri göndereceğinize karar verirken özellikle dikkatli olmalısınız. Ayrıca, hizmetteki gizlilik ayarlarının veya güvenlik önlemlerinin veya Üçüncü Taraf Web Sitelerinin güvenlik ayarlarının veya güvenlik önlemlerinin engellenmesinden sorumlu değiliz. Kişisel bilgilerinizi, size hizmet sunmamız için veya anlaşmazlıkları çözmek, güvenlik ve emniyet nedenleri veya yasal yükümlülüklerimize uymak gibi diğer meşru iş amaçları için gerekli olduğu sürece saklayacağız. Kişisel bilgileri sakladığımız sürenin uzunluğu, bilgilerin miktarı, niteliği, hassasiyeti, yetkisiz kullanım veya ifşanın potansiyel riskleri, bilgileri işleme amaçlarımız ve diğer yasal olarak bağlayıcı hususlar gibi birçok faktöre bağlı olacaktır.',
    subTitle7: 'Gizlilik Politikasının Değiştirilmesi',
    block11:
      'Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Bunu yaptığımızda, yürürlükteki kanunlar aksini gerektirmedikçe, bu sayfada güncellenmiş bir versiyon yayınlayacağız.',
    subTitle8: 'Bize Nasıl Ulaşabilirsiniz?',
    block12:
      'Bu Gizlilik Politikasıyla ilgili yanıtlanmamış sorularınız veya endişeleriniz varsa lütfen müşteri hizmetlerimizle iletişime geçin. E-posta:',
  },
  terms: {
    title: 'Hizmet Şartları',
    updateTime: 'Güncelleme:15 Mart 2024',
    block1: "Noam'ı kullandığınız için teşekkür ederiz!",
    block2:
      'Bu Kullanım Koşulları, uygulama programlama arayüzlerimiz, yazılımımız, araçlarımız, geliştirici hizmetlerimiz, verilerimiz, belgelerimiz ve web sitemiz (toplu olarak "Hizmetler" olarak anılacaktır) dahil olmak üzere Noam hizmetlerini kullanımınız için geçerlidir. Hizmetlerimizi kullanarak bu şartlara uymayı kabul etmiş olursunuz. Gizlilik Politikamız kişisel bilgileri nasıl topladığımızı ve kullandığımızı açıklamaktadır.',
    subTitle1: 'Kayıt ve Erişim',
    block3:
      '18 yaşın altındaysanız, Hizmeti kullanmak için ebeveynlerinizden veya yasal vasilerinizden izin almanız gerekir. Hizmeti başka bir kişi veya kuruluş adına kullanıyorsanız, bu şartları onlar adına kabul etmeye yetkili olmanız gerekir. Bir hesaba kaydolmak için doğru ve eksiksiz bilgi vermeniz gerekir. Erişim bilgilerinizi veya hesabınızı kuruluşunuz dışından hiç kimseye veremezsiniz ve kimlik bilgileriniz kullanılarak gerçekleştirilen tüm faaliyetlerden siz sorumlusunuz.',
    subTitle2: 'Kullanım Gereksinimleri',
    block4:
      '(a) Hizmetin Kullanımı. Hizmete bu şartlar kapsamında erişebilirsiniz ve size Hizmeti kullanma konusunda münhasır olmayan bir hak veriyoruz. Hizmeti kullanırken bu şartlara ve geçerli tüm yasalara uyacaksınız. Biz ve bağlı kuruluşlarımız, Hizmete ilişkin tüm haklara, unvanlara ve menfaatlere sahibiz.',
    block5:
      '(b) Geribildirim. Geri bildirimleri, yorumları, fikirleri, önerileri ve iyileştirmeleri memnuniyetle karşılıyoruz. Bu tür bir içerik sağlamanız durumunda, bunu herhangi bir kısıtlama olmaksızın ve size tazminat ödemeden kullanabiliriz.',
    block6:
      '(c) Kısıtlamalar. Şunları yapamazsınız: (1) Hizmeti başkalarının herhangi bir hakkını ihlal edecek, kötüye kullanacak veya ihlal edecek şekilde kullanamazsınız; (2) Hizmet modellerinin, algoritmalarının ve sistemlerinin kaynak kodunu veya temel bileşenlerini tersine mühendislik yapmak, kaynak koda dönüştürmek, parçalara ayırmak, tercüme etmek veya başka bir şekilde keşfetme girişiminde bulunmak (bu tür kısıtlamalar geçerli yasalarca yasaklanmadıkça); (3) Hizmetin çıktılarını Noam ile rekabet eden modeller geliştirmek için kullanmak; (4) API aracılığıyla izin verilmediği sürece, kazıma, web toplama veya web veri çıkarma dahil olmak üzere herhangi bir otomatik veya programlama yöntemini kullanarak Hizmetten veri veya çıktı çıkarmak; (5) Hizmetin çıktısını yapay olarak üretilmiş gibi göstermek veya kullanım politikalarımızı başka bir şekilde ihlal etmek; (6) API anahtarlarını önceden iznimiz olmadan satın almak, satmak veya aktarmak; veya (7) 13 yaşın veya geçerli dijital izin yaşının altındaki çocuklara ilişkin kişisel bilgileri bize sağlamak. Belgelerimizdeki her türlü oran sınırına ve diğer gereksinimlere uyacaksınız.',
    block7:
      '(d) Üçüncü Taraf Hizmetleri. Hizmetle ilişkili tüm üçüncü taraf yazılımları, hizmetleri veya diğer ürünleri, kendi şartlarına tabidir ve üçüncü taraf ürünlerinden biz sorumlu değiliz.',
    subTitle3: 'İçerik',
    block8:
      '(a) İçeriğiniz. Hizmete girdi ("Giriş") sağlayabilir ve Girdiye dayalı olarak oluşturulan ve döndürülen çıktıyı ("Çıktı") alabilirsiniz (toplu olarak "İçerik" olarak anılacaktır). Taraflar arasında ve yürürlükteki yasaların izin verdiği ölçüde, tüm Girdilerin sahibi sizsiniz. Noam, İçeriği Hizmeti sağlamak ve sürdürmek, geçerli yasalara uymak ve politikalarımızı uygulamak için kullanabilir. Geçerli yasaları veya bu şartları ihlal etmemesini sağlamak da dahil olmak üzere İçerikten siz sorumlusunuz.',
    block9:
      '(b) Doğruluk. Yapay zeka ve makine öğrenimi hızla gelişen araştırma alanlarıdır. Hizmetimizi daha doğru, güvenilir, güvenli ve faydalı hale getirmek için sürekli olarak iyileştirmeye çalışıyoruz. Makine öğreniminin olasılıksal doğası göz önüne alındığında, hizmetimizi kullanmak bazı durumlarda gerçek kişileri, yerleri veya gerçekleri doğru şekilde yansıtmayabilecek hatalı çıktılara yol açabilir. Çıktıları manuel olarak gözden geçirmek de dahil olmak üzere, kullanım durumunuza göre herhangi bir çıktının doğruluğunu uygun şekilde değerlendirmelisiniz.',
    subTitle4: 'Ücretler ve Ödeme',
    block10:
      '(a) Ücretler ve Faturalandırma. Tüm ücretleri ("Ücretler") geçerli fiyatlandırma sayfasındaki fiyatlandırma ve şartlara göre veya aramızda yazılı olarak mutabakata varıldığı şekilde ödeyeceksiniz. Fatura düzenlemiş veya ödeme almış olsak bile fiyatlandırma hatalarını veya hatalarını düzeltme hakkımızı saklı tutuyoruz. Geçerli ve yetkili bir ödeme yöntemi de dahil olmak üzere tam ve doğru fatura bilgilerini sağlayacaksınız. Ödeme yönteminizden, mutabakata varıldığı şekilde periyodik olarak ücret alacağız ancak fatura tarihini makul ölçüde değiştirebiliriz. Noam\'a, bağlı kuruluşlarına ve üçüncü taraf ödeme işleyicilerine, ödeme yönteminizden ücret alma yetkisi verirsiniz. Ödemeniz başarısız olursa, size yazılı bildirimde bulunacağız ve ödeme alınana kadar Hizmete erişimi askıya alabiliriz. Bu sözleşmede aksi belirtilmedikçe yapılan ödemeler iade edilmez.',
    block11:
      '(b) Vergiler. Aksi belirtilmediği sürece, Ücretlere herhangi bir federal, eyalet, yerel ve yabancı vergi, harç ve benzeri tahakkuklar ("Vergiler") dahil değildir. Size fatura edeceğimiz net gelire dayalı Vergiler hariç, satın alma işlemlerinizle ilgili tüm Vergilerden siz sorumlusunuz. Bu tür Vergileri derhal ödemeyi ve bize yasal olarak geçerli gizlilik bildirimlerini sunmayı ve bu tür verileri işlemek için gerekli izinleri almayı kabul edersiniz ve bu tür verileri geçerli yasalara uygun olarak kullandığınızı bize garanti edersiniz.',
    block12:
      '(c) Fiyat Değişiklikleri. Hesabınız ve/veya web sitemiz aracılığıyla size bildirimde bulunarak fiyatlarımızı değiştirebiliriz. Fiyat artışları yayınlandıktan hemen sonra geçerli olacaktır. Herhangi bir fiyat değişikliği, değişikliğin geçerlilik tarihinden sonra hesabınızdaki Ücretlere uygulanacaktır.',
    block13:
      '(d) Anlaşmazlıklar ve Gecikmiş Ödemeler. Herhangi bir Ücret veya Vergiye itiraz etmek istiyorsanız lütfen fatura tarihinden itibaren otuz (30) gün içinde contact@noam.tools ile iletişime geçin.',
    block14:
      '(e) Ücretsiz Kullanım. Ücretsiz kredi almak için birden fazla hesap oluşturamazsınız. Ücretsiz kredileri iyi niyetle kullanmadığınızı tespit edersek, sizden standart ücretler talep edebilir veya Hizmete erişim sağlamayı durdurabiliriz.',
    subTitle5: 'Gizlilik, Güvenlik ve Veri Koruma',
    block15:
      "(a) Gizlilik. Noam'ın gizli bilgilerine erişiminiz olabilir. Gizli bilgileri yalnızca bu koşullar altında izin verildiği ölçüde Hizmeti kullanmak amacıyla kullanabilirsiniz. Gizli bilgileri herhangi bir üçüncü tarafa ifşa edemezsiniz ve gizli bilgileri, kendi benzer gizli bilgilerinizi koruduğunuzdan daha az koruyucu olmayan bir şekilde, en azından makul bir özenle koruyacaksınız. Gizli bilgiler, yazılım, spesifikasyonlar ve diğer kamuya açık olmayan ticari bilgiler de dahil olmak üzere, Noam tarafından gizli olarak belirlenen veya duruma göre makul bir şekilde gizli olarak ele alınması gereken, kamuya açık olmayan bilgilerdir. Gizli bilgiler aşağıdaki bilgileri içermez: (1) sizin tarafınızdan herhangi bir kusur olmadan genel olarak kamuya açık hale gelen; (2) bu koşullar uyarınca teslim almadan önce herhangi bir gizlilik yükümlülüğü olmaksızın elinizde bulunan; (3) herhangi bir gizlilik yükümlülüğü olmaksızın üçüncü bir tarafça size yasal olarak ifşa edilen; veya (4) gizli bilgiler kullanılmadan tarafınızca bağımsız olarak geliştirilmişse. Yasa, mahkeme veya başka bir resmi emir gerektirdiği takdirde gizli bilgileri ifşa edebilirsiniz ancak Noam'a makul bir şekilde önceden yazılı bildirimde bulunmalı ve bize yardımcı olmak da dahil olmak üzere mümkün olduğu ölçüde ifşa kapsamını sınırlamak için makul çaba göstermelisiniz. ifşa taleplerine karşı çıkıyor.",
    block16:
      '(b) Güvenlik. Hizmete erişiminizi ve Hizmeti kullanımınızı korumaya yardımcı olmak için makul ve uygun önlemleri uygulamanız gerekir. Hizmeti kullanımınızla ilgili herhangi bir güvenlik açığı veya ihlal keşfederseniz derhal Noam ile iletişime geçmeli ve güvenlik açığı veya ihlalin ayrıntılarını vermelisiniz.',
    block17:
      '((c) Kişisel Verilerin İşlenmesi. Hizmeti kişisel verileri işlemek için kullanırsanız, yasal olarak yeterli gizlilik bildirimlerini sağlamalı ve bu tür verileri işlemek için gerekli izinleri almalısınız ve bu verileri geçerli yasalara uygun olarak kullandığınızı bize garanti edersiniz. .',
    subTitle6: 'Süre ve Fesih',
    block18:
      "(a) Fesih; Süspansiyon. Bu şartlar, Hizmeti ilk kullanımınızdan itibaren geçerli olacak ve sonlandırılana kadar yürürlükte kalacaktır. Hizmet ve İçeriği kullanmayı bırakarak bu koşulları istediğiniz zaman herhangi bir nedenle feshedebilirsiniz. Bu koşulları herhangi bir nedenle önceden bildirimde bulunarak feshedebiliriz. Bölüm 2 (Kullanım Gereksinimleri), Bölüm 5 (Gizlilik, Güvenlik ve Veri Koruma), Bölüm 8 (Anlaşmazlık Çözümü) veya Bölüm 9'u (Genel Şartlar) önemli ölçüde ihlal etmeniz durumunda veya kontrolümüz dışındaki herhangi bir üçüncü taraf teknoloji sağlayıcısıyla ilişki değişiklikleri veya yasalara veya devlet taleplerine uyum amacıyla. Bu şartlara uymamanız veya kullanımınızın bizim veya herhangi bir üçüncü taraf için güvenlik riski oluşturması veya kullanımınızın hileli olduğundan şüphelenmemiz veya bizi veya herhangi bir üçüncü tarafı yükümlülük altına sokması durumunda Hizmete erişiminizi askıya alabiliriz. .",
    block19:
      '(b) Feshin Etkileri. Feshin ardından, Hizmeti kullanmayı bırakacak ve gizli bilgileri tarafımızca belirtilen şekilde derhal iade edecek veya yok edeceksiniz. Bölüm 3 ve 5-9 dahil ancak bunlarla sınırlı olmamak üzere, doğası gereği fesih veya sona erme tarihinden sonra da geçerli olması gereken bu şartların hükümleri geçerliliğini sürdürecektir.',
    subTitle7: 'Tazminat; Sorumluluk reddi beyanları; Sorumluluğun Sınırlandırılması',
    block20:
      '(a) Tazminat. İçeriğiniz de dahil olmak üzere Hizmeti kullanımınızdan kaynaklanan veya bununla ilgili olarak ortaya çıkan her türlü talep, kayıp ve masrafa (avukatlık ücretleri dahil) karşı bizi, bağlı kuruluşlarımızı ve personelimizi savunacak, tazmin edecek ve masun tutacaksınız. Hizmetle ilgili ürün veya hizmetlerin kullanımı ve bu koşulları veya geçerli yasaları ihlal etmeniz.',
    block21:
      '(b) Sorumluluk reddi beyanları. Hizmet "olduğu gibi" sağlanır. Yasaların izin verdiği ölçüde, biz, bağlı kuruluşlarımız ve lisans verenlerimiz Hizmetle ilgili hiçbir beyanda bulunmaz veya garanti vermez ve ticarete elverişlilik, belirli bir amaca uygunluk, tatmin edici kalite, ihlal etmeme garantileri dahil ancak bunlarla sınırlı olmamak üzere hiçbir garanti vermez. ve sessiz kullanım ve ticaretten veya ticaretin kullanımından kaynaklanan her türlü garanti. Hizmetin kesintisiz, doğru veya hatasız olacağını veya herhangi bir İçeriğin güvenli olacağını, kaybolmayacağını veya değiştirilmeyeceğini garanti etmiyoruz.',
    block22:
      '(c) Sorumluluğun Sınırlandırılması. Ne biz, ne de bağlı kuruluşlarımız veya lisans verenlerimiz, kar kaybı, itibar kaybı, kullanım kaybı, veri kaybı veya diğer maddi olmayan kayıplar da dahil olmak üzere dolaylı, arızi, özel, sonuç olarak ortaya çıkan veya cezai zararlardan sorumlu olmayacağız. Bu tür zararların meydana gelebileceği konusunda bilgilendirildik. Bu şartlar uyarınca toplam sorumluluğumuz, talepten önceki on iki (12) ay içinde Hizmete ödediğiniz Ücretleri veya yüz ABD dolarını (100 ABD Doları) (hangisi daha büyükse) aşmayacaktır. Bu bölümdeki sınırlamalar yürürlükteki yasaların izin verdiği azami ölçüde geçerlidir.',
    subTitle8: 'Geçerli Kanun ve Yargı Yetkisi',
    block23:
      'Şartlar ve Hizmetleri kullanımınız, ABD kanunlar ihtilafı kuralları haricinde Amerika Birleşik Devletleri kanunlarına tabidir. Uygulamayı kullanımınız ayrıca diğer yerel, eyalet, ulusal veya uluslararası yasalara da tabi olabilir. Bu Koşullara ilişkin herhangi bir yasal işlem veya dava, yalnızca Amerika Birleşik Devletleri mahkemelerinde görülecektir ve bu tür mahkemelerin kişisel yargı yetkisine tabi olmayı kabul edersiniz.',
    subTitle9: 'Genel Şartlar',
    block24:
      '(a) Tarafların İlişkisi. Noam ve siz bağımsız yüklenicilersiniz ve taraflardan hiçbiri, diğer tarafın önceden yazılı izni olmadan diğer tarafı temsil veya ilzam etme veya diğer taraf adına yükümlülük üstlenme yetkisine sahip değildir.',
    block25:
      "(b) Marka Kullanımı. Noam'ın veya bağlı şirketlerinin adını, logolarını veya ticari markalarını önceden yazılı iznimiz olmadan kullanamazsınız.",
    subTitle10: 'Bölünebilirlik',
    block26:
      'Bu şartların herhangi bir hükmünün geçersiz, yasa dışı veya uygulanamaz olduğu tespit edilirse geri kalan hükümler tam olarak yürürlükte kalacaktır.',
  },
  contact: {
    title: 'Bize Ulaşın',
    block1:
      'Özelleştirme için veya herhangi bir sorunuz veya öneriniz varsa lütfen contact@noam.tools adresinden bizimle iletişime geçin; hemen yanıt vereceğiz.',
    block2:
      '★★★★★ adresindeki Google Apps Uzantı mağazasında bizi derecelendirerek bizi destekleyin.',
    block3: 'Bu uzantıyı paylaşarak başkalarına yardımcı olun:',
  },
  pdf: {
    toast1: 'PDF yükleniyor, lütfen daha sonra tekrar deneyin!',
    toast2: 'PDF içeriği çok fazla, şimdilik iletişim kutusunu desteklemiyoruz',
    toast3: 'İndiriliyor, lütfen indirme işlemi tamamlandıktan sonra tekrar deneyin.',
    shareMsg1: 'Bağlantı Oluşturma Başarılı',
    shareMsg2: 'İçerik Güncellemelerini Başarıyla Paylaşın',
    shareMsg3:
      'Paylaşım bağlantısı oluşturulamadı. Lütfen daha sonra tekrar deneyin veya müşteri hizmetlerimizle iletişime geçin.',
    shareMsg4:
      'İçerik güncellemesi paylaşılamadı, lütfen daha sonra tekrar deneyin veya müşteri hizmetleriyle iletişime geçin',
    shareBtnText1: 'Paylaşım Bağlantıları Oluşturun',
    shareBtnText2: 'Paylaşım içeriği güncellendi',
    uploading: 'Yükleniyor',
    uploadingMsg: 'Yükleme işlemi biraz zaman alacağından lütfen sabırlı olun.',
    cancelUpload: 'İptal etmek',
    uploadErr:
      'Dosya yükleme başarısız oldu. Lütfen daha sonra tekrar deneyin veya müşteri hizmetleriyle iletişime geçin',
    uploadMsg1: 'Lütfen dosyayı buraya sürükleyip bırakın',
    uploadMsg2: 'Desteklenen dosya türleri: PDF |  Maksimum dosya boyutu: 100MB',
    uploadMsg3: 'Buraya yüklenecek dosyaları seçmek veya sürükleyip bırakmak için tıklayın',
    searchPlaceholder: 'Aramak',
    collectionEmpty: 'Koleksiyonda içerik yok~',
    kw: 'zımba',
    video: 'video',
    link: 'bağlantı',
    dataLoading: 'Veriler yükleniyor...',
    freeBannerTips: 'Ücretsiz PDF Çevirisinin Sonu',
    vipBannerTips: 'Bu ay pdf çevirisi sınırınıza ulaştınız.',
    freeBannerBtn: 'Daha fazla keyif almak için yükseltme yapın',
    vipBannerBtn: 'Daha fazla keyif almak için yükseltme yapın',
    dialogTitle: 'Dikkat',
    dialogContent: 'Silmek istediğinizden emin misiniz?',
    okBtn: 'Silmek',
    cancelBtn: 'İptal etmek',
    unLoginToast: 'Bu işlevi kullanmak için lütfen giriş yapın',
    parseErrTips: 'Dosya ayrıştırılamıyor',
    new: 'yeni dosya',
    parseErrDialogContent:
      "Mevcut PDF bağlantısı doğrudan çeviriyi desteklemiyor, lütfen çeviriden sonra orijinal bağlantı PDF'sini indirin",
    okBtn2: 'tanımak',
    notPdf: 'Yalnızca PDF dosyaları',
    uploadingMsg1: 'Dosya yükleme işlemi devam ediyor. Lütfen daha sonra tekrar deneyin.',
    uploadingMsg2:
      'Şu anda dönüştürülmekte olan bir dosyanız var, lütfen daha sonra tekrar deneyin.',
    uploadingMsg3:
      'Dönüşüm ilerleme durumu sorgulaması başarısız oldu, lütfen daha sonra tekrar deneyin veya müşteri hizmetleriyle iletişime geçin',
    uploadingMsg4:
      'Noam yerel dosyalarınıza doğrudan erişemiyor; lütfen çeviri için manuel olarak bir PDF yükleyin.',
    uploadingMsg5:
      "Dosya URL'si alınamadı. Lütfen daha sonra tekrar deneyin veya müşteri hizmetleriyle iletişime geçin.",
    fileMsg: 'Geçerli dosya hacmi:',
    fileMsg2: 'Lütfen 100 milyondan küçük bir dosya yükleyin',
    fileErrMsg:
      'Dosya okunamadı, lütfen daha sonra tekrar deneyin veya müşteri hizmetleriyle iletişime geçin.',
    parseFileToast:
      'Dosya ayrıştırma başarısız oldu, lütfen daha sonra tekrar deneyin veya müşteri hizmetlerimizle iletişime geçin.',
    noId: 'Dosya kimliği mevcut değil',
    autoDetect: 'Otomatik algılama',
    hasTransTask: 'Çevrilen bir görev var. Lütfen bekleyin.',
    transSucc: 'Çeviri başarılıydı!',
    entry: 'PDF Listesi',
    listError:
      'PDF listesi yüklenemedi. Lütfen daha sonra tekrar deneyin veya müşteri hizmetleriyle iletişime geçin.',
    collectionTab: 'Hafıza',
    pdfLoading: 'Ayrıştırma...',
    installTips:
      'Küçük eklentiyi yükleyin, daha fazla PDF dosyasını çevirebilir, ayrıca sorunsuz web sayfası çevirisini deneyimleyebilirsiniz:',
    webTrans: 'tam çapraz referans',
    hoverTrans: 'Vurgulu paragraf çevirisi',
    selectTrans: 'kelimelerin altını çizin ve tercüme edin',
    installText: 'Tüm günlük çeviri ihtiyaçlarınız için tek adres',
    start: 'Ücretsiz deneyiminizi başlatın',
    shareTips: 'PDF Çevirisi bitti, devam edin ve arkadaşlarınızla paylaşın!',
    download: {
      loading: 'Kaydedildi...',
      btn: 'Şimdi Kaydet',
      trans: 'Yalnızca çeviri',
      both: 'iki dillilik',
      download: 'İndirmek',
      msg1: 'Mevcut belgenin bazı çevirileri orijinal metinden daha uzun olduğundan indirildikten sonra metinler çakışabilir, bu nedenle web sayfasında okunması tavsiye edilir.',
      msg2: '1、İçeriğin tamamı çevrilmemiştir, lütfen çeviriden sonra tamamen indirin.',
      msg3: '2、Çevirinin belge kısmı çok uzun, metin çakışmasını önlemek için web sayfasında okuyabilirsiniz.',
      msg4: 'Şu anda çevrilen görevler var. Lütfen çeviri tamamlandıktan sonra tekrar deneyin.',
      fileName: 'Dosya adı:',
      fileType: 'Dosya türünü indirin:',
      transContinue: 'Çeviriye devam et',
      save: 'Yerele kaydet',
      downloadMsg1: 'PDF Çeviride ilerleme:',
      downloadMsg2: 'Öngörülen gereksinimler',
      minute: 'Dakikalar...',
      downloadSucc: "PDF indirme işlemi tamamlandı, şimdi kaydet'e tıklayın!",
      savePrint: 'Kaydet ve Yazdır',
      loading2: 'Yükleniyor...',
      desc: 'Yerele Kaydet: İndirilen çeviriler resim olarak kaydedilir; Kaydet ve Yazdır: İndirilen çeviriler orijinal formatlarında tutulur ve kopyalamayı destekler.',
      success: 'Başarıyla indir',
      cancel:
        'Açılır pencerenin kapatılması, indirilmekte olan PDF dosyasının iptal edilmesine neden olacaktır. İptal etmek istediğinizden emin misiniz?',
      downloadTip:
        'PDF indiriliyor. İndirme başarısızlığını önlemek için indirme sırasında açılır pencereyi kapatmayın.',
      progress: 'PDF çevriliyor. İlerleme: {{ilerleme}}, {{dakika}} dakika sürmesi bekleniyor...',
      error: 'Yerel olarak kaydedilemedi, lütfen kaydetmeyi yazdırmayı deneyin',
    },
    guide: {
      title1: 'İki Dilli Çeviri',
      title2: "Sohbet PDF'si",
      text1: 'İki Dilli Okuma',
      text2:
        'İki dilli okuma, böylece okuma verimliliğini artırmak için okurken orijinal metni ve çeviriyi karşılaştırabilirsiniz',
      text3: "Sohbet PDF'si",
      text4: 'Yapay zeka analiz eder, özetler ve PDF içeriğine göre sorularınızı yanıtlayabilir',
      text5: 'Doğruluk ve Verimlilik',
      text6: 'Daha doğru ve verimli çeviriler için yapay zeka destekli PDF',
      placeholder: 'Lütfen PDF bağlantısını doldurun',
      start: 'Çeviriyi başlat',
      toast: 'Lütfen .pdf ile biten geçerli bir çevrimiçi pdf bağlantısı girin',
    },
    original: 'Orijinal',
    parallel: 'Paralel',
    trans: 'Çeviri',
    initial: "PDF'yi çevir",
    thumbnail: 'kataloglar',
    downloadBtn: 'İndirmek',
    shareBtn: 'Paylaşmak',
    uploadBtn: 'Yüklemek',
    installText2: 'Lütfen önce yükleyin',
    chatPdf: {
      clearSucc: 'Başarıyla temizlendi',
      clear: 'Temizlemek',
      clearContent:
        'Temizledikten sonra yeni bir görüşme oluşturulacak ve bu görüşmenin içeriği kaydedilmeyecek, silmek istediğinizden emin misiniz?',
      clearDialogTitle: 'Dikkat',
      cancel: 'İptal etmek',
      chatTitle: "Noam'a hoş geldiniz, PDF özetiniz hazır:",
      summaryTitle: 'İlginizi çekebilecek sorular:',
      placeholder: 'PDF ile ilgili herhangi bir soruyu girin',
      copy: 'Kopyala',
      copySucc: 'Başarıyı Kopyala',
      historyTitle: 'Tarih',
      expand: 'Yıkılmak',
      noDataDesc: 'Geçmiş yok~',
      noMore: 'Daha fazla yok.',
      Search: 'Aramak',
      delete: 'Silmek',
      loading: 'yükleniyor...',
      deleteTitle: 'Uç',
      deleteContent: 'Silme işleminden sonra bu PDF kurtarılamaz, silmek istiyor musunuz?',
      deleteOk: 'Silmek',
      deleteCancel: 'İptal etmek',
      deleteSuccess: 'Başarıyla sil',
      listError:
        'Okuma listesi yüklenemedi. Lütfen daha sonra tekrar deneyin veya müşteri desteğiyle iletişime geçin',
    },
    shareModal: {
      shareSetting: 'İçerik Ayarlarını Paylaş',
      shareText: 'Paylaşmak',
      text1: 'Paylaşım Ayarları',
      text2: 'Paylaşım Bağlantıları Oluşturun',
      range: 'Kapsam:',
      custom: 'Özelleştirme:',
      page: 'sayfalar',
      sourceLang: 'Kaynak:',
      target: 'Hedef:',
      errMsg:
        'Mevcut paylaşım aralığının içeriği tam olarak tercüme edilmemiştir, bu nedenle lütfen paylaşmadan önce tercüme edin.',
      pageNuErr: 'Lütfen doğru sayfa numarası değerini girin',
      trans: 'Çevir',
      cancel: 'İptal etmek',
      update: 'Güncellenen paylaşım bağlantıları',
      gen: 'Paylaşmak',
      shareTips: 'Çevrilmiş PDF bağlantısını okumak için arkadaşlarınızla paylaşın:',
      copySucc: 'Bağlantı Başarısını Kopyala',
      copy: 'Kopyala',
      settings: 'Paylaşım Ayarları:',
      modify: 'değiştirmek',
      translateTips:
        'İpucu: Çeviri uzun sürüyorsa açılır pencereyi kapatabilir ve çeviri bittikten sonra paylaşabilirsiniz.',
      translating: 'PDF Çevirisi...',
      remain: 'geriye kalan',
      remain1: 'Toplam öngörülen gereksinimler',
      totalPage: 'Toplam 1 sayfa',
      search: 'Aramak',
      notSupport: 'Bu dil şu anda desteklenmiyor',
    },
    toolbar: { Adjust: 'Ayarlamak', autoFit: 'Otomatik uyum' },
    memoTotalCount: 'Toplam 1 içerik',
    dragTipModal: {
      press: 'Basmak',
      black: 'uzay',
      leftKey: 'sol tuş',
      tips: 'sürükleyip görüntülemek için',
      ok: 'Anladım',
    },
  },
  locale: {
    zh: 'Çince',
    en: 'İngilizce',
    es: 'İspanyol',
    ru: 'Rusça',
    pt: 'Portekizce (Brezilya)',
    id: 'Endonezya dili',
    cs: 'Çek',
    de: 'Almanca',
    fr: 'Fransızca',
    it: 'İtalyan',
    nl: 'Flemenkçe',
    pl: 'Lehçe',
    vi: 'Vietnam',
    tr: 'Türkçe',
    uk: 'Ukrayna',
    zhHant: 'Çince Geleneksel',
    ja: 'Japonca',
    ko: 'Korece',
    ar: 'Arapça',
    fa: 'Farsça',
    th: 'Tay dili',
    hi: 'Hintçe',
    noSearchLang: 'Bu dil desteklenmiyor',
    placeholder: 'Aramak',
    af: 'Afrikaans',
    am: 'Amharca',
    as: 'Assam dili',
    az: 'Azerice',
    ba: 'Başkurt',
    bg: 'Bulgarca',
    bn: 'Bangladeş',
    bo: 'Tibetçe',
    bs: 'Boşnakça',
    ca: 'Katalanca',
    cy: 'Galce',
    da: 'Danimarka',
    dsb: 'Aşağı Sorbca',
    dv: 'Divehi',
    el: 'Yunan',
    et: 'Estonyalı',
    eu: 'Bask',
    fi: 'Fince',
    fil: 'Filipinli',
    fj: 'Fiji dili',
    frCA: 'Fransızca (Kanada)',
    ga: 'İrlandalı',
    gl: 'Galiçyaca',
    gom: 'Konkani',
    gu: 'Gujarati dili',
    ha: 'Hausa',
    he: 'İbranice',
    hr: 'Hırvat',
    hsb: 'Yukarı Sorbca',
    ht: 'Haiti Kreyolu',
    hu: 'Macarca',
    hy: 'Ermeni',
    ig: 'İbo',
    ikt: 'Inuinnaqtun',
    is: 'İzlandaca',
    iu: 'İnuitçe',
    iuLatn: 'İnuitçe (Latince)',
    ka: 'Gürcüce',
    kk: 'Kazak',
    km: 'Kmer',
    kmr: 'Kürtçe (Kuzey)',
    kn: 'Kannadaca',
    ku: 'Kürtçe (Merkez)',
    ky: 'Kırgız',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Litvanyalı',
    lug: 'Ganda',
    lv: 'Letonca',
    lzh: 'Çince (Edebi)',
    mai: 'Maithili',
    mg: 'Madagaskarca',
    mi: 'Maori',
    mk: 'Makedonca',
    ml: 'Malayalamca',
    mnCyrl: 'Moğolca (Kiril)',
    mnMong: 'Moğolca (Geleneksel)',
    mr: 'Marathi',
    ms: 'Malayca',
    mt: 'Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Birmanya)',
    nb: 'Norveççe',
    ne: 'Nepalce',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Pencap',
    prs: 'Dari',
    ps: 'Peştuca',
    ptPT: 'Portekizce (Portekiz)',
    ro: 'Romen',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovence',
    sm: 'Samoalı',
    sn: 'Şona',
    so: 'Somalili',
    sq: 'Arnavut',
    srCyrl: 'Sırpça (Kiril)',
    srLatn: 'Sırpça (Latince)',
    st: 'Sesotho',
    sv: 'İsveççe',
    sw: 'Svahili',
    ta: 'Tamilce',
    te: 'Telugu dili',
    ti: 'Tigrinya',
    tk: 'Türkmence',
    tlhLatn: 'Klingonca (Latince)',
    tlhPiqd: 'Klingonca (pIqaD)',
    tn: 'Setsvana',
    to: 'Tongalı',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'Uygur',
    ur: 'Urduca',
    uz: 'Özbekçe (Latince)',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonca (Geleneksel)',
    zhHans: 'Basitleştirilmiş Çince',
    zu: 'Zuluca',
    auto: 'Otomatik algılama',
  },
  stripe: {
    contact: 'Sorularınız için contact@noam.tools adresinden bize ulaşın!',
    fail: {
      title: 'Ödeme başarısız oldu',
      text1: 'Maalesef ödeme isteğinizi işleme koyamıyoruz.',
      text2: 'Lütfen internet bağlantınızı kontrol edip tekrar deneyin.',
      btn: 'Yeniden dene',
    },
    succ: {
      title: 'Tebrikler',
      text1: "Noam'a hoş geldin,",
      text2: 'hadi birlikte başlayalım!',
      btn: 'Hadi biraz sihir yapalım',
    },
  },
  welfare: {
    newUser: {
      label: 'Yeni Kullanıcı Avantajları:',
      joinBtn: 'Şimdi Katılın',
      text: 'Sadece 100 Yer Mevcut! Şimdi Sahip Olun!',
      remain: 'Sadece 30 tane kaldı',
    },
  },
  help: {
    Fixedplugin: 'Sabit eklenti',
    Howtouse: 'Nasıl kullanılır',
    try: 'Bir dene',
    FreeTrialBenefits: 'Ücretsiz deneme avantajları',
    pin: 'Daha kolay erişim için Pin Noam~',
    Video: 'Video',
    ParallelTranslation: 'Paralel Çeviri',
    WebSummary: 'Web Özeti',
    ChatPDF: "Sohbet PDF'si",
    AquickOverviewOfNoam: "Noam'a kısa bir genel bakış",
    WordSelectionTranslation: 'Kelime seçimi çevirisi',
    HoverTranslation: 'Fareyle üzerine gelindiğinde çeviri',
    ImageTranslation: 'Resim çevirisi',
    InstantTranslation: 'Anında çeviri',
    VideoTranslation: 'Video çevirisi',
    benefits: 'Sizin için faydalar hazırladık!',
    Free: 'Özgür',
    Membership: 'Üyelik',
    Translation: 'Çeviri',
    PDFparallelTranslation: 'PDF paralel çeviri',
    ChatPDF2: 'SohbetPDF',
    Summary: 'Özet',
    VideoSummary: 'Video özeti',
    FavoritesManagement: 'Sık kullanılanlar yönetimi',
    Focusing: 'Odaklanma',
    dayFree: '3 Günlük Ücretsiz Deneme',
    planIsNotExist: 'plan mevcut değil',
    TryItForFree: 'Ücretsiz Deneyin',
    startYoutube: "YouTube'la başlayalım~",
    startWiki: 'Wikimedia ile başlayalım~',
    tryNow: 'Şimdi bir deneyin~',
    GoWithYouTube: "YouTube'u kullanın",
    GoWithWikipedia: "Vikipedi'ye git",
    NoamHelp: 'Noam tercüme etmenize ve özetlemenize yardımcı olabilir',
    SkipAllSteps: 'Tüm adımları atla',
    next: 'Sonraki',
    done: 'Tamamlamak',
  },
  trialModal: {
    title: 'Hoş geldin hediyesi',
    trialText: '7 Günlük Deneme',
    desc1: 'Deneme süresi sona erdikten sonra sizden yıllık 180$ ücret alınacaktır.',
    desc2: 'İstediğiniz Zaman İptal Edin',
    btn: 'Şimdi Başlayın（Yalnızca 0,99$）',
    installBtn: 'Hediyenizi almak için hemen yükleyin',
    web: 'Paralel Çeviri',
    select: 'Kelime seçimi çevirisi',
    hover: 'Fareyle üzerine gelindiğinde çeviri',
    image: 'Resim çevirisi',
    realtime: 'Anında çeviri',
    video: 'Video çevirisi',
    pdf: 'PDF paralel çeviri',
    chat: 'SohbetPDF',
    webSummary: 'Web özeti',
    videoSummary: 'Video özeti',
    memo: 'Favori yönetimi',
    focus: 'Odaklanma',
  },
  privacyCollectionPanel: {
    title: 'Bu web sitesi çerezleri kullanıyor',
    text: 'Deneyiminizi geliştirmek amacıyla içerik ve hizmetleri kişiselleştirmek için çerezleri kullanmak istiyoruz.',
    acceptBtn: 'Tümünü kabul et',
  },
  installTips: {
    text: 'Daha fazla özelliğin kilidini açmak için “Noam Tarayıcı Eklentisi”ni yükleyin! Web çapraz referanslama,\n    web videosu özetleme ve daha fazlası!',
    btn: 'Şimdi Deneyin',
  },
}

export default TRANSLATION
